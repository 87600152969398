import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { State } from "redux/store";

import { getSelectedSection } from "redux/Metadata/selectors";
import { MetadataSectionTypes } from "types";

import VLEnrichmentIcon from "assets/icons/VLEnrichmentIcon";
import VLEnrichmentSection from "./Sections/VLEnrichment";
import MetadataSection from "./Sections/Metadata";
import UserTagsSection from "./Sections/UserTags";
import QualitySection from "./Sections/Quality";
import { MetadataSummaryData } from "types";

import {
  getClusterMetadataSummary,
  getClusterMetadataSummaryLoading,
  getDataMetadataSummary,
  getDataMetadataSummaryLoading,
  getIsCardslistLoading,
  getNavigationClusterLoader,
} from "redux/SingleDataset/selectors";
import classNames from "classnames";

import styles from "./style.module.scss";

const MetadataSummary = ({
  selectedSection,
  clusterMetadataSummary,
  clusterMetadataSummaryLoading,
  dataMetadataSummary,
  dataMetadataSummaryLoading,
  isNavigationClusterLoading,
  isCardsListLoading,
  showMetadataSummary,
  dynamicPage,
}: MetadataSummaryProps) => {
  const [initialIsExpanded, setInitialIsExpanded] = useState(true);
  const { datasetId, clusterId } = useParams();
  const isDataPage = !!datasetId && !clusterId;
  const metadataSummary = isDataPage
    ? dataMetadataSummary
    : clusterMetadataSummary;
  const metadataSummaryLoading = isDataPage
    ? dataMetadataSummaryLoading
    : clusterMetadataSummaryLoading;

  const dataLoading = isDataPage
    ? isCardsListLoading
    : isNavigationClusterLoading;
  const isLoading = metadataSummaryLoading || dataLoading;

  const parentContainerRef = useRef<HTMLDivElement>(null);
  const vlEnrichmentRef = useRef<HTMLDivElement>(null);
  const metadataRef = useRef<HTMLDivElement>(null);
  const userTagsRef = useRef<HTMLDivElement>(null);
  const qualityRef = useRef<HTMLDivElement>(null);

  const vlEnrichmentCategory = "Automated";
  const metadataCategory = "Source";
  const userTagsCategory = "User";
  const qualityCategory = "Automated";

  const sectionHeight =
    !!parentContainerRef.current &&
    parentContainerRef.current.offsetHeight - 50;

  const metaDataSummaryWrapperClassNames = classNames(
    showMetadataSummary ? styles.metaDataSummaryWrapper : styles.hiddenDiv
  );

  useEffect(() => {
    const parent = parentContainerRef.current;
    const vlEnrichment = vlEnrichmentRef.current;
    const metadata = metadataRef.current;
    const userTag = userTagsRef.current;
    const quality = qualityRef.current;

    if (parent && vlEnrichment && metadata && userTag && quality) {
      switch (selectedSection) {
        case "vl-enrichment":
          metadata.style.display = "none";
          userTag.style.display = "none";
          quality.style.display = "none";
          vlEnrichment.style.height = `${parent.clientHeight}px`;
          break;
        case "metadata":
          userTag.style.display = "none";
          quality.style.display = "none";
          vlEnrichment.style.display = "none";
          metadata.style.height = `${parent.clientHeight}px`;
          break;
        case "user-tags":
          quality.style.display = "none";
          metadata.style.display = "none";
          vlEnrichment.style.display = "none";
          userTag.style.height = `${parent.clientHeight}px`;
          break;
        case "quality":
          userTag.style.display = "none";
          metadata.style.display = "none";
          vlEnrichment.style.display = "none";
          quality.style.height = `${parent.clientHeight}px`;
          break;
        case null:
          vlEnrichment.style.display = "unset";
          metadata.style.display = "unset";
          userTag.style.display = "unset";
          quality.style.display = "unset";

          vlEnrichment.style.height = "max-content";
          metadata.style.height = "max-content";
          userTag.style.height = "max-content";
          quality.style.height = "max-content";
          break;

        default:
          return;
      }
    }
  }, [selectedSection]);

  useEffect(() => {
    setTimeout(() => setInitialIsExpanded(true), 0);
    setInitialIsExpanded(false);
  }, [dynamicPage]);

  return initialIsExpanded ? (
    <div ref={parentContainerRef} className={metaDataSummaryWrapperClassNames}>
      <div className={styles.expandedContainer}>
        <VLEnrichmentSection
          labels={metadataSummary?.vlLabels}
          icon={<VLEnrichmentIcon />}
          vlEnrichmentRef={vlEnrichmentRef}
          showVLEnrichment={metadataSummary?.showVLEnrichment}
          vlEnrichmentCategory={vlEnrichmentCategory}
          sectionHeight={selectedSection === "vl-enrichment" && sectionHeight}
          isLoading={isLoading}
        />

        <QualitySection
          issueTypes={metadataSummary!.issueTypes}
          qualityRef={qualityRef}
          qualityCategory={qualityCategory}
          sectionHeight={selectedSection === "quality" && sectionHeight}
          isLoading={isLoading}
        />

        <UserTagsSection
          userTags={metadataSummary!.userTags}
          userTagsRef={userTagsRef}
          userTagsCategory={userTagsCategory}
          sectionHeight={selectedSection === "user-tags" && sectionHeight}
          isLoading={isLoading}
        />

        <MetadataSection
          labels={metadataSummary?.labels}
          metadataRef={metadataRef}
          videoData={metadataSummary?.videoData}
          metadataCategory={metadataCategory}
          sectionHeight={selectedSection === "metadata" && sectionHeight}
          isLoading={isLoading}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedSection: getSelectedSection(state),
    clusterMetadataSummary: getClusterMetadataSummary(state),
    clusterMetadataSummaryLoading: getClusterMetadataSummaryLoading(state),
    dataMetadataSummary: getDataMetadataSummary(state),
    dataMetadataSummaryLoading: getDataMetadataSummaryLoading(state),
    isNavigationClusterLoading: getNavigationClusterLoader(state),
    isCardsListLoading: getIsCardslistLoading(state),
  };
};

export default connect(mapStateToProps)(MetadataSummary);

interface MetadataSummaryProps {
  selectedSection: MetadataSectionTypes;
  clusterMetadataSummary?: MetadataSummaryData;
  dataMetadataSummary?: MetadataSummaryData;
  clusterMetadataSummaryLoading: boolean;
  dataMetadataSummaryLoading: boolean;
  isNavigationClusterLoading: boolean;
  isCardsListLoading: boolean;
  showMetadataSummary: boolean;
  dynamicPage: string;
}
