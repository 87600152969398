import { MouseEvent } from "react";
import classNames from "classnames";
import { DatasetStatusEnum } from "types";
import { useDispatch } from "react-redux";
import { setShowDatasetErrorModal } from "redux/Modals/actions";

import styles from "./style.module.scss";

const DatasetStatusButton = ({
  status,
  datasetId,
  errorMessage,
  buttonStyle,
}: DatasetStatusButtonTypes) => {
  const dispatch = useDispatch();

  const handleButtonStyle = () => {
    if (buttonStyle && buttonStyle.lineHeight) {
      const lineHeight = buttonStyle.lineHeight.split("px")[0] as number;

      let paddingV = lineHeight * 0.5;
      let paddingH = lineHeight;
      return {
        ...buttonStyle,
        padding: `${paddingV}px ${paddingH}px`,
        borderRadius: `${paddingV}px`,
      };
    } else return buttonStyle;
  };

  const dynamicStatusText =
    status === DatasetStatusEnum.FATAL_ERROR
      ? "FAILED"
      : status === DatasetStatusEnum.INITIALIZING
      ? "RESUME"
      : status;

  const isInProgress =
    status === DatasetStatusEnum.UPLOADING ||
    status === DatasetStatusEnum.INDEXING;
  const isFailed = status === DatasetStatusEnum.FATAL_ERROR;

  const statusButtonClassnames = classNames(styles.default, {
    [styles.indexing]:
      status === DatasetStatusEnum.UPLOADING ||
      status === DatasetStatusEnum.INDEXING ||
      status === ("SAVING" as any),
    [styles.resume]: status === DatasetStatusEnum.INITIALIZING,
    [styles.ready]: status === DatasetStatusEnum.READY,
    [styles.failed]: isFailed,
  });

  const handleViewErrorClick = (event: MouseEvent) => {
    event.stopPropagation();

    dispatch(
      setShowDatasetErrorModal({
        datasetId,
        errorMessage,
      })
    );
  };

  return (
    <div className={statusButtonClassnames} style={handleButtonStyle()}>
      {isInProgress && <div className={styles.spinner}></div>}
      {dynamicStatusText} {isInProgress && <span>...</span>}
      {isFailed && (
        <div className={styles.viewStatus} onClick={handleViewErrorClick}>
          <span>VIEW</span>
        </div>
      )}
    </div>
  );
};
interface DatasetStatusButtonTypes {
  status: DatasetStatusEnum;
  datasetId: string;
  errorMessage: string;
  buttonStyle: any;
}

export default DatasetStatusButton;
