import { PUBLIC_PAGE_QUERY_PARAMETER_KEY } from "./miscellaneous";
import { getQueryParameter } from "helpers/utility/utilities";

export const PUBLIC_DATASETS_PATH = "/vl-datasets";
export const PRIVATE_DATASETS_PATH = "/datasets";

export const getRootDatasetTitle = () =>
  getQueryParameter(PUBLIC_PAGE_QUERY_PARAMETER_KEY)
    ? "Public VL Datasets"
    : "My Datasets";

export const getRootDatasetsPath = () =>
  getQueryParameter(PUBLIC_PAGE_QUERY_PARAMETER_KEY)
    ? "/vl-datasets"
    : "/datasets";
