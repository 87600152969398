import { createSelector } from "@reduxjs/toolkit";

const state = (state: any) => state.singleImage;

export const getContentLoading = createSelector(state, (state: any) => {
  return state.isContentLoading;
});

export const getSingleImageData = createSelector(state, (state: any) => {
  return state.singleImageData;
});

export const getSingleImageIssues = createSelector(state, (state: any) => {
  return state.singleImageIssues;
});

export const getSingleImageLabels = createSelector(state, (state: any) => {
  return state.singleImageLabels;
});

export const getSelectedObjectLabels = createSelector(state, (state: any) => {
  return state.selectedObjectLabels;
});

export const getHoveredObjectLabel = createSelector(state, (state: any) => {
  return state.hoveredObjectLabel;
});
