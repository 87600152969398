const LinkIcon = ({
  color = "#fff",
  clicked = false,
}: {
  color?: string;
  clicked?: boolean;
}) => {
  return clicked ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
    >
      <path
        d="M3.22218 8.76361C3.65176 9.19318 4.34824 9.19318 4.77782 8.7636L11.3707 2.17071C11.7964 1.74506 11.7964 1.05494 11.3707 0.629289C10.9451 0.203637 10.2549 0.203638 9.82929 0.62929L4.6364 5.82218C4.28492 6.17365 3.71508 6.17366 3.3636 5.82218L2.17071 4.62929C1.74506 4.20364 1.05494 4.20364 0.629289 4.62929C0.203638 5.05494 0.203637 5.74506 0.629289 6.17071L3.22218 8.76361Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.3237 9.74892C13.5036 9.73834 13.6708 9.6516 13.785 9.51197L13.7999 9.49293C14.5025 8.61493 14.913 7.59729 14.9871 6.55216C15.0929 5.05004 14.5448 3.57965 13.4824 2.51759C12.42 1.45553 10.9492 0.905453 9.44666 1.01335C8.40122 1.0874 7.38329 1.49784 6.50503 2.20024L6.4881 2.21505C6.34631 2.32718 6.25954 2.49643 6.25108 2.67626C6.2405 2.85609 6.30822 3.03169 6.43519 3.16075C6.66375 3.38924 7.02775 3.4104 7.28171 3.20729L7.32615 3.17133C7.94199 2.67838 8.65306 2.37584 9.37894 2.29544C9.52708 2.27852 9.67522 2.27006 9.82336 2.27006C10.8582 2.27006 11.8486 2.67626 12.5893 3.41674C13.4359 4.26301 13.8443 5.43086 13.711 6.62409C13.6305 7.34976 13.3258 8.06062 12.8327 8.67628L12.7967 8.72071C12.5936 8.97248 12.6147 9.33637 12.8433 9.56486C12.9703 9.6918 13.1459 9.7595 13.3279 9.74892H13.3237Z"
        fill={color}
        stroke={color}
        strokeWidth="0.1"
      />
      <path
        d="M9.51196 13.7869C9.65371 13.6748 9.74046 13.5055 9.74892 13.3256C9.7595 13.1458 9.6918 12.9702 9.56485 12.8411C9.33635 12.6126 8.97243 12.5914 8.71854 12.7945L8.67411 12.8305C8.05842 13.3235 7.34752 13.6261 6.62182 13.7065C5.42852 13.8398 4.2585 13.4314 3.41431 12.5851C2.568 11.7387 2.15966 10.5707 2.29295 9.37733C2.37335 8.65157 2.67802 7.94062 3.171 7.32489L3.20697 7.28045C3.41008 7.02866 3.38892 6.66472 3.16042 6.4362C3.04194 6.31771 2.87902 6.25 2.71188 6.25C2.69918 6.25 2.6886 6.25 2.67591 6.25C2.49395 6.26058 2.32892 6.34522 2.21467 6.48698L2.19986 6.50603C1.49742 7.38413 1.08697 8.40189 1.01291 9.44716C0.907125 10.9495 1.45511 12.42 2.51722 13.4822C3.57934 14.5444 5.0498 15.0946 6.552 14.9866C7.59719 14.9126 8.61487 14.5021 9.49291 13.7996L9.50984 13.7848L9.51196 13.7869Z"
        fill={color}
        stroke={color}
        strokeWidth="0.1"
      />
      <path
        d="M9.75 6.77585C9.75 6.63563 9.69575 6.50416 9.596 6.40425C9.4945 6.30259 9.35975 6.25 9.225 6.25C9.09025 6.25 8.9555 6.30083 8.854 6.40425L6.404 8.85824C6.30425 8.95815 6.25 9.08961 6.25 9.22984C6.25 9.37007 6.30425 9.50153 6.404 9.60145C6.60175 9.79952 6.94825 9.79952 7.146 9.60145L9.596 7.14746C9.69575 7.04755 9.75 6.91608 9.75 6.77585Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default LinkIcon;
