//ToolTip Text
export const QUALITY_SCORE_TOOLTIP_TEXT =
  "A score between 1 and 100 that reflects the overall health of the Dataset";
export const SEVERITY_TOOLTIP_TEXT =
  "How painful a certain issue is in terms of the impact on image quality";
export const ISSUES_TOOLTIP_TEXT =
  "Problems affecting different images or objects, when objects in an image contain issues, that image is counted as being affected";
export const ISSUE_TYPE_TOOLTIP_TEXT =
  "The kind of quality problem this Issue represents";
export const IMAGE_CLASS_DISTRIBUTION_TOOLTIP_TEXT =
  "How many images from each class are available in the dataset";
export const OBJECT_CLASS_DISTRIBUTION_TOOLTIP_TEXT =
  "How many objects from each class are available in the dataset";
export const MISLABELED_IMAGE_TOOLTIP_TEXT =
  "Images that have been assigned incorrect or inaccurate labels";
export const MISLABELED_OBJECT_TOOLTIP_TEXT =
  "Objects that have been assigned incorrect or inaccurate labels";
export const DUPLICATE_IMAGE_TOOLTIP_TEXT =
  "Images that appear in a highly similar form more than once (I.e., same image, twice or more)";
export const DUPLICATE_OBJECT_TOOLTIP_TEXT =
  "Objects that appear in a highly similar form more than once";
export const OUTLIER_IMAGE_TOOLTIP_TEXT =
  "Images that deviate significantly from the majority of images in a dataset";
export const OUTLIER_OBJECT_TOOLTIP_TEXT =
  "Objects that deviate significantly from the majority of images in a dataset";
export const BLURRY_IMAGE_TOOLTIP_TEXT =
  "Images that lack sharpness or clarity";
export const BLURRY_OBJECT_TOOLTIP_TEXT =
  "Objects that lack sharpness or clarity";
export const DARK_IMAGE_TOOLTIP_TEXT =
  "Images that have low brightness levels, often resulting in an overall dark appearance";
export const DARK_OBJECT_TOOLTIP_TEXT =
  "Objects that have low brightness levels, often resulting in an overall dark appearance";
export const BRIGHT_IMAGE_TOOLTIP_TEXT =
  "Images that have high brightness levels, resulting in an overall bright or overexposed appearance";
export const BRIGHT_OBJECT_TOOLTIP_TEXT =
  "Objects that have high brightness levels, resulting in an overall bright or overexposed appearance";
export const IMAGE_LABELS_TOOLTIP_TEXT =
  "A short phrase or a word used to describe the essence or salient object in an image";
export const OBJECT_LABELS_TOOLTIP_TEXT =
  "A short phrase or a word used to describe the object in a region of interest";
export const VL_ENRICHMENT_TOOLTIP_TEXT =
  "Visual Layer can automatically identify and enrich your data with relevant objects and keywords";
export const QUALITY_TOOLTIP_TEXT =
  "Visual Layer automatically detects potential quality issues with your data or annotations";
export const USER_TAGS_TOOLTIP_TEXT =
  "If you found an interesting Image, you can use User Tags to organize and classify it";
export const META_DATA_TOOLTIP_TEXT =
  "Here you will find the annotations that were imported with the data, along with the list of videos available";

export const QUOTA_LIMIT_REACHED_TOOLTIP_TEXT =
  "You have utilized all available images in your free plan. Upgrade now to access more images!";
