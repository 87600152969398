import { useLocation, useNavigate } from "react-router";
import { useState, useEffect, useMemo } from "react";
import { Tree } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";

import { DATA_PAGE } from "helpers/constants/pages";
import { BRIGHT_BLUE } from "helpers/constants/colors";
import VLAccordian from "views/components/Accordian";
import {
  FOLDER_PARAMETER,
  PAGE_PARAMETER,
} from "helpers/constants/miscellaneous";

import { FilterBehavior, FilterOption, TreeData } from "types";

import QuestionIcon from "assets/icons/QuestionIcon";
import SearchIcon from "assets/icons/SearchIcon";
import XIcon from "assets/icons/XIcon";
import styles from "./style.module.scss";
import "./tree.css";

import { fetchTreeFolder } from "redux/SingleDataset/actions";
import { trackFilterSelection } from "helpers/utility/amplitude";
import { debounceCreator } from "helpers/utility/utilities";

const { DirectoryTree } = Tree;

const NoMatchComponent = () => {
  return (
    <div className={styles.noSearchDataBox}>
      <QuestionIcon />
      <span className={styles.noSearchDataText}>No matches found</span>
      <span className={styles.noSearchDataText}>Please try another term</span>
    </div>
  );
};

function FolderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H8L10 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2ZM2 14H18V4H9.175L7.175 2H2V14Z"
        fill="white"
      />
    </svg>
  );
}

function FolderFilterContent({
  treeData,
  selectedFolder,
  onSelectFolder,
  entityTypeFilter,
}: FolderFilterContentProps) {
  const [checkedKeys, setCheckedKeys] = useState(
    !!selectedFolder ? [selectedFolder] : []
  );
  const [expandedKeys, setExpandedKeys] = useState(checkedKeys);
  const [searchValue, setSearchValue] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const filterTreeData = (data: any) => {
    return data
      .filter((item: any) => {
        const itemText = item.title.toLowerCase();
        const searchText = searchValue.toLowerCase();
        return (
          itemText.includes(searchText) ||
          (item.children && filterTreeData(item.children).length > 0)
        );
      })
      .map((filteredItem: any) => {
        if (filteredItem.children) {
          return {
            ...filteredItem,
            children: filterTreeData(filteredItem.children),
          };
        }
        return filteredItem;
      });
  };

  const getExpandedKeys = (data: any, searchText: string): string[] => {
    const keys: string[] = [];
    data.forEach((item: any) => {
      const itemText = item.title.toLowerCase();
      const lowerSearchText = searchText.toLowerCase();
      if (itemText.includes(lowerSearchText)) {
        keys.push(item.key);
        if (item.children) {
          keys.push(...getExpandedKeys(item.children, searchText));
        }
      } else if (item.children) {
        const childKeys = getExpandedKeys(item.children, searchText);
        if (childKeys.length > 0) {
          keys.push(item.key);
          keys.push(...childKeys);
        }
      }
    });

    return keys;
  };

  const onCheck = debounceCreator((e: any) => {
    let selection = null;
    if (e.checked.length > 0) {
      selection = e.checked.slice(-1)[0];
      setCheckedKeys(e.checked.slice(-1));
    } else {
      setCheckedKeys(e.checked);
    }

    if (selection) {
      trackFilterSelection("Files", "Dataset", entityTypeFilter, "Added");
    } else {
      trackFilterSelection("Files", "Dataset", entityTypeFilter, "Removed");
    }

    onSelectFolder(selection);
  }, 500);

  const onExpand = (expandedKeysValue: any, e: any) => {
    e.nativeEvent.preventDefault();
    setExpandedKeys(expandedKeysValue);
  };

  const clearSearch = (e: any) => {
    e.stopPropagation();
    setSearchValue("");
  };

  const clearSelection = (e: any) => {
    e.stopPropagation();
    setSearchValue("");
    setCheckedKeys([]);
    setExpandedKeys([]);

    searchParams.delete(FOLDER_PARAMETER);
    searchParams.set(PAGE_PARAMETER, "1");
    navigate(location.pathname + `?${searchParams.toString()}`);
  };

  const onSearch = (e: any) => {
    const { value } = e.target;
    if (value) {
      const keys = getExpandedKeys(treeData, value);

      setExpandedKeys(keys);
      setSearchValue(value);
    } else {
      setExpandedKeys(checkedKeys);
      setSearchValue("");
    }
  };

  const filteredTreeData = filterTreeData(treeData);
  return (
    <div className={styles.dropdown}>
      <div className={styles.searchBarContainer}>
        {!searchValue && (
          <div className={styles.searchIcon}>
            <SearchIcon />
          </div>
        )}
        <input
          type="search"
          autoComplete="off"
          onChange={onSearch}
          value={searchValue}
          className={styles.searchBarInput}
        />
        {searchValue && (
          <div className={styles.clearSearchIcon} onClick={clearSearch}>
            <XIcon />
          </div>
        )}
      </div>
      {checkedKeys.length > 0 && (
        <div className={styles.clearSelectionButton} onClick={clearSelection}>
          Clear Selection
        </div>
      )}
      <div className={styles.horizontalRule}></div>
      {!!treeData && (
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <DirectoryTree
            checkable
            checkStrictly
            checkedKeys={checkedKeys}
            expandedKeys={expandedKeys}
            expandAction={"doubleClick"}
            selectable={false}
            onCheck={onCheck}
            onExpand={(arr: any, e: any) => onExpand(arr, e)}
            treeData={filteredTreeData}
          />
          {searchValue && filteredTreeData.length < 1 && <NoMatchComponent />}
        </ErrorBoundary>
      )}
    </div>
  );
}

function FolderFilter({
  datasetId,
  treeData,
  onSelect,
  selectedFolder,
  behavior,
  entityTypeFilter,
}: FolderFilterProps) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTreeFolder(datasetId) as any);
    //eslint-disable-next-line
  }, [datasetId]);
  const onSelectFolder = (path: string) => {
    onSelect(path);
  };
  const title = selectedFolder ? "Folder Selected" : "Source File/Folder";
  return (
    <VLAccordian
      title={title}
      page={DATA_PAGE}
      customWidth={"max-content"}
      hideInfo={true}
      initiallyClosed={true}
      fontSize={"13px"}
      bgColor={!!selectedFolder ? BRIGHT_BLUE : "#151928"}
      borderRadius="5px"
      padding={"0 10px"}
      height="40px"
      border={!!selectedFolder ? "1px solid #fff" : undefined}
      type="folderFilter"
      behavior={behavior}
      tooltipContent={
        !!selectedFolder
          ? selectedFolder
          : `Apply this filter to display only the data entries from a specific file or folder.`
      }
      tooltipID="data-page-entity-type-filter-box"
      tooltipWidth="265px"
      icon={!!selectedFolder ? <FolderIcon /> : null}
      customIconMarginRight="4px"
    >
      <FolderFilterContent
        treeData={treeData}
        onSelectFolder={onSelectFolder}
        selectedFolder={selectedFolder}
        entityTypeFilter={entityTypeFilter}
      />
    </VLAccordian>
  );
}

export default FolderFilter;

interface FolderFilterProps {
  datasetId: string;
  treeData: TreeData[] | null;
  onSelect: Function;
  selectedFolder: string;
  behavior?: FilterBehavior;
  entityTypeFilter: any;
}
interface FolderFilterContentProps {
  treeData: TreeData[] | null;
  selectedFolder: string;
  entityTypeFilter: FilterOption[];
  onSelectFolder: (path: string) => void;
}
