const ArrowIcon = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
    >
      <path
        d="M9.70357 0.51666C10.3798 1.2024 10.3845 2.3123 9.69889 3.0004L5.96207 6.74486L17.2568 6.74486C18.2185 6.74486 19 7.53192 19 8.50044C19 9.46896 18.2185 10.2537 17.2568 10.2537L5.96207 10.2537L9.69889 14.0028C10.3845 14.6862 10.3798 15.7985 9.70357 16.4842C9.02734 17.17 7.92291 17.1723 7.23966 16.4889L0.512438 9.74467C0.510098 9.74231 0.510098 9.74231 0.510098 9.74231C0.350985 9.58207 0.22229 9.38884 0.135714 9.17675C0.0467977 8.96467 -3.6133e-07 8.73373 -3.71528e-07 8.50044C-3.81828e-07 8.26479 0.0467976 8.03386 0.135714 7.82177C0.22229 7.61205 0.350985 7.42117 0.507758 7.26093C0.510098 7.25857 0.510098 7.25622 0.512438 7.25622L7.23965 0.511947C7.92291 -0.171433 9.02734 -0.171433 9.70357 0.51666Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowIcon;
