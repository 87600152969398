import { useEffect, useRef } from "react";
import { DataSources, SetStateFunction } from "types";

import S3Banner from "./S3";

const InputBannerWrapper = ({
  showInputBanner,
  setShowInputBanner,
}: InputBannerWrapperProps) => {
  const bannerRef = useRef<HTMLDivElement>(null);

  const closeInputBanner = () => {
    setShowInputBanner(null);
  };

  const handleOutsideClick = (event: any) => {
    const banner = bannerRef.current;

    if (banner && !banner.contains(event.target)) {
      closeInputBanner();
    }
  };

  const renderInputBanner = () => {
    switch (showInputBanner!) {
      case DataSources.S3:
        return (
          <S3Banner bannerRef={bannerRef} closeBanner={closeInputBanner} />
        );

      default:
        return <noscript />;
    }
  };

  useEffect(() => {
    if (showInputBanner) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [showInputBanner]);

  return showInputBanner ? renderInputBanner() : <noscript />;
};

export default InputBannerWrapper;

interface InputBannerWrapperProps {
  showInputBanner: DataSources | null;
  setShowInputBanner: SetStateFunction<DataSources | null>;
}
