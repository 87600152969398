import VLAccordian from "views/components/Accordian";
import { FilterItemCountsWithSelect } from "types";
import { BiShieldPlus } from "react-icons/bi";
import { GREY } from "helpers/constants/colors";
import { useState } from "react";
import Content from "../Content";

import styles from "../style.module.scss";

function NoQualityData() {
  return (
    <div className={styles.noDataWrapper}>
      <div className={styles.noData}>
        Currently no Issue Types available in this cluster
      </div>
    </div>
  );
}

const QualitySectionContent = ({
  issueTypes,
  sectionHeight,
}: {
  issueTypes: Array<FilterItemCountsWithSelect>;
  sectionHeight: any;
}) => {
  const [selectedID, setSelectedID] = useState(null);
  return (
    <div
      className={styles.section}
      style={{ height: !!sectionHeight ? sectionHeight : "max-content" }}
    >
      <Content
        title={"Issue Types"}
        totalCount={issueTypes.length}
        type={"issue-types"}
        data={issueTypes}
        sectionType={"quality"}
        id={"quality-1"}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
      />
    </div>
  );
};

const QualitySection = ({
  issueTypes,
  qualityRef,
  qualityCategory,
  sectionHeight,
  isLoading,
}: {
  issueTypes: Array<FilterItemCountsWithSelect>;
  qualityRef: any;
  qualityCategory: any;
  sectionHeight: any;
  isLoading: boolean;
}) => {
  const handleDisplay = () => {
    if (isLoading) {
      return <div className={styles.loadingBox}>Loading...</div>;
    } else if (issueTypes.length > 0) {
      return (
        <QualitySectionContent
          issueTypes={issueTypes}
          sectionHeight={sectionHeight}
        />
      );
    } else return <NoQualityData />;
  };
  return (
    <div className={styles.qualityWrapper} ref={qualityRef}>
      <VLAccordian
        title="Quality"
        icon={<BiShieldPlus size="20px" color={GREY} />}
        childrenBgColor={"#151928"}
        roundCorners="10px"
        borderRadius="10px 10px 0 0"
        padding="0 15px 0 13px"
        height="47px"
        category={qualityCategory}
        initiallyClosed={false}
      >
        {handleDisplay()}
      </VLAccordian>
    </div>
  );
};

export default QualitySection;
