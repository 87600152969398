export default function DangerCircleIcon({
  color,
  variant,
}: DangerCircleIconProps) {
  const renderSVG = () => {
    switch (variant) {
      case "outline":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.25 1C15.358 1 19.5 5.141 19.5 10.25C19.5 15.358 15.358 19.5 10.25 19.5C5.141 19.5 1 15.358 1 10.25C1 5.141 5.141 1 10.25 1Z"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.2441 6.4541V10.8731"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.245 14.0459H10.255"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case "filled":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 9.5C0 4.25695 4.256 0 9.5 0C14.7535 0 19 4.25695 19 9.5C19 14.7449 14.7535 19 9.5 19C4.256 19 0 14.7449 0 9.5ZM8.66406 5.89952C8.66406 5.44447 9.04406 5.06352 9.50006 5.06352C9.95606 5.06352 10.3266 5.44447 10.3266 5.89952V10.0985C10.3266 10.5555 9.95606 10.925 9.50006 10.925C9.04406 10.925 8.66406 10.5555 8.66406 10.0985V5.89952ZM9.50983 13.9467C9.04433 13.9467 8.67383 13.5667 8.67383 13.1107C8.67383 12.6547 9.04433 12.2842 9.50033 12.2842C9.96583 12.2842 10.3363 12.6547 10.3363 13.1107C10.3363 13.5667 9.96583 13.9467 9.50983 13.9467Z"
              fill={color}
            />
          </svg>
        );
    }
  };

  return renderSVG();
}

interface DangerCircleIconProps {
  variant: "outline" | "filled";
  color: string;
}
