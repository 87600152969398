import { useRef } from "react";
import { BiSolidXCircle } from "react-icons/bi";

import { SimpleImageBox } from "views/components/CardBodyBoxes/ImageBox";

import styles from "./style.module.scss";

export const ImageFileCard = ({
  filename,
  imageSrc,
  handleRemove,
  cardElementRef,
}: ImageFileCardProps) => {
  const title = `Image: ${filename}`;
  const titleRef = useRef<HTMLSpanElement>(null);
  const titleContainerRef = useRef<HTMLDivElement>(null);

  const handleRemoveButtonClick = (e: any) => {
    e.stopPropagation();
    handleRemove();
  };

  return (
    <div
      className={styles.singleClusterWrapper}
      ref={cardElementRef}
      id="clusterCardWrapper"
    >
      <div className={styles.dataCard}>
        <div className={styles.cardHead} title={title}>
          <div
            className={styles.cardHeadTitleContainer}
            ref={titleContainerRef}
          >
            <div className={styles.leftSection}>
              <span ref={titleRef} className={styles.titleName}>
                {title}
              </span>
            </div>
            <div
              className={styles.vertexButton}
              onClick={handleRemoveButtonClick}
            >
              Remove <BiSolidXCircle size="16px" />
            </div>
          </div>
        </div>
        <div className={styles.cardBody}>
          <SimpleImageBox image={imageSrc} />
        </div>
      </div>
    </div>
  );
};

export interface ImageFileCardProps {
  filename: string;
  imageSrc: string;
  handleRemove: () => void;
  cardElementRef: any;
}
