import styles from "./style.module.scss";

type SingleRowProps = {
  imageSrc: string;
  title: string;
  description: string;
};

const SingleRow = ({ imageSrc, title, description }: SingleRowProps) => {
  return (
    <div className={styles.singleRow}>
      <div className={styles.rowIconContainer}>
        <img src={imageSrc} alt="title-icon" />
      </div>
      <div className={styles.rowTextContainer}>
        <span className={styles.title}>{title}</span>
        <span className={styles.description}>{description}</span>
      </div>
    </div>
  );
};
export default SingleRow;
