import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import { State } from "redux/store";
import dangerIcon from "assets/img/danger.svg";
import arrowUp from "assets/img/arrowUp.svg";
import {
  getFilesUploadProgress,
  getFilesUploadSize,
  getPreviewDataSource,
  getUploadStatus,
} from "redux/CreateDataset/selectors";
import { DataSources, FilesUploadSize, StepTileProgressStatus } from "types";
import { formatSize } from "helpers/utility/utilities";
import {
  setFilesUploadProgress,
  setFilesUploadSize,
} from "redux/CreateDataset/actions";
import { formatNumberWithCommas } from "helpers/utility/formatters";

type UploadProgressProps = {
  uploadSize: FilesUploadSize | null;
  uploadProgress: number | null;
  uploadStatus: StepTileProgressStatus;
  previewDataSource: DataSources | null;
};

const UploadProgress: React.FC<UploadProgressProps> = ({
  uploadSize,
  uploadProgress,
  uploadStatus,
  previewDataSource,
}) => {
  const dispatch = useDispatch();
  let showProgress = true;

  if (previewDataSource === DataSources.FOLDER) {
    // For folder upload we will show the progress bar only when the upload state is in progress
    showProgress = uploadStatus === StepTileProgressStatus.IN_PROGRESS;
  }
  useEffect(() => {
    if (uploadProgress === 100) {
      setTimeout(() => {
        dispatch(setFilesUploadProgress(null));
        dispatch(setFilesUploadSize(null));
      }, 3000);
    }
  }, [uploadProgress, dispatch]);
  if (!showProgress || uploadSize === null || uploadProgress === null) {
    return <noscript />;
  }
  let progressLabel = `Uploading ${formatSize(uploadSize.bytes)} `;
  if (previewDataSource === DataSources.FOLDER) {
    if (uploadSize.length === 1) {
      progressLabel =
        progressLabel + `(${formatNumberWithCommas(uploadSize.length)} Image)`;
    } else {
      progressLabel =
        progressLabel + `(${formatNumberWithCommas(uploadSize.length)} Images)`;
    }
  } else if (previewDataSource === DataSources.ZIP) {
    progressLabel = progressLabel + `(1 ZIP file)`;
  }

  return (
    <div className={styles.uploadProgressContainer}>
      <img
        src={dangerIcon}
        alt="danger-icon"
        className={styles.uploadProgressWarningIcon}
      />
      <div className={styles.uploadProgressHeader}>
        <div className={styles.uploadProgressHeaderTitle}>
          Uploading Your Data
        </div>
        <div className={styles.uploadProgressHeaderSubTitle}>
          Please wait while we process your files, do not navigate outside this
          tab.
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.uploadProgressBody}>
        <div className={styles.uploadProgressBodyInfo}>
          <img src={arrowUp} alt="arrow-up" className={styles.uploadIcon} />
          <div className={styles.uploadProgressInfo}>{progressLabel}</div>
          <div className={styles.uploadProgressPrecentage}>
            {Math.ceil(uploadProgress)}% done
          </div>
        </div>
        <div
          className={styles.uploadProgressBar}
          style={{
            width: `calc(max(${Math.ceil(uploadProgress)}% - 1em, 6px))`,
          }}
        ></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  uploadSize: getFilesUploadSize(state),
  uploadProgress: getFilesUploadProgress(state),
  uploadStatus: getUploadStatus(state),
  previewDataSource: getPreviewDataSource(state),
});

export default connect(mapStateToProps)(UploadProgress);
