import * as amplitude from "@amplitude/analytics-browser";
import { DataSources, FilterOption } from "types";
import Cookies from "js-cookie";
import {
  AMP_DATASET_CREATION_PROPERTY__CREATED_AT,
  AMP_DATASET_CREATION_PROPERTY__CREATED_BY,
  AMP_DATASET_CREATION_PROPERTY__DATASET_STATUS,
  AMP_DATASET_CREATION_PROPERTY__ERROR_MESSAGE,
  AMP_DATASET_CREATION_PROPERTY__UPLOAD_SOURCE,
  AMP_DATASET_EXPLORATION_PROPERTY__CONTEXT,
  AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE,
  AMP_DATASET_EXPLORATION_PROPERTY__FILTER_TYPE,
  AMP_DATASET_ID,
  AMP_DATASET_NAME,
  AMP_IMAGE_COUNT,
} from "helpers/constants/amplitudeProperties";
import {
  AMP_DATASET_EXPLORATION_EVENT__FILTER__APPLIED,
  AMP_DATASET_EXPLORATION_EVENT__FILTER__REMOVED,
  AMP_DATASET_INGESTION_EVENT__ANNOTATIONS_ADDED,
  AMP_DATASET_INGESTION_EVENT__ANNOTATIONS_UPDATED,
  AMP_DATASET_INGESTION_EVENT__DATASET_CREATION_FAILED,
  AMP_DATASET_INGESTION_EVENT__SOURCE_ADDED,
  AMP_DATASET_INGESTION_EVENT__SOURCE_UPDATED,
} from "helpers/constants/amplitudeEvents";
import { State, store } from "redux/store";
import { isFastdupUser } from "./utilities";

export const initAmplitude = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "PROD" || isFastdupUser()) {
    const key = process.env.REACT_APP_AMPLITUDE_KEY;
    if (!key) return;

    amplitude.init(key);
    if (isFastdupUser()) {
      amplitude.setGroup("environment", "local");
    }
    if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
      amplitude.setGroup("environment", "cloud");
    }
  }
};

export const trackUserEmail = (email: string) => {
  amplitude.setUserId(email);
};

export async function amplitudeTrack(
  eventName: string,
  eventProperties?: object
) {
  let userEmail;

  const user_origin = Cookies.get("user_origin");
  userEmail = store.getState()?.user?.userInfo?.email;

  if (!userEmail && (user_origin === "system" || user_origin === undefined)) {
    userEmail = "Anonymous";
  }

  if (process.env.REACT_APP_ENVIRONMENT === "PROD" || isFastdupUser()) {
    const updatedEventProperties = {
      ...eventProperties,
      email: userEmail,
      environment: isFastdupUser() ? "Local" : "Cloud",
    };

    amplitude.track(eventName, updatedEventProperties);
  }
}

export function trackFilterSelection(
  type: "Labels" | "Files" | "Issue Types" | "User Tags",
  context: "Dataset" | "Metadata Summary",
  entityTypeFilter: FilterOption[],
  changeType: "Added" | "Removed"
) {
  const selectedEntities = entityTypeFilter
    .filter((entity) => entity.selected)
    .map((entity) => entity.text);
  let value;
  if (selectedEntities.length > 0) {
    value = selectedEntities.join(",");
  } else {
    value = "Images";
  }
  const properties = {
    [AMP_DATASET_EXPLORATION_PROPERTY__FILTER_TYPE]: type,
    [AMP_DATASET_EXPLORATION_PROPERTY__CONTEXT]: context,
    [AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE]: value,
  };
  const event =
    changeType === "Added"
      ? AMP_DATASET_EXPLORATION_EVENT__FILTER__APPLIED
      : AMP_DATASET_EXPLORATION_EVENT__FILTER__REMOVED;

  amplitudeTrack(event, properties);
}

// Tracks dataset ingestion events to Amplitude.
export function trackNewDatasetIngestionEvent(
  eventName: string,
  properties?: any
) {
  const state: State = { ...store.getState() };
  const newDataset = { ...state.createDataset.newDataset };

  const {
    id,
    status,
    n_images,
    created_at,
    created_by,
    display_name,
    fatal_error_message,
  } = newDataset;

  let datasetProperties: any = {
    [AMP_DATASET_ID]: id,
    [AMP_DATASET_NAME]: display_name,
    [AMP_IMAGE_COUNT]: n_images > 0 ? n_images : 0,
    [AMP_DATASET_CREATION_PROPERTY__CREATED_BY]: created_by,
    [AMP_DATASET_CREATION_PROPERTY__CREATED_AT]: created_at,
    [AMP_DATASET_CREATION_PROPERTY__DATASET_STATUS]: status,
  };

  if (fatal_error_message) {
    datasetProperties = {
      ...datasetProperties,
      [AMP_DATASET_CREATION_PROPERTY__ERROR_MESSAGE]: fatal_error_message,
    };
  }

  if (properties) {
    datasetProperties = { ...datasetProperties, ...properties };
  }

  amplitudeTrack(eventName, datasetProperties);
}

export const trackDataSourceAdded = (
  isCalledAgain: boolean,
  source: DataSources
) => {
  const eventName = isCalledAgain
    ? AMP_DATASET_INGESTION_EVENT__SOURCE_UPDATED
    : AMP_DATASET_INGESTION_EVENT__SOURCE_ADDED;

  trackNewDatasetIngestionEvent(eventName, {
    [AMP_DATASET_CREATION_PROPERTY__UPLOAD_SOURCE]: source,
  });
};

export const trackAnnotation = (isCalledAgain: boolean) => {
  const eventName = isCalledAgain
    ? AMP_DATASET_INGESTION_EVENT__ANNOTATIONS_UPDATED
    : AMP_DATASET_INGESTION_EVENT__ANNOTATIONS_ADDED;

  trackNewDatasetIngestionEvent(eventName);
};

export const trackFatalError = (
  reason: string,
  dataSource: DataSources | null
) => {
  trackNewDatasetIngestionEvent(
    AMP_DATASET_INGESTION_EVENT__DATASET_CREATION_FAILED,
    {
      [AMP_DATASET_CREATION_PROPERTY__ERROR_MESSAGE]: reason,
      [AMP_DATASET_CREATION_PROPERTY__UPLOAD_SOURCE]: dataSource || "",
    }
  );
};
