import { determineFileType } from "helpers/utility/utilities";
import ObjectIcon from "assets/icons/ObjectIcon";
import TagIcon from "assets/icons/tagIcon";
import ImagePreview from "./ImagePreview";
import { DataPreview } from "types";

import styles from "./style.module.scss";

const PreviewCard = ({ preview }: PreviewCardProps) => {
  const imageLabels = preview.image_annotations || [];
  const objectLabels = preview.object_annotations || [];

  const fileType = determineFileType(preview.file_name);

  const renderLabels = (labels: any[], type: "image" | "object") => {
    const limitedLabels = labels.slice(0, 3);

    const emptyMessage =
      type === "image"
        ? "Labels will be displayed here"
        : "Objects will be displayed here";

    const renderLabel = (label: any) => {
      const dynamicLabel =
        type === "image"
          ? label
          : label.annotations
          ? label.annotations[0]
          : "";
      return <span>{dynamicLabel}</span>;
    };

    return limitedLabels.length > 0 ? (
      <div className={styles.labels}>
        {limitedLabels.map((label, index) => (
          <div className={styles.singleLabel} key={index}>
            {renderLabel(label)}
          </div>
        ))}
      </div>
    ) : (
      <span className={styles.rowTitle}>{emptyMessage}</span>
    );
  };

  return (
    <div className={styles.previewCard}>
      <div className={styles.previewCardHead}>
        <div className={styles.previewCardHeadRow}>
          <div className={styles.rowIcon}>
            <ObjectIcon color="#60646E" />
          </div>
          {renderLabels(objectLabels, "object")}
        </div>
        <div className={styles.previewCardHeadRow}>
          <div className={styles.rowIcon}>
            <TagIcon color="#60646E" />
          </div>
          {renderLabels(imageLabels, "image")}
        </div>
      </div>
      <div className={styles.previewCardBody}>
        <ImagePreview
          src={preview.thumbnail}
          boundingBox={objectLabels}
          fileType={fileType}
        />
      </div>
    </div>
  );
};

export default PreviewCard;

interface PreviewCardProps {
  preview: DataPreview;
}
