import { useState } from "react";
import classNames from "classnames";
import ObjectsBox from "../Objects";

import TooltipWrapper from "views/uikit/TooltipWrapper";
import LinkIcon from "assets/icons/LinkIcon";

import styles from "./style.module.scss";

const GeneralBox = ({
  img,
  imageLabels,
  objectLables,
  selectedLabels,
}: any) => {
  const [isCopied, setIsCopied] = useState(false);

  const imgInfo = {
    Height: img?.height,
    Width: img?.width,
    "File size": img?.file_size_display_value,
    "Mime type": img?.mime_type,
  };

  const copyIconClassnames = classNames(styles.copyIcon, {
    [styles.defaultCursor]: isCopied,
  });

  const groupedInfo: { key: string; value: string | number }[][] =
    Object.entries(imgInfo).reduce(
      (
        acc: { key: string; value: string | number }[][],
        [key, value],
        index
      ) => {
        const pairIndex = Math.floor(index / 2);
        acc[pairIndex] = acc[pairIndex] || [];
        acc[pairIndex].push({ key, value });
        return acc;
      },
      []
    );

  const isSmalltext = (key: string) => {
    let value = key?.toLowerCase();
    switch (value) {
      case "file name":
        return true;
      case "file size":
        return true;
      case "height":
        return true;
      default:
        return false;
    }
  };

  const handleCopyUrlClick = () => {
    setIsCopied(true);
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(img?.original_uri)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 3000);
        })
        .catch((err) => console.error("Could not copy text: ", err));
    }
  };

  return (
    <div className={styles.generalContainer}>
      {img && (
        <div className={styles.infoContainer}>
          <div className={styles.infoRow}>
            <div className={styles.pathPair}>
              <span className={styles.path}>Path</span>
              <span className={styles.pathText} title={img?.original_uri}>
                <span className={styles.imageUri}>{img?.original_uri}</span>

                <div
                  className={copyIconClassnames}
                  data-tooltip-id={"image-view-url-button-tooltip"}
                  data-tooltip-content={isCopied ? "Copied!" : "Copy URL"}
                  title=""
                  onClick={handleCopyUrlClick}
                >
                  <LinkIcon color={"#fff"} clicked={isCopied} />

                  <TooltipWrapper id="image-view-url-button-tooltip" />
                </div>
              </span>
            </div>
          </div>
          {groupedInfo.map((pair, index) => (
            <div className={styles.infoRow} key={index}>
              {pair.map(({ key, value }, index) => (
                <div className={styles.pair} key={index}>
                  <span
                    className={styles.infoTitle}
                    style={
                      isSmalltext(key) ? { width: "60px" } : { width: "76px" }
                    }
                  >
                    {key}
                  </span>
                  <span className={styles.infoDescription}>{value}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      {imageLabels?.length > 0 && (
        <div className={styles.imageLabelsContainer}>
          <div className={styles.imageLabelTitle}>Image labels</div>
          <div className={styles.imageLabels}>
            {imageLabels?.map((singleLabel: string, index: number) => (
              <div key={index} className={styles.imageLabel}>
                {singleLabel}
              </div>
            ))}
          </div>
        </div>
      )}
      {objectLables && objectLables.length > 0 && (
        <div className={styles.objectLabelsContainer}>
          <div className={styles.objectLabelTitle}>Objects</div>
          <ObjectsBox labels={objectLables} selectedLabels={selectedLabels} />
        </div>
      )}
    </div>
  );
};

export default GeneralBox;
