import { State } from "redux/store";
import classNames from "classnames";
import { ActivityLogShowModalType } from "types";
import { connect, useDispatch } from "react-redux";
import { getShowActivityLogModal } from "redux/CreateDataset/selectors";

import { setShowActivityLogModal } from "redux/CreateDataset/actions";
import DangerCircleIcon from "assets/icons/DangerCircleIcon";

import styles from "./style.module.scss";

const ActivityLogErrorModal = ({ showModal }: ActivityLogErrorProps) => {
  const { show, title, reason, severity } = showModal;
  const dispatch = useDispatch();

  const showWarningColor = severity === "WARNING";

  const containrHeaderTitleClassNames = classNames(styles.containrHeaderTitle, {
    [styles.containrHeaderTitleWarning]: showWarningColor,
  });

  const handleCloseClick = () => {
    dispatch(
      setShowActivityLogModal({
        show: false,
        title: "",
        reason: "",
        severity: "",
      })
    );
  };

  return show ? (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.containrHeader}>
          <DangerCircleIcon
            variant="filled"
            color={showWarningColor ? "#de6e45" : "#D6493E"}
          />
          <span className={containrHeaderTitleClassNames}>{title}</span>
        </div>

        <div className={styles.containrBody}>
          {reason && <div className={styles.reasonTitle}>Reason: {reason}</div>}
          <div className={styles.descriptionBody}>
            For more information please contact{" "}
            <span className={styles.link}>
              <a href="mailto:support@visual-layer.com">
                support@visual-layer.com
              </a>
            </span>
          </div>
        </div>

        <div className={styles.goBackBtn} onClick={handleCloseClick}>
          Close
        </div>
      </div>
    </div>
  ) : (
    <noscript />
  );
};

const mapStateToProps = (state: State) => {
  return { showModal: getShowActivityLogModal(state) };
};

export default connect(mapStateToProps)(ActivityLogErrorModal);

interface ActivityLogErrorProps {
  showModal: ActivityLogShowModalType;
}
