import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { isAnonymousUser, selectUser, userLogout } from "redux/authReducer";
import { PUBLIC_HEADER_MESSAGE } from "helpers/constants/miscellaneous";
import { PRIVATE_DATASETS_PATH } from "helpers/constants/paths";
import { useWindowResize } from "helpers/utility/utilities";
import { clearFilters } from "redux/SingleDataset/actions";

import greyInfoIcon from "assets/img/grey-info-icon.svg";
import styles from "./style.module.scss";

type PublicHeaderProps = {
  isBig: boolean;
};

const PublicHeader = ({ isBig }: PublicHeaderProps) => {
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [dynamicFontSize, setDynamicFontSize] = useState({});

  const { width } = useWindowResize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = selectUser();

  const allowedToGoToPrivateCollection = user && !isAnonymousUser();

  const showSignIn = !user || isAnonymousUser();

  const handlePrivateButtonClick = () => {
    dispatch(clearFilters() as any);
    if (allowedToGoToPrivateCollection) {
      navigate(PRIVATE_DATASETS_PATH);
    } else {
      userLogout();
    }
  };

  useEffect(() => {
    if (width < 1320) {
      setDynamicTitle(PUBLIC_HEADER_MESSAGE);
    } else {
      setDynamicTitle("");
    }

    if (!isBig) {
      if (width < 1200) {
        setDynamicFontSize({ fontSize: "11px" });
        return;
      } else if (width <= 1400 && width >= 1200) {
        setDynamicFontSize({ fontSize: "13px" });
        return;
      }
      setDynamicFontSize({});
    }
    //eslint-disable-next-line
  }, [width]);

  return (
    <div
      className={
        isBig
          ? styles.publicHeaderContainerForInventory
          : styles.publicHeaderContainer
      }
    >
      <div className={styles.infoIconContainer}>
        <img className={styles.infoIcon} src={greyInfoIcon} alt="info-icon" />
      </div>
      <div
        className={styles.textContainer}
        title={dynamicTitle}
        style={dynamicFontSize}
      >
        <span className={styles.mainText}>{PUBLIC_HEADER_MESSAGE}</span>
      </div>

      <div className={styles.buttonContainer}>
        <div
          className={styles.loginButton}
          onClick={handlePrivateButtonClick}
          style={dynamicFontSize}
        >
          Switch to private
        </div>

        {showSignIn && (
          <div
            className={styles.signupButton}
            onClick={handlePrivateButtonClick}
            style={dynamicFontSize}
          >
            Sign Up
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicHeader;
