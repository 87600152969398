import { useMemo, useState } from "react";
import { State } from "redux/store";
import { useLocation, useNavigate, useParams } from "react-router";
import { connect, useDispatch } from "react-redux";
import TooltipWrapper from "views/uikit/TooltipWrapper";
import { amplitudeTrack } from "helpers/utility/amplitude";
import {
  GRANULARITY_PARAMETER,
  PAGE_PARAMETER,
} from "helpers/constants/miscellaneous";
import {
  AMP_DATASET_ID,
  AMP_DATASET_EXPLORATION_PROPERTY__GRANULARITY_LEVEL,
} from "helpers/constants/amplitudeProperties";
import { AMP_DATASET_EXPLORATION_EVENT__GRANULARITY_CHANGED } from "helpers/constants/amplitudeEvents";

import {
  setSimilarityThreshold,
} from "redux/SingleDataset/actions";
import {
  getSimilarityThreshold,
  getSimilarityVertexCluster,
  getThreasholdsRange,
} from "redux/SingleDataset/selectors";
import HighGranularityIcon from "assets/icons/GranularityIcons/HighGranularityIcon";
import LowGranularityIcon from "assets/icons/GranularityIcons/LowGranularityIcon";

import { ImInfo } from "react-icons/im";
import styles from "./style.module.scss";

const GranularityStepIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 14 14"
      fill="none"
    >
      <circle cx="7" cy="7" r="6.5" fill="#151928" stroke="white" />
    </svg>
  );
};

const GranularityStepSelectedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 14 14"
      fill="none"
    >
      <circle cx="7" cy="7" r="6.5" fill="#151928" stroke="white" />

      <circle cx="7" cy="7" r="3.5" fill="white" stroke="white" />
    </svg>
  );
};

const GranularitySlider = ({
  threasholdsRange,
  similarityThreashold,
  isVertexSelected,
}: any) => {
  const [hoveredTooltip, setHoveredTooltip] = useState<number | null>(null);
  const dispatch = useDispatch();
  const { datasetId, clusterId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const handleGranularityChange = async (value: string) => {
    dispatch(setSimilarityThreshold({ threshold: value }));
    searchParams.set(GRANULARITY_PARAMETER, value);
    searchParams.set(PAGE_PARAMETER, "1");
    navigate({ search: `?${searchParams.toString()}` });

    amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__GRANULARITY_CHANGED, {
      [AMP_DATASET_ID]: datasetId,
      [AMP_DATASET_EXPLORATION_PROPERTY__GRANULARITY_LEVEL]: Number(value) + 1, //+1 to start from 1 rather than 0
    });
  };

  const handleTooltipMessage = (index: number): string => {
    const totalThresholds =
      threasholdsRange.maxThreshold - threasholdsRange.minThreshold;

    const areTotalThreasholdsOdd = (totalThresholds - 1) % 2 !== 0;
    const middleThreashold =
      areTotalThreasholdsOdd && Math.floor(totalThresholds / 2);

    switch (index) {
      case 0:
        return "Lower Threshold";
      case totalThresholds:
        return "Higher Threshold";
      case middleThreashold:
        return "Mid Threshold";
      default:
        return "";
    }
  };

  const displayThresholdTooltip = (index: number): JSX.Element | null => {
    const message = handleTooltipMessage(index);

    return message && hoveredTooltip === index ? (
      <div className={styles.threasholdTooltip} key={index}>
        {message}
      </div>
    ) : null;
  };

  if (similarityThreashold) {
    return (
      <div
        className={styles.granularityWrapper}
        style={
          !!clusterId || isVertexSelected ? { visibility: "hidden" } : undefined
        }
      >
        <span className={styles.granularityTitle}>Granularity</span>

        <div
          className={styles.granularityInfoIcon}
          data-tooltip-content={`Control the level of similarity between Images in a Cluster. A higher threshold groups more similar images together, resulting in smaller, more precise clusters.`}
          data-tooltip-id="data-page-granularity-box"
          data-tooltip-place="top"
        >
          <ImInfo size="13px" />
          <TooltipWrapper id="data-page-granularity-box" maxWidth="360px" />
        </div>
        <HighGranularityIcon />
        <div className={styles.inputContainer}>
          <div className={styles.ticks}>
            {[
              ...Array(
                threasholdsRange.maxThreshold -
                  threasholdsRange.minThreshold +
                  1
              ),
            ].map((_: any, index: number) => {
              const stringIndex = "" + index;
              return similarityThreashold === stringIndex ? (
                <div
                  className={styles.selectedtick}
                  key={index}
                  onMouseEnter={() => setHoveredTooltip(index)}
                  onMouseLeave={() => setHoveredTooltip(null)}
                >
                  <GranularityStepSelectedIcon />
                  {displayThresholdTooltip(index)}
                </div>
              ) : (
                <div
                  className={styles.selectedtick}
                  key={index}
                  onClick={() => handleGranularityChange(stringIndex)}
                  onMouseEnter={() => setHoveredTooltip(index)}
                  onMouseLeave={() => setHoveredTooltip(null)}
                >
                  <GranularityStepIcon />
                  {displayThresholdTooltip(index)}
                </div>
              );
            })}
          </div>
        </div>
        <LowGranularityIcon />
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state: State) => {
  return {
    threasholdsRange: getThreasholdsRange(state),
    similarityThreashold: getSimilarityThreshold(state),
    isVertexSelected: !!getSimilarityVertexCluster(state),
  };
};

export default connect(mapStateToProps)(GranularitySlider);
