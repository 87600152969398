import { ChangeEvent, MutableRefObject, useEffect } from "react";
import styles from "./style.module.scss";

const FileDropZone = ({
  label,
  dropRef,
  inputRef,
  inputProps,
  onFileChange,
  onDropEventHandler,
}: FileDropZoneProps) => {
  useEffect(() => {
    const dropRefCurr = dropRef.current;
    const handleDragOver = (event: any) => {
      event.preventDefault();
    };

    let handleDrop: (event: DragEvent) => void;
    if (onDropEventHandler) {
      handleDrop = onDropEventHandler;
    } else {
      handleDrop = (e: DragEvent) => {
        e.preventDefault();
        if (e.dataTransfer?.files?.length && inputRef.current) {
          inputRef.current.files = e.dataTransfer.files;
          const manualEvent = new Event("change", { bubbles: true });
          inputRef.current.dispatchEvent(manualEvent);
        }
      };
    }

    if (dropRefCurr) {
      dropRefCurr.addEventListener("dragover", handleDragOver);
      dropRefCurr.addEventListener("drop", handleDrop);
    }

    return () => {
      if (dropRefCurr) {
        dropRefCurr.removeEventListener("dragover", handleDragOver);
        dropRefCurr.removeEventListener("drop", handleDrop);
      }
    };
  }, [dropRef, inputRef, onDropEventHandler]);

  return (
    <div className={styles.fileDropContainer} ref={dropRef}>
      <span className={styles.dragText}>{label}</span>
      <span
        className={styles.browseText}
        onClick={() => inputRef.current?.click()}
      >
        Browse
      </span>
      <input
        className={styles.fileInput}
        ref={inputRef}
        type="file"
        onChange={onFileChange}
        {...inputProps}
      />
    </div>
  );
};

interface FileDropZoneProps {
  dropRef: MutableRefObject<HTMLDivElement | null>;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  label: string;
  inputProps: Partial<React.InputHTMLAttributes<HTMLInputElement>>;
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDropEventHandler?: (event: DragEvent) => void;
}

export default FileDropZone;
