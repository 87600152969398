import { useEffect, useRef, useState } from "react";
import {
  calculatePlaceHolderCount,
  useWindowResize,
} from "helpers/utility/utilities";
import classNames from "classnames";
import { connect } from "react-redux";

import { State } from "redux/store";
import { getPreviewData } from "redux/CreateDataset/selectors";

import PreviewCard from "./PreviewCard";

import styles from "./style.module.scss";
import { DataPreview } from "types";

const DataViewSection = ({ previewData }: DataViewProps) => {
  const [placeholderCount, setPlaceholderCount] = useState(0);

  const previewContainerRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const { height, width } = useWindowResize();

  const previewSectionClassNames = classNames(styles.previewSection, {
    [styles.hiddenOverflowY]: previewData.length < 1,
  });

  useEffect(() => {
    const extraWidth = 60;

    let tempCount = calculatePlaceHolderCount(
      previewContainerRef.current,
      cardRef.current,
      previewData.length,
      extraWidth
    );
    if (placeholderCount !== tempCount) {
      setPlaceholderCount(tempCount);
    }
  }, [height, width, placeholderCount, previewData.length]);

  return (
    <div className={previewSectionClassNames} ref={previewContainerRef}>
      {previewData.map((preview: DataPreview, index) => {
        return (
          <div
            key={"preview-card-" + index}
            ref={cardRef}
            className={styles.previewCardWrapper}
          >
            <PreviewCard preview={preview} />
          </div>
        );
      })}
      {placeholderCount > 0 &&
        [...Array(placeholderCount)].map((_, index) => (
          <div key={index} className={styles.cardPlaceHolder}></div>
        ))}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return { previewData: getPreviewData(state) };
};

export default connect(mapStateToProps)(DataViewSection);

interface DataViewProps {
  previewData: DataPreview[];
}
