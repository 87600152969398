import { useRef, useState } from "react";

import ChevronIcon from "assets/icons/ChevronIcon";
import styles from "./style.module.scss";

const Carousel = ({ previews, imageIndex, updateAssetData }: CarouselProps) => {
  const [hoverId, setHoverId] = useState<null | number>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const isLastIndex = imageIndex === previews.length - 1;
  const isFirstIndex = imageIndex === 0;

  const handleImageScroll = async (direction: "left" | "right") => {
    if (direction === "left" && isFirstIndex) return;
    if (direction === "right" && isLastIndex) return;

    const newIndex = direction === "left" ? imageIndex - 1 : imageIndex + 1;
    const newImg = previews[newIndex];

    updateAssetData(newImg.media_id, newImg.media_uri);
  };

  const handleImageSelect = async (index: any) => {
    if (index === imageIndex) return;

    const newImg = previews[index];

    updateAssetData(newImg.media_id, newImg.media_uri);
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carousel}>
        <div
          className={styles.scrollButton}
          onClick={() => handleImageScroll("left")}
        >
          <ChevronIcon directon="left" />
        </div>

        <div className={styles.carouselBody}>
          <div className={styles.carouselImages}>
            {previews.map((image: any, index: number) => {
              const isSelected = index === hoverId || index === imageIndex;
              const imageSource =
                image.type === "IMAGE"
                  ? image.media_uri
                  : image.media_thumb_uri;
              return (
                <div
                  className={styles.carouselImageContainer}
                  ref={index === imageIndex ? containerRef : undefined}
                  key={index}
                  onMouseEnter={() => setHoverId(index)}
                  onMouseLeave={() => setHoverId(null)}
                  onClick={() => handleImageSelect(index)}
                >
                  <img
                    className={styles.carouselImage}
                    src={imageSource}
                    alt={image.file_name}
                    loading="lazy"
                  />
                  {isSelected && <div className={styles.hoveredImage}></div>}
                </div>
              );
            })}
          </div>
        </div>

        <div
          className={styles.scrollButton}
          onClick={() => handleImageScroll("right")}
        >
          <ChevronIcon directon="right" />
        </div>
      </div>
    </div>
  );
};

export default Carousel;

interface CarouselProps {
  previews: any[];
  imageIndex: number;
  updateAssetData: (id: string, source: string) => void;
}
