const SearchIcon = ({ color = "#fff" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M13.5728 15L10.2794 11.7C9.78039 12.1 9.20654 12.4167 8.55784 12.65C7.90914 12.8833 7.21886 13 6.487 13C4.67396 13 3.13954 12.3708 1.88372 11.1125C0.627908 9.85417 0 8.31667 0 6.5C0 4.68333 0.627908 3.14583 1.88372 1.8875C3.13954 0.629167 4.67396 0 6.487 0C8.30003 0 9.83445 0.629167 11.0903 1.8875C12.3461 3.14583 12.974 4.68333 12.974 6.5C12.974 7.23333 12.8576 7.925 12.6247 8.575C12.3918 9.225 12.0758 9.8 11.6766 10.3L14.97 13.6L13.5728 15ZM6.487 11C7.73449 11 8.79487 10.5625 9.66812 9.6875C10.5414 8.8125 10.978 7.75 10.978 6.5C10.978 5.25 10.5414 4.1875 9.66812 3.3125C8.79487 2.4375 7.73449 2 6.487 2C5.2395 2 4.17912 2.4375 3.30587 3.3125C2.43262 4.1875 1.996 5.25 1.996 6.5C1.996 7.75 2.43262 8.8125 3.30587 9.6875C4.17912 10.5625 5.2395 11 6.487 11Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
