import { connect, useDispatch } from "react-redux";
import { useState } from "react";

import { MdPersonAddAlt1, MdOutlineClose } from "react-icons/md";
import { RiDeleteBin6Line, RiAddFill } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";

import { getShowInvitationModal } from "redux/Modals/selectors";
import { setShowInvitationModal } from "redux/Modals/actions";
import { State } from "redux/store";

import styles from "./style.module.scss";

const SingleInvitationRow = ({
  id,
  handleDeleteButtonClick,
}: {
  id: number;
  handleDeleteButtonClick: Function;
}) => {
  const [inputString, setInputString] = useState<string>("");

  const handleChange = (event: any) => {
    setInputString(event.target.value);
  };

  return (
    <div className={styles.singleRow}>
      <input
        type="text"
        value={inputString}
        className={styles.singleRowInput}
        onChange={handleChange}
        placeholder="Enter email address"
      />
      <div
        className={styles.deleteButton}
        onClick={() => handleDeleteButtonClick(id)}
      >
        <RiDeleteBin6Line />
      </div>
    </div>
  );
};

const InvitationModal = ({ showModal }: any) => {
  const [ids, setIds] = useState<number[]>([]);
  const [idCounter, setIdCounter] = useState(0);

  const dispatch = useDispatch();

  const handleCloseButtonClick = () => {
    dispatch(setShowInvitationModal(false));
    setIds([]);
  };

  const handleAddButtonClick = () => {
    setIds((oldIds) => [...oldIds, idCounter]);
    setIdCounter((prevCounter) => prevCounter + 1);
  };

  const handleDeleteButtonClick = (value: number) => {
    setIds((oldIds) => oldIds.filter((singleId) => singleId !== value));
  };

  return (
    <Modal
      animation={false}
      show={showModal}
      onHide={handleCloseButtonClick}
      className={styles.modalWrapper}
      backdropClassName={styles.backgroundColor}
    >
      <Modal.Body
        className={styles.modalContainerWrapper}
        onClick={(e: any) => e.stopPropagation()}
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <div className={styles.modalHeaderTitleBox}>
              <div className={styles.modalHeaderIcon}>
                <MdPersonAddAlt1 size="1.5em" />
              </div>
              <span className={styles.modalHeaderTitle}>
                Invite team members
              </span>
            </div>
            <button
              className={styles.modalCloseBtn}
              onClick={handleCloseButtonClick}
            >
              <MdOutlineClose />
            </button>
          </div>
          <div className={styles.modalBody}>
            <span className={styles.modalBodyTitle}>
              Send invitation links to team members and friends
            </span>
            <div className={styles.rowContainer}>
              {ids.map((id: number, index: number) => {
                return (
                  <SingleInvitationRow
                    key={id}
                    id={id}
                    handleDeleteButtonClick={handleDeleteButtonClick}
                  />
                );
              })}
            </div>
            <div className={styles.addButton} onClick={handleAddButtonClick}>
              <span className={styles.addButtonText}>Add new member</span>
              <div className={styles.addButtonIcon}>
                <RiAddFill />
              </div>
            </div>
          </div>

          <div className={styles.modalFooter}>
            <button className={styles.modalInviteButton}>Invite</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state: State) => {
  return {
    showModal: getShowInvitationModal(state),
  };
};
export default connect(mapStateToProps)(InvitationModal);
