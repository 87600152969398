import { useState, useEffect, MutableRefObject, useCallback } from "react";

export const useImageDrag = (
  imageRef: MutableRefObject<HTMLElement | null>,
  zoomLevel: number
) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [translatePosition, setTranslatePosition] = useState({ x: 0, y: 0 });
  const [isImageCentered, setIsImageCentered] = useState(true);

  useEffect(() => {
    setIsImageCentered(translatePosition.x === 0 && translatePosition.y === 0);
  }, [translatePosition]);

  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      if (
        imageRef &&
        imageRef.current &&
        imageRef.current.contains(e.target as Node)
      ) {
        setIsDragging(true);
        setStartPosition({
          x: e.clientX - translatePosition.x,
          y: e.clientY - translatePosition.y,
        });
      }
    },
    [translatePosition, imageRef]
  );

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        const x = e.clientX - startPosition.x;
        const y = e.clientY - startPosition.y;
        setTranslatePosition({ x, y });
      }
    },
    [startPosition, isDragging]
  );

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const centerImageHandler = () => {
    if (translatePosition.x !== 0 || translatePosition.y !== 0) {
      setTranslatePosition({ x: 0, y: 0 });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onMouseDown);
    if (isDragging) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    } else {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [isDragging, onMouseDown, onMouseMove]);

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.style.left = `${translatePosition.x}px`;
      imageRef.current.style.top = `${translatePosition.y}px`;
      imageRef.current.style.position = "relative";
      imageRef.current.style.cursor = isDragging
        ? "grabbing"
        : zoomLevel > 100
        ? "grab"
        : "auto";
    }
  }, [translatePosition, imageRef, zoomLevel, isDragging]);
  return { centerImageHandler, isImageCentered };
};
