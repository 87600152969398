import Cookies from "js-cookie";
import Modal from "react-bootstrap/Modal";
import { connect, useDispatch } from "react-redux";

import { State } from "redux/store";
import { getShowSignUpSuccessModal } from "redux/Modals/selectors";
import { setShowModal, setShowSignUpSuccessModal } from "redux/Modals/actions";

import { MdOutlineClose } from "react-icons/md";

import {
  CREATE_NEW_DATASET,
  SIGNUP_ORIGIN,
} from "helpers/constants/amplitudeProperties";

import styles from "./style.module.scss";

const SignupSuccessModal = ({ showSignUpSuccessModal }: any) => {
  const dispatch = useDispatch();

  const handleCloseButtonClick = () => {
    dispatch(setShowSignUpSuccessModal(false));
    Cookies.set("account_setup_modal_shown", "true");

    if (localStorage.getItem(SIGNUP_ORIGIN) === CREATE_NEW_DATASET) {
      dispatch(setShowModal(true));
      localStorage.removeItem(SIGNUP_ORIGIN);
    }
  };

  return (
    <Modal
      animation={false}
      show={showSignUpSuccessModal}
      onHide={handleCloseButtonClick}
      className={styles.modalWrapper}
      backdropClassName={styles.backgroundColor}
    >
      <Modal.Body onClick={(e: any) => e.stopPropagation()}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <div className={styles.modalHeaderTitleBox}>
              <span className={styles.modalHeaderTitle}>
                Account setup completed
              </span>
            </div>
            <button
              className={styles.modalCloseBtn}
              onClick={handleCloseButtonClick}
            >
              <MdOutlineClose size="15px" />
            </button>
          </div>
          <hr className={styles.horizontalRule} />
          <div className={styles.modalBody}>
            <span className={styles.description}>
              Congratulations! Your account creation was successful.
              <br />
              Here are some useful resources:
            </span>

            <ul className={styles.linkList}>
              <li className={styles.singleListElement}>
                <a
                  href="https://www.youtube.com/@visual-layer"
                  target="_blank"
                  rel="noreferrer"
                >
                  Turorial Videos
                </a>
              </li>
              <li className={styles.singleListElement}>
                <a
                  href="https://docs.visual-layer.com/docs"
                  target="_blank"
                  rel="noreferrer"
                >
                  Documentation
                </a>
              </li>
              <li className={styles.singleListElement}>
                <a
                  href="https://visual-layer.slack.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Slack Community
                </a>
              </li>
              <li className={styles.singleListElement}>
                <a
                  href="https://medium.com/visual-layer"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </li>
            </ul>
            <button
              className={styles.okButton}
              onClick={handleCloseButtonClick}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStatesToProps = (state: State) => {
  return {
    showSignUpSuccessModal: getShowSignUpSuccessModal(state),
  };
};

export default connect(mapStatesToProps)(SignupSuccessModal);
