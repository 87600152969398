import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  setClusterPageNumber,
  setDataPageNumber,
} from "redux/SingleDataset/actions";
import {
  BiChevronDown,
  BiChevronLeft,
  BiChevronRight,
  BiChevronUp,
} from "react-icons/bi";
import { formatNumberWithCommas } from "helpers/utility/formatters";
import { PAGE_PARAMETER } from "helpers/constants/miscellaneous";
import { amplitudeTrack } from "helpers/utility/amplitude";
import { AMP_DATASET_EXPLORATION_EVENT__PAGE_CHANGED } from "helpers/constants/amplitudeEvents";
import { AMP_DATASET_EXPLORATION_PROPERTY__PAGE_NUMBER } from "helpers/constants/amplitudeProperties";

import styles from "./style.module.scss";

function updatePageParam(navigateFunc: any, searchParams: any, newValue: any) {
  amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__PAGE_CHANGED, {
    [AMP_DATASET_EXPLORATION_PROPERTY__PAGE_NUMBER]: newValue,
  });

  searchParams.set(PAGE_PARAMETER, newValue.toString());
  navigateFunc({ search: `?${searchParams.toString()}` });
}

interface PaginiatorTypes {
  totalCount: number;
  currentBracket: string;
  type: string;
  currentPage: number;
  totalPages: number;
  metadata: any;
}

interface PageOptionsProps {
  totalPages: number;
  currentPage: number;
  handleClick: (option: number) => void;
}

const PageOptions = ({
  totalPages,
  currentPage,
  handleClick,
}: PageOptionsProps) => {
  const dynamicTopValue = (totalPages > 8 ? 8 : totalPages) * 40 + 5;
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  const scrollToOption = (index: number) => {
    const singleOption = optionsContainerRef.current?.children[index];
    if (singleOption) {
      singleOption.scrollIntoView({ block: "center" });
    }
  };

  useEffect(() => {
    const selectedIndex = currentPage - 1;
    scrollToOption(selectedIndex);
  }, [currentPage]);

  return (
    <div
      className={styles.optionsContainer}
      style={{ top: `-${dynamicTopValue}px` }}
      ref={optionsContainerRef}
    >
      {[...Array(totalPages)].map((_: any, index: number) => {
        const singleOption = index + 1;
        const isSelected = singleOption === currentPage ? styles.selected : "";

        return (
          <div
            key={singleOption}
            className={`${styles.singleOption} ${isSelected}`}
            onClick={() => handleClick(singleOption)}
          >
            {singleOption}
          </div>
        );
      })}
      <div></div>
    </div>
  );
};

const Paginator = ({
  totalCount,
  currentBracket,
  type,
  totalPages,
  currentPage,
  metadata,
}: PaginiatorTypes) => {
  const [showOptions, setShowOptions] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const pageOptionsRef = useRef<HTMLDivElement>(null);

  const { datasetId, clusterId } = useParams();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const typeText = type.toLowerCase();

  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const isLeftAvailable = currentPage > 1;
  const isRightAvailable = currentPage < totalPages;

  const updatePageNumber = (value: any) => {
    if (!!datasetId && !!clusterId) {
      dispatch(setClusterPageNumber(value));
    } else {
      dispatch(setDataPageNumber(value));
    }
  };

  const previousPageClick = async () => {
    if (currentPage === 1) return;
    updatePageParam(navigate, searchParams, currentPage - 1);
    updatePageNumber(currentPage - 1);
  };
  const nextPageClick = () => {
    if (currentPage === totalPages) return;
    updatePageParam(navigate, searchParams, currentPage + 1);
    updatePageNumber(currentPage + 1);
  };
  const handleOptionSelection = (option: number) => {
    if (option !== currentPage) {
      updatePageParam(navigate, searchParams, option);
      updatePageNumber(option);
    }
    setShowOptions(false);
  };

  const handleOutsideClick = (event: any) => {
    if (
      pageOptionsRef.current &&
      !pageOptionsRef.current.contains(event.target)
    ) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    if (showOptions) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showOptions]);

  return (
    <div className={styles.paginatorBox}>
      <div className={styles.textContainer}>
        <div className={styles.previousPageButton} onClick={previousPageClick}>
          <BiChevronLeft
            size="1.60em"
            color={isLeftAvailable ? "#fff" : "#575B67"}
          />
        </div>

        <div className={styles.text}>
          <span>{currentBracket} </span>
          of <span>{formatNumberWithCommas(totalCount)} </span>
          <span style={{ textTransform: "capitalize" }}>{typeText}</span>
        </div>

        <div className={styles.nextPageButton} onClick={nextPageClick}>
          <BiChevronRight
            size="1.60em"
            color={isRightAvailable ? "#fff" : "#575B67"}
          />
        </div>
      </div>
      <div className={styles.pageSelectionContainer}>
        <span className={styles.pageText}>Page:</span>
        <div
          className={styles.pageSelectionBox}
          onClick={toggleShowOptions}
          ref={pageOptionsRef}
        >
          <span className={styles.selectedOption}>{currentPage}</span>
          <div className={styles.showOptionsIcon} onClick={toggleShowOptions}>
            {showOptions ? (
              <BiChevronUp size="1.60em" />
            ) : (
              <BiChevronDown size="1.60em" />
            )}
          </div>
          {showOptions && (
            <PageOptions
              totalPages={totalPages}
              currentPage={currentPage}
              handleClick={handleOptionSelection}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Paginator;
