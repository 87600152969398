import { ReactNode, useEffect } from "react";
import classNames from "classnames";
import { StepTileProgressStatus } from "types";

import styles from "./style.module.scss";
import CheckIcon from "assets/icons/CheckIcon";
import DangerCircleIcon from "assets/icons/DangerCircleIcon";

const StepTile = ({
  title,
  status,
  headIcon,
  actionIcon,
  isContentShown,
  handleOutsideClick,
  isIndexingTile, //used to apply styles only for indexing step
}: StepTileProps) => {
  const stepTileContainerClassNames = classNames(styles.stepTileContainer, {
    [styles.disabledContainer]: status === StepTileProgressStatus.DISABLED,
    [styles.failedContainer]: status === StepTileProgressStatus.FAILED,
    [styles.activeContainer]:
      status === StepTileProgressStatus.ACTIVE ||
      status === StepTileProgressStatus.IN_PROGRESS,
    [styles.completeContainer]: status === StepTileProgressStatus.COMPLETE,
    [styles.activeIndexingContainer]:
      status !== StepTileProgressStatus.DISABLED && isIndexingTile,
  });

  const titleTextClassNames = classNames(styles.titleText, {
    [styles.disabledTextColor]: status === StepTileProgressStatus.DISABLED,
    [styles.greenTextColor]:
      status === StepTileProgressStatus.COMPLETE && isIndexingTile,
    [styles.blackTextColor]:
      status === StepTileProgressStatus.IN_PROGRESS && isIndexingTile,
  });

  const renderHeadIcon = () => {
    if (status === StepTileProgressStatus.COMPLETE) {
      return (
        <div className={styles.stepCompleteIcon}>
          <CheckIcon color={isIndexingTile ? "#34A853" : "#fff"} />
        </div>
      );
    } else if (status === StepTileProgressStatus.FAILED) {
      return (
        <div className={styles.stepCompleteIcon}>
          <DangerCircleIcon variant="filled" color="#fff" />
        </div>
      );
    } else {
      return headIcon;
    }
  };

  useEffect(() => {
    if (isContentShown) {
      window.addEventListener("mousedown", handleOutsideClick);
    } else window.removeEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [isContentShown]);

  return (
    <div className={stepTileContainerClassNames}>
      <div className={styles.headIconContainer}>{renderHeadIcon()}</div>
      <div className={styles.titleContainer}>
        <span className={titleTextClassNames}>{title}</span>
      </div>
      <div className={styles.actionIconContainer}>{actionIcon}</div>
    </div>
  );
};

export default StepTile;

interface StepTileProps {
  headIcon: ReactNode;
  title: string;
  status: StepTileProgressStatus;
  actionIcon: ReactNode;
  isContentShown: boolean;
  handleOutsideClick: (event: any) => void;
  isIndexingTile?: boolean;
}
