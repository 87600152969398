const PublicDatasetIcon = ({ color = "#fff" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
    >
      <path
        d="M5.21168 13.8661V11.1233C5.21168 9.18282 6.18447 7.41085 7.77707 6.31006C7.05096 5.49722 6.64226 4.45885 6.64226 3.35198C6.64226 2.83365 6.73712 2.33702 6.90371 1.87208C6.46983 1.65022 5.97516 1.52344 5.45023 1.52344C3.74116 1.52344 2.35051 2.85656 2.35051 4.49494C2.35051 5.49039 2.86577 6.37126 3.65215 6.91056C2.04659 7.57907 0.919922 9.11261 0.919922 10.8948V12.9521C0.919922 14.2123 1.98962 15.2377 3.30423 15.2377H5.53849C5.33121 14.8213 5.21168 14.3573 5.21168 13.8661Z"
        fill={color}
      />
      <path
        d="M18.3014 6.91065C19.0004 6.3711 19.4584 5.49046 19.4584 4.49499C19.4584 2.85659 18.2222 1.52344 16.7031 1.52344C16.2362 1.52344 15.7968 1.65022 15.4111 1.87209C15.5592 2.33703 15.6435 2.83367 15.6435 3.35201C15.6435 4.45866 15.28 5.49705 14.6348 6.31015C16.0504 7.41095 16.9151 9.1832 16.9151 11.1234V13.8663C16.9151 14.3576 16.8089 14.8213 16.6246 15.2377H18.6106C19.7792 15.2377 20.73 14.2123 20.73 12.952V10.8947C20.73 9.1125 19.7285 7.57918 18.3014 6.91065Z"
        fill={color}
      />
      <path
        d="M13.078 6.5875C14.1134 5.96219 14.808 4.83887 14.808 3.55556C14.808 1.59502 13.1923 0 11.2063 0C9.22029 0 7.60456 1.59502 7.60456 3.55556C7.60456 4.83887 8.29921 5.96219 9.33459 6.5875C7.52964 7.31804 6.25391 9.06927 6.25391 11.1111V13.7778C6.25391 15.003 7.26383 16 8.50499 16H13.9076C15.1487 16 16.1587 15.003 16.1587 13.7778V11.1111C16.1587 9.06927 14.8829 7.31804 13.078 6.5875Z"
        fill={color}
      />
    </svg>
  );
};

export default PublicDatasetIcon;
