import { createSelector } from "@reduxjs/toolkit";
import { isFeatureEnabled } from "helpers/utility/utilities";
import {
  SHOW_DS_VERSION,
  ENABLE_DS_DUPLICATE,
} from "helpers/constants/filters";
import { UserQuota } from "types";

const userState = (state: any) => {
  return state.user;
};

export const getUserInfo = createSelector(userState, (state: any) => {
  return state.userInfo;
});

export const getVlVersion = createSelector(
  userState,
  getUserInfo,
  (state: any, userInfo: any) => {
    return userInfo.isInternalUser ? state.vlVersion : undefined;
  }
);

export const getUserConfig = createSelector(userState, (state: any) => {
  return state.userConfig;
});

export const shouldShowDsVersion = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, SHOW_DS_VERSION);
  }
);

export const shouldShowDuplicateButton = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, ENABLE_DS_DUPLICATE);
  }
);

export const getUserQuota = createSelector(
  userState,
  (state: any): UserQuota | null => {
    return state.userQuota;
  }
);
