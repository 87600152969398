import { State } from "redux/store";
import { GlobalCounts } from "types";
import { useSelector } from "react-redux";
import TooltipWrapper from "views/uikit/TooltipWrapper";
import { formatNumberWithCommas } from "helpers/utility/formatters";

import styles from "./style.module.scss";

const getInfoBarText = (
  value: string | number,
  total: string | number,
  label: string,
  tooltipText: string
) => {
  let formattedValue = formatNumberWithCommas(value);
  let formattedTotal = formatNumberWithCommas(total);
  return (
    <span
      className={styles.normalText}
      data-tooltip-content={tooltipText}
      data-tooltip-id={`data-page-${label}-count-info-box`}
      data-tooltip-place="top"
    >
      {formattedValue}/{formattedTotal} {label}
      <TooltipWrapper
        id={`data-page-${label}-count-info-box`}
        maxWidth="220px"
      />
    </span>
  );
};

const ExplorationStats = ({ globalCount }: { globalCount: GlobalCounts }) => {
  return (
    <>
      {globalCount?.images &&
        getInfoBarText(
          globalCount.images.filtered,
          globalCount.images.total,
          "Images",
          "Displays the number of Images and Video Key Frames that meet your filter criteria out of the total in the Dataset."
        )}
      <div className={styles.infoDivider}></div>
      {globalCount?.objects &&
        getInfoBarText(
          globalCount.objects.filtered,
          globalCount.objects.total,
          "Objects",
          "Displays the number of Object occurrences within Images and Video Key Frames that meet your filter criteria out of the total in the Dataset."
        )}
      <div className={styles.infoDivider}></div>
      {globalCount?.clusters &&
        getInfoBarText(
          globalCount.clusters.filtered,
          globalCount.clusters.total,
          "Clusters",
          "Displays the number of Clusters of Images and Objects that meet your filter criteria out of the total in the Dataset."
        )}
    </>
  );
};

const InfoBar = ({ showStats }: { showStats: boolean }) => {
  const globalCount = useSelector(
    (state: State) => state.singleDataset.explorationStats?.globalCounts
  ) as GlobalCounts;

  const noInfoBarStyle = showStats ? "" : styles.noInfoBar;

  return (
    <div className={`${styles.infoBar} ${noInfoBarStyle}`}>
      {showStats && <ExplorationStats globalCount={globalCount} />}
    </div>
  );
};

export default InfoBar;
