const QuestionIcon = ({ color = "#fff" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="19"
      viewBox="0 0 11 19"
      fill="none"
    >
      <path
        d="M4.05 13C4.05 11.65 4.17083 10.6792 4.4125 10.0875C4.65417 9.49583 5.16667 8.85 5.95 8.15C6.63333 7.55 7.15417 7.02917 7.5125 6.5875C7.87083 6.14583 8.05 5.64167 8.05 5.075C8.05 4.39167 7.82083 3.825 7.3625 3.375C6.90417 2.925 6.26667 2.7 5.45 2.7C4.6 2.7 3.95417 2.95833 3.5125 3.475C3.07083 3.99167 2.75833 4.51667 2.575 5.05L0 3.95C0.35 2.88333 0.991667 1.95833 1.925 1.175C2.85833 0.391667 4.03333 0 5.45 0C7.2 0 8.54583 0.4875 9.4875 1.4625C10.4292 2.4375 10.9 3.60833 10.9 4.975C10.9 5.80833 10.7208 6.52083 10.3625 7.1125C10.0042 7.70417 9.44167 8.375 8.675 9.125C7.85833 9.90833 7.3625 10.5042 7.1875 10.9125C7.0125 11.3208 6.925 12.0167 6.925 13H4.05ZM5.45 19C4.9 19 4.42917 18.8042 4.0375 18.4125C3.64583 18.0208 3.45 17.55 3.45 17C3.45 16.45 3.64583 15.9792 4.0375 15.5875C4.42917 15.1958 4.9 15 5.45 15C6 15 6.47083 15.1958 6.8625 15.5875C7.25417 15.9792 7.45 16.45 7.45 17C7.45 17.55 7.25417 18.0208 6.8625 18.4125C6.47083 18.8042 6 19 5.45 19Z"
        fill={color}
      />
    </svg>
  );
};

export default QuestionIcon;
