import { State } from "redux/store";
import { connect, useDispatch } from "react-redux";
import { getimageErrorFileName } from "redux/CreateDataset/selectors";

import DangerCircleIcon from "assets/icons/DangerCircleIcon";
import XIcon from "assets/icons/XIcon";

import styles from "./style.module.scss";
import { setImageErrorFileName } from "redux/CreateDataset/actions";

const ImageError = ({ imageErrorFileName }: ImageErrorProps) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setImageErrorFileName(""));
  };

  return imageErrorFileName ? (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.containrHeader}>
          <DangerCircleIcon variant="filled" color="#151928" />
          <span className={styles.containrHeaderTitle}>Image failed</span>
          <div className={styles.crossIcon} onClick={closeModal}>
            <XIcon color="#000" />
          </div>
        </div>

        <div className={styles.containrBody}>
          <p>
            Sorry, we couldn't process {imageErrorFileName} because of the
            following error:
          </p>
          <ul>
            <li>File type not supported</li>
          </ul>
          <p>
            Please review our{" "}
            <span className={styles.link}>Upload guidelines</span> and try
            again.
          </p>
        </div>
        <div className={styles.gotitButton} onClick={closeModal}>
          Got It
        </div>
      </div>
    </div>
  ) : (
    <noscript />
  );
};

const mapStateToProps = (state: State) => {
  return {
    imageErrorFileName: getimageErrorFileName(state),
  };
};

export default connect(mapStateToProps)(ImageError);

interface ImageErrorProps {
  imageErrorFileName: string;
}
