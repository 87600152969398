//Colors
export const YELLOW = "#FEAA01";
export const MUSTARD_YELLOW = "#FFA903";
export const ORANGE = "#d85829";
export const DARK_BLUE = "#28303f";
export const ERROR_RED = "#D85829";
export const LIGHT_RED = "#FF5454";
export const LIGHT_GREEN = "#43e625";
export const PARROT_GREEN = "#7CCA1A";
export const LIGHT_GREY = "#D4D4D4";
export const BLUISH_GREY =  "#4f5568"; // "#6c757d"; 
export const BONE_GREY =  "#4f556810"; //  "#c5c1c1";
export const DOWNLOAD_GREY = "#c0bfbd";
export const DARK_GREY = "#373C4B";
export const PURE_WHITE = "#fff";
export const PURE_BLACK = "#000";
export const SEMI_BLACK = "#151928";
export const GREY = "#60646e";
export const BLACK_BLUE = "#474C58";
export const BRIGHT_BLUE = "#0197d8";
