import { useState } from "react";

const ImageWithFallback = ({
  thumbImage,
  plainImage,
  className,
  onLoad,
}: ImageWithFallbackProps) => {
  const [src, setSrc] = useState(thumbImage.src || plainImage.src);

  const handleImgError = () => {
    if (src !== plainImage.src) {
      setSrc(plainImage.src);
    }
  };

  return (
    <img
      className={className}
      loading="lazy"
      src={src}
      alt="No Img"
      onLoad={onLoad}
      onError={handleImgError}
    />
  );
};

interface ImageProps {
  src: string;
}

interface ImageWithFallbackProps {
  thumbImage: ImageProps;
  plainImage: ImageProps;
  className?: string;
  onLoad?: () => void;
}

export default ImageWithFallback;
