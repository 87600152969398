import moment from "moment";
import { useParams } from "react-router";
import { DATA_PAGE, SINGLE_IMAGE_PAGE } from "helpers/constants/pages";
import {
  AMP_IMAGE_ID,
  AMP_IMAGE_NAME,
  AMP_IMAGE_SOURCE,
} from "helpers/constants/amplitudeProperties";
import { kFormatter } from "helpers/utility/formatters";
import { amplitudeTrack } from "helpers/utility/amplitude";
import { AMP_IMAGE_SOURCE_VISITED } from "helpers/constants/amplitudeEvents";
import { useDatasetVersion } from "helpers/utility/utilities";

import ContentLoad from "views/uikit/ContentLoad";

import styles from "./style.module.scss";

const DescriptionListForDatasetExploration = ({
  isContentLoading,
  stats,
}: any) => {
  return isContentLoading ? (
    <div className={styles.innerHeaderContentLoaders}>
      <div className={styles.innerHeaderContentTypeLoader}>
        <ContentLoad />
      </div>
      <div className={styles.innerHeaderContentImagesLoader}>
        <ContentLoad />
      </div>
      <div className={styles.innerHeaderContentStorageLoader}>
        <ContentLoad />
      </div>
      <div className={styles.innerHeaderContentCreatedLoader}>
        <ContentLoad />
      </div>
    </div>
  ) : (
    <div className={styles.innerHeaderContentLeft}>
      <div className={styles.contentLeftBox}>
        <span className={styles.title}>Source&nbsp;</span>
        <span className={styles.description}>
          {stats?.dataset?.source_type}
        </span>
      </div>
      <div className={styles.contentLeftBox}>
        <span className={styles.title}>Images&nbsp;</span>
        <span className={styles.description}>{stats?.dataset?.n_images}</span>
      </div>
      <div className={styles.contentLeftBox}>
        <span className={styles.title}>Size&nbsp;</span>
        <span className={styles.description}>
          {stats?.dataset?.size_display_value}
        </span>
      </div>
      <div className={styles.contentLeftBox}>
        <span className={styles.title}>Created&nbsp;</span>
        <span className={styles.description}>
          {moment(stats?.dataset?.created_at).fromNow()}
        </span>
      </div>
    </div>
  );
};

const DescriptionListForImageExploration = ({
  imageHeight,
  imageWidth,
  file_size,
  mime_type,
  isContentLoading,
  image_uri,
  image_name,
  image_id,
}: any) => {
  const handleImageSourceClick = () => {
    amplitudeTrack(AMP_IMAGE_SOURCE_VISITED, {
      [AMP_IMAGE_NAME]: image_name,
      [AMP_IMAGE_ID]: image_id,
      [AMP_IMAGE_SOURCE]: image_uri,
    });
  };

  return isContentLoading ? (
    <div className={styles.innerHeaderContentLoadersForImageExploration}>
      <div className={styles.dimensionsLoaderForImageExploration}>
        <ContentLoad />
      </div>
      <div className={styles.sizeLoaderForImageExploration}>
        <ContentLoad />
      </div>
      <div className={styles.typeLoaderForImageExploration}>
        <ContentLoad />
      </div>
      <div className={styles.urlLoaderForImageExploration}>
        <ContentLoad />
      </div>
    </div>
  ) : (
    <>
      <div className={styles.innerHeaderContentLeft}>
        <div className={styles.numberOfImages}>
          <span className={styles.title}>Dimensions </span>
          <span
            className={styles.description}
          >{`${imageHeight} x ${imageWidth}`}</span>
        </div>
      </div>

      <div className={styles.innerHeaderContentRight}>
        <div className={styles.storageCapacity}>
          <span className={styles.title}>size </span>
          <span className={styles.description}>{file_size}</span>
        </div>
        <div className={styles.url}>
          <a
            className={styles.urlText}
            onClick={handleImageSourceClick}
            href={image_uri}
            target="blank"
          >
            Source Image
          </a>
        </div>
      </div>
    </>
  );
};
const DescriptionListForDataPage = ({
  sourceName,
  imageCount,
  objectCount,
  videoCount,
  frameCount,
  sizeValue,
  createdAt,
  creatorName,
  dsVersion,
}: any) => {
  const { datasetId, clusterId, imageId } = useParams();

  const isImagePage = !!imageId && !!datasetId && !clusterId;

  const showSource = isImagePage;
  const showSize = !!sizeValue;
  const showCreatedAt = !!createdAt;
  const showCreatedBy = !!creatorName;
  const showDsVersion = !!dsVersion;

  const formatValue = (value: number) => {
    return kFormatter(value).toUpperCase();
  };

  const renderDescription = (title: string, description: string) => (
    <div className={styles.contentLeftBox}>
      <span className={styles.title}>{title}&nbsp;</span>
      <span className={styles.description}>{description}</span>
    </div>
  );

  const renderDescriptionBoxes = () => (
    <div className={styles.innerHeaderContentLeft}>
      {showSource && renderDescription("Source", sourceName)}
      {renderDescription("Images", formatValue(imageCount))}
      {renderDescription("Objects", formatValue(objectCount))}
      {renderDescription("Videos", formatValue(videoCount))}
      {renderDescription("Video Frames", formatValue(frameCount))}
      {showSize && renderDescription("Size", sizeValue)}
      {showCreatedAt &&
        renderDescription("Created", moment.utc(createdAt).local().fromNow())}
      {showCreatedBy && renderDescription("Created By", creatorName)}
      {showDsVersion && renderDescription("Version", dsVersion)}
    </div>
  );

  return renderDescriptionBoxes();
};

const DescriptionList = ({
  page,
  isContentLoading,
  stats,
  dataset,
  clusterData,
  imageClusterData,
  singleImageData,
}: any) => {
  const dsVersion = useDatasetVersion(dataset?.pipeline_commit_id);
  return (
    <div className={styles.innerHeaderContent}>
      {page === DATA_PAGE ? (
        isContentLoading ? null : (
          <DescriptionListForDataPage
            sourceName={dataset?.source_type}
            imageCount={dataset?.n_images}
            objectCount={dataset?.n_objects}
            videoCount={dataset?.n_videos}
            frameCount={dataset?.n_video_frames}
            sizeValue={dataset?.size_display_value}
            createdAt={dataset?.created_at}
            creatorName={dataset?.creator_name}
            isContentLoading={isContentLoading}
            dsVersion={dsVersion}
          />
        )
      ) : page === SINGLE_IMAGE_PAGE ? (
        <DescriptionListForImageExploration
          isContentLoading={isContentLoading}
          imageHeight={singleImageData?.height ? singleImageData?.height : 0}
          imageWidth={singleImageData?.width ? singleImageData?.width : 0}
          file_size={singleImageData?.file_size_display_value}
          mime_type={singleImageData?.mime_type}
          image_uri={singleImageData?.image_uri}
          image_name={singleImageData?.file_name}
          image_id={singleImageData?.id}
        />
      ) : (
        <DescriptionListForDatasetExploration
          isContentLoading={isContentLoading}
          stats={stats}
        />
      )}
    </div>
  );
};

export default DescriptionList;
