import { toast, Id } from "react-toastify";
import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { MdOutlineClose } from "react-icons/md";
import { GoCheck as GreenTick } from "react-icons/go";

import { State } from "redux/store";

import { LIGHT_GREEN } from "helpers/constants/colors";
import { validateDatasetName } from "helpers/validations";

import VLToastComponent from "views/components/VLToastComponent";
import TooltipWrapper from "views/uikit/TooltipWrapper";
import SelectionOption from "./SelectionOption";
import FileOption from "./FileOption";

import { getExportData } from "redux/SingleDataset/selectors";
import {
  setShowCart,
  setShowExportModal,
  submitExportRequest,
} from "redux/SingleDataset/actions";
import formattedDateNow from "helpers/utility/formatters";
import { amplitudeTrack } from "helpers/utility/amplitude";
import { AMP_DATA_EXPORTED } from "helpers/constants/amplitudeEvents";
import {
  AMP_EXPORT_FILE_CONTEXT,
  AMP_EXPORT_FILE_FORMAT,
  AMP_EXPORT_INCLUDES_RAW_DATA,
} from "helpers/constants/amplitudeProperties";
import classNames from "classnames";
import DangerCircleIcon from "assets/icons/DangerCircleIcon";

import TodoIcon from "assets/img/colored-to-do-list.svg";
import SettingsIcon from "assets/img/colored-setting.svg";
import FolderIcon from "assets/img/colored-file-storage.svg";

import styles from "./style.module.scss";

export type FilesToExport = "SELECTED" | "FILTERED" | "DATASET";
export type ExportFormatOptions = "JSON" | "COCO";

const showToast = (Element: ReactNode): Id => {
  return toast(Element, {
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
  });
};

interface ExportModalProps {
  callerButtonRef: RefObject<HTMLDivElement>;
  showModal: boolean;
  exportData: any[];
}

const ExportModal = ({
  showModal,
  exportData,
  callerButtonRef,
}: ExportModalProps) => {
  const [errorFlag, setErrorFlag] = useState<string>("");
  const [fileName, setFileName] = useState<string>(
    "VL-export-" + formattedDateNow()
  );
  const [selectedItemsCount, setSelectedItemsCount] = useState<number>(0);
  const [selectedSource, setSelectedSource] =
    useState<FilesToExport>("SELECTED");
  const [selectedExportType, setSelectedExportType] =
    useState<ExportFormatOptions>("JSON");
  const [includeRawData, setIncludeRawData] = useState<boolean>(false);

  const modalRef = useRef<HTMLDivElement>(null);
  const toastIdRef = useRef<any>(null);
  const dispatch = useDispatch();
  const isExportDisabled = !(fileName && selectedSource) || errorFlag;

  const inputClassNames = classNames(styles.input, {
    [styles.errorInput]: errorFlag,
    [styles.showInputIcon]: fileName !== "",
    [styles.successBorder]: !!fileName && !errorFlag,
  });

  const toggleIconClassnames = classNames(styles.toggleIcon, {
    [styles.selectedToggleIcon]: includeRawData,
  });

  const exportButtonClassnames = classNames(styles.exportButton, {
    [styles.disabledExortButton]: isExportDisabled,
  });

  const resetStates = () => {
    setErrorFlag("");
    setFileName("VL-export-" + formattedDateNow());
    setSelectedSource(selectedItemsCount > 0 ? "SELECTED" : "FILTERED");
  };

  const handleInputValidation = (value: any) => {
    let result = validateDatasetName(value);
    result ? setErrorFlag(result) : setErrorFlag("");
  };

  const handleInputChange = (e: any) => {
    let inputValue = e.target.value;
    if (inputValue === "") {
      resetStates();
    }
    setFileName(inputValue);
    handleInputValidation(inputValue);
  };

  const handleCloseButtonClick = () => {
    resetStates();
    dispatch(setShowExportModal(false));
  };

  const handleOutsideClick = (event: any) => {
    if (
      callerButtonRef.current &&
      callerButtonRef.current.contains(event.target)
    ) {
      return;
    }

    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseButtonClick();
    }
  };

  const successNotification = (downloadURI: string) => {
    toastIdRef.current = showToast(
      <VLToastComponent
        status="SUCCESS"
        title={"The file is now ready!"}
        onClose={() => toast.dismiss(toastIdRef.current)}
        helperText={
          <span>
            You can download the file again by clicking this{" "}
            <a
              className={styles.toastLink}
              href={downloadURI}
              download={`${fileName}.zip`}
            >
              link
            </a>
            .
          </span>
        }
        description={
          "Your export is almost ready! You can find your ZIP file in the 'Downloads' folder shortly."
        }
      />
    );
  };

  const errorNotification = (errorDescription: string) => {
    toastIdRef.current = showToast(
      <VLToastComponent
        status="ERROR"
        title={"Oops! We could not process your file"}
        onClose={() => toast.dismiss(toastIdRef.current)}
        helperText={
          <span>
            You can export the file again by clicking this{" "}
            <span
              className={styles.toastLink}
              onClick={() => {
                dispatch(setShowCart(true));
                dispatch(setShowExportModal(true));
                toast.dismiss(toastIdRef.current);
              }}
            >
              link
            </span>
            .
          </span>
        }
        description={errorDescription}
      />
    );
  };

  const pendingNotification = () => {
    toastIdRef.current = showToast(
      <VLToastComponent
        status="IN_PROGRESS"
        title={"Exporting File..."}
        onClose={() => toast.dismiss(toastIdRef.current)}
        helperText={
          "This process will continue in the background. You can close this window"
        }
        description={
          "Your export is almost ready! You can find your ZIP file in the 'Downloads' folder shortly."
        }
      />
    );
  };

  const handleExportClick = async () => {
    if (isExportDisabled) {
      setErrorFlag("Please insert a correct file name.");
      return;
    }

    try {
      dispatch(setShowExportModal(false));
      dispatch(setShowCart(false));
      pendingNotification();
      const downloadURI = await dispatch(
        submitExportRequest(
          fileName,
          selectedExportType,
          selectedSource,
          includeRawData
        ) as any
      );
      toast.dismiss(toastIdRef.current);
      amplitudeTrack(AMP_DATA_EXPORTED, {
        [AMP_EXPORT_INCLUDES_RAW_DATA]: includeRawData,
        [AMP_EXPORT_FILE_CONTEXT]: selectedSource,
        [AMP_EXPORT_FILE_FORMAT]: selectedExportType,
      });
      successNotification(downloadURI);
    } catch (error: any) {
      toast.dismiss(toastIdRef.current);
      errorNotification(error.message);
    } finally {
      resetStates();
    }
  };

  const renderInput = () => {
    return (
      <div className={styles.inputContainer}>
        <input
          type="text"
          value={fileName}
          className={inputClassNames}
          onChange={handleInputChange}
          placeholder="Insert a file name e.g My Images"
        />
        <div
          className={classNames(
            fileName === "" && !errorFlag ? styles.disabledGreenTick : undefined
          )}
        >
          {errorFlag ? (
            <div
              className={styles.errorIcon}
              data-tooltip-id="export-filename-error-tooltip"
              data-tooltip-content={errorFlag}
            >
              <DangerCircleIcon variant="outline" color="#ff5454" />
              <TooltipWrapper
                id="export-filename-error-tooltip"
                customClass="totalQuotaTooltip"
                maxWidth="max-content"
              />
            </div>
          ) : (
            <GreenTick
              size="1em"
              color={LIGHT_GREEN}
              className={styles.greenTick}
            />
          )}
        </div>
      </div>
    );
  };

  const renderSlectionOptions = () => {
    return (
      <div className={styles.selectionOptionsContainer}>
        {selectedItemsCount > 0 && (
          <SelectionOption
            icon={TodoIcon}
            badgeText={`${selectedItemsCount}  items`}
            title="Current Selection"
            description="Export the selected items"
            selected={selectedSource === "SELECTED"}
            onSelect={() => setSelectedSource("SELECTED")}
          />
        )}

        <SelectionOption
          icon={SettingsIcon}
          title="Matching the applied filters"
          selected={selectedSource === "FILTERED"}
          onSelect={() => setSelectedSource("FILTERED")}
          description="Export items that match the current filter selection"
        />

        <SelectionOption
          icon={FolderIcon}
          title="Entire Dataset"
          selected={selectedSource === "DATASET"}
          onSelect={() => setSelectedSource("DATASET")}
          description="Export all items from the complete dataset"
        />
      </div>
    );
  };

  const renderFileTypeOptions = () => {
    return (
      <div className={styles.fileOptionsContainer}>
        <FileOption
          title="JSON"
          selected={selectedExportType === "JSON"}
          onSelect={() => setSelectedExportType("JSON")}
        />

        <FileOption
          title="COCO"
          disabled={true}
          selected={false}
          onSelect={() => {}}
          badgeText="⚡ Coming Soon"
        />
      </div>
    );
  };

  useEffect(() => {
    setSelectedItemsCount(
      exportData.reduce((total, item) => {
        const excludeMediaIDs = item.excludeMediaIDs || [];
        if (item.type === "IMAGES" && item.n_images !== undefined) {
          return total + (item.n_images - excludeMediaIDs.length);
        } else if (item.type === "IMAGE" || item.type === "OBJECT") {
          return total + 1;
        } else if (item.type === "OBJECTS" && item.n_objects !== undefined) {
          return total + (item.n_objects - excludeMediaIDs.length);
        }
        return total;
      }, 0)
    );
  }, [exportData]);

  useEffect(() => {
    if (selectedItemsCount === 0) {
      setSelectedSource("FILTERED");
    } else {
      setSelectedSource("SELECTED");
    }
  }, [selectedItemsCount]);

  useEffect(() => {
    if (showModal) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [showModal]);

  return showModal ? (
    <div
      ref={modalRef}
      className={styles.modalContainer}
      onClick={(e: any) => e.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <span className={styles.modalHeaderTitle}>Export data</span>
        <button
          className={styles.modalCloseBtn}
          onClick={handleCloseButtonClick}
        >
          <MdOutlineClose size="14px" />
        </button>
      </div>
      <div className={styles.divider}></div>

      <div className={styles.label}>
        Please provide a name for the file you're exporting
      </div>
      {renderInput()}

      <div className={styles.label}>
        Choose the items you want to include in your export
      </div>
      {renderSlectionOptions()}

      <div className={styles.label}>Choose your preferred file format</div>
      {renderFileTypeOptions()}
      <div className={styles.rawImageOption}>
        <div className={styles.label}>
          Include image files (up to 5,000 images)
        </div>
        <div
          className={styles.toggleContainer}
          onClick={() => setIncludeRawData(!includeRawData)}
        >
          <div className={toggleIconClassnames}></div>
        </div>
      </div>

      <div className={styles.divider}></div>

      <div className={exportButtonClassnames} onClick={handleExportClick}>
        Export File
      </div>
    </div>
  ) : (
    <noscript />
  );
};

const mapStateToProps = (state: State) => {
  return {
    exportData: getExportData(state),
  };
};

export default connect(mapStateToProps)(ExportModal);
