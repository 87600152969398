import { BRIGHT_BLUE } from "helpers/constants/colors";
import { USER_TAGS_PARAMETER } from "helpers/constants/miscellaneous";
import { DATA_PAGE } from "helpers/constants/pages";
import { trackFilterSelection } from "helpers/utility/amplitude";
import { useDispatch } from "react-redux";
import { setUserTags } from "redux/SingleDataset/actions";
import { FilterBehavior, FilterOption } from "types";
import VLAccordian from "views/components/Accordian";
import MultiSelectDropDown from "views/pages/DataPage/CardsView/DataPageFilters/MultiSelectDropDown";

interface TagsFilterProps {
  datasetId: string;
  tags: Array<FilterOption>;
  isTagLoading: boolean;
  behavior: FilterBehavior;
  resetImageSearch: () => void;
  entityTypeFilter: any;
  updateQueryParameter: (options: FilterOption[], parameter: string) => void;
}

const TagsFilter = ({
  datasetId,
  tags,
  isTagLoading,
  behavior,
  updateQueryParameter,
  resetImageSearch,
  entityTypeFilter,
}: TagsFilterProps) => {
  const dispatch = useDispatch();
  const clearTags = () => {
    const unselectedTags = tags.map((option: FilterOption) => ({
      ...option,
      selected: false,
    }));
    updateQueryParameter(unselectedTags as any, USER_TAGS_PARAMETER);
    dispatch(setUserTags(unselectedTags));
    resetImageSearch();
  };
  const selectedTags = tags.filter((option: FilterOption) => option.selected);
  const isTagSelected = selectedTags.length > 0;
  const selectedTagsId = selectedTags.map((option: FilterOption) => option.id);
  const selectedTagsText = selectedTags.map(
    (option: FilterOption) => option.text
  );
  const firstTagText = selectedTagsText.length > 0 ? selectedTagsText[0] : "";
  const tagsCountDisplayNumber =
    selectedTags?.length > 1 ? selectedTags?.length - 1 : 0;

  const handleFilterSelection = (value: number, type: any) => {
    const selectedTag = tags.find(
      (option: FilterOption) => option.id === value
    );

    if (selectedTag?.selected) {
      trackFilterSelection("User Tags", "Dataset", entityTypeFilter, "Removed");
    } else {
      trackFilterSelection("User Tags", "Dataset", entityTypeFilter, "Added");
    }

    let newTags = [
      ...tags.filter((option: FilterOption) => option.id !== value),
      { ...selectedTag, selected: !selectedTag?.selected },
    ];
    newTags = [
      ...newTags.filter((tag) => tag.text === "untagged"),
      ...newTags.filter((tag) => tag.text !== "untagged"),
    ];
    updateQueryParameter(newTags as any, USER_TAGS_PARAMETER);
    dispatch(setUserTags(newTags) as any);
    resetImageSearch();
  };

  return (
    <VLAccordian
      title="User Tags"
      type="tag"
      page={DATA_PAGE}
      customWidth={"max-content"}
      hideInfo={true}
      initiallyClosed={true}
      fontSize={"13px"}
      bgColor={isTagSelected ? BRIGHT_BLUE : "#151928"}
      borderRadius="5px"
      padding={"0 10px"}
      height="40px"
      border={isTagSelected ? "1px solid #fff" : undefined}
      selectionCount={tagsCountDisplayNumber}
      firstSelectionName={firstTagText}
      behavior={behavior}
      tooltipContent={
        isTagSelected
          ? ""
          : `Apply this filter to display only the data entries tagged with a specific User Tag you're interested in.`
      }
      tooltipID="data-page-tag-filter-box"
      tooltipWidth="203px"
    >
      <MultiSelectDropDown
        selectedFilters={{ tag: selectedTagsId }}
        handleFilterSelection={handleFilterSelection}
        clear={clearTags}
        options={tags}
        type={"tag"}
        isLoading={isTagLoading}
      />
    </VLAccordian>
  );
};
export default TagsFilter;
