const PREFIX = "SingleDataset";
export const RESET_STORE = `${PREFIX}_RESET_STORE`;
export const IS_CONTENT_LOADING = `${PREFIX}_IS_CONTENT_LOADING`;
export const IS_CARDSLIST_LOADING = `${PREFIX}_IS_CARDSLIST_LOADING`;
export const IS_LABEL_LOADING = `${PREFIX}_IS_LABEL_LOADING`;
export const IS_ISSUE_TYPE_LOADING = `${PREFIX}_IS_ISSUE_TYPE_LOADING`;
export const SET_ISSUE_DATA = `${PREFIX}_ISSUE_DATA`;
export const SET_SEVERITY_DATA = `${PREFIX}_SET_SEVERITY_DATA`;
export const SET_FILTER_ISSUE = `${PREFIX}_SET_ISSUE_ID`;
export const SET_FILTER_SEVERITY = `${PREFIX}_SET_SEVERITY_ID`;
export const SET_DATASET_SORTING_VALUE = `${PREFIX}_SET_DATASET_SORTING_VALUE`;
export const SET_SIMILARITY_DATA = `${PREFIX}_SET_SIMILARITY_DATA`;
export const SET_SIMILARITY_VERTEX = `${PREFIX}_SET_SIMILARITY_VERTEX`;
export const SET_SIMILARITY_VERTEX_ID = `${PREFIX}_SET_SIMILARITY_VERTEX_ID`;
export const SET_SIMILARITY_VERTEX_TYPE = `${PREFIX}_SET_SIMILARITY_VERTEX_TYPE`;
export const SET_SIMILARITY_THRESHOLD = `${PREFIX}_SET_SIMILARITY_THRESHOLD`;
export const SET_SIMILARITY_THRESHOLDS = `${PREFIX}_SET_SIMILARITY_THRESHOLDS`;
export const SET_ENTITY_TYPE_FILTER = `${PREFIX}_SET_ENTITY_TYPE_FILTER`;
export const SET_DATASET_LABELS = `${PREFIX}_SET_DATASET_LABELS`;
export const SET_DATASET_ISSUE_TYPES = `${PREFIX}_SET_DATASET_ISSUE_TYPES`;
export const SET_EXPLORATION_STATS = `${PREFIX}_SET_EXPLORATION_STATS`;

export const SET_NAVIGATION_CLUSTER = `${PREFIX}_SET_NAVIGATION_CLUSTER`;
export const SET_NAVIGATION_CLUSTER_LOADER = `${PREFIX}_SET_NAVIGATION_CLUSTER_LOADER`;

export const SET_SEARCH_TEXT = `${PREFIX}_SET_SEARCH_TEXT`;
export const SET_FILE_NAME_SEARCH_TEXT = `${PREFIX}_SET_FILE_NAME_SEARCH_TEXT`;
export const SET_TIME_RANGE = `${PREFIX}_SET_TIME_RANGE`;

export const SET_FILTER_CONFIG = `${PREFIX}_SET_FILTER_CONFIG`;
export const SET_TREE_FOLDER = `${PREFIX}_SET_TREE_FOLDER`;
export const SET_FILTER_FOLDER = `${PREFIX}_SET_FILTER_FOLDER`;

export const SET_EXPORT_DATA = `${PREFIX}_SET_EXPORT_DATA`;
export const SET_TEMP_EXPORT_DATA = `${PREFIX}_SET_TEMP_EXPORT_DATA`;
export const SET_EXPORT_DATA_TIMEOUT_ID = `${PREFIX}_SET_EXPORT_DATA_TIMEOUT_ID`;

export const SET_SHOW_CART = `${PREFIX}_SET_SHOW_CART`;
export const SET_DATA_PAGE_NUMBER = `${PREFIX}_SET_DATA_PAGE_NUMBER`;
export const SET_CLUSTER_PAGE_NUMBER = `${PREFIX}_SET_CLUSTER_PAGE_NUMBER`;
export const SET_PAGINATION_METADATA_FOR_DATA = `${PREFIX}_SET_PAGINATION_METADATA_FOR_DATA`;
export const SET_PAGINATION_METADATA_FOR_CLUSTER = `${PREFIX}_SET_PAGINATION_METADATA_FOR_CLUSTER`;
export const SET_CLUSTER_METADATA_SUMMARY = `${PREFIX}_SET_CLUSTER_METADATA_SUMMARY`;
export const SET_CLUSTER_METADATA_SUMMARY_LOADING = `${PREFIX}_SET_CLUSTER_METADATA_SUMMARY_LOADING`;
export const SET_USER_TAGS = `${PREFIX}_SET_USER_TAGS`;
export const SET_DATASET_TAGS = `${PREFIX}_SET_DATASET_TAGS`;
export const UPDATE_USER_TAGS = `${PREFIX}_UPDATE_USER_TAGS`;
export const IS_USER_TAG_LOADING = `${PREFIX}_IS_USER_TAG_LOADING`;
export const UPDATE_FILE_SELECTION = `${PREFIX}_UPDATE_FILE_SELECTION`;

export const SET_NAVIGATION_CONTEXT = `${PREFIX}_SET_NAVIGATION_CONTEXT`;

export const SET_IS_IMAGE_UPLOADING = `${PREFIX}_SET_IS_IMAGE_UPLOADING`;
export const HOVERED_FILE_STATE = `${PREFIX}_HOVERED_FILE_STATE`;

export const SET_FETCH_ERROR = `${PREFIX}_SET_FETCH_ERROR`;

export const SET_DATA_METADATA_SUMMARY = `${PREFIX}_SET_DATA_METADATA_SUMMARY`;
export const SET_DATA_METADATA_SUMMARY_LOADING = `${PREFIX}_SET_DATA_METADATA_SUMMARY_LOADING`;

export const SET_AUTOCOMPLETE_SUGGESTIONS = `${PREFIX}_SET_AUTOCOMPLETE_SUGGESTIONS`;
export const SET_RECENT_SEARCHES = `${PREFIX}_SET_RECENT_SEARCHES`;
export const SET_AUTOCOMPLETE_LOADING = `${PREFIX}_SET_AUTOCOMPLETE_LOADING`;
export const SET_AUTOCOMPLETE_NO_RESULTS_FLAG = `${PREFIX}_SET_AUTOCOMPLETE_NO_RESULTS_FLAG`;
export const SET_VERTEX_PREVIEWS_COUNT_CONTEXT = `${PREFIX}_SET_VERTEX_PREVIEWS_COUNT_CONTEXT`;

export const SET_IMAGE_PAGE_PARAMS_CONTEXT = `${PREFIX}_SET_IMAGE_PAGE_PARAMS_CONTEXT`;
export const SET_VERTEX_CONTEXT = `${PREFIX}_SET_VERTEX_CONTEXT`;

export const SET_ASSIGNED_TAGS = `${PREFIX}_SET_ASSIGNED_TAGS`;
export const SET_SHOW_EXPORT_MODAL = `${PREFIX}_SET_SHOW_EXPORT_MODAL`;

export const SET_EXPORT_PROGRESS = `${PREFIX}_SET_EXPORT_PROGRESS`;
export const SET_SHOW_SHARING_MENU = `${PREFIX}_SET_SHOW_SHARING_MENU`;
export const SET_USERS_WITH_ACCESS = `${PREFIX}_SET_USERS_WITH_ACCESS`;
