const ErrorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="87"
      height="76"
      viewBox="0 0 87 76"
      fill="none"
    >
      <path
        d="M34 48.1882C34 49.7689 35.7473 50.7249 37.0785 49.8724L53.7697 39.1843C54.9984 38.3975 54.9984 36.6025 53.7697 35.8157L37.0785 25.1276C35.7473 24.2751 34 25.2311 34 26.8118V48.1882ZM16.4 63C14.64 63 13.1333 62.3758 11.88 61.1273C10.6267 59.8789 10 58.3781 10 56.625V18.375C10 16.6219 10.6267 15.1211 11.88 13.8727C13.1333 12.6242 14.64 12 16.4 12H67.6C69.36 12 70.8667 12.6242 72.12 13.8727C73.3733 15.1211 74 16.6219 74 18.375V56.625C74 58.3781 73.3733 59.8789 72.12 61.1273C70.8667 62.3758 69.36 63 67.6 63H16.4Z"
        fill="#313745"
      />
      <rect
        x="3.59327"
        y="7.54675"
        width="6"
        height="99.9417"
        transform="rotate(-49.4729 3.59327 7.54675)"
        fill="#313745"
        stroke="#151928"
        strokeWidth="4"
      />
    </svg>
  );
};

export default ErrorIcon;
