import { useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchUsersWithAccess,
  setShowSharingMenu,
  submitRevokeAccessRequest,
  submitShareInviteRequest,
} from "redux/SingleDataset/actions";
import {
  getDataset,
  getShowSharingMenu,
  getUsersWithAccess,
} from "redux/SingleDataset/selectors";
import classNames from "classnames";
import styles from "./style.module.scss";
import { State } from "redux/store";
import { MdArrowOutward, MdClose } from "react-icons/md";
import { CgSpinner } from "react-icons/cg";
import { isValidEmail, useWindowResize } from "helpers/utility/utilities";
import VLAvatar from "views/uikit/VLAvatar";
import PublicDatasetIcon from "assets/icons/PublicDatasetIcon";
import TrashIcon from "assets/icons/TrashIcon";

interface userFromApi {
  user_id: string;
  email: string;
  name: string;
  avatar_uri: string;
  is_internal_user: string;
}

interface SharingMenuProps {
  showSharingMenu: boolean;
  dataset: any;
  usersWithAccess: userFromApi[];
}

const UserWithAccessRow = ({
  user,
  isOwner,
  datasetID,
}: {
  user: userFromApi;
  isOwner: boolean;
  datasetID: string;
}) => {
  const [revokeInProgress, setRevokeInProgress] = useState<boolean>(false);
  const dispatch = useDispatch();
  const onRemoveUser = async (userId: string) => {
    if (isOwner) {
      return;
    }
    setRevokeInProgress(true);
    await dispatch(submitRevokeAccessRequest(datasetID, userId) as any);
    await dispatch(fetchUsersWithAccess(datasetID) as any);
    setRevokeInProgress(false);
  };

  const renderRowEnd = () => {
    if (isOwner) {
      return "Dataset Owner";
    }
    if (revokeInProgress) {
      return (
        <div className={styles.spinner}>
          <CgSpinner size={"20px"} />
        </div>
      );
    } else {
      return <TrashIcon />;
    }
  };
  return (
    <div
      className={classNames(styles.userWithAccessRow, {
        [styles.datasetOwnerRow]: isOwner,
      })}
      key={user.user_id}
    >
      <div>
        <VLAvatar size="xsmall" userAvatar={user.avatar_uri} />
      </div>
      <div>
        <div>{user.name}</div>
        <div>{user.email}</div>
      </div>
      <div
        className={classNames(styles.userRowEnd, {
          [styles.trashIcon]: !isOwner,
        })}
        onClick={() => onRemoveUser(user.user_id)}
      >
        {renderRowEnd()}
      </div>
    </div>
  );
};

const SharingMenu = ({
  showSharingMenu,
  dataset,
  usersWithAccess,
}: SharingMenuProps) => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const [insertedEmail, setInsertedEmail] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [inviteInProgress, setInviteInProgress] = useState<boolean>(false);
  const [placeholdersCount, setPlaceholdersCount] = useState<number>(0);
  const { height } = useWindowResize();

  useEffect(() => {
    if (insertedEmail.length === 0 || isValidEmail(insertedEmail)) {
      setErrorMsg("");
    } else {
      setErrorMsg("Please enter a valid email.");
    }
  }, [insertedEmail]);

  useEffect(() => {
    if (showSharingMenu) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [showSharingMenu]);

  useEffect(() => {
    if (dataset?.id && showSharingMenu) {
      dispatch(fetchUsersWithAccess(dataset?.id) as any);
    }
  }, [showSharingMenu, dataset, dispatch]);

  useEffect(() => {
    const menuContainer = containerRef.current;
    let totalHeight;
    if (menuContainer) totalHeight = menuContainer.offsetHeight - 220;
    if (totalHeight) {
      // 67 is a single element's height,
      let requiredCount = Math.round(totalHeight / 67);

      if (usersWithAccess.length < requiredCount) {
        setPlaceholdersCount(requiredCount - usersWithAccess.length);
      } else {
        setPlaceholdersCount(0);
      }
    }
  }, [showSharingMenu, usersWithAccess, containerRef, height]);

  const inviteBtnDisabled = useMemo(() => {
    return !insertedEmail || !isValidEmail(insertedEmail);
  }, [insertedEmail]);
  const usersWithoutOwner = useMemo(() => {
    return usersWithAccess.filter(
      (user) => dataset?.created_by && dataset?.created_by !== user.user_id
    );
  }, [usersWithAccess, dataset?.created_by]);
  function handleOutsideClick(event: MouseEvent) {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      dispatch(setShowSharingMenu(false));
    }
  }

  const handleCloseIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    dispatch(setShowSharingMenu(false));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInsertedEmail(value.trim());
  };

  const handleSendInvitation = async () => {
    setInviteInProgress(true);
    const response = await dispatch(
      submitShareInviteRequest(dataset?.id, insertedEmail) as any
    );
    if (response.status === 404) {
      setInviteInProgress(false);
      setErrorMsg("Email address not found. Please invite a registered user.");
    } else {
      await dispatch(fetchUsersWithAccess(dataset?.id) as any);
      setInsertedEmail("");
      setInviteInProgress(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSendInvitation();
    }
  };

  const handleSendInvitationClick = (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    handleSendInvitation();
  };

  const renderOwnerRow = () => {
    if (!dataset?.created_by) {
      return <noscript />;
    }
    const ownerUser = usersWithAccess.find(
      (userInfo) => userInfo.user_id === dataset?.created_by
    );
    if (!ownerUser) {
      return <noscript />;
    }
    return (
      <UserWithAccessRow
        user={ownerUser}
        isOwner={true}
        datasetID={dataset?.id}
      />
    );
  };

  const renderCollaborators = () => {
    if (usersWithoutOwner.length === 0) {
      return <noscript />;
    }
    return (
      <>
        <div className={styles.collaboratorsTitle}>
          <PublicDatasetIcon />
          Current Collaborators
        </div>
        {usersWithoutOwner.map((user) => (
          <UserWithAccessRow
            user={user}
            isOwner={false}
            datasetID={dataset?.id}
          />
        ))}
      </>
    );
  };

  const renderPlaceholders = () => {
    if (placeholdersCount === 0) {
      return <noscript />;
    }
    return [...Array(placeholdersCount)].map((_, index) => (
      <div key={index} className={styles.placeholderBox}></div>
    ));
  };

  return (
    <div
      className={classNames(styles.sharingMenuBackground, {
        [styles.sharingMenuBackgroundHidden]: !showSharingMenu,
      })}
    >
      <div
        ref={containerRef}
        className={classNames(styles.sharingMenuContainer, {
          [styles.sharingMenuContainerHidden]: !showSharingMenu,
          [styles.hideScroll]: placeholdersCount > 0,
        })}
      >
        <div className={styles.closeIcon} onClick={handleCloseIconClick}>
          <MdClose />
        </div>
        <div className={styles.sharingMenuHeader}>
          <MdArrowOutward size={"18px"} />
          Share this Dataset
        </div>
        <div className={styles.sharingMenuInvite}>
          <input
            type="text"
            placeholder="Invite others by email"
            className={classNames(styles.sharingMenuInput, {
              [styles.sharingMenuInputDisabled]: errorMsg.length > 0,
            })}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            value={insertedEmail}
          />
          <div
            className={classNames(styles.sharingMenuInviteButton, {
              [styles.sharingMenuInviteButtonDisabled]: inviteBtnDisabled,
            })}
            onClick={handleSendInvitationClick}
          >
            {inviteInProgress ? (
              <div className={styles.spinner}>
                <CgSpinner size={"20px"} />
              </div>
            ) : (
              "SEND"
            )}
          </div>
        </div>
        {errorMsg.length > 0 && (
          <div className={styles.sharingMenuInputErrorMsg}>{errorMsg}</div>
        )}
        {renderOwnerRow()}
        {renderCollaborators()}
        {renderPlaceholders()}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    showSharingMenu: getShowSharingMenu(state),
    dataset: getDataset(state),
    usersWithAccess: getUsersWithAccess(state),
  };
};

export default connect(mapStateToProps)(SharingMenu);
