import { formatBytes } from "helpers/utility/formatters";
import {
  setUploadStatus,
  setUserInitiatedCreation,
  startProcess,
} from "redux/CreateDataset/actions";
import { LimitExceeded } from "extractedBackendTypes";
import ArrowIcon from "assets/icons/ArrowIcon";
import { useDispatch } from "react-redux";
import XIcon from "assets/icons/XIcon";
import { RefObject } from "react";

import styles from "./style.module.scss";
import { StepTileProgressStatus } from "types";

const UploadBanner = ({
  bannerRef,
  showBanner,
  newDatasetId,
  toggleShowBanner,
  limitExceededDetails,
  uploadFinished,
}: UploadBannerProps) => {
  const dispatch = useDispatch();
  let uploadLimitLabel = "";
  let uploadLimitNote = "";
  if (
    limitExceededDetails &&
    limitExceededDetails.limit_type === "MAX_DATASET_RAW_SIZE"
  ) {
    uploadLimitLabel = `You current plan is limited to ${formatBytes(
      limitExceededDetails.limit_value
    )}. Your upload exceeds this limit.`;
    uploadLimitNote = `Note that only ${formatBytes(
      limitExceededDetails.limit_value
    )} of files will be processed.`;
  }
  if (
    limitExceededDetails &&
    limitExceededDetails.limit_type === "MAX_USER_QUOTA"
  ) {
    uploadLimitLabel = `You current plan is limited to ${limitExceededDetails.limit_value} files. Your upload exceeds this limit.`;
    uploadLimitNote = `Note that only ${limitExceededDetails.limit_value} files will be processed.`;
  }
  const handleSaveButtonClick = () => {
    toggleShowBanner();
    if (uploadFinished) {
      dispatch(startProcess(newDatasetId) as any);
    } else {
      dispatch(setUserInitiatedCreation(true));
      dispatch(setUploadStatus(StepTileProgressStatus.IN_PROGRESS));
    }
  };

  const renderDetails = () => {
    if (limitExceededDetails) {
      return (
        <div className={styles.description}>
          {uploadLimitLabel}
          <ul>
            <li>
              Please contact{" "}
              <a
                className={styles.supportEmailText}
                href="mailto:support@visual-layer.com"
              >
                support@visual-layer.com
              </a>{" "}
              to upgrade your plan or press “Next” to proceed.
            </li>
            <li>{uploadLimitNote}</li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className={styles.description}>
          Click 'Next' to proceed with the upload. Please avoid navigating away
          from this view during the upload process. If you need to use another
          view, please open a separate tab in your browser.
        </div>
      );
    }
  };

  return showBanner ? (
    <div className={styles.uploadBanner} ref={bannerRef}>
      <div className={styles.inputTitleContainer}>
        <div className={styles.titleIcon}>
          <ArrowIcon color="#fff" />
        </div>
        <span className={styles.title}>Upload Data</span>
        <div className={styles.crossBtn} onClick={toggleShowBanner}>
          <XIcon color="#fff" />
        </div>
      </div>

      {renderDetails()}

      <div className={styles.actionBtnContainer}>
        <div className={styles.proceedBtn} onClick={handleSaveButtonClick}>
          Next
        </div>
      </div>
    </div>
  ) : (
    <noscript />
  );
};

export default UploadBanner;

interface UploadBannerProps {
  bannerRef: RefObject<HTMLDivElement>;
  showBanner: boolean;
  newDatasetId: string;
  toggleShowBanner: () => void;
  limitExceededDetails: LimitExceeded | null;
  uploadFinished: boolean;
}
