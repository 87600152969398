import { ReactNode, useState, useRef, useEffect } from "react";
import TooltipWrapper from "views/uikit/TooltipWrapper";
import AccordianHeader from "./AccordianHeader";
import { FilterBehavior } from "types";

import styles from "./style.module.scss";

const VLAccordian = ({
  title,
  children,
  icon,
  customWidth,
  page,
  initiallyClosed,
  hideInfo,
  bgColor,
  padding,
  height,
  borderRadius,
  border,
  fontSize,
  selectionCount,
  firstSelectionName,
  type,
  allowedClickedClassNames,
  onClose,
  behavior,
  tooltipContent,
  tooltipID,
  tooltipWidth,
  customIconMarginRight,
  showCrossNotChevron,
  handleCrossBtnClick,
  navigationClusterLoader,
  childrenBgColor,
  roundCorners,
  category,
  showTitleOnHover,
}: VLAccordianTypes) => {
  const initialIsExpanded = initiallyClosed ? false : true;
  const [isExpanded, setIsExpanded] = useState(initialIsExpanded);
  const childRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const dynamicStyle = {
    width: customWidth ? customWidth : undefined,
    borderRadius: roundCorners ? roundCorners : undefined,
    overflow: roundCorners ? "hidden" : undefined,
  };

  const toggleAccordion = (): any => {
    setIsExpanded(!isExpanded);
  };

  const handleOutsideClick = (e: any) => {
    if (
      childRef.current &&
      headerRef.current &&
      !childRef.current.contains(e.target) &&
      !headerRef.current.contains(e.target)
    ) {
      const internaleClick =
        !!allowedClickedClassNames &&
        allowedClickedClassNames.includes(e.target.className);
      if (!!type && isExpanded && !internaleClick) {
        if (onClose) {
          onClose();
        }
        setIsExpanded(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [isExpanded]);

  const dynamicAccordianWrapperClassName = () => {
    let className = styles.accordianWrapper;
    if (behavior === "HIDE") {
      className = styles.hiddenAccordianWrapper;
    } else if (behavior === "GREY_OUT") {
      className = styles.disabledAccordianWrapper;
    }
    return className;
  };

  return (
    <div
      className={dynamicAccordianWrapperClassName()}
      style={dynamicStyle}
      data-tooltip-content={tooltipContent}
      data-tooltip-id={tooltipID}
      data-tooltip-place="top"
    >
      <AccordianHeader
        icon={icon}
        customIconMarginRight={customIconMarginRight}
        title={!!firstSelectionName ? `${title}: ${firstSelectionName}` : title}
        isExpanded={isExpanded}
        toggleAccordion={toggleAccordion}
        page={page}
        hideInfo={hideInfo}
        bgColor={bgColor}
        padding={padding}
        height={height}
        borderRadius={borderRadius}
        border={border}
        fontSize={fontSize}
        selectionCount={selectionCount}
        headerRef={headerRef}
        type={type}
        showCrossNotChevron={showCrossNotChevron}
        handleCrossBtnClick={handleCrossBtnClick}
        navigationClusterLoader={navigationClusterLoader}
        category={category}
        showTitleOnHover={showTitleOnHover}
      />
      {isExpanded && (
        <div
          ref={childRef}
          className={styles.accordianContent}
          style={
            childrenBgColor ? { backgroundColor: childrenBgColor } : undefined
          }
        >
          {children}
        </div>
      )}
      {tooltipID && !isExpanded && (
        <TooltipWrapper id={tooltipID} maxWidth={tooltipWidth} />
      )}
    </div>
  );
};
export default VLAccordian;

type VLAccordianTypes = {
  title: string;
  children: ReactNode;
  icon?: any;
  customWidth?: string;
  page?: string;
  initiallyClosed?: boolean;
  isNoneSelected?: boolean;
  hideInfo?: boolean;
  bgColor?: string;
  padding?: string;
  height?: string;
  border?: string;
  borderRadius?: string;
  fontSize?: string;
  handleFilterSelection?: any;
  selectionCount?: number;
  firstSelectionName?: string;
  type?: string;
  allowedClickedClassNames?: string[];
  onClose?: Function;
  behavior?: FilterBehavior;
  tooltipContent?: string;
  tooltipID?: string;
  tooltipWidth?: string;
  customIconMarginRight?: string;
  showCrossNotChevron?: boolean;
  handleCrossBtnClick?: () => void;
  navigationClusterLoader?: boolean;
  childrenBgColor?: string;
  roundCorners?: string;
  category?: string;
  showTitleOnHover?: string | boolean;
};
