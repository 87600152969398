import TrashIcon from "assets/icons/TrashIcon";
import { useDispatch } from "react-redux";
import { ReactNode } from "react";
import { useParams } from "react-router";
import XIcon from "assets/icons/XIcon";
import { deleteDataset, fetchDatasets } from "redux/Datasets/actions";
import {
  amplitudeTrack,
  trackNewDatasetIngestionEvent,
} from "helpers/utility/amplitude";
import { AMP_DATASET_DELETED } from "helpers/constants/amplitudeEvents";
import {
  AMP_DATASET_ID,
  AMP_DATASET_NAME,
  AMP_DATASET_SOURCE,
} from "helpers/constants/amplitudeProperties";

import styles from "./style.module.scss";

const TrashIconWrapper = () => {
  return (
    <div className={styles.trashIcon}>
      <TrashIcon color="#D6493E" />
    </div>
  );
};

function DatasetDeletionModal({
  datasetId,
  datasetName,
  datasetSource,
  setShowModal,
  showModal,
  handleNavigate,
  icon = <TrashIconWrapper />,
  modalTitle = "Delete Dataset",
  modalSubtitle = "Are you sure you want to delete this dataset?",
}: DatasetDeletionModalProps) {
  const dispatch = useDispatch();
  const { newDatasetId } = useParams();

  const _delete = async () => {
    await dispatch(deleteDataset(datasetId) as any);
    dispatch(fetchDatasets(true) as any);

    if (handleNavigate) {
      handleNavigate();
    }
  };

  const handleCancelButtonClick = () => {
    setShowModal(false);
  };

  const handleConfirmButtonClick = async () => {
    _delete();

    if (newDatasetId) {
      trackNewDatasetIngestionEvent(AMP_DATASET_DELETED);
    } else {
      amplitudeTrack(AMP_DATASET_DELETED, {
        [AMP_DATASET_NAME]: datasetName,
        [AMP_DATASET_ID]: datasetId,
        [AMP_DATASET_SOURCE]: datasetSource,
      });
    }

    setShowModal(false);
  };

  const handleCrosClick = () => {
    setShowModal(false);
  };

  return showModal ? (
    <div className={styles.overlay} onClick={(e) => e.stopPropagation()}>
      <div className={styles.deleteModalContainer}>
        <div className={styles.deleteModalHeader}>
          <div className={styles.modalTitleContainer}>
            {icon}

            <span className={styles.modalTitle}>{modalTitle}</span>
          </div>

          <span onClick={handleCrosClick} className={styles.crossIcon}>
            <XIcon />
          </span>
        </div>

        <span className={styles.divider} />

        <div className={styles.modalSubtitle}>{modalSubtitle}</div>

        <span className={styles.modalDescription}>
          Confirming this action will permanently remove the Dataset, images,
          clusters, labels and all their related information.
        </span>
        <span className={styles.divider} />

        <div className={styles.buttonContainer}>
          <div
            className={styles.cancelButton}
            onClick={handleCancelButtonClick}
          >
            Cancel
          </div>
          <div
            className={styles.confirmButton}
            onClick={handleConfirmButtonClick}
          >
            Confirm
          </div>
        </div>
      </div>
    </div>
  ) : (
    <noscript />
  );
}

export default DatasetDeletionModal;

interface DatasetDeletionModalProps {
  datasetId: string;
  datasetName: string;
  datasetSource: string;
  showModal: boolean;
  setShowModal: any;
  handleNavigate?: () => void;
  modalTitle?: string;
  modalSubtitle?: string;
  icon?: ReactNode;
}
