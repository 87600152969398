import { createSelector } from "@reduxjs/toolkit";
import { State } from "redux/store";
import {
  DataSources,
  DatasetStatusEnum,
  FilesUploadSize,
  LastUsedData,
  StepTileProgressStatus,
} from "types";
import { Event } from "extractedBackendTypes";

const EVENT_TYPES_TO_EXCLUDE: string[] = [
  "S3ValidURL",
  "S3FileDownloaded",
  "S3MediaPreview",
  "S3NoPreview",
  "FileMediaPreview",
  "FileNoPreview",
  "DataSourceEvent",
  "FilesChunkUploaded",
  "AnnotationsCleared",
  "FilesUploadCompleted",
  "LimitNotExceeded",
  "FilesUploadStart",
];
const state = (state: State) => state.createDataset;

export const getConnectStatus = createSelector(state, (state) => {
  return state.connectStatus;
});

export const getAnnotationStatus = createSelector(state, (state) => {
  return state.annotationStatus;
});

export const getUploadStatus = createSelector(
  state,
  getAnnotationStatus,
  (state, annotationStatus): StepTileProgressStatus => {
    if (
      annotationStatus === StepTileProgressStatus.COMPLETE ||
      state.uploadStatus === StepTileProgressStatus.FAILED
    ) {
      return state.uploadStatus;
    } else {
      return StepTileProgressStatus.DISABLED;
    }
  }
);

export const getIndexingStatus = createSelector(state, (state) => {
  return state.indexingStatus;
});

export const getS3FetchProgress = createSelector(state, (state) => {
  return state.s3FetchProgress;
});

export const getPreviewData = createSelector(state, (state) => {
  return state.previewData;
});

export const getPreviewDataSource = createSelector(
  state,
  (state): DataSources | null => {
    return state.previewDataSource;
  }
);

export const getNewDataset = createSelector(state, (state) => {
  return state.newDataset;
});

export const getNewDatasetId = createSelector(getNewDataset, (newDataset) => {
  return newDataset?.id;
});

export const getNewDatasetName = createSelector(getNewDataset, (newDataset) => {
  return newDataset ? newDataset.display_name : null;
});

export const getActivityLogs = createSelector(state, (state) => {
  return state.activityLogs;
});

// We filter out logs we don't want to display
export const getFilteredActivityLogs = createSelector(
  getActivityLogs,
  (activityLogs) => {
    return activityLogs.filter(
      (log: Event) => !EVENT_TYPES_TO_EXCLUDE.includes(log.event_type)
    );
  }
);

export const getimageErrorFileName = createSelector(state, (state) => {
  return state.imageErrorFileName;
});

export const getActivityOffset = createSelector(state, (state) => {
  return state.activityOffset;
});

export const getIsFatalError = createSelector(state, (state) => {
  return state.isFatalError;
});

export const getFilesUploadTransId = createSelector(state, (state) => {
  return state.filesUploadTransId;
});

export const getLimitExceededDetails = createSelector(state, (state) => {
  return state.limitExceededDetails;
});

export const getIsDatasetIndexing = createSelector(state, (state) => {
  return state.indexingStatus === StepTileProgressStatus.IN_PROGRESS;
});

export const getAreAnnotationsFetched = createSelector(state, (state) => {
  return state.areAnnotationsFetched;
});

export const getStatusCallInProgress = createSelector(state, (state) => {
  return state.statusCallInProgress;
});
export const getIsLoading = createSelector(state, (state) => {
  return state.isLoading;
});

export const getShowDeleteDatasetModal = createSelector(state, (state) => {
  return state.showDeleteDatasetModal;
});

export const getNewDatasetStatus = createSelector(state, (state) => {
  return state.datasetStatus;
});

export const getIsUploadInProgress = createSelector(state, (state) => {
  return state.uploadStatus === StepTileProgressStatus.IN_PROGRESS;
});

export const getIsNewDatasetInitializing = createSelector(
  getNewDataset,
  (newDataset) => {
    if (newDataset) {
      return newDataset.status === DatasetStatusEnum.INITIALIZING;
    } else {
      return false;
    }
  }
);

export const getLastUsedData = createSelector(state, (state): LastUsedData => {
  return state.lastUsedData;
});

export const getUploadFInished = createSelector(state, (state): boolean => {
  return state.uploadFinished;
});

export const getUserInitiatedCreation = createSelector(
  state,
  (state): boolean => {
    return state.userInitiatedCreation;
  }
);

export const getFilesUploadSize = createSelector(
  state,
  (state): FilesUploadSize => {
    return state.filesUploadSize;
  }
);

export const getFilesUploadProgress = createSelector(state, (state): number => {
  return state.filesUploadProgress;
});

export const getShowActivityLogModal = createSelector(state, (state) => {
  return state.showActivityLogModal;
});

export const getIsNewDatasetReady = createSelector(
  getNewDataset,
  (newDataset) => {
    if (newDataset) {
      return newDataset.status === DatasetStatusEnum.READY;
    } else {
      return false;
    }
  }
);
