import { useState, useRef, useEffect } from "react";

import { GoCheck } from "react-icons/go";
import { TbCircleXFilled } from "react-icons/tb";

import { LIGHT_RED, PARROT_GREEN } from "helpers/constants/colors";
import { validateLocalUpload } from "helpers/validations";
import { formatBytes } from "helpers/utility/formatters";

import uploadIcon from "assets/img/upload-icon.svg";
import addFileIcon from "assets/img/add-file-icon.svg";
import errorfileIcon from "assets/img/file-icon-red.svg";
import greenFileIcon from "assets/img/file-icon-green.svg";

import styles from "./style.module.scss";

const DragDropWithFile = ({
  file,
  setIsDropping,
  setFile,
  fileError,
  setReplace,
}: any) => {
  const cancelUpload = () => {
    setIsDropping(false);
    setFile(null);
  };

  const handleReplace = () => {
    cancelUpload();
    setReplace(true);
  };

  return (
    <div
      className={
        fileError ? styles.errorFileContainer : styles.successFileContainer
      }
    >
      <div className={styles.fileDetails}>
        <div className={styles.leftSide}>
          <div>
            <img src={fileError ? errorfileIcon : greenFileIcon} alt="" />
          </div>
          <div
            className={styles.fileNameSection}
            style={fileError ? { color: LIGHT_RED } : undefined}
          >
            <span className={styles.fileName}>{file.name}</span>
            <span className={styles.fileSize}>{formatBytes(file.size)}</span>
          </div>
        </div>
        <div className={styles.checkIcon}>
          {fileError ? (
            <TbCircleXFilled
              color={LIGHT_RED}
              size="24px"
              onClick={cancelUpload}
            />
          ) : (
            <GoCheck color={PARROT_GREEN} />
          )}
        </div>
      </div>
      {!fileError && (
        <span className={styles.cancelUpload} onClick={handleReplace}>
          Replace
        </span>
      )}
    </div>
  );
};

const DragDropWithoutFile = ({
  isDropping,
  inputRef,
  setIsDropping,
  setFile,
  setFileError,
  replace,
  setReplace,
}: any) => {
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDropping(true);
    } else if (e.type === "dragleave") {
      setIsDropping(false);
    }
  };

  const handleDrop = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDropping(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      if (validateLocalUpload(file)) {
        setFileError(false);
      } else {
        setFileError(true);
      }
      setFile(file);
    }
  };

  const uploadFile = async (e: any) => {
    setReplace(false);
    const file = e.target.files[0];
    if (validateLocalUpload(file)) {
      setFileError(false);
    } else {
      setFileError(true);
    }
    setFile(file);
  };

  const dynamicClass = isDropping ? styles.dropContainer : styles.dropZone;

  useEffect(() => {
    if (replace) {
      inputRef.current?.click();
    }
    //eslint-disable-next-line
  }, []);
  return (
    <div
      className={dynamicClass}
      onDragOver={handleDragOver}
      onDragEnter={handleDragOver}
      onDragLeave={handleDragOver}
      onDrop={handleDrop}
    >
      {isDropping ? (
        <div className={styles.droppingBox}>
          <div>Drop file here</div>
          <div className={styles.addFileIcon}>
            <img src={addFileIcon} alt="" />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.uploadImg}>
            <img src={uploadIcon} alt="" />
          </div>
          <div className={styles.dragDropText}>
            Drag & Drop or{" "}
            <span
              className={styles.chooseFileText}
              onClick={() => inputRef?.current?.click()}
            >
              Choose A File
            </span>
          </div>
          <input
            type="file"
            onChange={(e) => uploadFile(e)}
            hidden
            ref={inputRef}
          />
        </>
      )}
    </div>
  );
};

function DragDrop({
  file,
  setFile,
  fileError,
  setFileError,
  isLocalUpload,
}: any) {
  const [isDropping, setIsDropping] = useState(false);
  const inputRef = useRef<any>(null);
  const [replace, setReplace] = useState(false);

  return (
    <form
      className={isLocalUpload ? styles.dragDropSection : "d-none"}
      onSubmit={(e) => e.preventDefault()}
    >
      <span className={styles.fileTypeText}>
        Please provide a .zip or .tar file that contains images
      </span>
      {file ? (
        <DragDropWithFile
          file={file}
          setFile={setFile}
          setIsDropping={setIsDropping}
          fileError={fileError}
          setReplace={setReplace}
        />
      ) : (
        <DragDropWithoutFile
          setFile={setFile}
          inputRef={inputRef}
          fileError={fileError}
          isDropping={isDropping}
          setFileError={setFileError}
          setIsDropping={setIsDropping}
          replace={replace}
          setReplace={setReplace}
        />
      )}
    </form>
  );
}

export default DragDrop;
