import { connect, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";

import { getShowMobileErrorModal } from "redux/Modals/selectors";
import { setShowMobileErrorModal } from "redux/Modals/actions";
import { State } from "redux/store";

import { AiFillInfoCircle } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";

import { YELLOW } from "helpers/constants/colors";

import mobileErrorImage from "assets/pngImages/mobile-error-image.png";
import styles from "./style.module.scss";

const MobileScreenErrorModal = ({ showModal }: any) => {
  const dispatch = useDispatch();
  const handleCloseButtonClick = () => {
    dispatch(setShowMobileErrorModal(false));
  };

  return (
    <Modal
      animation={false}
      show={showModal}
      onHide={handleCloseButtonClick}
      className={styles.modalWrapper}
      backdropClassName={styles.backgroundColor}
    >
      <Modal.Body onClick={(e: any) => e.stopPropagation()}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <div className={styles.modalHeaderTitleBox}>
              <AiFillInfoCircle color={YELLOW} size={27} />
              <span className={styles.modalHeaderTitle}>Heads up</span>
            </div>
            <button
              className={styles.modalCloseBtn}
              onClick={handleCloseButtonClick}
            >
              <MdOutlineClose size="18px" />
            </button>
          </div>
          <div className={styles.modalBody}>
            <img
              src={mobileErrorImage}
              className={styles.mobileErrorImage}
              loading="lazy"
              alt=""
            />
            <span className={styles.description}>
              We're currently working on our mobile experience.
              <br />
              In the meantime, for the best user experience, we recommend
              accessing our platform via a desktop.
              <br />
              We appreciate your understanding and patience.
            </span>

            <button
              className={styles.okButton}
              onClick={handleCloseButtonClick}
            >
              Got it
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state: State) => {
  return {
    showModal: getShowMobileErrorModal(state),
  };
};

export default connect(mapStateToProps)(MobileScreenErrorModal);
