import { BsArrowRightCircleFill } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import { State } from "redux/store";

import { getLimitError } from "redux/Datasets/selectors";
import { setLimitError } from "redux/Datasets/actions";
import { YELLOW } from "helpers/constants/colors";

import MaxAmountDataset from "./MaxAmountDataset";
import VLModal from "views/uikit/VLModal";

import styles from "./style.module.scss";

function LimitExceedModal({ limitError }: { limitError: string | null }) {
  const dispatch = useDispatch();

  const closeMaxLimitModal = () => {
    dispatch(setLimitError(null));
  };

  return limitError ? (
    <VLModal closeLimitModal={closeMaxLimitModal} showModal={limitError}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          {/* Max Amount first Modal Heading */}
          <BsArrowRightCircleFill
            className={styles.arrowRight}
            color={YELLOW}
          />
          <span className={styles.freePlan}>Max amount of Datasets</span>
        </div>
        <div className={styles.modalBody}>
          {/* max amount first Modal */}
          <MaxAmountDataset limitErrorMessage={limitError} />
        </div>
      </div>
    </VLModal>
  ) : (
    <noscript />
  );
}

const mapStateToProps = (state: State) => {
  return { limitError: getLimitError(state) };
};

export default connect(mapStateToProps)(LimitExceedModal);
