// import { useState } from "react";
import styles from "./style.module.scss";

const MetadataToggleButton = ({
  color = "#fff",
  isOpen,
  toggle,
}: MetadataToggleButtonProps) => {
  // const [hovering, setHovering] = useState(false);
  const dynamicBackground = {
    backgroundColor: isOpen ? "#151928" : "#3D4355",
    // backgroundColor: isOpen || hovering ? "#151928" : "#3D4355",
  };
  return (
    <div
      // onMouseEnter={() => setHovering(true)}
      // onMouseLeave={() => setHovering(false)}
      className={styles.metadataButtonContainer}
      style={dynamicBackground}
      onClick={toggle}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="15"
        viewBox="0 0 19 15"
        fill="none"
      >
        <path
          d="M2 14C0.89543 14 0 13.1046 0 12V7C0 5.89543 0.895431 5 2 5H3C4.10457 5 5 5.89543 5 7V9.5V13.5C5 13.7761 4.77614 14 4.5 14H2ZM9 14C7.89543 14 7 13.1046 7 12V7V2C7 0.89543 7.89543 0 9 0H10C11.1046 0 12 0.895431 12 2V7V12C12 13.1046 11.1046 14 10 14H9ZM16 14C14.8954 14 14 14.2564 14 13.1518C14 12.9593 14 12.7428 14 12.5C14 10.3327 14 7.41622 14 5.49851C14 4.39394 14.8954 3.5 16 3.5H16.2857H17C18.1046 3.5 19 4.39543 19 5.5V12C19 13.1046 18.1046 14 17 14H16Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default MetadataToggleButton;

interface MetadataToggleButtonProps {
  color?: string;
  isOpen: boolean;
  toggle: any;
}
