import VideoIndicationIcon from "views/uikit/VideoIndicationIcon";
import useHoverDelay, { checkTallOrWideImage } from "helpers/utility/utilities";

import { useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./style.module.scss";
import ImageWithFallback from "views/components/ImageWithFallback";

const StripeImage = ({
  plainImage,
  thumbImage,
  videoURI,
  updateAssetData,
  showAssetPreview,
  hoverDelay,
  imageType,
}: StripeImageProps) => {
  const [loading, setLoading] = useState(true);

  const showIcon = !loading && !!videoURI;

  const tableColumnClassNames = classNames(styles.tableColumn, {
    [styles.tableColumnForAssetPreview]: showAssetPreview,
  });

  const updateAssetDataWithValues = () => {
    const source = plainImage.src;
    updateAssetData(plainImage.id, source);
  };

  const { handleMouseEnter, handleMouseLeave } = useHoverDelay(
    updateAssetDataWithValues,
    hoverDelay
  );

  return (
    <div
      className={tableColumnClassNames}
      onMouseOver={() => showAssetPreview && handleMouseEnter()}
      onMouseLeave={() => showAssetPreview && handleMouseLeave()}
    >
      {showIcon && <VideoIndicationIcon />}
      <ImageWithFallback
        thumbImage={thumbImage}
        plainImage={plainImage}
        onLoad={() => setLoading(false)}
        className={styles.singleSquareImage}
      />
    </div>
  );
};

const StripeImageBox = ({
  plainImages,
  thumbImages,
  videoURIs,
  roundBottom,
  imageType,
  updateAssetData,
  showAssetPreview,
  hoverDelay,
}: StripeImageBoxProps) => {
  const images = imageType === "IMAGE" ? plainImages : thumbImages;

  const [imageCount, setImageCount] = useState(0);
  const [gridLayout, setGridLayout] = useState<null | "tall" | "wide">(null);

  const stripeImagesContainerClassNames = classNames(
    styles.stripeImagesContainer,
    {
      [styles.roundBottom]: roundBottom,
    }
  );

  const gridStyle = () => {
    if (gridLayout) {
      const isTall = gridLayout === "tall";
      return {
        gridTemplateColumns: `repeat(${
          isTall ? imageCount : 1
        }, minmax(1px, 1fr))`,
        gridTemplateRows: `repeat(${
          isTall ? 1 : imageCount
        }, minmax(1px, 1fr))`,
      };
    }
    return undefined;
  };

  const mergedStyle: any = {
    ...gridStyle(),
    visibility: !!gridLayout ? "visible" : "hidden",
  };

  useEffect(() => {
    if (images.length > 0) {
      if (images.length === 3) setImageCount(3);
      else setImageCount(2);

      let wideCount = 0;
      let tallCount = 0;

      Promise.all(
        images.map(async (image) => {
          const type = await checkTallOrWideImage(image.src);
          if (type === "tall") tallCount += 1;
          else if (type === "wide") wideCount += 1;
        })
      ).then(() => {
        if (tallCount > wideCount) setGridLayout("tall");
        else setGridLayout("wide");
      });
    }
  }, [images]);

  return (
    <div className={stripeImagesContainerClassNames} style={mergedStyle}>
      {plainImages.length > 0 &&
        plainImages.map((plainImage, i: number) => {
          const thumbImage = thumbImages && thumbImages[i];
          return (
            i < imageCount * imageCount && (
              <StripeImage
                key={i}
                plainImage={plainImage}
                thumbImage={thumbImage}
                imageType={imageType}
                videoURI={videoURIs[i]}
                updateAssetData={updateAssetData}
                showAssetPreview={showAssetPreview}
                hoverDelay={hoverDelay}
              />
            )
          );
        })}
    </div>
  );
};

export default StripeImageBox;

interface BaseStripeImageProps {
  updateAssetData: (id: string, source: string) => void;
  imageType: "IMAGE" | "OBJECT";
  showAssetPreview: boolean;
  hoverDelay: number;
}

interface StripeImageBoxProps extends BaseStripeImageProps {
  plainImages: { src: string; id: string }[];
  thumbImages: { src: string; id: string }[];
  videoURIs: string[];
  roundBottom: any;
}

interface StripeImageProps extends BaseStripeImageProps {
  plainImage: { src: string; id: string };
  thumbImage: { src: string; id: string };
  videoURI: string;
}
