import { useMemo } from "react";
import { DATA_PAGE } from "helpers/constants/pages";
import { BRIGHT_BLUE } from "helpers/constants/colors";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  setCardslistLoading,
  setNavigationCluster,
  fetchDatasetLabels,
  fetchDatasetIssues,
  fetchUserTags,
} from "redux/SingleDataset/actions";
import VLAccordian from "views/components/Accordian";
import {
  DATA_PAGE_PARAMETER,
  PAGE_PARAMETER,
} from "helpers/constants/miscellaneous";

interface ClusterDetailsFilterProps {
  navigationCluster: any;
  datasetID: string;
  navigationClusterLoader: boolean;
}
const ClusterDetailsFilter = ({
  navigationCluster,
  datasetID,
  navigationClusterLoader,
}: ClusterDetailsFilterProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const clusterType = navigationCluster?.type === "IMAGES" ? "Image" : "Object";
  const clusterDisplayID = navigationCluster?.cluster_id.substring(0, 8);

  const formattedTitle = navigationCluster
    ? `${clusterType} Cluster #${clusterDisplayID}`
    : "Cluster ";

  const handleRemoveFilter = async () => {
    dispatch(setCardslistLoading(true));

    const path = `${location.pathname.split("/cluster")[0]}`;

    searchParams.delete(PAGE_PARAMETER);
    if (searchParams.has(DATA_PAGE_PARAMETER)) {
      const dataPageNumber = searchParams.get(DATA_PAGE_PARAMETER);
      if (dataPageNumber != null) {
        searchParams.set(PAGE_PARAMETER, dataPageNumber);
      }
      searchParams.delete(DATA_PAGE_PARAMETER);
    }

    const newSearchParams = "?" + searchParams.toString();

    const pathWithParams = path.concat(newSearchParams);
    navigate(pathWithParams);
    dispatch(fetchDatasetLabels(datasetID) as any);
    dispatch(fetchDatasetIssues(datasetID) as any);
    dispatch(fetchUserTags(datasetID) as any);
    dispatch(setNavigationCluster(null));
  };

  return (
    <VLAccordian
      title={formattedTitle}
      page={DATA_PAGE}
      customWidth={"max-content"}
      hideInfo={true}
      initiallyClosed={true}
      fontSize={"13px"}
      bgColor={BRIGHT_BLUE}
      borderRadius="5px"
      padding={"0 10px"}
      height="40px"
      border={"1px solid #fff"}
      tooltipContent={`Remove filter to go back to Dataset.`}
      tooltipID="data-page-cluster-details-filter-box"
      tooltipWidth="max-content"
      showCrossNotChevron={true}
      handleCrossBtnClick={handleRemoveFilter}
      navigationClusterLoader={navigationClusterLoader}
    >
      {/* TO DO */}
      {/* OptionsList that contains other clusters*/}
      <></>
    </VLAccordian>
  );
};
export default ClusterDetailsFilter;
