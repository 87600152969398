import React from "react";
import styles from "./style.module.scss";
import dangerIcon from "assets/img/danger.svg";

const ErrorComponent: React.FC<{}> = () => {
  const handleRetry = () => {
    window.location.reload();
  };
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <img src={dangerIcon} alt="danger-icon" className={styles.icon} />
      </div>
      <h1 className={styles.title}>Oops, something went wrong</h1>
      <p className={styles.message}>
        There was a problem connecting to Visual Layer.
        <br />
        Please{" "}
        <span className={styles.link} onClick={handleRetry}>
          refresh the page
        </span>{" "}
        or check your connection.
      </p>
    </div>
  );
};

export default ErrorComponent;
