import { State } from "redux/store";
import { ReactNode, useState } from "react";
import { connect, useDispatch } from "react-redux";
import TooltipWrapper from "views/uikit/TooltipWrapper";
import { useNavigate, useParams } from "react-router";
import { amplitudeTrack } from "helpers/utility/amplitude";
import { clearFilters, setShowSharingMenu } from "redux/SingleDataset/actions";
import { getPreviewData } from "redux/CreateDataset/selectors";
import previewInfoIcon from "assets/img/preview-info-icon.svg";

import { AMP_DATASET_EXPLORATION_EVENT__TAB_CHANGED } from "helpers/constants/amplitudeEvents";
import { AMP_DATASET_EXPLORATION_PROPERTY__TAB_NAME } from "helpers/constants/amplitudeProperties";

import { TabTypes } from "types";

import ExploreTabIcon from "assets/icons/ExploreTabIcon";
import DataTabIcon from "assets/icons/DataTabIcon";
import classNames from "classnames";
import styles from "./style.module.scss";
import { isSharingEnabled } from "redux/SingleDataset/selectors";
import { MdArrowOutward } from "react-icons/md";

interface TabProps {
  label: string;
  selected: boolean;
  onClick: () => void;
  icon: ReactNode;
}

interface TabSwitchProps {
  showTabs: TabTypes;
  previewCount: number;
  sharingEnabled: boolean;
}

const PreviewTitle = ({ previewCount }: { previewCount: number }) => {
  const renderPreviewCount = (count: number) => {
    const dynamicPreviewCount = `${count}  ${count > 1 ? "Images" : "Image"}`;
    return (
      <>
        <span className={styles.previewCount}>{dynamicPreviewCount}</span>
        <div
          className={styles.infoIcon}
          data-tooltip-id="previewCount-tooltip"
          data-tooltip-content={`Below is a preview of ${dynamicPreviewCount} generated from your data. You will be able to access full data once the upload is completed`}
          data-tooltip-place="right"
        >
          <img src={previewInfoIcon} alt="preview-info-icon" />
          <TooltipWrapper
            id="previewCount-tooltip"
            customClass="totalQuotaTooltip"
            maxWidth="425px"
          />
        </div>
      </>
    );
  };
  return (
    <div className={styles.previewTextContianer}>
      Preview{!!previewCount && renderPreviewCount(previewCount)}
    </div>
  );
};

const TABS = [
  {
    type: TabTypes.DATA_INGESTION,
    label: "Data",
    renderIcon: (selected: boolean) => <DataTabIcon selected={selected} />,
  },
  {
    type: TabTypes.DATA_EXPLORATION,
    label: "Explore",
    renderIcon: (selected: boolean) => <ExploreTabIcon selected={selected} />,
  },
];

const Tab = ({ icon, label, selected, onClick }: TabProps) => (
  <div
    className={classNames(styles.tabButton, { [styles.selected]: selected })}
    onClick={onClick}
  >
    {icon}
    {label}
    {selected && <div className={styles.selectedBorderBottom}></div>}
  </div>
);

const TabSwitch = ({
  showTabs,
  previewCount,
  sharingEnabled,
}: TabSwitchProps) => {
  const { datasetId, newDatasetId } = useParams<{
    datasetId: string;
    newDatasetId: string;
  }>();

  const initialTab = datasetId
    ? TabTypes.DATA_EXPLORATION
    : TabTypes.DATA_INGESTION;

  const [selectedTab, setSelectedTab] = useState<TabTypes>(initialTab); //does not contain the BOTH value

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePageClick = (value: TabTypes) => {
    if (selectedTab === value) return;
    setSelectedTab(value);

    if (value === TabTypes.DATA_EXPLORATION) {
      navigate(`/dataset/${newDatasetId}/data`);
    } else if (value === TabTypes.DATA_INGESTION) {
      dispatch(clearFilters() as any);
      navigate(`/dataset/${datasetId}/create`);
    }

    amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__TAB_CHANGED, {
      [AMP_DATASET_EXPLORATION_PROPERTY__TAB_NAME]: value,
    });
  };

  const handleShareBtnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    dispatch(setShowSharingMenu(true));
  };

  const filteredTabs =
    showTabs === TabTypes.ALL
      ? TABS
      : TABS.filter((tab) => tab.type === selectedTab);

  return (
    <div className={styles.pageSwitchContainer}>
      <div className={styles.tabContainer}>
        {filteredTabs.map((tab) => (
          <Tab
            key={tab.type}
            icon={tab.renderIcon(selectedTab === tab.type)}
            label={tab.label}
            selected={selectedTab === tab.type}
            onClick={() => handlePageClick(tab.type)}
          />
        ))}
      </div>

      {!!newDatasetId && <PreviewTitle previewCount={previewCount} />}
      {!newDatasetId && sharingEnabled && (
        <div className={styles.shareButton} onClick={handleShareBtnClick}>
          <MdArrowOutward size={"16px"} />
          Share
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    previewCount: getPreviewData(state)?.length,
    sharingEnabled: isSharingEnabled(state),
  };
};

export default connect(mapStateToProps)(TabSwitch);
