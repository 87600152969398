import { InventoryFilterData, SetStateFunction } from "types";
import { ChangeEvent, useEffect, useState } from "react";
import { BiChevronRight, BiX } from "react-icons/bi";
import SearchIcon from "assets/icons/SearchIcon";

import styles from "./style.module.scss";

interface SearchContainerProps {
  filterData: InventoryFilterData;
  setFilterData: SetStateFunction<InventoryFilterData>;
}

const SearchContainer = ({
  filterData,
  setFilterData,
}: SearchContainerProps) => {
  const [inputText, setInputText] = useState("");

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputText(value);
    if (filterData.searchValue && !value) {
      setFilterData({ ...filterData, searchValue: null });
    }
  };

  const handleKeydown = (e: any) => {
    if (!inputText || inputText === filterData.searchValue) {
      return;
    }
    if (e.keyCode === 13) {
      setFilterData({ ...filterData, searchValue: inputText });
    }
  };

  const handleClearSearch = () => {
    setInputText("");
    if (filterData.searchValue) {
      setFilterData({ ...filterData, searchValue: null });
    }
  };

  const handleSearchClick = () => {
    if (!inputText || inputText === filterData.searchValue) {
      return;
    }

    setFilterData({ ...filterData, searchValue: inputText });
  };

  useEffect(() => {
    if (!filterData.searchValue && inputText) {
      setInputText("");
    }
    //eslint-disable-next-line
  }, [filterData.searchValue]);

  return (
    <div
      className={styles.textualSearchContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.searchBarIcon}>
        <SearchIcon />
      </div>
      <div className={styles.inputBox}>
        <input
          type="search"
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleKeydown}
          placeholder="Search Datasets"
          className={styles.searchBarInput}
        />
        <div
          className={inputText ? styles.crossBtn : styles.disabledCrossBtn}
          onClick={handleClearSearch}
        >
          <BiX size="1.25em" />
        </div>
      </div>
      <div className={styles.searchBarButton} onClick={handleSearchClick}>
        <BiChevronRight size="1.5em" />
      </div>
    </div>
  );
};

export default SearchContainer;
