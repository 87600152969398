import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { isAnonymousUser, selectUser } from "redux/authReducer";
import { createNewDataset, resetStore } from "redux/CreateDataset/actions";

import {
  CREATE_NEW_DATASET,
  SIGNUP_ORIGIN,
} from "helpers/constants/amplitudeProperties";
import { getQueryParameter } from "helpers/utility/utilities";
import { NEW_DATASET_CREATION__QUERY_PARAMETER_KEY } from "helpers/constants/miscellaneous";
import {
  getDatasets,
  getIsInventoryFilteredEnabled,
  getIsNewDatasetImportEnabled,
} from "redux/Datasets/selectors";

import PublicHeader from "views/components/PublicHeader";
import HealthStatus from "views/components/HealthStatus";
import VLButton from "views/uikit/VLButton";
import { setShowModal, setShowSignUpModal } from "redux/Modals/actions";
import { State } from "redux/store";

import styles from "./style.module.scss";

type HeaderProps = {
  handleSampleDatasets: () => void;
  isPrivatePath: boolean;
  isNewDatasetImportEnabled: boolean;
  datasets: any[];
  isInventoryFilteredEnabled: boolean;
};

const PrivateDisplay = ({ handleButtonClick }: any) => {
  return (
    <div className={styles.headerRight}>
      <HealthStatus />
      <VLButton
        text={"+ New Dataset"}
        onClick={handleButtonClick}
        tooltipText="Create a new dataset"
      />
    </div>
  );
};

function Header({
  handleSampleDatasets,
  isPrivatePath,
  isNewDatasetImportEnabled,
  datasets,
  isInventoryFilteredEnabled,
}: HeaderProps) {
  const user = selectUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isNewDataIngestionEnabled =
    !!getQueryParameter(NEW_DATASET_CREATION__QUERY_PARAMETER_KEY) ||
    isNewDatasetImportEnabled;

  const handleButtonClick = async () => {
    //check if user has not signed-in
    if (user === null || isAnonymousUser()) {
      dispatch(setShowSignUpModal(true));
      localStorage.setItem(SIGNUP_ORIGIN, CREATE_NEW_DATASET);
      return;
    }
    //open the new dataset creation flow
    else if (isNewDataIngestionEnabled) {
      dispatch(resetStore());
      const newDatasetId = await dispatch(createNewDataset("") as any);

      if (newDatasetId) {
        navigate(`/dataset/${newDatasetId}/create`);
      }
      return;
    }
    //open the old dataset creation flow
    dispatch(setShowModal(true));
    handleSampleDatasets();
  };

  const renderHeaderTitle = () => {
    if (isInventoryFilteredEnabled) {
      return (
        <div className={styles.headerLeft}>
          <span className={styles.headerText}>All Datasets</span>{" "}
          {datasets.length > 0 && (
            <span className={styles.datasetCountTitle}>
              Datasets{" "}
              <span className={styles.datasetCount}>{datasets.length}</span>
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div className={styles.headerLeft}>
          <span className={styles.headerText}>
            {isPrivatePath ? "My Datasets" : "Public VL Datasets"}
          </span>
        </div>
      );
    }
  };

  return (
    <div className={styles.headerContainer}>
      {renderHeaderTitle()}
      {isPrivatePath ? (
        <PrivateDisplay handleButtonClick={handleButtonClick} />
      ) : (
        <PublicHeader isBig={true} />
      )}
    </div>
  );
}

function mapStatesToProps(state: State) {
  return {
    isInventoryFilteredEnabled: getIsInventoryFilteredEnabled(state),
    isNewDatasetImportEnabled: getIsNewDatasetImportEnabled(state),
    datasets: getDatasets(state),
  };
}

export default connect(mapStatesToProps)(Header);
