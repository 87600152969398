import axios from "helpers/axiosInstance";
import { AxiosResponse } from "axios";

// Generic GET request
export const getRequest = async <T>(url: string): Promise<T> => {
  const response: AxiosResponse<T> = await axios.get(url);
  return response.data;
};

// Generic POST request
export const postRequest = async <T, R>(url: string, data: R): Promise<T> => {
  const response: AxiosResponse<T> = await axios.post(url, data);
  return response.data;
};
