import { DATA_PAGE } from "helpers/constants/pages";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import SquareImageBox from "views/components/CardBodyBoxes/SquareImageBox";
import ImageBox from "views/components/CardBodyBoxes/ImageBox";
import { updateExportData } from "redux/SingleDataset/actions";

import TrashIcon from "assets/icons/TrashIcon";
import styles from "./style.module.scss";

const SingleItem = ({ singleItem, datasetID }: any) => {
  const dispatch = useDispatch();

  const isCluster = singleItem.exportType === "cluster";

  const getTitle = () => {
    let title;

    if (isCluster) {
      if (singleItem.previews.length === 1) {
        // If only one preview exists, use its filename without extension
        title = singleItem.previews[0].file_name.split(".")[0];
      } else {
        // If multiple previews exist, use the first 8 characters of the cluster_id
        title = singleItem.cluster_id.substring(0, 8);
      }
    } else {
      // If not a cluster, use the filename without extension
      title = singleItem.file_name.split(".")[0];
    }
    return title;
  };
  const title = getTitle();

  const getDynamicImages = (type: "plain" | "thumb", previews: any) => {
    let result: { src: string; id: string }[] = [];
    if (type === "thumb") {
      result = previews?.map((preview: any) => {
        return { src: preview.media_thumb_uri, id: preview.media_id };
      });
    } else if (type === "plain") {
      result = previews?.map((preview: any) => {
        return { src: preview.media_uri, id: preview.media_id };
      });
    }

    return result;
  };

  const plainImages = singleItem.previews
    ? getDynamicImages("plain", singleItem.previews)
    : [{ id: singleItem.media_id, src: singleItem.media_uri }];
  const thumbImages = singleItem.previews
    ? getDynamicImages("thumb", singleItem.previews)
    : [{ id: singleItem.media_id, src: singleItem.media_thumb_uri }];

  const isTypeImage = ["IMAGES", "IMAGE"].includes(singleItem.type);

  const handleImageCount = () => {
    if (isTypeImage) {
      if (isCluster) {
        const excludeMediaIDs = singleItem.excludeMediaIDs || [];
        return singleItem.n_images - excludeMediaIDs.length;
      } else return 1;
    }
    return 0;
  };

  const handleObjectCount = () => {
    if (!isTypeImage) {
      if (isCluster) {
        const excludeMediaIDs = singleItem.excludeMediaIDs || [];
        return singleItem.n_objects - excludeMediaIDs.length;
      } else return 1;
    }
    return 0;
  };

  const imageCount = handleImageCount();
  const objectCount = handleObjectCount();

  const renderInfo = (type: "Images" | "Objects", count: number) => {
    const countClassnames = classNames(styles.value, {
      [styles.disabledText]: count === 0,
    });
    const titleClassnames = classNames(styles.name, {
      [styles.disabledText]: count === 0,
    });

    return (
      <div className={styles.singleInfo}>
        <span className={titleClassnames}>{type}</span>
        <span className={countClassnames}>{count}</span>
      </div>
    );
  };

  const handleRemove = () => {
    dispatch(
      updateExportData(
        datasetID as string,
        singleItem,
        "cart",
        singleItem.exportType,
        {}
      ) as any
    );
  };

  const renderImages = () => {
    if (isCluster) {
      return plainImages.length === 1 ? (
        <ImageBox image={plainImages[0].src} imageThumb={thumbImages[0].src} />
      ) : (
        <SquareImageBox
          plainImages={plainImages}
          thumbImages={thumbImages}
          objectImages={undefined}
          page={DATA_PAGE}
        />
      );
    } else {
      return (
        <ImageBox
          image={singleItem.media_uri}
          imageThumb={singleItem.media_thumb_uri}
        />
      );
    }
  };

  return (
    <div className={styles.singleItemContainer}>
      <div className={styles.imageContainer}>{renderImages()}</div>
      <div className={styles.dataContainer}>
        <div className={styles.titleContainer} title={title}>
          {title}
        </div>
        <div className={styles.infoContainer}>
          {renderInfo("Images", imageCount)}
          {renderInfo("Objects", objectCount)}
          <div className={styles.singleInfo}>
            <div className={styles.removeButton} onClick={handleRemove}>
              <TrashIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleItem;
