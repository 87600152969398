import { useDispatch } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import { useState } from "react";

import { formatNumberWithCommas } from "helpers/utility/formatters";

import styles from "./style.module.scss";
import {
  FilterItemCountsWithSelect,
  FilterOption,
  MetadataBarTypes,
} from "types";
import {
  ISSUE_TYPE_PARAMETER,
  LABELS_PARAMETER,
  USER_TAGS_PARAMETER,
} from "helpers/constants/miscellaneous";
import {
  setDatasetIssueTypes,
  setDatasetLabels,
  setUserTags,
} from "redux/SingleDataset/actions";

const NoMatchingData = () => (
  <div className={styles.noMatchcontainer}>No Matching Data.</div>
);

const Row = ({
  data,
  index,
  style,
  isDataOverflowing,
  dynamicPaddingRight,
  handleSingleSelection,
  maxCount,
  maxPercentage,
  type,
}: any) => {
  const singleLabel = data[index];

  const dynamicPaddingTop =
    index === 0 || index === data.length - 1 ? "1px" : "0";

  const percentage = singleLabel.ratio
    ? Math.round(singleLabel.ratio * 100)
    : 0;

  return (
    <div
      key={index}
      className={styles.singleBarRow}
      style={{
        ...style,
        padding: `${dynamicPaddingTop} ${
          dynamicPaddingRight().paddingRight
        } 10px 10px`,
      }}
    >
      <SingleBarDisplay
        maxCount={maxCount}
        maxPercentage={maxPercentage}
        count={singleLabel.count}
        percentage={percentage}
        title={singleLabel.title}
        key={index}
        index={index}
        handleChange={handleSingleSelection}
        isSelected={singleLabel.selected}
        type={type}
      />
    </div>
  );
};

const SingleBarDisplay = ({
  index,
  maxCount,
  count,
  maxPercentage,
  percentage,
  title,
  handleChange,
  isSelected,
  type,
}: any) => {
  const [isHovering, setIsHovering] = useState(false);
  const customWidth = percentage > 0 ? (percentage / maxPercentage) * 100 : 1;
  const countWidth = `${maxCount.toString().length + 1}ch`;
  const percentageWidth = `${maxPercentage.toString().length + 4}ch`;

  const handleClick = () => {
    handleChange(index, !isSelected);
  };

  const barContainerStyle = {
    backgroundColor: isSelected ? "#fff" : "#3C424F",
    outlineColor: isSelected || isHovering ? "#fff" : "#3C424F",
    color: isSelected ? "#151928" : "#fff",
  };

  const barStyle = {
    width: `${customWidth}%`,
    height: "6px",
    backgroundColor: isSelected ? "#151928" : "#60646E",
    borderRadius: "30px",
  };

  return (
    <div
      className={styles.singleBarDisplay}
      key={index}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        className={styles.barContainer}
        style={barContainerStyle}
        onClick={handleClick}
      >
        <div
          className={styles.barTitle}
          title={title}
          style={type === "labels" ? { textTransform: "none" } : undefined}
        >
          {title}
        </div>
        <div
          style={{
            width: `calc(100% - 65px - ${countWidth} - ${percentageWidth})`,
          }}
        >
          <div style={barStyle}></div>
        </div>
        <div className={styles.barValues}>
          <div className={styles.barCount} style={{ maxWidth: countWidth }}>
            {formatNumberWithCommas(count)}
          </div>
          <div
            className={styles.barPercentage}
            style={{ maxWidth: percentageWidth }}
          >
            ({percentage}%)
          </div>
        </div>
      </div>
    </div>
  );
};

const BarDisplayContent = ({
  data,
  isDataOverflowing,
  showNoMatchingData,
  isContentSelected,
  entityTypeFilter,
  type,
  resetSection,
  datasetLabels,
  userTags,
  issueTypes,
  updateQueryParameter,
  trackMetadataFilterChange,
}: BarDisplayContentProps) => {
  const dispatch = useDispatch();
  const maxCount = Math.max(...data.map((item: any) => item.count));
  const maxPercentage = Math.max(
    ...data.map((item: any) => {
      if (item.ratio) return Math.round(item.ratio * 100);
      else return 0;
    })
  );

  const updateFilterArray = (
    filterArray: FilterOption[],
    selectedTitle: string,
    isSelected: boolean
  ) => {
    return filterArray.map((option: any) =>
      option.text === selectedTitle
        ? { ...option, selected: isSelected }
        : option
    );
  };

  const handleSingleSelection = (index: number, isChecked: boolean) => {
    //track label filter selection for amplitude
    const trackingType =
      type === "user-tags"
        ? "User Tags"
        : type === "labels"
        ? "Labels"
        : type === "issue-types"
        ? "Issue Types"
        : "";

    if (trackingType) {
      trackMetadataFilterChange(
        trackingType,
        entityTypeFilter,
        isChecked ? "Added" : "Removed"
      );
    }

    const handleUserTags = (tags: FilterOption[]) => {
      const updatedTags = updateFilterArray(tags, data[index].title, isChecked);
      updateQueryParameter(updatedTags as any, USER_TAGS_PARAMETER);
      dispatch(setUserTags(updatedTags));
    };

    const handleLabels = (labels: FilterOption[]) => {
      const updatedLabels = updateFilterArray(
        labels,
        data[index].title,
        isChecked
      );
      updateQueryParameter(updatedLabels as any, LABELS_PARAMETER);
      dispatch(setDatasetLabels(updatedLabels));
    };

    const handleIssueTypes = (issues: FilterOption[]) => {
      const updatedIssueTypes = updateFilterArray(
        issues,
        data[index].title,
        isChecked
      );
      updateQueryParameter(updatedIssueTypes as any, ISSUE_TYPE_PARAMETER);
      dispatch(setDatasetIssueTypes(updatedIssueTypes));
    };

    switch (type) {
      case "user-tags":
        if (userTags) handleUserTags(userTags);
        break;
      case "labels":
        if (datasetLabels) handleLabels(datasetLabels);
        break;
      case "issue-types":
        if (issueTypes) handleIssueTypes(issueTypes);
        break;
    }

    resetSection();
  };

  const dynamicPaddingRight = () => {
    return {
      paddingRight: isContentSelected
        ? "4px"
        : isDataOverflowing
        ? "4px"
        : "7px",
    };
  };

  const itemSize = (index: number) => {
    return 40; // Set a default item height
  };

  const dynamicWrapperHeight = () => {
    if (isContentSelected) {
      return { height: "100%", maxHeight: "100%" };
    } else {
      switch (data.length) {
        case 1 || 2 || 0:
          return { height: "90px" };
        case 3:
          return { height: "130px" };
        case 4:
          return { height: "170px" };
        default:
          return { height: "210px" };
      }
    }
  };

  return showNoMatchingData ? (
    <NoMatchingData />
  ) : (
    <div className={styles.wrapper} style={dynamicWrapperHeight()}>
      <AutoSizer>
        {({ height, width }: any) => (
          <List
            height={height}
            width={width}
            itemCount={data.length}
            itemSize={itemSize}
            itemData={data}
            className={`List ${styles.barListContainer}`}
            style={dynamicPaddingRight()}
          >
            {({ index, style }) => {
              return (
                <Row
                  data={data}
                  index={index}
                  style={style}
                  isDataOverflowing={isDataOverflowing}
                  dynamicPaddingRight={dynamicPaddingRight}
                  handleSingleSelection={handleSingleSelection}
                  type={type}
                  maxCount={maxCount}
                  maxPercentage={maxPercentage}
                />
              );
            }}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};

export default BarDisplayContent;

interface BarDisplayContentProps {
  data: Array<FilterItemCountsWithSelect>;
  isDataOverflowing: any;
  showNoMatchingData: any;
  isContentSelected: any;
  entityTypeFilter: FilterOption[];
  type: MetadataBarTypes;
  resetSection: any;
  datasetLabels?: FilterOption[];
  userTags?: FilterOption[];
  issueTypes?: FilterOption[];
  updateQueryParameter: any;
  trackMetadataFilterChange: any;
}
