import { useDispatch, connect } from "react-redux";
import { useEffect, useState } from "react";

import { updateSelectedLabels } from "redux/Modals/actions";
import StatsSection from "views/pages/DataPage/ImageView/StatsSection";
import ImageSection from "views/pages/DataPage/ImageView/ImageSection";

import { getQueryParameter } from "helpers/utility/utilities";
import { OBJECT_ID_QUERY_PARAMETER } from "helpers/constants/miscellaneous";

import styles from "./style.module.scss";
import { getFetchError } from "redux/SingleDataset/selectors";
import { State } from "redux/store";
import { useLocation, useNavigate } from "react-router";

const ImageView = ({ imageData, labels, fetchError }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [carouselDropDown, setCarouselDropDown] = useState(true);

  const dispatch = useDispatch();

  const selectedLabelID = getQueryParameter(OBJECT_ID_QUERY_PARAMETER);

  const handleCarouselDropDown = (e: any, flag?: any) => {
    if (flag !== undefined) setCarouselDropDown(flag);
    else setCarouselDropDown(!carouselDropDown);
  };

  useEffect(() => {
    if (fetchError) {
      navigate(location.pathname.split("/image")[0]);
    }
  }, [fetchError, location.pathname, navigate]);

  useEffect(() => {
    if (labels?.length > 0 && selectedLabelID) {
      labels.forEach((singleLabel: any) => {
        const name = singleLabel.categoryName;
        const color = singleLabel.color;
        singleLabel.labels?.forEach((subLabel: any, index: number) => {
          const subLabelDisplayNumber = index + 1;

          if (subLabel.id === selectedLabelID) {
            dispatch(
              updateSelectedLabels(
                selectedLabelID,
                subLabel.bounding_box,
                color,
                `${name} | ${subLabelDisplayNumber}`
              ) as any
            );
          }
        });
      });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.imageDetailsContainer}>
      <div className={styles.imageDetailsBody}>
        <ImageSection
          imageData={imageData}
          isExpanded={carouselDropDown}
          setIsExpanded={handleCarouselDropDown}
        >
          <StatsSection img={imageData} />
        </ImageSection>
      </div>
    </div>
  );
};
const mapStateToProp = (state: State) => {
  return {
    fetchError: getFetchError(state),
  };
};
export default connect(mapStateToProp)(ImageView);
