import { useEffect, useState } from "react";

import { BRIGHT_BLUE } from "helpers/constants/colors";
import crossImg from "assets/img/blue-cross-icon.svg";
import { BiCheck, BiSearch } from "react-icons/bi";

import ContentLoad from "views/uikit/ContentLoad";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";

import styles from "./style.module.scss";
import { FilterOption } from "types";

const Row = ({
  index,
  style,
  data,
  selectedOptions,
  handleFilterSelection,
  type,
}: any) => {
  const option = data[index];

  return (
    <div key={index} style={style}>
      <OptionContainer
        key={option.id}
        value={option.id}
        selectedOptions={selectedOptions}
        handleFilterSelection={handleFilterSelection}
        text={option.text}
        type={type}
      />
    </div>
  );
};

const OptionContainer = ({
  value,
  selectedOptions,
  handleFilterSelection,
  text,
  type,
}: any) => {
  const isSelected = selectedOptions.includes(value);

  return (
    <div
      className={styles.optionContainer}
      onClick={(e: any) => {
        e.stopPropagation();
        handleFilterSelection(value, type);
      }}
    >
      <div
        className={styles.checkbox}
        style={isSelected ? { backgroundColor: BRIGHT_BLUE } : undefined}
      >
        {isSelected && <BiCheck color="#fff" size="12px" />}
      </div>
      <div
        className={styles.text}
        style={isSelected ? { fontWeight: 500 } : undefined}
      >
        {text}
      </div>
    </div>
  );
};

const MultiSelectDropDown = ({
  type,
  options,
  clear,
  isLoading,
  selectedFilters,
  handleFilterSelection,
}: any) => {
  const selectedOptions = selectedFilters[type] || [];

  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<any>(options);
  const [searchedOptions, setSearchedOptions] = useState<any>(options);

  const handleClearSelection = (e: any) => {
    e.stopPropagation();
    clear();
    setSearchText("");
  };

  const handleCloseSearchClick = (e: any) => {
    setSearchText("");
    e.stopPropagation();
    e.preventDefault();
  };

  const dynamicOptions =
    searchText.length > 0 ? searchedOptions : filteredOptions;

  const showClearButton = () => {
    return (
      selectedOptions.length > 0 && (
        <div className={styles.clearButton} onClick={handleClearSelection}>
          Clear Selection
        </div>
      )
    );
  };

  const itemSize = (index: number) => {
    // Calculate the size of each item based on its content or return a default size
    // Example: You can use a fixed height for each item or calculate it based on the content
    return 40; // Set a default item height
  };

  const calculateOptionsContainerStyle = () => {
    let contentHeight = "";
    const optionsCount = dynamicOptions.length;
    const highestValue = dynamicOptions.reduce(
      (maxText: string, currentItem: FilterOption) => {
        if (currentItem.text.length > maxText.length) {
          return currentItem.text;
        } else {
          return maxText;
        }
      },
      ""
    );

    if (optionsCount > 0 && optionsCount <= 5) {
      contentHeight = optionsCount * 40 + "px";
    } else {
      contentHeight = "200px";
    }
    return {
      height: contentHeight,
      minWidth: "100%",
      width: `calc((${highestValue.length} * 0.45em) + 56px)`,
    };
  };

  useEffect(() => {
    if (searchText === "") {
      return;
    }
    setSearchedOptions(
      filteredOptions.filter((option: any) => {
        return (
          option.text?.toLowerCase().includes(searchText) &&
          !selectedOptions.includes(option.id)
        );
      })
    );
    //eslint-disable-next-line
  }, [searchText, selectedOptions]);

  useEffect(() => {
    const newOptionsAfterFilter = options.filter((option: any) => {
      return !selectedOptions.includes(option.id);
    });
    setFilteredOptions(newOptionsAfterFilter);
    //eslint-disable-next-line
  }, [selectedOptions]);

  return isLoading ? (
    <div className={styles.dropdownLoader}>
      {[...Array(6)].map((_: any, index: number) => (
        <div className={styles.singleLoadingBox} key={index}>
          <div className={styles.singleLoader}>
            <ContentLoad />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className={styles.dropdown}>
      <div className={styles.selectedOptionsContainer}>
        <div className={styles.searchBarContainer}>
          {!searchText && (
            <div className={styles.searchBarButton}>
              <BiSearch />
            </div>
          )}
          <input
            type="search"
            onChange={(e: any) => setSearchText(e.target.value?.toLowerCase())}
            value={searchText}
            className={styles.searchBarInput}
          />
          {searchText && (
            <img
              className={styles.crossImg}
              src={crossImg}
              alt="cross-icon"
              onClick={handleCloseSearchClick}
            />
          )}
        </div>
        <div className={styles.selectedOptions}>
          {selectedOptions.map((item: number, index: number) => (
            <div
              key={index}
              className={styles.optionContainer}
              onClick={(e: any) => {
                e.stopPropagation();
                handleFilterSelection(item, type);
              }}
              style={{ backgroundColor: "#151928;" }}
            >
              <div
                className={styles.checkbox}
                style={{ backgroundColor: BRIGHT_BLUE }}
              >
                <div className={styles.check}>
                  <BiCheck />
                </div>
              </div>
              <div className={styles.text} style={{ fontWeight: 500 }}>
                {options.find((option: any) => option.id === item)?.text}
              </div>
            </div>
          ))}
        </div>
      </div>

      {showClearButton()}
      <div className={styles.horizintalRule}></div>
      {dynamicOptions.length > 0 && (
        <div
          className={styles.virtualizedOptionsContainer}
          style={calculateOptionsContainerStyle()}
        >
          <AutoSizer>
            {({ height, width }: any) => (
              <List
                height={height}
                width={width}
                itemCount={dynamicOptions.length}
                itemSize={itemSize}
                itemData={dynamicOptions}
                className={"List"}
              >
                {({ index, style }) => (
                  <Row
                    type={type}
                    index={index}
                    style={style}
                    data={dynamicOptions}
                    selectedOptions={selectedOptions}
                    handleFilterSelection={handleFilterSelection}
                  />
                )}
              </List>
            )}
          </AutoSizer>
        </div>
      )}

      {!!searchText && dynamicOptions.length <= 0 && (
        <div className={styles.noSearchDataBox}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="19"
            viewBox="0 0 11 19"
            fill="none"
            className={styles.questionMark}
          >
            <path
              d="M4.05 13C4.05 11.65 4.17083 10.6792 4.4125 10.0875C4.65417 9.49583 5.16667 8.85 5.95 8.15C6.63333 7.55 7.15417 7.02917 7.5125 6.5875C7.87083 6.14583 8.05 5.64167 8.05 5.075C8.05 4.39167 7.82083 3.825 7.3625 3.375C6.90417 2.925 6.26667 2.7 5.45 2.7C4.6 2.7 3.95417 2.95833 3.5125 3.475C3.07083 3.99167 2.75833 4.51667 2.575 5.05L0 3.95C0.35 2.88333 0.991667 1.95833 1.925 1.175C2.85833 0.391667 4.03333 0 5.45 0C7.2 0 8.54583 0.4875 9.4875 1.4625C10.4292 2.4375 10.9 3.60833 10.9 4.975C10.9 5.80833 10.7208 6.52083 10.3625 7.1125C10.0042 7.70417 9.44167 8.375 8.675 9.125C7.85833 9.90833 7.3625 10.5042 7.1875 10.9125C7.0125 11.3208 6.925 12.0167 6.925 13H4.05ZM5.45 19C4.9 19 4.42917 18.8042 4.0375 18.4125C3.64583 18.0208 3.45 17.55 3.45 17C3.45 16.45 3.64583 15.9792 4.0375 15.5875C4.42917 15.1958 4.9 15 5.45 15C6 15 6.47083 15.1958 6.8625 15.5875C7.25417 15.9792 7.45 16.45 7.45 17C7.45 17.55 7.25417 18.0208 6.8625 18.4125C6.47083 18.8042 6 19 5.45 19Z"
              fill="#151928"
            />
          </svg>
          <span className={styles.noSearchDataText}>No matches found</span>
          <span className={styles.noSearchDataText}>
            Please try another term
          </span>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropDown;
