import { State } from "redux/store";
import { Helmet } from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";

import {
  getAllSelectedFilters,
  getCartData,
  getExportCount,
  getExportDataTimeoutID,
  getExportTags,
  getFilteredSimilarityClusters,
  getNavigationCluster,
  getShowCart,
  getStats,
  getTempExportData,
  getIsExportEntitiesEnabled,
  getAssignedTags,
  getExportRequestData,
  getShowExportModal,
  getExportData,
  getDatasetTags,
} from "redux/SingleDataset/selectors";
import {
  setShowCart,
  submitSelectedCartTags,
  fetchAssignedTags,
  setAssignedTags,
  sumbitRemoveTagsRequest,
  setShowExportModal,
} from "redux/SingleDataset/actions";

import { FilterOption } from "types";
import SingleItem from "./SingleItem";
import TagsDropDown from "./TagsDropDown";
import ToolTipBox from "views/components/TooltipBox";
import { useWindowResize } from "helpers/utility/utilities";
import AssignTagsToast from "./AssignTagsToast";

import fancyRightArrow from "assets/img/fancy-right-arrow.svg";
import selectionCountIcon from "assets/img/note-stack-add.svg";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

import styles from "./style.module.scss";
import { toast } from "react-toastify";
import { ERROR_RED } from "helpers/constants/colors";
import { ERROR } from "helpers/constants/toastify";
import { formatNumberWithCommas } from "helpers/utility/formatters";
import classNames from "classnames";
import tagIcon from "assets/img/tag-icon-black.svg";
import TodoIcon from "assets/img/colored-to-do-list.svg";
import SettingsIcon from "assets/img/colored-setting.svg";
import FolderIcon from "assets/img/colored-file-storage.svg";
import ExportModal from "views/modals/ExportModal";

const Cart = ({
  exportCount,
  showCart,
  exportData,
  originalTags,
  exportTags,
  datasetID,
  clusters,
  navigationCluster,
  timeoutID,
  tempExportData,
  cartData,
  allSelectedFilters,
  isExportEntitiesEnabled,
  exportRequestData,
  assignedTags,
  showExportModal,
}: any) => {
  const toastIdRef = useRef<any>(null);
  const exportButtonRef = useRef<HTMLDivElement>(null);
  const cartContainerRef = useRef<HTMLDivElement>(null);
  const tagDropdownBtnRef = useRef<HTMLDivElement>(null);
  const [showTagsDropdown, setShowTagsDropdown] = useState(false);
  const [removeAll, setRemoveAll] = useState(false);
  const [count, setCount] = useState(0);

  const dispatch = useDispatch();
  const { height } = useWindowResize();

  const cartBodyClassNames = classNames(styles.cartBody, {
    [styles.hideScroll]: count > 0,
  });

  const toastStyle = {
    borderRadius: "10px",
  };

  const toggleShowTagsDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setShowTagsDropdown(!showTagsDropdown);
  };

  const handleOutsideClick = (event: any) => {
    if (
      cartContainerRef.current &&
      !cartContainerRef.current.contains(event.target)
    ) {
      dispatch(setShowCart(!showCart));
      setRemoveAll(false);
      setShowTagsDropdown(false);
    }
  };

  const handleExportClick = () => {
    dispatch(setShowExportModal(!showExportModal));
  };

  const errorNotification = (type: ToastActionType) => {
    const dynamicDescription =
      type === "assign"
        ? "Error occoured while tagging. Please try again"
        : "Error occoured while removing tags. Please try again";
    toastIdRef.current = toast(
      <AssignTagsToast
        status={ERROR}
        heading={`Error!`}
        description={dynamicDescription}
        onClose={() => toast.dismiss(toastIdRef.current)}
      />,
      {
        closeOnClick: false,
        closeButton: false,
        style: { ...toastStyle, border: `2px solid ${ERROR_RED}` },
      }
    );
  };

  const handleTagSubmission = async (tagID: number) => {
    try {
      const requestBody: any = { [tagID]: exportRequestData };
      await dispatch(submitSelectedCartTags(datasetID, requestBody) as any);
    } catch (error) {
      toast.dismiss(toastIdRef.current);
      dispatch(setShowCart(false));
      errorNotification("assign");
    }
  };

  const handleTagRemoval = async (tagID: number) => {
    try {
      let requestBody: any = { [tagID]: exportRequestData };
      await dispatch(sumbitRemoveTagsRequest(datasetID, requestBody) as any);
    } catch (error) {
      toast.dismiss(toastIdRef.current);
      errorNotification("remove");
    }
  };

  const renderExportOption = () => {
    if (!isExportEntitiesEnabled) {
      return <noscript />;
    }

    return (
      <>
        <div
          ref={exportButtonRef}
          className={styles.exportButton}
          onClick={handleExportClick}
        >
          Export
        </div>
        <ExportModal
          showModal={showExportModal}
          callerButtonRef={exportButtonRef}
        />
      </>
    );
  };

  useEffect(() => {
    if (cartData?.exportDataArray && cartData.exportDataArray.length > 0) {
      dispatch(
        fetchAssignedTags(datasetID, exportRequestData, assignedTags) as any
      );
    } else {
      dispatch(setAssignedTags([]));
    }
    //eslint-disable-next-line
  }, [cartData?.exportDataArray, datasetID]);

  //calculates the count of the placeholders
  useEffect(() => {
    const cartContainer = cartContainerRef.current;
    const selectedCards = exportData.length;

    let totalHeight;
    if (cartContainer) totalHeight = cartContainer.offsetHeight - 50;
    if (totalHeight) {
      // 134 is single element's height,
      let requiredCount = Math.round(totalHeight / 134);

      if (selectedCards < requiredCount) {
        setCount(requiredCount - selectedCards);
      } else if (selectedCards >= requiredCount) {
        setCount(0);
      }
    }
  }, [showCart, exportData, height]);

  useEffect(() => {
    if (showCart) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [showCart]);

  return (
    <div
      className={styles.cartBackground}
      style={
        showCart ? undefined : { width: 0, backgroundColor: "transparent" }
      }
    >
      {/* preload icons */}
      <Helmet>
        <link rel="preload" href={tagIcon} as="image" type="image/svg+xml" />
        <link rel="preload" href={TodoIcon} as="image" type="image/svg+xml" />
        <link
          rel="preload"
          href={SettingsIcon}
          as="image"
          type="image/svg+xml"
        />
        <link rel="preload" href={FolderIcon} as="image" type="image/svg+xml" />
      </Helmet>
      <div className={styles.cartWrapper}>
        <div
          ref={cartContainerRef}
          className={styles.cartContainer}
          style={
            showCart ? undefined : { width: 0, padding: 0, overflowX: "hidden" }
          }
        >
          <div className={styles.cartHeader}>
            <div className={styles.savedItemsContainer}>
              <div className={styles.savedItemsIcon}>
                <img src={selectionCountIcon} alt="note-stack-add-icon" />
              </div>
              <span className={styles.savedItemsText}>{`Selected Items (${
                exportCount ? formatNumberWithCommas(exportCount) : 0
              })`}</span>
            </div>
            {(exportCount > 0 || exportTags.length > 0) && (
              <div
                className={styles.removeAllButton}
                onClick={() => setRemoveAll(true)}
              >
                Clear All
              </div>
            )}
            <div className={styles.actionButtons}>
              <div
                className={styles.assignedTagsButton}
                onClick={toggleShowTagsDropdown}
                ref={tagDropdownBtnRef}
              >
                <img
                  src={tagIcon}
                  className={styles.tagsButton}
                  alt="manage-tags-icon"
                />
                <span>Manage Tags</span>
                {showTagsDropdown ? (
                  <BiChevronUp size="1.55em" />
                ) : (
                  <BiChevronDown size="1.55em" />
                )}
              </div>
              {showTagsDropdown && (
                <TagsDropDown
                  assignedTags={assignedTags.filter(
                    (t: FilterOption) => t.selected
                  )}
                  allTags={originalTags}
                  toggleShowTags={toggleShowTagsDropdown}
                  onTagSubmission={handleTagSubmission}
                  onTagRemoval={handleTagRemoval}
                  datasetID={datasetID}
                  btnRef={tagDropdownBtnRef}
                />
              )}
            </div>

            {renderExportOption()}
          </div>

          <div className={cartBodyClassNames}>
            <div className={styles.itemsList}>
              {exportData.map((singleItem: any, index: number) => (
                <SingleItem
                  key={index}
                  singleItem={singleItem}
                  datasetID={datasetID}
                />
              ))}
              {count > 0 &&
                [...Array(count)].map((_, index) => (
                  <div key={index} className={styles.placeholderBox}>
                    {exportData.length <= 0 && index === 0 && (
                      <div className={styles.noSelectionBox}>
                        <img
                          src={fancyRightArrow}
                          className={styles.noSelectionIcon}
                          alt="right-arrow-icon"
                        />

                        <div className={styles.noSelectionText}>
                          No selected items yet.
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
          {((tempExportData && tempExportData.calledFrom === "cart") ||
            removeAll) && (
            <ToolTipBox
              tempExportData={tempExportData}
              exportData={exportData}
              timeoutID={timeoutID}
              clusters={clusters}
              navigationCluster={navigationCluster}
              removeAll={removeAll}
              setRemoveAll={setRemoveAll}
              allSelectedFilters={allSelectedFilters}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    exportCount: getExportCount(state),
    showCart: getShowCart(state),
    exportData: getExportData(state),
    originalTags: getDatasetTags(state),
    exportTags: getExportTags(state),
    datasetID: getStats(state)?.dataset?.id,
    clusters: getFilteredSimilarityClusters(state),
    navigationCluster: getNavigationCluster(state),
    tempExportData: getTempExportData(state),
    timeoutID: getExportDataTimeoutID(state),
    cartData: getCartData(state),
    allSelectedFilters: getAllSelectedFilters(state),
    isExportEntitiesEnabled: getIsExportEntitiesEnabled(state),
    assignedTags: getAssignedTags(state),
    exportRequestData: getExportRequestData(state),
    showExportModal: getShowExportModal(state),
  };
};
export default connect(mapStateToProps)(Cart);

type ToastActionType = "assign" | "remove";
