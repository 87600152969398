import { useParams } from "react-router";
import { connect, useDispatch } from "react-redux";
import classNames from "classnames";
import { State } from "redux/store";
import { useEffect, useState } from "react";

import {
  CLUSTERS_PAGE,
  CLUSTER_DETAILS_PAGE,
  DATASET_CREATION_PAGE,
  DATA_PAGE,
  IMAGE_CLUSTERS_PAGE,
  IMAGE_DETAILS_PAGE,
  SINGLE_DATASET_PAGE,
  SINGLE_IMAGE_PAGE,
} from "helpers/constants/pages";
import { getPublicParameter } from "redux/Datasets/selectors";
import {
  getAllSelectedFilters,
  getDataset,
  getExportCount,
  getExportData,
  getExportDataTimeoutID,
  getFilteredSimilarityClusters,
  getIsDataIngestionTabEnabled,
  getNavigationCluster,
  getShowCart,
  getTempExportData,
  isCartEnabled,
} from "redux/SingleDataset/selectors";
import { getImageDataLoader } from "redux/Modals/selectors";

import DescriptionList from "views/components/DescriptionList";
import ProgressBarLoader from "views/uikit/ProgressBarLoader";
import {
  getIsLoading,
  getIsNewDatasetReady,
  getNewDataset,
  getNewDatasetStatus,
} from "redux/CreateDataset/selectors";

import { setShowDeleteDatasetModal } from "redux/CreateDataset/actions";

import EditableDatasetTitle from "../EditableDatasetTitle";
import PublicHeader from "views/components/PublicHeader";
import DatasetStatusComponent from "./DatasetStatus";
import SignupModal from "views/modals/SignupModal";
import ContentLoad from "views/uikit/ContentLoad";
import SelectionBox from "./SelectionBox";
import TabSwitch from "./TabSwitch";

import ClusterLogo from "assets/pngImages/clusterLogo.png";
import VectorLogo from "assets/pngImages/vector.png";
import ImageLogo from "assets/pngImages/imageTab.png";

import { TabTypes } from "types";

import styles from "./style.module.scss";
import { getUserInfo } from "redux/User/selectors";

const ContainerHeaderIcon = ({ isContentLoading, page, imgUrl }: any) => {
  const isSingleDataPage = page === SINGLE_DATASET_PAGE;

  const handleImageSrc = (page: string, imgUrl?: string) => {
    return page === CLUSTERS_PAGE || page === IMAGE_CLUSTERS_PAGE
      ? ClusterLogo
      : page === SINGLE_IMAGE_PAGE
      ? ImageLogo
      : imgUrl || VectorLogo;
  };
  const handleContentLoader = (page: string) => {
    switch (page) {
      case SINGLE_IMAGE_PAGE:
        return (
          <div className={styles.headerIconImageLoaderForImageExploraion}>
            <ContentLoad />
          </div>
        );
      case DATA_PAGE:
        return null;
      default:
        return (
          <div className={styles.headerIconImageLoader}>
            <ContentLoad />
          </div>
        );
    }
  };

  const headerIconStyle = isSingleDataPage
    ? { margin: 0, height: "52px", width: "auto" }
    : undefined;

  const imageStyle = isSingleDataPage
    ? { height: "100%", borderRadius: "10px 0 0 0" }
    : undefined;

  return (
    <div className={styles.containerHeaderIcon} style={headerIconStyle}>
      {isContentLoading ? (
        handleContentLoader(page)
      ) : (
        <img src={handleImageSrc(page, imgUrl)} alt="" style={imageStyle} />
      )}
    </div>
  );
};

const PublicHeaderComponent = () => {
  return (
    <>
      <PublicHeader isBig={false} />
      <SignupModal />
    </>
  );
};

const ShowLoader = ({
  dynamicPage,
  isImageLoading,
  isCardListLoading,
}: any) => {
  const isImageDetailsPage = dynamicPage === IMAGE_DETAILS_PAGE;
  const isClusterOrDataPage =
    dynamicPage === CLUSTER_DETAILS_PAGE || dynamicPage === DATA_PAGE;
  const isDatasetCrationPage = dynamicPage === DATASET_CREATION_PAGE;

  if (isImageDetailsPage) {
    return <ProgressBarLoader loading={isImageLoading} />;
  }

  if (isClusterOrDataPage || isDatasetCrationPage) {
    return <ProgressBarLoader loading={isCardListLoading} />;
  }

  return null;
};

const ContainerHeader = ({
  title,
  page,
  isContentLoading,
  isCardListLoading,
  stats,
  clusterData,
  imageClusterData,
  singleImageData,
  publicParameter,
  showPageSwitch,
  dataset,
  exportCount,
  exportData,
  showCart,
  clusters,
  navigationCluster,
  tempExportData,
  timeoutID,
  cartEnabled,
  dynamicPage,
  isImageLoading,
  allSelectedFilters,
  isCreateDatasetLoading,
  isDataIngestionTabEnabled,
  newDatasetStatus,
  isNewDatasetReady,
  newDataset,
  userId,
}: any) => {
  const [datasetStatus, setDatasetStatus] = useState("");

  const dispatch = useDispatch();
  const { newDatasetId } = useParams();

  const isDataCreationPage = dynamicPage === DATASET_CREATION_PAGE;
  const dynamicIsCardListLoading = isDataCreationPage
    ? isCreateDatasetLoading
    : isContentLoading;

  const showDynamicTabs: TabTypes = isDataIngestionTabEnabled
    ? TabTypes.ALL
    : page === DATA_PAGE
    ? TabTypes.DATA_EXPLORATION
    : TabTypes.DATA_INGESTION;

  const showContainerHeaderIcon =
    page !== DATA_PAGE && page !== DATASET_CREATION_PAGE;

  const showPublicHeaderIcon =
    publicParameter &&
    !isContentLoading &&
    (stats || page !== SINGLE_DATASET_PAGE);

  const showCartButton = cartEnabled && !isDataCreationPage;
  const showAbortButton =
    isDataCreationPage && !showPublicHeaderIcon && !isNewDatasetReady;

  const containerHeaderDetailClassnames = classNames(
    styles.containerHeaderDetails,
    {
      [styles.justifySpaceBetween]: publicParameter,
    }
  );

  const handleAbortClick = () => {
    dispatch(setShowDeleteDatasetModal(true));
  };

  const renderEditableTitle = () => {
    const dynamicDataset = newDatasetId ? newDataset : dataset;
    const isCreator = dynamicDataset?.created_by === userId;

    return isCreator ? (
      <EditableDatasetTitle
        title={dataset?.display_name || title}
        datasetID={dataset?.id || newDatasetId!}
        isContentLoading={isContentLoading}
        fontStyle={{ width: "310px" }}
      />
    ) : (
      <span
        className={styles.containerHeaderTitleText}
        title={dynamicDataset?.display_name}
      >
        {dynamicDataset?.display_name}
      </span>
    );
  };

  useEffect(() => {
    let status = "";

    if (dynamicPage === DATA_PAGE) {
      status = dataset?.status || "";
    } else if (dynamicPage === DATASET_CREATION_PAGE) {
      status = newDatasetStatus;
    }

    setDatasetStatus(status);
  }, [dynamicPage, newDatasetStatus, dataset]);

  return (
    <div className={styles.containerHeader}>
      <div className={styles.upperRow}>
        <div className={styles.containerLeft}>
          {showContainerHeaderIcon && (
            <ContainerHeaderIcon
              isContentLoading={isContentLoading}
              page={page}
              imgUrl={dataset?.preview_uri}
            />
          )}
          <div className={containerHeaderDetailClassnames}>
            <div className={styles.containerHeaderTitleContainer}>
              {renderEditableTitle()}
            </div>

            {!publicParameter && (
              <DescriptionList
                isContentLoading={isContentLoading}
                page={page}
                stats={stats}
                dataset={dataset}
                clusterData={clusterData}
                imageClusterData={imageClusterData}
                singleImageData={singleImageData}
              />
            )}
          </div>
        </div>

        {showPublicHeaderIcon && <PublicHeaderComponent />}
        {!publicParameter && (
          <DatasetStatusComponent datasetStatus={datasetStatus} />
        )}

        {showAbortButton && (
          <div className={styles.abortButton} onClick={handleAbortClick}>
            Abort
          </div>
        )}

        {showCartButton && (
          <SelectionBox
            showCart={showCart}
            exportCount={exportCount}
            exportData={exportData}
            clusters={clusters}
            navigationCluster={navigationCluster}
            tempExportData={tempExportData}
            timeoutID={timeoutID}
            allSelectedFilters={allSelectedFilters}
          />
        )}
      </div>

      {showPageSwitch && (
        <div className={styles.lowerRow}>
          <ShowLoader
            dynamicPage={dynamicPage}
            isImageLoading={isImageLoading}
            isCardListLoading={dynamicIsCardListLoading}
          />
          <TabSwitch showTabs={showDynamicTabs} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    publicParameter: getPublicParameter(state),
    dataset: getDataset(state),
    exportCount: getExportCount(state),
    exportData: getExportData(state),
    showCart: getShowCart(state),
    clusters: getFilteredSimilarityClusters(state),
    navigationCluster: getNavigationCluster(state),
    tempExportData: getTempExportData(state),
    timeoutID: getExportDataTimeoutID(state),
    cartEnabled: isCartEnabled(state),
    isImageLoading: getImageDataLoader(state),
    allSelectedFilters: getAllSelectedFilters(state),
    isDataIngestionTabEnabled: getIsDataIngestionTabEnabled(state),
    isCreateDatasetLoading: getIsLoading(state),
    newDatasetStatus: getNewDatasetStatus(state),
    isNewDatasetReady: getIsNewDatasetReady(state),
    newDataset: getNewDataset(state),
    userId: getUserInfo(state).userId,
  };
};

export default connect(mapStateToProps)(ContainerHeader);
