import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";

import { State } from "./store";

import { Status, TreeNode, ItemCenter, User } from "types";
import { axiosGet, composeApiURL } from "helpers/utility/axiosHelpers";

export const getRoot = createAsyncThunk(
  "tree/getRoot",
  async (
    payload: {
      user: User;
      selectedDataset: string | null;
      clickedNode: string | null;
    },
    options
  ) => {
    const state = options.getState() as State;
    if (state.tree.trees[0].manual) {
      return state.tree.trees[0].root;
    } else {
      const clusterApi = payload.clickedNode
        ? "/clusters/node/" + payload.clickedNode + "?lean=true"
        : "/clusters/node/-1?lean=true";
      const path = payload.selectedDataset
        ? "/dataset/" + payload.selectedDataset + clusterApi
        : clusterApi;
      const url = composeApiURL(state.debug.serverUrl, path);
      const response = await axiosGet(url, payload.user);
      return response.data;
    }
  }
);

const initialState = {
  activeTree: "",
  trees: [
    {
      status: Status.Init,
      breadcrumbs: [] as Array<string>,
      clickedNode: "",
      clickedItemCenter: null as ItemCenter | null,
      root: undefined as TreeNode | undefined,
      manual: false,
    },
  ],
};

const treeSlice = createSlice({
  name: "trees",
  initialState,
  reducers: {
    onSelectDataset(state) {
      const activeTree = state.trees[0];
      activeTree.breadcrumbs = [];
      state.trees[0] = activeTree;
    },
    onBreadcrumbClicked(state, action) {
      const activeTree = state.trees[0];
      activeTree.breadcrumbs = activeTree.breadcrumbs.slice(
        0,
        activeTree.breadcrumbs.findIndex(
          (item: string) => item === action.payload
        ) + 1
      );
      activeTree.clickedItemCenter = null;
      state.trees[0] = activeTree;
    },
    onTreeNodeClicked(
      state,
      action: PayloadAction<{ clusterId: string; clickedItemCenter?: any }>
    ) {
      const activeTree = state.trees[0];
      activeTree.breadcrumbs.push(action.payload.clusterId);
      activeTree.clickedNode = action.payload.clusterId;
      activeTree.clickedItemCenter = action.payload.clickedItemCenter;
      state.trees[0] = activeTree;
    },
    onUploadedJson(state, action) {
      const activeTree = state.trees[0];
      activeTree.root = action.payload;
      activeTree.clickedItemCenter = null;
      activeTree.breadcrumbs = [
        action.payload.name || action.payload.component_id,
      ];
      activeTree.status = Status.Loaded;
      activeTree.manual = true;
      state.trees[0] = activeTree;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoot.pending, (state) => {
        const activeTree = state.trees[0];
        activeTree.status = Status.Loading;
        state.trees[0] = activeTree;
      })
      .addCase(getRoot.rejected, (state) => {
        const activeTree = state.trees[0];
        activeTree.root = undefined;
        activeTree.status = Status.Loaded;
      })
      .addCase(getRoot.fulfilled, (state, action) => {
        const activeTree = state.trees[0];
        activeTree.root = action.payload;
        activeTree.breadcrumbs =
          activeTree.breadcrumbs.length === 0
            ? [action.payload.name || action.payload.component_id]
            : activeTree.breadcrumbs;
        activeTree.status = Status.Loaded;
        state.trees[0] = activeTree;
      });
  },
});

export const {
  onSelectDataset,
  onBreadcrumbClicked,
  onTreeNodeClicked,
  onUploadedJson,
} = treeSlice.actions;

export default treeSlice.reducer;

const selectSelf = (state: any) => {
  return state.tree;
};

const activeTree = createSelector(selectSelf, (tree: typeof initialState) => {
  const activeTree = tree.trees[0];
  return activeTree;
});

export const selectStatus = createSelector(activeTree, (tree: any) => {
  return tree.status;
});
