import { RefObject } from "react";
import { useNavigate } from "react-router";

import CheckIcon from "assets/icons/CheckIcon";
import XIcon from "assets/icons/XIcon";

import styles from "./style.module.scss";
import { StepTileProgressStatus } from "types";

const SuccessBanner = ({
  toggleShowBanner,
  newDatasetId,
  bannerRef,
}: SuccessBannerProps) => {
  const navigate = useNavigate();

  const handleExploreBtnClick = () => {
    toggleShowBanner();
    navigate(`/dataset/${newDatasetId}/data`);
  };

  return (
    <div className={styles.indexingBanner} ref={bannerRef}>
      <div className={styles.inputTitleContainer}>
        <div className={styles.titleIcon}>
          <CheckIcon color="#34A853" />
        </div>
        <span className={styles.title}>
          The VL graph was created without any issues
        </span>
        <div className={styles.crossBtn} onClick={toggleShowBanner}>
          <XIcon color="#151928" />
        </div>
      </div>

      <div className={styles.description}>
        Your private dataset is ready to use, explore and enrich now.{" "}
      </div>

      <div className={styles.actionBtnContainer}>
        <div className={styles.exploreBtn} onClick={handleExploreBtnClick}>
          Explore
        </div>
      </div>
    </div>
  );
};

const LoadingBanner = ({ bannerRef }: LoadingBannerProps) => {
  return (
    <div className={styles.loadingbanner} ref={bannerRef}>
      <div className={styles.inputTitleContainer}>
        <div className={styles.spinner}></div>
        <span className={styles.loadingTitle}>Indexing...</span>
      </div>

      <div className={styles.description}>
        Your data is currently being indexed, and this process might take some
        time, depending on your network speed and the amount of data being
        processed.
        <br />
        <ul>
          <li>
            In the meantime, feel free to exit this page without any concerns.
            We'll inform you when your dataset is prepared.
          </li>
        </ul>
      </div>
    </div>
  );
};

const IndexingBannerWrapper = ({
  bannerRef,
  showBanner,
  newDatasetId,
  toggleShowBanner,
  progressStatus,
}: IndexingBannerWrapperProps) => {
  const handleBannerDisplay = () => {
    if (showBanner) {
      if (progressStatus === StepTileProgressStatus.COMPLETE) {
        return (
          <SuccessBanner
            toggleShowBanner={toggleShowBanner}
            newDatasetId={newDatasetId}
            bannerRef={bannerRef}
          />
        );
      } else if (progressStatus === StepTileProgressStatus.IN_PROGRESS) {
        return <LoadingBanner bannerRef={bannerRef} />;
      }
    }

    return <noscript />;
  };

  return handleBannerDisplay();
};

export default IndexingBannerWrapper;

interface CommonProps {
  bannerRef: RefObject<HTMLDivElement>;
  toggleShowBanner: () => void;
  newDatasetId: string;
}

interface IndexingBannerWrapperProps extends CommonProps {
  showBanner: boolean;
  progressStatus: StepTileProgressStatus;
}

interface SuccessBannerProps extends CommonProps {}

interface LoadingBannerProps {
  bannerRef: RefObject<HTMLDivElement>;
}
