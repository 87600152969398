import styles from "./style.module.scss";
import { isFastdupUser } from "helpers/utility/utilities";
import VLCloudTitle from "assets/img/vl-cloud-title.svg";
import MagnifyingGlass from "assets/img/magnifying-glass.svg";
import LayersMagic from "assets/img/layers-magic.svg";
import crossImg from "assets/img/modalBlackCross.svg";
import {
  setShowLearnMoreModal,
  setShowDemoRequestModal,
} from "redux/Modals/actions";
import { useDispatch } from "react-redux";
import { amplitudeTrack } from "helpers/utility/amplitude";
import { AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__GET_STARTED_CLICKED } from "helpers/constants/amplitudeEvents";

export const LearnMoreModal = () => {
  const dispatch = useDispatch();
  const handleGetStartedClicked = () => {
    amplitudeTrack(
      AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__GET_STARTED_CLICKED
    );
    window.open("https://app.visual-layer.com?utm_source=fastdup_exploration");
  };

  const handleCloseModal = () => {
    dispatch(setShowLearnMoreModal(false));
  };

  if (isFastdupUser()) {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <div className={styles.closeBtn}>
            <button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleCloseModal();
              }}
            >
              <img src={crossImg} alt="" />
            </button>
          </div>
          <div className={styles.modalHeader}>
            <img src={VLCloudTitle} alt="Visual Layer cloud" />
          </div>
          <div className={styles.videoContainer}>
            <video autoPlay muted className={styles.video}>
              <source
                src="https://d1join20nb2yft.cloudfront.net/Exploration_v2.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.featuresLine}>
              <img src={MagnifyingGlass} alt="" />
              <div className={styles.featureBlock}>
                <div className={styles.featureTitle}>Explore with Ease</div>
                <div className={styles.featureDescription}>
                  Quickly view and manage all your datasets in one place to
                  identify and resolve quality issues like duplicated images and
                  incorrect labels.
                </div>
              </div>
              <div className={styles.featureBlock}>
                <div className={styles.featureTitle}>Enrich with AI</div>
                <div className={styles.featureDescription}>
                  Efficiently add tags and metadata using SOTA foundation models
                  from Visual Layer, open-source models, or 3rd party APIs only
                  on the data that matters, saving 98% of the costs.
                </div>
              </div>
            </div>
            <div className={styles.featuresLine}>
              <img src={LayersMagic} alt="" />
              <div className={styles.featureBlock}>
                <div className={styles.featureTitle}>Work at Scale</div>
                <div className={styles.featureDescription}>
                  Connect to all your data sources in one place and scale
                  effortlessly from 10GB to 100PB.
                </div>
              </div>
              <div className={styles.featureBlock}>
                <div className={styles.featureTitle}>Team Collaboration</div>
                <div className={styles.featureDescription}>
                  Share your datasets and work with your team members.
                </div>
              </div>
            </div>
            <div className={styles.modalActionItems}>
              <button
                className={styles.secondaryButton}
                onClick={() => {
                  dispatch(setShowLearnMoreModal(false));
                  dispatch(
                    setShowDemoRequestModal({
                      flag: true,
                      calledFrom: "fastdup_exploration",
                    })
                  );
                }}
              >
                On-Premise Solutions Contact Us
              </button>
              <button
                className={styles.signupButton}
                onClick={handleGetStartedClicked}
              >
                Get Started with Cloud Free
              </button>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <span>Having trouble signing-up / signing-in?</span>
            <span>
              Contact us at{" "}
              <a href="mailto:support@visual-layer.com">
                support@visual-layer.com
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return <noscript />;
  }
};
