import { FcGoogle } from "react-icons/fc";
import { BsGithub } from "react-icons/bs";

import styles from "./style.module.scss";

type LoginButtonTypes = {
  type: "google" | "github";
  text: string;
  children: any;
};

const LoginButton = (props: any) => {
  const { type, text, children }: LoginButtonTypes = props;

  const isGoogle = type === "google";

  const handleButtonStyle = isGoogle
    ? { backgroundColor: "#cadafd" }
    : undefined;
  const handleTextStyle = isGoogle ? { color: "#007DFA" } : undefined;
  const handleIcon = isGoogle ? (
    <FcGoogle size="1.5em" />
  ) : (
    <BsGithub size="1.5em" />
  );

  return (
    <div className={styles.loginButtonWrapper}>
      <button className={styles.loginButton} style={handleButtonStyle}>
        <div className={styles.loginButtonIcon}>{handleIcon}</div>
        <span className={styles.loginButtonText} style={handleTextStyle}>
          {text}
        </span>
      </button>
      {children}
    </div>
  );
};

export default LoginButton;
