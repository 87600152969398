import React from "react";
import { connect } from "react-redux";
import styles from "./styles.module.scss";
import { State } from "redux/store";
import { getUploadStatus } from "redux/CreateDataset/selectors";
import { StepTileProgressStatus } from "types";
import DangerCircleIcon from "assets/icons/DangerCircleIcon";

type UploadFailureProps = {
  uploadStatus: StepTileProgressStatus;
};

const UploadFailure: React.FC<UploadFailureProps> = ({ uploadStatus }) => {
  if (uploadStatus !== StepTileProgressStatus.FAILED) {
    return <noscript />;
  }

  return (
    <div className={styles.uploadFailureContainer}>
      <div className={styles.uploadFailureDangerIcon}>
        <DangerCircleIcon variant="outline" color="#fff" />
      </div>
      <div className={styles.uploadFailureHeaderTitle}>Upload failed</div>
      <div className={styles.uploadFailureMessage}>
        Upload failed due to an unexpected error. Please check your files and
        try again from the{" "}
        <span className={styles.uploadFailureMessageSource}>
          "Select Data Source"
        </span>{" "}
        step. Make sure you don't leave or close this tab until the upload is
        completed. If the problem persists, please contact support.
      </div>
      <div className={styles.uploadFailureProgressBarContainer}>
        <div className={styles.uploadFailureProgressBar}></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  uploadStatus: getUploadStatus(state),
});

export default connect(mapStateToProps)(UploadFailure);
