import { ReactNode, useRef, useState } from "react";
import { State } from "redux/store";
import { connect, useDispatch } from "react-redux";
import { QuotaLimitModalData, StepTileProgressStatus } from "types";
import { LimitExceeded } from "extractedBackendTypes";

import StepTile from "../StepTile";
import UploadIcon from "assets/icons/UploadIcon";
import {
  getIsDatasetIndexing,
  getLimitExceededDetails,
  getNewDatasetId,
  getUploadFInished,
  getUploadStatus,
} from "redux/CreateDataset/selectors";
import { setQuotaLimitModalData } from "redux/Modals/actions";
import { getQuotaLimitModalData } from "redux/Modals/selectors";
import UploadBanner from "./UploadBanner";

import PulsingDropdownIcon from "../PulsingDropdownIcon";
import styles from "./style.module.scss";
import { getIsUsageReportEnabled } from "redux/Datasets/selectors";

const UploadStep = ({
  uploadStatus,
  newDatasetId,
  limitExceededDetails,
  datasetIndexing,
  uploadFinished,
  quotaModalData,
  usageReportEnabled,
}: UploadStepProps) => {
  const [showBanner, setShowBanner] = useState(false);
  const uploadActive = uploadStatus === StepTileProgressStatus.ACTIVE;

  const bannerRef = useRef<HTMLDivElement>(null);
  const actionButtonRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const toggleShowBanner = () => {
    setShowBanner(!showBanner);
  };

  const handlePulsingArrowClick = () => {
    if (
      usageReportEnabled &&
      !!limitExceededDetails &&
      limitExceededDetails.limit_type === "MAX_USER_QUOTA" &&
      !quotaModalData?.allowUpload
    ) {
      dispatch(setQuotaLimitModalData({ ...quotaModalData, show: true }));
    } else {
      setShowBanner(!showBanner);
    }
  };

  const handleOutsideClick = (event: any) => {
    const actionButton = actionButtonRef.current;
    const banner = bannerRef.current;

    const editButtonClicked =
      actionButton && actionButton.contains(event.target);
    const bannerClicked = banner && banner.contains(event.target);

    if ((!actionButton || !editButtonClicked) && !bannerClicked) {
      setShowBanner(false);
    }
  };

  const renderHeadIcon = (icon: ReactNode, loading?: boolean): ReactNode => {
    if (loading) {
      return <div className={styles.spinner}></div>;
    } else {
      return <div className={styles.flexIcon}>{icon}</div>;
    }
  };

  const renderActionIcon = () => {
    if (!showBanner && uploadActive && !datasetIndexing) {
      return (
        <PulsingDropdownIcon
          showPulse={!showBanner}
          isDropdownOpen={showBanner}
          toggleDropdown={handlePulsingArrowClick}
          containerRef={actionButtonRef}
        />
      );
    } else return <></>;
  };

  return (
    <div className={styles.uploadContainer}>
      <StepTile
        title="Upload Data"
        status={uploadStatus}
        headIcon={renderHeadIcon(
          <UploadIcon
            selected={uploadStatus !== StepTileProgressStatus.DISABLED}
          />,
          uploadStatus === StepTileProgressStatus.IN_PROGRESS
        )}
        actionIcon={renderActionIcon()}
        isContentShown={showBanner}
        handleOutsideClick={handleOutsideClick}
      />

      <UploadBanner
        bannerRef={bannerRef}
        newDatasetId={newDatasetId}
        showBanner={showBanner}
        toggleShowBanner={toggleShowBanner}
        limitExceededDetails={limitExceededDetails}
        uploadFinished={uploadFinished}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    uploadStatus: getUploadStatus(state),
    newDatasetId: getNewDatasetId(state),
    limitExceededDetails: getLimitExceededDetails(state),
    datasetIndexing: getIsDatasetIndexing(state),
    uploadFinished: getUploadFInished(state),
    quotaModalData: getQuotaLimitModalData(state),
    usageReportEnabled: getIsUsageReportEnabled(state),
  };
};

export default connect(mapStateToProps)(UploadStep);

interface UploadStepProps {
  uploadStatus: StepTileProgressStatus;
  limitExceededDetails: LimitExceeded | null;
  newDatasetId: string;
  datasetIndexing: boolean;
  uploadFinished: boolean;
  quotaModalData: QuotaLimitModalData;
  usageReportEnabled: boolean;
}
