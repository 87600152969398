const LowGranularityIcon = ({ color = "#fff" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.8 5.6C8.8 5.44177 8.75308 5.2871 8.66518 5.15554C8.57727 5.02398 8.45233 4.92145 8.30615 4.8609C8.15997 4.80035 7.99911 4.7845 7.84393 4.81537C7.68874 4.84624 7.5462 4.92243 7.43431 5.03431C7.32243 5.1462 7.24624 5.28874 7.21537 5.44393C7.1845 5.59911 7.20035 5.75997 7.2609 5.90615C7.32145 6.05233 7.42398 6.17727 7.55554 6.26518C7.6871 6.35308 7.84177 6.4 8 6.4C8.21217 6.4 8.41566 6.31571 8.56569 6.16568C8.71571 6.01566 8.8 5.81217 8.8 5.6ZM16 12L16 4C16 2.93913 15.5786 1.92172 14.8284 1.17157C14.0783 0.421427 13.0609 -1.28474e-07 12 -1.74846e-07L4 -5.24537e-07C2.93913 -5.70909e-07 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 -1.28474e-07 2.93913 -1.74846e-07 4L-5.24537e-07 12C-5.70909e-07 13.0609 0.421426 14.0783 1.17157 14.8284C1.92172 15.5786 2.93913 16 4 16L12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12ZM4 14.4C3.36348 14.4 2.75303 14.1471 2.30294 13.6971C1.85286 13.247 1.6 12.6365 1.6 12L1.6 4C1.6 3.36348 1.85286 2.75303 2.30294 2.30294C2.75303 1.85286 3.36348 1.6 4 1.6L12 1.6C12.6365 1.6 13.247 1.85286 13.6971 2.30294C14.1471 2.75303 14.4 3.36348 14.4 4L14.4 12C14.4 12.6365 14.1471 13.247 13.6971 13.6971C13.247 14.1471 12.6365 14.4 12 14.4L4 14.4ZM8.8 10.4C8.8 10.2418 8.75308 10.0871 8.66518 9.95554C8.57727 9.82398 8.45233 9.72145 8.30615 9.6609C8.15997 9.60035 7.99911 9.5845 7.84393 9.61537C7.68874 9.64624 7.5462 9.72243 7.43431 9.83431C7.32243 9.9462 7.24624 10.0887 7.21537 10.2439C7.1845 10.3991 7.20035 10.56 7.2609 10.7061C7.32145 10.8523 7.42398 10.9773 7.55554 11.0652C7.6871 11.1531 7.84177 11.2 8 11.2C8.21217 11.2 8.41566 11.1157 8.56568 10.9657C8.71571 10.8157 8.8 10.6122 8.8 10.4Z"
        fill={color}
      />
    </svg>
  );
};

export default LowGranularityIcon;
