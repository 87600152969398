//File Upload Validation
export const validateLocalUpload = (file: File) => {
  let allowedTypes = ["zip", "tar"];
  let type = file.name.split(".").pop();
  if (type === undefined) {
    return false;
  }

  return allowedTypes.includes(type);
};

export const validateBucket = (value: string) => {
  if (value === "") {
    return true;
  }
  if (value.startsWith("s3://")) {
    value = value.replace("s3://", "");
  }
  //eslint-disable-next-line
  let bucketRegex = /^[a-zA-Z0-9.\-_(\/)]+$/;
  return bucketRegex.test(value);
};

export const validateDatasetName = (value: string) => {
  // Check if input is valid UTF-8
  try {
    // eslint-disable-next-line
    let utf8Text = decodeURIComponent(escape(value));
  } catch (err) {
    if (err) {
      return "Only UTF-8 supported";
    }
  }

  if (value === "") {
    return "";
  } else {
    let aplhaNumRegex = /^[a-zA-Z0-9_-\s]+$/;
    let result = aplhaNumRegex.test(value);
    if (result === false) {
      return "Please enter alphanumeric characters, underscores or hyphens only";
    }

    if (value === "") {
      return "";
    }
  }
  return "";
};

export const validateEmail = (email: any) => {
  if (email === "") {
    return "";
  }

  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (email.length < 5 || !regex.test(email)) {
    return "Please Enter Correct Email ID";
  }
  return "";
};

export const validateName = (name: any) => {
  try {
    // eslint-disable-next-line
    let utf8Text = decodeURIComponent(escape(name));
  } catch (err) {
    if (err) {
      return "Only UTF-8 supported";
    }
  }

  if (name === "") {
    return "";
  } else {
    let regex = /^[A-Za-z]+$/;
    let result = regex.test(name);
    if (result === false) {
      return "Please enter alphanumeric characters only";
    }
  }
  return "";
};

export const validateImageUpload = (file: File) => {
  const allowedMimeTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
  ];
  const allowedExtensions = ["jpg", "jpeg", "png", "gif", "jfif", "webp"];

  let extension = file.name.split(".").pop();

  const mimeType = file.type.toLowerCase();
  if (extension) {
    extension = extension.toLowerCase();
    if (
      allowedExtensions.includes(extension) &&
      allowedMimeTypes.includes(mimeType)
    ) {
      return true;
    } else return false;
  } else return false;
};

export const validatePhoneNumber = (phoneNumber: string): string => {
  const phoneNumberRegex =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  if (phoneNumberRegex.test(phoneNumber)) return "";
  else return "Please enter a valid phone number";
};
