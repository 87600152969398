import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { State } from "redux/store";
import { useEffect } from "react";

import {
  getImageDataLoader,
  getImageIssues,
  getImageLabels,
  getImageTagLoading,
  getImageTags,
  getSelectedIssues,
  getSelectedLabels,
} from "redux/Modals/selectors";
import { getUserConfig } from "redux/SingleDataset/selectors";
import { getUserTags } from "redux/SingleDataset/selectors";
import { fetchUserTags } from "redux/SingleDataset/actions";

import GeneralBox from "./General";
import VideoBox from "./VideoBox";
import UserTags from "./UserTags";
import IssuesBox from "./Issues";
import VLAccordian from "views/components/Accordian";
import {
  formatNumberWithCommas,
  timeStringToSeconds,
} from "helpers/utility/formatters";
import { truncateFilename } from "helpers/utility/utilities";

import styles from "./style.module.scss";
import VLEnrichmentIcon from "assets/icons/VLEnrichmentIcon";
import TagIcon from "assets/icons/tagIcon";
import ShieldIcon from "assets/icons/ShieldIcon";
import ImageMetadataIcon from "assets/icons/ImageMetadataIcon";
import VideoPlayIcon from "assets/icons/VideoPlayIcon";

const StatsSection = ({
  img,
  issues,
  labels,
  selectedLabels,
  selectedIssues,
  imageTags,
  userTags,
  filterConfig,
  isContentLoading,
  isImageTagLoading,
}: any) => {
  const dispatch = useDispatch();
  const { datasetId, imageId } = useParams();
  useEffect(() => {
    if (datasetId && imageId) {
      dispatch(fetchUserTags(datasetId) as any);
    }
  }, [datasetId, imageId, dispatch]);

  const imageLabels = labels?.filter(
    (label: any) => label.type === "IMAGE" && label.source === "USER"
  );
  const imageLabelNames = imageLabels.map((label: any) => label.categoryName);
  const objectLables = labels?.filter(
    (label: any) => label.type === "OBJECT" && label.source === "USER"
  );
  const vlImageLabels = labels?.filter(
    (label: any) => label.type === "IMAGE" && label.source === "VL"
  );
  const vlImageLabelNames = vlImageLabels.map(
    (label: any) => label.categoryName
  );
  const vlObjectLables = labels?.filter(
    (label: any) => label.type === "OBJECT" && label.source === "VL"
  );

  const tagsHeaderTitle = `User Tags ${
    !isContentLoading && !isImageTagLoading
      ? `(${imageTags?.length ? formatNumberWithCommas(imageTags.length) : 0})`
      : ""
  }`;

  return (
    <div className={styles.statsContainer}>
      <div className={styles.mainBody}>
        {(vlImageLabels?.length > 0 || vlObjectLables?.length > 0) && (
          <VLAccordian
            title={"VL Enrichment"}
            hideInfo={true}
            fontSize="14px"
            bgColor={"#151928"}
            height="49px"
            icon={<VLEnrichmentIcon />}
          >
            <GeneralBox
              imageLabels={vlImageLabelNames}
              objectLables={vlObjectLables}
              selectedLabels={selectedLabels}
            />
          </VLAccordian>
        )}
        {!!img?.video_uri && (
          <VLAccordian
            title={
              img.video_name
                ? `Frame from ${truncateFilename(img.video_name, 28)}`
                : "Video"
            }
            showTitleOnHover={
              img.video_name?.length > 28 ? img.video_name : false
            }
            hideInfo={true}
            fontSize="14px"
            bgColor={"#151928"}
            height="49px"
            icon={<VideoPlayIcon />}
          >
            <VideoBox
              src={img.video_uri}
              offset={
                img.frame_timestamp
                  ? timeStringToSeconds(img.frame_timestamp)
                  : 0
              }
              duration={2}
            />
          </VLAccordian>
        )}
        <VLAccordian
          title="General"
          hideInfo={true}
          icon={<ImageMetadataIcon />}
          fontSize="14px"
          bgColor={"#151928"}
          height="49px"
        >
          <GeneralBox
            img={img}
            imageLabels={imageLabelNames}
            objectLables={objectLables}
            selectedLabels={selectedLabels}
          />
        </VLAccordian>
        <VLAccordian
          title={tagsHeaderTitle}
          icon={<TagIcon color="#60646E" size="16" />}
          hideInfo={true}
          fontSize="14px"
          bgColor={"#151928"}
          height="49px"
          initiallyClosed={false}
          behavior={
            filterConfig?.find(
              (feature: any) => feature.feature_key === "TAGS_FILTER"
            )?.feature_behavior.ANY || "HIDE"
          }
        >
          <UserTags
            imageTags={imageTags}
            userTags={userTags.filter((tag: any) => tag.text !== "untagged")}
            isContentLoading={isContentLoading}
            isImageTagLoading={isImageTagLoading}
          />
        </VLAccordian>
        {issues && issues.length > 0 && (
          <VLAccordian
            title="Issues"
            icon={<ShieldIcon />}
            hideInfo={true}
            fontSize="14px"
            bgColor={"#151928"}
            height="49px"
          >
            <IssuesBox issues={issues} selectedIssues={selectedIssues} />
          </VLAccordian>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    labels: getImageLabels(state),
    issues: getImageIssues(state),
    selectedLabels: getSelectedLabels(state),
    selectedIssues: getSelectedIssues(state),
    imageTags: getImageTags(state),
    userTags: getUserTags(state),
    filterConfig: getUserConfig(state),
    isContentLoading: getImageDataLoader(state),
    isImageTagLoading: getImageTagLoading(state),
  };
};

export default connect(mapStateToProps)(StatsSection);
