import moment from "moment";
import { Moment } from "moment";
import Datetime from "react-datetime";
import { useState, useEffect, useRef } from "react";

import { DATA_PAGE } from "helpers/constants/pages";
import { BRIGHT_BLUE } from "helpers/constants/colors";
import VLAccordian from "views/components/Accordian";

import { FilterBehavior, TimeRange } from "types";

import "./css/react-datetime.css";
import styles from "./style.module.scss";

function formatDateRange(start?: Moment, end?: Moment): string {
  const formatTime = (date: Moment, full = false) => {
    if (!full && date.hour() === 0 && date.minute() === 0) {
      return "";
    } else {
      return `${date.hour()}:${String(date.minute()).padStart(2, "0")}`;
    }
  };
  if (!start && !end) {
    return "Date Range";
  } else if (start && end) {
    const startTime = formatTime(start);
    const endTime = formatTime(end);
    if (start.month() === end.month() && start.date() === end.date()) {
      return `${start.date()}/${start.month() + 1} ${formatTime(
        start,
        true
      )}-${formatTime(end, true)}`;
    } else {
      return `${start.date()}/${start.month() + 1} ${startTime}-${end.date()}/${
        end.month() + 1
      } ${endTime}`;
    }
  } else if (start) {
    return `Start: ${start.date()}/${start.month() + 1} ${formatTime(start)}`;
  } else {
    // only end
    return `End: ${end!.date()}/${end!.month() + 1} ${formatTime(end!)}`;
  }
}

const convertMsToMoment = (
  timestamp: number | undefined
): Moment | undefined => {
  return timestamp ? moment(timestamp) : undefined;
};

function DateTimeRangePicker(props: {
  timeRange: TimeRange;
  handleTimeRangeChange: any;
  behavior: FilterBehavior;
}) {
  const [start, setStart] = useState(convertMsToMoment(props.timeRange?.start));
  const [end, setEnd] = useState(convertMsToMoment(props.timeRange?.end));

  useEffect(() => {
    setStart(convertMsToMoment(props.timeRange?.start));
    setEnd(convertMsToMoment(props.timeRange?.end));
  }, [props]);

  const startRef = useRef(start);
  const endRef = useRef(end);

  useEffect(() => {
    startRef.current = start;
    endRef.current = end;
    // console.log(start);
    // console.log(end);
  }, [start, end]);

  const onSubmit = () => {
    const startMS = startRef.current?.valueOf();
    const endMS = endRef.current?.valueOf();
    if (startMS !== props.timeRange?.start || endMS !== props.timeRange?.end) {
      props.handleTimeRangeChange({ start: startMS, end: endMS });
    }
  };

  const title = formatDateRange(start, end);
  const timeRangeSelected = !!start || !!end;

  return (
    <VLAccordian
      title={title}
      page={DATA_PAGE}
      customWidth={"max-content"}
      hideInfo={true}
      initiallyClosed={true}
      fontSize={"13px"}
      bgColor={timeRangeSelected ? BRIGHT_BLUE : "#151928"}
      borderRadius="5px"
      padding={"0 10px"}
      height="40px"
      border={timeRangeSelected ? "1px solid #fff" : undefined}
      type="timeRange"
      allowedClickedClassNames={["rdtTimeToggle", "rdtSwitch"]}
      onClose={() => onSubmit()}
      behavior={props.behavior}
    >
      <div className={styles.dropdown}>
        <div className={styles.dateRangeWrapper}>
          <div className={styles.dateRangePicker}>
            <div className={styles.dateRangePickerHeader}>
              <label>Start Date</label>
              <div
                className={styles.dateRangeClear}
                onClick={() => {
                  setStart(undefined);
                }}
                style={{ cursor: `${!!start ? "pointer" : "default"}` }}
              >
                Clear
              </div>
            </div>
            <Datetime
              className="dateTimePicker"
              dateFormat="YYYY-MM-DD"
              timeFormat="HH:mm:ss"
              value={start}
              open={true}
              onChange={(date: any) => setStart(date)}
              isValidDate={(currentDate) => true}
            />
          </div>
          <div className={styles.dateRangePicker}>
            <div className={styles.dateRangePickerHeader}>
              <label>End Date</label>
              <div
                className={styles.dateRangeClear}
                onClick={() => {
                  setEnd(undefined);
                }}
                style={{ cursor: `${!!end ? "pointer" : "default"}` }}
              >
                Clear
              </div>
            </div>
            <Datetime
              className="dateTimePicker"
              dateFormat="YYYY-MM-DD"
              timeFormat="HH:mm:ss"
              value={end}
              open={true}
              onChange={(date: any) => setEnd(date)}
              isValidDate={(currentDate) => true}
            />
          </div>
        </div>
      </div>
    </VLAccordian>
  );
}

export default DateTimeRangePicker;
