import { useDispatch } from "react-redux";
import { useState } from "react";
import Content from "../../Content";
import VLAccordian from "views/components/Accordian";
import {
  setShowDemoRequestModal,
  setShowLearnMoreModal,
} from "redux/Modals/actions";
import VLIcon from "views/uikit/VLIcon";
import styles from "./style.module.scss";
import { isFastdupUser } from "helpers/utility/utilities";
import classNames from "classnames";
import { amplitudeTrack } from "helpers/utility/amplitude";
import { AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__OPENED } from "helpers/constants/amplitudeEvents";

const VLEnrichmentContactBanner = () => {
  const dispatch = useDispatch();

  const handleContactButtonClick = () => {
    if (isFastdupUser()) {
      dispatch(setShowLearnMoreModal(true));
      amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__OPENED);
    } else {
      dispatch(
        setShowDemoRequestModal({ flag: true, calledFrom: "vl_enrichment" })
      );
    }
  };

  return (
    <div className={styles.vlEnrichmentContactBanner}>
      <div className={styles.TitleContainer}>
        <div className={styles.titleIcon}>
          <VLIcon />
        </div>
        <div className={styles.titleText}>Visual Layer Enrichment</div>
      </div>
      <div className={styles.body}>
        <div className={styles.messageContainer}>
          Contact us to enrich your data with objects, labels, keywords and
          tags.
        </div>
        <div
          className={classNames(styles.contactUsButton, {
            [styles.learnMoreButton]: isFastdupUser(),
          })}
          onClick={handleContactButtonClick}
        >
          {isFastdupUser() ? "LEARN MORE" : "Contact us"}
        </div>
      </div>
    </div>
  );
};

const VLEnrichmentSection = ({
  labels,
  vlEnrichmentRef,
  vlEnrichmentCategory,
  showVLEnrichment,
  sectionHeight,
  isLoading,
  icon,
}: any) => {
  const [selectedID, setSelectedID] = useState(null);
  const handleDisplay = () => {
    if (isLoading) {
      return <div className={styles.loadingBox}>Loading...</div>;
    } else {
      return (
        <div
          className={styles.section}
          style={{ height: !!sectionHeight ? sectionHeight : "max-content" }}
        >
          <Content
            title={"Keywords"}
            totalCount={labels.length}
            type={"labels"}
            data={labels}
            sectionType={"vl-enrichment"}
            id={`vl-enrichment-1`}
            selectedID={selectedID}
            setSelectedID={setSelectedID}
          />
        </div>
      );
    }
  };

  return showVLEnrichment || isLoading ? (
    <div className={styles.vlEnrichmentWrapper} ref={vlEnrichmentRef}>
      <VLAccordian
        title="VL Enrichment"
        icon={icon}
        childrenBgColor={"#151928"}
        roundCorners="10px"
        borderRadius="10px 10px 0 0"
        padding="0 15px 0 13px"
        height="47px"
        category={vlEnrichmentCategory}
        initiallyClosed={true}
      >
        {handleDisplay()}
      </VLAccordian>
    </div>
  ) : (
    <VLEnrichmentContactBanner />
  );
};
export default VLEnrichmentSection;
