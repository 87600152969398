class AbortControllerManager {
  private controller: AbortController | null = null;

  getController(): AbortController {
    if (!this.controller) {
      this.controller = new AbortController();
    }
    return this.controller;
  }

  abortController(): void {
    if (this.controller) {
      this.controller.abort();
      this.controller = null;
    }
  }
}

const abortControllerManager = new AbortControllerManager();
export default abortControllerManager;
