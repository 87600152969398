import { useLocation, useNavigate } from "react-router";
import { useEffect, useState, useRef } from "react";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { State } from "redux/store";
import {
  getClusterPageNumber,
  getCurrentFilterQueries,
  getDataPageNumber,
  getEntityTypeFilter,
  getFetchError,
  getIsToggleConfig,
  getSimilarityVertexCluster,
} from "redux/SingleDataset/selectors";

import SingleCard from "./SingleCard";
import {
  clearFilters,
  fetchDataset,
  fetchDatasetLabels,
  fetchUserTags,
  setVertexPreviewsCountContext,
} from "redux/SingleDataset/actions";
import {
  useWindowResize,
  calculatePlaceHolderCount,
} from "helpers/utility/utilities";
import {
  PAGE_PARAMETER,
  VERTEX_CONTEXT,
  VERTEX_ID,
  VERTEX_TYPE,
  ENTITY_TYPE_PARAMETER,
  GRANULARITY_PARAMETER,
} from "helpers/constants/miscellaneous";
import FetchErrorCard from "views/components/FetchErrorCard";
import {
  NoMatchFoundCard,
  NoMatchFoundPlaceholder,
} from "views/components/NoMatchFoundCard";
import { AMP_DATASET_EXPLORATION_EVENT__FILTER__ALL_CLEARED } from "helpers/constants/amplitudeEvents";
import { AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE } from "helpers/constants/amplitudeProperties";

import styles from "./style.module.scss";
import classNames from "classnames";
import VertexCard from "./VertexCard";
import { FilterOption } from "types";
import { amplitudeTrack } from "helpers/utility/amplitude";

const DataList = ({
  isContentLoading,
  clusters,
  navigationCluster,
  page,
  dataPageNumber,
  clusterPageNumber,
  showMetadataSummary,
  fetchError,
  currentFilterQueries,
  entityTypeFilter,
  isVertexSelected,
  isEntityToggle,
}: any) => {
  const [placeholderCount, setPlaceholderCount] = useState(0);
  const [indexOfPointerOut, setIndexOfPointerOut] = useState(-1);
  const [length, setLength] = useState(1);

  const dispatch = useDispatch();
  const { datasetId, clusterId } = useParams();
  const { height, width } = useWindowResize();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const cardContainer = useRef<HTMLDivElement>(null);
  const cardElement = useRef<HTMLDivElement>(null);

  const isDataPage = !!datasetId && !clusterId;
  const isClusterPage = !!datasetId && !!clusterId;

  let sortedList;
  if (fetchError) {
    sortedList = [];
  }
  if (isDataPage) {
    sortedList = clusters;
  } else {
    sortedList = [];
    if (navigationCluster) {
      sortedList.push(...navigationCluster.previews);
    }
  }

  const pageNumber = isClusterPage ? clusterPageNumber : dataPageNumber;

  const listClassNames = classNames({
    [styles.listWithMetadata]: showMetadataSummary,
    [styles.list]: !showMetadataSummary,
    [styles.hiddenOverflowY]: length <= 0,
  });

  const removeParameters = (searchParams: URLSearchParams) => {
    let keysToBeDeleted: any = [];
    searchParams.forEach((value: any, key: any) => {
      //ignoring the filters that are not reset
      if (key === PAGE_PARAMETER || key === GRANULARITY_PARAMETER) {
        //do nothing
      } else if (key === ENTITY_TYPE_PARAMETER && isEntityToggle) {
        //do nothing
      } else {
        keysToBeDeleted.push(key);
      }
    });

    if (keysToBeDeleted.length > 0) {
      keysToBeDeleted.forEach((key: any) => searchParams.delete(key));
    }
    return searchParams;
  };

  const onClearFilters = async () => {
    dispatch(clearFilters() as any);

    const selectedEntities = entityTypeFilter
      .filter((entity: FilterOption) => entity.selected)
      .map((entity: FilterOption) => entity.text);
    let value;
    if (selectedEntities.length > 0) {
      value = selectedEntities.join(",");
    } else {
      value = "Images";
    }
    amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__FILTER__ALL_CLEARED, {
      [AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE]: value,
    });
    if (!isVertexSelected) {
      searchParams.set(PAGE_PARAMETER, "1");
    }
    const updatedSearchParams = removeParameters(searchParams);
    const newParams = `?${updatedSearchParams.toString()}`;
    let newPath = location.pathname;

    if (newPath.includes("/cluster")) {
      newPath = newPath.split("/cluster")[0];
    }
    dispatch(fetchDatasetLabels(datasetId) as any);
    dispatch(fetchUserTags(datasetId) as any);
    dispatch(fetchDataset(datasetId) as any);
    navigate(newPath.concat(newParams));
  };

  const showNoMatchFound = () => {
    if (fetchError) {
      return <FetchErrorCard />;
    } else if (!isContentLoading && length === 0 && !isVertexSelected) {
      return <NoMatchFoundCard handleClearFilters={onClearFilters} />;
    }
  };

  const showNoMatchFoundPlaceholder =
    !isContentLoading && isVertexSelected && length === 0;

  const handleFindSimilarClick = useCallback(
    (id: string, type: "MEDIA" | "CLUSTER") => {
      searchParams.set(PAGE_PARAMETER, "1");
      if (type === "MEDIA") {
        dispatch(setVertexPreviewsCountContext(0));
        searchParams.set(VERTEX_ID, id);
        searchParams.set(VERTEX_TYPE, "MEDIA");
      } else if (type === "CLUSTER") {
        const vertexCluster =
          clusters?.find((cluster: any) => cluster.cluster_id === id) || [];
        if (!!vertexCluster) {
          const vertexClusterPreviewCount = vertexCluster.previews?.length || 0;
          dispatch(setVertexPreviewsCountContext(vertexClusterPreviewCount));
          if (currentFilterQueries) {
            searchParams.set(VERTEX_CONTEXT, currentFilterQueries);
          }
          searchParams.set(VERTEX_ID, vertexCluster.previews[0].media_id);
          searchParams.set(VERTEX_TYPE, "CLUSTER");
        }
      }
      const path = isDataPage
        ? `/dataset/${datasetId}/data`
        : `/dataset/${datasetId}/data/cluster/${clusterId}`;
      navigate({
        pathname: path,
        search: `?${searchParams.toString()}`,
      });
    },
    //eslint-disable-next-line
    [dispatch, datasetId, navigate, searchParams, pageNumber, clusters]
  );

  useEffect(() => {
    if (sortedList?.length > 0) {
      setLength(sortedList.length);
    } else if (!isContentLoading) {
      // set whatever length after loading finishes
      setLength(sortedList?.length);
    } else if (isContentLoading && !isVertexSelected && length === 0) {
      setLength(1);
    }
    //eslint-disable-next-line
  }, [isContentLoading]);

  useEffect(() => {
    cardContainer.current && cardContainer.current.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const extraWidth = 26;
    setPlaceholderCount(
      calculatePlaceHolderCount(
        cardContainer?.current,
        cardElement?.current,
        length,
        extraWidth
      )
    );
    if (isVertexSelected && length === 0 && !isContentLoading) {
      setPlaceholderCount(1);
    }
  }, [height, width, length, cardElement, isVertexSelected, isContentLoading]);

  return (
    <div className={styles.singleListWrapper} data-test-id="singleListWrapper">
      <div className={listClassNames} ref={cardContainer}>
        <VertexCard
          cardElementRef={cardElement}
          cardContainerRef={cardContainer}
          indexOfPointerOut={indexOfPointerOut}
          setIndexOfPointerOut={setIndexOfPointerOut}
        />

        {sortedList?.map((singleData: any, index: number) => {
          const clusterID = isDataPage
            ? singleData.cluster_id
            : navigationCluster?.cluster_id;
          return (
            <SingleCard
              key={index}
              index={index}
              singleData={singleData}
              datasetId={datasetId!}
              cardElementRef={cardElement}
              containerRef={cardContainer}
              page={page}
              isDataPage={isDataPage}
              isClusterPage={isClusterPage}
              isCardSelected={false}
              handleFindSimilarClick={handleFindSimilarClick}
              clusterID={clusterID}
              showExportOption={isDataPage || isClusterPage}
              searchParams={searchParams}
              indexOfPointerOut={indexOfPointerOut}
              setIndexOfPointerOut={setIndexOfPointerOut}
            />
          );
        })}
        {placeholderCount > 0 &&
          [...Array(placeholderCount)].map((_, index) => (
            <div key={index} className={styles.cardPlaceHolder}>
              {showNoMatchFoundPlaceholder && index === 0 && (
                <NoMatchFoundPlaceholder handleClearFilters={onClearFilters} />
              )}
            </div>
          ))}
        {showNoMatchFound()}
      </div>
    </div>
  );
};

const mapStatesToProps = (state: State) => {
  return {
    dataPageNumber: getDataPageNumber(state),
    clusterPageNumber: getClusterPageNumber(state),
    fetchError: getFetchError(state),
    currentFilterQueries: getCurrentFilterQueries(state, false, false),
    entityTypeFilter: getEntityTypeFilter(state),
    isVertexSelected: !!getSimilarityVertexCluster(state),
    isEntityToggle: getIsToggleConfig("entityType")(state),
  };
};

export default connect(mapStatesToProps)(DataList);
