import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import axios from "helpers/axiosInstance";
import { composeApiURL } from "helpers/utility/axiosHelpers";

import { State } from "redux/store";
import {
  IS_CONTENT_LOADING,
  IS_CARDSLIST_LOADING,
  IS_LABEL_LOADING,
  IS_ISSUE_TYPE_LOADING,
  SET_DATASET_SORTING_VALUE,
  SET_FILTER_ISSUE,
  SET_FILTER_SEVERITY,
  SET_ISSUE_DATA,
  SET_SEVERITY_DATA,
  SET_SIMILARITY_DATA,
  SET_SIMILARITY_VERTEX,
  SET_SIMILARITY_THRESHOLD,
  SET_SIMILARITY_THRESHOLDS,
  SET_ENTITY_TYPE_FILTER,
  SET_DATASET_LABELS,
  SET_DATASET_ISSUE_TYPES,
  SET_EXPLORATION_STATS,
  SET_NAVIGATION_CLUSTER,
  SET_NAVIGATION_CLUSTER_LOADER,
  SET_SEARCH_TEXT,
  SET_FILE_NAME_SEARCH_TEXT,
  SET_TIME_RANGE,
  SET_FILTER_CONFIG,
  SET_TREE_FOLDER,
  SET_FILTER_FOLDER,
  SET_EXPORT_DATA,
  SET_SHOW_CART,
  SET_TEMP_EXPORT_DATA,
  SET_EXPORT_DATA_TIMEOUT_ID,
  SET_PAGINATION_METADATA_FOR_DATA,
  SET_PAGINATION_METADATA_FOR_CLUSTER,
  SET_CLUSTER_METADATA_SUMMARY,
  SET_CLUSTER_METADATA_SUMMARY_LOADING,
  SET_USER_TAGS,
  UPDATE_USER_TAGS,
  IS_USER_TAG_LOADING,
  UPDATE_FILE_SELECTION,
  SET_SIMILARITY_VERTEX_ID,
  SET_DATA_PAGE_NUMBER,
  SET_CLUSTER_PAGE_NUMBER,
  RESET_STORE,
  SET_NAVIGATION_CONTEXT,
  SET_IS_IMAGE_UPLOADING,
  HOVERED_FILE_STATE,
  SET_DATA_METADATA_SUMMARY,
  SET_DATA_METADATA_SUMMARY_LOADING,
  SET_FETCH_ERROR,
  SET_RECENT_SEARCHES,
  SET_AUTOCOMPLETE_SUGGESTIONS,
  SET_AUTOCOMPLETE_LOADING,
  SET_AUTOCOMPLETE_NO_RESULTS_FLAG,
  SET_VERTEX_PREVIEWS_COUNT_CONTEXT,
  SET_IMAGE_PAGE_PARAMS_CONTEXT,
  SET_VERTEX_CONTEXT,
  SET_ASSIGNED_TAGS,
  SET_SIMILARITY_VERTEX_TYPE,
  SET_SHOW_EXPORT_MODAL,
  SET_EXPORT_PROGRESS,
  SET_SHOW_SHARING_MENU,
  SET_USERS_WITH_ACCESS,
  SET_DATASET_TAGS,
} from "./constants";
import { EventTarget, FilterOption, VertexType } from "types";

import { transformFilterData } from "helpers/utility/utilities";
import * as Feature from "helpers/constants/filters";
import { addImageTag } from "redux/Modals/actions";
import {
  CLUSTER_DETAILS_PAGE,
  DATA_PAGE,
  IMAGE_DETAILS_PAGE,
} from "helpers/constants/pages";
import {
  AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE,
  AMP_DATASET_EXPLORATION_PROPERTY__CONTEXT,
  AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE,
  AMP_IMAGE_COUNT,
  AMP_DATASET_EXPLORATION_PROPERTY__TARGET,
} from "helpers/constants/amplitudeProperties";
import { amplitudeTrack } from "helpers/utility/amplitude";
import {
  AMP_DATASET_EXPLORATION_EVENT__ENTITY__SELECTED,
  AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__RETURNED_NO_RESULT,
  AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__RETURNED_RESULTS,
  AMP_DATASET_EXPLORATION_EVENT__USER_TAG__ASSIGNED,
} from "helpers/constants/amplitudeEvents";
import {
  getCurrentFilterQueries,
  getExportData,
  getIsClusterMetadataSummaryEnabled,
  getNavigationClusterID,
  getVertexQuery,
} from "./selectors";
import { CancelTokenSource } from "axios";
import { FilesToExport, ExportFormatOptions } from "views/modals/ExportModal";
let cancelExploreToken: CancelTokenSource | undefined;

const FALLBACK_EXPORT_ERROR_TEXT =
  "Unfortunately, we encountered an error while processing the file. Please try again.";

export const setContentLoader = (data: any) => {
  return { type: IS_CONTENT_LOADING, data };
};

export const setCardslistLoading = (data: any) => {
  return { type: IS_CARDSLIST_LOADING, data };
};

export const setLabelLoading = (data: any) => {
  return { type: IS_LABEL_LOADING, data };
};

export const setIssueTypeLoading = (data: any) => {
  return { type: IS_ISSUE_TYPE_LOADING, data };
};

export const setDatasetSortingValue = (data: any) => {
  return { type: SET_DATASET_SORTING_VALUE, data };
};

export const setIssueData = (data: any) => {
  return { type: SET_ISSUE_DATA, data };
};

export const setSeverityData = (data: any) => {
  return { type: SET_SEVERITY_DATA, data };
};

export const setFilterIssue = (data: any) => {
  return { type: SET_FILTER_ISSUE, data };
};

export const setFilterSeverity = (data: any) => {
  return { type: SET_FILTER_SEVERITY, data };
};

export const setSimilarityData = (data: any) => {
  return { type: SET_SIMILARITY_DATA, data };
};

export const setExplorationStats = (data: any) => {
  return { type: SET_EXPLORATION_STATS, data };
};

export const setVertexID = (data: string | null) => {
  return { type: SET_SIMILARITY_VERTEX_ID, data };
};

export const setVertexType = (data: VertexType | null) => {
  return { type: SET_SIMILARITY_VERTEX_TYPE, data };
};

export const setSimilarityVertex = (data: any) => {
  return { type: SET_SIMILARITY_VERTEX, data };
};

export const setSimilarityThresholds = (data: {
  similarity_thresholds: string[];
}) => {
  return { type: SET_SIMILARITY_THRESHOLDS, data };
};

export const setSimilarityThreshold = (data: { threshold: string }) => {
  return { type: SET_SIMILARITY_THRESHOLD, data };
};

export const setDatasetLabels = (data: any) => {
  return { type: SET_DATASET_LABELS, data };
};

export const setEntityTypeFilter = (data: any) => {
  return { type: SET_ENTITY_TYPE_FILTER, data };
};

export const setDatasetIssueTypes = (data: any) => {
  return { type: SET_DATASET_ISSUE_TYPES, data };
};

export const setNavigationCluster = (data: any) => {
  return { type: SET_NAVIGATION_CLUSTER, data };
};

export const setSearchText = (data: any) => {
  return { type: SET_SEARCH_TEXT, data };
};

export const setFileNameSearchText = (data: any) => {
  return { type: SET_FILE_NAME_SEARCH_TEXT, data };
};

export const setNavigationClusterLoader = (data: boolean) => {
  return { type: SET_NAVIGATION_CLUSTER_LOADER, data };
};

export const setTimeRange = (data: any) => {
  return { type: SET_TIME_RANGE, data };
};

export const setFilterConfig = (data: any) => {
  return { type: SET_FILTER_CONFIG, data };
};

export const setTreeFolder = (data: any) => {
  return { type: SET_TREE_FOLDER, data };
};

export const setFilterFolder = (data: any) => {
  return { type: SET_FILTER_FOLDER, data };
};

export const setShowCart = (data: boolean) => {
  return { type: SET_SHOW_CART, data };
};

export const setShowExportModal = (data: boolean) => {
  return { type: SET_SHOW_EXPORT_MODAL, data };
};

export const setExportProgress = (data: number) => {
  return { type: SET_EXPORT_PROGRESS, data };
};

export const setShowSharingMenu = (data: boolean) => {
  return { type: SET_SHOW_SHARING_MENU, data };
};

export const setUsersWithAccess = (data: boolean) => {
  return { type: SET_USERS_WITH_ACCESS, data };
};

export const fetchDataset = (
  uuid: any,
  clusterId?: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    dispatch(setSeverityData(null));
    dispatch(setContentLoader(true));
    if (!clusterId) {
      dispatch(setDatasetIssueTypes([]));
    }
    try {
      const statsUrl = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${uuid}/stats`
      );
      const statsResponse = await axios.get(statsUrl);
      const stats = statsResponse.data;
      dispatch(setSeverityData(stats));
      dispatch(setContentLoader(false));
      if (!clusterId) dispatch(setIssueTypeLoading(false));
    } catch (error) {
      dispatch(setContentLoader(false));
      dispatch(setCardslistLoading(false));
      if (!clusterId) dispatch(setIssueTypeLoading(false));
      dispatch(setFetchError(true));
      console.log(error);
    }
  };
};

export const fetchDatasetIssues = (
  uuid: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const state = { ...getState().singleDataset };
      const selectedIssues = state.issueTypes.filter(
        (issue: any) => issue.selected
      );
      dispatch(setDatasetIssueTypes(selectedIssues));
      dispatch(setIssueTypeLoading(true));
      const entityTypeValues = [...state.entityTypeFilter];
      let paramValue = "";
      if (entityTypeValues) {
        paramValue = createEntityParams(entityTypeValues, state.filterConfig);
      }
      const issuesUrl = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${uuid}/issue_types_of_entities${paramValue}`
      );
      const issuesReponse = await axios.get(issuesUrl);
      const selectedIssuesIds = selectedIssues.map((issue: any) => issue.id);
      const issueTypes = issuesReponse.data.map((issue: any) => {
        return {
          id: issue.id,
          text: issue.name,
          selected: selectedIssuesIds.includes(issue.id),
        };
      });
      dispatch(setDatasetIssueTypes(issueTypes));
      dispatch(setIssueTypeLoading(false));
    } catch (error) {
      dispatch(setIssueTypeLoading(false));
    }
  };
};
export const fetchClusterPageIssueTypes = (
  datasetId: any,
  clusterId: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const state = { ...getState().singleDataset };
    const selectedIssues = state.issueTypes.filter(
      (issue: any) => issue.selected
    );
    dispatch(setDatasetIssueTypes(selectedIssues));
    dispatch(setIssueTypeLoading(true));

    const entityTypeValues = [...state.entityTypeFilter];

    let paramValue = "";
    if (entityTypeValues) {
      paramValue = createEntityParams(entityTypeValues, state.filterConfig);
    }
    try {
      const path = `/dataset/${datasetId}/cluster/${clusterId}/issues${paramValue}`;

      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);

      const response = await axios.get(url);

      if (response.data && response.data.length > 0) {
        const selectedIssuesIds = selectedIssues.map((issue: any) => issue.id);
        const issueTypes = response.data.map((issue: any) => {
          return {
            id: issue.id,
            text: issue.name,
            selected: selectedIssuesIds.includes(issue.id),
          };
        });
        dispatch(setDatasetIssueTypes(issueTypes));
        dispatch(setIssueTypeLoading(false));
      }
      dispatch(setIssueTypeLoading(false));
    } catch (error) {
      dispatch(setIssueTypeLoading(false));
      console.log(error);
    }
  };
};

export const fetchSortedData = (
  uuid: any,
  sortingValue: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setIssueData(null));
      dispatch(setCardslistLoading(true));
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${uuid}/issues${
          sortingValue === "name" ? "sort_by=name" : null
        }`
      );

      const response = await axios.get(url);

      dispatch(setIssueData(response.data));
      dispatch(setCardslistLoading(false));
    } catch (error) {
      dispatch(setCardslistLoading(false));
      console.log(error);
    }
  };
};

export const updateUserTagsWithNewTags = (data: any) => {
  return { type: UPDATE_USER_TAGS, data };
};

export const setUserTags = (data: any) => {
  return { type: SET_USER_TAGS, data };
};

export const setDatasetTags = (data: any) => {
  return { type: SET_DATASET_TAGS, data };
};

export const updateFileSelection = ({
  title,
  isSelected,
}: {
  title: string;
  isSelected: boolean;
}) => {
  return { type: UPDATE_FILE_SELECTION, data: { title, isSelected } };
};

export const fetchDataMetadataSummary = (
  datasetId: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const queries = getCurrentFilterQueries(getState());

    try {
      dispatch(dataMetadataSummaryLoading(true));
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/explore/${datasetId}/metadata_summary${queries}`
      );
      try {
        const response = await axios.get(url);

        dispatch(setDataMetadataSummary(response.data));
      } catch (error) {
        console.log(error);
        dispatch(dataMetadataSummaryLoading(false));
      }

      dispatch(dataMetadataSummaryLoading(false));
    } catch (e) {
      console.log("Error fetching Metadata Summary for Data Page", e);
      dispatch(dataMetadataSummaryLoading(false));
    }
  };
};

export const fetchClusterMetadataSummary = (
  datasetId: string,
  clusterId: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const queries = getCurrentFilterQueries(getState());

    dispatch(clusterMetadataSummaryLoading(true));
    const url = composeApiURL(
      process.env.REACT_APP_API_ENDPOINT!,
      `/explore/${datasetId}/similarity_cluster/${clusterId}/metadata_summary${queries}`
    );
    try {
      const response = await axios.get(url);
      dispatch(setClusterMetadataSummary(response.data));
    } catch (error) {
      console.log(error);
    }
    dispatch(clusterMetadataSummaryLoading(false));
  };
};

export const fetchNavigationCluster = (
  datasetId: string,
  clusterId: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    dispatch(setNavigationClusterLoader(true));
    dispatch(setCardslistLoading(true));
    dispatch(setNavigationCluster(null));
    const state = getState().singleDataset;
    const vertexID = state.vertexID;
    let vertexDataPromise;
    if (vertexID) {
      vertexDataPromise = dispatch(fetchVertexData(datasetId) as any);
    }

    const basePath = `/explore/${datasetId}/similarity_cluster/${clusterId}`;
    const queries = getCurrentFilterQueries(getState());
    const path = `${basePath}${queries}`;
    try {
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
      const similarityClusterPromise = axios.get(url);
      Promise.all([vertexDataPromise, similarityClusterPromise]).then(
        (values) => {
          const similarityResponse = values[1];
          dispatch(setNavigationCluster(similarityResponse.data));
          dispatch(setNavigationClusterLoader(false));
          dispatch(setCardslistLoading(false));
        }
      );
    } catch (error) {
      console.log(error);
      dispatch(setFetchError(true));
      dispatch(setNavigationClusterLoader(false));
      dispatch(setCardslistLoading(false));
      return;
    }

    // Fetch cluster metadata (right-pane) in a non-blocking async request
    // if filterConfig cluster_metadata_summary is enabled, fetch cluster metadata summary
    const isClusterMetadataSummaryEnabled = getIsClusterMetadataSummaryEnabled(
      getState()
    );

    if (isClusterMetadataSummaryEnabled) {
      dispatch(fetchClusterMetadataSummary(datasetId, clusterId) as any);
    }
  };
};

export const fetchVertexData = (
  datasetId: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const state = getState().singleDataset;
    const vertexID = state.vertexID;
    if (!vertexID) {
      return;
    }
    const basePath = `/explore/${datasetId}/media/${vertexID}/vertex`;
    const queries = getVertexQuery(getState()) || "";
    const path = `${basePath}${queries}`;
    const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
    const response = await axios.get(url);
    dispatch(setSimilarityVertex(response.data));
    return response;
  };
};

export const fetchSimilarityData = (
  datasetId: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    if (cancelExploreToken) {
      cancelExploreToken.cancel(); // Cancel the previous request if it's still pending
    }
    dispatch(setClusterMetadataSummary(null));
    dispatch(setSimilarityData(null));
    const state = getState().singleDataset;
    const vertexID = state.vertexID;
    let vertexDataPromise;
    if (vertexID) {
      vertexDataPromise = dispatch(fetchVertexData(datasetId) as any);
    }

    const basePath = `/explore/${datasetId}`;
    const queries = getCurrentFilterQueries(getState());
    const path = `${basePath}${queries}`;
    try {
      dispatch(setCardslistLoading(true));
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
      cancelExploreToken = axios.CancelToken.source();
      const similarityDataPromise = axios.get(url, {
        cancelToken: cancelExploreToken.token,
      });
      Promise.all([vertexDataPromise, similarityDataPromise]).then((values) => {
        const similarityResponse = values[1];
        dispatch(setSimilarityData(similarityResponse.data.clusters));
        dispatch(setCardslistLoading(false));
        //tracking the result for textual search------------
        const selectedEntities = state.entityTypeFilter
          .filter((entity: FilterOption) => entity.selected)
          .map((entity: FilterOption) => entity.text);

        let entityValue;
        if (selectedEntities.length > 0) {
          entityValue = selectedEntities.join(",");
        } else {
          entityValue = "Images";
        }

        if (!!state.searchText && similarityResponse.data?.clusters?.length) {
          amplitudeTrack(
            AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__RETURNED_RESULTS,
            { [AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE]: entityValue }
          );
        } else if (
          !!state.searchText &&
          !similarityResponse.data?.clusters?.length
        ) {
          amplitudeTrack(
            AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__RETURNED_NO_RESULT,
            { [AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE]: entityValue }
          );
        }
        //--------------------------------------------------------End
      });
    } catch (error: any) {
      console.log(error);
      dispatch(setFetchError(true));
      dispatch(setCardslistLoading(false));
    }

    // Fetch exploration stats in a non-blocking async request
    if (
      state.filterConfig?.find(
        (feature: any) => feature.feature_key === "exploration_stats"
      )?.feature_behavior === "SHOW"
    ) {
      if (!!vertexID === false) {
        try {
          const statsPath = path.replace("/explore", "/exploration_stats");
          const statsUrl = composeApiURL(
            process.env.REACT_APP_API_ENDPOINT!,
            statsPath
          );
          const response = await axios.get(statsUrl);
          dispatch(setExplorationStats(response.data));
        } catch (error) {
          console.log(error);
        }
      }
    }

    // Fetch metadata for dataset (right-pane) in a non-blocking async request
    // if filterConfig cluster_metadata_summary is enabled, fetch cluster metadata summary
    //change condition to check isFeatureEnabled when this needs to be gated
    dispatch(fetchDataMetadataSummary(datasetId) as any);
  };
};

export const searchImageFileUploaded = (
  datasetId: any,
  file: File,
  croppedFile?: File,
  boundingBoxValues?: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const entitityTypeFilter = [...getState().singleDataset.entityTypeFilter];
    let selectedEntities = entitityTypeFilter.filter(
      (singleEntity: FilterOption) => singleEntity.selected
    );
    const filterConfig = [...getState().singleDataset.filterConfig] || [];

    const entityFilterConfigIndex = filterConfig.findIndex((singleKey: any) => {
      return singleKey.feature_key === Feature.ENTITY_TYPE_FILTER;
    });
    if (entityFilterConfigIndex) {
      let behavior = filterConfig[entityFilterConfigIndex].feature_behavior;
      let isToggle = !!behavior.ANY && behavior.ANY === "TOGGLE";

      if (isToggle && selectedEntities.length < 1) {
        //add image entity to selected
        selectedEntities = ["IMAGES"];
      } else {
        selectedEntities = [
          ...selectedEntities.map((entity: FilterOption) => {
            if (entity.text === "Images") return "IMAGES";
            else return "OBJECTS";
          }),
        ];
      }
    }

    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    const entityQueryText =
      selectedEntities.length > 0 ? selectedEntities.join(",").trim() : "";

    let queries = "";

    // Check if entityQueryText is not empty
    if (entityQueryText) queries = `?entity_type=${entityQueryText}`;
    if (boundingBoxValues) {
      // handle '&'and '?' prefix
      queries += `${queries ? "&" : "?"}bounding_box=${boundingBoxValues}`;
    }

    try {
      const path = `/dataset/${datasetId}/search-image-similarity${queries}`;
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
      const response = await axios.post(url, bodyFormData);
      return response.data.anchor_media_id;
    } catch (error) {
      console.log(error);
      dispatch(setCardslistLoading(false));
      return Promise.reject(error);
    }
  };
};

function createEntityParams(entityValues: FilterOption[], filterConfig: any) {
  let valuesArray: any[] = [];
  let entityTypeBehavior = null;
  let noSelection = entityValues.every(
    (value: any) => value.selected === false
  );

  let entityTypeFilter = filterConfig?.find(
    (filter: any) => filter.feature_key === Feature.ENTITY_TYPE_FILTER
  );
  entityTypeBehavior = entityTypeFilter?.feature_behavior["ANY"];

  if (noSelection) {
    if (entityTypeBehavior === "TOGGLE") {
      valuesArray = [entityValues[0].text.toUpperCase()];
    } else {
      valuesArray = entityValues.map((value: any) => value.text.toUpperCase());
    }
  } else {
    let selectedEntities = entityValues.filter(
      (value: any) => value.selected === true
    );
    valuesArray = selectedEntities.map((value: any) =>
      value.text.toUpperCase()
    );
  }
  let valueText = valuesArray.join(",");

  return `?entity_type=${valueText}`;
}

export const fetchDatasetLabels = (
  datasetId: any,
  clusterId?: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const state = { ...getState().singleDataset };
    const entityTypeValues = [...state.entityTypeFilter];
    const isObjectEntitySelected = entityTypeValues[1].selected;
    let paramValue = "";
    if (entityTypeValues) {
      paramValue = createEntityParams(entityTypeValues, state.filterConfig);
    }

    try {
      dispatch(setLabelLoading(true));
      const selectedLabels = state.labels.filter(
        (label: any) => label.selected
      );
      dispatch(setDatasetLabels(selectedLabels));
      let path: any;
      if (clusterId) {
        path = `/explore/${datasetId}/cluster/${clusterId}/labels${paramValue}`;
      } else {
        path = `/explore/${datasetId}/labels${paramValue}`;
      }

      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);

      const response = await axios.get(url);
      let labels = response.data.labels || [];

      if (!!labels && labels.length > 0) {
        const indexOfUnlabeled = labels.findIndex(
          (label: string) => "unlabeled" === label.toLowerCase()
        );
        if (indexOfUnlabeled !== -1) {
          const unlabeledLabel = labels.splice(indexOfUnlabeled, 1)[0];
          if (!isObjectEntitySelected) {
            labels.unshift(unlabeledLabel);
          }
        }

        const selectedLabelText = selectedLabels.map(
          (label: any) => label.text
        );
        const datasetLabelsWithUnLabeledOnTop = labels.map(
          (label: any, index: number) => {
            return {
              id: index,
              text: label,
              selected: selectedLabelText.includes(label),
            };
          }
        );

        dispatch(setDatasetLabels(datasetLabelsWithUnLabeledOnTop));
      }
      dispatch(setLabelLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLabelLoading(false));
    }
  };
};

export const clearFilters =
  (): ThunkAction<void, State, unknown, any> =>
  async (dispatch: Dispatch, getState) => {
    const singleDatasetState = { ...getState().singleDataset };

    const clearOptions = (options: FilterOption[]) =>
      options.map((option) => ({ ...option, selected: false }));

    const clearAndDispatch = (
      action: (options: FilterOption[]) => void,
      options: FilterOption[]
    ) => {
      dispatch(action(clearOptions(options)) as any);
    };

    clearAndDispatch(setUserTags, singleDatasetState.userTags);
    clearAndDispatch(setDatasetLabels, singleDatasetState.labels);
    clearAndDispatch(setDatasetIssueTypes, singleDatasetState.issueTypes);

    dispatch(setSearchText(""));
    dispatch(setTimeRange(null));
    dispatch(setFilterFolder(null));
    dispatch(setFileNameSearchText(""));
    dispatch(setNavigationCluster(null));
    dispatch(setDataPageNumber(1));
    dispatch(setClusterPageNumber(1));
    dispatch(setVertexID(null));
    dispatch(setVertexType(null));
    dispatch(setVertexContext(null));
  };

export const fetchFilterConfig = (
  datasetId: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    const basePath = "/user_config";
    try {
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, basePath);
      const response = await axios.get(url, {
        params: { dataset_id: datasetId },
      });
      const transformedData = transformFilterData(response.data?.features);

      dispatch(setFilterConfig(transformedData));
      return { filterConfig: transformedData };
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchTreeFolder = (
  datasetId: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    const basePath = `/explore/${datasetId}/file_tree`;
    try {
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, basePath);
      const response = await axios.get(url);
      dispatch(setTreeFolder(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const clearExportData = (
  datasetID: string,
  showTooltip?: boolean
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const exportData = { ...getState().singleDataset.exportData };
      let newExportData = { ...exportData };

      if (datasetID in exportData) {
        delete newExportData[datasetID];

        if (showTooltip) {
          dispatch(
            setTempExportData({
              clusterArray: exportData,
              clearSelection: true,
              type: "clusters",
              calledFrom: "card",
              isAdded: false,
            })
          );
        }

        dispatch(setExportData(newExportData));
        localStorage.setItem("cart__data", JSON.stringify(newExportData));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const clearSelectedMediaIDsFromExportData = (
  datasetID: string,
  showTooltip?: boolean
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const exportData = { ...getState().singleDataset.exportData };
    const navigationCluster = { ...getState().singleDataset.navigationCluster };
    const newExportData = { ...exportData };
    try {
      if (datasetID in exportData) {
        const exportDataArray =
          [...exportData[datasetID]?.exportDataArray] || [];
        const tagsArray = exportData[datasetID]?.tags || [];
        let newExportDataArray: any[] = [];

        newExportDataArray = exportDataArray.filter((singleData: any) => {
          const existingMediaID = navigationCluster.previews.find(
            (preview: any) => preview.media_id === singleData.media_id
          );
          if (existingMediaID) return false;
          else return true;
        });

        const newEntry = {
          [datasetID]: { exportDataArray: newExportDataArray, tags: tagsArray },
        };

        newExportData[datasetID] = {
          ...newExportData[datasetID],
          ...newEntry[datasetID],
        };
        if (showTooltip) {
          // Calculate removedPreviews
          const removedPreviews = navigationCluster.previews.filter(
            (preview: any) => {
              return exportDataArray.some(
                (singleData: any) => preview.media_id === singleData.media_id
              );
            }
          );

          const { type, n_images, n_objects } = navigationCluster;
          const updatedImagesCount =
            type === "IMAGES" ? removedPreviews.length : n_images;
          const updatedObjectsCount =
            type === "OBJECTS" ? removedPreviews.length : n_objects;

          const temporaryExportData = {
            ...navigationCluster,
            n_images: updatedImagesCount,
            n_objects: updatedObjectsCount,
            exportType: "cluster",
            calledFrom: "card",
            isAdded: false,
          };

          dispatch(setTempExportData(temporaryExportData));
        }

        dispatch(setExportData(newExportData));
        localStorage.setItem("cart__data", JSON.stringify(newExportData));
      }
    } catch (error) {
      console.error("Error clearing selections", error);
    }
  };
};

export const setExportData = (data: any) => {
  return { type: SET_EXPORT_DATA, data };
};

export const setTempExportData = (data: any) => {
  return { type: SET_TEMP_EXPORT_DATA, data };
};

export const setExportDataTimeout = (data: any) => {
  return { type: SET_EXPORT_DATA_TIMEOUT_ID, data };
};

export const setClusterMetadataSummary = (data: any) => {
  return { type: SET_CLUSTER_METADATA_SUMMARY, data };
};

export const setDataMetadataSummary = (data: any) => {
  return { type: SET_DATA_METADATA_SUMMARY, data };
};

export const clusterMetadataSummaryLoading = (data: any) => {
  return { type: SET_CLUSTER_METADATA_SUMMARY_LOADING, data };
};

export const dataMetadataSummaryLoading = (data: boolean) => {
  return { type: SET_DATA_METADATA_SUMMARY_LOADING, data };
};

const setUserTagLoading = (data: boolean) => {
  return { type: IS_USER_TAG_LOADING, data };
};

const trackEntitySlectedAction = (data: any) => {
  let target: EventTarget = "Image";
  let clusterSize: number = 0;
  const isSingleEntity =
    (data.exportType === "cluster" && data.previews?.length === 1) ||
    data.exportType === "media"
      ? true
      : false;

  if (isSingleEntity) {
    const singlePreview =
      data.exportType === "cluster" ? data.previews[0] : data;
    const isVideo = !!singlePreview?.video_uri;
    if (isVideo) target = "Video";
    else if (singlePreview.type === "IMAGE") target = "Image";
    else if (singlePreview.type === "OBJECT") target = "Object";
  } else if (data.type === "IMAGES") {
    target = "Cluster";
    clusterSize = data.n_images;
  } else if (data.type === "OBJECTS") {
    target = "Cluster";
    clusterSize = data.n_objects;
  }

  let properties: any = {
    [AMP_DATASET_EXPLORATION_PROPERTY__TARGET]: target,
  };
  if (!!clusterSize) {
    properties = {
      ...properties,
      [AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE]: clusterSize,
    };
  }

  amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__ENTITY__SELECTED, properties);
};

export const updateExportData = (
  datasetID: string,
  selectedExportData: any,
  calledFrom: "cart" | "card",
  type: "cluster" | "media",
  cluster_filters: object
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    try {
      selectedExportData = { ...selectedExportData, cluster_filters };
      const existingTimeoutID = getState().singleDataset.exportDataTimeoutID;
      if (existingTimeoutID) {
        clearTimeout(existingTimeoutID);
      }

      const exportData = { ...getState().singleDataset.exportData };
      const newExportData = { ...exportData };

      if (datasetID in exportData) {
        const exportDataArray = exportData[datasetID]?.exportDataArray || [];
        const tagsArray = exportData[datasetID]?.tags || [];
        let newExportDataArray: any[] = [];

        if (type === "cluster") {
          const existingCluster = exportDataArray.find(
            (data: any) => data.cluster_id === selectedExportData.cluster_id
          );
          if (existingCluster) {
            newExportDataArray = exportDataArray.filter(
              (data: any) => data.cluster_id !== selectedExportData.cluster_id
            );

            dispatch(
              setTempExportData({
                ...selectedExportData,
                exportType: type,
                calledFrom,
                isAdded: false,
              })
            );
          } else {
            newExportDataArray = [
              ...exportDataArray,
              { ...selectedExportData, exportType: type },
            ];
            dispatch(
              setTempExportData({
                ...selectedExportData,
                exportType: type,
                calledFrom,
                isAdded: true,
              })
            );
            trackEntitySlectedAction({
              ...selectedExportData,
              exportType: type,
            });
          }
        } else if (type === "media") {
          const existingParentCluster = exportDataArray.find(
            (data: any) =>
              data.cluster_id === selectedExportData.cluster_id &&
              data.exportType === "cluster"
          );
          // if the cluster was selected on data page
          if (existingParentCluster) {
            let excludeMediaIDs: string[] =
              existingParentCluster.excludeMediaIDs
                ? [...existingParentCluster.excludeMediaIDs]
                : [];
            const indexOfMediaID = excludeMediaIDs.indexOf(
              selectedExportData.media_id
            );
            // media id was selected to be removed
            if (indexOfMediaID > -1) {
              excludeMediaIDs.splice(indexOfMediaID, 1);
            }
            // media id was unselected to be removed
            else {
              excludeMediaIDs = [
                ...excludeMediaIDs,
                selectedExportData.media_id,
              ];
            }

            // update the export data array with new cluster
            newExportDataArray = exportDataArray.map((data: any) => {
              if (data.cluster_id !== existingParentCluster.cluster_id) {
                return data;
              } else {
                return { ...existingParentCluster, excludeMediaIDs };
              }
            });

            dispatch(
              setTempExportData({
                ...selectedExportData,
                exportType: type,
                calledFrom,
                isAdded: indexOfMediaID > -1 ? true : false,
              })
            );
            trackEntitySlectedAction({
              ...selectedExportData,
              exportType: type,
            });
          }
          //handle the case where only a single media is selected without its parent being selected
          else {
            const existingMedia = exportDataArray.find(
              (data: any) => data.media_id === selectedExportData.media_id
            );
            if (existingMedia) {
              newExportDataArray = exportDataArray.filter(
                (data: any) => data.media_id !== selectedExportData.media_id
              );

              dispatch(
                setTempExportData({
                  ...selectedExportData,
                  exportType: type,
                  calledFrom,
                  isAdded: false,
                })
              );
            } else {
              newExportDataArray = [
                ...exportDataArray,
                { ...selectedExportData, exportType: type },
              ];
              dispatch(
                setTempExportData({
                  ...selectedExportData,
                  exportType: type,
                  calledFrom,
                  isAdded: true,
                })
              );
              trackEntitySlectedAction({
                ...selectedExportData,
                exportType: type,
              });
            }
          }
        }

        const newEntry = {
          [datasetID]: { exportDataArray: newExportDataArray, tags: tagsArray },
        };

        newExportData[datasetID] = {
          ...newExportData[datasetID],
          ...newEntry[datasetID],
        };
      } else {
        const newEntry = {
          [datasetID]: {
            exportDataArray: [{ ...selectedExportData, exportType: type }],
            tags: [],
          },
        };
        dispatch(
          setTempExportData({
            ...selectedExportData,
            exportType: type,
            calledFrom,
            isAdded: true,
          })
        );

        trackEntitySlectedAction({ ...selectedExportData, exportType: type });
        newExportData[datasetID] = {
          ...newExportData[datasetID],
          ...newEntry[datasetID],
        };
      }

      dispatch(setExportData(newExportData));

      const newTimeout = setTimeout(
        () => dispatch(setTempExportData(null)),
        3000
      );
      dispatch(setExportDataTimeout(newTimeout));

      localStorage.setItem("cart__data", JSON.stringify(newExportData));
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateExportTags = (
  datasetID: string,
  tag: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const exportData = { ...getState().singleDataset.exportData };
      const exportDataArray = exportData[datasetID]?.exportDataArray || [];
      const newExportData = { ...exportData };

      if (datasetID in exportData) {
        const tagsArray = exportData[datasetID]?.tags || [];

        const exists = tagsArray.some(
          (singleTag: any) => singleTag.id === tag.id
        );

        const newTagsArray = exists
          ? tagsArray
          : [...tagsArray, { ...tag, selected: true }];

        const newEntry = {
          [datasetID]: { tags: newTagsArray, exportDataArray: exportDataArray },
        };

        newExportData[datasetID] = {
          ...newExportData[datasetID],
          ...newEntry[datasetID],
        };
      } else {
        const newEntry = {
          [datasetID]: { tags: [tag], exportDataArray: exportDataArray },
        };

        newExportData[datasetID] = {
          ...newExportData[datasetID],
          ...newEntry[datasetID],
        };
      }

      dispatch(setExportData(newExportData));
      localStorage.setItem("cart__data", JSON.stringify(newExportData));
    } catch (error) {
      console.error(error);
    }
  };
};

export const addAllForExport = (
  datasetID: string,
  clusters: any[],
  allSelectedFilters: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const exportData = { ...getState().singleDataset.exportData };
      let newExportData = { ...exportData };
      let selectedClusterArray = [];

      if (datasetID in exportData) {
        const tagsArray = exportData[datasetID].tags;
        const selectedClusters = clusters.map((singleData: any) => {
          return {
            ...singleData,
            exportType: "cluster",
            cluster_filters: allSelectedFilters,
          };
        });
        selectedClusterArray = selectedClusters;
        const newEntry = {
          [datasetID]: { tags: tagsArray, exportDataArray: selectedClusters },
        };

        newExportData[datasetID] = {
          ...newExportData[datasetID],
          ...newEntry[datasetID],
        };
      } else {
        const tagsArray: any[] = [];
        const selectedClusters = clusters.map((singleData: any) => {
          return {
            ...singleData,
            exportType: "cluster",
            cluster_filters: allSelectedFilters,
          };
        });
        selectedClusterArray = selectedClusters;
        const newEntry = {
          [datasetID]: { tags: tagsArray, exportDataArray: selectedClusters },
        };

        newExportData = {
          ...newExportData,
          ...newEntry,
        };
      }
      dispatch(
        setTempExportData({
          clusterArray: selectedClusterArray,
          type: "clusters",
          calledFrom: "card",
          isAdded: true,
        })
      );
      dispatch(setExportData(newExportData));
      localStorage.setItem("cart__data", JSON.stringify(newExportData));
    } catch (error) {
      console.error(error);
    }
  };
};

export const addAllMediaIDsFromClusterPage = (
  datasetID: string,
  allSelectedFilters: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const exportData = { ...getState().singleDataset.exportData };
    const navigationCluster = { ...getState().singleDataset.navigationCluster };
    const newExportData = { ...exportData };
    try {
      if (datasetID in exportData) {
        const exportDataArray =
          [...exportData[datasetID]?.exportDataArray] || [];
        const tagsArray = exportData[datasetID]?.tags || [];
        let newExportDataArray: any[] = [];
        const mediaArray = navigationCluster.previews.map(
          (singlePreview: any) => {
            return {
              ...singlePreview,
              exportType: "media",
              cluster_id: navigationCluster.cluster_id,
              cluster_filters: allSelectedFilters,
            };
          }
        );
        //add all single entities as media
        newExportDataArray = [...exportDataArray, ...mediaArray];

        const newEntry = {
          [datasetID]: { exportDataArray: newExportDataArray, tags: tagsArray },
        };

        newExportData[datasetID] = {
          ...newExportData[datasetID],
          ...newEntry[datasetID],
        };

        dispatch(
          setTempExportData({
            ...navigationCluster,
            exportType: "cluster",
            calledFrom: "card",
            isAdded: true,
          })
        );

        dispatch(setExportData(newExportData));
        localStorage.setItem("cart__data", JSON.stringify(newExportData));
      }
    } catch (error) {
      console.error("Error clearing selections", error);
    }
  };
};

export const submitSelectedCartTags = (
  datasetID: string,
  requestBody: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const clusterID = getNavigationClusterID(getState());
        const path = `/dataset/${datasetID}/tags`;
        const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
        axios({
          url,
          method: "post",
          data: { tags_assignment: requestBody },
        })
          .then((response) => {
            const arr: any = Object.values(requestBody)[0];
            const size = arr?.length;
            amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__USER_TAG__ASSIGNED, {
              [AMP_DATASET_EXPLORATION_PROPERTY__TARGET]: "Mixed",
              [AMP_DATASET_EXPLORATION_PROPERTY__CONTEXT]: "Selected Items",
              [AMP_IMAGE_COUNT]: size,
            });

            if (clusterID) {
              dispatch(
                fetchClusterMetadataSummary(datasetID, clusterID) as any
              );
            } else {
              dispatch(fetchDataMetadataSummary(datasetID) as any);
            }

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };
};

const fetchExportStatus = (
  taskID: string,
  datasetID: string
): ThunkAction<Promise<string>, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    const url = composeApiURL(
      process.env.REACT_APP_API_ENDPOINT!,
      `/dataset/${datasetID}/export_status?export_task_id=${taskID}`
    );

    const checkStatus = async (): Promise<any> => {
      try {
        const response = await axios.get(url);
        const responseData = response.data;

        if (!responseData) {
          throw new Error(FALLBACK_EXPORT_ERROR_TEXT);
        } else if (
          responseData.status === "REJECTED" ||
          responseData.status === "FAILED"
        ) {
          dispatch(setExportProgress(0));
          throw new Error(
            responseData.result_message || FALLBACK_EXPORT_ERROR_TEXT
          );
        } else if (responseData.download_uri) {
          return responseData.download_uri;
        } else {
          dispatch(setExportProgress(responseData.progress));
          await new Promise((resolve) => setTimeout(resolve, 5000));
          return await checkStatus();
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    return await checkStatus();
  };
};

export const submitExportRequest = (
  fileName: string,
  exportType: ExportFormatOptions,
  exportSource: FilesToExport,
  includeRawData: boolean = false
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const state = getState();
      const datasetID = state.singleDataset?.stats?.dataset?.id;
      let params =
        "?file_name=" +
        fileName +
        "&export_type=" +
        exportType +
        "&include_images=" +
        includeRawData;
      let url = "";
      if (exportSource === "SELECTED") {
        const exportData = getExportData(state);
        const clustersIds = exportData
          .filter((data: any) => data.exportType === "cluster")
          .map((data: any) => data.cluster_id);
        if (clustersIds.length > 0) {
          params = params + "&cluster_ids=" + JSON.stringify(clustersIds);
        }
        const mediasIds = exportData
          .filter((data: any) => data.exportType === "media")
          .map((data: any) => data.media_id);
        if (mediasIds.length > 0) {
          params = params + "&media_ids=" + JSON.stringify(mediasIds);
        }
        params =
          params +
          "&" +
          getCurrentFilterQueries(getState(), false).split("?")[1];
        url = composeApiURL(
          process.env.REACT_APP_API_ENDPOINT!,
          `/dataset/${datasetID}/export_entities_async${params}`
        );
      } else {
        if (exportSource === "FILTERED") {
          params =
            params +
            "&" +
            getCurrentFilterQueries(getState(), false).split("?")[1];
        }
        url = composeApiURL(
          process.env.REACT_APP_API_ENDPOINT!,
          `/dataset/${datasetID}/export_context_async${params}`
        );
      }
      const response = await axios({
        method: "GET",
        url: url,
      });
      const responseData = response.data;

      if (!responseData) {
        throw new Error(FALLBACK_EXPORT_ERROR_TEXT);
      } else if (
        responseData.status === "REJECTED" ||
        responseData.status === "FAILED"
      ) {
        throw new Error(
          responseData.result_message || FALLBACK_EXPORT_ERROR_TEXT
        );
      } else if (responseData.id) {
        try {
          const downloadUri = await dispatch(
            fetchExportStatus(responseData.id, datasetID) as any
          );

          const link = document.createElement("a");
          link.href = downloadUri;
          link.setAttribute("download", `${fileName}.zip`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          return downloadUri;
        } catch (error) {
          throw error;
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

export const setDataPageNumber = (data: number | null) => {
  return { type: SET_DATA_PAGE_NUMBER, data };
};

export const setClusterPageNumber = (data: number | null) => {
  return { type: SET_CLUSTER_PAGE_NUMBER, data };
};

export const setMetadataForData = (total: number, size: number) => {
  let data = { total, size };
  return { type: SET_PAGINATION_METADATA_FOR_DATA, data };
};

export const setMetadataForCluster = (total: number, size: number) => {
  let data = { total, size };
  return { type: SET_PAGINATION_METADATA_FOR_CLUSTER, data };
};

export const fetchSimilarityThresholdsInfo = (
  datasetId: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const path = `/explore/${datasetId}/exploration_metadata_get_thresholds`;
    try {
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
      const response = await axios.get(url);
      const thresholds = response.data.similarity_thresholds;
      let threshold: any;
      if (thresholds && thresholds.length > 0) {
        dispatch(setSimilarityThresholds(response.data));

        //give the first value to threshold if array length is less than or equal to 2
        if (thresholds.length <= 2) threshold = thresholds[0];
        else threshold = thresholds[Math.floor(thresholds.length / 2)];

        dispatch(setSimilarityThreshold({ threshold }));
        return { threshold };
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchDataPagePagination = (
  datasetID: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const basePath = `/explore/${datasetID}/exploration_metadata`;
    const queries = getCurrentFilterQueries(state);
    const path = `${basePath}${queries}`;
    try {
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
      const response = await axios.get(url);
      const totalItems = response.data.cluster_count;
      const pageSize = response.data.page_size;
      dispatch(setMetadataForData(totalItems, pageSize));
    } catch (error) {
      console.log(error);
      dispatch(setFetchError(true));
      dispatch(setCardslistLoading(false));
    }
  };
};

export const fetchClusterPagePagination = (
  datasetID: string,
  clusterID: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const basePath = `/explore/${datasetID}/similarity_cluster/${clusterID}/cluster_metadata`;
    const queries = getCurrentFilterQueries(state);
    const path = `${basePath}${queries}`;
    try {
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
      const response = await axios.get(url);
      const totalItems = response.data.cluster_size;
      const pageSize = response.data.page_size;
      dispatch(setMetadataForCluster(totalItems, pageSize));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchDatasetTags = (
  datasetId: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const path = `/dataset/${datasetId}/tags`;
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);

      const response = await axios.get(url);
      if (!!response.data && response.data.length > 0) {
        const uniqueTagsArray = response.data
          .filter(
            (tag: any, index: number, self: any[]) =>
              index === self.findIndex((t) => t.id === tag.id)
          )
          .map((tag: any) => ({ id: tag.id, text: tag.name }));
        dispatch(setDatasetTags(uniqueTagsArray));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchUserTags = (
  datasetId: any,
  clusterId?: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const state = { ...getState().singleDataset };
    const selectedUserTags = state.userTags.filter(
      (userTag: any) => userTag.selected
    );
    dispatch(setUserTags(selectedUserTags));
    dispatch(setUserTagLoading(true));

    const entityTypeValues = [...state.entityTypeFilter];
    let paramValue = "";
    if (entityTypeValues) {
      paramValue = createEntityParams(entityTypeValues, state.filterConfig);
    }

    try {
      let path: any;
      if (clusterId) {
        path = `/dataset/${datasetId}/cluster/${clusterId}/tags${paramValue}`;
      } else {
        path = `/dataset/${datasetId}/tags${paramValue}`;
      }
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);

      const response = await axios.get(url);
      if (!!response.data && response.data.length > 0) {
        const selectedUserTagsText = selectedUserTags.map(
          (userTag: any) => userTag.text
        );
        const newUserTags = response.data?.map((tag: any) => {
          return {
            id: tag.id,
            text: tag.name,
            selected: selectedUserTagsText.includes(tag.name),
          };
        });
        const newUserTagsWithUntagged = [
          { id: "untagged", text: "untagged", selected: false },
          ...newUserTags.filter(
            (tag: any) => tag.text.toLowerCase() !== "untagged"
          ),
        ];

        dispatch(updateUserTagsWithNewTags(newUserTagsWithUntagged));
        dispatch(setUserTagLoading(false));
        if (state.datasetTags.length === 0) {
          if (
            !clusterId &&
            ["IMAGES", "OBJECTS"].every((substring) =>
              paramValue.includes(substring)
            )
          ) {
            const uniqueTagsArray = newUserTags
              .filter(
                (tag: any, index: number, self: any[]) =>
                  index === self.findIndex((t) => t.id === tag.id)
              )
              .map((tag: any) => ({ id: tag.id, text: tag.text }));
            dispatch(setDatasetTags(uniqueTagsArray));
          } else {
            dispatch(fetchDatasetTags(datasetId) as any);
          }
        }
      } else {
        const untaggedTag = {
          id: "untagged",
          text: "untagged",
          selected: false,
        };

        dispatch(updateUserTagsWithNewTags([untaggedTag]));
      }
      dispatch(setUserTagLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setUserTagLoading(false));
      dispatch(setFetchError(true));
      dispatch(setCardslistLoading(false));
    }
  };
};

export const updateUserTags = (
  newTag: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, `/tags`);
      const response = await axios.post(url, { tag_name: newTag });
      let newTagID = response.data.tag_id;
      dispatch(
        updateUserTagsWithNewTags([
          { id: newTagID, text: newTag, selected: false },
        ])
      );
      if (newTagID) {
        dispatch(addImageTag(newTagID, newTag) as any);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const addNewTagFromCart = (
  datasetID: string,
  tagName: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, `/tags`);
      const response = await axios.post(url, { tag_name: tagName });
      const newTagID = response.data.tag_id;

      if (newTagID) {
        const newTag = { id: newTagID, text: tagName, selected: false };
        await Promise.all([
          dispatch(updateUserTagsWithNewTags([newTag])),
          dispatch(updateExportTags(datasetID, newTag) as any),
          dispatch(
            setDatasetTags([...getState().singleDataset.datasetTags, newTag])
          ),
        ]);

        return newTagID;
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const resetStore = () => {
  return { type: RESET_STORE };
};

export const setNavigationContext = (
  currentPage: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    let initialContext = [...getState().singleDataset.navigationContext];
    if (initialContext.includes(currentPage)) {
    } else {
      let checkPage;
      switch (currentPage) {
        case DATA_PAGE:
          checkPage = DATA_PAGE;
          break;
        case CLUSTER_DETAILS_PAGE:
          checkPage = CLUSTER_DETAILS_PAGE;
          break;
        case IMAGE_DETAILS_PAGE:
          checkPage = IMAGE_DETAILS_PAGE;
          break;
        default:
          checkPage = false;
      }
      if (checkPage) {
        const newContext = [...initialContext, checkPage];
        dispatch({ type: SET_NAVIGATION_CONTEXT, data: newContext });
      }
    }
  };
};

export const setIsImageUploading = (data: boolean) => {
  return { type: SET_IS_IMAGE_UPLOADING, data };
};

export const setHoveredFileState = (
  data: {
    path: string;
    top: number;
    right: number;
  } | null
) => {
  return { type: HOVERED_FILE_STATE, data };
};

export const setFetchError = (data: boolean) => {
  return { type: SET_FETCH_ERROR, data };
};

export const setAutocompleteSuggestions = (data: string[]) => {
  return { type: SET_AUTOCOMPLETE_SUGGESTIONS, data };
};

export const setAutocompleteLoading = (data: boolean) => {
  return { type: SET_AUTOCOMPLETE_LOADING, data };
};

export const setAutocompleteNoResultsFlag = (data: boolean) => {
  return { type: SET_AUTOCOMPLETE_NO_RESULTS_FLAG, data };
};

export const fetchAutocompleteSuggestions = (
  datasetId: string,
  query: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const encodedQuery = encodeURIComponent(query);
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${datasetId}/autocomplete?query=${encodedQuery}`
      );

      const response = await axios.get(url);
      const suggestions = response.data.results || [];

      if (suggestions.length > 0) {
        dispatch(setAutocompleteSuggestions(suggestions));
        dispatch(setAutocompleteNoResultsFlag(false));
      } else {
        dispatch(setAutocompleteSuggestions([]));
        dispatch(setAutocompleteNoResultsFlag(true));
      }
      dispatch(setAutocompleteLoading(false));
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      dispatch(setAutocompleteSuggestions([]));
      dispatch(setAutocompleteLoading(false));
      dispatch(setAutocompleteNoResultsFlag(false));
    }
  };
};

export const setRecentSearches = (data: any) => {
  localStorage.setItem("recent_searches", JSON.stringify(data));
  return { type: SET_RECENT_SEARCHES, data };
};

export const addToRecentSearches = (
  datasetId: string,
  text: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const recentSearches =
        { ...getState().singleDataset.recentSearches } || {};
      let newValue = {};

      if (Object.keys(recentSearches).length > 0) {
        if (datasetId in recentSearches) {
          const existingValues = [...recentSearches[datasetId]] || [];

          const existingIndex = existingValues.indexOf(text);
          if (existingIndex !== -1) existingValues.splice(existingIndex, 1);

          const newArr = [text, ...existingValues].slice(0, 5);
          newValue = { ...recentSearches, [datasetId]: newArr };
        } else {
          newValue = { ...recentSearches, [datasetId]: [text] };
        }
      } else {
        newValue = { [datasetId]: [text] };
      }

      dispatch(setRecentSearches(newValue));
    } catch (error) {
      console.error("Error occurred while adding to recent searches:", error);
    }
  };
};

export const removeFromRecentSearches = (
  datasetId: string,
  text: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const recentSearches =
        { ...getState().singleDataset.recentSearches } || {};
      let newValue = {};

      let existingValues = [...recentSearches[datasetId]];
      const existingIndex = existingValues.indexOf(text);
      if (existingIndex !== -1) existingValues.splice(existingIndex, 1);

      newValue = { ...recentSearches, [datasetId]: [...existingValues] };
      dispatch(setRecentSearches(newValue));
    } catch (error) {
      console.error("Error occurred while adding to recent searches:", error);
    }
  };
};

export const setVertexPreviewsCountContext = (data: number) => {
  return { type: SET_VERTEX_PREVIEWS_COUNT_CONTEXT, data };
};

export const setImagePageParamsContext = (data: {
  imageId: string;
  searchRegion: string;
}) => {
  return { type: SET_IMAGE_PAGE_PARAMS_CONTEXT, data };
};

export const clearImagePageParamsContext = () => {
  const data = { imageId: "", searchRegion: "" };
  return { type: SET_IMAGE_PAGE_PARAMS_CONTEXT, data };
};

export const setVertexContext = (data: URLSearchParams | null) => {
  return { type: SET_VERTEX_CONTEXT, data };
};

export const setAssignedTags = (data: FilterOption[]) => {
  return { type: SET_ASSIGNED_TAGS, data };
};

export const fetchAssignedTags = (
  datasetId: string,
  exportRequestData: any,
  assignedTags: FilterOption[]
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${datasetId}/tags/union`
      );

      const response = await axios.post(url, {
        tags_retrieval: exportRequestData,
      });
      const responseTags = response.data;

      if (responseTags.length > 0) {
        const structuredTags = responseTags.map(
          (tag: { id: string; name: string }) => {
            // Check if the fetched tag ID exists in the previously assigned tags
            const previouslyAssignedTag = assignedTags.find(
              (assignedTag: any) => assignedTag.id === tag.id
            );

            if (previouslyAssignedTag) {
              return { ...previouslyAssignedTag, text: tag.name };
            } else {
              return { id: tag.id, text: tag.name, selected: true };
            }
          }
        );

        dispatch(setAssignedTags(structuredTags));
      } else {
        dispatch(setAssignedTags([]));
      }
    } catch (error) {
      console.error("Error fetching assigned tags:", error);
    }
  };
};

export const sumbitRemoveTagsRequest = (
  datasetID: string,
  requestBody: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const path = `/dataset/${datasetID}/tags/remove`;
        const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
        axios
          .patch(url, { tags_removal: requestBody })
          .then((response) => resolve())
          .catch((error) => reject(error));
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };
};

export const submitShareInviteRequest = (
  datasetID: string,
  email: string
): ThunkAction<void, State, unknown, any> => {
  return async () => {
    try {
      const path = `/manage/${datasetID}/share`;
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
      const formData = new FormData();
      formData.append("grant_to_user_email", email);

      const response = await axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response;
      }
    }
  };
};

export const submitRevokeAccessRequest = (
  datasetID: string,
  userId: string
): ThunkAction<void, State, unknown, any> => {
  return async () => {
    try {
      const path = `/manage/${datasetID}/share/${userId}`;
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
      const response = await axios.delete(url);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response;
      }
    }
  };
};

export const fetchUsersWithAccess = (
  datasetID: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const path = `/manage/${datasetID}/share`;
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, path);
      const response = await axios.get(url);
      if (response.data && response.data.length > 0) {
        dispatch(setUsersWithAccess(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
