import { MouseEvent, RefObject } from "react";
import { ContactUsComponentType, DataSources } from "types";

import GoogleDriveIcon from "assets/icons/GoogleDriveIcon";
import PrivateS3Icon from "assets/icons/PrivateS3Icon";
import PublicS3Icon from "assets/icons/PublicS3Icon";
import DropboxIcon from "assets/icons/DropboxIcon";
import FolderIcon from "assets/icons/FolderIcon";
import ImageIcon from "assets/icons/imageIcon";

import styles from "./style.module.scss";

interface DropdownOption {
  id: DataSources;
  title: string;
  available: boolean;
  contactType?: ContactUsComponentType;
}

const SOURCES: DropdownOption[] = [
  {
    id: DataSources.ZIP,
    title: "ZIP file",
    available: true,
  },
  {
    id: DataSources.FOLDER,
    title: "Folder",
    available: true,
  },
  {
    id: DataSources.S3,
    title: "Public Amazon S3 URL",
    available: true,
  },
  {
    id: DataSources.PVT_S3_BUCKET,
    title: "Private Amazon S3 URL",
    available: false,
    contactType: "create_newdataset_private_s3_bucket",
  },
  {
    id: DataSources.G_DRIVE,
    title: "Google Drive",
    available: false,
    contactType: "create_newdataset_google_drive",
  },
  {
    id: DataSources.DROPBOX,
    title: "Dropbox",
    available: false,
    contactType: "create_newdataset_dropbox",
  },
];

const ConnectDropdown = ({
  dropdownRef,
  handleSelection,
  handleContactUsClick,
}: ConnectDropdownProps) => {
  const availableOptions = SOURCES.filter((source) => source.available);
  const additionalOptions = SOURCES.filter((source) => !source.available);

  const renderHeadIcon = (id: DataSources) => {
    switch (id) {
      case DataSources.ZIP:
        return (
          <div className={styles.imageIconContainer}>
            <ImageIcon />
          </div>
        );

      case DataSources.FOLDER:
        return <FolderIcon color="#fff" />;

      case DataSources.S3:
        return <PublicS3Icon />;

      case DataSources.G_DRIVE:
        return <GoogleDriveIcon color="#474e5f" />;

      case DataSources.PVT_S3_BUCKET:
        return <PrivateS3Icon color="#474e5f" />;

      case DataSources.DROPBOX:
        return <DropboxIcon color="#474e5f" />;
      default:
        return null;
    }
  };

  const renderRow = (source: DropdownOption, index: number) => {
    if (source.available) {
      return (
        <div
          className={styles.dropdownRow}
          onClick={() => handleSelection(source.id)}
          key={"available" + index}
        >
          <div className={styles.rowHeadIcon}>{renderHeadIcon(source.id)}</div>
          <div className={styles.rowTitle}>{source.title}</div>
        </div>
      );
    } else {
      return (
        <div className={styles.dropdownRow} key={"additional" + index}>
          <div className={styles.rowHeadIcon}>{renderHeadIcon(source.id)}</div>
          <div className={styles.rowTitle}>{source.title}</div>
          <div
            className={styles.contactUsButton}
            onClick={() => handleContactUsClick(source.contactType!)}
          >
            Contact Us
          </div>
        </div>
      );
    }
  };

  return (
    <div
      ref={dropdownRef}
      className={styles.dropdownContainer}
      onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      <div className={styles.availableOptionsContainer}>
        {availableOptions.map((source: DropdownOption, index: number) => {
          return renderRow(source, index);
        })}
      </div>
      <div className={styles.additionalOptionsContainer}>
        <div className={styles.additionalOptionsHeader}>Additional Sources</div>
        {additionalOptions.map((source: DropdownOption, index: number) => {
          return renderRow(source, index);
        })}
      </div>
    </div>
  );
};
export default ConnectDropdown;

interface ConnectDropdownProps {
  dropdownRef: RefObject<HTMLDivElement>;
  handleSelection: (dataSource: DataSources) => void;
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
  handleContactUsClick: (calledFrom: ContactUsComponentType) => void;
}
