const PREFIX = "CreateDataset";
export const SET_S3_FETCH_PROGRESS = `${PREFIX}_SET_S3_FETCH_PROGRESS`;
export const SET_PREVIEW_DATA = `${PREFIX}_SET_PREVIEW_DATA`;
export const SET_PREVIEW_DATA_SOURCE = `${PREFIX}_SET_PREVIEW_DATA_SOURCE`;
export const SET_NEW_DATASET = `${PREFIX}_SET_NEW_DATASET`;
export const SET_IS_LOADING = `${PREFIX}_SET_IS_LOADING`;
export const SET_ACTIVITY_LOGS = `${PREFIX}_SET_ACTIVITY_LOGS`;
export const RESET_STORE = `${PREFIX}_RESET_STORE`;
export const SET_IMAGE_ERROR_FILE_NAME = `${PREFIX}_SET_IMAGE_ERROR_FILE_NAME`;
export const SET_ACTIVITY_OFFSET = `${PREFIX}_SET_ACTIVITY_OFFSET`;
export const SET_IS_FATAL_ERROR = `${PREFIX}_SET_IS_FATAL_ERROR`;
export const SET_FILES_UPLOAD_TRANS_ID = `${PREFIX}_SET_FILES_UPLOAD_TRANS_ID`;
export const SET_LIMIT_EXCEEDED_DETAILS = `${PREFIX}_SET_LIMIT_EXCEEDED_DETAILS`;

export const SET_UPLOAD_STATUS = `${PREFIX}_SET_UPLOAD_STATUS`;
export const SET_CONNECT_STATUS = `${PREFIX}_SET_CONNECT_STATUS`;
export const SET_INDEXING_STATUS = `${PREFIX}_SET_INDEXING_STATUS`;
export const SET_ANNOTATION_STATUS = `${PREFIX}_SET_ANNOTATION_STATUS`;

export const SET_ARE_ANNOTATIONS_FETCHED = `${PREFIX}_SET_ARE_ANNOTATIONS_FETCHED`;
export const SET_STATUS_CALL_IN_PROGRESS = `${PREFIX}_SET_STATUS_CALL_IN_PROGRESS`;
export const SET_SHOW_DELETE_DATASET_MODAL = `${PREFIX}_SET_SHOW_DELETE_DATASET_MODAL`;
export const SET_SHOW_ACTIVITY_LOG_MODAL = `${PREFIX}_SET_SHOW_ACTIVITY_LOG_MODAL`;
export const SET_DATASET_STATUS = `${PREFIX}_SET_DATASET_STATUS`;

export const SET_LAST_USED_DATA = `${PREFIX}_SET_LAST_USED_DATA`;
export const SET_UPLOAD_FINISHED = `${PREFIX}_SET_UPLOAD_FINISHED`;
export const SET_USER_INITIATED_CREATION = `${PREFIX}_SET_USER_INITIATED_CREATION`;
export const SET_FILES_UPLOAD_SIZE = `${PREFIX}_SET_FILES_UPLOAD_SIZE`;
export const SET_FILES_UPLOAD_PROGRESS = `${PREFIX}_SET_FILES_UPLOAD_PROGRESS`;
