import { toast } from "react-toastify";
import { useCallback, useEffect, useRef, useState } from "react";
import { FileType, NaturalDimensions } from "types";
import * as Feature from "helpers/constants/filters";

import { useSelector } from "react-redux";
import { shouldShowDsVersion } from "redux/User/selectors";

export function addQueryParameter(
  url: string,
  paramName: string,
  paramValue: any
) {
  const separator = url.includes("?") ? "&" : "?";
  return (
    url +
    separator +
    encodeURIComponent(paramName) +
    "=" +
    encodeURIComponent(paramValue)
  );
}

export function getQueryParameter(paramName: string) {
  const queryParams = window.location.toString().split("?")[1];

  if (!queryParams) {
    return false;
  }

  const paramsArray = queryParams.split("&");

  for (const param of paramsArray) {
    const [name, value] = param.split("=");

    if (name === paramName) {
      return value;
    }
  }

  return false;
}

export function calculatePlaceHolderCount(
  cardContainer: any,
  cardElement: any,
  length: number,
  gap: number
) {
  let wrapperWidth = 0;
  let elementWidth = 0;

  if (cardContainer) {
    wrapperWidth = cardContainer.offsetWidth;
  }

  if (cardElement) {
    elementWidth = cardElement.offsetWidth;
  }

  const cols = Math.floor((wrapperWidth - gap) / elementWidth);
  const rows = Math.ceil(length / cols);

  const placeholdersCount: any = cols * rows - length;

  return isNaN(parseInt(placeholdersCount)) ? 32 : placeholdersCount;
}

export function calculateAspectRatio(img: any) {
  let ratio = img.naturalWidth / img.naturalHeight;
  let width = img.height * ratio;
  let height = img.height;
  if (width > img.width) {
    width = img.width;
    height = img.width / ratio;
  }
  return [height, width];
}

export function debounceCreator(func: any, delay: number) {
  let timeoutID: ReturnType<typeof setTimeout>;
  return (...args: any) => {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function isMobileDevice() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent);
  return check;
}

export function showNotification(type: String, value: String) {
  switch (type) {
    case "error":
      toast.error(value);
      break;
    case "info":
      toast.info(value);
      break;
    case "success":
      toast.success(value);
      break;
    case "warning":
      toast.warning(value);
      break;
  }
}

export function useWindowResize() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  //delaying resize recalls  by 0.7 seconds
  const debouncedListener = debounceCreator(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, 700);

  useEffect(() => {
    window.addEventListener("resize", debouncedListener);
    return () => {
      window.removeEventListener("resize", debouncedListener);
    };
    //eslint-disable-next-line
  }, []);

  return {
    width,
    height,
  };
}

export function addOpacity(randomBrightColor: string, alpha: number) {
  const rgbArray = randomBrightColor.match(/\d+/g);
  if (rgbArray && rgbArray.length === 3) {
    const r = parseInt(rgbArray[0]);
    const g = parseInt(rgbArray[1]);
    const b = parseInt(rgbArray[2]);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgba(0, 0, 0, ${alpha})`;
}

export function generateRandomColor() {
  // adjust value for brightness
  const minComponentValue = 115;
  const red =
    minComponentValue +
    Math.floor(Math.random() * (255 - minComponentValue + 1));
  const green =
    minComponentValue +
    Math.floor(Math.random() * (255 - minComponentValue + 1));
  const blue =
    minComponentValue +
    Math.floor(Math.random() * (255 - minComponentValue + 1));
  const randomBrightColor = `rgb(${red}, ${green}, ${blue})`;
  return randomBrightColor;
}

export function isFeatureEnabled(userConfig: any, featureKey: string): boolean {
  const config = userConfig?.find(
    (config: any) => config.feature_key === featureKey
  );
  if (config) {
    const featureBehavior = config.feature_behavior;
    if (featureBehavior && featureBehavior["ANY"] === "SHOW") {
      return true;
    }
  }
  return false;
}

export function safeJsonParse(jsonString: string) {
  try {
    const parsedData = JSON.parse(jsonString);

    if (Array.isArray(parsedData)) {
      return parsedData;
    } else {
      throw new Error();
    }
  } catch (error) {
    return false;
  }
}

export function useInterval(callback: any, delay: number) {
  const savedCallback = useRef<any>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useDatasetVersion(dsVersion: string | null) {
  const showDsVersion = useSelector((state: any) => shouldShowDsVersion(state));
  let datasetPipelineVersion = null;

  // check if dsVersion is semver
  if (
    showDsVersion &&
    dsVersion &&
    /^\d+\.\d+([a-zA-Z]*)?(\.\d+(-[a-zA-Z]+\d*)?)?$/.test(dsVersion)
  ) {
    datasetPipelineVersion = dsVersion;
  }
  return datasetPipelineVersion;
}

export function truncateFilename(filename: string, maxLength: number) {
  if (filename.length <= maxLength) return filename;

  const extensionIndex = filename.lastIndexOf(".");
  const nameWithoutExtension = filename.substring(0, extensionIndex);
  const extension = filename.substring(extensionIndex);

  const remainingLength = maxLength - 3;

  const halfLength = Math.floor(remainingLength / 2);
  const beginning = nameWithoutExtension.substring(0, halfLength);
  const end = nameWithoutExtension.substring(
    nameWithoutExtension.length - halfLength
  );

  return `${beginning}...${end}${extension}`;
}

export const checkTallOrWideImage = async (
  url: string
): Promise<"tall" | "wide"> => {
  const img = new Image();

  const imageLoaded = new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
  });

  img.src = url;

  await imageLoaded;

  const width = img.width;
  const height = img.height;

  const isTall = height > width * 1.25;

  return isTall ? "tall" : "wide";
};

export const getMediaDimensions = async (
  url: string
): Promise<NaturalDimensions> => {
  return new Promise<NaturalDimensions>((resolve, reject) => {
    const media: HTMLImageElement = new Image();

    media.onload = () => {
      resolve({
        naturalWidth: media.naturalWidth,
        naturalHeight: media.naturalHeight,
      });
    };

    media.onerror = (error) => {
      reject(error);
    };

    media.src = url;
  });
};

export const useHoverDelay = (action: () => void, delay: number) => {
  // This ref will store the timeout ID.
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const clearTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const handleMouseEnter = useCallback(() => {
    clearTimer();
    timerRef.current = setTimeout(() => {
      action();
    }, delay);
  }, [action, delay, clearTimer]);

  const handleMouseLeave = useCallback(() => {
    clearTimer();
  }, [clearTimer]);

  return { handleMouseEnter, handleMouseLeave };
};

export default useHoverDelay;

export const isFastdupUser = () => process.env.REACT_APP_MODE === "FD2";

export const transformFilterData = (features: any[]): any[] => {
  const transformedFeatures: any[] = [];
  let filtersArray: any[] = [
    Feature.TAGS_FILTER,
    Feature.LABEL_FILTER,
    Feature.DATE_RANGE_FILTER,
    Feature.ISSUE_TYPE_FILTER,
    Feature.ENTITY_TYPE_FILTER,
    Feature.FILE_FOLDER_FILTER,
    Feature.EXPLORATION_STATS,
    Feature.FIND_SIMILAR_OBJECT_FILTER,
    Feature.DATA_INGESTION_UX,
    Feature.SHOW_DS_VERSION,
  ];
  features.forEach((feature) => {
    const existingFeature = transformedFeatures.find(
      (transformedFeature) =>
        transformedFeature.feature_key === feature.feature_key
    );

    if (existingFeature) {
      existingFeature.feature_behavior[feature.contexts[0]] =
        feature.feature_behavior;
    } else {
      transformedFeatures.push({
        feature_key: feature.feature_key,
        feature_behavior: { [feature.contexts[0]]: feature.feature_behavior },
      });
    }
  });
  filtersArray.forEach((filterName: string) => {
    let exists = transformedFeatures.find(
      (feature: any) => feature.feature_key === filterName
    );
    if (!exists) {
      transformedFeatures.push({
        feature_key: filterName,
        feature_behavior: { ANY: null },
      });
    }
  });

  return transformedFeatures;
};

export function determineFileType(filename: string | undefined): FileType {
  if (!filename) {
    return FileType.UNKNOWN;
  }

  const extension = filename.split(".").pop()?.toLowerCase();
  const videoExtensions = ["mp4", "avi", "mkv", "mov", "wmv"];
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

  if (extension && videoExtensions.includes(extension)) {
    return FileType.VIDEO;
  } else if (extension && imageExtensions.includes(extension)) {
    return FileType.IMAGE;
  } else {
    return FileType.UNKNOWN;
  }
}

export const formatSize = (bytes: number): string => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.ceil(bytes / Math.pow(1024, i)) + " " + sizes[i];
};

export const removeS3Prefix = (s3link: string): string => {
  // Extract the substring starting from "s3://"
  if (s3link.includes("s3://")) {
    const s3Index = s3link.indexOf("s3://");
    return s3link.substring(s3Index + 5);
  }
  return s3link;
};

export const isValidEmail = (email: string): boolean => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};
