import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { connect, useDispatch } from "react-redux";
import { setSelectedSection } from "redux/Metadata/actions";
import { State } from "redux/store";
import { getSelectedSection } from "redux/Metadata/selectors";
import {
  getDatasetIssueTypes,
  getDatasetLabels,
  getEntityTypeFilter,
  getFilteredSimilarityClusters,
  getNavigationCluster,
  getUserTags,
} from "redux/SingleDataset/selectors";
import {
  FilterItemCountsWithSelect,
  FilterOption,
  MetadataBarTypes,
  MetadataSectionTypes,
} from "types";

import { AMP_DATASET_EXPLORATION_EVENT__METADATA__SECTION_OPENED } from "helpers/constants/amplitudeEvents";
import {
  AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE,
  AMP_DATASET_EXPLORATION_PROPERTY__TARGET,
} from "helpers/constants/amplitudeProperties";
import { amplitudeTrack } from "helpers/utility/amplitude";

import ContentHeader from "./ContentHeader";
import ContentBody from "./ContentBody";

import styles from "./style.module.scss";

const Content = ({
  type,
  totalCount,
  title,
  data,
  sectionType,
  id,
  selectedID,
  setSelectedID,
  selectedSection,
  entityTypeFilter,
  clusters,
  navigationCluster,
  datasetLabels,
  userTags,
  issueTypes,
}: ContentProps) => {
  const [searchText, setSearchText] = useState("");
  const [isSearchSelected, setIsSearchSelected] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const { datasetId, clusterId, imageId } = useParams();

  const isDataPage = datasetId && !clusterId && !imageId;

  const toggleSearchSelection = () => {
    setSearchText("");
    setIsSearchSelected(!isSearchSelected);
    if (!isSearchSelected) {
      setIsDropdownOpen(true);
    }

    if (selectedSection === sectionType) {
      dispatch(setSelectedSection(null));
      setSelectedID(null);
    } else if (!isSearchSelected === true) {
      //track what metadata summary section was opened
      const dataList = isDataPage ? clusters : navigationCluster?.previews;
      const capitalizedTitle = title
        .split(" ")
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__METADATA__SECTION_OPENED, {
        [AMP_DATASET_EXPLORATION_PROPERTY__TARGET]: capitalizedTitle,
        [AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE]: dataList.length,
      });

      dispatch(setSelectedSection(sectionType));
      setSelectedID(id);
    }
  };

  const selectedData = data.filter((singleValue: any) => {
    const trimmedSearchText = searchText
      .toLowerCase()
      .replace(/^\s+|\s+$/g, "");
    const title = singleValue.title?.toLowerCase();

    return title.includes(trimmedSearchText);
  });
  const showNoMatchingData = !!searchText && selectedData.length <= 0;

  const resetSection = () => {
    setIsSearchSelected(!isSearchSelected);
    setIsDropdownOpen(true);
    setSearchText("");
    dispatch(setSelectedSection(null));
    setSelectedID(null);
  };

  const handleOutsideClick = (event: any) => {
    if (contentRef.current && !contentRef.current.contains(event.target)) {
      resetSection();
    }
  };

  useEffect(() => {
    if (isSearchSelected) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [isSearchSelected]);

  return (
    <div
      ref={contentRef}
      className={styles.contentWrapper}
      style={selectedID && selectedID !== id ? { display: "none" } : undefined}
    >
      <ContentHeader
        title={title}
        totalCount={totalCount}
        isSearchSelected={isSearchSelected}
        toggleSearchSelection={toggleSearchSelection}
        searchText={searchText}
        setSearchText={setSearchText}
        isUserTitle={type === "user-tags"}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
      />
      {isDropdownOpen && (
        <ContentBody
          title={title}
          type={type}
          data={searchText ? selectedData : data}
          showNoMatchingData={showNoMatchingData}
          isContentSelected={!!selectedID && selectedID === id}
          entityTypeFilter={entityTypeFilter}
          resetSection={resetSection}
          datasetLabels={datasetLabels}
          userTags={userTags}
          issueTypes={issueTypes}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedSection: getSelectedSection(state),
    entityTypeFilter: getEntityTypeFilter(state),
    clusters: getFilteredSimilarityClusters(state),
    navigationCluster: getNavigationCluster(state),
    datasetLabels: getDatasetLabels(state),
    userTags: getUserTags(state),
    issueTypes: getDatasetIssueTypes(state),
  };
};
export default connect(mapStateToProps)(Content);

interface ContentProps {
  title: string;
  totalCount: number;
  type: MetadataBarTypes;
  data: Array<FilterItemCountsWithSelect>;
  sectionType: MetadataSectionTypes;
  id: string;
  selectedID: string | null;
  setSelectedID: any;
  selectedSection?: MetadataSectionTypes;
  entityTypeFilter: FilterOption[];
  clusters: any;
  navigationCluster: any;
  datasetLabels: FilterOption[];
  userTags: FilterOption[];
  issueTypes: FilterOption[];
}
