import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";

import {
  deleteImageTag,
  addImageTag,
} from "redux/Modals/actions";
import { updateUserTags } from "redux/SingleDataset/actions";
import TooltipWrapper from "views/uikit/TooltipWrapper";

import { BiSearch, BiX } from "react-icons/bi";
import styles from "./style.module.scss";
import ContentLoad from "views/uikit/ContentLoad";

const ImageTagsBox = ({
  setShowTagOptionsBox,
  imageTags,
  isContentLoading,
  isImageTagLoading
}: any) => {
  const [redBorderIndex, setRedBorderIndex] = useState(-1);
  const dispatch = useDispatch();

  const handleRemoveTag = (id: string) => {
    dispatch(deleteImageTag(id) as any);
  };

  return (
    <div className={styles.imageTagsWrapper}>
      <div
        className={styles.assignTagsButton}
        onClick={() => setShowTagOptionsBox(true)}
      >
        Assign User Tags
      </div>

      {(isContentLoading || isImageTagLoading) ? (
        <div className={styles.userTagsLoader}>
          <ContentLoad radius="5px" />
        </div>
      ) : (
        <>
          {imageTags.map((tag: any, index: number) => (
            <div
              className={styles.singleTag}
              key={index}
              title={tag?.name?.length <= 32 ? "" : tag.name}
              style={
                redBorderIndex === index
                  ? { borderColor: "#ff5454" }
                  : undefined
              }
            >
              <span className={styles.singleTagTitle}>{tag.name}</span>
              <div
                className={styles.removeTagButton}
                onMouseOver={() => setRedBorderIndex(index)}
                onMouseLeave={() => setRedBorderIndex(-1)}
                data-tooltip-id="remove-tag-button-tooltip"
                data-tooltip-content="De-Assign User Tag"
                data-tooltip-offset={15}
                onClick={() => handleRemoveTag(tag.id)}
              >
                <BiX size="1.25em" />
                <TooltipWrapper id="remove-tag-button-tooltip" />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const TagsOptionsBox = ({
  userTags,
  imageTags,
  recentTags,
  showTagOptionsBox,
  setShowTagOptionsBox,
}: any) => {
  const [searchText, setSearchText] = useState("");
  const [newTag, setNewTag] = useState("");
  const [alreadyAssignedTags, setAlreadyAssignedTags] = useState<any[]>([]);
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  let trimmedSearchText = searchText.toLowerCase().replace(/^\s+|\s+$/g, "");

  const searchedRecentTags = recentTags.filter((tag: any) =>
    tag.name.includes(trimmedSearchText)
  );

  const searchedUserTags = userTags.filter((tag: any) =>
    tag.text.toLowerCase().includes(trimmedSearchText)
  );

  const dynamicAvailableTags = searchText ? searchedUserTags : userTags;

  const showNewTag =
    !recentTags.find((tag: any) => tag.name === trimmedSearchText) &&
    !userTags.find((tag: any) => tag.text === trimmedSearchText) &&
    !imageTags.find((tag: any) => tag.name === trimmedSearchText) &&
    trimmedSearchText !== "";

  const closeSection = () => {
    setSearchText("");
    setShowTagOptionsBox(false);
    setAlreadyAssignedTags([]);
  };
  const handleKeyDown = (e: any, clicked?: boolean) => {
    if ((e.keyCode === 13 && newTag && showNewTag) || clicked) {
      dispatch(updateUserTags(newTag) as any);
      closeSection();
    }
  };
  const handleOutsideClick = (e: any) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      closeSection();
    }
  };

  const handleSubmit = (id: string, text: string) => {
    dispatch(addImageTag(id, text) as any);

    closeSection();
  };

  useEffect(() => {
    const handleAssignedTags = async () => {
      let alreadyAssignedTags: any[] = [];
      let allRecentTags =
        (await JSON.parse(localStorage.getItem("recent_tags") as string)) || [];
      if (searchText) {
        let imageTagsInRecent = allRecentTags.filter((recentTag: any) =>
          imageTags.some((imageTag: any) => imageTag.name === recentTag.name)
        );

        alreadyAssignedTags = imageTagsInRecent.filter((tag: any) =>
          tag.name?.toLowerCase().includes(trimmedSearchText)
        );
      }

      if (alreadyAssignedTags.length > 0) {
        setAlreadyAssignedTags(alreadyAssignedTags);
      } else {
        setAlreadyAssignedTags([]);
      }

      if (showNewTag && trimmedSearchText !== "") {
        setNewTag(trimmedSearchText);
      }
    };

    handleAssignedTags();
    //eslint-disable-next-line
  }, [searchText]);

  useEffect(() => {
    document.addEventListener("mouseup", handleOutsideClick);

    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div
      className={styles.tagOptionsWrapper}
      style={showTagOptionsBox ? undefined : { height: 0, border: "none" }}
      ref={containerRef}
    >
      <div className={styles.searchWrapper}>
        <div className={styles.searchContainer}>
          <div className={styles.searchIcon}>
            <BiSearch size={"0.8em"} />
          </div>
          <input
            type="search"
            maxLength={32}
            onChange={(e: any) => setSearchText(e.target.value)}
            onKeyDown={handleKeyDown}
            value={searchText}
            className={styles.searchInput}
            placeholder="Type to search for or create User Tags"
          />
        </div>
        <div className={styles.closeButton} onClick={closeSection}>
          <BiX size="1.50em" />
        </div>
      </div>

      {showNewTag && (
        <div className={styles.newTagBox}>
          <div className={styles.optionsTitle}>New User Tag</div>
          <div className={styles.options}>
            <div
              className={styles.option}
              onClick={(e: any) => handleKeyDown(e, true)}
            >
              {newTag} <span>(New User Tag)</span>
            </div>
          </div>
        </div>
      )}

      <div className={styles.suggestedTagsBox}>
        {(alreadyAssignedTags.length > 0 || searchedRecentTags.length > 0) && (
          <div className={styles.recentTagsBox}>
            <div className={styles.optionsTitle}>Recent</div>
            <div className={styles.options}>
              {alreadyAssignedTags.map((tag: any, index: number) => (
                <div
                  className={styles.option}
                  key={index}
                  style={{ cursor: "default" }}
                >
                  {tag.name} <span>(Already Assigned)</span>
                </div>
              ))}
              {searchedRecentTags.map((tag: any, index: number) => (
                <div
                  className={styles.option}
                  key={index}
                  onClick={() => handleSubmit(tag.id, tag.name)}
                >
                  {tag.name}
                </div>
              ))}
            </div>
          </div>
        )}
        {dynamicAvailableTags.length > 0 && (
          <div className={styles.availableTagsBox}>
            <div className={styles.optionsTitle}>Available</div>
            <div className={styles.options}>
              {dynamicAvailableTags.map((tag: any, index: number) => (
                <div
                  className={styles.option}
                  key={index}
                  onClick={() => handleSubmit(tag.id, tag.text)}
                >
                  {tag.text}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

type UserTagProps = {
  userTags: Array<{ id: string; name: string }>;
  imageTags: Array<{ id: string; name: string }>;
  isContentLoading: boolean;
  isImageTagLoading: boolean;
};

const UserTags = ({ userTags, imageTags, isContentLoading ,isImageTagLoading}: UserTagProps) => {
  const [showTagOptionsBox, setShowTagOptionsBox] = useState(false);
  const [filteredRecentTags, setFilteredRecentTags] = useState<any[]>([]);
  const [filteredUserTags, setFilteredUserTags] = useState<any[]>([
    ...userTags,
  ]);

  useEffect(() => {
    const recreateTags = async () => {
      let newRecentTags =
        (await JSON.parse(localStorage.getItem("recent_tags") as string)) || [];
      const userTagNames = userTags.map((userTag: any) =>
        userTag.text.toLowerCase()
      );
      const imageTagNames = imageTags.map((imageTag: any) =>
        imageTag.name.toLowerCase()
      );
      const recentTagNames = newRecentTags.map((recentTag: any) =>
        recentTag.name.toLowerCase()
      );

      newRecentTags = newRecentTags.filter((recentTag: any) => {
        const isUserTag = userTagNames.includes(recentTag.name.toLowerCase());
        const isImageTag = imageTagNames.includes(recentTag.name.toLowerCase());

        return isUserTag && !isImageTag;
      });

      const newUserTags = userTags.filter((userTag: any) => {
        const isImageTag = imageTagNames.includes(userTag.text.toLowerCase());
        const isRecentTag = recentTagNames.includes(userTag.text.toLowerCase());

        return !isRecentTag && !isImageTag;
      });

      setFilteredRecentTags(newRecentTags);
      setFilteredUserTags(newUserTags);
    };
    recreateTags();
    //eslint-disable-next-line
  }, [imageTags, userTags]);

  return (
    <div className={styles.tagsWrapper}>
      <ImageTagsBox
        imageTags={imageTags}
        setShowTagOptionsBox={setShowTagOptionsBox}
        isContentLoading={isContentLoading}
        isImageTagLoading={isImageTagLoading}
      />
      <TagsOptionsBox
        userTags={filteredUserTags}
        recentTags={filteredRecentTags}
        imageTags={imageTags}
        showTagOptionsBox={showTagOptionsBox}
        setShowTagOptionsBox={setShowTagOptionsBox}
      />
    </div>
  );
};

export default UserTags;
