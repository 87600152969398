import { connect, useDispatch } from "react-redux";
import { State } from "redux/store";
import classNames from "classnames";
import { UserQuota } from "types";

import {
  setshowProfileModal,
  setSelectPlanModalData,
} from "redux/Modals/actions";
import TooltipWrapper from "views/uikit/TooltipWrapper";
import noUserImage from "assets/img/anonymous_user.svg";
import { getUserQuota } from "redux/User/selectors";
import { formatNumberWithCommas } from "helpers/utility/formatters";
import { QUOTA_LIMIT_REACHED_TOOLTIP_TEXT } from "helpers/constants/tooltips";

import styles from "./style.module.scss";

const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9 5.66667V9M9 12.3333H9.00833M16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z"
        stroke="#FF4500"
        stroke-width="1.04167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const UserInfo = ({
  userQuota,
  userAvatar,
}: {
  userQuota: UserQuota | null;
  userAvatar: string | undefined;
}) => {
  const dispatch = useDispatch();

  const handleUpgradeClick = () => {
    dispatch(setshowProfileModal(false));
    dispatch(setSelectPlanModalData({ show: true, calledFrom: "user_info" }));
  };

  const progressPercentage = userQuota
    ? (userQuota.usedQuota / userQuota.totalQuota) * 100
    : 0;
  const dynamicWidth = userQuota ? `calc(${progressPercentage}%)` : "0px";

  const barClassnames = classNames(styles.bar, {
    [styles.yellowBar]: progressPercentage >= 85 && progressPercentage < 95,
    [styles.redBar]: progressPercentage >= 95,
  });

  const showInfoIcon = progressPercentage >= 100;

  const formattedUsedQuota = userQuota
    ? formatNumberWithCommas(userQuota.usedQuota)
    : 0;
  const formattedTotalQuota = userQuota
    ? formatNumberWithCommas(userQuota.totalQuota)
    : 0;

  return (
    <div className={styles.userInfoContainer}>
      <div className={styles.profileImageContianer}>
        <div className={styles.profileImage}>
          <img src={userAvatar || noUserImage} alt="profile" />
        </div>
        {userQuota?.email && (
          <div className={styles.header}>
            <span className={styles.userName}>
              {userQuota.email.split("@")[0].toLowerCase()}
            </span>
            <span className={styles.userEmail}>{userQuota?.email}</span>
          </div>
        )}
      </div>
      <span className={styles.modalDivder}></span>

      <div className={styles.planInfo}>
        <span className={styles.planText}>{userQuota?.accountType}</span>
      </div>
      <div className={styles.planInfo}>
        <span className={styles.ImageCountInfo}>Image Usage</span>
      </div>
      <span className={styles.imageCount}>
        <span className={styles.boldText}>{formattedUsedQuota} </span>
        <span> / </span>
        <span className={styles.totalQuota}>{formattedTotalQuota}</span>
        {showInfoIcon && (
          <div
            className={styles.infoIconContainer}
            data-tooltip-id="totalQuota-tooltip"
            data-tooltip-content={QUOTA_LIMIT_REACHED_TOOLTIP_TEXT}
            data-tooltip-place="right"
          >
            <InfoIcon />
            <TooltipWrapper
              id="totalQuota-tooltip"
              customClass="totalQuotaTooltip"
              maxWidth="346px"
            />
          </div>
        )}
      </span>
      <div className={styles.progressBar}>
        <div className={barClassnames} style={{ width: dynamicWidth }}></div>
      </div>
      <div className={styles.upgradeButton} onClick={handleUpgradeClick}>
        Upgrade Plan
      </div>

      <div className={styles.tagLine}>
        <span className={styles.tagLineText}>
          ✨Unlimited images & more benefits!
        </span>
      </div>
      <span className={styles.bottomDivder}></span>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    userQuota: getUserQuota(state),
  };
};

export default connect(mapStateToProps)(UserInfo);
