import classNames from "classnames";
import { DatasetStatusEnum } from "types";

import styles from "./style.module.scss";

const DatasetStatusComponent = ({ datasetStatus }: DatasetStatusProps) => {
  const statusButtonClassnames = classNames(styles.statusButton, {
    [styles.readyButton]: datasetStatus === DatasetStatusEnum.READY,
    [styles.progressButton]:
      datasetStatus === DatasetStatusEnum.INDEXING ||
      datasetStatus === DatasetStatusEnum.UPLOADING,
    [styles.initializingButton]:
      datasetStatus === DatasetStatusEnum.INITIALIZING,
    [styles.errorButton]: datasetStatus === DatasetStatusEnum.FATAL_ERROR,
  });

  return datasetStatus ? (
    <div className={styles.datasetStatusContainer}>
      <button className={statusButtonClassnames}>{datasetStatus}</button>
    </div>
  ) : (
    <noscript />
  );
};

export default DatasetStatusComponent;

interface DatasetStatusProps {
  datasetStatus: string;
}
