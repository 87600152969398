import axios from "axios";
import { userLogout } from "redux/authReducer";
import { User } from "types";

export const axiosConfig = (user: User) => {
  const headers: { [key: string]: string } = {};

  headers["Content-Type"] = "multipart/form-data";

  return { headers };
};

export const composeApiURL = (hostUrl: string, path: string) => {
  if (
    hostUrl === "https://app.visual-layer.com" &&
    window.location.host === "app.visual-layer.link"
  ) {
    hostUrl = "https://app.visual-layer.link";
  }
  return hostUrl + "/api/v1" + path;
};

export const axiosGet = async (url: string, user: User): Promise<any> => {
  // call axios get with the given url and user and redirect to login upon 401 or 403
  try {
    return await axios.get(url, axiosConfig(user));
  } catch (error: any) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      userLogout();
    } else {
      throw error;
    }
  }
};
