import { useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { MdOutlineLogin } from "react-icons/md";
import Modal from "react-bootstrap/Modal";

import { getIsUsageReportEnabled } from "redux/Datasets/selectors";
import { getShowProfileModal } from "redux/Modals/selectors";
import { setshowProfileModal } from "redux/Modals/actions";
import { userLogout } from "redux/authReducer";
import { State } from "redux/store";
import classNames from "classnames";
import UserInfo from "./UserInfo";

import styles from "./style.module.scss";

interface ProfileModalProps {
  showModal: boolean;
  userAvatar: string | undefined;
  isUsageReportEnabled: boolean;
}

function ProfileModal({
  showModal,
  userAvatar,
  isUsageReportEnabled,
}: ProfileModalProps) {
  const dispatch = useDispatch();

  const innerContainerClassnames = classNames(styles.modalInnerContainer, {
    [styles.wideContainer]: isUsageReportEnabled,
  });

  const closeModal = useCallback(() => {
    dispatch(setshowProfileModal(false));
    //eslint-disable-next-line
  }, [setshowProfileModal]);

  const handleLogout = () => {
    userLogout();
  };
  const listener = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
        document.removeEventListener("keydown", listener);
      }
    },
    [closeModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [closeModal, listener]);

  return (
    <Modal
      animation={false}
      show={showModal}
      onHide={closeModal}
      className={styles.modalMainContainer}
      backdropClassName={styles.backgroundColor}
    >
      <Modal.Body className={innerContainerClassnames}>
        <div className={styles.optionsListContainer}>
          {isUsageReportEnabled && <UserInfo userAvatar={userAvatar} />}
          <div className={styles.listStyle} onClick={handleLogout}>
            <MdOutlineLogin className={styles.listIcon} size="18px" />
            <span className={styles.logoutOption}>Log Out</span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state: State) => {
  return {
    showModal: getShowProfileModal(state),
    isUsageReportEnabled: getIsUsageReportEnabled(state),
  };
};
export default connect(mapStateToProps)(ProfileModal);
