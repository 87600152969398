import { DatasetInitialized, LimitExceeded } from "extractedBackendTypes";
import {
  SET_CONNECT_STATUS,
  SET_PREVIEW_DATA,
  SET_S3_FETCH_PROGRESS,
  SET_NEW_DATASET,
  SET_ACTIVITY_LOGS,
  RESET_STORE,
  SET_ANNOTATION_STATUS,
  SET_UPLOAD_STATUS,
  SET_IMAGE_ERROR_FILE_NAME,
  SET_ACTIVITY_OFFSET,
  SET_IS_FATAL_ERROR,
  SET_PREVIEW_DATA_SOURCE,
  SET_FILES_UPLOAD_TRANS_ID,
  SET_INDEXING_STATUS,
  SET_LIMIT_EXCEEDED_DETAILS,
  SET_ARE_ANNOTATIONS_FETCHED,
  SET_STATUS_CALL_IN_PROGRESS,
  SET_IS_LOADING,
  SET_SHOW_DELETE_DATASET_MODAL,
  SET_DATASET_STATUS,
  SET_LAST_USED_DATA,
  SET_UPLOAD_FINISHED,
  SET_USER_INITIATED_CREATION,
  SET_FILES_UPLOAD_SIZE,
  SET_FILES_UPLOAD_PROGRESS,
  SET_SHOW_ACTIVITY_LOG_MODAL,
} from "./constants";
import {
  DataPreview,
  DatasetStatusEnum,
  DataSources,
  LastUsedData,
  StepTileProgressStatus,
  FilesUploadSize,
  ActivityLogShowModalType,
} from "types";

const INITIAL_STATE: CreateDatasetState = {
  connectStatus: StepTileProgressStatus.ACTIVE,
  annotationStatus: StepTileProgressStatus.DISABLED,
  uploadStatus: StepTileProgressStatus.DISABLED,
  indexingStatus: StepTileProgressStatus.DISABLED,
  s3FetchProgress: -1,
  previewData: [],
  previewDataSource: null,
  newDataset: null,
  activityLogs: [],
  imageErrorFileName: "",
  activityOffset: 0,
  isFatalError: false,
  filesUploadTransId: null,
  limitExceededDetails: null,
  areAnnotationsFetched: false,
  statusCallInProgress: false,
  isLoading: false,
  showDeleteDatasetModal: false,
  showActivityLogModal: { show: false, reason: "", title: "", severity: "" },
  datasetStatus: null,
  lastUsedData: { s3Url: "", folderName: "", annotationFiles: [] },
  uploadFinished: false,
  userInitiatedCreation: false,
  filesUploadSize: null,
  filesUploadProgress: null,
};

const reducer = (state: CreateDatasetState = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RESET_STORE:
      return INITIAL_STATE;

    case SET_CONNECT_STATUS:
      return { ...state, connectStatus: action.data };

    case SET_S3_FETCH_PROGRESS:
      return { ...state, s3FetchProgress: action.data };

    case SET_PREVIEW_DATA:
      return { ...state, previewData: action.data };

    case SET_PREVIEW_DATA_SOURCE:
      return { ...state, previewDataSource: action.data };

    case SET_NEW_DATASET:
      return { ...state, newDataset: action.data };

    case SET_ACTIVITY_LOGS:
      return { ...state, activityLogs: action.data };

    case SET_ANNOTATION_STATUS:
      return { ...state, annotationStatus: action.data };

    case SET_UPLOAD_STATUS:
      return { ...state, uploadStatus: action.data };

    case SET_IMAGE_ERROR_FILE_NAME:
      return { ...state, imageErrorFileName: action.data };

    case SET_ACTIVITY_OFFSET:
      return { ...state, activityOffset: action.data };

    case SET_IS_FATAL_ERROR:
      return { ...state, isFatalError: action.data };

    case SET_FILES_UPLOAD_TRANS_ID:
      return { ...state, filesUploadTransId: action.data };

    case SET_INDEXING_STATUS:
      return { ...state, indexingStatus: action.data };

    case SET_LIMIT_EXCEEDED_DETAILS:
      return { ...state, limitExceededDetails: action.data };

    case SET_ARE_ANNOTATIONS_FETCHED:
      return { ...state, areAnnotationsFetched: action.data };

    case SET_STATUS_CALL_IN_PROGRESS:
      return { ...state, statusCallInProgress: action.data };

    case SET_IS_LOADING:
      return { ...state, isLoading: action.data };

    case SET_SHOW_DELETE_DATASET_MODAL:
      return { ...state, showDeleteDatasetModal: action.data };

    case SET_DATASET_STATUS:
      return { ...state, datasetStatus: action.data };

    case SET_LAST_USED_DATA:
      return { ...state, lastUsedData: action.data };

    case SET_UPLOAD_FINISHED:
      return { ...state, uploadFinished: action.data };

    case SET_USER_INITIATED_CREATION:
      return { ...state, userInitiatedCreation: action.data };

    case SET_FILES_UPLOAD_SIZE:
      return { ...state, filesUploadSize: action.data };

    case SET_FILES_UPLOAD_PROGRESS:
      return { ...state, filesUploadProgress: action.data };

    case SET_SHOW_ACTIVITY_LOG_MODAL:
      return { ...state, showActivityLogModal: action.data };

    default:
      return state;
  }
};

export default reducer;

interface CreateDatasetState {
  connectStatus: StepTileProgressStatus;
  annotationStatus: StepTileProgressStatus;
  uploadStatus: StepTileProgressStatus;
  indexingStatus: StepTileProgressStatus;
  s3FetchProgress: number;
  filesUploadTransId: string | null;
  previewData: DataPreview[];
  previewDataSource: DataSources | null;
  newDataset: DatasetInitialized | null;
  activityLogs: any[];
  imageErrorFileName: string;
  activityOffset: number;
  isFatalError: boolean;
  limitExceededDetails: LimitExceeded | null;
  areAnnotationsFetched: boolean;
  statusCallInProgress: boolean;
  isLoading: boolean;
  showDeleteDatasetModal: Boolean;
  showActivityLogModal: ActivityLogShowModalType;
  datasetStatus: DatasetStatusEnum | null;
  lastUsedData: LastUsedData;
  uploadFinished: boolean;
  userInitiatedCreation: boolean;
  filesUploadSize: FilesUploadSize | null;
  filesUploadProgress: number | null;
}
