import { useInterval } from "helpers/utility/utilities";
import { useState, useEffect } from "react";
import styles from "./style.module.scss";

interface ProgressBarProps {
  loading: boolean;
  setCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

const gradientColors = [
  "#ff0900",
  "#d6493e",
  "#cc4e84",
  "#a63b94",
  "#336c9b",
  "#0197d8",
  "#26bf79",
  "#2db686",
  "#1bc07d",
  "#a4c217",
  "#d5ca29",
];

const calculateLinearGradient = (stops: number) => {
  const validStops = Math.max(2, Math.min(stops, 10));
  const step = 100 / (validStops - 1);

  return `linear-gradient(90deg, ${gradientColors
    .slice(0, validStops)
    .map((color, index) => `${color} ${index * step}%`)
    .join(",")})`;
};

const ProgressBarLoader = ({ loading, setCompleted }: ProgressBarProps) => {
  const [progress, setProgress] = useState(0);
  const intervalSpeed = loading ? 10 : 1;

  const dynamicStyle = {
    backgroundImage: calculateLinearGradient(Math.ceil(progress / 10)),
    width: `${progress}%`,
  };

  useInterval(() => {
    setProgress((prev: number) =>
      prev >= 100 ? 0 : prev + (loading ? 0.1 : 1)
    );
  }, intervalSpeed);

  useEffect(() => {
    if (progress >= 100) {
      setProgress(0);
      if (!loading) {
        setCompleted(true);
      }
    }
    //eslint-disable-next-line
  }, [progress]);

  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressBar} style={dynamicStyle}></div>
    </div>
  );
};

const ProgressBarLoaderWrapper = ({ loading }: { loading: boolean }) => {
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (loading) setCompleted(false);
  }, [loading]);

  return loading || !completed ? (
    <ProgressBarLoader loading={loading} setCompleted={setCompleted} />
  ) : null;
};

export default ProgressBarLoaderWrapper;
