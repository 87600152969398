import { connect } from "react-redux";
import { State } from "redux/store";

import {
  getShowRequestDemoModal,
  getShowRequestDemoSuccessModal,
} from "redux/Modals/selectors";

import RequestDemoModal from "views/modals/RequestDemoModal";
import AnnotationStep from "./Annotations";
import IndexingStep from "./Indexing";
import ConnectStep from "./Connect";
import UploadStep from "./Upload";

import { ContactUsComponentType, SetStateFunction } from "types";

import styles from "./style.module.scss";
import RequestDemoSuccessModal from "views/modals/RequestDemoSuccessModal";

const StepsSection = ({
  showStartBanner,
  setShowStartBanner,
  showRequestDemoModal,
  showRequestDemoSuccessModal,
}: StepsSectionProps) => {
  return (
    <div className={styles.stepsSection}>
      <ConnectStep
        showStartBanner={showStartBanner}
        setShowStartBanner={setShowStartBanner}
      />

      <AnnotationStep />
      <UploadStep />
      <div className={styles.separator}></div>
      <IndexingStep />

      {showRequestDemoModal.flag && (
        <RequestDemoModal calledFrom={showRequestDemoModal.calledFrom} />
      )}
      {showRequestDemoSuccessModal && <RequestDemoSuccessModal />}
      <div className={styles.hiddenText}>hiddenText</div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    showRequestDemoModal: getShowRequestDemoModal(state),
    showRequestDemoSuccessModal: getShowRequestDemoSuccessModal(state),
  };
};

export default connect(mapStateToProps)(StepsSection);

interface StepsSectionProps {
  showStartBanner: boolean;
  showRequestDemoModal: { flag: boolean; calledFrom: ContactUsComponentType };
  setShowStartBanner: SetStateFunction<boolean>;
  showRequestDemoSuccessModal: boolean;
}
