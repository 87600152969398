import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { connect, useDispatch } from "react-redux";

import {
  setQuotaLimitModalData,
  setShowDemoRequestModal,
  setShowDemoRequestSuccessModal,
  submitDemoRequest,
} from "redux/Modals/actions";
import {
  getQuotaLimitModalData,
  getShowRequestDemoModal,
} from "redux/Modals/selectors";
import { validateEmail, validatePhoneNumber } from "helpers/validations";
import { PRIVATE_DATASETS_PATH } from "helpers/constants/paths";
import { getNewDatasetId } from "redux/CreateDataset/selectors";
import { deleteDataset } from "redux/Datasets/actions";

import ArrowCircleIcon from "assets/icons/ArrowCircleIcon";
import styles from "./style.module.scss";
import XIcon from "assets/icons/XIcon";
import { State } from "redux/store";
import { ContactUsComponentType } from "types";

interface RequestDemoModalProps {
  calledFrom: ContactUsComponentType;
  attemptedImageCount: number;
  newDatasetId: string;
}

const RequestDemoModal = ({
  calledFrom,
  newDatasetId,
  attemptedImageCount,
}: RequestDemoModalProps) => {
  const { datasetId } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormDataType>({
    fullName: "",
    email: "",
    phone: "",
    jobTitle: "",
    company: "",
    notes: "",
    datasetID: datasetId || "",
    calledFrom: calledFrom,
  });

  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    fullName: "",
    email: "",
    jobTitle: "",
    company: "",
    phone: "",
  });
  const [fetchError, setFetchError] = useState(false);
  const dispatch = useDispatch();

  const showDynamicValue = (
    key: "email" | "fullName" | "jobTitle" | "company" | "phone"
  ) => {
    if (!!formErrors[key]) return formErrors[key];
    else return formData[key];
  };

  const clearErrorMessage = (
    key: "email" | "fullName" | "jobTitle" | "company" | "phone"
  ) => {
    if (!!formErrors[key]) setFormErrors({ ...formErrors, [key]: "" });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const resetForm = () => {
    setFormData({
      fullName: "",
      email: "",
      phone: "",
      jobTitle: "",
      company: "",
      notes: "",
      datasetID: datasetId || "",
      calledFrom: formData.calledFrom,
    });
    setFormErrors({
      fullName: "",
      email: "",
      jobTitle: "",
      company: "",
      phone: "",
    });
    setFetchError(false);
  };

  const handleCloseButtonClick = (e: any) => {
    e.stopPropagation();
    resetForm();
    dispatch(setShowDemoRequestModal({ flag: false, calledFrom: "" }));
    if (calledFrom === "upgrade_plan") {
      dispatch(setQuotaLimitModalData({ show: true, attemptedImageCount }));
    }
  };

  const validateForm = () => {
    const errors: any = {};

    // can be changed later
    const keysToValidate = ["fullName", "email"];

    keysToValidate.forEach((key) => {
      if (!formData[key].trim()) {
        errors[key] = "This field can't be empty.";
      } else if (key === "email") {
        //handle Email validation
        const emailError = validateEmail(formData[key]);
        if (!!emailError) {
          errors[key] = "Not a valid e-mail";
          setFormData({ ...formData, email: "" });
        }
      }
    });

    if (!!formData["phone"]) {
      const value = formData["phone"].trim();
      const result = validatePhoneNumber(value);

      if (result) {
        errors["phone"] = "Not a valid phone number";
        setFormData({ ...formData, phone: "" });
      }
    }

    return errors;
  };

  const handleFormSubmission = async () => {
    //resetErrors
    setFetchError(false);
    setFormErrors({
      fullName: "",
      email: "",
      jobTitle: "",
      company: "",
      phone: "",
    });

    const errors = validateForm();
    if (Object.keys(errors).length > 0) setFormErrors(errors);
    else {
      const result = await dispatch(submitDemoRequest(formData) as any);

      if (result) {
        resetForm();
        dispatch(setShowDemoRequestSuccessModal(true));
        dispatch(setShowDemoRequestModal({ flag: false, calledFrom: "" }));
        if (calledFrom === "upgrade_plan") {
          await dispatch(deleteDataset(newDatasetId) as any);
          navigate(PRIVATE_DATASETS_PATH);
        }
      } else setFetchError(true);
    }
  };

  return (
    <div className={styles.requestDemoModalWrapper}>
      <div className={styles.requestDemoModalContainer}>
        <div className={styles.requestDemoModalHeader}>
          <div className={styles.titleContainer}>
            <div className={styles.titleIcon}>
              <ArrowCircleIcon color="#FEAA01" />
            </div>
            <div className={styles.titleText}>Contact Sales</div>
          </div>
          <div className={styles.closeButton} onClick={handleCloseButtonClick}>
            <XIcon />
          </div>
        </div>
        <div className={styles.requestDemoModalBody}>
          <div className={styles.bodyTitle}>
            Leave your info below and our team will get back to you shortly:
          </div>
          <form className={styles.bodyForm}>
            <div className={styles.formColumn}>
              <label htmlFor="fullName" className={styles.required}>
                Full Name
              </label>
              <input
                type="text"
                required
                id="fullName"
                name="fullName"
                value={showDynamicValue("fullName")}
                onClick={() => clearErrorMessage("fullName")}
                onChange={handleInputChange}
                autoComplete="off"
                className={!!formErrors.fullName ? styles.errorInput : ""}
              />
            </div>
            <div className={styles.formColumn}>
              <label htmlFor="email" className={styles.required}>
                Email
              </label>
              <input
                type="email"
                required
                id="email"
                name="email"
                value={showDynamicValue("email")}
                onClick={() => clearErrorMessage("email")}
                onChange={handleInputChange}
                className={!!formErrors.email ? styles.errorInput : ""}
              />
            </div>
            <div className={styles.formColumn}>
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={showDynamicValue("phone")}
                onClick={() => clearErrorMessage("phone")}
                pattern="[0-9]*"
                onChange={handleInputChange}
                autoComplete="off"
                className={!!formErrors.phone ? styles.errorInput : ""}
              />
            </div>
            <div className={styles.formColumn}>
              <label htmlFor="jobTitle">Job Title</label>
              <input
                type="text"
                id="jobTitle"
                name="jobTitle"
                value={showDynamicValue("jobTitle")}
                onClick={() => clearErrorMessage("jobTitle")}
                onChange={handleInputChange}
                autoComplete="off"
                className={!!formErrors.jobTitle ? styles.errorInput : ""}
              />
            </div>
            <div className={styles.formColumn}>
              <label htmlFor="company">Company</label>
              <input
                type="text"
                id="company"
                name="company"
                value={showDynamicValue("company")}
                onClick={() => clearErrorMessage("company")}
                onChange={handleInputChange}
                autoComplete="off"
                className={!!formErrors.company ? styles.errorInput : ""}
              />
            </div>
            <div className={styles.formColumn}>
              <label htmlFor="notes">Notes</label>
              <textarea
                id="notes"
                name="notes"
                value={formData.notes}
                onChange={handleInputChange}
              />
            </div>
          </form>
        </div>
        <div className={styles.requestDemoModalFooter}>
          {fetchError && (
            <div className={styles.fetchErrorText}>Something went wrong.</div>
          )}
          <div className={styles.cancelButton} onClick={handleCloseButtonClick}>
            Cancel
          </div>
          <div className={styles.submitButton} onClick={handleFormSubmission}>
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    newDatasetId: getNewDatasetId(state),
    showRequestDemoModal: getShowRequestDemoModal(state),
    attemptedImageCount: getQuotaLimitModalData(state).attemptedImageCount,
  };
};

export default connect(mapStateToProps)(RequestDemoModal);

type FormDataType = {
  fullName: string;
  email: string;
  phone: string;
  jobTitle: string;
  company: string;
  notes: string;
  calledFrom: ContactUsComponentType;
  datasetID: string;
  [key: string]: string;
};

type FormErrorsType = {
  fullName: string;
  email: string;
  jobTitle: string;
  company: string;
  phone: string;
  [key: string]: string;
};
