import { Fragment, useRef } from "react";

import styles from "./style.module.scss";

const IssuesBoundingBox = ({
  imageDimensions,
  selectedIssue,
  tooltipDimensions,
}: any) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);

  const getTooltipWrapperStyle = () => {
    if (tooltipDimensions.height > 0 && tooltipDimensions.width > 0) {
      return {
        height: `${tooltipDimensions.height}px`,
        width: `${tooltipDimensions.width}px`,
      };
    } else {
      return undefined;
    }
  };

  const boundingBoxStyle = (label: any) => {
    let heightRatio = 100;
    let widthRatio = 100;

    if (imageDimensions.height > 0 && imageDimensions.width > 0) {
      heightRatio = (tooltipDimensions.height / imageDimensions.height) * 100;
      widthRatio = (tooltipDimensions.width / imageDimensions.width) * 100;
    }

    if (label.boundingBox) {
      return {
        left: (label.boundingBox[0] * widthRatio) / 100,
        top: (label.boundingBox[1] * heightRatio) / 100,
        width: (label.boundingBox[2] * widthRatio) / 100,
        height: (label.boundingBox[3] * heightRatio) / 100,
      };
    }

    return undefined;
  };

  const alignDescriptionBox = (issue: any) => {
    let halfWidth = tooltipDimensions?.width / 2;
    let widthFromX = issue.boundingBox[0] + issue.boundingBox[2];

    if (issue.boundingBox) {
      //harcoded value for now
      if (widthFromX > halfWidth) {
        return { left: "-124px", padding: "4px 1px 3px 8px" };
      } else if (widthFromX < halfWidth) {
        return { right: "-124px", padding: "4px 8px 3px 1px" };
      }
    }
  };

  const overflowHeightStyle = (issue: any) => {
    if (issue.boundingBox) {
      if (issue.boundingBox[3] < 60) {
        return { height: "max-content" };
      }
    }
  };

  return (
    <Fragment>
      <div className={styles.tooltipWrapper} style={getTooltipWrapperStyle()}>
        <div className={styles.tooltipConatiner}>
          <div
            className={styles.tooltipBox}
            ref={boxRef}
            style={boundingBoxStyle(selectedIssue)}
          ></div>
        </div>
      </div>
      <div
        className={styles.textTooltipWrapper}
        style={getTooltipWrapperStyle()}
      >
        <div
          className={styles.textTooltipConatiner}
          style={boundingBoxStyle(selectedIssue)}
        >
          <div
            className={styles.descriptionBox}
            style={{
              ...alignDescriptionBox(selectedIssue),
              ...overflowHeightStyle(selectedIssue),
            }}
          >
            <div className={styles.description} ref={descriptionRef}>
              {selectedIssue.description}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default IssuesBoundingBox;
