const DataTabIcon = ({ selected }: { selected: boolean }) => {
  const color = selected ? "#fff" : "#60646E";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7507 1H5.24927C2.6378 1 1 2.85022 1 5.46862V12.5314C1 15.1498 2.63087 17 5.24927 17H12.7464C15.3691 17 16.9991 15.1498 16.9991 12.5314V5.46862C17.0026 2.85022 15.3717 1 12.7507 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89635 6.23333C7.89635 7.11769 7.18019 7.83385 6.29583 7.83385C5.41234 7.83385 4.69531 7.11769 4.69531 6.23333C4.69531 5.34897 5.41234 4.63281 6.29583 4.63281C7.17933 4.63368 7.89549 5.34984 7.89635 6.23333Z"
        fill={color}
      />
      <path
        d="M17 12C17 12.6351 14.4552 9 12.7968 9C11.1376 9 10.1812 12.4892 8.5856 12.4892C6.98997 12.4892 5.54041 10.9115 4.26184 11.9229C2.98327 12.9335 2 15 2 15"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DataTabIcon;
