import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router";

import { State } from "redux/store";
import { fetchHealthStatus } from "redux/Datasets/actions";
import { isCartEnabled, isSharingEnabled } from "redux/SingleDataset/selectors";
import { isAnonymousUser, userLogout } from "redux/authReducer";
import { getUserInfo, getVlVersion } from "redux/User/selectors";

import SelectPlanModal from "views/modals/SelectPlanModal";
import ToastWrapper from "views/components/ToastWrapper";
import Cart from "views/components/Cart";

import Loader from "views/uikit/Loader";
import Sidebar from "./Sidebar";

import { PUBLIC_PAGE_QUERY_PARAMETER_KEY } from "helpers/constants/miscellaneous";
import { getQueryParameter, isFastdupUser } from "helpers/utility/utilities";
import { PUBLIC_DATASETS_PATH } from "helpers/constants/paths";

import styles from "./style.module.scss";
import SharingMenu from "views/components/SharingMenu";

function Layout({
  isCartEnabled,
  userAvatar,
  vlVersion,
  isSharingEnabled,
}: LayoutTypes) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();

  const isPublicPath = location.pathname === PUBLIC_DATASETS_PATH;
  const publicParameter = getQueryParameter(PUBLIC_PAGE_QUERY_PARAMETER_KEY);

  useEffect(() => {
    if (
      isAnonymousUser() &&
      !isPublicPath &&
      !publicParameter &&
      process.env.REACT_APP_IS_AUTHENTICATION_DISABELED === "false" &&
      !isFastdupUser()
    ) {
      userLogout();
    }

    setLoading(false);

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const repeatedHealthCheck = setInterval(() => {
      dispatch(fetchHealthStatus() as any);
    }, 60000);

    dispatch(fetchHealthStatus() as any);

    return () => clearInterval(repeatedHealthCheck);
  }, [dispatch]);

  if (!loading) {
    return (
      <>
        <ToastWrapper />
        <div className={styles.mainWrapper}>
          <div className={styles.innerWrapper}>
            <Sidebar userAvatar={userAvatar} vlVersion={vlVersion} />
            <div className={styles.content}>
              <div className={styles.body}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        {isCartEnabled && <Cart />}
        {isSharingEnabled && <SharingMenu />}
        <SelectPlanModal />
      </>
    );
  }
  return (
    <div className={styles.loadingPage}>
      <Loader />
    </div>
  );
}

const mapStateToProps = (state: State) => {
  return {
    isCartEnabled: isCartEnabled(state),
    userAvatar: getUserInfo(state).userAvatar,
    vlVersion: getVlVersion(state),
    isSharingEnabled: isSharingEnabled(state),
  };
};

export default connect(mapStateToProps)(Layout);

type LayoutTypes = {
  userAvatar?: string;
  vlVersion: string | undefined;
  isCartEnabled: boolean;
  isSharingEnabled: boolean;
};
