import { State } from "redux/store";
import { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { setShowDatasetErrorModal } from "redux/Modals/actions";
import { getShowDatasetErrorModal } from "redux/Modals/selectors";
import XIcon from "assets/icons/XIcon";

import styles from "./style.module.scss";

const DatasetErrorModal = ({
  showDatasetErrorModal,
}: DatasetErrorModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setShowDatasetErrorModal(null));
  };

  const handleOutsideClick = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (!!showDatasetErrorModal) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [showDatasetErrorModal]);

  return !!showDatasetErrorModal ? (
    <div className={styles.overlay}>
      <div className={styles.container} ref={modalRef}>
        <div className={styles.containrHeader}>
          <span className={styles.containrHeaderTitle}>
            dataset creation failed
          </span>
          <div className={styles.crossIcon} onClick={closeModal}>
            <XIcon color="#000" />
          </div>
        </div>

        <div className={styles.containrBody}>
          For more information please contact{" "}
          <span className={styles.link}>
            <a href="mailto:support@visual-layer.com">
              support@visual-layer.com
            </a>
          </span>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.okButton} onClick={closeModal}>
            OK
          </div>
        </div>
      </div>
    </div>
  ) : (
    <noscript />
  );
};

function mapStatesToProps(state: State) {
  return {
    showDatasetErrorModal: getShowDatasetErrorModal(state),
  };
}
export default connect(mapStatesToProps)(DatasetErrorModal);

interface DatasetErrorModalProps {
  showDatasetErrorModal: null | { datasetId: string; errorMessage: string };
}
