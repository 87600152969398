export const PUBLIC_HEADER_MESSAGE =
  "These are public read-only sample datasets. You can switch to your private workspace.";

export const OBJECT_ID_QUERY_PARAMETER = "object_id";

export const PUBLIC_PAGE_QUERY_PARAMETER_KEY = "p";
export const PUBLIC_PAGE_QUERY_PARAMETER_VALUE = "1";

//parameters for data page filters
export const PAGE_PARAMETER = "page";
export const VERTEX_CONTEXT = "vc";
export const VERTEX_ID = "v";
export const VERTEX_TYPE = "vt";
export const ENTITY_TYPE_PARAMETER = "t";
export const SEARCH_TEXT_PARAMETER = "q";
export const LABELS_PARAMETER = "labels";
export const FOLDER_PARAMETER = "folder";
export const ISSUE_TYPE_PARAMETER = "issue-types";
export const USER_TAGS_PARAMETER = "user-tags";
export const GRANULARITY_PARAMETER = "granularity";
export const DATE_PARAMETER = "date";

//parameter to save data page number when moving into cluster page.
export const DATA_PAGE_PARAMETER = "d_page";

export const LOADING_OR_ERROR_CARD_DIMENSION_NUMBER = -99999;

//introduced to allow support of new dataset creation flow without gating. To be changed later.
export const NEW_DATASET_CREATION__QUERY_PARAMETER_KEY = "dc";

export const FREE_ACCOUNT_PLAN_DEFAULT_LIMIT = 10000;

export const enum PipelineType {
  NORMAL = "NORMAL",
  ENRICHED = "ENRICHED",
  BIG = "BIG",
  BIG_ENRICHED = "BIG_ENRICHED",
}
