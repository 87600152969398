// FILTER_NAME_CONSTANTS
export const TAGS_FILTER = "TAGS_FILTER";
export const LABEL_FILTER = "LABEL_FILTER";
export const DATE_RANGE_FILTER = "DATE_RANGE_FILTER";
export const ISSUE_TYPE_FILTER = "ISSUE_TYPE_FILTER";
export const ENTITY_TYPE_FILTER = "ENTITY_TYPE_FILTER";
export const FILE_FOLDER_FILTER = "FILE_FOLDER_FILTER";
export const TEXTUAL_SEARCH = "TEXTUAL_SEARCH";
export const VISUAL_SEARCH = "VISUAL_SEARCH";
export const ACTIONS_CART = "ACTIONS_CART";
export const EXPLORATION_STATS = "EXPLORATION_STATS";
export const CLUSTER_METADATA_SUMMARY = "CLUSTER_METADATA_SUMMARY";
export const DATA_METADATA_SUMMARY = "DATA_METADATA_SUMMARY";
export const FIND_SIMILAR_OBJECT_FILTER = "FIND_SIMILAR_OBJECT";
export const SHOW_DS_VERSION = "SHOW_DS_VERSION";
export const ENABLE_DS_DUPLICATE = "ENABLE_DS_DUPLICATE";

export const AUTO_SUGGEST_RECENT_SEARCH = "AUTO_SUGGEST_RECENT_SEARCH";
export const AUTO_SUGGEST_QUERY = "AUTO_SUGGEST_QUERY";

export const REGION_SELECTION_SIMILARITY = "REGION_SELECTION_SIMILARITY";
export const ASSET_PREVIEW = "ASSET_PREVIEW";
export const EXPORT_ENTITIES = "EXPORT_ENTITIES";

export const DATA_INGESTION_UX = "DATA_INGESTION_UX";
export const DATA_INGESTION_TAB_AVAILABLE = "DATA_INGESTION_TAB_AVAILABLE";

export const USAGE_REPORT = "USAGE_REPORT";
export const EXPLORATION_DEBUG_INFO = "EXPLORATION_DEBUG_INFO";
export const INVENTORY_FILTER = "INVENTORY_FILTER";
export const DATASET_SHARE = "DATASET_SHARE";
