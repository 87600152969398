import SquareImageBox from "views/components/CardBodyBoxes/SquareImageBox";
import StripeImageBox from "views/components/CardBodyBoxes/StripeImageBox";
import ImageBox from "views/components/CardBodyBoxes/ImageBox";
import { DATA_PAGE } from "helpers/constants/pages";
import classNames from "classnames";

import styles from "./style.module.scss";

const DataCardBody = ({
  page,
  plainImages,
  isCardSelected,
  thumbImages,
  isHovering,
  cardType,
  videoURIsForData,
  roundBottom,
  isAssetPreviewEnabled,
  updateAssetData,
  hoverDelay,
}: any) => {
  const images = cardType === "IMAGES" ? plainImages : thumbImages;
  const imageThumb = thumbImages[0];
  const renderDataCardBody = () => {
    if (images.length === 1) {
      const isVideo = videoURIsForData && !!videoURIsForData[0];
      const dynamicUpdate = () => {
        updateAssetData(plainImages[0].id, plainImages[0].src);
      };

      return (
        <ImageBox
          image={images[0].src}
          imageThumb={imageThumb}
          showVideoIcon={isVideo}
          roundBottom={roundBottom}
          updateAssetData={dynamicUpdate}
          showAssetPreview={isAssetPreviewEnabled}
          hoverDelay={hoverDelay}
        />
      );
    } else if (images.length > 1 && images.length < 4) {
      return (
        <StripeImageBox
          plainImages={plainImages}
          thumbImages={thumbImages}
          videoURIs={videoURIsForData}
          roundBottom={roundBottom}
          imageType={cardType === "IMAGES" ? "IMAGE" : "OBJECT"}
          updateAssetData={updateAssetData}
          showAssetPreview={isAssetPreviewEnabled}
          hoverDelay={hoverDelay}
        />
      );
    } else {
      return (
        <SquareImageBox
          plainImages={plainImages}
          thumbImages={thumbImages}
          objectImages={undefined}
          page={page}
          videoURIsForData={videoURIsForData}
          roundBottom={roundBottom}
          cardType={cardType}
          updateAssetData={updateAssetData}
          showAssetPreview={isAssetPreviewEnabled}
          hoverDelay={hoverDelay}
        />
      );
    }
  };

  return (
    <div
      className={classNames({
        [styles.hoveredCardBody]: isHovering,
        [styles.cardBody]: !isHovering,
        [styles.cardBodyNotClickable]: isCardSelected,
      })}
    >
      {renderDataCardBody()}
    </div>
  );
};

const ClusterCardBody = ({
  isHovering,
  plainImage,
  thumbImage,
  boundingBox,
  isObjectCluster,
  videoURIForCluster,
  roundBottom,
  isAssetPreviewEnabled,
  updateAssetData,
  hoverDelay,
}: any) => {
  const dynamicUpdate = () => {
    updateAssetData(plainImage.id, plainImage.src);
  };

  const renderClusterCardBody = () => {
    return (
      <ImageBox
        image={plainImage.src}
        imageThumb={thumbImage.src}
        isObjectCluster={isObjectCluster}
        boundingBox={boundingBox}
        showVideoIcon={!!videoURIForCluster}
        roundBottom={roundBottom}
        updateAssetData={dynamicUpdate}
        showAssetPreview={isAssetPreviewEnabled}
        hoverDelay={hoverDelay}
        // showZoomableImage={true}
        // showZoomableObject={true}
      />
    );
    // }
  };

  return (
    <div
      className={classNames({
        [styles.hoveredCardBody]: isHovering,
        [styles.cardBody]: !isHovering,
      })}
    >
      {renderClusterCardBody()}
    </div>
  );
};

const DynamicCardBody = ({
  page,
  plainImages,
  isCardSelected,
  thumbImages,
  isHovering,
  cardType,
  plainImage,
  thumbImage,
  isObjectCluster,
  boundingBox,
  videoURIForCluster,
  videoURIsForData,
  roundBottom,
  isAssetPreviewEnabled,
  updateAssetData,
  hoverDelay,
}: any) => {
  return page === DATA_PAGE ? (
    <DataCardBody
      page={page}
      cardType={cardType}
      isCardSelected={isCardSelected}
      isHovering={isHovering}
      plainImages={plainImages}
      thumbImages={thumbImages}
      videoURIsForData={videoURIsForData}
      roundBottom={roundBottom}
      isAssetPreviewEnabled={isAssetPreviewEnabled}
      updateAssetData={updateAssetData}
      hoverDelay={hoverDelay}
    />
  ) : (
    <ClusterCardBody
      isHovering={isHovering}
      plainImage={plainImage}
      thumbImage={thumbImage}
      boundingBox={boundingBox}
      isObjectCluster={isObjectCluster}
      videoURIForCluster={videoURIForCluster}
      roundBottom={roundBottom}
      isAssetPreviewEnabled={isAssetPreviewEnabled}
      updateAssetData={updateAssetData}
      hoverDelay={hoverDelay}
    />
  );
};

export default DynamicCardBody;
