const FrameIcon = ({ color = "#fff" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M14.5159 0.297852L0.407227 0.297852L0.407227 14.4065L14.5159 14.4065L14.5159 0.297852ZM8.25166 1.85921L9.81302 1.85921V3.42057H8.25166V1.85921ZM5.11012 1.85921L6.67148 1.85921L6.67148 3.42057H5.11012L5.11012 1.85921ZM1.96859 1.85921L3.52995 1.85921V3.42057H1.96859L1.96859 1.85921ZM3.54876 12.8452H1.9874L1.9874 11.2838H3.54876L3.54876 12.8452ZM6.67148 12.8452L5.11012 12.8452V11.2838L6.67148 11.2838V12.8452ZM9.81302 12.8452H8.25166V11.2838H9.81302V12.8452ZM12.9546 12.8452H11.3932V11.2838H12.9546V12.8452ZM12.9546 9.70364L1.96859 9.70364L1.96859 5.00075L12.9357 5.00075L12.9357 9.70364H12.9546ZM12.9546 3.43939L11.3932 3.43939V1.87802L12.9546 1.87802V3.43939Z"
        fill={color}
      />
    </svg>
  );
};

export default FrameIcon;
