import { State } from "redux/store";
import { connect } from "react-redux";
import { ReactNode, useEffect, useRef, useState } from "react";

import StepTile from "../StepTile";
import IndexingBanner from "./IndexingBanner";
import IndexingIcon from "assets/icons/IndexingIcon";
import PulsingDropdownIcon from "../PulsingDropdownIcon";
import {
  getIndexingStatus,
  getNewDatasetId,
} from "redux/CreateDataset/selectors";

import { StepTileProgressStatus } from "types";
import styles from "./style.module.scss";

const IndexingStep = ({ indexingStatus, newDatasetId }: IndexingStepProps) => {
  const [showBanner, setShowBanner] = useState(false);
  const indexingComplete = indexingStatus === StepTileProgressStatus.COMPLETE;
  const indexingLoading = indexingStatus === StepTileProgressStatus.IN_PROGRESS;

  const bannerRef = useRef<HTMLDivElement>(null);
  const actionButtonRef = useRef<HTMLDivElement>(null);

  const toggleShowBanner = () => {
    setShowBanner(!showBanner);
  };

  const handleOutsideClick = (event: any) => {
    if (
      indexingComplete &&
      bannerRef.current &&
      !bannerRef.current.contains(event.target)
    ) {
      setShowBanner(false);
    }
  };

  const renderHeadIcon = (icon: ReactNode, loading?: boolean): ReactNode => {
    if (loading) {
      return <div className={styles.spinner}></div>;
    } else {
      return <div className={styles.flexIcon}>{icon}</div>;
    }
  };

  const renderActionIcon = () => {
    if (!showBanner && (indexingLoading || indexingComplete)) {
      return (
        <PulsingDropdownIcon
          showPulse={!showBanner}
          isDropdownOpen={showBanner}
          toggleDropdown={toggleShowBanner}
          containerRef={actionButtonRef}
          showGreenPulse={indexingComplete}
        />
      );
    }
  };

  useEffect(() => {
    if (indexingComplete || indexingLoading) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
    //eslint-disable-next-line
  }, [indexingStatus]);

  return (
    <div className={styles.indexingContainer}>
      <StepTile
        title="Visual Layer Indexing"
        status={indexingStatus}
        headIcon={renderHeadIcon(
          <IndexingIcon
            selected={indexingStatus !== StepTileProgressStatus.DISABLED}
          />
        )}
        actionIcon={renderActionIcon()}
        handleOutsideClick={handleOutsideClick}
        isContentShown={showBanner}
        isIndexingTile={true}
      />

      <IndexingBanner
        bannerRef={bannerRef}
        newDatasetId={newDatasetId}
        showBanner={showBanner}
        toggleShowBanner={toggleShowBanner}
        progressStatus={indexingStatus}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    indexingStatus: getIndexingStatus(state),
    newDatasetId: getNewDatasetId(state),
  };
};

export default connect(mapStateToProps)(IndexingStep);

interface IndexingStepProps {
  indexingStatus: StepTileProgressStatus;
  newDatasetId: string;
}
