export default function UploadIcon({ selected }: { selected: boolean }) {
  return selected ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
    >
      <path
        d="M20.3618 12.2571C19.5147 13.1113 18.1436 13.1172 17.2936 12.2512L12.6681 7.53103L12.6681 21.798C12.6681 23.0128 11.6959 24 10.4995 24C9.30305 24 8.3337 23.0128 8.3337 21.798L8.3337 7.53103L3.70238 12.2512C2.8582 13.1172 1.48423 13.1113 0.63714 12.2571C-0.209948 11.403 -0.212859 10.0079 0.631318 9.14483L8.96247 0.647291C8.96538 0.644335 8.96538 0.644335 8.96538 0.644335C9.16333 0.44335 9.40203 0.280788 9.66401 0.171429C9.926 0.0591133 10.2113 0 10.4995 0C10.7906 0 11.0758 0.0591133 11.3378 0.171429C11.5969 0.280788 11.8327 0.44335 12.0306 0.641379C12.0335 0.644335 12.0364 0.644335 12.0364 0.647291L20.3676 9.14483C21.2118 10.0079 21.2118 11.403 20.3618 12.2571Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
    >
      <path
        d="M21.3618 13.2571C20.5147 14.1113 19.1436 14.1172 18.2936 13.2512L13.6681 8.53103L13.6681 22.798C13.6681 24.0128 12.6959 25 11.4995 25C10.3031 25 9.3337 24.0128 9.3337 22.798L9.3337 8.53103L4.70238 13.2512C3.8582 14.1172 2.48423 14.1113 1.63714 13.2571C0.790052 12.403 0.787141 11.0079 1.63132 10.1448L9.96247 1.64729C9.96538 1.64433 9.96538 1.64433 9.96538 1.64433C10.1633 1.44335 10.402 1.28079 10.664 1.17143C10.926 1.05911 11.2113 1 11.4995 1C11.7906 1 12.0758 1.05911 12.3378 1.17143C12.5969 1.28079 12.8327 1.44335 13.0306 1.64138C13.0335 1.64433 13.0364 1.64433 13.0364 1.64729L21.3676 10.1448C22.2118 11.0079 22.2118 12.403 21.3618 13.2571Z"
        fill="url(#paint0_linear_5810_17371)"
        fillOpacity="0.24"
      />
      <path
        d="M21.3618 13.2571C20.5147 14.1113 19.1436 14.1172 18.2936 13.2512L13.6681 8.53103L13.6681 22.798C13.6681 24.0128 12.6959 25 11.4995 25C10.3031 25 9.3337 24.0128 9.3337 22.798L9.3337 8.53103L4.70238 13.2512C3.8582 14.1172 2.48423 14.1113 1.63714 13.2571C0.790052 12.403 0.787141 11.0079 1.63132 10.1448L9.96247 1.64729C9.96538 1.64433 9.96538 1.64433 9.96538 1.64433C10.1633 1.44335 10.402 1.28079 10.664 1.17143C10.926 1.05911 11.2113 1 11.4995 1C11.7906 1 12.0758 1.05911 12.3378 1.17143C12.5969 1.28079 12.8327 1.44335 13.0306 1.64138C13.0335 1.64433 13.0364 1.64433 13.0364 1.64729L21.3676 10.1448C22.2118 11.0079 22.2118 12.403 21.3618 13.2571"
        stroke="#60646E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5810_17371"
          x1="11.5"
          y1="1"
          x2="11.5"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}
