const PREFIX = "Datasets";

export const ERROR_TEXT = `${PREFIX}_ERROR_TEXT`;
export const IS_CONTENT_LOADING = `${PREFIX}_IS_CONTENT_LOADING`;
export const IS_DISCLAIMER_SHOWN = `${PREFIX}IS_DISCLAIMER_SHOWN`;
export const IS_ERROR = `${PREFIX}_IS_ERROR`;
export const IS_LOADING = `${PREFIX}_IS_LOADING`;
export const LIMIT_ERROR = `${PREFIX}_LIMIT_ERROR`;
export const SET_BREADCRUMBS = `${PREFIX}_SET_BREADCRUMBS`;
export const SET_DATASETS = `${PREFIX}_SET_DATASETS`;
export const SET_DATASETS_SORTING_VALUE = `${PREFIX}_SET_DATASETS_SORTING_VALUE`;
export const SET_DATASET_ID = `${PREFIX}_SET_DATASET_ID`;
export const SET_DATASET_PROGRESS = `${PREFIX}_SET_DATASET_PROGRESS`;
export const SET_DELETE_MODAL_ID = `${PREFIX}_SET_DELETE_MODAL_ID`;
export const SET_HEALTH_STATUS = `${PREFIX}_SET_HEALTH_STATUS`;
export const SET_PUBLIC_PARAMETER = `${PREFIX}_SET_PUBLIC_PARAMETER`;
export const SET_SAMPLE_DATA = `${PREFIX}_SET_SAMPLE_DATA`;
export const SET_SAMPLE_DATASET = `${PREFIX}_SET_SAMPLE_DATASET`;
export const SET_SELECTED_SAMPLE_DATASET = `${PREFIX}_SET_SELECTED_SAMPLE_DATASET`;
export const SET_SELECTED_EXPORT_IDS = `${PREFIX}_SET_SELECTED_EXPORT_IDS`;
export const SET_SELECTED_IMAGE_COUNT = `${PREFIX}_SET_SELECTED_IMAGE_COUNT`;
export const CLEAR_EXPORT_DATA = `${PREFIX}_CLEAR_EXPORT_DATA`;
export const SET_DUPLICATE_DATASET = `${PREFIX}_SET_DUPLICATE_DATASET`;
