import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { ReactNode, RefObject } from "react";
import styles from "./style.module.scss";
import classNames from "classnames";

const PulsingDropdownIcon = ({
  containerRef,
  showPulse,
  toggleDropdown,
  isDropdownOpen,
  children,
  showGreenPulse,
}: PulsingDropdownIconProps) => {
  const pulseClassnames = classNames(styles.pulse, {
    [styles.greenPulse]: showGreenPulse,
  });

  return (
    <div
      ref={containerRef}
      className={styles.dropdownIconContainer}
      onClick={toggleDropdown}
    >
      {showPulse && <div className={pulseClassnames}></div>}
      {isDropdownOpen ? (
        <BiChevronUp
          size={"1.55em"}
          color={showGreenPulse ? "#34a853" : "#fff"}
        />
      ) : (
        <BiChevronDown
          size={"1.55em"}
          color={showGreenPulse ? "#34a853" : "#fff"}
        />
      )}
      {isDropdownOpen && children}
    </div>
  );
};

export default PulsingDropdownIcon;

interface PulsingDropdownIconProps {
  showPulse: boolean;
  children?: ReactNode;
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
  containerRef: RefObject<HTMLDivElement>;
  showGreenPulse?: boolean;
}
