import { AnnotatedBoundingBox } from "extractedBackendTypes";

export type ItemCenter = {
  x: number;
  y: number;
};

export type User = {
  origin?: string;
  session?: string;
};

export enum TabName {
  Explorer = "Datasets",
  Debug = "Debug",
}

export enum TabBasePath {
  //Explorer = "/datasets", - we still need to make the datasetsets tab unclickable
  Explorer = "/clusters",
  Debug = "/debug",
}

export type Tab = {
  name: TabName;
  path: string;
};

export enum Status {
  Init = "Init",
  Loading = "Loading",
  Loaded = "Loaded",
}

export type TreeNode = {
  components: Array<TreeNode>;
  name?: string;
  component_id: string;
  num_images: number;
  preview?: string;
  previews?: Array<string>;
  src?: string;
  type: string;
};

export type Tree = {
  status: Status;
  root?: TreeNode;
  breadcrumbs: Array<string>;
};

export type ContentLoadProps = {
  radius?: string;
};

export type FilterOption = {
  id: number;
  text: string;
  selected?: boolean;
};

export type FilteredCounts = {
  total: number;
  filtered: number;
};

export type GlobalCounts = {
  clusters: FilteredCounts;
  images: FilteredCounts;
  objects: FilteredCounts;
};

export type ExplorationStats = {
  globalCounts: GlobalCounts;
  countsByClusterId: { [key: string]: FilteredCounts };
};

export type TimeRange = {
  start: number;
  end: number;
};

export type FilterBehavior =
  | "SHOW"
  | "HIDE"
  | "GREY_OUT"
  | "MULTI_SELECT"
  | "TOGGLE"
  | null;

export type MetadataBarTypes =
  | "labels"
  | "file-display"
  | "user-tags"
  | "issue-types";
export type MetadataSectionTypes =
  | "vl-enrichment"
  | "metadata"
  | "user-tags"
  | "quality"
  | null;

export type FilterItemCounts = {
  title: string;
  count: number;
};

export type FilterItemCountsWithSelect = {
  title: string;
  count: number;
  selected: boolean;
  ratio?: number;
};

export type RawMetadataSummaryData = {
  showVLEnrichment: boolean;
  videoData: Array<FilterItemCounts>;
  imageLabels: Array<FilterItemCounts>;
  objectLabels: Array<FilterItemCounts>;
  userTags: Array<FilterItemCounts>;
};

export type MetadataSummaryData = {
  showVLEnrichment: boolean;
  videoData: Array<FilterItemCountsWithSelect>;
  labels: Array<FilterItemCountsWithSelect>;
  userTags: Array<FilterItemCountsWithSelect>;
  issueTypes: Array<FilterItemCountsWithSelect>;
  vlLabels: Array<FilterItemCountsWithSelect>;
};

export type EventTarget = "Image" | "Object" | "Cluster" | "Video";

export type ContactUsComponentType =
  | "textual_search"
  | "vl_enrichment"
  | "create_newdataset_private_s3_bucket"
  | "create_newdataset_google_drive"
  | "create_newdataset_dropbox"
  | "fastdup_exploration"
  | "upgrade_plan"
  | "";

export type SetStateFunction<T> = React.Dispatch<React.SetStateAction<T>>;

export type UserInfo = {
  userAvatar?: string;
  isInternalUser: boolean;
  email?: string;
  userId?: string;
};

export type singleImageObjectType = {
  boundingBox: number[];
  color: string;
  id: string;
  name: string;
  selected: boolean;
  source: string;
};

export type hoveredAssetDataType = {
  id: string;
  dimensions: NaturalDimensions;
  error: boolean;
};

export type Label = {
  categoryName: string;
  labels: any[];
  color: string;
  type: string;
  source: string;
};

export type TreeData = {
  key: string;
  title: string;
  children?: TreeData[];
  isLeaf?: boolean;
};

export type NaturalDimensions = { naturalWidth: number; naturalHeight: number };

// Upload - for user uploaded image search or a region of interest
// Cluster - for find similar on a cluster
// Media - for find similar images or objects
export type VertexType = "UPLOAD" | "CLUSTER" | "MEDIA";

export enum StepTileProgressStatus {
  ACTIVE = "ACTIVE",
  COMPLETE = "COMPLETE",
  DISABLED = "DISABLED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum DataSources {
  FOLDER = "FOLDER",
  ZIP = "ZIP",
  S3 = "S3",
  G_DRIVE = "G_DRIVE",
  PVT_S3_BUCKET = "PVT_S3_BUCKET",
  DROPBOX = "DROPBOX",
}

export interface DataPreview {
  file_name?: string;
  thumbnail: string;
  object_annotations?: AnnotatedBoundingBox[];
  image_annotations?: string[];
}

export enum TabTypes {
  DATA_EXPLORATION = "DATA_EXPLORATION",
  DATA_INGESTION = "DATA_INGESTION",
  ALL = "ALL",
}

export enum DatasetStatusEnum {
  UPLOADING = "UPLOADING",
  INDEXING = "INDEXING",
  FATAL_ERROR = "FATAL_ERROR",
  INITIALIZING = "INITIALIZING",
  READY = "READY",
}

export type LastUsedData = {
  s3Url: string;
  folderName: string;
  annotationFiles: string[];
};

export enum DatasetTypeEnum {
  All = "All",
  Public = "Public",
  Private = "Private",
}

export enum FileType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  UNKNOWN = "UNKNOWN",
}

export type FilesUploadSize = {
  bytes: number;
  length: number;
};

export type ActivityLogShowModalType = {
  show: boolean;
  title: string;
  reason: string;
  severity: "ERROR" | "WARNING" | "";
};

export type CopyURLTypes = "/create" | "/data" | null;

export type UserQuota = {
  accountType: string;
  totalQuota: number;
  usedQuota: number;
  availableQuota: number;
  email: string | null;
};

export type QuotaLimitModalData = {
  show: boolean;
  attemptedImageCount: number;
  allowUpload?: boolean;
};

export type SelectPlanModalData = {
  show: boolean;
  calledFrom: "user_info" | "limit_modal" | "pricing_modal";
};

export type DatasetSortValue = "date" | "size" | "name";

export interface InventoryFilterData {
  searchValue: null | string;
  datasetType: DatasetTypeEnum;
  datasetStatus: { id: DatasetStatusEnum; selected: boolean }[];
  sortValue: DatasetSortValue;
}

export type DatasetFilterIconType =
  | "Ready"
  | "Resume"
  | "Failed"
  | "Indexing"
  | "Uploading"
  | "Private"
  | "Public"
  | "Date "
  | "Size";
