import { useEffect, useState } from "react";
import { DATA_PAGE } from "helpers/constants/pages";

import DataList from "views/pages/DataPage/CardsView/DataList";
import MetadataSummary from "views/components/MetadataSummary";
import ImageDropBox from "views/pages/DataPage/CardsView/ImageDropBox";
import DataPageFilters from "views/pages/DataPage/CardsView/DataPageFilters";

import styles from "./style.module.scss";

const CardsView = ({
  clusters,
  dynamicPage,
  navigationCluster,
  isCardListLoading,
  navigationClusterLoader,
  metadataSummaryEnabled,
  isImageUploading,
}: any) => {
  const [isMetadataSummaryOpen, setIsMetadataSummaryOpen] = useState(true);

  const showMetadataSummary = isMetadataSummaryOpen && metadataSummaryEnabled;

  const dynamicLoader =
    dynamicPage === DATA_PAGE ? isCardListLoading : navigationClusterLoader;

  useEffect(() => {
    if (isImageUploading && isMetadataSummaryOpen) {
      setIsMetadataSummaryOpen(false);
    } else if (!isImageUploading && !isMetadataSummaryOpen) {
      setIsMetadataSummaryOpen(true);
    }
    //eslint-disable-next-line
  }, [isImageUploading]);

  return (
    <div
      className={styles.mainContainer}
      style={isCardListLoading ? { pointerEvents: "none" } : undefined}
    >
      <DataPageFilters
        toggleMedataSummary={() => {
          setIsMetadataSummaryOpen(!isMetadataSummaryOpen);
        }}
        isMetadataSummaryOpen={isMetadataSummaryOpen}
      />
      <div className={styles.innerBody}>
        {isImageUploading ? (
          <ImageDropBox />
        ) : (
          <DataList
            page={dynamicPage}
            clusters={clusters}
            navigationCluster={navigationCluster}
            isContentLoading={dynamicLoader}
            showMetadataSummary={showMetadataSummary}
          />
        )}

        <MetadataSummary
          dynamicPage={dynamicPage}
          showMetadataSummary={showMetadataSummary}
        />
      </div>
    </div>
  );
};
export default CardsView;
