import { SET_SELECTED_SECTION } from "./constants";

const INITIAL_STATE = {
  selectedSection: null,
};
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_SELECTED_SECTION:
      return { ...state, selectedSection: action.data };

    default:
      return state;
  }
};

export default reducer;
