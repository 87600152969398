const GoogleDriveIcon = ({ color = "#fff" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        d="M19.4231 10.5499L13.3336 0H6.66663L12.7566 10.5499H19.4231ZM6.08917 1.00043L0 11.5494L3.33356 17.3242L9.42267 6.7749L6.08917 1.00043ZM7.8219 11.5494L4.48877 17.3242H16.6666L20 11.5494L7.8219 11.5494Z"
        fill={color}
      />
    </svg>
  );
};

export default GoogleDriveIcon;
