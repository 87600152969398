import { useState } from "react";
import Lock from "assets/img/lock.svg";

import styles from "./style.module.scss";

const FetchErrorCard = () => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className={styles.fetchErrorBox}>
      <div className={styles.iconContainer}>
        <img
          src={Lock}
          style={!loaded ? { display: "none" } : undefined}
          alt="Access icon"
          onLoad={() => setLoaded(true)}
        />
      </div>

      <div className={styles.textContainer}>
        <div className={styles.title}>Failed to access entity</div>
        <span className={styles.description}>
          You are trying to access an entity that doesn’t exist or which you
          don’t have permissions to access. For more info please contact
          support@visual-layer.com.
        </span>
      </div>
    </div>
  );
};

export default FetchErrorCard;
