import { useState } from "react";
import Content from "../Content";
import { IoMdPricetag } from "react-icons/io";
import VLAccordian from "views/components/Accordian";

import styles from "../style.module.scss";
import { FilterItemCountsWithSelect } from "types";

function NoUserTags() {
  return (
    <div className={styles.noDataWrapper}>
      <div className={styles.noData}>
        Currently no User Tags available in this cluster
      </div>
    </div>
  );
}

const UserTagsSectionContent = ({
  userTags,
  sectionHeight,
}: {
  userTags: Array<FilterItemCountsWithSelect>;
  sectionHeight: any;
}) => {
  const [selectedID, setSelectedID] = useState(null);
  return (
    <div
      className={styles.section}
      style={{ height: !!sectionHeight ? sectionHeight : "max-content" }}
    >
      <Content
        title={"User Tags"}
        totalCount={userTags.length}
        type={"user-tags"}
        data={userTags}
        sectionType={"user-tags"}
        id={"user-tags-1"}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
      />
    </div>
  );
};

const UserTagsSection = ({
  userTags,
  userTagsRef,
  userTagsCategory,
  sectionHeight,
  isLoading,
}: {
  userTags: Array<FilterItemCountsWithSelect>;
  userTagsRef: any;
  userTagsCategory: any;
  sectionHeight: any;
  isLoading: boolean;
}) => {
  const handleDisplay = () => {
    if (isLoading) {
      return <div className={styles.loadingBox}>Loading...</div>;
    } else if (userTags.length > 0) {
      return (
        <UserTagsSectionContent
          userTags={userTags}
          sectionHeight={sectionHeight}
        />
      );
    } else return <NoUserTags />;
  };
  return (
    <div className={styles.userTagsWrapper} ref={userTagsRef}>
      <VLAccordian
        title="User Tags"
        icon={<IoMdPricetag color="#373C4B" size={"20px"} />}
        childrenBgColor={"#151928"}
        roundCorners="10px"
        borderRadius="10px 10px 0 0"
        padding="0 15px 0 13px"
        height="47px"
        category={userTagsCategory}
        initiallyClosed={true}
      >
        {handleDisplay()}
      </VLAccordian>
    </div>
  );
};

export default UserTagsSection;
