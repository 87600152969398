function VideoPlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
    >
      <path
        d="M1.8 14C1.305 14 0.88125 13.8286 0.52875 13.4859C0.17625 13.1432 0 12.7312 0 12.25V1.75C0 1.26875 0.17625 0.856771 0.52875 0.514063C0.88125 0.171354 1.305 0 1.8 0H16.2C16.695 0 17.1187 0.171354 17.4713 0.514063C17.8238 0.856771 18 1.26875 18 1.75V12.25C18 12.7312 17.8238 13.1432 17.4713 13.4859C17.1187 13.8286 16.695 14 16.2 14H1.8ZM1.8 12.25H16.2V1.75H1.8V12.25Z"
        fill="#575B67"
      />
      <path
        d="M11.2854 7.42875L7.75725 9.54565C7.42399 9.74561 7 9.50555 7 9.1169V4.8831C7 4.49445 7.42399 4.25439 7.75725 4.45435L11.2854 6.57125C11.6091 6.76546 11.6091 7.23455 11.2854 7.42875Z"
        fill="#575B67"
      />
    </svg>
  );
}

export default VideoPlayIcon;
