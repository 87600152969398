import Cookies from "js-cookie";

import { User } from "types";

export function selectUser(state?: any): User | null {
  let session = Cookies.get("SESSION");
  if (typeof session !== "undefined") {
    return { session: session, origin: Cookies.get("user_origin") };
  }

  return null;
}

export function isAnonymousUser(): Boolean {
  const cookieValue = Cookies.get("is_anonymous_user");
  return cookieValue !== undefined && cookieValue.toLowerCase() === "true";
}

export function userLogout() {
  try {
    Cookies.remove("user_token");
    Cookies.remove("user_origin");
    Cookies.remove("is_anonymous_user");
    Cookies.remove("SESSION");
    localStorage.removeItem("vl_user_info");
    window.location.href = "/login";
  } catch (e) {
    console.log(e);
  }
}
