import { calculateAspectRatio } from "helpers/utility/utilities";
import { AnnotatedBoundingBox } from "extractedBackendTypes";
import { useEffect, useRef, useState } from "react";

import styles from "./style.module.scss";

const ObjectPreview = ({ src, boundingBox }: ObjectPreviewProps) => {
  const [loading, setLoading] = useState(true);

  const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({
    height: 0,
    width: 0,
  });

  const [boundingBoxContainerDimensions, setBoundingBoxContainerDimensions] =
    useState<ImageDimensions>({
      height: 0,
      width: 0,
    });

  const objectImageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateNewImageDimensions = () => {
    if (objectImageRef.current) {
      let newValues = calculateAspectRatio(objectImageRef.current);
      setBoundingBoxContainerDimensions({
        height: newValues[0],
        width: newValues[1],
      });
    }
  };

  const handleLoad = () => {
    setLoading(false);
    calculateNewImageDimensions();
    setImageDimensions({
      height: objectImageRef.current?.naturalHeight as number,
      width: objectImageRef.current?.naturalWidth as number,
    });
  };

  const boundingBoxContainerStyle = () => {
    if (
      boundingBoxContainerDimensions.height > 0 &&
      boundingBoxContainerDimensions.width > 0
    ) {
      return {
        height: `${boundingBoxContainerDimensions.height}px`,
        width: `${boundingBoxContainerDimensions.width}px`,
      };
    } else {
      return undefined;
    }
  };

  const boundingBoxStyle = (item: AnnotatedBoundingBox) => {
    let heightRatio = 100;
    let widthRatio = 100;

    if (imageDimensions.height > 0 && imageDimensions.width > 0) {
      heightRatio =
        (boundingBoxContainerDimensions.height / imageDimensions.height) * 100;
      widthRatio =
        (boundingBoxContainerDimensions.width / imageDimensions.width) * 100;
    }

    return {
      left: (item.col_x! * widthRatio) / 100,
      top: (item.row_y! * heightRatio) / 100,
      width: (item.width! * widthRatio) / 100,
      height: (item.height! * heightRatio) / 100,
      display: loading ? "none" : "block",
    };
  };

  useEffect(() => {
    calculateNewImageDimensions();
    const objectImageElement = objectImageRef.current;
    if (!objectImageElement) return;

    const resizeObserver = new ResizeObserver(calculateNewImageDimensions);
    resizeObserver.observe(objectImageElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [objectImageRef, src]);

  return (
    <div className={styles.objectImageWrapper} ref={containerRef}>
      <div
        className={styles.boundingBoxContainer}
        style={boundingBoxContainerStyle()}
      >
        {boundingBox.map((Item: AnnotatedBoundingBox, index: number) => (
          <div
            key={index}
            className={styles.boundingBox}
            style={boundingBoxStyle(Item)}
          ></div>
        ))}
      </div>

      <img
        src={src}
        ref={objectImageRef}
        className={styles.objectImage}
        alt="preview"
        loading="lazy"
        onLoad={handleLoad}
      />
    </div>
  );
};

export default ObjectPreview;

interface ObjectPreviewProps {
  src: string;
  boundingBox: AnnotatedBoundingBox[];
}

type ImageDimensions = {
  height: number;
  width: number;
};
