import VideoIndicationIcon from "views/uikit/VideoIndicationIcon";

import { DATA_PAGE } from "helpers/constants/pages";
import useHoverDelay from "helpers/utility/utilities";
import placeholderImg from "assets/img/blank.png";
import { useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./style.module.scss";
import ImageWithFallback from "views/components/ImageWithFallback";

const SquaredImage = ({
  plainImage,
  thumbImage,
  showVideoIcon,
  updateAssetData,
  showAssetPreview,
  hoverDelay,
}: any) => {
  const [loading, setLoading] = useState(true);

  const showIcon = !loading && showVideoIcon;

  const tableColumnClassNames = classNames(styles.tableColumn, {
    [styles.tableColumnForAssetPreview]: showAssetPreview,
  });

  const { handleMouseEnter, handleMouseLeave } = useHoverDelay(
    updateAssetData,
    hoverDelay
  );

  return (
    <div
      className={tableColumnClassNames}
      onMouseOver={() => showAssetPreview && handleMouseEnter()}
      onMouseLeave={() => showAssetPreview && handleMouseLeave()}
    >
      {showIcon && <VideoIndicationIcon />}
      <ImageWithFallback
        thumbImage={thumbImage}
        plainImage={plainImage}
        className={styles.singleSquareImage}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

const SingleBoxColumn = ({ key, item }: SingleBoxColumnProps) => {
  return (
    <div key={key} className={styles.singleBoxColumn}>
      <img
        className={styles.singleBoxImage}
        src={item}
        loading="lazy"
        alt="No Img"
      />
    </div>
  );
};

const SquareImageBox = ({
  plainImages,
  objectImages,
  thumbImages,
  page,
  videoURIsForData,
  roundBottom,
  cardType,
  showAssetPreview,
  updateAssetData,
  hoverDelay,
}: any) => {
  const [imageCount, setImageCount] = useState(0);

  //set thumb images if imageType exists and is OBJECT
  const images = cardType && cardType === "OBJECTS" ? thumbImages : plainImages;

  const squareImagesContainerClassnames = classNames(
    styles.squareImagesContainer,
    {
      [styles.roundBottom]: roundBottom,
    }
  );

  const gridStyle = () => {
    if (imageCount)
      return {
        gridTemplateColumns: `repeat(${imageCount}, minmax(1px, 1fr))`,
        gridTemplateRows: `repeat(${imageCount}, minmax(1px, 1fr))`,
      };
  };

  const mergedStyle: any = {
    ...gridStyle(),
    visibility: !!imageCount ? "visible" : "hidden",
  };

  const dynamicUpdateAssetData = (index: number) => {
    const source = plainImages[index].src;
    updateAssetData(plainImages[index].id, source);
  };

  useEffect(() => {
    if (images) {
      if (images.length >= 25) {
        if (page === DATA_PAGE) {
          setImageCount(4);
        } else {
          setImageCount(5);
        }
      } else if (images.length >= 16 && images.length < 25) {
        setImageCount(4);
      } else if (images.length >= 9 && images.length < 16) {
        setImageCount(3);
      } else {
        setImageCount(2);
      }
    }
    if (objectImages) {
      if (objectImages.length >= 25) {
        setImageCount(5);
      } else if (objectImages.length >= 16 && objectImages.length < 25) {
        setImageCount(4);
      } else if (objectImages.length >= 9 && objectImages.length < 16) {
        setImageCount(3);
      } else {
        setImageCount(2);
      }
    }
    // eslint-disable-next-line
  }, [images, objectImages]);

  return (
    <div className={squareImagesContainerClassnames} style={mergedStyle}>
      {plainImages?.length > 0
        ? plainImages.map((plainImage: any, i: number) => {
            const thumbImage = thumbImages && thumbImages[i];
            return (
              i < imageCount * imageCount && (
                <SquaredImage
                  key={i}
                  plainImage={plainImage}
                  thumbImage={thumbImage}
                  showVideoIcon={videoURIsForData && !!videoURIsForData[i]}
                  updateAssetData={() => dynamicUpdateAssetData(i)}
                  showAssetPreview={showAssetPreview}
                  hoverDelay={hoverDelay}
                />
              )
            );
          })
        : objectImages?.length > 0
        ? objectImages.map(
            (item: any, i: number) =>
              i < imageCount * imageCount && (
                <SingleBoxColumn key={i} item={item} />
              )
          )
        : null}

      {/* placeholders */}
      {images?.length > 0
        ? images?.length < imageCount * imageCount &&
          [...Array(imageCount * imageCount - images.length)].map(
            (item: any, i: number) => (
              <div key={i} className={styles.tableColumn}>
                <img
                  className={styles.singleSquareImage}
                  src={placeholderImg}
                  loading="lazy"
                  alt="No Img"
                />
              </div>
            )
          )
        : objectImages?.length > 0
        ? objectImages?.length < imageCount * imageCount &&
          [...Array(imageCount * imageCount - objectImages.length)].map(
            (item: any, i: number) => (
              <div key={i} className={styles.tableColumn}>
                <img
                  className={styles.singleSquareImage}
                  src={placeholderImg}
                  loading="lazy"
                  alt="No Img"
                />
              </div>
            )
          )
        : null}
    </div>
  );
};

export default SquareImageBox;

interface SingleBoxColumnProps {
  key: number;
  item: string;
}
