import { getShowInternalErrorModal } from "redux/Modals/selectors";
import { setShowInternalErrorModal } from "redux/Modals/actions";
import DangerCircleIcon from "assets/icons/DangerCircleIcon";
import { connect, useDispatch } from "react-redux";
import { State } from "redux/store";

import styles from "./style.module.scss";

const InternalServerErrorModal = ({
  showModal,
}: InternalServerErrorModalProps) => {
  const dispatch = useDispatch();

  const handleCloseClick = () => {
    dispatch(setShowInternalErrorModal(false));
  };

  return showModal ? (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.containrHeader}>
          <DangerCircleIcon variant="filled" color="#D6493E" />
          <span className={styles.containrHeaderTitle}>
            Internal Server Error
          </span>
        </div>

        <div className={styles.containrBody}>
          For more information please contact{" "}
          <a href="mailto:support@visual-layer.com">support@visual-layer.com</a>
        </div>
        <div className={styles.goBackBtn} onClick={handleCloseClick}>
          Close
        </div>
      </div>
    </div>
  ) : (
    <noscript />
  );
};

const mapStateToProps = (state: State) => {
  return { showModal: getShowInternalErrorModal(state) };
};

export default connect(mapStateToProps)(InternalServerErrorModal);

interface InternalServerErrorModalProps {
  showModal: boolean;
}
