import { createSelector } from "@reduxjs/toolkit";
import { getUserConfig } from "redux/User/selectors";
import { isFeatureEnabled } from "helpers/utility/utilities";
import {
  DATA_INGESTION_UX,
  INVENTORY_FILTER,
  USAGE_REPORT,
} from "helpers/constants/filters";

const state = (state: any) => state.datasets;

export const getLoading = createSelector(state, (state: any) => {
  return state.isLoading;
});

export const getContentLoading = createSelector(state, (state: any) => {
  return state.isContentLoading;
});

export const getDatasets = createSelector(state, (state: any) => {
  return state.datasets.filter(
    (dataset: any) => dataset.similarity_data || dataset.status !== "READY" // filter out old datasets
  );
});

export const getSampleData = createSelector(state, (state: any) => {
  return state.sampleData;
});

export const getDatasetId = createSelector(state, (state: any) => {
  return state.datasetId;
});

export const getSelectedSampleDataset = createSelector(state, (state: any) => {
  return state.selectedSampleDataset;
});

export const getIsError = createSelector(state, (state: any) => {
  return state.isError;
});

export const getErrorText = createSelector(state, (state: any) => {
  return state.errorText;
});

export const getSampleDataset = createSelector(state, (state: any) => {
  return state.dataset;
});

export const getLimitError = createSelector(state, (state: any) => {
  return state.limitError;
});

export const getBreadCrumbs = createSelector(state, (state: any) => {
  return state.breadcrumbs;
});

export const getDatasetsSortingValue = createSelector(state, (state: any) => {
  return state.datasetsSortingValue;
});

export const getDeleteModalId = createSelector(state, (state: any) => {
  return state.deleteModalId;
});

export const getHealthStatus = createSelector(state, (state: any) => {
  return state.healthStatus;
});

export const getPublicParameter = createSelector(state, (state: any) => {
  return state.publicParameter;
});

export const getIsDisclaimerShown = createSelector(state, (state: any) => {
  return state.isDisclaimerShown;
});

export const getIsPublicPath = createSelector(state, (state: any) => {
  return state.isPublicPath;
});

export const getSelectedExportIDs = createSelector(state, (state: any) => {
  return state.selectedExportIDs;
});

export const getSelectedImageCount = createSelector(state, (state: any) => {
  return state.selectedImageCount;
});

export const getIsNewDatasetImportEnabled = createSelector(
  getUserConfig,
  (filterConfig: any) => {
    return isFeatureEnabled(filterConfig, DATA_INGESTION_UX);
  }
);

export const getDatasetToDuplicate = createSelector(state, (state: any) => {
  return state.datasets?.find(
    (ds: any) => ds.id === state.datasetIdToDuplicate
  );
});

export const getIsUsageReportEnabled = createSelector(
  getUserConfig,
  (userConfig: any): boolean => {
    return isFeatureEnabled(userConfig, USAGE_REPORT);
  }
);

export const getIsInventoryFilteredEnabled = createSelector(
  getUserConfig,
  (filterConfig: any) => {
    return isFeatureEnabled(filterConfig, INVENTORY_FILTER);
  }
);
