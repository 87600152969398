import { connect, useDispatch } from "react-redux";
import { State } from "redux/store";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  getEntityTypeFilter,
  getDatasetLabels,
  getIsLabelLoading,
  getDatasetIssueTypes,
  getTimeRange,
  getUserConfig,
  getSimilarityVertexCluster,
  getTreeFolder,
  getSelectedFolder,
  getNavigationCluster,
  isSearchEnabled,
  isImageSearchEnabled,
  getNavigationClusterLoader,
  isFolderFilterEnabled,
  getTotalPagesForCluster,
  getIsToggleConfig,
  getIsImageUploading,
  isClusterMetadataSummaryEnabled,
  isDataMetadataSummaryEnabled,
} from "redux/SingleDataset/selectors";
import {
  getIsUserTagLoading,
  getUserTags,
} from "redux/SingleDataset/selectors";
import {
  setEntityTypeFilter,
  setDatasetIssueTypes,
  setTimeRange,
  clearFilters,
  fetchDatasetLabels,
  fetchUserTags,
  fetchDataset,
  setDatasetLabels,
} from "redux/SingleDataset/actions";
import {
  DATE_RANGE_FILTER,
  ENTITY_TYPE_FILTER,
  ISSUE_TYPE_FILTER,
  LABEL_FILTER,
  TAGS_FILTER,
  FILE_FOLDER_FILTER,
} from "helpers/constants/filters";
import {
  ENTITY_TYPE_PARAMETER,
  FOLDER_PARAMETER,
  GRANULARITY_PARAMETER,
  ISSUE_TYPE_PARAMETER,
  LABELS_PARAMETER,
  PAGE_PARAMETER,
} from "helpers/constants/miscellaneous";

import { AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE } from "helpers/constants/amplitudeProperties";
import {
  AMP_DATASET_EXPLORATION_EVENT__ENTITY_TYPE_CHANGED,
  AMP_DATASET_EXPLORATION_EVENT__FILTER__ALL_CLEARED,
  AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__APPLIED,
  AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__REMOVED,
} from "helpers/constants/amplitudeEvents";
import {
  amplitudeTrack,
  trackFilterSelection,
} from "helpers/utility/amplitude";

import TagsFilter from "./TagsFilter";
import FolderFilter from "./FolderFilter";
import DateTimeRangePicker from "./TimePicker";
import SearchContainer from "./SearchContainer";
import IssueTypeFilter from "./IssueTypeFilter";
import DataPageLabelFilters from "./LabelFilter";
import DataPageEntityFilter from "./EntityTypeFilter";
import ClusterDetailsFilter from "./ClusterDetailsFilter";
import MetadataToggleButton from "views/components/MetadataSummary/MetadataToggleButton";

import { FilterBehavior, FilterOption } from "types";

import styles from "./style.module.scss";

const showArrayFilter = (filterArr: any[]) => {
  return filterArr && filterArr.length > 0;
};

const DataPageFilters = ({
  isLabelLoading,
  entityTypeFilter,
  datasetLabels,
  datasetIssueTypes,
  timeRange,
  filterConfig,
  isVertexSelected,
  treeData,
  selectedFolder,
  isTagLoading,
  tags,
  navigationCluster,
  navigationClusterLoader,
  searchEnabled,
  imageSearchEnabled,
  folderFilterEnabled,
  isEntityToggle,
  isImageUploading,
  clusterMetadataSummaryEnabled,
  dataMetadataSummaryEnabled,
  isMetadataSummaryOpen,
  toggleMedataSummary,
}: any) => {
  const { datasetId, clusterId, imageId } = useParams();
  const [firstIssueType, setFirstIssueType] = useState("");
  const [firstEntityType, setFirstEntityType] = useState("");
  const [isSearchInFocus, setIsSearchInFocus] = useState(false);
  const [isClearFiltersCalled, setIsClearFiltersCalled] = useState(false);
  const [dateRangeBehavior, setDateRangeBehavior] =
    useState<FilterBehavior>("HIDE");
  const [labelBehavior, setLabelBehavior] = useState<FilterBehavior>("HIDE");
  const [issueTypeBehavior, setIssueTypeBehavior] =
    useState<FilterBehavior>("HIDE");
  const [entityTypeBehavior, setEntityTypeBehavior] =
    useState<FilterBehavior>("HIDE");
  const [tagsBehavior, setTagsBehavior] = useState<FilterBehavior>("HIDE");
  const [fileFolderBehavior, setFileFolderBehavior] =
    useState<FilterBehavior>("HIDE");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isDataPage = !!datasetId && !clusterId && !imageId;

  const showMetadataSummary = isDataPage
    ? dataMetadataSummaryEnabled
    : clusterMetadataSummaryEnabled;

  const filterSectionClassnames = classNames(styles.filterSection, {
    [styles.filterSectionRightDisabled]: isImageUploading,
  });

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const isEntityTypeSelected = entityTypeFilter.some(
    (option: FilterOption) => option.selected === true
  );
  const isIssueTypeSelected = datasetIssueTypes.some(
    (option: FilterOption) => option.selected === true
  );

  const selectedEntityTypes = entityTypeFilter
    .filter((option: FilterOption) => option.selected)
    .map((option: FilterOption) => option.id);
  const selectedIssueTypes = datasetIssueTypes
    .filter((option: FilterOption) => option.selected)
    .map((option: FilterOption) => option.id);

  const issueTypesCountDisplayNumber =
    selectedIssueTypes?.length > 1 ? selectedIssueTypes?.length - 1 : 0;
  const entityTypesCountDisplayNumber =
    selectedEntityTypes?.length > 1 ? selectedEntityTypes?.length - 1 : 0;

  const showClusterFilter = !!clusterId && !!datasetId;
  const hideEntityFilter = (datasetId && clusterId) || isVertexSelected;

  const resetImageSearch = () => {};

  const updateQueryParameter = (options: FilterOption[], parameter: string) => {
    const result = options
      .filter((option) => option.selected === true)
      .map((option) => option.text);

    if (result.length > 0) {
      searchParams.set(parameter, JSON.stringify(result));
    } else {
      searchParams.delete(parameter);
    }
    searchParams.set(PAGE_PARAMETER, "1");
    navigate({ search: `?${searchParams.toString()}` });
  };

  const unsetLabels = async (): Promise<void> => {
    const unselected = datasetLabels.map((option: FilterOption) => ({
      ...option,
      selected: false,
    }));

    dispatch(setDatasetLabels(unselected));
  };

  const handleFilterSelection = async (value: number, type: any) => {
    if (type === "entityType") {
      let newEntityTypeFilter: any[] = [];
      const selectedEntityType = entityTypeFilter.find(
        (option: FilterOption) => option.id === value
      );
      if (isEntityToggle) {
        newEntityTypeFilter = entityTypeFilter.map((option: FilterOption) => ({
          ...option,
          selected: option.id === value,
        }));
      } else {
        newEntityTypeFilter = [
          ...entityTypeFilter.filter(
            (option: FilterOption) => option.id !== value
          ),
          { ...selectedEntityType, selected: !selectedEntityType.selected },
        ];
      }
      const newlySelectedEntities = newEntityTypeFilter
        .filter((entity: FilterOption) => entity.selected)
        .map((entity: FilterOption) => entity.text);

      if (newlySelectedEntities && newlySelectedEntities.length > 0) {
        if (searchParams.has(LABELS_PARAMETER)) {
          await unsetLabels(); //remove from state
          searchParams.delete(LABELS_PARAMETER); //remove from search params
        }
      }

      amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__ENTITY_TYPE_CHANGED, {
        [AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE]:
          //using join because the entities can have multiple values when in dropdown mode
          newlySelectedEntities.join(","),
      });

      updateQueryParameter(newEntityTypeFilter, ENTITY_TYPE_PARAMETER);
      dispatch(setEntityTypeFilter(newEntityTypeFilter) as any);
      dispatch(fetchDatasetLabels(datasetId) as any);
      dispatch(fetchDataset(datasetId) as any);
      resetImageSearch();
    } else if (type === "issueType") {
      const updatedDatasetIssueTypes = datasetIssueTypes.map(
        (option: FilterOption) => {
          if (option.id === value) {
            //tracking checks
            if (option.selected) {
              trackFilterSelection(
                "Issue Types",
                "Dataset",
                entityTypeFilter,
                "Removed"
              );
            } else {
              trackFilterSelection(
                "Issue Types",
                "Dataset",
                entityTypeFilter,
                "Added"
              );
            }
            return { ...option, selected: !option.selected };
          }
          return option;
        }
      );

      updateQueryParameter(updatedDatasetIssueTypes, ISSUE_TYPE_PARAMETER);
      dispatch(setDatasetIssueTypes(updatedDatasetIssueTypes) as any);
      resetImageSearch();
    }
  };

  const removeParameters = (searchParams: any) => {
    let keysToBeDeleted: any = [];
    searchParams.forEach((value: any, key: any) => {
      //ignoring the filters that are not reset
      if (key === PAGE_PARAMETER || key === GRANULARITY_PARAMETER) {
        //do nothing
      } else if (key === ENTITY_TYPE_PARAMETER && isEntityToggle) {
        //do nothing
      } else {
        keysToBeDeleted.push(key);
      }
    });

    if (keysToBeDeleted.length > 0) {
      keysToBeDeleted.forEach((key: any) => searchParams.delete(key));
    }
    return searchParams;
  };

  const handleClearFilters = async () => {
    setIsClearFiltersCalled(true); //used only for search input for now
    dispatch(clearFilters() as any);

    const selectedEntities = entityTypeFilter
      .filter((entity: FilterOption) => entity.selected)
      .map((entity: FilterOption) => entity.text);
    let value;
    if (selectedEntities.length > 0) {
      value = selectedEntities.join(",");
    } else {
      value = "Images";
    }
    amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__FILTER__ALL_CLEARED, {
      [AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE]: value,
    });
    if (!isVertexSelected) {
      searchParams.set(PAGE_PARAMETER, "1");
    }
    const updatedSearchParams = removeParameters(searchParams);
    const newParams = `?${updatedSearchParams.toString()}`;
    let newPath = location.pathname;

    if (newPath.includes("/cluster")) {
      newPath = newPath.split("/cluster")[0];
    }
    dispatch(fetchDatasetLabels(datasetId) as any);
    dispatch(fetchUserTags(datasetId) as any);
    dispatch(fetchDataset(datasetId) as any);
    navigate(newPath.concat(newParams));
  };

  const logSearchText_Amplitude = (
    entities: FilterOption[],
    context: "added" | "removed"
  ) => {
    const event =
      context === "added"
        ? AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__APPLIED
        : AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__REMOVED;
    const selectedEntities = entities
      .filter((entity) => entity.selected)
      .map((entity) => entity.text);

    let value;
    if (selectedEntities.length > 0) {
      value = selectedEntities.join(",");
    } else {
      value = "Images";
    }

    amplitudeTrack(event, {
      [AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE]: value,
    });
  };

  const areAllFiltersHidden = () => {
    let allFilters = [
      entityTypeBehavior,
      labelBehavior,
      issueTypeBehavior,
      dateRangeBehavior,
      fileFolderBehavior,
      tagsBehavior,
    ];
    const areAllFiltersHidden = allFilters.every((filter) => filter === "HIDE");
    if (areAllFiltersHidden && !navigationCluster) {
      return true;
    } else {
      return false;
    }
  };

  const handleTimeRangeChange = (timeRange: any) => {
    dispatch(setTimeRange(timeRange));
    resetImageSearch();
  };

  const handleFolderSelection = (path: string) => {
    if (path === null) {
      searchParams.delete(FOLDER_PARAMETER);
    } else {
      searchParams.set(FOLDER_PARAMETER, encodeURIComponent(path));
      searchParams.set(PAGE_PARAMETER, "1");
    }
    navigate({ search: `?${searchParams.toString()}` });
    resetImageSearch();
  };

  useEffect(() => {
    if (selectedIssueTypes?.length >= 1) {
      const firstIssue = datasetIssueTypes.find(
        (issue: FilterOption) => issue.id === selectedIssueTypes[0]
      );

      setFirstIssueType(firstIssue.text);
    } else if (selectedIssueTypes?.length < 1) {
      setFirstIssueType("");
    }
    if (selectedEntityTypes?.length >= 1) {
      setFirstEntityType(entityTypeFilter[selectedEntityTypes[0]]?.text);
    } else if (selectedEntityTypes?.length < 1) {
      setFirstEntityType("");
    }
    //eslint-disable-next-line
  }, [selectedIssueTypes, selectedEntityTypes]);

  useEffect(() => {
    const handleFilterBehavior = (filter: any, setBehaviorFunction: any) => {
      let behaviorForANY = filter.feature_behavior["ANY"];
      let behaviorForSIMILARITY = filter.feature_behavior["SIMILARITY_SEARCH"];

      if (isVertexSelected && behaviorForSIMILARITY) {
        setBehaviorFunction(behaviorForSIMILARITY);
      } else if (behaviorForANY) {
        setBehaviorFunction(behaviorForANY);
      } else {
        setBehaviorFunction(null);
      }
    };

    filterConfig.forEach((filter: any) => {
      switch (filter.feature_key) {
        case LABEL_FILTER:
          handleFilterBehavior(filter, setLabelBehavior);
          break;
        case DATE_RANGE_FILTER:
          handleFilterBehavior(filter, setDateRangeBehavior);
          break;
        case ISSUE_TYPE_FILTER:
          handleFilterBehavior(filter, setIssueTypeBehavior);
          break;
        case ENTITY_TYPE_FILTER:
          handleFilterBehavior(filter, setEntityTypeBehavior);
          break;
        case TAGS_FILTER:
          handleFilterBehavior(filter, setTagsBehavior);
          break;
        case FILE_FOLDER_FILTER:
          handleFilterBehavior(filter, setFileFolderBehavior);
          break;
        default:
          return;
      }
    });
  }, [isVertexSelected, filterConfig]);

  return (
    <div className={filterSectionClassnames}>
      <div className={styles.filterSectionLeft}>
        {/* -------------------FILTERS----------------------- */}

        <SearchContainer
          datasetId={datasetId}
          isDataPage={!!datasetId && !clusterId && !imageId}
          isSearchInFocus={isSearchInFocus}
          setIsSearchInFocus={setIsSearchInFocus}
          logSearchRemoval={() => {
            logSearchText_Amplitude(entityTypeFilter, "removed");
          }}
          logSearchAddition={() => {
            logSearchText_Amplitude(entityTypeFilter, "added");
          }}
          resetImageSearch={resetImageSearch}
          searchEnabled={searchEnabled}
          imageSearchEnabled={imageSearchEnabled}
          isImageUploading={isImageUploading}
          isClearFiltersCalled={isClearFiltersCalled}
          setIsClearFiltersCalled={setIsClearFiltersCalled}
        />

        {entityTypeBehavior !== "HIDE" && (
          <DataPageEntityFilter
            firstEntityType={firstEntityType}
            entityTypeFilter={entityTypeFilter}
            hideToggle={hideEntityFilter}
            entityTypeBehavior={
              showArrayFilter(entityTypeFilter)
                ? entityTypeBehavior
                : "GREY_OUT"
            }
            selectedEntityTypes={selectedEntityTypes}
            isEntityTypeSelected={isEntityTypeSelected}
            handleFilterSelection={handleFilterSelection}
            entityTypesCountDisplayNumber={entityTypesCountDisplayNumber}
            type={"entityType"}
          />
        )}
        {folderFilterEnabled && (
          <FolderFilter
            datasetId={datasetId!}
            treeData={treeData}
            onSelect={handleFolderSelection}
            selectedFolder={selectedFolder}
            entityTypeFilter={entityTypeFilter}
            behavior={treeData ? undefined : "GREY_OUT"}
          />
        )}

        {dateRangeBehavior !== "HIDE" && (
          <DateTimeRangePicker
            timeRange={timeRange}
            handleTimeRangeChange={handleTimeRangeChange}
            behavior={!!timeRange ? dateRangeBehavior : "GREY_OUT"}
          />
        )}

        {labelBehavior !== "HIDE" && (
          <DataPageLabelFilters
            datasetId={datasetId!}
            datasetLabels={datasetLabels}
            isLabelLoading={isLabelLoading}
            behavior={
              showArrayFilter(datasetLabels) ? labelBehavior : "GREY_OUT"
            }
            resetImageSearch={resetImageSearch}
            updateQueryParameter={updateQueryParameter}
            entityTypeFilter={entityTypeFilter}
          />
        )}

        {issueTypeBehavior !== "HIDE" && (
          <IssueTypeFilter
            firstIssueType={firstIssueType}
            issueTypeBehavior={
              showArrayFilter(datasetIssueTypes)
                ? issueTypeBehavior
                : "GREY_OUT"
            }
            datasetIssueTypes={datasetIssueTypes}
            selectedIssueTypes={selectedIssueTypes}
            isIssueTypeSelected={isIssueTypeSelected}
            handleFilterSelection={handleFilterSelection}
            issueTypesCountDisplayNumber={issueTypesCountDisplayNumber}
          />
        )}

        {tagsBehavior !== "HIDE" && (
          <TagsFilter
            datasetId={datasetId!}
            tags={tags}
            isTagLoading={isTagLoading}
            behavior={showArrayFilter(tags) ? tagsBehavior : "GREY_OUT"}
            resetImageSearch={resetImageSearch}
            updateQueryParameter={updateQueryParameter}
            entityTypeFilter={entityTypeFilter}
          />
        )}

        {showClusterFilter && (
          <ClusterDetailsFilter
            navigationClusterLoader={navigationClusterLoader}
            navigationCluster={navigationCluster}
            datasetID={datasetId!}
          />
        )}

        {/* ------------------------------------------------------------------------- */}

        {!areAllFiltersHidden() && (
          <div
            className={styles.clearFilterButton}
            onClick={handleClearFilters}
          >
            &nbsp;&nbsp;Clear Filters
          </div>
        )}
      </div>
      {showMetadataSummary && (
        <div className={styles.filterSectionRight}>
          <MetadataToggleButton
            toggle={toggleMedataSummary}
            isOpen={isMetadataSummaryOpen}
          />
        </div>
      )}
    </div>
  );
};

const mapStatesToProps = (state: State) => {
  return {
    isLabelLoading: getIsLabelLoading(state),
    entityTypeFilter: getEntityTypeFilter(state),
    datasetLabels: getDatasetLabels(state),
    datasetIssueTypes: getDatasetIssueTypes(state),
    timeRange: getTimeRange(state),
    filterConfig: getUserConfig(state),
    isVertexSelected: !!getSimilarityVertexCluster(state),
    treeData: getTreeFolder(state),
    selectedFolder: getSelectedFolder(state),
    isTagLoading: getIsUserTagLoading(state),
    tags: getUserTags(state),
    navigationCluster: getNavigationCluster(state),
    navigationClusterLoader:
      getNavigationClusterLoader(state) || !getTotalPagesForCluster(state),
    searchEnabled: isSearchEnabled(state),
    imageSearchEnabled: isImageSearchEnabled(state),
    folderFilterEnabled: isFolderFilterEnabled(state),
    isEntityToggle: getIsToggleConfig("entityType")(state),
    isImageUploading: getIsImageUploading(state),
    clusterMetadataSummaryEnabled: isClusterMetadataSummaryEnabled(state),
    dataMetadataSummaryEnabled: isDataMetadataSummaryEnabled(state),
  };
};

export default connect(mapStatesToProps)(DataPageFilters);
