import { amplitudeTrack } from "helpers/utility/amplitude";
import {
  AMP_DATASET_EXPLORATION_EVENT__SIGNUP_TEASER_MODAL__SIGNUP_CLICKED,
  AMP_DATASET_EXPLORATION_EVENT__SIGNUP_TEASER_MODAL__LEARN_MORE_CLICKED,
  AMP_DATASET_EXPLORATION_EVENT__SIGNUP_TEASER_MODAL__DISPLAYED,
} from "helpers/constants/amplitudeEvents";
import styles from "./style.module.scss";
import { userLogout } from "redux/authReducer";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getUserInfo } from "redux/User/selectors";
import { State } from "redux/store";
import { getIsCardslistLoading } from "redux/SingleDataset/selectors";
import { isFastdupUser } from "helpers/utility/utilities";

const SignupTeaserModal = ({
  userEmail,
  isCardListLoading,
}: SignupTeaserModalProps) => {
  const showSignupTeaserModal =
    isFastdupUser() && !userEmail && !isCardListLoading;

  useEffect(() => {
    if (showSignupTeaserModal) {
      amplitudeTrack(
        AMP_DATASET_EXPLORATION_EVENT__SIGNUP_TEASER_MODAL__DISPLAYED
      );
    }
  }, [showSignupTeaserModal]);

  const handleSignupClicked = () => {
    amplitudeTrack(
      AMP_DATASET_EXPLORATION_EVENT__SIGNUP_TEASER_MODAL__SIGNUP_CLICKED
    );
    userLogout();
  };

  const handleLearnMoreClicked = () => {
    amplitudeTrack(
      AMP_DATASET_EXPLORATION_EVENT__SIGNUP_TEASER_MODAL__LEARN_MORE_CLICKED
    );
    window.open("https://docs.visual-layer.com", "_blank");
  };
  if (showSignupTeaserModal) {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            Sign-up to unlock the full potential of your visual data!
          </div>
          <div className={styles.modalContent}>
            <p>
              Sign up for the free Visual Layer platform and quickly explore
              your datasets with advanced AI-powered curation tools -all on your
              local environment without sharing data.
            </p>
            <div className={styles.modalActionItems}>
              <button
                className={styles.learnMoreLink}
                onClick={handleLearnMoreClicked}
              >
                Learn more
              </button>
              <button
                className={styles.signupButton}
                onClick={handleSignupClicked}
              >
                Sign up for free
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <noscript />;
  }
};

interface SignupTeaserModalProps {
  userEmail: string;
  isCardListLoading: boolean;
}

const mapStateToProps = (state: State) => {
  return {
    userEmail: getUserInfo(state).email,
    isCardListLoading: getIsCardslistLoading(state),
  };
};

export default connect(mapStateToProps)(SignupTeaserModal);
