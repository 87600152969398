import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearFilters } from "redux/SingleDataset/actions";
import { PRIVATE_DATASETS_PATH } from "helpers/constants/paths";
import datasetIconWhite from "assets/img/dataset-white-icon.svg";
import TooltipWrapper from "views/uikit/TooltipWrapper";

import styles from "./style.module.scss";
import { isFastdupUser } from "helpers/utility/utilities";
import { setShowLearnMoreModal } from "redux/Modals/actions";
import { amplitudeTrack } from "helpers/utility/amplitude";
import { AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__OPENED } from "helpers/constants/amplitudeEvents";

const VLDatasetIcon = () => {
  const dispatch = useDispatch();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleLearnMoreClick = () => {
    setIsTooltipOpen(false);
    dispatch(setShowLearnMoreModal(true));
    amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__OPENED);
  };
  const handleClick = () => {
    dispatch(clearFilters() as any);
  };

  const isActive = !isFastdupUser();

  return (
    <div className={styles.filterIcon}>
      <div
        data-tooltip-id="private-dataset-icon-tooltip"
        data-tooltip-place={isActive ? "right" : "bottom"}
      >
        {isActive ? (
          <Link to={PRIVATE_DATASETS_PATH} onClick={handleClick}>
            <img src={datasetIconWhite} alt="" />
          </Link>
        ) : (
          <div className={styles.disabledIcon}>
            <img src={datasetIconWhite} alt="" />
          </div>
        )}
      </div>
      <TooltipWrapper
        id="private-dataset-icon-tooltip"
        isClickable={true}
        isOpen={isTooltipOpen}
        setIsOpen={setIsTooltipOpen}
        maxWidth="390px"
      >
        <div className={styles.tooltipContent}>
          <div className={styles.tooltipText}>
            {isActive
              ? "My Datasets"
              : "The local version of visual layer only allows you to explore one dataset at a time"}
          </div>
          {!isActive && (
            <button
              className={styles.learnMoreButton}
              onClick={handleLearnMoreClick}
            >
              LEARN MORE
            </button>
          )}
        </div>
      </TooltipWrapper>
    </div>
  );
};

export default React.memo(VLDatasetIcon);
