import { useDispatch } from "react-redux";
import { setShowCart } from "redux/SingleDataset/actions";

import selectionCountIcon from "assets/img/note-stack-add.svg";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import ToolTipBox from "views/components/TooltipBox";
import styles from "./style.module.scss";

const SelectionBox = ({
  exportCount,
  showCart,
  clusters,
  navigationCluster,
  exportData,
  tempExportData,
  timeoutID,
  allSelectedFilters,
}: any) => {
  const dispatch = useDispatch();
  const toggleIsOpen = () => {
    dispatch(setShowCart(!showCart));
  };

  return (
    <div
      className={`${styles.selectionDisplayBox} ${
        exportCount > 0 ? styles.exportSelected : ""
      }`}
      onClick={toggleIsOpen}
      data-tooltip-id="export-selection-count-box"
      data-tooltip-place="left"
    >
      <img
        className={styles.selectionCountIcon}
        src={selectionCountIcon}
        alt="selection count icon"
      />
      <span className={styles.selectionTitle}>
        Selected Items {`(${exportCount})`}
      </span>
      <div className={styles.chevronIcon}>
        {showCart ? (
          <BiChevronUp size="1.55em" color="#fff" />
        ) : (
          <BiChevronDown size="1.55em" color="#fff" />
        )}
      </div>

      {tempExportData && tempExportData.calledFrom === "card" && (
        <ToolTipBox
          tempExportData={tempExportData}
          exportData={exportData}
          timeoutID={timeoutID}
          clusters={clusters}
          navigationCluster={navigationCluster}
          allSelectedFilters={allSelectedFilters}
        />
      )}
    </div>
  );
};
export default SelectionBox;
