import { useRef } from "react";
import styles from "./style.module.scss";

function SourceItem(props: any) {
  const radioRef = useRef<HTMLInputElement>(null);
  return (
    <div
      className={props.selected ? styles.selectedSourceItem : styles.sourceItem}
      onClick={() => {
        props.onClick();
        radioRef.current && radioRef.current.click();
      }}
    >
      <div className={styles.radioBtn}>
        <input
          ref={radioRef}
          type="radio"
          name="radio-btns"
          onChange={(e) => {}}
          checked={props.selected}
        />
        <span className={styles.checkmark}></span>
      </div>
      <div className={styles.labels}>
        <div className={styles.labelName}>{props.name}</div>
        <div className={styles.labelDescription}>{props.description}</div>
      </div>
    </div>
  );
}
export default SourceItem;
