import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { FiBookOpen } from "react-icons/fi";

import { isAnonymousUser, selectUser } from "redux/authReducer";
import {
  setShowLearnMoreModal,
  setshowProfileModal,
} from "redux/Modals/actions";
import { clearFilters } from "redux/SingleDataset/actions";

import {
  AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__OPENED,
  AMP_DOCUMENTATION_VISITED,
} from "helpers/constants/amplitudeEvents";
import {
  PRIVATE_DATASETS_PATH,
  PUBLIC_DATASETS_PATH,
} from "helpers/constants/paths";
import { amplitudeTrack } from "helpers/utility/amplitude";

import InvitationModal from "views/modals/InvitationModal";
import ProfileModal from "views/modals/ProfileModal";
import TooltipWrapper from "views/uikit/TooltipWrapper";
import VLDatasetIcon from "views/uikit/VLDatasetIcon";
import VLAvatar from "views/uikit/VLAvatar";
import VLIcon from "views/uikit/VLIcon";

import whiteBinoculars from "assets/img/binoculars-white.svg";
import LocalIcon from "assets/icons/LocalIcon";
import binoculars from "assets/img/binoculars.svg";

import styles from "./style.module.scss";
import { isFastdupUser } from "helpers/utility/utilities";
import { fetchUserConfig } from "redux/User/actions";

const PublicDatasetsIcon = ({
  handleSrc,
  setIsHovering,
}: PublicDatasetsIconProps) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(clearFilters() as any);
  };
  return (
    <div
      className={styles.publicDatasetsIcon}
      data-tooltip-content={"Explore Public VL Datasets"}
      data-tooltip-id="view-public-datasets-icon-tooltip"
      data-tooltip-place="right"
    >
      <Link to={PUBLIC_DATASETS_PATH} onClick={handleClick}>
        <img
          src={handleSrc}
          width={"30px"}
          onMouseOver={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          alt=""
        />
      </Link>
      <TooltipWrapper id="view-public-datasets-icon-tooltip" />
    </div>
  );
};

const Sidebar = ({
  userAvatar,
  vlVersion,
}: {
  userAvatar: string | undefined;
  vlVersion: string | undefined;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isLocalTooltipOpen, setIsLocalTooltipOpen] = useState(false);
  const pathName = useLocation().pathname;
  const user = selectUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await Promise.all([dispatch(fetchUserConfig() as any)]);
    })();
    //eslint-disable-next-line
  }, []);

  const handleAvatarClick = () => {
    if (user && !isAnonymousUser()) {
      dispatch(setshowProfileModal(true));
    }
  };

  const handleLogoClick = () => {
    if (user && !isAnonymousUser()) {
      navigate(PRIVATE_DATASETS_PATH);
    } else {
      navigate(PUBLIC_DATASETS_PATH);
    }
  };

  const handleDocsButtonClick = (e: any) => {
    e.stopPropagation();
    amplitudeTrack(AMP_DOCUMENTATION_VISITED);
  };

  const handleLearnMoreButtonClick = (e: any) => {
    setIsLocalTooltipOpen(false);
    dispatch(setShowLearnMoreModal(true));
    amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__OPENED);
  };

  const avatarClass =
    user && !isAnonymousUser() ? styles.avatarContainer : styles.noUser;
  const handleSrc = isHovering ? whiteBinoculars : binoculars;

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarContainer}>
        <div className={styles.sidebarTop}>
          <span className={styles.vlLogo} onClick={handleLogoClick}>
            <VLIcon />
          </span>
          <div className={styles.vlText}>BETA</div>
          <VLDatasetIcon />
        </div>

        <div className={styles.sidebarBottom}>
          {!isFastdupUser() &&
            !isAnonymousUser() &&
            pathName !== PUBLIC_DATASETS_PATH && (
              <PublicDatasetsIcon
                setIsHovering={setIsHovering}
                handleSrc={handleSrc}
              />
            )}

          <div
            className={styles.docsIcon}
            data-tooltip-content={"Go to documentation"}
            data-tooltip-id="docs-icon-tooltip"
            data-tooltip-place="right"
          >
            <a
              href={"https://docs.visual-layer.com"}
              target="_blank"
              rel="noreferrer"
              onClick={handleDocsButtonClick}
            >
              <FiBookOpen size="30" />
            </a>
            <TooltipWrapper id="docs-icon-tooltip" />
          </div>
          {isFastdupUser() && (
            <div
              className={styles.localIcon}
              data-tooltip-id="local-icon-tooltip"
              data-tooltip-place="right"
            >
              <LocalIcon />
              <TooltipWrapper
                id="local-icon-tooltip"
                maxWidth="420px"
                isOpen={isLocalTooltipOpen}
                setIsOpen={setIsLocalTooltipOpen}
                isClickable={true}
              >
                <div className={styles.localTooltip}>
                  <div className={styles.tooltipText}>
                    <b>Local visual layer deployment</b>
                    <br />
                    Your Data Never Leaves This Environment
                  </div>
                  <button
                    className={styles.learnMoreButton}
                    onClick={handleLearnMoreButtonClick}
                  >
                    LEARN MORE
                  </button>
                </div>
              </TooltipWrapper>
            </div>
          )}
          <div className={avatarClass} onClick={handleAvatarClick}>
            <VLAvatar size="small" userAvatar={userAvatar} />
            {!!vlVersion && (
              <div className={styles.vlVersionBanner}>{vlVersion}</div>
            )}
          </div>
        </div>
        <ProfileModal userAvatar={userAvatar} />
        <InvitationModal />
      </div>
    </div>
  );
};

export default Sidebar;

type PublicDatasetsIconProps = {
  setIsHovering: Function;
  handleSrc: any;
};
