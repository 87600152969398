export default function TrashIcon({ color = "#fff" }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
    >
      <path
        d="M3 18.5903C2.45 18.5903 1.97917 18.3945 1.5875 18.0028C1.19583 17.6112 1 17.1403 1 16.5903V3.59033H0V1.59033H5V0.590332H11V1.59033H16V3.59033H15V16.5903C15 17.1403 14.8042 17.6112 14.4125 18.0028C14.0208 18.3945 13.55 18.5903 13 18.5903H3ZM13 3.59033H3V16.5903H13V3.59033ZM5 14.5903H7V5.59033H5V14.5903ZM9 14.5903H11V5.59033H9V14.5903Z"
        fill={color}
      />
    </svg>
  );
}
