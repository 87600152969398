import { configureStore } from "@reduxjs/toolkit";

import singleDatasetReducer from "./SingleDataset/reducer";
import datasetsReducer from "./Datasets/reducer";
import imageReducer from "./SingleImage/reducer";
import modalsReducer from "./Modals/reducer";
import metaDataReducer from "./Metadata/reducer";
import createDatasetReducer from "./CreateDataset/reducer";

import userDatasetReducer from "./UserDatasets/reducer";
import debugReducer from "./debugSlice";
import headerReducer from "./headerSlice";
import treeReducer from "./treeSlice";
import userReducer from "./User/reducer";

export const store = configureStore({
  reducer: {
    tree: treeReducer,
    header: headerReducer,
    debug: debugReducer,
    Userdatasets: userDatasetReducer,
    datasets: datasetsReducer,
    singleDataset: singleDatasetReducer,
    singleImage: imageReducer,
    modals: modalsReducer,
    metadata: metaDataReducer,
    user: userReducer,
    createDataset: createDatasetReducer,
  },
  preloadedState: {},
});

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
