import { connect, useDispatch } from "react-redux";
import { useState } from "react";

import { setShowProModal, setShowUpgradeModal } from "redux/Modals/actions";
import { getShowUpgradeModal } from "redux/Modals/selectors";
import { State } from "redux/store";

import UpgradePlanForm from "./UpgradePlanForm";
import VLModal from "views/uikit/VLModal";

import FreeUserStar from "assets/img/FreeUserStar.svg";
import styles from "./style.module.scss";

function UpgradePlanModal({ showModal }: any) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errorFlag, setErrorFlag] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const dispatch = useDispatch();

  const resetUpgradeModalStates = () => {
    setEmail("");
    setLastName("");
    setFirstName("");
  };

  const handleClick = () => {
    dispatch(setShowProModal(true));
    resetUpgradeModalStates();
    dispatch(setShowUpgradeModal(false));
  };

  return (
    <VLModal
      showModal={showModal}
      setShowModal={(value: boolean) => dispatch(setShowUpgradeModal(value))}
      resetUpgradeModalStates={resetUpgradeModalStates}
    >
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <img src={FreeUserStar} alt="" className={styles.arrowRight} />
          <span className={styles.freePlan}>Upgrade Inquiry</span>
        </div>

        <div className={styles.modalBody}>
          <UpgradePlanForm
            showModal={showModal}
            setFirstName={setFirstName}
            firstName={firstName}
            setLastName={setLastName}
            lastName={lastName}
            setEmail={setEmail}
            email={email}
            setErrorFlag={setErrorFlag}
            errorFlag={errorFlag}
          />
        </div>
        <div className={styles.modalFooter}>
          <div className={styles.nextBtnContainer}>
            <button
              className={
                !firstName || !lastName || !email
                  ? styles.disabledNextBtn
                  : styles.nextBtn
              }
              onClick={handleClick}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </VLModal>
  );
}

const mapStatesToProps = (state: State) => {
  return {
    showModal: getShowUpgradeModal(state),
  };
};

export default connect(mapStatesToProps)(UpgradePlanModal);
