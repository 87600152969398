import { ReactNode, useRef, useState } from "react";
import { State } from "redux/store";
import { connect } from "react-redux";
import { StepTileProgressStatus } from "types";

import StepTile from "../StepTile";
import AnnotationsIcon from "assets/icons/AnnotationIcon";

import {
  getAnnotationStatus,
  getIsDatasetIndexing,
  getNewDatasetId,
  getIsNewDatasetInitializing,
  getLastUsedData,
  getIsNewDatasetReady,
} from "redux/CreateDataset/selectors";
import PulsingDropdownIcon from "../PulsingDropdownIcon";
import AnnotationsInputBanner from "./AnnotationsInputBanner";

import styles from "./style.module.scss";

const AnnotationStep = ({
  annotationStatus,
  datasetIndexing,
  newDatasetId,
  isNewDatasetInitializing,
  lastUsedAnnotationFiles,
  isDatasetReady,
}: AnnotationStepProps) => {
  const [showBanner, setShowBanner] = useState(false);

  const bannerRef = useRef<HTMLDivElement>(null);
  const editButtonRef = useRef<HTMLDivElement>(null);

  const annotationComplete =
    annotationStatus === StepTileProgressStatus.COMPLETE;
  const annotationActive = annotationStatus === StepTileProgressStatus.ACTIVE;
  const annotationInProgress =
    annotationStatus === StepTileProgressStatus.IN_PROGRESS;

  const toggleShowBanner = () => {
    setShowBanner(!showBanner);
  };

  const handleOutsideClick = (event: any) => {
    const editButton = editButtonRef.current;
    const banner = bannerRef.current;

    const editButtonClicked = editButton && editButton.contains(event.target);
    const bannerClicked = banner && banner.contains(event.target);

    if ((!editButton || !editButtonClicked) && !bannerClicked) {
      setShowBanner(false);
    }
  };

  const handleEditClick = () => {
    setShowBanner(true);
  };

  const renderHeadIcon = (icon: ReactNode, loading?: boolean): ReactNode => {
    if (loading) {
      return <div className={styles.spinner}></div>;
    } else {
      return <div className={styles.flexIcon}>{icon}</div>;
    }
  };

  const renderActionIcon = () => {
    if (datasetIndexing || isDatasetReady) {
      return <noscript />;
    }

    if (annotationComplete) {
      return (
        <div
          ref={editButtonRef}
          className={styles.actionButton}
          onClick={handleEditClick}
        >
          Edit
        </div>
      );
    } else if (annotationActive && !showBanner) {
      return (
        <PulsingDropdownIcon
          showPulse={!showBanner}
          isDropdownOpen={showBanner}
          toggleDropdown={toggleShowBanner}
          containerRef={editButtonRef}
        />
      );
    }
  };

  return (
    <div className={styles.annotationsContainer}>
      <StepTile
        title={"Add Annotations"}
        status={annotationStatus}
        headIcon={renderHeadIcon(
          <AnnotationsIcon status={annotationStatus} />,
          annotationInProgress
        )}
        actionIcon={renderActionIcon()}
        handleOutsideClick={handleOutsideClick}
        isContentShown={showBanner}
      />

      <AnnotationsInputBanner
        newDatasetId={newDatasetId}
        showBanner={showBanner}
        closeBanner={toggleShowBanner}
        bannerRef={bannerRef}
        viewOnly={!isNewDatasetInitializing}
        lastUsedAnnotationFiles={lastUsedAnnotationFiles}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    annotationStatus: getAnnotationStatus(state),
    newDatasetId: getNewDatasetId(state),
    isDatasetReady: getIsNewDatasetReady(state),
    datasetIndexing: getIsDatasetIndexing(state),
    isNewDatasetInitializing: getIsNewDatasetInitializing(state),
    lastUsedAnnotationFiles: getLastUsedData(state).annotationFiles,
  };
};

export default connect(mapStateToProps)(AnnotationStep);

interface AnnotationStepProps {
  annotationStatus: StepTileProgressStatus;
  newDatasetId: string;
  datasetIndexing: boolean;
  isDatasetReady: boolean;
  isNewDatasetInitializing: boolean;
  lastUsedAnnotationFiles: string[];
}
