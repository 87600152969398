import { updateSelectedIssues } from "redux/Modals/actions";
import { useDispatch } from "react-redux";
import styles from "./style.module.scss";
import { Fragment } from "react";

const SingleRow = ({ singleIssue, selectedIssues }: any) => {
  const dispatch = useDispatch();

  const isMisLabeled = singleIssue.type_display_name === "mislabels";
  const isSelected = selectedIssues.find(
    (issue: any) => issue.cause === singleIssue.cause
  );

  const handleIssueClick = (issueObject: string) => {
    if (isMisLabeled) {
      dispatch(updateSelectedIssues(issueObject) as any);
    }
  };

  return (
    <tr className={styles.singleLabelRow}>
      <td className={`${styles.singleLabel} ${styles.type}`}>
        {singleIssue.type_display_name}
      </td>
      <td
        className={styles.solutionsBox}
        style={
          isMisLabeled
            ? {
                color: "#0197D8",
                cursor: "pointer",
                textDecoration: "underline",
                fontWeight: 700,
              }
            : undefined
        }
      >
        <span
          className={`${styles.singleLabel} ${styles.singleSolution}`}
          onClick={() => handleIssueClick(singleIssue)}
          style={
            isMisLabeled
              ? isSelected
                ? { fontWeight: 400 }
                : { fontWeight: 700 }
              : undefined
          }
        >
          {singleIssue.description}
        </span>
      </td>

      <td className={`${styles.singleLabel} ${styles.accuracy}`}>
        {Math.round(singleIssue.confidence * 100)}%
      </td>
    </tr>
  );
};

const IssuesBox = ({
  issues,
  selectedIssues,
}: {
  issues: Array<any>;
  selectedIssues: Array<any>;
}) => {
  return issues && issues.length > 0 ? (
    <table className={styles.issuesTable}>
      <thead style={{ backgroundColor: "#3B4051" }}>
        <tr className={styles.singleLabelRow}>
          <th className={`${styles.singleLabel} ${styles.type}`}>Issue</th>
          <th className={`${styles.singleLabel} ${styles.solutionTitle}`}>
            Suggestion
          </th>
          <th className={`${styles.singleLabel} ${styles.accuracy}`}>
            Confidence
          </th>
        </tr>
      </thead>
      <tbody>
        {issues.map((singleIssue, index) => (
          <Fragment key={index}>
            <SingleRow
              singleIssue={singleIssue}
              selectedIssues={selectedIssues}
            />
          </Fragment>
        ))}
      </tbody>
    </table>
  ) : null;
};

export default IssuesBox;
