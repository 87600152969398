import { useCallback, useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { FiLink2 } from "react-icons/fi";
import { toast } from "react-toastify";
import { CopyURLTypes } from "types";
import classNames from "classnames";

import { setDeleteModalId } from "redux/Datasets/actions";
import { useDispatch, useSelector } from "react-redux";

import {
  PUBLIC_PAGE_QUERY_PARAMETER_KEY,
  PUBLIC_PAGE_QUERY_PARAMETER_VALUE,
} from "helpers/constants/miscellaneous";

import { setShowModal } from "redux/Modals/actions";
import DuplicateIcon from "assets/icons/DuplicateIcon";
import { setDatasetIdToDuplicate } from "redux/Datasets/actions";
import { shouldShowDuplicateButton } from "redux/User/selectors";

import styles from "./style.module.scss";

const DeleteButton = ({
  setShowDeleteDatasetModal,
  closeModal,
  showDeleteButton,
}: DeleteButtonProps) => {
  const handleDeleteButtonClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setShowDeleteDatasetModal(true);
    closeModal();
  };

  return showDeleteButton ? (
    <li
      className={styles.datasetHamburgerListStyle}
      onClick={handleDeleteButtonClick}
    >
      <MdDeleteOutline
        className={styles.datasetHamburgerDeleteIcon}
        size="22px"
      />
      <span className={styles.datasetHamburgerDeleteOption}>Delete</span>
    </li>
  ) : null;
};

const DuplicateButton = ({ datasetId, closeModal }: DuplicateButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const dispatch = useDispatch();
  const handleButtonClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    closeModal();
    dispatch(setDatasetIdToDuplicate(datasetId));
    dispatch(setShowModal(true));
  };
  const showButton = useSelector((state: any) =>
    shouldShowDuplicateButton(state)
  );
  return showButton ? (
    <li
      className={styles.datasetHamburgerListStyle}
      onClick={handleButtonClick}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <DuplicateIcon color={isHovering ? "#000" : "#d4d4d4"} />
      <span className={styles.datasetHamburgerDeleteOption}>Duplicate</span>
    </li>
  ) : null;
};

function DatasetHamburger({
  setShowDeleteDatasetModal,
  datasetId,
  deleteModalId,
  url,
  isPrivatePath,
  hasSimilarityData,
  copyButtonURL,
}: any) {
  const [isOpen, setIsOpen] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const dispatch = useDispatch();

  const copyUrlRowClassnames = classNames(styles.datasetHamburgerListStyle, {
    [styles.disabledRow]: !copyButtonURL,
  });

  useEffect(() => {
    if (isPrivatePath) {
      setShowDeleteButton(true);
    }
    // eslint-disable-next-line
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    dispatch(setDeleteModalId(null) as any);
  }, [dispatch]);

  // eslint-disable-next-line
  const listener = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeModal();
      document.removeEventListener("keydown", listener);
    }
  };

  const checkIfClickedOutside = useCallback(
    (event: any) => {
      event.stopPropagation();
      const className = (event.target.className as string) || "";
      let flag = className.includes("datasetHamburger");

      if (!flag) {
        closeModal();
      }
    },
    [closeModal]
  );

  const handleCopyButtonClick = (event: any) => {
    let copyURL: CopyURLTypes = copyButtonURL;

    if (!copyURL) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    let urlToCopy = new URL(window.location.href);

    const endpoint = copyURL === "/create" ? "/create" : "/data";

    urlToCopy.pathname = `/dataset/${url}${endpoint}`;
    if (isPrivatePath === false) {
      urlToCopy.searchParams.set(
        PUBLIC_PAGE_QUERY_PARAMETER_KEY,
        PUBLIC_PAGE_QUERY_PARAMETER_VALUE
      );
    }
    navigator.clipboard.writeText(urlToCopy.toString());
    toast.success("Copied to clipboard", {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    closeModal();
  };

  useEffect(() => {
    if (deleteModalId === datasetId) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [deleteModalId, datasetId]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", checkIfClickedOutside);
    } else {
      document.removeEventListener("click", checkIfClickedOutside);
    }
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [checkIfClickedOutside, isOpen]);

  useEffect(() => {
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line
  }, [listener]);

  return isOpen ? (
    <div className={styles.datasetHamburgerMainContainer}>
      <div className={styles.datasetHamburgerInnerContainer}>
        <ul className={styles.datasetHamburgerListContainer}>
          {copyButtonURL && (
            <li
              className={copyUrlRowClassnames}
              onClick={handleCopyButtonClick}
            >
              <FiLink2
                className={styles.datasetHamburgerDeleteIcon}
                size="22px"
              />
              <span className={styles.datasetHamburgerDeleteOption}>
                Copy URL
              </span>
            </li>
          )}
          <DeleteButton
            closeModal={closeModal}
            setShowDeleteDatasetModal={setShowDeleteDatasetModal}
            showDeleteButton={showDeleteButton}
          />
          <DuplicateButton datasetId={datasetId} closeModal={closeModal} />
        </ul>
      </div>
    </div>
  ) : null;
}

export default DatasetHamburger;

interface DeleteButtonProps {
  setShowDeleteDatasetModal: Function;
  closeModal: Function;
  showDeleteButton: boolean;
}

interface DuplicateButtonProps {
  datasetId: string;
  closeModal: Function;
}
