import { ChangeEvent, ReactNode, useRef, useState } from "react";
import { State } from "redux/store";
import { connect, useDispatch } from "react-redux";
import { setShowDemoRequestModal } from "redux/Modals/actions";
import {
  checkFolder,
  checkZip,
  setPreviewDataSource,
} from "redux/CreateDataset/actions";
import {
  getConnectStatus,
  getIsDatasetIndexing,
  getS3FetchProgress,
  getIsNewDatasetInitializing,
  getPreviewDataSource,
  getIsNewDatasetReady,
} from "redux/CreateDataset/selectors";
import { getNewDatasetId } from "redux/CreateDataset/selectors";

import {
  DataSources,
  ContactUsComponentType,
  SetStateFunction,
  StepTileProgressStatus,
} from "types";

import S3ProgressComponent from "./S3ProgressComponent";
import PulsingDropdownIcon from "../PulsingDropdownIcon";
import ConnectDropdown from "./ConnectDropdown";
import InputBannerWrapper from "./InputBanner";
import StepTile from "../StepTile";

import ConnectIcon from "assets/icons/ConnectIcon";

import styles from "./style.module.scss";
import classNames from "classnames";

const ConnectStep = ({
  showStartBanner,
  setShowStartBanner,
  connectStatus,
  s3FetchProgress,
  datasetIndexing,
  isNewDatasetInitializing,
  previewDataSource,
  newDatasetId,
  isDatasetReady,
}: ConnectStepProps) => {
  const [isConnectDropdownOpen, setIsConnectDropdownOpen] = useState(false);
  const [showInputBanner, setShowInputBanner] = useState<DataSources | null>(
    null
  );

  const editButtonRef = useRef<HTMLDivElement>(null);
  const folderInputRef = useRef<HTMLInputElement>(null);
  const zipInputRef = useRef<HTMLInputElement>(null);
  const connectDropdownRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const connectComplete = connectStatus === StepTileProgressStatus.COMPLETE;
  const connectInProgress =
    connectStatus === StepTileProgressStatus.IN_PROGRESS;

  const showS3ProgressComponent = s3FetchProgress > -1;

  const actionIconsClassNames = classNames(styles.actionIcons, {
    [styles.noPointer]: connectInProgress,
  });

  const handleFolderChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      dispatch(checkFolder(newDatasetId, event.target.files) as any);
      dispatch(setPreviewDataSource(DataSources.FOLDER));
    }
  };

  const handleZipChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      dispatch(setPreviewDataSource(DataSources.ZIP));
      dispatch(checkZip(newDatasetId, event.target.files[0]) as any);
    }
  };

  const handleOutsideClick = (event: any) => {
    if (
      connectDropdownRef.current &&
      editButtonRef.current &&
      !connectDropdownRef.current.contains(event.target) &&
      !editButtonRef.current.contains(event.target)
    ) {
      toggleDropdown();
    }
  };

  const handleConnectOptionSelection = (dataSource: DataSources) => {
    if (isNewDatasetInitializing && dataSource === DataSources.FOLDER) {
      folderInputRef.current?.click();
    } else if (isNewDatasetInitializing && dataSource === DataSources.ZIP) {
      zipInputRef.current?.click();
    } else {
      setShowInputBanner(dataSource);
    }
    setIsConnectDropdownOpen(false);
  };

  const handleContactUsClick = (calledFrom: ContactUsComponentType) => {
    dispatch(setShowDemoRequestModal({ flag: true, calledFrom }));
    setIsConnectDropdownOpen(false);
  };

  const toggleDropdown = () => {
    if (showStartBanner) {
      setShowStartBanner(false);
    }
    if (isConnectDropdownOpen) {
      setIsConnectDropdownOpen(false);
    } else {
      setIsConnectDropdownOpen(true);
      setShowInputBanner(null);
    }
  };

  const renderInputs = () => {
    return (
      <>
        <input
          onChange={handleFolderChange}
          ref={folderInputRef}
          webkitdirectory=""
          mozdirectory=""
          msdirectory=""
          odirectory=""
          directory=""
          type="file"
          hidden
        />

        <input
          onChange={handleZipChange}
          ref={zipInputRef}
          accept=".zip"
          type="file"
          hidden
        />
      </>
    );
  };

  const renderHeadIcon = (icon: ReactNode, loading?: boolean): ReactNode => {
    if (loading) {
      return <div className={styles.spinner}></div>;
    } else {
      return <div className={styles.flexIcon}>{icon}</div>;
    }
  };

  const renderActionIcon = () => {
    if (datasetIndexing || isDatasetReady) {
      return <noscript />;
    }

    if (connectComplete) {
      return (
        <div className={styles.editButtonContainer}>
          <div
            ref={editButtonRef}
            className={styles.editButton}
            onClick={toggleDropdown}
          >
            Edit
          </div>
          {renderInputs()}
          {isConnectDropdownOpen && (
            <ConnectDropdown
              dropdownRef={connectDropdownRef}
              handleSelection={handleConnectOptionSelection}
              handleContactUsClick={handleContactUsClick}
              isDropdownOpen={isConnectDropdownOpen}
              toggleDropdown={toggleDropdown}
            />
          )}
        </div>
      );
    } else if (connectStatus !== StepTileProgressStatus.IN_PROGRESS) {
      return (
        <div className={actionIconsClassNames}>
          {renderInputs()}
          <PulsingDropdownIcon
            showPulse={showStartBanner}
            isDropdownOpen={isConnectDropdownOpen}
            toggleDropdown={toggleDropdown}
            containerRef={editButtonRef}
          >
            <ConnectDropdown
              dropdownRef={connectDropdownRef}
              handleSelection={handleConnectOptionSelection}
              handleContactUsClick={handleContactUsClick}
              isDropdownOpen={isConnectDropdownOpen}
              toggleDropdown={toggleDropdown}
            />
          </PulsingDropdownIcon>
        </div>
      );
    } else return null;
  };

  return (
    <div className={styles.connectStepContainer}>
      {showStartBanner && (
        <div className={styles.initalOverlay} onClick={toggleDropdown}></div>
      )}
      <StepTile
        title={connectInProgress ? "Generating Preview" : "Select Data Source"}
        status={connectStatus}
        headIcon={renderHeadIcon(<ConnectIcon />, connectInProgress)}
        actionIcon={renderActionIcon()}
        handleOutsideClick={handleOutsideClick}
        isContentShown={isConnectDropdownOpen}
      />

      <InputBannerWrapper
        showInputBanner={showInputBanner}
        setShowInputBanner={setShowInputBanner}
      />

      {showS3ProgressComponent && (
        <S3ProgressComponent progress={s3FetchProgress} />
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    newDatasetId: getNewDatasetId(state),
    connectStatus: getConnectStatus(state),
    s3FetchProgress: getS3FetchProgress(state),
    datasetIndexing: getIsDatasetIndexing(state),
    isNewDatasetInitializing: getIsNewDatasetInitializing(state),
    previewDataSource: getPreviewDataSource(state),
    isDatasetReady: getIsNewDatasetReady(state),
  };
};

export default connect(mapStateToProps)(ConnectStep);

interface ConnectStepProps {
  newDatasetId: string;
  showStartBanner: boolean;
  setShowStartBanner: SetStateFunction<boolean>;
  connectStatus: StepTileProgressStatus;
  s3FetchProgress: number;
  datasetIndexing: boolean;
  isNewDatasetInitializing: boolean;
  previewDataSource: DataSources | null;
  isDatasetReady: boolean;
}
