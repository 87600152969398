import CheckIcon from "assets/icons/CheckIcon";
import classNames from "classnames";

import styles from "./style.module.scss";

interface SelectionOptionProps {
  icon: string;
  title: string;
  onSelect: () => void;
  selected: boolean;
  badgeText?: string;
  description: string;
}

const SelectionOption = ({
  icon,
  title,
  selected,
  onSelect,
  badgeText,
  description,
}: SelectionOptionProps) => {
  const contentContainerClassnames = classNames(styles.contentContainer, {
    [styles.selectedBorder]: selected,
  });

  return (
    <label className={contentContainerClassnames} onClick={onSelect}>
      <div className={styles.content}>
        <div className={styles.contentIcon}>
          <img src={icon} alt="selection-icon" loading="eager" />
        </div>
        <div className={styles.contentTextContainer}>
          <div className={styles.contentTitleContainer}>
            <span className={styles.contentTitle}>{title}</span>
            {badgeText && <span className={styles.badge}>{badgeText}</span>}
          </div>

          <span className={styles.contentDescription}>{description}</span>
        </div>
      </div>
      <div className={styles.selectionIcon}>
        {selected && <CheckIcon color="#0197D8" />}
      </div>
    </label>
  );
};

export default SelectionOption;
