export default function CheckIcon({ color = "#fff" }: { color?: string }) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.17 13.87L14.8675 7.1725L13.5375 5.8425L8.17 11.21L5.4625 8.5025L4.1325 9.8325L8.17 13.87ZM9.5 19C8.18583 19 6.95083 18.7506 5.795 18.2519C4.63917 17.7531 3.63375 17.0763 2.77875 16.2213C1.92375 15.3663 1.24688 14.3608 0.748125 13.205C0.249375 12.0492 0 10.8142 0 9.5C0 8.18583 0.249375 6.95083 0.748125 5.795C1.24688 4.63917 1.92375 3.63375 2.77875 2.77875C3.63375 1.92375 4.63917 1.24688 5.795 0.748125C6.95083 0.249375 8.18583 0 9.5 0C10.8142 0 12.0492 0.249375 13.205 0.748125C14.3608 1.24688 15.3663 1.92375 16.2213 2.77875C17.0763 3.63375 17.7531 4.63917 18.2519 5.795C18.7506 6.95083 19 8.18583 19 9.5C19 10.8142 18.7506 12.0492 18.2519 13.205C17.7531 14.3608 17.0763 15.3663 16.2213 16.2213C15.3663 17.0763 14.3608 17.7531 13.205 18.2519C12.0492 18.7506 10.8142 19 9.5 19Z"
        fill={color}
      />
    </svg>
  );
}
