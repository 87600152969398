import { useState } from "react";
import { useDispatch } from "react-redux";
import { ImInfo } from "react-icons/im";

import { checkBucketList } from "redux/Datasets/actions";
import { validateBucket } from "helpers/validations";
import { LIGHT_GREEN, LIGHT_RED, SEMI_BLACK } from "helpers/constants/colors";

import DragDrop from "views/components/DragDrop";

import { MdAutorenew as AutoRenewImg } from "react-icons/md";
import { GoCheck as GreenTick, GoX as CrossImg } from "react-icons/go";

import S3UploadImage from "assets/pngImages/S3-upload.png";
import LocalImg from "assets/pngImages/Local_upload.png";

import styles from "./style.module.scss";

const S3InfoBox = () => {
  return (
    <div className={styles.s3InfoBox}>
      <div className={styles.singleRow}>
        <span className={styles.infoIcon}>
          <ImInfo size="10px" />
        </span>
        <span className={styles.infoText}>
          Please specify the S3 bucket path, e.g. bucket-name/folderA/folderB.{" "}
          <br /> A single Dataset must contain at least 2 Images and up to 10k
          Images.
        </span>
      </div>
    </div>
  );
};

const LocalInfoBox = () => {
  return (
    <div className={styles.localInfoBox}>
      <div className={styles.singleRow}>
        <span className={styles.infoIcon}>
          <ImInfo size="10px" />
        </span>
        <span className={styles.infoText}>
          A single Dataset must contain at least 2 Images and up to 10k Images.
        </span>
      </div>
    </div>
  );
};

function UploadDataset(props: any) {
  const [localLoading, setLocalLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const {
    bucketNameError,
    setBucketNameError,
    errorText,
    setErrorText,
    fileUrl,
    show,
    selectedSourceName,
    isLocalUpload,
    setFileUrl,
  } = props;

  const dispatch = useDispatch();

  const handleInputValidation = (value: any) => {
    let result = validateBucket(value);

    if (result) {
      setBucketNameError("");
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
      setBucketNameError(
        "Only lowercase letters, numbers, /, dots (.) and hyphens/low dashes (-/_) allowed."
      );
    }
  };

  const handleReset = (value: any) => {
    if (value === "") {
      setErrorText(null);
      setBucketNameError("");
      setIsButtonDisabled(true);
    }
  };

  const handleTest = async () => {
    setLocalLoading(true);
    //updates errortext in store to be "success" or ~"error message"
    await dispatch(checkBucketList(fileUrl) as any);

    setLocalLoading(false);
  };

  const handleIconDisplay = () => {
    if (localLoading) {
      return <AutoRenewImg size="1em" color={SEMI_BLACK} />;
    }
    if (!localLoading) {
      if (errorText === "success") {
        return <GreenTick size="1em" color={LIGHT_GREEN} />;
      } else if (errorText !== null && errorText !== "success") {
        return <CrossImg size="1em" color={LIGHT_RED} />;
      } else {
        return null;
      }
    }
  };

  return (
    <>
      <div className={show ? "d-block" : "d-none"}>
        <div className={styles.list}>
          <div className={styles.leftSection}>
            <div
              className={
                isLocalUpload ? styles.localSourceName : styles.s3SourceName
              }
            >
              {selectedSourceName}&nbsp;
            </div>
            {isLocalUpload ? <LocalInfoBox /> : <S3InfoBox />}
          </div>
          <div className={styles.rightSection}>
            <div
              className={
                isLocalUpload ? styles.localImage : styles.imageContainer
              }
            >
              <img src={isLocalUpload ? LocalImg : S3UploadImage} alt="" />
            </div>
          </div>
        </div>

        {!isLocalUpload && (
          <span className={styles.inputTitle}>Specify S3 bucket path:</span>
        )}

        <div className={isLocalUpload ? "d-none" : styles.addInputDataset}>
          <input
            type="text"
            placeholder="Enter S3 Bucket Path"
            className={`${fileUrl ? styles.uploaded : styles.addInput} ${
              bucketNameError || (errorText !== null && errorText !== "success")
                ? styles.errorInput
                : errorText === "success"
                ? styles.successInput
                : undefined
            }`}
            onChange={(e) => {
              setFileUrl(e.target.value);
              handleInputValidation(e.target.value);
              handleReset(e.target.value);
              dispatch(setErrorText(null));
            }}
            value={fileUrl}
          />
          <div
            className={styles.syncImg}
            onClick={() => {
              if (errorText) {
                setFileUrl("");
                dispatch(setErrorText(null));
                handleIconDisplay();
              }
            }}
          >
            {handleIconDisplay()}
          </div>

          {bucketNameError && (
            <span className={styles.bucketNameError}>{bucketNameError}</span>
          )}
          {errorText !== null && errorText !== "success" && fileUrl !== "" && (
            <span className={styles.isError}>{errorText}</span>
          )}
          <div className={styles.test}>
            <button
              onClick={() => {
                setIsButtonDisabled(true);
                handleTest();
              }}
              className={
                isButtonDisabled ? styles.disabledTestBtn : styles.testBtn
              }
            >
              Test
            </button>
          </div>
        </div>

        <DragDrop {...props} />
      </div>
    </>
  );
}

export default UploadDataset;
