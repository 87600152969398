export default function ConnectIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="35"
      viewBox="0 0 31 35"
      fill="none"
    >
      <g opacity="0.4">
        <path
          d="M13.9648 8.1036C13.9648 7.21474 13.2346 6.49518 12.3325 6.49518L4.44691 6.05176C3.29731 6.05176 2.36659 6.97085 2.36659 8.1036C2.36659 9.23635 3.29731 10.1534 4.44691 10.1534L12.3325 9.71202C13.2346 9.71202 13.9648 8.99246 13.9648 8.1036Z"
          fill="#EDEDEE"
        />
        <path
          d="M13.9648 8.1036C13.9648 7.21474 13.2346 6.49518 12.3325 6.49518L4.44691 6.05176C3.29731 6.05176 2.36659 6.97085 2.36659 8.1036C2.36659 9.23635 3.29731 10.1534 4.44691 10.1534L12.3325 9.71202C13.2346 9.71202 13.9648 8.99246 13.9648 8.1036"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M29.8402 10.2995C29.8402 10.2995 30.3147 9.83796 30.5213 9.55175C30.8404 9.13654 31 8.62459 31 8.11465C31 7.54223 30.82 7.01012 30.4804 6.57678C30.4007 6.49817 30.1 6.16359 29.8197 5.88746C28.1812 4.15407 23.904 1.31818 21.6641 0.451485C21.3246 0.314427 20.4654 0.0181396 20.0052 -4.80599e-07C19.5674 -4.99733e-07 19.146 0.0967464 18.7472 0.294271C18.2481 0.570403 17.8471 1.00375 17.6283 1.5157C17.4871 1.87044 17.2682 2.93264 17.2682 2.9528C17.0473 4.11578 16.9287 6.00637 16.9287 8.0945C16.9287 10.0839 17.0473 11.8958 17.2273 13.077C17.2478 13.0971 17.4667 14.4153 17.708 14.8688C18.1478 15.6952 19.007 16.2091 19.9254 16.2091L20.0052 16.2091C20.6045 16.189 21.8646 15.675 21.8646 15.6569C23.9838 14.7902 28.1608 12.0914 29.8402 10.2995Z"
        fill="#EDEDEE"
      />
      <g opacity="0.4">
        <path
          d="M17.0343 26.3409C17.0343 27.2298 17.7646 27.9493 18.6667 27.9493L26.5524 28.3907C27.7021 28.3907 28.6328 27.4737 28.6328 26.3409C28.6328 25.2082 27.7021 24.2891 26.5524 24.2891L18.6667 24.7325C17.7646 24.7325 17.0343 25.452 17.0343 26.3409Z"
          fill="#EDEDEE"
        />
        <path
          d="M17.0343 26.3409C17.0343 27.2298 17.7646 27.9493 18.6667 27.9493L26.5524 28.3907C27.7021 28.3907 28.6328 27.4737 28.6328 26.3409C28.6328 25.2082 27.7021 24.2891 26.5524 24.2891L18.6667 24.7325C17.7646 24.7325 17.0343 25.452 17.0343 26.3409"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M12.2518 34.1502C12.7509 33.8741 13.1498 33.4408 13.3707 32.9288C13.5119 32.5741 13.7307 31.5098 13.7307 31.4917C13.9517 30.3287 14.0703 28.4381 14.0703 26.35C14.0703 24.3606 13.9517 22.5487 13.7717 21.3675C13.7512 21.3474 13.5303 20.0292 13.2909 19.5757C12.8511 18.7473 11.992 18.2354 11.0715 18.2354L10.9917 18.2354C10.3923 18.2555 9.1343 18.7675 9.1343 18.7876C7.01507 19.6543 2.83592 22.3511 1.15854 24.145C1.15854 24.145 0.683959 24.6065 0.479399 24.8928C0.158242 25.3059 -0.00131449 25.8179 -0.00131452 26.3319C-0.00131454 26.9023 0.178698 27.4324 0.518266 27.8677C0.598044 27.9463 0.898746 28.2809 1.17695 28.557C2.81751 30.2884 7.09484 33.1263 9.33272 33.991C9.67229 34.1301 10.5335 34.4264 10.9917 34.4445C11.4315 34.4445 11.8529 34.3478 12.2518 34.1502Z"
        fill="#EDEDEE"
      />
    </svg>
  );
}
