import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { State } from "redux/store";

import { getExportProgress } from "redux/SingleDataset/selectors";

import LoadingGif from "assets/gif/download.gif";
import SuccessGif from "assets/gif/success.gif";
import ErrorGif from "assets/gif/error.gif";
import CheckIcon from "assets/icons/CheckIcon";
import XIcon from "assets/icons/XIcon";

import styles from "./style.module.scss";

interface VLToastComponentProps {
  status: "IN_PROGRESS" | "SUCCESS" | "ERROR";
  title: string;
  onClose?: () => void;
  helperText: string | JSX.Element;
  description: string;
  progress: number;
}

const VLToastComponent = ({
  status,
  title,
  onClose,
  progress,
  helperText,
  description,
}: VLToastComponentProps) => {
  const toastRef = useRef<HTMLDivElement>(null);

  const renderIcon = () => {
    switch (status) {
      case "IN_PROGRESS":
        return (
          <img
            loading="eager"
            src={LoadingGif}
            alt="toast-progress-status-icon"
          />
        );
      case "SUCCESS":
        return (
          <img
            loading="eager"
            src={SuccessGif}
            alt="toast-success-status-icon"
          />
        );
      case "ERROR":
        return (
          <img src={ErrorGif} alt="toast-error-status-icon" loading="eager" />
        );
    }
  };

  const handleCloseClick = () => {
    onClose && onClose();
  };

  const handleOutsideClick = (event: any) => {
    if (toastRef.current && !toastRef.current.contains(event.target)) {
      handleCloseClick();
    }
  };

  const renderProgressBar = () => {
    if (status !== "IN_PROGRESS") {
      return <noscript />;
    }

    return (
      <div className={styles.progressBarContainer}>
        <div className={styles.bar} style={{ width: `${progress}%` }} />
      </div>
    );
  };

  useEffect(() => {
    if (status) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [status]);

  return (
    <div ref={toastRef} className={styles.toast}>
      <div className={styles.main}>
        <div className={styles.toastIcon}>{renderIcon()}</div>
        <div className={styles.textContainer}>
          <div className={styles.titleContainer}>
            <span className={styles.titleText}>{title}</span>
            <div className={styles.closeButton} onClick={handleCloseClick}>
              <XIcon />
            </div>
          </div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>

      {renderProgressBar()}

      <div className={styles.helper}>
        <div className={styles.helperIcon}>
          <CheckIcon color="#34A853" />
        </div>
        <span className={styles.helperText}>{helperText}</span>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    progress: getExportProgress(state),
  };
};

export default connect(mapStateToProps)(VLToastComponent);
