import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import axios from "helpers/axiosInstance";
import { composeApiURL } from "helpers/utility/axiosHelpers";

import { State } from "redux/store";

import {
  SHOW_SIGN_UP_SUCCESS_MODAL,
  SHOW_IMAGE_OPTIONS_MODAL,
  SHOW_MOBILE_ERROR_MODAL,
  SHOW_INVITATION_MODAL,
  SHOW_PROGRESS_MODAL,
  SHOW_UPGRADE_MODAL,
  SHOW_PROFILE_MODAL,
  SHOW_SIGN_UP_MODAL,
  SHOW_FREE_MODAL,
  SHOW_PRO_MODAL,
  SHOW_MODAL,
  SET_IMAGE_DATA,
  SET_IMAGE_DATA_LOADER,
  SET_IMAGE_ISSUES,
  SET_IMAGE_LABELS,
  SET_SELECTED_LABELS,
  SET_SELECTED_ISSUES,
  SET_SELECTED_CAROUSEL_INDEX,
  SET_IMAGE_TAGS,
  IS_IMAGE_TAG_LOADING,
  SHOW_DEMO_REQUEST_MODAL,
  SHOW_DEMO_REQUEST_SUCCESS_MODAL,
  SHOW_LEARN_MORE_MODAL,
  SHOW_DATASET_ERROR_MODAL,
  SHOW_INTERNAL_ERROR_MODAL,
  SET_QUOTA_LIMIT_MODAL_DATA,
  SHOW_SELECT_PLAN_MODAL,
} from "./constants";
import { amplitudeTrack } from "helpers/utility/amplitude";
import { AMP_DATASET_EXPLORATION_EVENT__USER_TAG__ASSIGNED } from "helpers/constants/amplitudeEvents";
import {
  AMP_DATASET_EXPLORATION_PROPERTY__CONTEXT,
  AMP_DATASET_EXPLORATION_PROPERTY__TARGET,
} from "helpers/constants/amplitudeProperties";
import {
  ContactUsComponentType,
  Label,
  QuotaLimitModalData,
  SelectPlanModalData,
} from "types";
import { generateRandomColor, isFastdupUser } from "helpers/utility/utilities";

export const setShowSignUpSuccessModal = (data: boolean) => {
  return { type: SHOW_SIGN_UP_SUCCESS_MODAL, data };
};

export const setShowInvitationModal = (data: boolean) => {
  return { type: SHOW_INVITATION_MODAL, data };
};

export const setshowProfileModal = (data: boolean) => {
  return { type: SHOW_PROFILE_MODAL, data };
};

export const setShowMobileErrorModal = (data: boolean) => {
  return { type: SHOW_MOBILE_ERROR_MODAL, data };
};

export const setShowUpgradeModal = (data: boolean) => {
  return { type: SHOW_UPGRADE_MODAL, data };
};

export const setShowSignUpModal = (data: boolean) => {
  return { type: SHOW_SIGN_UP_MODAL, data };
};

export const setShowFreeModal = (data: boolean) => {
  return { type: SHOW_FREE_MODAL, data };
};

export const setShowProModal = (data: boolean) => {
  return { type: SHOW_PRO_MODAL, data };
};

export const setShowImageOptionsModal = (data: boolean) => {
  return { type: SHOW_IMAGE_OPTIONS_MODAL, data };
};

export const setShowProgressModal = (data: boolean) => {
  return { type: SHOW_PROGRESS_MODAL, data };
};

export const setImageData = (data: any) => {
  return { type: SET_IMAGE_DATA, data };
};

export const setImageDataLoader = (data: boolean) => {
  return { type: SET_IMAGE_DATA_LOADER, data };
};

export const setImageLabels = (data: Label[]) => {
  return { type: SET_IMAGE_LABELS, data };
};

export const setImageIssues = (data: any) => {
  return { type: SET_IMAGE_ISSUES, data };
};

export const setSelectedCarouselIndex = (data: any) => {
  return { type: SET_SELECTED_CAROUSEL_INDEX, data };
};

export const setShowDatasetErrorModal = (
  data: { datasetId: string; errorMessage: string } | null
) => {
  return { type: SHOW_DATASET_ERROR_MODAL, data };
};

export const fetchImageInfo = (
  imageId: string | null,
  selectedLabelID?: string | false
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    dispatch(setImageDataLoader(true));
    dispatch(setImageIssues([]));
    dispatch(setImageLabels([]));
    dispatch(setSelectedLabels([]));
    dispatch(setSelectedIssues([]));

    dispatch(setImageData(null));
    try {
      //fetch Image Data
      try {
        const url1 = composeApiURL(
          process.env.REACT_APP_API_ENDPOINT!,
          `/image/${imageId}`
        );
        const imgResponse = await axios.get(url1);

        if (Object.keys(imgResponse.data).length > 0) {
          dispatch(setImageData(imgResponse.data));
        } else dispatch(setImageData(null));
      } catch (err) {
        console.log("Error fetching Image Data:", err);
        dispatch(setImageData(null));
      }

      //fetch image labels and recreate them
      try {
        const url2 = composeApiURL(
          process.env.REACT_APP_API_ENDPOINT!,
          `/image/${imageId}/labels`
        );
        const labelsResponse = await axios.get(url2);

        if (labelsResponse.data?.length > 0) {
          const labels = [...labelsResponse.data];
          let newLabels: Label[] = [];
          let selectedLabels: any[] = [];

          labels.forEach((singleLabel: any) => {
            const existsIndex = newLabels.findIndex((obj: any) => {
              return (
                obj.categoryName === singleLabel.category_display_name &&
                obj.type === singleLabel.type &&
                obj.source === singleLabel.source
              );
            });

            if (existsIndex !== -1 && singleLabel.bounding_box) {
              newLabels[existsIndex].labels.push(singleLabel);
            } else {
              newLabels.push({
                categoryName: singleLabel.category_display_name,
                labels: singleLabel.bounding_box ? [singleLabel] : [],
                color: generateRandomColor(),
                type: singleLabel.type_display_name,
                source: singleLabel.source,
              });
            }
          });
          dispatch(setImageLabels(newLabels));

          //add all to selectedlabels with a selected boolean key
          newLabels.forEach((label: any) =>
            label.labels.forEach((subLabel: any, index: number) => {
              selectedLabels.push({
                id: subLabel.id,
                boundingBox: subLabel.bounding_box,
                selected: selectedLabelID
                  ? subLabel.id === selectedLabelID
                  : true,
                name: `${label.categoryName} | ${index + 1}`,
                color: label.color,
                source: label.source,
              });
            })
          );
          dispatch(setSelectedLabels(selectedLabels));
        } else dispatch(setImageLabels([]));
      } catch (err) {
        console.log("Error fetching Image Labels:", err);
        dispatch(setImageLabels([]));
      }

      //fetch Image Issues
      try {
        const url3 = composeApiURL(
          process.env.REACT_APP_API_ENDPOINT!,
          `/image/${imageId}/issues`
        );
        const issuesResponse = await axios.get(url3);

        if (issuesResponse.data?.length > 0) {
          dispatch(setImageIssues(issuesResponse.data));
        } else dispatch(setImageIssues([]));
      } catch (err) {
        console.log("Error fetching Image Issues:", err);
        dispatch(setImageIssues([]));
      }

      dispatch(fetchImageTags(imageId) as any);

      dispatch(setImageDataLoader(false));
    } catch (error) {
      console.log(error);
      dispatch(setImageDataLoader(false));
      return;
    }
  };
};

export const setShowModal = (data: boolean) => {
  return { type: SHOW_MODAL, data };
};

export const setSelectedLabels = (data: any) => {
  return { type: SET_SELECTED_LABELS, data };
};

export const updateSelectedLabels = (
  id: string,
  boundingBox: number[],
  color: string,
  name: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const selectedLabels = [...getState().modals.selectedLabels];
    const existingLabel = selectedLabels.find((label: any) => label.id === id);
    if (existingLabel) {
      let updatedLabels = selectedLabels.map((label: any) => {
        if (label.id === id) {
          return { ...label, selected: !label.selected };
        } else return label;
      });
      dispatch(setSelectedLabels(updatedLabels));
    } else {
      selectedLabels.push({
        id: id,
        boundingBox: boundingBox,
        color: color,
        name: name,
        selected: true,
      });
      dispatch(setSelectedLabels(selectedLabels));
    }
  };
};

export const addAllLabels = (
  labelsToUpdate: any[]
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    let selectedLabels = [...getState().modals.selectedLabels];

    labelsToUpdate.forEach((labelToAdd: any) => {
      const indexOfLabel = selectedLabels.findIndex(
        (label: any) => label.id === labelToAdd.id
      );
      if (indexOfLabel > -1) {
        selectedLabels = [
          ...selectedLabels.slice(0, indexOfLabel),
          { ...selectedLabels[indexOfLabel], selected: true },
          ...selectedLabels.slice(indexOfLabel + 1),
        ];
      }
    });

    dispatch(setSelectedLabels(selectedLabels));
  };
};

export const removeAllLabels = (
  labelsToUpdate: any[]
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    let selectedLabels = [...getState().modals.selectedLabels];

    labelsToUpdate.forEach((labelToUpdate: any) => {
      const labelIndex = selectedLabels.findIndex(
        (label: any) => label.id === labelToUpdate.id
      );
      if (labelIndex > -1) {
        selectedLabels = [
          ...selectedLabels.slice(0, labelIndex),
          { ...selectedLabels[labelIndex], selected: false },
          ...selectedLabels.slice(labelIndex + 1),
        ];
      }
    });

    dispatch(setSelectedLabels(selectedLabels));
  };
};

export const setSelectedIssues = (data: any) => {
  return { type: SET_SELECTED_ISSUES, data };
};

export const updateSelectedIssues = (
  issueObject: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    try {
      const selectedIssues = [...getState().modals.selectedIssues];
      const labels = [...getState().modals.imageLabels];

      const relatedLabel = labels.find((singleLabel: any) =>
        singleLabel.labels.some(
          (subLabel: any) => subLabel.id === issueObject.cause
        )
      );

      if (!relatedLabel) {
        console.error("Related label not found");
        return;
      }

      const matchingSublabel = relatedLabel.labels.find(
        (subLabel: any) => subLabel.id === issueObject.cause
      );

      if (!matchingSublabel) {
        console.error("Matching sublabel not found");
        return;
      }

      const updatedIssueObject = {
        ...issueObject,
        boundingBox: matchingSublabel.bounding_box,
        relatedLabel: matchingSublabel,
      };

      const isIssueSelected = selectedIssues.find(
        (singleIssue: any) => singleIssue.id === issueObject.id
      );

      if (isIssueSelected) {
        const updatedIssues = selectedIssues.filter(
          (singleIssue: any) => singleIssue.id !== issueObject.id
        );
        dispatch(setSelectedIssues(updatedIssues));
      } else {
        selectedIssues.push(updatedIssueObject);
        dispatch(setSelectedIssues(selectedIssues));
      }
    } catch (error) {
      console.error("Error updating selected issues", error);
    }
  };
};

export const setImageTags = (data: any) => {
  return { type: SET_IMAGE_TAGS, data };
};

export const setImageTagLoading = (data: boolean) => {
  return { type: IS_IMAGE_TAG_LOADING, data };
};

export const fetchImageTags = (
  imageID: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    dispatch(setImageTags([]));
    dispatch(setImageTagLoading(true));
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/media/${imageID}/tags`
      );
      const response = await axios.get(url);
      dispatch(setImageTags(response.data));
      dispatch(setImageTagLoading(false));
    } catch (error) {
      dispatch(setImageTagLoading(false));
      console.error(error);
    }
  };
};

export const updateImageTags = (
  tagIDs: any[]
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    let imageID = getState().modals.imageData.id;
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/media/${imageID}/tags`
      );
      const response = await axios.post(url, { payload: { tags: tagIDs } });
      dispatch(setImageTags(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const addImageTag = (
  tagID: any,
  tagName: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const datasetId = getState().singleDataset.stats?.dataset?.id;
    const imageID = getState().modals.imageData.id;
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${datasetId}/media/${imageID}/tag`
      );
      const response = await axios.post(url, { payload: { tag: tagID } });
      dispatch(setImageTags(response.data));

      if (response.data && response.data.length > 0) {
        amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__USER_TAG__ASSIGNED, {
          [AMP_DATASET_EXPLORATION_PROPERTY__TARGET]: "Image",
          [AMP_DATASET_EXPLORATION_PROPERTY__CONTEXT]: "Image",
        });
      }

      let recentTags = JSON.parse(localStorage.getItem("recent_tags") || "[]");
      const existingTag = recentTags.find(
        (tag: any) => tag.name?.toLowerCase() === tagName.toLowerCase()
      );

      if (!existingTag) {
        recentTags.push({ id: tagID, name: tagName });
        localStorage.setItem("recent_tags", JSON.stringify(recentTags));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteImageTag = (
  tagID: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const datasetId = getState().singleDataset.stats?.dataset?.id;
    let imageID = getState().modals.imageData.id;
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${datasetId}/media/${imageID}/tag/${tagID}`
      );
      const response = await axios.delete(url);
      dispatch(setImageTags(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const setShowDemoRequestModal = (data: {
  flag: boolean;
  calledFrom: ContactUsComponentType;
}) => {
  return { type: SHOW_DEMO_REQUEST_MODAL, data };
};

export const setShowDemoRequestSuccessModal = (data: boolean) => {
  return { type: SHOW_DEMO_REQUEST_SUCCESS_MODAL, data };
};

export const setShowLearnMoreModal = (data: boolean) => {
  return { type: SHOW_LEARN_MORE_MODAL, data };
};

type FormDataKeys = {
  fullName: string;
  email: string;
  phone?: string;
  jobTitle: string;
  company: string;
  notes?: string;
  datasetID?: string;
  calledFrom: ContactUsComponentType;
};

const createFormData = (formData: FormDataKeys): FormData => {
  const bodyFormData = new FormData();

  bodyFormData.append("full_name", formData.fullName.trim());
  bodyFormData.append("email", formData.email.trim());
  bodyFormData.append("job_title", formData.jobTitle.trim());
  bodyFormData.append("company_name", formData.company.trim());

  if (formData.datasetID) {
    bodyFormData.append("dataset_id", formData.datasetID);
  }
  if (formData.phone) {
    bodyFormData.append("phone_number", formData.phone.trim());
  }
  if (formData.notes) {
    bodyFormData.append("notes", formData.notes.trim());
  }
  if (formData.calledFrom) {
    bodyFormData.append("feature_name", formData.calledFrom.trim());
  }
  return bodyFormData;
};

//returns true or false based on success or failure
export const submitDemoRequest = (
  formData: FormDataKeys
): ThunkAction<void, State, unknown, any> => {
  return async () => {
    try {
      const url = composeApiURL(
        isFastdupUser()
          ? "https://app.visual-layer.com"
          : process.env.REACT_APP_API_ENDPOINT!,
        `/request_feature`
      );

      const bodyFormData = createFormData(formData);

      const response = await axios.post(url, bodyFormData);
      if (response.status === 200) return true;
      else return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
};

export const setShowInternalErrorModal = (data: any) => {
  return { type: SHOW_INTERNAL_ERROR_MODAL, data };
};

export const setQuotaLimitModalData = (data: QuotaLimitModalData) => {
  return { type: SET_QUOTA_LIMIT_MODAL_DATA, data };
};

export const setSelectPlanModalData = (data: SelectPlanModalData) => {
  return { type: SHOW_SELECT_PLAN_MODAL, data };
};
