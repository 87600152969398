import { State } from "redux/store";
import classNames from "classnames";
import { connect, useDispatch } from "react-redux";
import { Fragment, ReactNode, useState } from "react";
import { setShowActivityLogModal } from "redux/CreateDataset/actions";
import { getFilteredActivityLogs } from "redux/CreateDataset/selectors";
import moment from "moment";

import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import DangerCircleIcon from "assets/icons/DangerCircleIcon";
import CheckIcon from "assets/icons/CheckIcon";
import { Event } from "extractedBackendTypes";
import XIcon from "assets/icons/XIcon";

import styles from "./styles.module.scss";

const ProgressPaneHeader = ({
  handleDropdownClick,
  isProgressPaneOpen,
}: ProgressPaneHeaderProps) => {
  return (
    <div className={styles.paneHeader}>
      <span>Activity Log</span>
      <div className={styles.dropdownButton} onClick={handleDropdownClick}>
        {isProgressPaneOpen ? (
          <BiChevronDown size={"1.55em"} />
        ) : (
          <BiChevronUp size={"1.55em"} />
        )}
      </div>
    </div>
  );
};

const ProgressPaneRow = ({
  timestamp,
  icon,
  title,
  reason,
  severity,
}: ProgressPaneRowProps) => {
  const formattedTime = moment.utc(timestamp).local().format("hh:mm A");
  const dispatch = useDispatch();
  const isActivityLogError = severity === "WARNING" || severity === "ERROR";
  const dynamicTitle = isActivityLogError && reason !== "" ? reason : title;

  const singlePaneRowDetailsClassNames = classNames(
    styles.singlePaneRowDetails,
    { [styles.linkText]: isActivityLogError }
  );

  const handleActivityLogClick = () => {
    if (isActivityLogError) {
      dispatch(
        setShowActivityLogModal({ show: true, reason, title, severity } as any)
      );
    }
  };

  return (
    <div className={styles.singlePaneRow}>
      <div className={styles.singlePaneRowTime}>{formattedTime}</div>
      <div className={styles.singlePaneRowStatus}>{icon}</div>
      <div
        className={singlePaneRowDetailsClassNames}
        onClick={handleActivityLogClick}
      >
        <span className={styles.singlePaneRowText} title={dynamicTitle}>
          {dynamicTitle}
        </span>
      </div>
    </div>
  );
};

const ProgressPaneBody = ({
  isProgressPaneOpen,
  activityLogs,
}: ProgressPaneBodyProps) => {
  const renderStatusIcon = (status: Event["severity"]) => {
    switch (status) {
      case "ERROR":
        return (
          <div className={styles.errorStatusIcon}>
            <XIcon color="#151928" />
          </div>
        );

      case "WARNING":
        return <DangerCircleIcon variant="filled" color="#DE6F46" />;

      case "OK":
        return <CheckIcon color="#0DC973" />;
    }
  };
  const renderReason = (reason: string): string => {
    if (reason && reason.length > 1) {
      return reason.charAt(0).toUpperCase() + reason.slice(1);
    } else {
      return "";
    }
  };

  const renderDynamicTitle = (activityLog: any) => {
    switch (activityLog.event_type) {
      case "AnnotatedPreview":
        return "Annotations applied.";
      case "PreviewReady":
        return "Data preview generated.";
      case "FileUploaded":
        return "File/folder uploaded successfully.";
      case "ArchiveUploaded":
        return "ZIP file uploaded successfully.";
      case "S3Connected":
        return "Connected successfully.";
      case "DatasetInitialized":
        return "Dataset initialized.";
      case "DatasetInitializationFailed":
        return "Dataset initialization failed.";
      case "FatalDatasetStatus":
        return "Dataset creation failed.";
      case "S3InvalidURL":
        return "Invalid S3 URL.";
      case "S3NoAccess":
        return "No access to S3 data source.";
      case "AnnotationsValid":
        return "Annotations validated.";
      case "AnnotationsInvalid":
        return "Invalid annotations.";
      case "InvalidInput":
        return "Invalid input.";
      case "S3Error":
        return "Internal Error.";
      case "ServerFailure":
        return "Internal Server Error.";
      case "LimitExceeded":
        return "Exceeded size limitations.";
      case "DatasetStatus":
        switch (activityLog.status as DatasetStatusTypes) {
          case "DatasetStatus.READY":
            return "Dataset ready.";
          case "DatasetStatus.FATAL_ERROR":
            return "Dataset encountered an error.";
          case "DatasetStatus.INDEXING":
            return "Dataset indexing.";
          case "DatasetStatus.UPLOADING":
            return "Dataset uploading.";
          case "DatasetStatus.SAVING":
            return "Dataset saving.";
        }
        break;

      default:
        const spaced = activityLog.event_type.replace(
          /([a-z])([A-Z])/g,
          "$1 $2"
        );
        return (
          spaced.charAt(0).toUpperCase() + spaced.slice(1).toLowerCase() + "."
        );
    }
  };

  if (!isProgressPaneOpen) return <noscript />;
  return (
    <div className={styles.paneBody}>
      {activityLogs.map((activityLog: any, index: number) => {
        return (
          <Fragment key={index}>
            <ProgressPaneRow
              icon={renderStatusIcon(activityLog.severity)}
              title={renderDynamicTitle(activityLog)}
              reason={renderReason(activityLog.reason)}
              timestamp={activityLog.timestamp}
              severity={activityLog.severity}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

const ProgressPane = ({ activityLogs }: ProgressPaneProps) => {
  const [isProgressPaneOpen, setIsProgressPaneOpen] = useState(true);

  const handleDropdownClick = () => {
    setIsProgressPaneOpen(!isProgressPaneOpen);
  };

  if (activityLogs.length <= 0) return <noscript />;
  return (
    <div className={styles.progressPaneContainer}>
      <ProgressPaneHeader
        isProgressPaneOpen={isProgressPaneOpen}
        handleDropdownClick={handleDropdownClick}
      />
      <div className={styles.divider}></div>
      <ProgressPaneBody
        activityLogs={activityLogs}
        isProgressPaneOpen={isProgressPaneOpen}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    activityLogs: getFilteredActivityLogs(state),
  };
};

export default connect(mapStateToProps)(ProgressPane);

interface ProgressPaneProps {
  activityLogs: any[];
}

interface ProgressPaneHeaderProps {
  handleDropdownClick: () => void;
  isProgressPaneOpen: boolean;
}

interface ProgressPaneBodyProps {
  activityLogs: any[];
  isProgressPaneOpen: boolean;
}

interface ProgressPaneRowProps {
  icon: ReactNode;
  title: string;
  reason?: string;
  timestamp: string;
  severity: string;
}

// temp solution, replace when BE sends the type
type DatasetStatusTypes =
  | "DatasetStatus.READY"
  | "DatasetStatus.FATAL_ERROR"
  | "DatasetStatus.INDEXING"
  | "DatasetStatus.UPLOADING"
  | "DatasetStatus.SAVING";
