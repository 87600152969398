const ObjectIcon = ({ color = "#fff" }: { color?: string }) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.1943 7.6114L5.3886 3.8057L9.1943 0L13 3.8057L9.1943 7.6114ZM0 6.26425V0.875648H5.3886V6.26425H0ZM6.73575 13V7.6114H12.1244V13H6.73575ZM0 13V7.6114H5.3886V13H0ZM1.34715 4.9171H4.04145V2.2228H1.34715V4.9171ZM9.21114 5.72539L11.114 3.82254L9.21114 1.91969L7.30829 3.82254L9.21114 5.72539ZM8.0829 11.6528H10.7772V8.95855H8.0829V11.6528ZM1.34715 11.6528H4.04145V8.95855H1.34715V11.6528Z"
        fill={color}
      />
    </svg>
  );
};

export default ObjectIcon;
