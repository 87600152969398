import {
  RESET_STORE,
  IS_CONTENT_LOADING,
  IS_LABEL_LOADING,
  IS_ISSUE_TYPE_LOADING,
  SET_DATASET_SORTING_VALUE,
  SET_FILTER_ISSUE,
  SET_FILTER_SEVERITY,
  SET_ISSUE_DATA,
  SET_SEVERITY_DATA,
  IS_CARDSLIST_LOADING,
  SET_SIMILARITY_DATA,
  SET_SIMILARITY_VERTEX,
  SET_SIMILARITY_THRESHOLD,
  SET_SIMILARITY_THRESHOLDS,
  SET_ENTITY_TYPE_FILTER,
  SET_DATASET_LABELS,
  SET_DATASET_ISSUE_TYPES,
  SET_EXPLORATION_STATS,
  SET_NAVIGATION_CLUSTER,
  SET_NAVIGATION_CLUSTER_LOADER,
  SET_SEARCH_TEXT,
  SET_FILE_NAME_SEARCH_TEXT,
  SET_TIME_RANGE,
  SET_FILTER_CONFIG,
  SET_TREE_FOLDER,
  SET_FILTER_FOLDER,
  SET_EXPORT_DATA,
  SET_SHOW_CART,
  SET_TEMP_EXPORT_DATA,
  SET_EXPORT_DATA_TIMEOUT_ID,
  SET_PAGINATION_METADATA_FOR_DATA,
  SET_PAGINATION_METADATA_FOR_CLUSTER,
  SET_CLUSTER_METADATA_SUMMARY,
  SET_CLUSTER_METADATA_SUMMARY_LOADING,
  SET_USER_TAGS,
  UPDATE_USER_TAGS,
  IS_USER_TAG_LOADING,
  UPDATE_FILE_SELECTION,
  SET_SIMILARITY_VERTEX_ID,
  SET_DATA_PAGE_NUMBER,
  SET_CLUSTER_PAGE_NUMBER,
  SET_NAVIGATION_CONTEXT,
  SET_IS_IMAGE_UPLOADING,
  HOVERED_FILE_STATE,
  SET_FETCH_ERROR,
  SET_DATA_METADATA_SUMMARY,
  SET_DATA_METADATA_SUMMARY_LOADING,
  SET_AUTOCOMPLETE_SUGGESTIONS,
  SET_RECENT_SEARCHES,
  SET_AUTOCOMPLETE_LOADING,
  SET_AUTOCOMPLETE_NO_RESULTS_FLAG,
  SET_VERTEX_PREVIEWS_COUNT_CONTEXT,
  SET_IMAGE_PAGE_PARAMS_CONTEXT,
  SET_VERTEX_CONTEXT,
  SET_ASSIGNED_TAGS,
  SET_SIMILARITY_VERTEX_TYPE,
  SET_SHOW_EXPORT_MODAL,
  SET_EXPORT_PROGRESS,
  SET_SHOW_SHARING_MENU,
  SET_USERS_WITH_ACCESS,
  SET_DATASET_TAGS,
} from "./constants";
import { FilterOption } from "types";

const initialEntityTypeFilter: Array<FilterOption> = [
  { id: 0, text: "Images", selected: false },
  { id: 1, text: "Objects", selected: false },
];
const initialImagePageParamsContext = {
  imageId: "",
  searchRegion: "",
};

const initialExportData =
  JSON.parse(localStorage.getItem("cart__data") as string) || {};

const initialRecentSearches =
  JSON.parse(localStorage.getItem("recent_searches") as string) || {};

const INITIAL_STATE = {
  datasetSortingValue: "Severity",
  filterIssue: [],
  filterSeverity: [],
  isContentLoading: false,
  isLoading: false,
  issues: null,
  stats: null,
  similarityVertexCluster: null,
  vertexID: null,
  vertexType: null,
  similarityClusters: [],
  isCardslistLoading: true,
  isLabelLoading: false,
  isIssueTypeLoading: false,
  explorationStats: null,
  navigationCluster: null,
  navigationClusterLoader: false,
  filterConfig: [],
  treeFolder: null,
  exportData: initialExportData,
  showCart: false,
  tempExportData: null,
  exportDataTimeoutID: null,
  paginationMetadataForData: null,
  paginationMetadataForCluster: null,
  clusterMetadataSummaryLoading: true,
  clusterMetadataSummary: null,
  datasetTags: [],

  // filters
  similarityThreshold: 2,
  labels: [],
  issueTypes: [],
  searchText: "",
  fileNameSearchText: "",
  entityTypeFilter: initialEntityTypeFilter,
  pageNumber: 1,
  dataPageNumber: null,
  clusterPageNumber: null,
  timeRange: null,
  filterFolder: null,
  userTags: [],
  //----------------------------
  navigationContext: [],
  isImageUploading: false,
  hoveredFileState: null,
  fetchError: false,
  dataMetadataSummaryLoading: true,
  dataMetadataSummary: null,
  autocompleteSuggestions: [],
  recentSearches: initialRecentSearches,
  autocompleteLoading: false,
  autocompleteNoResultsFlag: false,
  vertexPreviewsCountContext: 0,
  imagePageParamsContext: initialImagePageParamsContext,
  vertexContext: null,
  assignedTags: [],
  showExportModal: false,
  exportProgress: 0,
  exportFileLink: null,
  showSharingMenu: false,
  usersWithAccess: [],
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RESET_STORE:
      const filterConfig = state.filterConfig;
      const exportData = state.exportData;
      return {
        ...state,
        ...INITIAL_STATE,
        filterConfig,
        exportData,
      };

    case IS_CONTENT_LOADING:
      return { ...state, isContentLoading: action.data };

    case IS_CARDSLIST_LOADING:
      return { ...state, isCardslistLoading: action.data };

    case IS_LABEL_LOADING:
      return { ...state, isLabelLoading: action.data };

    case IS_USER_TAG_LOADING:
      return { ...state, isUserTagLoading: action.data };

    case IS_ISSUE_TYPE_LOADING:
      return { ...state, isIssueTypeLoading: action.data };

    case SET_ISSUE_DATA:
      return { ...state, issues: action.data };

    case SET_SEVERITY_DATA:
      return { ...state, stats: action.data };

    case SET_FILTER_ISSUE:
      return { ...state, filterissue: action.data };

    case SET_FILTER_SEVERITY:
      return { ...state, filterSeverity: action.data };

    case SET_DATASET_SORTING_VALUE:
      return { ...state, datasetSortingValue: action.data };

    case SET_EXPLORATION_STATS:
      return { ...state, explorationStats: action.data };

    case SET_NAVIGATION_CLUSTER:
      return { ...state, navigationCluster: action.data };

    case SET_NAVIGATION_CLUSTER_LOADER:
      return { ...state, navigationClusterLoader: action.data };

    case SET_SIMILARITY_DATA:
      let similarityClusters = action.data;
      if (similarityClusters === null) {
        similarityClusters = [];
      }
      return { ...state, similarityClusters };

    case SET_SIMILARITY_VERTEX_ID:
      return {
        ...state,
        vertexID: action.data,
      };

    case SET_SIMILARITY_VERTEX_TYPE:
      return {
        ...state,
        vertexType: action.data,
      };

    case SET_SIMILARITY_VERTEX:
      return { ...state, similarityVertexCluster: action.data };

    case SET_FILTER_CONFIG:
      return {
        ...state,
        filterConfig: action.data,
        pageNumber: 1,
      };

    case SET_TREE_FOLDER:
      return { ...state, treeFolder: action.data };

    case SET_EXPORT_DATA:
      return { ...state, exportData: action.data };

    case SET_TEMP_EXPORT_DATA:
      return { ...state, tempExportData: action.data };

    case SET_EXPORT_DATA_TIMEOUT_ID:
      return { ...state, exportDataTimeoutID: action.data };

    case SET_SHOW_CART:
      return { ...state, showCart: action.data };

    case SET_PAGINATION_METADATA_FOR_DATA:
      return { ...state, paginationMetadataForData: action.data };

    case SET_PAGINATION_METADATA_FOR_CLUSTER:
      return { ...state, paginationMetadataForCluster: action.data };

    case SET_CLUSTER_METADATA_SUMMARY:
      return { ...state, clusterMetadataSummary: action.data };

    case SET_CLUSTER_METADATA_SUMMARY_LOADING:
      return { ...state, clusterMetadataSummaryLoading: action.data };

    // filters
    case SET_SIMILARITY_THRESHOLDS:
      return {
        ...state,
        similarityThresholds: action.data.similarity_thresholds,
      };

    case SET_SIMILARITY_THRESHOLD:
      return {
        ...state,
        similarityClusters: [],
        similarityVertexCluster: null,
        similarityThreshold: action.data.threshold,
      };

    case SET_DATASET_LABELS:
      return { ...state, labels: action.data };

    case SET_DATASET_ISSUE_TYPES:
      return { ...state, issueTypes: action.data };

    case SET_SEARCH_TEXT:
      return { ...state, searchText: action.data };

    case SET_FILE_NAME_SEARCH_TEXT:
      return { ...state, fileNameSearchText: action.data };

    case SET_ENTITY_TYPE_FILTER:
      return { ...state, entityTypeFilter: action.data };

    case SET_DATA_PAGE_NUMBER:
      return { ...state, dataPageNumber: action.data };

    case SET_CLUSTER_PAGE_NUMBER:
      return { ...state, clusterPageNumber: action.data };

    case SET_TIME_RANGE:
      return { ...state, timeRange: action.data };

    case SET_FILTER_FOLDER:
      return { ...state, filterFolder: action.data };

    case UPDATE_USER_TAGS:
      // Until we have org-tags we're hacking around by "accumalating" tags so here we concat tags and then deduplicate them by id
      const newTags = [...state.userTags, ...action.data].reduce(
        (acc, current) => {
          const x = acc.find((item: any) => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        },
        []
      );
      return { ...state, userTags: newTags };

    case SET_USER_TAGS:
      return { ...state, userTags: action.data };

    case SET_DATASET_TAGS:
      return { ...state, datasetTags: action.data };

    case UPDATE_FILE_SELECTION: {
      const { title, isSelected } = action.data;
      const filterFolder = isSelected
        ? title.startsWith("/")
          ? title
          : "/" + title
        : "";

      // Ugly Hack:
      // ideally the tree-folder includes the entire file tree and thus it also includes the path of the selected file
      // but due to performance issues we're only loading the top most folders so the file tree is incomplete and the path might also be missing
      // to overcome this we're updating the filter-folder with the selected file path recursively here
      const pathParts = title.split("/");
      const root = state.treeFolder
        ? { ...(state.treeFolder as Object) }
        : { full_path: "/", name: "/", children: [], type: "ROOT" };
      let fullPath = "";
      let node = root;
      pathParts.forEach((part: string, index: number) => {
        fullPath += "/" + part;
        let nextChild = node.children?.find(
          (child: any) => child.name === part
        );
        let nextNode = null;
        if (nextChild) {
          nextNode = { ...(nextChild as Object) };
        } else {
          (nextNode as any) = {
            name: part,
            full_path: fullPath,
            children: [],
            type: index === pathParts.length - 1 ? "FILE" : "FOLDER",
          };
        }
        const originalChildren = node.children
          ? [
              ...(node.children as any)?.filter(
                (child: any) => child.name !== part
              ),
            ]
          : [];
        (node.children as any) = [...originalChildren, nextNode];
        (node as any) = nextNode;
      });

      return { ...state, filterFolder, treeFolder: root as any };
    }

    case SET_NAVIGATION_CONTEXT: {
      return { ...state, navigationContext: action.data };
    }

    case SET_IS_IMAGE_UPLOADING:
      return { ...state, isImageUploading: action.data };

    case HOVERED_FILE_STATE:
      return { ...state, hoveredFileState: action.data };

    case SET_FETCH_ERROR:
      return { ...state, fetchError: action.data };

    case SET_DATA_METADATA_SUMMARY:
      return { ...state, dataMetadataSummary: action.data };

    case SET_DATA_METADATA_SUMMARY_LOADING:
      return { ...state, dataMetadataSummaryLoading: action.data };

    case SET_AUTOCOMPLETE_SUGGESTIONS:
      return { ...state, autocompleteSuggestions: action.data };

    case SET_RECENT_SEARCHES:
      return { ...state, recentSearches: action.data };

    case SET_AUTOCOMPLETE_LOADING:
      return { ...state, autocompleteLoading: action.data };

    case SET_AUTOCOMPLETE_NO_RESULTS_FLAG:
      return { ...state, autocompleteNoResultsFlag: action.data };

    case SET_VERTEX_PREVIEWS_COUNT_CONTEXT:
      return { ...state, vertexPreviewsCountContext: action.data };

    case SET_IMAGE_PAGE_PARAMS_CONTEXT:
      return { ...state, imagePageParamsContext: action.data };

    case SET_VERTEX_CONTEXT:
      return { ...state, vertexContext: action.data };

    case SET_ASSIGNED_TAGS:
      return { ...state, assignedTags: action.data };

    case SET_SHOW_EXPORT_MODAL:
      return { ...state, showExportModal: action.data };

    case SET_EXPORT_PROGRESS:
      return { ...state, exportProgress: action.data };

    case SET_SHOW_SHARING_MENU:
      return { ...state, showSharingMenu: action.data };

    case SET_USERS_WITH_ACCESS:
      return { ...state, usersWithAccess: action.data };

    default:
      return state;
  }
};

export default reducer;
