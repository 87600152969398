const PrivateS3Icon = ({ color = "#fff" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path d="M0 0H15L11.4474 15H3.55263L0 0Z" fill={color} />
    </svg>
  );
};

export default PrivateS3Icon;
