const ArrowCircleIcon = ({
  color = "#fff",
  showGradient = false,
}: {
  color?: string;
  showGradient?: boolean;
}) => {
  return showGradient ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 1.55766e-06C10.1217 1.46491e-06 12.1566 0.842856 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 -2.56947e-07 10.1217 -3.49691e-07 8C-4.42435e-07 5.87827 0.842854 3.84344 2.34315 2.34315C3.84344 0.842856 5.87827 1.6504e-06 8 1.55766e-06ZM4.5 7.5C4.36739 7.5 4.24021 7.55268 4.14645 7.64645C4.05268 7.74022 4 7.86739 4 8C4 8.13261 4.05268 8.25979 4.14645 8.35355C4.24021 8.44732 4.36739 8.5 4.5 8.5L10.293 8.5L8.146 10.646C8.05211 10.7399 7.99937 10.8672 7.99937 11C7.99937 11.1328 8.05211 11.2601 8.146 11.354C8.23989 11.4479 8.36722 11.5006 8.5 11.5006C8.63278 11.5006 8.76011 11.4479 8.854 11.354L11.854 8.354C11.9006 8.30755 11.9375 8.25238 11.9627 8.19163C11.9879 8.13089 12.0009 8.06577 12.0009 8C12.0009 7.93423 11.9879 7.86911 11.9627 7.80837C11.9375 7.74762 11.9006 7.69245 11.854 7.646L8.854 4.646C8.76011 4.55211 8.63277 4.49937 8.5 4.49937C8.36722 4.49937 8.23989 4.55211 8.146 4.646C8.05211 4.73989 7.99937 4.86723 7.99937 5C7.99937 5.13278 8.05211 5.26011 8.146 5.354L10.293 7.5L4.5 7.5Z"
        fill="url(#paint0_linear_3415_13228)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3415_13228"
          x1="-13.5"
          y1="-3.5"
          x2="17.5"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4500" />
          <stop offset="0.55967" stopColor="#CC4E84" />
          <stop offset="1" stopColor="#0197D8" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 -2.34446e-06C12.6522 -2.46039e-06 15.1957 1.05357 17.0711 2.92893C18.9464 4.80429 20 7.34783 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34783 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 -3.21184e-07 12.6522 -4.37114e-07 10C-5.53044e-07 7.34783 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34783 -2.22853e-06 10 -2.34446e-06ZM5.625 9.375C5.45924 9.375 5.30027 9.44085 5.18306 9.55806C5.06585 9.67527 5 9.83424 5 10C5 10.1658 5.06585 10.3247 5.18306 10.4419C5.30027 10.5592 5.45924 10.625 5.625 10.625L12.8662 10.625L10.1825 13.3075C10.0651 13.4249 9.99921 13.584 9.99921 13.75C9.99921 13.916 10.0651 14.0751 10.1825 14.1925C10.2999 14.3099 10.459 14.3758 10.625 14.3758C10.791 14.3758 10.9501 14.3099 11.0675 14.1925L14.8175 10.4425C14.8757 10.3844 14.9219 10.3155 14.9534 10.2395C14.9849 10.1636 15.0011 10.0822 15.0011 10C15.0011 9.91779 14.9849 9.83639 14.9534 9.76046C14.9219 9.68453 14.8757 9.61556 14.8175 9.5575L11.0675 5.8075C10.9501 5.69014 10.791 5.62421 10.625 5.62421C10.459 5.62421 10.2999 5.69014 10.1825 5.8075C10.0651 5.92486 9.99921 6.08403 9.99921 6.25C9.99921 6.41597 10.0651 6.57514 10.1825 6.6925L12.8662 9.375L5.625 9.375Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowCircleIcon;
