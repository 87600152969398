import { State } from "redux/store";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import HealthStatus from "views/components/HealthStatus";
import ContainerHeader from "views/components/ContainerHeader";
import { DATASET_CREATION_PAGE } from "helpers/constants/pages";
import {
  getIsLoading,
  getIsNewDatasetInitializing,
  getNewDatasetName,
} from "redux/CreateDataset/selectors";

import styles from "./style.module.scss";

function PageHeader({
  newDatasetName,
  isNewDatasetInitializing,
  isContentLoading,
}: PageHeaderProps) {
  return (
    <>
      <Helmet>
        <title>Dataset Creation</title>
      </Helmet>
      <div className={styles.headerContainer}>
        <ContainerHeader
          title={newDatasetName}
          page={DATASET_CREATION_PAGE}
          dynamicPage={DATASET_CREATION_PAGE}
          showPageSwitch={true}
          isNewDatasetInitializing={isNewDatasetInitializing}
          isContentLoading={isContentLoading}
        />
        <HealthStatus />
      </div>
    </>
  );
}

const mapStateToProps = (state: State) => {
  return {
    newDatasetName: getNewDatasetName(state),
    isNewDatasetInitializing: getIsNewDatasetInitializing(state),
    isContentLoading: getIsLoading(state),
  };
};

export default connect(mapStateToProps)(PageHeader);

type PageHeaderProps = {
  newDatasetName: string;
  isNewDatasetInitializing: boolean;
  isContentLoading: boolean;
};
