import styles from "./style.module.scss";

const S3ProgressComponent = ({ progress }: S3ProgressComponentProps) => {
  return (
    <div className={styles.s3ProgressComponentWrapper}>
      <div className={styles.s3ProgressTitleContainer}>
        <div className={styles.spinner}></div>
        <span className={styles.s3ProgressTitle}>Initializing S3 URL</span>
      </div>
      <div className={styles.s3ProgressBarContainer}>
        <div
          className={styles.s3ProgressBar}
          style={{ width: `${progress}%` }}
        ></div>
        <div className={styles.s3ProgressCount}>{progress}%</div>
      </div>
      <div className={styles.s3ProgressDescription}>Generating preview...</div>
    </div>
  );
};

export default S3ProgressComponent;

interface S3ProgressComponentProps {
  progress: number;
}
