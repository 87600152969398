import classNames from "classnames";
import { State } from "redux/store";
import { useLocation, useNavigate, useParams } from "react-router";
import { connect, useDispatch } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  setCardslistLoading,
  setDatasetLabels,
  setNavigationCluster,
  setNavigationClusterLoader,
  updateExportData,
} from "redux/SingleDataset/actions";
import {
  getDatasetLabels,
  getFilteredSimilarityClusters,
  getNavigationCluster,
  getAllSelectedFilters,
  getIsShowDebugInfoEnabled,
} from "redux/SingleDataset/selectors";
import { truncateFilename, useWindowResize } from "helpers/utility/utilities";
import { amplitudeTrack } from "helpers/utility/amplitude";
import {
  AMP_DATASET_EXPLORATION_EVENT__ENTITY__TOP_LABEL__CLICKED,
  AMP_DATASET_EXPLORATION_EVENT__VISUAL_SEARCH__REMOVED,
} from "helpers/constants/amplitudeEvents";
import {
  AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE,
  AMP_DATASET_EXPLORATION_PROPERTY__TARGET,
} from "helpers/constants/amplitudeProperties";
import { kFormatter } from "helpers/utility/formatters";
import {
  LABELS_PARAMETER,
  PAGE_PARAMETER,
} from "helpers/constants/miscellaneous";

import { BiCheck, BiX } from "react-icons/bi";
import ObjectIcon from "assets/icons/ObjectIcon";
import FrameIcon from "assets/icons/frameIcon";
import ImageIcon from "assets/icons/imageIcon";
import TagIcon from "assets/icons/tagIcon";
import { FilterOption, hoveredAssetDataType } from "types";

import styles from "./style.module.scss";
import { IoMdInformationCircleOutline } from "react-icons/io";
import TooltipWrapper from "views/uikit/TooltipWrapper";

const SingleLabel = ({
  label,
  isCardSelected,
  isExportSelected,
  datasetLabels,
  handleSingleLabelClick,
}: any) => {
  const existingText = datasetLabels.find(
    (l: FilterOption) => l.text === label
  );
  const isLabelSelected = !!existingText?.selected;

  const labelClassName = classNames({
    [styles.selectedSingleLabelForSimilarity]:
      isCardSelected && isLabelSelected,
    [styles.singleLabelForSimilarity]: isCardSelected && !isLabelSelected,
    [styles.selectedSingleLabelForExport]:
      isExportSelected && !isCardSelected && isLabelSelected,
    [styles.singleLabelForExport]:
      isExportSelected && !isCardSelected && !isLabelSelected,
    [styles.selectedSingleLabel]:
      !isCardSelected && !isExportSelected && isLabelSelected,
    [styles.singleLabel]:
      !isCardSelected && !isExportSelected && !isLabelSelected,
  });

  return (
    <div
      className={labelClassName}
      onClick={(e: any) => {
        e.stopPropagation();
        handleSingleLabelClick(label);
      }}
      title={label}
    >
      <span>{label}</span>
    </div>
  );
};

const SingleObjectTitle = ({
  fileName,
  fileNameClassName,
  getDynamicColor,
  getDynamicTitleClass,
  isSelectedForExportOrSimilarity,
}: any) => {
  const singleObjectTitleBoxClassName = classNames(
    styles.singleObjectTitleBox,
    {
      [styles.higherZIndex]: isSelectedForExportOrSimilarity,
    }
  );
  return (
    <div className={singleObjectTitleBoxClassName}>
      <ObjectIcon color={getDynamicColor()} />
      <span className={getDynamicTitleClass()}>Object from</span>
      <div className={fileNameClassName} title={fileName}>
        {truncateFilename(fileName, 28)}
      </div>
    </div>
  );
};

const SingleVideoTitle = ({
  isSelectedForExportOrSimilarity,
  fileNameClassName,
  getDynamicColor,
  getDynamicTitleClass,
  fileName,
}: any) => {
  const singleFrameTitleBoxClassName = classNames(styles.singleFrameTitleBox, {
    [styles.higherZIndex]: isSelectedForExportOrSimilarity,
  });
  return (
    <div className={singleFrameTitleBoxClassName}>
      <FrameIcon color={getDynamicColor()} />
      <span className={getDynamicTitleClass()}>Frame from</span>
      <div className={fileNameClassName} title={fileName}>
        {truncateFilename(fileName, 28)}
      </div>
    </div>
  );
};

const DescriptionBox = ({
  visibleLabels,
  getDynamicColor,
  isCardSelected,
  isExportSelected,
  datasetLabels,
  handleSingleLabelClick,
}: any) => {
  return (
    <div className={styles.descriptionBox}>
      <TagIcon color={getDynamicColor()} />

      <div className={styles.labelsContainer}>
        {visibleLabels.map((label: any, index: number) => {
          return (
            <SingleLabel
              key={index}
              label={label}
              isCardSelected={isCardSelected}
              isExportSelected={isExportSelected}
              datasetLabels={datasetLabels}
              handleSingleLabelClick={handleSingleLabelClick}
            />
          );
        })}
        {visibleLabels.length < 1 && (
          <span
            className={styles.noLabelsText}
            style={{ color: getDynamicColor() }}
          >
            {"No labels available"}
          </span>
        )}
      </div>
    </div>
  );
};

const CardHeader = ({
  title,
  isCardSelected,
  handleRemoveVertex,
  singleCardLabels,
  isHovering,
  clusterID,
  clusters,
  isExportSelected,
  showExportOption,
  type,
  imageCount,
  objectCount,
  videoCount,
  frameCount,
  isClusterPage,
  isDataPage,
  clusterPreview,
  isSingleEntityOnDataPage,
  datasetLabels,
  fileName,
  clusterSize,
  fileType,
  allSelectedFilters,
  hoveredAssetData,
  showDebugInfo,
  index,
}: CardHeaderProps) => {
  const [isTextLong, setIsTextLong] = useState(false);
  const isSelected = isExportSelected;
  const selectedCluster = useMemo(() => {
    return clusters?.find((cluster: any) => cluster.cluster_id === clusterID);
  }, [clusterID, clusters]);

  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowResize();
  const { datasetId } = useParams();
  const dispatch = useDispatch();

  const titleRef = useRef<HTMLSpanElement>(null);
  const titleContainerRef = useRef<HTMLDivElement>(null);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const visibleLabels = singleCardLabels.slice(0, 3);

  const handleSingleLabelClick = (value: string) => {
    const selectedLabel = datasetLabels.find(
      (option: FilterOption) => option.text === value
    );

    if (!!selectedLabel) {
      const newDatasetLabels = [
        ...datasetLabels.filter(
          (option: FilterOption) => option.text !== value
        ),
        { ...selectedLabel, selected: !selectedLabel?.selected },
      ];

      const result = newDatasetLabels
        .filter((option) => option.selected)
        .map((option) => option.text);

      if (result.length > 0) {
        searchParams.set(LABELS_PARAMETER, JSON.stringify(result));
      } else {
        searchParams.delete(LABELS_PARAMETER);
      }
      searchParams.set(PAGE_PARAMETER, "1");
      navigate({ search: `?${searchParams.toString()}` });

      dispatch(setDatasetLabels(newDatasetLabels) as any);
    }

    //tarck top label clicked
    let properties: any;
    let target = "";

    switch (type) {
      case "IMAGES":
        if (clusterSize > 1) target = "Cluster";
        else target = "Image";
        break;
      case "OBJECTS":
        if (clusterSize > 1) target = "Cluster";
        else target = "Object";
        break;
      case "IMAGE":
        target = "IMAGE";
        break;
      case "OBJECT":
        target = "OBJECT";
        break;
    }

    if (!!target) {
      properties = {
        [AMP_DATASET_EXPLORATION_PROPERTY__TARGET]: target,
      };
    }
    if (clusterSize > 1) {
      properties = {
        ...properties,
        [AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE]: clusterSize,
      };
    }
    amplitudeTrack(
      AMP_DATASET_EXPLORATION_EVENT__ENTITY__TOP_LABEL__CLICKED,
      properties
    );
  };

  const getDynamicColor = () => {
    if (isExportSelected && isCardSelected) return "#fff";
    else if (isExportSelected) return "#151928";
  };

  const getDynamicTitleClass = () => {
    if (isExportSelected && isCardSelected) return styles.title;
    else if (isExportSelected) return styles.selectedTitle;
    else return styles.title;
  };

  const renderTitle = () => {
    const fileNameClassName = classNames({
      [styles.fileNameBox]: !isCardSelected && !isExportSelected,
      [styles.fileNameBoxForExport]: !isCardSelected && isExportSelected,
      [styles.fileNameBoxForSimilarity]: isCardSelected,
    });

    const imageTitleHeaderClassNames = classNames(styles.imageTitleHeader, {
      [styles.higherZIndex]: isCardSelected || isExportSelected,
    });

    const objectTitleHeaderClassNames = classNames(styles.objectTitleHeader, {
      [styles.higherZIndex]: isCardSelected || isExportSelected,
    });

    if (isDataPage) {
      if (isSingleEntityOnDataPage) {
        if (type === "IMAGES" && fileType === "video") {
          return (
            <SingleVideoTitle
              fileName={fileName}
              fileNameClassName={fileNameClassName}
              getDynamicColor={getDynamicColor}
              getDynamicTitleClass={getDynamicTitleClass}
              isSelectedForExportOrSimilarity={
                isExportSelected || isCardSelected
              }
            />
          );
        } else if (type === "IMAGES") {
          return (
            <div className={imageTitleHeaderClassNames}>
              <div className={styles.imageTitleBox}>
                <ImageIcon color={getDynamicColor()} />
                <span className={getDynamicTitleClass()} title={fileName}>
                  {fileName}
                </span>
              </div>
            </div>
          );
        } else if (type === "OBJECTS") {
          return (
            <SingleObjectTitle
              fileName={fileName}
              fileNameClassName={fileNameClassName}
              getDynamicColor={getDynamicColor}
              getDynamicTitleClass={getDynamicTitleClass}
              isSelectedForExportOrSimilarity={
                isExportSelected || isCardSelected
              }
            />
          );
        }
      } else {
        if (type === "IMAGES") {
          //assuming that image count will always be greater than or equal to image frame count
          const imageCountAfterDeductingFrameCount =
            frameCount > 0 ? imageCount - frameCount : imageCount;
          const getImageTitleText = () => {
            let imageText =
              imageCountAfterDeductingFrameCount > 0
                ? `${kFormatter(imageCountAfterDeductingFrameCount)} ${
                    kFormatter(imageCountAfterDeductingFrameCount) === "1"
                      ? "Image"
                      : "Images"
                  }`
                : "";
            let frameText = "";
            let videoText = "";
            if (videoCount > 0) {
              videoText = ` (${kFormatter(videoCount)} ${
                kFormatter(videoCount) === "1" ? "Video" : "Videos"
              })`;
            }
            if (frameCount > 0) {
              frameText = `${kFormatter(frameCount)} ${
                kFormatter(frameCount) === "1" ? "Frame" : "Frames"
              }${videoText} ${
                imageCountAfterDeductingFrameCount > 0 ? "+" : ""
              } `;
            }
            return frameText + imageText;
          };

          return (
            <div className={imageTitleHeaderClassNames}>
              <div className={styles.imageTitleBox}>
                <ImageIcon color={getDynamicColor()} />
                <span
                  ref={titleRef}
                  title={frameCount > 0 ? getImageTitleText() : ""}
                  className={getDynamicTitleClass()}
                >
                  {getImageTitleText()}
                </span>
              </div>
            </div>
          );
        } else if (type === "OBJECTS") {
          const showextraInfo = videoCount > 0 || imageCount > 0;
          const getObjectTitleText = () => {
            const objectText = `${kFormatter(objectCount)} ${
              kFormatter(objectCount) === "1" ? "Object" : "Objects"
            }`;
            let imageText = "";
            let videoText = "";
            if (imageCount > 0)
              imageText = `${kFormatter(imageCount)} ${
                kFormatter(imageCount) === "1" ? "Image" : "Images"
              }`;
            if (videoCount > 0)
              videoText = `${kFormatter(videoCount)} ${
                kFormatter(videoCount) === "1" ? "Video" : "Videos"
              }`;

            videoText =
              imageText && videoText
                ? ` (${videoText} + ${imageText})`
                : imageText && !videoText
                ? ` (${imageText})`
                : videoText && !imageText
                ? ` (${videoText})`
                : "";

            return objectText + videoText;
          };

          return (
            <div className={objectTitleHeaderClassNames}>
              <div className={styles.objectTitleBox}>
                <ObjectIcon color={getDynamicColor()} />
                <span
                  className={getDynamicTitleClass()}
                  title={showextraInfo ? getObjectTitleText() : ""}
                >
                  {getObjectTitleText()}
                </span>
              </div>
            </div>
          );
        }
      }
    } else if (isClusterPage) {
      if (type === "IMAGE" && fileType === "video") {
        return (
          <SingleVideoTitle
            fileName={fileName}
            fileNameClassName={fileNameClassName}
            getDynamicColor={getDynamicColor}
            getDynamicTitleClass={getDynamicTitleClass}
            isSelectedForExportOrSimilarity={isExportSelected || isCardSelected}
          />
        );
      } else if (type === "IMAGE") {
        return (
          <div className={imageTitleHeaderClassNames}>
            <div className={styles.imageTitleBox}>
              <ImageIcon color={getDynamicColor()} />
              <span className={getDynamicTitleClass()} title={fileName}>
                {fileName}
              </span>
            </div>
          </div>
        );
      } else if (type === "OBJECT") {
        return (
          <SingleObjectTitle
            fileName={fileName}
            fileNameClassName={fileNameClassName}
            getDynamicColor={getDynamicColor}
            getDynamicTitleClass={getDynamicTitleClass}
            isSelectedForExportOrSimilarity={isExportSelected || isCardSelected}
          />
        );
      }
    }
  };

  const showExportButton = () => {
    if (
      (isSelected || isHovering) &&
      showExportOption &&
      !isCardSelected &&
      !hoveredAssetData.id
    ) {
      return (
        <div className={styles.selectionButton} onClick={handleExportSelect}>
          {isSelected && (
            <div className={styles.selection}>
              <BiCheck size={"1em"} />
            </div>
          )}
        </div>
      );
    }
  };

  const debugTooltipText = useMemo(() => {
    let tooltipText = "";
    if (selectedCluster) {
      if (selectedCluster.formed_by) {
        tooltipText += `formed_by: ${selectedCluster.formed_by}\n`;
      }
      if (
        selectedCluster.relevance_score_type &&
        selectedCluster.relevance_score !== undefined
      ) {
        tooltipText += `${selectedCluster.relevance_score_type}: ${selectedCluster.relevance_score}\n`;
      }
    }
    if (clusterPreview) {
      if (
        clusterPreview.relevance_score_type &&
        clusterPreview.relevance_score !== undefined
      ) {
        tooltipText += `${clusterPreview.relevance_score_type}: ${clusterPreview.relevance_score}\n`;
      }
    }
    return tooltipText;
  }, [selectedCluster, clusterPreview]);

  const showDebugTooltip = () => {
    if (showDebugInfo && debugTooltipText !== "") {
      return (
        <>
          <div
            className={styles.debugInfoTooltip}
            data-tooltip-id={"debug-info-tooltip" + index}
          >
            <IoMdInformationCircleOutline size={"1em"} />
          </div>
          <TooltipWrapper
            id={"debug-info-tooltip" + index}
            content={debugTooltipText}
          />
        </>
      );
    }
  };

  const showRemoveSimilarityButton = () => {
    const handleVertexClick = (e: any) => {
      e.stopPropagation();
      if (isCardSelected) {
        //track visual search in amplitude(applied here)
        amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__VISUAL_SEARCH__REMOVED, {
          [AMP_DATASET_EXPLORATION_PROPERTY__TARGET]: type,
        });
        if (handleRemoveVertex) {
          handleRemoveVertex(clusterID);
        }
        dispatch(setNavigationClusterLoader(true));
        dispatch(setCardslistLoading(true));
        dispatch(setNavigationCluster(null));
      }
    };

    if (isCardSelected) {
      return (
        <div className={styles.removeSimilarButton} onClick={handleVertexClick}>
          <BiX size={"1.25em"} />
        </div>
      );
    }
  };

  const handleExportSelect = (e: any) => {
    e.stopPropagation();
    if (isDataPage) {
      if (selectedCluster && Object.keys(selectedCluster).length > 0) {
        dispatch(
          updateExportData(
            datasetId!,
            selectedCluster,
            "card",
            "cluster",
            allSelectedFilters
          ) as any
        );
      }
    } else if (isClusterPage) {
      let newCluster = { ...clusterPreview, cluster_id: clusterID };
      dispatch(
        updateExportData(
          datasetId!,
          newCluster,
          "card",
          "media",
          allSelectedFilters
        ) as any
      );
    }
  };

  const handleLongText = () => {
    let extraSpaceToBeIgnored = 10;
    if (titleContainerRef.current && titleRef.current) {
      let result =
        titleContainerRef.current.offsetWidth - extraSpaceToBeIgnored <=
        titleRef.current.offsetWidth;
      setIsTextLong(result);
    }
  };

  useEffect(() => {
    handleLongText();
  }, [title, width]);

  return (
    <div
      className={classNames(styles.cardHead, {
        [styles.cardHeadNotClickable]: isCardSelected,
      })}
      title={isTextLong ? title : ""}
    >
      <div className={styles.cardHeadTitleContainer}>
        {renderTitle()}
        {showDebugTooltip()}
        {showExportButton()}
        {showRemoveSimilarityButton()}
      </div>

      <DescriptionBox
        visibleLabels={visibleLabels}
        isCardSelected={isCardSelected}
        isExportSelected={isExportSelected}
        getDynamicColor={getDynamicColor}
        datasetLabels={datasetLabels}
        handleSingleLabelClick={handleSingleLabelClick}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    datasetLabels: getDatasetLabels(state),
    clusters: getFilteredSimilarityClusters(state),
    navigationCluster: getNavigationCluster(state),
    allSelectedFilters: getAllSelectedFilters(state),
    showDebugInfo: getIsShowDebugInfoEnabled(state),
  };
};

export default connect(mapStateToProps)(CardHeader);

type CardHeaderProps = {
  title: string;
  isCardSelected: boolean;
  handleRemoveVertex?: any;
  singleCardLabels: any;
  isHovering: boolean;
  clusterID: string;
  clusters: any;
  isExportSelected: boolean;
  exportData?: any;
  showExportOption: boolean;
  type: any;
  imageCount: number;
  objectCount: number;
  videoCount: number;
  frameCount: number;
  isDataPage: boolean;
  isClusterPage: boolean;
  clusterPreview?: any;
  navigationCluster: any;
  isSingleEntityOnDataPage?: boolean;
  fileName: string;
  datasetLabels: FilterOption[];
  clusterSize: number;
  fileType: "image" | "video" | null;
  allSelectedFilters: any;
  hoveredAssetData: hoveredAssetDataType;
  showDebugInfo: boolean;
  index: number;
};
