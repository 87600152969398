import { updateDatasetName } from "redux/CreateDataset/actions";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PencilIcon from "assets/img/pencil-icon.svg";
import ContentLoad from "views/uikit/ContentLoad";
import classNames from "classnames";

import styles from "./style.module.scss";

interface EditableDatasetTitleProps {
  title: string;
  fontStyle?: any;
  datasetID: string;
  isContentLoading?: boolean;
  editOnlyOnHover?: boolean;
  datasetSample?: boolean;
  labelStyle?: any;
}

function EditableDatasetTitle({
  title,
  datasetID,
  isContentLoading,
  fontStyle,
  labelStyle,
  editOnlyOnHover = false,
  datasetSample,
}: EditableDatasetTitleProps) {
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const showEditIcon = !isTitleEditing && editTitle;

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditTitle(event.target.value);
  };
  const onTitleSave = useCallback(() => {
    setIsTitleEditing(false);

    if (editTitle && editTitle !== title && datasetID) {
      dispatch(updateDatasetName(editTitle, datasetID) as any);
    } else {
      setEditTitle(title);
    }
  }, [dispatch, editTitle, title, datasetID]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onTitleSave();
    }
  };

  const handleEditIconClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setIsTitleEditing(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      event.stopPropagation();
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        onTitleSave();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onTitleSave]);

  useEffect(() => {
    setEditTitle(title);
  }, [title]);

  const renderEditableTitle = () => {
    if (isContentLoading) {
      return (
        <div className={styles.editableTitleLoader}>
          <ContentLoad />
        </div>
      );
    }
    return (
      <span
        className={styles.editableTitleContainer}
        onClick={(e) => e.stopPropagation()}
      >
        {isTitleEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={editTitle}
            onChange={handleTitleChange}
            onKeyDown={handleKeyDown}
            className={styles.editInput}
            autoFocus
            style={fontStyle}
            maxLength={128}
          />
        ) : (
          <span className={styles.editableTitleText} title={editTitle}>
            {editTitle}
          </span>
        )}
        {showEditIcon && (
          <>
            {editOnlyOnHover && (
              <div className={styles.datasetTypeLable} style={labelStyle}>
                {datasetSample ? (
                  <span className={styles.lableText}>PUBLIC</span>
                ) : (
                  <span className={styles.lableText}>PRIVATE</span>
                )}
              </div>
            )}
            <span
              className={classNames(styles.editIconContainer, {
                [styles.editOnlyOnHover]: editOnlyOnHover,
              })}
              onClick={handleEditIconClick}
            >
              <img
                className={styles.editIcon}
                src={PencilIcon}
                alt="edit-icon"
              />
            </span>
          </>
        )}
      </span>
    );
  };

  return renderEditableTitle();
}

export default EditableDatasetTitle;
