import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet";

import ProcessItem from "./ProcessItem";
import LoginButton from "./LoginButton";
import GoogleButton from "views/uikit/GoogleButton";
import { PRIVATE_DATASETS_PATH } from "helpers/constants/paths";

import { isAnonymousUser, selectUser } from "redux/authReducer";
import { setShowMobileErrorModal } from "redux/Modals/actions";

import vllogo from "assets/img/login-vllogo.svg";
import upload from "assets/img/upload.svg";
import fix from "assets/img/fix.svg";
import scan from "assets/img/scan.svg";
import githubLogo from "assets/img/github-logo.svg";
import googleLogo from "assets/img/google-logo.svg";

import "./style.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  useWindowResize,
  isMobileDevice,
  isFastdupUser,
} from "helpers/utility/utilities";

function Login() {
  const { width } = useWindowResize();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("disclaimer-shown")) {
      return;
    }

    if (isMobileDevice()) {
      dispatch(setShowMobileErrorModal(true));
      localStorage.setItem("disclaimer-shown", "true");
    } else {
      dispatch(setShowMobileErrorModal(false));
    }
    // eslint-disable-next-line
  }, [width]);

  //REMOVE CONDITION WHEN TWEAKING WITHOUT TOKENS IS NOT NEEDED ANYMORE
  if (process.env.REACT_APP_IS_AUTHENTICATION_DISABELED === "false") {
    let user = selectUser();
    if (user && !isAnonymousUser()) {
      window.location.href = PRIVATE_DATASETS_PATH;
    }
  }

  const generateGithubSSOUrl = () => {
    return (
      "https://github.com/login/oauth/authorize?scope=user&client_id=" +
      process.env.REACT_APP_GITHUB_CLIENT_ID +
      "&state=" +
      uuidv4()
    );
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>Login</title>
        <script
          src="https://accounts.google.com/gsi/client"
          async
          defer
        ></script>
      </Helmet>
      <div className="left-section">
        <div className="logo-image">
          <img src={vllogo} alt="logo" height={"60px"} />
        </div>
        <div className="login-body">
          <div className="login-text">
            <span>
              Automatically find and fix quality issues in your visual-data
            </span>
          </div>
          <div className="work-process">
            <ProcessItem
              icon={upload}
              title="1. Upload your dataset"
              body="We'll show you how to organize and upload your data to get started."
            />
            <ProcessItem
              icon={scan}
              title="2. Scan"
              body="Check visuals and metadata with as many filters as you like."
            />
            <ProcessItem
              icon={fix}
              title="3. Fix"
              body="Then when you're ready, download your metadata. That's it!"
            />
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="login-text1">
          <span>Get started for free</span>
        </div>
        <div className="login-with-section">
          {!isFastdupUser() && (
            <a href={generateGithubSSOUrl()}>
              <LoginButton
                className="bg-dark"
                icon={githubLogo}
                text="Log in with Github"
              />
            </a>
          )}
          <div className="google-auth-login">
            <LoginButton
              className="google-login-button"
              icon={googleLogo}
              text="Log in with Google"
            />
            <GoogleButton />
            <div
              className="g_id_signin"
              data-width={window.innerWidth > 700 ? 300 : 230}
            ></div>
          </div>
        </div>
        <div className="agree-terms">
          <label htmlFor="agree-terms">
            By Signing In, I Agree To The{" "}
            <a
              href={"https://visual-layer2.readme.io/docs/terms-of-service"}
              target="_blank"
              rel="noreferrer"
            >
              Terms And Conditions
            </a>{" "}
            And{" "}
            <a
              href={"https://visual-layer2.readme.io/docs/privacy-policy"}
              target="_blank"
              rel="noreferrer"
            >
              The Privacy Policy
            </a>
          </label>
        </div>
      </div>
    </div>
  );
}

export default Login;
