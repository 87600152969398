import React from "react";
import VLLogo from "assets/img/VL-Beta-Logo.svg";

import styles from "./style.module.scss";

const VLIcon = () => {
  return (
    <div className={styles.vlLogo}>
      <img src={VLLogo} alt="" />
    </div>
  );
};

export default React.memo(VLIcon);
