import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import VideoIndicationIcon from "views/uikit/VideoIndicationIcon";
import useHoverDelay, { calculateAspectRatio } from "helpers/utility/utilities";

import styles from "./style.module.scss";

export const SimpleImageBox = ({
  image,
  imageThumb,
  showVideoIcon,
  roundBottom,
  updateAssetData,
  showAssetPreview,
  hoverDelay,
}: any) => {
  const [loading, setLoading] = useState(true);
  const showIcon = showVideoIcon && !loading;

  const tableColumnClassNames = classNames(styles.tableColumn, {
    [styles.tableColumnForAssetPreview]: showAssetPreview,
  });

  const squareImageContainerClassNames = classNames(
    styles.squareImagesContainer,
    {
      [styles.roundBottom]: roundBottom,
    }
  );

  const { handleMouseEnter, handleMouseLeave } = useHoverDelay(
    updateAssetData,
    hoverDelay
  );

  return (
    <div
      className={squareImageContainerClassNames}
      onMouseOver={() => showAssetPreview && handleMouseEnter()}
      onMouseLeave={() => showAssetPreview && handleMouseLeave()}
    >
      <div className={tableColumnClassNames}>
        {showIcon && <VideoIndicationIcon />}
        <img
          className={styles.singleImage}
          src={image}
          alt="single img"
          loading="lazy"
          onLoad={() => setLoading(false)}
          style={{
            backgroundImage: `url(${imageThumb})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
    </div>
  );
};

const ObjectImageBox = ({
  image,
  boundingBox,
  showVideoIcon,
  roundBottom,
  updateAssetData,
  showAssetPreview,
  hoverDelay,
}: any) => {
  const [loading, setLoading] = useState(true);
  const [imageDimensions, setImageDimensions] = useState<{
    height: number;
    width: number;
  }>({
    height: 0,
    width: 0,
  });

  const [boundingBoxContainerDimensions, setBoundingBoxContainerDimensions] =
    useState<{
      height: number;
      width: number;
    }>({
      height: 0,
      width: 0,
    });

  const objectImageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const showIcon = showVideoIcon && !loading;

  const objectImageWrapperClassnames = classNames(styles.objectImageWrapper, {
    [styles.objectImageWrapperForAssetPreview]: showAssetPreview,
    [styles.roundBottom]: roundBottom,
  });

  const calculateNewImageDimensions = () => {
    if (objectImageRef.current) {
      let newValues = calculateAspectRatio(objectImageRef.current);
      setBoundingBoxContainerDimensions({
        height: newValues[0],
        width: newValues[1],
      });
    }
  };

  const handleLoad = () => {
    setLoading(false);
    calculateNewImageDimensions();
    setImageDimensions({
      height: objectImageRef.current?.naturalHeight as number,
      width: objectImageRef.current?.naturalWidth as number,
    });
  };

  const boundingBoxContainerStyle = () => {
    if (
      boundingBoxContainerDimensions.height > 0 &&
      boundingBoxContainerDimensions.width > 0
    ) {
      return {
        height: `${boundingBoxContainerDimensions.height}px`,
        width: `${boundingBoxContainerDimensions.width}px`,
      };
    } else {
      return undefined;
    }
  };

  const boundingBoxStyle = () => {
    let heightRatio = 100;
    let widthRatio = 100;

    if (imageDimensions.height > 0 && imageDimensions.width > 0) {
      heightRatio =
        (boundingBoxContainerDimensions.height / imageDimensions.height) * 100;
      widthRatio =
        (boundingBoxContainerDimensions.width / imageDimensions.width) * 100;
    }
    return {
      left: (boundingBox[0] * widthRatio) / 100,
      top: (boundingBox[1] * heightRatio) / 100,
      width: (boundingBox[2] * widthRatio) / 100,
      height: (boundingBox[3] * heightRatio) / 100,
      display: loading ? "none" : "block",
    };
  };

  const { handleMouseEnter, handleMouseLeave } = useHoverDelay(
    updateAssetData,
    hoverDelay
  );

  useEffect(() => {
    calculateNewImageDimensions();
    const objectImageElement = objectImageRef.current;
    if (!objectImageElement) return;

    const resizeObserver = new ResizeObserver(calculateNewImageDimensions);
    resizeObserver.observe(objectImageElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [objectImageRef, image]);

  return (
    <div
      className={objectImageWrapperClassnames}
      ref={containerRef}
      onMouseOver={() => showAssetPreview && handleMouseEnter()}
      onMouseLeave={() => showAssetPreview && handleMouseLeave()}
    >
      <div
        className={styles.boundingBoxContainer}
        style={boundingBoxContainerStyle()}
      >
        {boundingBox && (
          <div className={styles.boundingBox} style={boundingBoxStyle()}></div>
        )}
      </div>
      {showIcon && <VideoIndicationIcon />}
      <img
        ref={objectImageRef}
        className={styles.objectImage}
        src={image}
        alt="object img"
        loading="lazy"
        onLoad={handleLoad}
      />
    </div>
  );
};

const ZoomableImageBox = ({
  image,
  boundingBox,
  magnifierHeight = 150,
  magnifierWidth = 150,
  zoomLevel = 2,
}: any) => {
  const [loading, setLoading] = useState(true);
  const [imageDimensions, setImageDimensions] = useState<{
    height: number;
    width: number;
  }>({
    height: 0,
    width: 0,
  });

  const [boundingBoxContainerDimensions, setBoundingBoxContainerDimensions] =
    useState<{
      height: number;
      width: number;
    }>({
      height: 0,
      width: 0,
    });

  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);

  const objectImageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateNewImageDimensions = () => {
    if (objectImageRef.current) {
      let newValues = calculateAspectRatio(objectImageRef.current);
      setBoundingBoxContainerDimensions({
        height: newValues[0],
        width: newValues[1],
      });
    }
  };

  const handleLoad = () => {
    setLoading(false);
    calculateNewImageDimensions();
    setImageDimensions({
      height: objectImageRef.current?.naturalHeight as number,
      width: objectImageRef.current?.naturalWidth as number,
    });
  };

  const boundingBoxContainerStyle = () => {
    if (
      boundingBoxContainerDimensions.height > 0 &&
      boundingBoxContainerDimensions.width > 0
    ) {
      return {
        height: `${boundingBoxContainerDimensions.height}px`,
        width: `${boundingBoxContainerDimensions.width}px`,
        cursor: showMagnifier ? "none" : undefined,
      };
    } else {
      return undefined;
    }
  };

  const boundingBoxStyle = () => {
    let heightRatio = 100;
    let widthRatio = 100;

    if (imageDimensions.height > 0 && imageDimensions.width > 0) {
      heightRatio =
        (boundingBoxContainerDimensions.height / imageDimensions.height) * 100;
      widthRatio =
        (boundingBoxContainerDimensions.width / imageDimensions.width) * 100;
    }
    return {
      left: (boundingBox[0] * widthRatio) / 100,
      top: (boundingBox[1] * heightRatio) / 100,
      width: (boundingBox[2] * widthRatio) / 100,
      height: (boundingBox[3] * heightRatio) / 100,
      display: loading ? "none" : "block",
    };
  };

  useEffect(() => {
    calculateNewImageDimensions();
    const objectImageElement = objectImageRef.current;
    if (!objectImageElement) return;

    const resizeObserver = new ResizeObserver(calculateNewImageDimensions);
    resizeObserver.observe(objectImageElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [objectImageRef, image]);

  return (
    <div className={styles.objectImageWrapper} ref={containerRef}>
      <div
        className={styles.boundingBoxContainer}
        style={boundingBoxContainerStyle()}
        onMouseEnter={(e) => {
          // update image size and turn-on magnifier
          const elem = e.currentTarget;
          const { width, height } = elem.getBoundingClientRect();
          setSize([width, height]);
          setShowMagnifier(true);
        }}
        onMouseMove={(e) => {
          // update cursor position
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();

          // calculate cursor position on the image
          let x = e.pageX - left - window.scrollX;
          let y = e.pageY - top - window.scrollY;

          setXY([x, y]);
        }}
        onMouseLeave={() => {
          // close magnifier
          setShowMagnifier(false);
        }}
      >
        {boundingBox && (
          <div className={styles.boundingBox} style={boundingBoxStyle()}></div>
        )}
      </div>

      <img
        ref={objectImageRef}
        className={styles.objectImage}
        src={image}
        alt="object img"
        loading="lazy"
        onLoad={handleLoad}
      />

      <div
        style={{
          display: showMagnifier ? "" : "none",
          position: "absolute",
          // prevent magnifier blocks the mousemove event of img
          pointerEvents: "none",
          // set size of magnifier
          height: `${magnifierHeight}px`,
          width: `${magnifierWidth}px`,
          // move element center to cursor pos
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifierWidth / 2}px`,
          border: "1px solid lightgray",
          backgroundColor: "#474c58",
          backgroundImage: `url('${image}')`,
          backgroundRepeat: "no-repeat",
          borderRadius: "50%",
          //calculate zoomed image size
          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,
          zIndex: 2,
          //calculate position of zoomed image.
          backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
        }}
      ></div>
    </div>
  );
};

const ZoomableObjectBox = ({ image, boundingBox }: any) => {
  const [loading, setLoading] = useState(true);
  const [imageDimensions, setImageDimensions] = useState<{
    height: number;
    width: number;
  }>({
    height: 0,
    width: 0,
  });

  const [boundingBoxContainerDimensions, setBoundingBoxContainerDimensions] =
    useState<{
      height: number;
      width: number;
    }>({
      height: 0,
      width: 0,
    });

  const [isHovering, setIsHovering] = useState(false);

  const objectImageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateNewImageDimensions = () => {
    if (objectImageRef.current) {
      let newValues = calculateAspectRatio(objectImageRef.current);
      setBoundingBoxContainerDimensions({
        height: newValues[0],
        width: newValues[1],
      });
    }
  };

  const handleLoad = () => {
    setLoading(false);
    calculateNewImageDimensions();
    setImageDimensions({
      height: objectImageRef.current?.naturalHeight as number,
      width: objectImageRef.current?.naturalWidth as number,
    });
  };

  const boundingBoxContainerStyle = () => {
    let bbHeight = boundingBoxContainerDimensions.height;
    let bbWidth = boundingBoxContainerDimensions.width;
    if (bbHeight > 0 && bbWidth > 0) {
      return { height: `${bbHeight}px`, width: `${bbWidth}px` };
    } else {
      return undefined;
    }
  };

  const boundingBoxStyle = () => {
    let heightRatio = 100;
    let widthRatio = 100;
    let bbHeight = boundingBoxContainerDimensions.height;
    let bbWidth = boundingBoxContainerDimensions.width;
    let imageHeight = imageDimensions.height;
    let imageWidth = imageDimensions.width;

    if (imageHeight > 0 && imageWidth > 0) {
      heightRatio = (bbHeight / imageHeight) * 100;
      widthRatio = (bbWidth / imageWidth) * 100;
    }

    let left = Math.round((boundingBox[0] * widthRatio) / 100);
    let top = Math.round((boundingBox[1] * heightRatio) / 100);
    let width = Math.round((boundingBox[2] * widthRatio) / 100);
    let height = Math.round((boundingBox[3] * heightRatio) / 100);

    let maxHeight = bbHeight;
    let maxWidth = bbWidth;
    if (maxWidth < width + (maxHeight - height)) {
      maxHeight = height + (maxWidth - width);
    } else if (maxHeight < height + (maxWidth - width)) {
      maxWidth = width + (maxHeight - height);
    }

    return {
      left: isHovering ? Math.round(bbWidth / 2) - maxWidth / 2 : left,
      top: isHovering ? Math.round(bbHeight / 2) - maxHeight / 2 : top,
      width: isHovering ? maxWidth : width,
      height: isHovering ? maxHeight : height,
      display: loading ? "none" : "block",
      boxShadow: isHovering ? "none" : undefined,
    };
  };

  const backgroundStyle = () => {
    let heightRatio = 100;
    let widthRatio = 100;
    let bbHeight = boundingBoxContainerDimensions.height;
    let bbWidth = boundingBoxContainerDimensions.width;
    let imgHeight = imageDimensions.height;
    let imgWidth = imageDimensions.width;

    if (imgHeight > 0 && imgWidth > 0) {
      heightRatio = (bbHeight / imgHeight) * 100;
      widthRatio = (bbWidth / imgWidth) * 100;

      let left = Math.round((boundingBox[0] * widthRatio) / 100);
      let top = Math.round((boundingBox[1] * heightRatio) / 100);
      let width = Math.round((boundingBox[2] * widthRatio) / 100);
      let height = Math.round((boundingBox[3] * heightRatio) / 100);

      let maxHeight = bbHeight;
      let maxWidth = bbWidth;

      if (maxWidth < width + (maxHeight - height)) {
        maxHeight = height + (maxWidth - width);
      } else if (maxHeight < height + (maxWidth - width)) {
        maxWidth = width + (maxHeight - height);
      }

      let heightIncrease = (maxHeight - height) / height;
      let widthIncrease = (maxWidth - width) / width;

      let newImgTop = top + top * heightIncrease;
      let newImgLeft = left + left * widthIncrease;

      let offsetTop = (maxHeight - bbHeight) / 2;
      let offsetLeft = (maxWidth - bbWidth) / 2;

      let dynamicBackgroundSize = isHovering
        ? `${bbWidth + bbWidth * widthIncrease}px ${
            bbHeight + bbHeight * heightIncrease
          }px`
        : `${bbWidth}px ${bbHeight}px`;
      let dynamicBackgroundPositionX = isHovering
        ? -newImgLeft - offsetLeft
        : 0;
      let dynamicBackgroundPositionY = isHovering ? -newImgTop - offsetTop : 0;

      return {
        height: bbHeight,
        width: bbWidth,
        left: 0,
        top: 0,
        backgroundColor: "#474c58",
        backgroundImage: `url('${image}')`,
        backgroundRepeat: "no-repeat",

        backgroundSize: dynamicBackgroundSize,
        backgroundPositionX: dynamicBackgroundPositionX,
        backgroundPositionY: dynamicBackgroundPositionY,
      };
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    calculateNewImageDimensions();
    const objectImageElement = objectImageRef.current;
    if (!objectImageElement) return;

    const resizeObserver = new ResizeObserver(calculateNewImageDimensions);
    resizeObserver.observe(objectImageElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [objectImageRef, image]);

  return (
    <div
      className={styles.objectImageWrapper}
      ref={containerRef}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        className={styles.boundingBoxContainer}
        style={boundingBoxContainerStyle()}
      >
        {boundingBox && (
          <div className={styles.boundingBox} style={boundingBoxStyle()}></div>
        )}
        {boundingBox && (
          <div className={styles.zoomedObject} style={backgroundStyle()}></div>
        )}
      </div>

      <img
        ref={objectImageRef}
        className={styles.objectImage}
        src={image}
        alt="object img"
        loading="lazy"
        onLoad={handleLoad}
      />
    </div>
  );
};

const ImageBox = ({
  image,
  isObjectCluster,
  boundingBox,
  objectLabel,
  selectedObjectLabel,
  imageThumb,
  showZoomableObject,
  showZoomableImage,
  showVideoIcon,
  roundBottom,
  updateAssetData,
  showAssetPreview,
  hoverDelay,
}: any) => {
  return showZoomableObject ? (
    <ZoomableObjectBox
      image={image}
      imageThumb={imageThumb}
      objectLabel={objectLabel}
      selectedObjectLabel={selectedObjectLabel}
    />
  ) : showZoomableImage ? (
    <ZoomableImageBox
      image={image}
      imageThumb={imageThumb}
      objectLabel={objectLabel}
      selectedObjectLabel={selectedObjectLabel}
    />
  ) : isObjectCluster ? (
    <ObjectImageBox
      image={image}
      boundingBox={boundingBox}
      showVideoIcon={showVideoIcon}
      roundBottom={roundBottom}
      showAssetPreview={showAssetPreview}
      updateAssetData={updateAssetData}
      hoverDelay={hoverDelay}
    />
  ) : (
    <SimpleImageBox
      image={image}
      imageThumb={imageThumb}
      showVideoIcon={showVideoIcon}
      roundBottom={roundBottom}
      showAssetPreview={showAssetPreview}
      updateAssetData={updateAssetData}
      hoverDelay={hoverDelay}
    />
  );
};

export default ImageBox;
