type ChevronIconProps = {
  directon: "up" | "down" | "left" | "right";
  color?: string;
};

const ChevronIcon = ({ directon, color = "#fff" }: ChevronIconProps) => {
  switch (directon) {
    case "up":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="8"
          viewBox="0 0 13 9"
          fill="none"
        >
          <path
            d="M6.28783 0.601582L12.4902 6.80063L11.043 8.24707L6.28783 3.49447L1.53266 8.24707L0.0854322 6.80063L6.28783 0.601582Z"
            fill={color}
          />
        </svg>
      );
    case "down":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
        >
          <path
            d="M5.98902 7.4L0 1.4L1.39744 0L5.98902 4.6L10.5806 0L11.978 1.4L5.98902 7.4Z"
            fill={color}
          />
        </svg>
      );
    case "left":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
        >
          <path
            d="M0.000390267 6L6.00039 -6.11959e-08L7.40039 1.4L2.80039 6L7.40039 10.6L6.00039 12L0.000390267 6Z"
            fill={color}
          />
        </svg>
      );
    case "right":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
        >
          <path
            d="M7.4 6L1.4 12L-6.11959e-08 10.6L4.6 6L-4.63341e-07 1.4L1.4 -6.11959e-08L7.4 6Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default ChevronIcon;
