import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { IoIosArrowUp as OpenSelection } from "react-icons/io";
import { IoIosArrowDown as CloseSelection } from "react-icons/io";
import { State } from "redux/store";
import { setSelectedSampleDataset } from "redux/Datasets/actions";
import {
  getSelectedSampleDataset,
  getSampleData,
} from "redux/Datasets/selectors";

import checkIcon from "assets/img/check-circle.svg";

import styles from "./style.module.scss";

type DropDownTypes = {
  selectedSampleDataset: any;
  sampleData: any;
};

const DropDown = ({ selectedSampleDataset, sampleData }: DropDownTypes) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  const dispatch = useDispatch();

  const handleChange = (value: any) => {
    if (value.usage) {
      return;
    }
    dispatch(setSelectedSampleDataset(value));

    setIsDropdownOpen(false);
  };

  const toggleDropDown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleStyle = (flag: boolean) => {
    if (flag) {
      return { color: "#c0bfbd" };
    }
  };

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownHeader}>
        <label>Select a sample dataset</label>
      </div>
      <div className={styles.dataSelection}>
        <div className={styles.selection} onClick={toggleDropDown}>
          <div className={styles.optionContatainer}>
            <span className={styles.title}>
              {selectedSampleDataset?.display_name}
            </span>
            <span className={styles.value}>
              {selectedSampleDataset?.description}
            </span>
          </div>
          <span className={styles.chevronIcon}>
            {isDropdownOpen ? (
              <OpenSelection size="1.25em" />
            ) : (
              <CloseSelection size="1.25em" />
            )}
          </span>
        </div>
        {isDropdownOpen && (
          <div className={styles.selectorOptions}>
            {sampleData?.map((value: any, index: number): any => {
              return (
                <div
                  key={index}
                  className={styles.option}
                  onClick={() => handleChange(value)}
                  onMouseEnter={() => setSelectedOptionIndex(index)}
                  onMouseLeave={() => setSelectedOptionIndex(-1)}
                >
                  <div className={styles.leftSection}>
                    <span
                      className={styles.displayName}
                      style={handleStyle(value.usage)}
                    >
                      {value.display_name}
                    </span>
                    {value.usage ? (
                      <div className={styles.usageFlag}>Already Exists</div>
                    ) : null}
                  </div>
                  <div className={styles.rightSection}>
                    <span
                      className={styles.description}
                      style={handleStyle(value.usage)}
                    >
                      {value.description}
                    </span>

                    <img
                      className={
                        selectedOptionIndex === index && !value.usage
                          ? styles.checkIcon
                          : styles.noIcon
                      }
                      src={checkIcon}
                      alt=""
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

function mapStatesToProps(state: State) {
  return {
    selectedSampleDataset: getSelectedSampleDataset(state),
    sampleData: getSampleData(state),
  };
}

export default connect(mapStatesToProps)(DropDown);
