const ExploreTabIcon = ({ selected }: { selected: boolean }) => {
  const color = selected ? "#fff" : "#60646E";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
    >
      <ellipse
        cx="7.87938"
        cy="8.28588"
        rx="6.87938"
        ry="6.9226"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M15.9388 17.3293C15.6716 17.3206 15.4175 17.2107 15.2274 17.0214L13.5983 15.1172C13.249 14.7978 13.2205 14.2549 13.5343 13.9002C13.6811 13.7515 13.8809 13.668 14.0892 13.668C14.2975 13.668 14.4972 13.7515 14.6441 13.9002L16.6929 15.5396C16.9881 15.8416 17.0791 16.2901 16.9255 16.6844C16.7718 17.0788 16.402 17.3454 15.9815 17.3651L15.9388 17.3293Z"
        fill={color}
      />
    </svg>
  );
};

export default ExploreTabIcon;
