const PublicS3Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5941 4.09863L14.4414 12.0989L18.5941 20.0992L20.2953 19.1371V5.06069L18.5941 4.09863Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5429 4.09863L10.2673 5.06069L6.05469 12.0989L10.2673 19.1371L18.5429 20.0992V4.09863Z"
        fill="#3D4355"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.73853 4.09863L0.394531 4.70625V19.4915L1.73853 20.0992L10.1509 12.0989L1.73853 4.09863Z"
        fill="#3D4355"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75977 4.09863L10.1503 6.48073V17.9418L1.75977 20.0992V4.09863Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.253 7.80417L6.63867 7.22434L10.253 3.12109L13.8584 7.22434L10.253 7.80417Z"
        fill="#58150D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8584 7.21658L10.2485 7.80417L6.63867 7.21658V3.12109"
        fill="#3D4355"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.253 16.1953L6.63867 16.8804L10.253 20.4881L13.8584 16.8804L10.253 16.1953Z"
        fill="#3D4355"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1401 0L6.63867 1.91853V7.21975L10.151 6.19318L10.1401 0Z"
        fill="#3D4355"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.151 8.97754L6.63867 9.36898V14.6148L10.151 15.0265V8.97754Z"
        fill="#3D4355"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.151 17.8759L6.63867 16.9756V22.1211L10.151 24.0002V17.8759Z"
        fill="#3D4355"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8598 16.9756L10.1523 17.8762V24.0002L13.8598 22.1211V16.9756Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1523 8.97754L13.8598 9.36898V14.6148L10.1523 15.0265V8.97754Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1523 0L13.8598 1.91853V7.21975L10.1523 6.20999V0Z"
        fill="white"
      />
    </svg>
  );
};

export default PublicS3Icon;
