import { isAnonymousUser, selectUser } from "redux/authReducer";
import { connect } from "react-redux";
import { useState } from "react";
import { State } from "redux/store";
import {
  DatasetSortValue,
  DatasetStatusEnum,
  DatasetTypeEnum,
  InventoryFilterData,
  SetStateFunction,
} from "types";

import { getDatasets } from "redux/Datasets/selectors";
import FilterDropdownWrapper from "./FilterDropdownWrapper";
import FilterDropdown from "./FilterDropdown";
import SearchContainer from "./SearchContainer";

import styles from "./style.module.scss";

interface DatasetsFiltersProps {
  isPrivatePath: boolean;
  datasetsCountText: string;
  filterData: InventoryFilterData;
  setFilterData: SetStateFunction<InventoryFilterData>;
}

type InventoryFilterType = "sortBy" | "datasetType" | "datasetStatus";

const DatasetsFilters = ({
  filterData,
  isPrivatePath,
  setFilterData,
  datasetsCountText,
}: DatasetsFiltersProps) => {
  const [showDatasetTypeOptions, setShowDatasetTypeOptions] = useState(false);
  const [showSortByOptions, setShowSortByOptions] = useState(false);
  const [showDatasetStatusOptions, setShowDatasetStatusOptions] =
    useState(false);
  const { datasetStatus, datasetType, sortValue } = filterData;
  const user = selectUser();

  const DATASET_TYPES = [
    { id: DatasetTypeEnum.All, text: "All" },
    { id: DatasetTypeEnum.Private, text: "Private" },
    { id: DatasetTypeEnum.Public, text: "Public" },
  ];
  const DATASET_STATUS_OPTIONS = [
    { id: DatasetStatusEnum.READY, text: "Ready" },
    { id: DatasetStatusEnum.FATAL_ERROR, text: "Failed" },
    { id: DatasetStatusEnum.INITIALIZING, text: "Resume" },
    { id: DatasetStatusEnum.INDEXING, text: "Indexing" },
    { id: DatasetStatusEnum.UPLOADING, text: "Uploading" },
  ];
  const SORT_OPTIONS = [
    { id: "date", text: "Date Created" },
    { id: "size", text: "Size" },
    { id: "name", text: "Name" },
  ];

  const selectedDatasetStatusOptions = DATASET_STATUS_OPTIONS.filter((option) =>
    datasetStatus.find((status) => status.id === option.id && status.selected)
  ).map((option) => option.text);

  const datasetStatusFilterText =
    selectedDatasetStatusOptions.length > 0
      ? selectedDatasetStatusOptions[0]
      : "";
  const datasetTypeFilterText = filterData.datasetType
    ? filterData.datasetType
    : "";
  const sortByFilterText = SORT_OPTIONS.filter(
    (option) => sortValue === option.id
  ).map((option) => option.text);

  const datasetTypeAdditionalCount =
    selectedDatasetStatusOptions.length > 1
      ? selectedDatasetStatusOptions.length - 1
      : 0;

  const selectedDatasetStatus = datasetStatus
    .filter((status) => status.selected)
    .map((status) => status.id);

  const clearFilters = () => {
    const unselectedDatasetStatus = filterData.datasetStatus.map((status) => {
      return { ...status, selected: false };
    });

    setFilterData({
      datasetStatus: unselectedDatasetStatus,
      datasetType: DatasetTypeEnum.All,
      sortValue: "date",
      searchValue: "",
    });
  };

  const handleFilterSelection = (
    id: string | null,
    type: InventoryFilterType
  ) => {
    if (type === "datasetType") {
      if (id !== datasetType) {
        setFilterData({ ...filterData, datasetType: id as DatasetTypeEnum });
      }
      setShowDatasetTypeOptions(false);
    } else if (type === "datasetStatus") {
      const areAllSelected =
        selectedDatasetStatus.length === datasetStatus.length;
      if (id === "All") {
        const updatedValues = datasetStatus.map((type) => {
          return { ...type, selected: areAllSelected ? false : true };
        });

        setFilterData({ ...filterData, datasetStatus: updatedValues });
      } else {
        let updatedValues = datasetStatus.map((status) => {
          return {
            ...status,
            selected: status.id === id ? !status.selected : status.selected,
          };
        });

        setFilterData({ ...filterData, datasetStatus: updatedValues });
      }
    } else if (type === "sortBy") {
      if (id !== sortValue) {
        setFilterData({ ...filterData, sortValue: id as DatasetSortValue });
      }
      setShowSortByOptions(false);
    }
  };

  return (
    <div className={styles.datasetFilterContainer}>
      <div className={styles.leftContainer}>
        <SearchContainer
          filterData={filterData}
          setFilterData={setFilterData}
        />
        {/* DatasetType Filter */}
        {!(user === null || isAnonymousUser()) && (
          <FilterDropdownWrapper
            title={"Dataset Type"}
            filterSelectionText={datasetTypeFilterText}
            showDropdown={showDatasetTypeOptions}
            setShowDropdown={setShowDatasetTypeOptions}
          >
            <FilterDropdown
              selectedOptions={[]}
              options={DATASET_TYPES}
              selectedOption={datasetType}
              handleSelection={(id: string | null) =>
                handleFilterSelection(id, "datasetType")
              }
            />
          </FilterDropdownWrapper>
        )}

        {/* DatasetStatus Filter */}
        <FilterDropdownWrapper
          title={"Dataset Status"}
          filterSelectionText={datasetStatusFilterText}
          showDropdown={showDatasetStatusOptions}
          setShowDropdown={setShowDatasetStatusOptions}
          additionalCount={datasetTypeAdditionalCount}
        >
          <FilterDropdown
            selectedOption={null}
            allowMultiSelect={true}
            defaultOptionText={"All"}
            options={DATASET_STATUS_OPTIONS}
            selectedOptions={selectedDatasetStatus}
            handleSelection={(id: string | null) =>
              handleFilterSelection(id, "datasetStatus")
            }
          />
        </FilterDropdownWrapper>

        {/* Sort Filter */}
        <FilterDropdownWrapper
          title={"Sort by"}
          filterSelectionText={sortByFilterText[0]}
          showDropdown={showSortByOptions}
          setShowDropdown={setShowSortByOptions}
        >
          <FilterDropdown
            selectedOptions={[]}
            options={SORT_OPTIONS}
            selectedOption={sortValue}
            handleSelection={(id: string | null) =>
              handleFilterSelection(id, "sortBy")
            }
          />
        </FilterDropdownWrapper>

        <div className={styles.clearSelection} onClick={clearFilters}>
          Clear Selection
        </div>
      </div>
      <div className={styles.rightContainer}>{datasetsCountText}</div>
    </div>
  );
};

const mapStatesToProps = (state: State) => {
  return { datasets: getDatasets(state) };
};

export default connect(mapStatesToProps)(DatasetsFilters);
