import XIcon from "assets/icons/XIcon";
import styles from "./style.module.scss";

interface AssignTagsToastProps {
  status: "error" | "success" | "pending";
  heading: string;
  description: string;
  onClose: () => void;
}

const AssignTagsToast = ({
  status,
  heading,
  description,
  onClose,
}: AssignTagsToastProps) => {
  return (
    <div className={styles.toastWrapper}>
      <div className={styles.toastTextContainer}>
        <div className={styles.toastHeading}>{heading}</div>

        <div className={styles.toastDescriptionContainer}>
          <span>{description}</span>
        </div>
      </div>
      <div className={styles.crossButton} onClick={onClose}>
        <XIcon />
      </div>
    </div>
  );
};

export default AssignTagsToast;
