const VLEnrichmentIcon = ({ color = "#60646E" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09043 1.04654L4.7577 0H0.473389L1.68222 3.51889L1.6814 3.51855L3.67708 9.32834L6.51647 5.51142L6.5167 5.51152L5.09001 1.04721L5.09043 1.04654ZM17.2347 0H17.234H12.9738H12.9731L12.9736 0.000604988L11.2116 5.5107L11.2116 5.51066L8.85441 12.8822L13.0301 12.2399L14.0875 9.16169L14.0878 9.16215L16.0228 3.5293L11.2124 5.51171L11.2119 5.51101L16.0226 3.52845L17.2347 0ZM8.85414 12.8822L4.67785 12.238L3.67804 9.32774L6.51756 5.51066L8.85414 12.8822ZM11.3725 17.0644L8.85362 12.8838L6.33472 17.0644H11.3725ZM8.85379 12.8834L6.33488 17.064L4.67749 12.2393L8.85379 12.8834ZM13.0299 12.2402L11.3732 17.0631L8.85425 12.8826L13.0299 12.2402Z"
        fill={color}
      />
    </svg>
  );
};

export default VLEnrichmentIcon;
