import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import axios from "helpers/axiosInstance";
import formattedDateNow from "helpers/utility/formatters";
import { composeApiURL } from "helpers/utility/axiosHelpers";
import { PRIVATE_DATASETS_PATH } from "helpers/constants/paths";

import { State } from "redux/store";
import {
  CLEAR_EXPORT_DATA,
  ERROR_TEXT,
  IS_CONTENT_LOADING,
  IS_ERROR,
  IS_LOADING,
  IS_DISCLAIMER_SHOWN,
  LIMIT_ERROR,
  SET_BREADCRUMBS,
  SET_DATASETS,
  SET_DATASETS_SORTING_VALUE,
  SET_DATASET_ID,
  SET_DATASET_PROGRESS,
  SET_HEALTH_STATUS,
  SET_SAMPLE_DATA,
  SET_SELECTED_SAMPLE_DATASET,
  SET_DELETE_MODAL_ID,
  SET_PUBLIC_PARAMETER,
  SET_SELECTED_EXPORT_IDS,
  SET_SELECTED_IMAGE_COUNT,
  SET_DUPLICATE_DATASET,
} from "./constants";
import { AxiosProgressEvent } from "axios";
import { setShowInternalErrorModal } from "redux/Modals/actions";
import { DatasetSortValue } from "types";

export const setContentLoader = (data: any) => {
  return {
    type: IS_CONTENT_LOADING,
    data,
  };
};

export const setIsDisclaimerShown = (data: boolean) => {
  return {
    type: IS_DISCLAIMER_SHOWN,
    data,
  };
};

export const setLoader = (data: any) => {
  return {
    type: IS_LOADING,
    data,
  };
};

export const setSampleData = (data: any) => {
  return {
    type: SET_SAMPLE_DATA,
    data,
  };
};

export const setSelectedSampleDataset = (data: any) => {
  return {
    type: SET_SELECTED_SAMPLE_DATASET,
    data,
  };
};

export const setDatasetProgress = (datasetId: any, progress: any) => {
  return {
    type: SET_DATASET_PROGRESS,
    datasetId,
    progress,
  };
};

export const setDatasets = (data: any) => {
  return {
    type: SET_DATASETS,
    data,
  };
};

export const setDatasetId = (data: any) => {
  return {
    type: SET_DATASET_ID,
    data,
  };
};

export const setIsError = (data: any) => {
  return {
    type: IS_ERROR,
    data,
  };
};

export const setErrorText = (data: any) => {
  return {
    type: ERROR_TEXT,
    data,
  };
};

export const setBreadcrumbs = (data: any) => {
  return {
    type: SET_BREADCRUMBS,
    data,
  };
};

export const setLimitError = (data: string | null) => {
  return { type: LIMIT_ERROR, data };
};

export const setDeleteModalId = (data: any) => {
  return {
    type: SET_DELETE_MODAL_ID,
    data,
  };
};

export const setDatasetsSortingValue = (data: any) => {
  return {
    type: SET_DATASETS_SORTING_VALUE,
    data,
  };
};

export const setHealthStatus = (data: any) => {
  return {
    type: SET_HEALTH_STATUS,
    data,
  };
};

export const clearExportData = () => {
  return { type: CLEAR_EXPORT_DATA };
};

export const setPublicParameter = (data: string) => {
  return {
    type: SET_PUBLIC_PARAMETER,
    data,
  };
};

export const setDatasetIdToDuplicate = (data: any) => {
  return {
    type: SET_DUPLICATE_DATASET,
    data,
  };
};

export const setSelectedImageCount = (
  data: number,
  id: string,
  objectID: string | null
): ThunkAction<void, State, unknown, any> => {
  return (dispatch: Dispatch, getState: () => State) => {
    const imageCount = getState().datasets.selectedImageCount;
    const selectedExportIDs = { ...getState().datasets.selectedExportIDs };

    const selectedIDArray = Object.keys(selectedExportIDs);
    const imageExists = selectedIDArray.includes(id);

    if (objectID) {
      if (imageExists && selectedExportIDs[id].includes(objectID)) {
        dispatch({ type: SET_SELECTED_IMAGE_COUNT, data: imageCount - data });
      } else {
        dispatch({ type: SET_SELECTED_IMAGE_COUNT, data: imageCount + data });
      }
    } else {
      if (imageExists) {
        dispatch({ type: SET_SELECTED_IMAGE_COUNT, data: imageCount - data });
      } else {
        dispatch({ type: SET_SELECTED_IMAGE_COUNT, data: imageCount + data });
      }
    }
  };
};

export const setSelectedExportIDs = (
  imageID: string | null,
  totalData: { totalIDs: { string: [] }; totalCount: number } | null,
  objectID: string | null
): ThunkAction<void, State, unknown, any> => {
  return (dispatch: Dispatch, getState: () => State) => {
    //handle select all action
    if (totalData?.totalIDs && totalData.totalCount) {
      dispatch({ type: SET_SELECTED_EXPORT_IDS, data: totalData.totalIDs });
      dispatch({ type: SET_SELECTED_IMAGE_COUNT, data: totalData.totalCount });
      return;
    }

    if (imageID) {
      const currentExportIDs = { ...getState().datasets.selectedExportIDs };
      const imageIDExists = Object.keys(currentExportIDs).includes(imageID);

      const selectedObjectIDs = currentExportIDs[imageID] || [];

      const objectIDExists = selectedObjectIDs.includes(objectID);
      const isLastObjectID = objectIDExists && selectedObjectIDs.length === 1;

      // if imageExists and hasonly on objectID in array and it is selected again
      if (imageIDExists && isLastObjectID && objectID) {
        delete currentExportIDs[imageID];
        dispatch({ type: SET_SELECTED_EXPORT_IDS, data: currentExportIDs });
      } else {
        // if neither imageExists nor objectExists
        if (!imageIDExists && !objectIDExists) {
          let newObjectIDArray = [...selectedObjectIDs, objectID];
          let newExportIDs = {
            ...currentExportIDs,
            [imageID]: newObjectIDArray,
          };
          dispatch({ type: SET_SELECTED_EXPORT_IDS, data: newExportIDs });
        }
        // if imageExists but does not have current objectID
        else if (imageIDExists && !objectIDExists && objectID) {
          let newObjectIDs = [...currentExportIDs[imageID], objectID];
          let newExportIDs = {
            ...currentExportIDs,
            [imageID]: newObjectIDs,
          };
          dispatch({ type: SET_SELECTED_EXPORT_IDS, data: newExportIDs });
        }
        // if imageExists and has current objectID in array
        else if (imageIDExists && objectIDExists && objectID) {
          let newObjectIDs = selectedObjectIDs.filter(
            (id: any) => id !== objectID
          );
          let newExportIDs = {
            ...currentExportIDs,
            [imageID]: newObjectIDs,
          };
          dispatch({ type: SET_SELECTED_EXPORT_IDS, data: newExportIDs });
        }
        // if imageExists and there is no object ID passed
        else if (!objectID && imageIDExists) {
          delete currentExportIDs[imageID];
          dispatch({ type: SET_SELECTED_EXPORT_IDS, data: currentExportIDs });
        }
      }
    }
  };
};

export const fetchDatasets = (
  initialFetching = false
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    if (initialFetching) {
      dispatch(setDatasets([]));
      dispatch(setContentLoader(true));
    }
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        PRIVATE_DATASETS_PATH
      );
      const response = await axios.get(url);
      dispatch(setDatasets(response.data));
      dispatch(setContentLoader(false));
    } catch (error) {
      dispatch(setContentLoader(false));
      console.log(error);
    }
  };
};

export const fetchSampleData = (): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        "/datasets/sample_data"
      );
      const response = await axios.get(url);

      let newArray = response.data.map((obj: any) => ({
        id: obj.id,
        display_name: obj.display_name,
        description: obj.description,
        usage: false,
      }));

      dispatch(setSampleData(newArray));
      dispatch(setSelectedSampleDataset(newArray[0]));
    } catch (error) {
      console.log(error);
    }
  };
};

export const checkBucketList = (
  bucketName?: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/bucket?bucket_path=${bucketName}`
      );
      const response = await axios.get(url);
      if (response.status === 200) {
        dispatch(setErrorText("success"));
      }
    } catch (error: any) {
      dispatch(setErrorText(error.response.data.detail));
    }
  };
};

export const deleteDataset = (
  datasetID: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${datasetID}`
      );
      const response = await axios.delete(url);
      if (response.status === 200) {
        dispatch(setErrorText(""));
      }
    } catch (error: any) {
      dispatch(setErrorText(error.response.data.detail));
    }
  };
};

export const createDataset = (
  datasetName: any,
  s3Bucket?: any,
  selectedSampleId?: any,
  file?: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoader(true));
    dispatch(setDatasetId(null));
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        "/dataset"
      );
      var bodyFormData = new FormData();
      datasetName && bodyFormData.append("dataset_name", datasetName);
      s3Bucket && bodyFormData.append("bucket_path", s3Bucket);
      selectedSampleId &&
        bodyFormData.append("vl_dataset_id", selectedSampleId);
      file && bodyFormData.append("uploaded_filename", file.name);

      const response = await axios.post(url, bodyFormData);

      dispatch(setDatasetId(response.data.id));

      if (file) {
        dispatch(uploadDataset(response.data.id, file) as any);
      }
      dispatch(setLoader(false));
    } catch (error: any) {
      const errorResponse: { status: number; data: { detail: string } } =
        error.response;
      if (errorResponse.status === 412) {
        dispatch(setLimitError(errorResponse.data.detail));
      }
      if (errorResponse.status === 500) {
        dispatch(setShowInternalErrorModal(true));
      }
      dispatch(setLoader(false));
      dispatch(setIsError(true));
    }
  };
};

export const duplicateDataset = (
  datasetId: any,
  datasetName: any,
  pipelineType: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoader(true));
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${datasetId}/duplicate`
      );
      const bodyFormData = new FormData();
      bodyFormData.append("dataset_name", datasetName);
      bodyFormData.append("pipeline_type", pipelineType);

      await axios.post(url, bodyFormData);

      dispatch(setDatasetIdToDuplicate(null));
      dispatch(setLoader(false));
    } catch (error: any) {
      dispatch(setLoader(false));
    }
  };
};

export const uploadDataset = (
  datasetId: any,
  file: any
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoader(true));
    try {
      const url =
        process.env.REACT_APP_API_ENDPOINT +
        "/api/v1/dataset/" +
        datasetId +
        "/upload";

      const config = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 1)
          );
          dispatch(setDatasetProgress(datasetId, percentCompleted));
        },
      };

      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      await axios.post(url, bodyFormData, config);
      dispatch(setLoader(false));
    } catch (error: any) {
      dispatch(setLoader(false));
    }
  };
};

export const fetchSortedDatasets = (
  sortValue: DatasetSortValue,
  initialDatasetsFetch = false,
  isPrivate = false
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    const endpoint = isPrivate ? "datasets" : "pvld";
    const sortParams =
      sortValue === "name"
        ? "?sort_by=name"
        : sortValue === "size"
        ? "?sort_by=size"
        : "";
    try {
      if (initialDatasetsFetch) {
        dispatch(setDatasets([]));
        dispatch(setContentLoader(true));
      }
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/${endpoint}${sortParams}`
      );
      const response = await axios.get(url);

      dispatch(setDatasetsSortingValue(sortValue));
      dispatch(setDatasets(response.data));
      dispatch(setContentLoader(false));
    } catch (error) {
      dispatch(setContentLoader(false));
      console.log(error);
    }
  };
};

export const fetchHealthStatus = (): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/healthcheck`
      );
      const response = await axios.get(url);
      const prevHealtStatus = getState().datasets.healthStatus;

      // trying to remove the noise of the health status in the redux dev tools so only updates when the status changes
      if (prevHealtStatus?.status !== response.data.status) {
        dispatch(setHealthStatus(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const exportFile = (
  datasetID: string,
  clusterIDs: string,
  fileName: string,
  fileType: "CSV" | "JSON" | null,
  isSingleFile: boolean,
  isImageClusterPage: boolean
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const selectedFileType = isSingleFile
          ? "json_file"
          : fileType === "CSV"
          ? "csv"
          : "json_file_per_image";

        const extensionType =
          fileType === "JSON" && isSingleFile ? "json" : "zip";

        const selectedIDsType = isImageClusterPage
          ? "image_ids"
          : "cluster_ids";

        const dateNow = formattedDateNow(); // Format as YYYY-MM-DDTHHMMSS

        let tempURL = `/dataset/${datasetID}/export?${selectedIDsType}=${clusterIDs}&export_format=${selectedFileType}`;

        const finalURL = composeApiURL(
          process.env.REACT_APP_API_ENDPOINT!,
          tempURL
        );

        axios({
          url: finalURL,
          method: "GET",
          responseType: "blob",
        })
          .then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement("a");
            link.href = href;
            link.setAttribute(
              "download",
              `${fileName}-${dateNow}.${extensionType}`
            );
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            resolve();
          })
          .catch((error) => reject(error));
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };
};
