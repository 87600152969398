import { setShowDemoRequestSuccessModal } from "redux/Modals/actions";
import { useDispatch } from "react-redux";
import XIcon from "assets/icons/XIcon";

import styles from "./style.module.scss";

const RequestDemoSuccessModal = () => {
  const dispatch = useDispatch();

  const handleCloseButtonClick = (e: any) => {
    e.stopPropagation();
    dispatch(setShowDemoRequestSuccessModal(false));
  };

  return (
    <div className={styles.requestDemoSuccessModalWrapper}>
      <div className={styles.requestDemoSuccessModalContainer}>
        <div className={styles.content}>
          <div className={styles.middleText}>
            <div className={styles.title}>Done!</div>
            <div className={styles.subText}>
              One of our team members will reach out to you shortly.
            </div>
          </div>
          <div className={styles.closeButton} onClick={handleCloseButtonClick}>
            <XIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestDemoSuccessModal;
