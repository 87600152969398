import { useState } from "react";
import { validateName } from "helpers/validations";
import styles from "./style.module.scss";

function UpgradePlanForm({
  showModal,
  setErrorFlag,
  errorFlag,
  lastName,
  setLastName,
  firstName,
  setFirstName,
  email,
  setEmail,
}: any) {
  const [isSelected, setIsSelected] = useState(0);
  const [isUserSelected, setIsUserSelected] = useState(0);

  const handleFirstNameValidation = (value: any) => {
    let result = validateName(value);
    result
      ? setErrorFlag((prevState: any) => ({
          ...prevState,
          firstName: result,
        }))
      : setErrorFlag((prevState: any) => ({
          ...prevState,
          firstName: "",
        }));
  };

  const handleLastNameValidation = (value: any) => {
    let result = validateName(value);
    result
      ? setErrorFlag((prevState: any) => ({
          ...prevState,
          lastName: result,
        }))
      : setErrorFlag((prevState: any) => ({
          ...prevState,
          lastName: "",
        }));
  };

  return (
    <div
      className={showModal ? styles.selectSourceType : styles.hideSourceType}
    >
      <div className={styles.basicDetailsTop}>
        <span className={styles.upgrade}>
          Please Fill This Form And We Will Get In Touch With You Soon
        </span>
      </div>
      <div className={styles.basicDetailsBottom}>
        <div className={styles.names}>
          <div className={styles.firstName}>
            <input
              className={
                errorFlag.firstName !== ""
                  ? styles.errorNameInput
                  : styles.inputPlaceHolder
              }
              value={firstName}
              type="text"
              placeholder="First Name"
              onChange={(e) => {
                setFirstName(e.target.value);
                handleFirstNameValidation(e.target.value);
              }}
            />
          </div>
          <div className={styles.secondName}>
            <input
              value={lastName}
              className={
                errorFlag.lastName !== ""
                  ? styles.errorNameInput
                  : styles.inputPlaceHolder
              }
              type="text"
              placeholder="Last Name"
              onChange={(e) => {
                setLastName(e.target.value);
                handleLastNameValidation(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles.email}>
          <input
            className={
              errorFlag.email !== ""
                ? styles.errorEmailInput
                : styles.emailInput
            }
            value={email}
            type="text"
            placeholder="Work Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className={styles.heading}>
          <span>Expected number of images (optional)</span>
        </div>
        <div className={styles.chip}>
          <span
            className={
              isSelected === 1 ? styles.singleChipSelected : styles.singleChip
            }
            onClick={() => setIsSelected(1)}
          >
            1-10M
          </span>
          <span
            className={
              isSelected === 2 ? styles.singleChipSelected : styles.singleChip
            }
            onClick={() => setIsSelected(2)}
          >
            11-100M
          </span>
          <span
            className={
              isSelected === 3 ? styles.singleChipSelected : styles.singleChip
            }
            onClick={() => setIsSelected(3)}
          >
            101M-500M
          </span>
          <span
            className={
              isSelected === 4 ? styles.singleChipSelected : styles.singleChip
            }
            onClick={() => setIsSelected(4)}
          >
            500M-1B
          </span>
          <span
            className={
              isSelected === 5 ? styles.lastChipSelected : styles.lastChip
            }
            onClick={() => setIsSelected(5)}
          >
            1B+
          </span>
        </div>
        <div className={styles.expectedNumber}>
          <span>Expected number of Visual Layer users (optional)</span>
        </div>
        <div className={styles.chip}>
          <span
            className={
              isUserSelected === 1
                ? styles.numberChipSelected
                : styles.numberChip
            }
            onClick={() => setIsUserSelected(1)}
          >
            1-10{" "}
          </span>
          <span
            className={
              isUserSelected === 2
                ? styles.numberChipSelected
                : styles.numberChip
            }
            onClick={() => setIsUserSelected(2)}
          >
            11-50{" "}
          </span>
          <span
            className={
              isUserSelected === 3
                ? styles.numberChipSelected
                : styles.numberChip
            }
            onClick={() => setIsUserSelected(3)}
          >
            51 -250{" "}
          </span>
          <span
            className={
              isUserSelected === 4
                ? styles.numberChipSelected
                : styles.numberChip
            }
            onClick={() => setIsUserSelected(4)}
          >
            251 -500{" "}
          </span>
          <span
            className={
              isUserSelected === 5 ? styles.lastChipSelected : styles.lastChip
            }
            onClick={() => setIsUserSelected(5)}
          >
            500+
          </span>
        </div>
        <div className={styles.messageBox}>
          <textarea
            rows={4}
            cols={50}
            placeholder="Message"
            name="Message"
            form="usrform"
            className={styles.message}
          ></textarea>
        </div>
      </div>
    </div>
  );
}
export default UpgradePlanForm;
