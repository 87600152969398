import { BLUISH_GREY, BONE_GREY } from "helpers/constants/colors";
import ContentLoader from "react-content-loader";

import { ContentLoadProps } from "types";

const ContentLoad = ({ radius = "0px" }: ContentLoadProps) => {
  return (
    <ContentLoader
      speed={1.5}
      width={"100%"}
      height={"100%"}
      backgroundColor={BONE_GREY}
      foregroundColor={BLUISH_GREY}
    >
      <rect width="100%" rx={radius} height="100%" />
    </ContentLoader>
  );
};

export default ContentLoad;
