import TooltipWrapper from "views/uikit/TooltipWrapper";
import styles from "../style.module.scss";
import { BiChevronRight, BiX } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router";
import { connect, useDispatch } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  addToRecentSearches,
  removeFromRecentSearches,
  setCardslistLoading,
  setIsImageUploading,
  setSearchText,
} from "redux/SingleDataset/actions";
import {
  PAGE_PARAMETER,
  SEARCH_TEXT_PARAMETER,
} from "helpers/constants/miscellaneous";
import { State } from "redux/store";
import {
  getAutocompleteSuggestions,
  getRecentSearches,
  getIsAutoSuggestQueryEnabled,
  getIsRecentSearchEnabled,
  getAutocompleteLoading,
  getAutocompleteNoResultsFlag,
  getSearchText,
  getContentLoading,
  getIsCardslistLoading,
} from "redux/SingleDataset/selectors";
import { composeApiURL } from "helpers/utility/axiosHelpers";
import axios from "helpers/axiosInstance";
import {
  setShowDemoRequestModal,
  setShowLearnMoreModal,
} from "redux/Modals/actions";
import SearchIcon from "assets/icons/SearchIcon";
import ArrowCircleIcon from "assets/icons/ArrowCircleIcon";
import FindSimilarIcon from "assets/icons/FindSimilarIcon";
import { isFastdupUser } from "helpers/utility/utilities";
import classNames from "classnames";
import { amplitudeTrack } from "helpers/utility/amplitude";
import { AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__OPENED } from "helpers/constants/amplitudeEvents";

const SearchContainerContactBanner = ({
  isLoading,
}: {
  isLoading: boolean;
}) => {
  const dispatch = useDispatch();
  const handleContactButtonClick = () => {
    if (isFastdupUser()) {
      dispatch(setShowLearnMoreModal(true));
      amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__OPENED);
    } else {
      dispatch(
        setShowDemoRequestModal({ flag: true, calledFrom: "textual_search" })
      );
    }
  };
  const buttonText = isFastdupUser() ? "LEARN MORE" : "Contact us";

  return !isLoading ? (
    <div className={styles.searchContainerContactBanner}>
      <div className={styles.searchBarIcon}>
        <SearchIcon />
      </div>
      <div
        className={styles.textContainer}
        title="Unlock to search Images and Clusters"
      >
        Unlock to search Images and Clusters
      </div>
      <div
        className={classNames(styles.contactUsButton, {
          [styles.learnMoreButton]: isFastdupUser(),
        })}
        onClick={handleContactButtonClick}
      >
        <div className={styles.contactButtonIcon}>
          <ArrowCircleIcon showGradient={true} />
        </div>
        {buttonText}
      </div>
    </div>
  ) : null;
};

const ImageSearchButton = ({
  isImageUploading,
  isHovering,
  setIsHovering,
}: any) => {
  const dispatch = useDispatch();

  const selectedButtonStyle = () => {
    if (isImageUploading) {
      return {
        borderColor: "#fff",
        backgroundColor: "#0197d8",
      };
    }
    return undefined;
  };

  const handlebuttonClick = () => {
    if (isImageUploading) {
      dispatch(setIsImageUploading(false));
    } else {
      dispatch(setIsImageUploading(true));
    }
  };

  return (
    <div
      className={styles.imageSearchButton}
      style={selectedButtonStyle()}
      onClick={handlebuttonClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      data-tooltip-content={
        "Temporarily upload an Image to find similar Images in this Dataset"
      }
      data-tooltip-id="data-page-image-upload-button"
      data-tooltip-place="top"
    >
      <FindSimilarIcon
        color={isImageUploading ? "#fff" : isHovering ? "#151928" : "#fff"}
      />
      <TooltipWrapper id={"data-page-image-upload-button"} maxWidth="254px" />
    </div>
  );
};

const SearchContainer = ({
  isSearchInFocus,
  datasetId,
  setIsSearchInFocus,
  logSearchRemoval,
  resetImageSearch,
  searchEnabled,
  imageSearchEnabled,
  isImageUploading,
  isDataPage,
  recentSearches,
  isRecentSearchEnabled,
  isAutoSuggestQueryEnabled,
  logSearchAddition,
  isClearFiltersCalled,
  setIsClearFiltersCalled,
  searchText,
  isLoading,
}: any) => {
  const [isHoveringOnImageUpload, setIsHoveringOnImageUpload] = useState(false);
  const [isRecentSearchInFocus, setIsRecentSearchInFocus] = useState(false);
  const [autocompleteSearchText, setAutocompleteSearchText] = useState("");
  const [suggestionFlag, setSuggestionFlag] = useState(false);
  const [inputText, setInputText] = useState(searchText);
  const [noResultsFlag, setNoResultsFlag] = useState(false);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
  const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(false);
  const [autocompleteTimeout, setAutocompleteTimeout] = useState<number | null>(
    null
  );
  const [firstLoad, setFirstLoad] = useState(false);

  const recentSearchesRef = useRef<HTMLDivElement>(null);
  const availableSuggestionsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const showImageSearchButton = imageSearchEnabled;
  const isSearchTextInParams = !!searchParams.get(SEARCH_TEXT_PARAMETER);
  const showRecentSearches =
    searchEnabled &&
    !!isRecentSearchEnabled &&
    Object.keys(recentSearches).includes(datasetId) &&
    recentSearches[datasetId].length > 0 &&
    !inputText &&
    isRecentSearchInFocus;

  const showAutocompleteSuggestions =
    searchEnabled &&
    isAutoSuggestQueryEnabled &&
    (autocompleteSuggestions.length > 0 ||
      isAutocompleteLoading ||
      noResultsFlag) &&
    !showRecentSearches;

  const recentSearchArray = showRecentSearches ? recentSearches[datasetId] : [];

  const clearAll = () => {
    setIsHoveringOnImageUpload(false);
    setIsRecentSearchInFocus(false);
    setAutocompleteSearchText("");
    setSuggestionFlag(false);
    setInputText("");
    setNoResultsFlag(false);
    setAutocompleteSuggestions([]);
    setIsAutocompleteLoading(false);
  };

  const handleSearchByText = (e: any, clicked?: boolean) => {
    if (e.keyCode === 13 || clicked) {
      if (inputText === searchParams.get(SEARCH_TEXT_PARAMETER)) return;
      if (inputText) {
        dispatch(setCardslistLoading(true));
        searchParams.set(SEARCH_TEXT_PARAMETER, inputText);
        searchParams.set(PAGE_PARAMETER, "1");
        navigate({ search: `?${searchParams.toString()}` });
        if (isRecentSearchEnabled && datasetId) {
          dispatch(addToRecentSearches(datasetId, inputText.trim()) as any);
        }
        resetImageSearch();
        logSearchAddition();
      }
    }
  };

  const handleAutocompleteSuggestionClick = (suggestion: string) => {
    setIsRecentSearchInFocus(false);
    setSuggestionFlag(true);
    setInputText(suggestion);
    setAutocompleteSuggestions([]);
  };

  const handleRecentSuggestionClick = (suggestion: string) => {
    setIsRecentSearchInFocus(false);
    setSuggestionFlag(true);
    setInputText(suggestion);
    setAutocompleteSuggestions([]);
  };

  const handleRemoveSuggestion = (e: any, suggestion: string) => {
    e.stopPropagation();
    dispatch(removeFromRecentSearches(datasetId, suggestion) as any);
  };

  const handleOutsideClick = (event: any) => {
    const clickedOutsideRecentSearches =
      recentSearchesRef.current &&
      !recentSearchesRef.current.contains(event.target);

    const clickedOutsideAvailableSuggestions =
      availableSuggestionsRef.current &&
      !availableSuggestionsRef.current.contains(event.target);

    const clickedOutsideInput =
      inputRef.current && !inputRef.current.contains(event.target);

    if (
      (clickedOutsideRecentSearches || clickedOutsideAvailableSuggestions) &&
      clickedOutsideInput
    ) {
      setIsRecentSearchInFocus(false);
      setNoResultsFlag(false);
      setAutocompleteSuggestions([]);
      setAutocompleteSearchText("");
    }
  };

  const handleClearSearch = () => {
    searchParams.set(PAGE_PARAMETER, "1");
    clearAll();
    if (searchParams.has(SEARCH_TEXT_PARAMETER)) {
      searchParams.delete(SEARCH_TEXT_PARAMETER);
      navigate({ search: `?${searchParams.toString()}` });
      logSearchRemoval();
    }
  };

  const fetchAutocompleteSuggestions = async (
    datasetId: string,
    query: string
  ) => {
    try {
      if (autocompleteTimeout) {
        clearTimeout(autocompleteTimeout);
        setAutocompleteTimeout(null);
      }

      const encodedQuery = encodeURIComponent(query);
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${datasetId}/autocomplete?query=${encodedQuery}`
      );

      const response = await axios.get(url);
      const suggestions = response.data.results || [];

      if (suggestions.length > 0) {
        setAutocompleteSuggestions(suggestions);
        setNoResultsFlag(false);
      } else {
        setAutocompleteSuggestions([]);
        setNoResultsFlag(true);
      }
      setIsAutocompleteLoading(false);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setAutocompleteSuggestions([]);
      setIsAutocompleteLoading(false);
      setNoResultsFlag(false);
    }
  };

  const handleInputChange = (e: any) => {
    const newValue = e.target.value;
    setAutocompleteSuggestions([]);
    setAutocompleteSearchText("");
    setInputText(newValue);
    setSuggestionFlag(false);

    if (!newValue && isSearchTextInParams) {
      dispatch(setSearchText(""));
      searchParams.delete(SEARCH_TEXT_PARAMETER);
      navigate({ search: `?${searchParams.toString()}` });
      resetImageSearch();
      logSearchRemoval();
    }
  };

  const handleTooltipContent = () => {
    const result =
      isSearchInFocus || isHoveringOnImageUpload
        ? ""
        : `Enter keywords to find specific Images, Objects within Images, or Video Key Frames, e.g. 'white car' or 'person on a motorcycle'.`;

    return result;
  };

  const handleSuggestions = () => {
    const handleHeader = () => {
      if (isAutocompleteLoading && autocompleteSuggestions.length < 1) {
        return <div className={styles.autocompleteLoadingText}>Loading...</div>;
      } else if (!isAutocompleteLoading && noResultsFlag) {
        return (
          <div className={styles.autocompleteLoadingText}>No results found</div>
        );
      } else if (autocompleteSuggestions.length > 0) {
        return <div className={styles.autocompleteLoadingText}>Results</div>;
      }
    };
    return (
      <>
        {handleHeader()}
        {autocompleteSuggestions.map((suggestion: string, index: number) => {
          const queryIndex = suggestion
            .toLowerCase()
            .indexOf(autocompleteSearchText.toLowerCase());

          if (queryIndex === -1) {
            return (
              <div
                key={index}
                className={styles.autocompleteItem}
                onClick={() => handleAutocompleteSuggestionClick(suggestion)}
              >
                {suggestion}
              </div>
            );
          }

          const beforeQueryText = suggestion.substring(0, queryIndex);
          const queryText = suggestion.substring(
            queryIndex,
            queryIndex + autocompleteSearchText.length
          );
          const afterQueryText = suggestion.substring(
            queryIndex + autocompleteSearchText.length
          );

          const showRoundCorners =
            index === autocompleteSuggestions.length - 1 && index >= 4
              ? styles.lastSuggestionItem
              : "";

          return (
            <div
              key={index}
              className={`${styles.autocompleteItem} ${showRoundCorners}`}
              onClick={() => handleAutocompleteSuggestionClick(suggestion)}
            >
              <span style={{ fontWeight: "700" }}>{beforeQueryText}</span>
              <span style={{ fontWeight: "300" }}>{queryText}</span>
              <span style={{ fontWeight: "700" }}>{afterQueryText}</span>
            </div>
          );
        })}
      </>
    );
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode !== 13) return;
    else handleSearchClick(e);
  };

  const handleSearchClick = (e: any, flag?: boolean) => {
    if (autocompleteTimeout) {
      clearTimeout(autocompleteTimeout);
      setAutocompleteTimeout(null);
    }
    dispatch(setSearchText(inputText));
    setAutocompleteSearchText("");
    setIsAutocompleteLoading(false);
    setAutocompleteSuggestions([]);
    setNoResultsFlag(false);
    setSuggestionFlag(true);
    handleSearchByText(e, flag);
  };

  const handleRecentSearchDisplay = () => {
    if (showRecentSearches) {
      return recentSearchArray.map((suggestion: string, index: number) => {
        const showRoundCorners =
          index === recentSearchArray.length - 1 && index >= 4
            ? styles.lastSuggestionItem
            : "";
        return (
          <div
            key={index}
            className={`${styles.recentItem} ${showRoundCorners}`}
            onClick={() => handleRecentSuggestionClick(suggestion)}
          >
            <div className={styles.recentItemText}>{suggestion}</div>
            <div
              className={styles.removeSuggestionButton}
              onClick={(e: any) => handleRemoveSuggestion(e, suggestion)}
            >
              <BiX size="1.25em" />
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  };

  const displaySeachByText = () => {
    const dynamicSubmitButtonStyle = () => {
      let marginRight = "";
      let pointerEvents = "";

      if (!inputText) pointerEvents = "none";
      if (!showImageSearchButton) marginRight = "5px";

      const styles: any = {};
      if (marginRight) styles.marginRight = marginRight;
      if (pointerEvents) styles.pointerEvents = pointerEvents;

      return Object.keys(styles).length > 0 ? styles : undefined;
    };

    return (
      <div className={styles.textualSearchContainer}>
        <div className={styles.searchBarIcon}>
          <SearchIcon />
        </div>
        <div className={styles.inputBox}>
          <input
            type="search"
            placeholder="Search by text"
            onChange={handleInputChange}
            value={inputText}
            className={styles.searchBarInput}
            onKeyDown={handleKeyDown}
            onFocus={() => {
              setIsSearchInFocus(true);
              setIsRecentSearchInFocus(true);
            }}
            onBlur={() => setIsSearchInFocus(false)}
            ref={inputRef}
          />
          <div
            className={inputText ? styles.crossBtn : styles.disabledCrossBtn}
            onClick={handleClearSearch}
          >
            <BiX size="1.25em" />
          </div>
        </div>
        <div
          className={styles.searchBarButton}
          onClick={(e) => handleSearchClick(e, true)}
          style={dynamicSubmitButtonStyle()}
        >
          <BiChevronRight size="1.5em" />
        </div>
      </div>
    );
  };

  const dynamicContainerWidth = () => {
    const width = showImageSearchButton
      ? "max-content"
      : searchEnabled
      ? "387px"
      : "max-content";
    const minWidth = showImageSearchButton
      ? "285px"
      : searchEnabled
      ? "285px"
      : "245px";

    return {
      width,
      minWidth,
    };
  };

  useEffect(() => {
    if (!inputText && searchText) {
      setFirstLoad(true); //handles first load of page where search text was in URL
      setInputText(searchText);
    }
    //eslint-disable-next-line
  }, [searchText]);

  useEffect(() => {
    if (isAutoSuggestQueryEnabled) {
      if (!suggestionFlag) {
        if (!inputText) {
          setIsAutocompleteLoading(false);
          setNoResultsFlag(false);
        } else {
          if (!firstLoad) {
            setIsRecentSearchInFocus(false);
            setIsAutocompleteLoading(true);
            const timeoutId: any = setTimeout(() => {
              setAutocompleteSearchText(inputText.trim());
            }, 1000);
            setAutocompleteTimeout(timeoutId);

            return () => {
              clearTimeout(timeoutId);
              setAutocompleteTimeout(null);
            };
          } else if (firstLoad) {
            setFirstLoad(false);
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [inputText, suggestionFlag]);

  useEffect(() => {
    if (isAutoSuggestQueryEnabled) {
      if (autocompleteSearchText) {
        fetchAutocompleteSuggestions(datasetId, autocompleteSearchText) as any;
      } else {
        setAutocompleteSuggestions([]);
      }
    }
    //eslint-disable-next-line
  }, [autocompleteSearchText]);

  useEffect(() => {
    if (isRecentSearchEnabled && isRecentSearchInFocus) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [isRecentSearchInFocus]);

  useEffect(() => {
    //handles ClearFilter click
    if (isClearFiltersCalled) {
      clearAll();
      setIsClearFiltersCalled(false);
    }
    //eslint-disable-next-line
  }, [isClearFiltersCalled]);

  return (
    <div
      className={styles.searchBarContainer}
      data-tooltip-content={handleTooltipContent()}
      data-tooltip-id="data-page-search-by-text-box"
      data-tooltip-place="top"
      style={dynamicContainerWidth()}
    >
      {searchEnabled ? (
        displaySeachByText()
      ) : (
        <SearchContainerContactBanner isLoading={isLoading} />
      )}

      {showImageSearchButton && (
        <ImageSearchButton
          isImageUploading={isImageUploading}
          isHovering={isHoveringOnImageUpload}
          setIsHovering={setIsHoveringOnImageUpload}
        />
      )}

      {showAutocompleteSuggestions && (
        <div
          className={styles.autocompleteDropdown}
          ref={availableSuggestionsRef}
        >
          {handleSuggestions()}
        </div>
      )}

      {showRecentSearches && (
        <div
          className={styles.recentSuggestionsDropdown}
          ref={recentSearchesRef}
        >
          <div className={styles.recentSearchHeader}>
            <div className={styles.recentSearchHeaderTitle}>Recent</div>
          </div>
          {handleRecentSearchDisplay()}
        </div>
      )}

      {!inputText && searchEnabled && (
        <TooltipWrapper id={"data-page-search-by-text-box"} maxWidth="254px" />
      )}
    </div>
  );
};

const mapStatesToProps = (state: State) => {
  return {
    autocompleteSuggestions: getAutocompleteSuggestions(state),
    recentSearches: getRecentSearches(state),
    isRecentSearchEnabled: getIsRecentSearchEnabled(state),
    isAutoSuggestQueryEnabled: getIsAutoSuggestQueryEnabled(state),
    isAutoCompleteLoading: getAutocompleteLoading(state),
    noResultsFlag: getAutocompleteNoResultsFlag(state),
    searchText: getSearchText(state),
    isLoading: getContentLoading(state) || getIsCardslistLoading(state),
  };
};
export default connect(mapStatesToProps)(SearchContainer);
