import { connect } from "react-redux";

import { getContentLoading, getHealthStatus } from "redux/Datasets/selectors";
import { State } from "redux/store";

import styles from "./style.module.scss";

const HealthStatus = ({ healthStatus, isContentLoading }: any) => {
  return (
    healthStatus &&
    healthStatus.status !== "Green" && (
      <div className={styles.healthStatusBoxContainer}>
        <span
          className={styles.message}
          title={healthStatus["service-health-message"]}
        >
          [ {healthStatus["service-health-message"]} ]
        </span>
      </div>
    )
  );
};

const mapStateToProps = (state: State) => {
  return {
    healthStatus: getHealthStatus(state),
    isContentLoading: getContentLoading(state),
  };
};

export default connect(mapStateToProps)(HealthStatus);
