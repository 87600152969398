import { AnnotatedBoundingBox } from "extractedBackendTypes";
import { FileType } from "types";

import ObjectPreview from "./Object";
import VideoPreview from "./Video";

import styles from "./style.module.scss";

const ImagePreview = ({ src, boundingBox, fileType }: PreviewCardProps) => {
  const renderPreview = () => {
    switch (fileType) {
      case FileType.IMAGE:
      case FileType.UNKNOWN:
        if (boundingBox.length > 0) {
          return <ObjectPreview src={src} boundingBox={boundingBox} />;
        } else {
          return (
            <img
              className={styles.plainImage}
              src={src}
              alt="local-img-preview"
            />
          );
        }
      case FileType.VIDEO:
        return <VideoPreview src={src} />;
    }
  };

  return renderPreview();
};

export default ImagePreview;

interface PreviewCardProps {
  src: string;
  boundingBox: AnnotatedBoundingBox[];
  fileType: FileType;
}
