import classNames from "classnames";

import styles from "./style.module.scss";

interface FileOptionProps {
  title: string;
  selected: boolean;
  disabled?: boolean;
  badgeText?: string;
  onSelect: () => void;
}

const FileOption = ({
  title,
  selected,
  onSelect,
  disabled,
  badgeText,
}: FileOptionProps) => {
  const fileOptionClassnames = classNames(styles.fileOption, {
    [styles.disabledOption]: disabled,
  });

  const fileOptionTitleClassnames = classNames(styles.fileOptionTitle, {
    [styles.disabledText]: disabled,
  });

  return (
    <label className={fileOptionClassnames} onClick={onSelect}>
      <div className={styles.selectionIcon}>
        {selected && <div className={styles.radioIcon}></div>}
      </div>

      <div className={styles.fileOptionTitleContainer}>
        <div className={fileOptionTitleClassnames}>{title}</div>
        {badgeText && <div className={styles.badge}>{badgeText}</div>}
      </div>
    </label>
  );
};

export default FileOption;
