import {
  FilterItemCounts,
  MetadataSummaryData,
  RawMetadataSummaryData,
  UserInfo,
} from "types";
import { createSelector } from "@reduxjs/toolkit";
import { State } from "redux/store";
import { FilterOption } from "types";
import * as Features from "helpers/constants/filters";
import { isFeatureEnabled } from "helpers/utility/utilities";
import { getUserInfo } from "redux/User/selectors";

const state = (state: any) => state.singleDataset;

export const getContentLoading = createSelector(state, (state: any) => {
  return state.isContentLoading;
});

export const getIsCardslistLoading = createSelector(state, (state: any) => {
  return state.isCardslistLoading;
});

export const getIsLabelLoading = createSelector(state, (state: any) => {
  return state.isLabelLoading;
});

export const getIssueId = createSelector(state, (state: any) => {
  return state.issueId;
});

export const getSeverityId = createSelector(state, (state: any) => {
  return state.severityId;
});

export const getIssueData = createSelector(state, (state: any) => {
  return state.issues;
});

export const getSeverityData = createSelector(state, (state: any) => {
  return state.stats;
});

export const getDatasetSortingValue = createSelector(state, (state: any) => {
  return state.datasetSortingValue;
});

export const getVertexID = createSelector(state, (state: any) => {
  return state.vertexID;
});

export const getVertexType = createSelector(state, (state: any) => {
  return state.vertexType;
});

export const getSimilarityVertexCluster = createSelector(
  state,
  (state: any) => {
    return state.similarityVertexCluster;
  }
);

export const getTreeFolderRaw = createSelector(state, (state: any) => {
  return state.treeFolder;
});

const recursivelyUpdateTreeFolder = (treeFolder: any) => {
  let processedTreeFolder = Object.create(null);
  processedTreeFolder.key = treeFolder.full_path;
  processedTreeFolder.title = treeFolder.name;
  if (treeFolder.children) {
    processedTreeFolder.children = treeFolder.children.map((child: any) =>
      recursivelyUpdateTreeFolder(child)
    );
  } else {
    processedTreeFolder.isLeaf = true;
  }
  return processedTreeFolder;
};

export const getTreeFolder = createSelector(
  getTreeFolderRaw,
  (treeFolder: any) => {
    return treeFolder
      ? recursivelyUpdateTreeFolder(treeFolder)?.children
      : null;
  }
);

export const getSelectedFolder = createSelector(state, (state: any) => {
  const selectedFolder: string = state.filterFolder;
  if (selectedFolder && !selectedFolder.startsWith("/")) {
    return "/" + state.filterFolder;
  }
  return state.filterFolder;
});

export const getSimilarityThreshold = createSelector(state, (state: any) => {
  return state.similarityThreshold;
});

export const getSimilarityClusters = createSelector(state, (state: any) => {
  return state.similarityClusters;
});

export const getEntityTypeFilter = createSelector(state, (state: any) => {
  return state.entityTypeFilter.sort((a: FilterOption, b: FilterOption) =>
    a.text.localeCompare(b.text)
  );
});

export const getFilteredSimilarityClusters = createSelector(
  getSimilarityClusters,
  getSimilarityVertexCluster,
  (similarityClusters: any, similarityVertexCluster: any) => {
    if (similarityVertexCluster) {
      return similarityClusters.filter(
        (cluster: any) =>
          cluster.cluster_id !== similarityVertexCluster.cluster_id
      );
    } else {
      return similarityClusters;
    }
  }
);

export const getStats = createSelector(state, (state: any) => {
  return state.stats;
});

export const getDataset = createSelector(getStats, (stats: any) => {
  return stats?.dataset;
});

export const getThreasholdsRange = createSelector(state, (state: any) => {
  const simThreasholdsArray = state.similarityThresholds;
  if (simThreasholdsArray) {
    const simThresholdsNum = simThreasholdsArray.map((threshold: string) =>
      Number(threshold)
    );
    return {
      minThreshold: Math.min(...simThresholdsNum),
      maxThreshold: Math.max(...simThresholdsNum),
    };
  } else {
    return {
      minThreshold: 0,
      maxThreshold: 4,
    };
  }
});

export const getDatasetLabels = createSelector(state, (state: any) => {
  let sortedLabels = [...state.labels];
  sortedLabels = sortedLabels.sort((a: FilterOption, b: FilterOption) => {
    if (a.text === "unlabeled") {
      return -1;
    } else if (b.text === "unlabeled") {
      return 1;
    } else {
      return a.text.localeCompare(b.text);
    }
  });

  return sortedLabels;
});

export const getDatasetIssueTypes = createSelector(state, (state: any) => {
  let sortedIssueTypes = [...state.issueTypes];
  sortedIssueTypes = sortedIssueTypes.sort(
    (a: FilterOption, b: FilterOption) => {
      return a.text.localeCompare(b.text);
    }
  );

  return sortedIssueTypes;
});

export const getNavigationCluster = createSelector(state, (state: any) => {
  return state.navigationCluster;
});

export const getFilteredNavigationCluster = createSelector(
  getNavigationCluster,
  getSimilarityVertexCluster,
  (navigationCluster: any, similarityVertexCluster: any) => {
    if (
      similarityVertexCluster &&
      similarityVertexCluster.previews &&
      similarityVertexCluster.previews.length === 1 &&
      navigationCluster &&
      navigationCluster.previews
    ) {
      return {
        ...navigationCluster,
        previews: navigationCluster.previews.filter(
          (preview: any) =>
            preview.media_id !== similarityVertexCluster.previews[0].media_id
        ),
      };
    } else {
      return navigationCluster;
    }
  }
);

export const getNavigationClusterLoader = createSelector(
  state,
  (state: any) => {
    return state.navigationClusterLoader;
  }
);

export const getSearchText = createSelector(state, (state: any) => {
  return state.searchText;
});

export const getTimeRange = createSelector(state, (state: any) => {
  return state.timeRange;
});

export const getFileNameSearchText = createSelector(state, (state: any) => {
  return state.fileNameSearchText;
});

export const getUserConfig = createSelector(state, (state: any) => {
  return state.filterConfig;
});

export const getIsToggleConfig = (type: string) => {
  return createSelector(
    (state: State) => state,
    (state: State) => {
      if (type === "entityType") {
        let configs = [...state.singleDataset.filterConfig];
        for (let config of configs) {
          if (config.feature_key === "ENTITY_TYPE_FILTER") {
            let toggleConfig = Object.values(config.feature_behavior).some(
              (behavior: any) => behavior === "TOGGLE"
            );
            if (toggleConfig) return true;
          }
        }
      }
    }
  );
};

export const getExportData = createSelector(state, (state: any) => {
  const datasetID = state?.stats?.dataset?.id || "";
  let exportData = state.exportData[datasetID];
  if (exportData && exportData.exportDataArray) {
    return exportData.exportDataArray;
  }
  return [];
});

export const getCartData = createSelector(state, (state: any) => {
  const datasetID = state?.stats?.dataset?.id || "";
  let cartData = state.exportData[datasetID];
  return cartData || null;
});

export const getExportCount = createSelector(state, (state: any) => {
  const datasetID = state?.stats?.dataset?.id || "";
  let exportData = state.exportData[datasetID];
  if (exportData) return exportData?.exportDataArray.length;

  return 0;
});

export const getExportTags = createSelector(state, (state: any) => {
  const datasetID = state?.stats?.dataset?.id || "";
  let exportData = state.exportData[datasetID];
  if (exportData) return exportData?.tags;

  return [];
});

export const getShowCart = createSelector(state, (state: any) => {
  return state.showCart;
});

export const getTempExportData = createSelector(state, (state: any) => {
  return state.tempExportData;
});

export const getExportDataTimeoutID = createSelector(state, (state: any) => {
  return state.exportDataTimeoutID;
});

export const isCartEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.ACTIONS_CART);
  }
);

export const isSharingEnabled = createSelector(
  [getUserConfig, getDataset],
  (userConfig: any, dataset: any) => {
    return (
      isFeatureEnabled(userConfig, Features.DATASET_SHARE) && !dataset?.sample
    );
  }
);

export const isSearchEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.TEXTUAL_SEARCH);
  }
);

export const isImageSearchEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.VISUAL_SEARCH);
  }
);

export const getIsRecentSearchEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.AUTO_SUGGEST_RECENT_SEARCH);
  }
);

export const getIsAutoSuggestQueryEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.AUTO_SUGGEST_QUERY);
  }
);

export const isFolderFilterEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.FILE_FOLDER_FILTER);
  }
);

export const isClusterMetadataSummaryEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.CLUSTER_METADATA_SUMMARY);
  }
);

export const isDataMetadataSummaryEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return true;
  }
);

export const isFindSimilarObjectEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.FIND_SIMILAR_OBJECT_FILTER);
  }
);

export const isRegionSearchEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.REGION_SELECTION_SIMILARITY);
  }
);

export const getDataPageNumber = createSelector(state, (state: any) => {
  return state.dataPageNumber;
});

export const getClusterPageNumber = createSelector(state, (state: any) => {
  return state.clusterPageNumber;
});

export const getPaginationMetadataForData = createSelector(
  state,
  (state: any) => {
    return state.paginationMetadataForData;
  }
);

export const getTotalPagesForData = createSelector(
  getPaginationMetadataForData,
  (metadata?: { total: number; size: number }) => {
    if (metadata) {
      const { total, size } = metadata;
      return total < size ? 1 : Math.ceil(total / size);
    } else {
      return 0;
    }
  }
);

export const getPaginationMetadataForCluster = createSelector(
  state,
  (state: any) => {
    return state.paginationMetadataForCluster;
  }
);

export const getTotalPagesForCluster = createSelector(
  getPaginationMetadataForCluster,
  (metadata?: { total: number; size: number }) => {
    if (metadata) {
      const { total, size } = metadata;
      return total < size ? 1 : Math.ceil(total / size);
    } else {
      return 0;
    }
  }
);

const getRawClusterMetadataSummary = createSelector(
  state,
  (state: any): RawMetadataSummaryData => {
    return state.clusterMetadataSummary;
  }
);

export const getClusterMetadataSummary = createSelector(
  state,
  getRawClusterMetadataSummary,
  (state: any, rawMd: any): MetadataSummaryData => {
    if (!rawMd)
      return {
        showVLEnrichment: false,
        videoData: [],
        vlLabels: [],
        labels: [],
        userTags: [],
        issueTypes: [],
      };
    const {
      showVLEnrichment,
      videoData,
      imageLabels,
      objectLabels,
      userTags,
      issueData,
      vlImageLabels,
      vlObjectLabels,
    } = rawMd;
    const fileFolderEnabled = isFeatureEnabled(
      state.filterConfig,
      Features.FILE_FOLDER_FILTER
    );
    let videoDataWithSelect = [];
    if (fileFolderEnabled) {
      videoDataWithSelect = videoData.map((item: FilterItemCounts) => {
        const filterFolder = state.filterFolder?.startsWith("/")
          ? state.filterFolder.slice(1)
          : state.filterFolder;
        return {
          ...item,
          selected:
            filterFolder &&
            filterFolder !== "" &&
            item.title.startsWith(filterFolder),
        };
      });
    }
    const vlImageLabelsWithSelect = vlImageLabels.map(
      (item: FilterItemCounts) => {
        return {
          ...item,
          selected:
            state.labels.find(
              (label: FilterOption) => label.text === item.title
            )?.selected || false,
        };
      }
    );
    const vlObjectLabelsWithSelect = vlObjectLabels.map(
      (item: FilterItemCounts) => {
        return {
          ...item,
          selected:
            state.labels.find(
              (label: FilterOption) => label.text === item.title
            )?.selected || false,
        };
      }
    );
    const imageLabelsWithSelect = imageLabels.map((item: FilterItemCounts) => {
      return {
        ...item,
        selected:
          state.labels.find((label: FilterOption) => label.text === item.title)
            ?.selected || false,
      };
    });
    const objectLabelsWithSelect = objectLabels.map(
      (item: FilterItemCounts) => {
        return {
          ...item,
          selected:
            state.labels.find(
              (label: FilterOption) => label.text === item.title
            )?.selected || false,
        };
      }
    );
    const userTagsWithSelect = userTags.map((item: FilterItemCounts) => {
      return {
        ...item,
        selected:
          state.userTags.find((tag: FilterOption) => tag.text === item.title)
            ?.selected || false,
      };
    });

    const issueTypesWithSelect = issueData.map((item: FilterItemCounts) => {
      return {
        ...item,
        selected:
          state.issueTypes.find(
            (issue: FilterOption) => issue.text === item.title
          )?.selected || false,
      };
    });

    return {
      showVLEnrichment,
      videoData: videoDataWithSelect,
      vlLabels: [...vlImageLabelsWithSelect, ...vlObjectLabelsWithSelect],
      labels: [...imageLabelsWithSelect, ...objectLabelsWithSelect],
      userTags: userTagsWithSelect,
      issueTypes: issueTypesWithSelect,
    };
  }
);

export const getClusterMetadataSummaryLoading = createSelector(
  state,
  (state: any) => {
    return state.clusterMetadataSummaryLoading;
  }
);

const getRawDataMetadataSummary = createSelector(
  state,
  (state: any): RawMetadataSummaryData => {
    return state.dataMetadataSummary;
  }
);

export const getDataMetadataSummary = createSelector(
  state,
  getRawDataMetadataSummary,
  (state: any, rawMd: any): MetadataSummaryData => {
    if (!rawMd)
      return {
        showVLEnrichment: false,
        videoData: [],
        vlLabels: [],
        labels: [],
        userTags: [],
        issueTypes: [],
      };
    const {
      showVLEnrichment,
      videoData,
      imageLabels,
      objectLabels,
      userTags,
      issueData,
      vlImageLabels,
      vlObjectLabels,
    } = rawMd;
    const fileFolderEnabled = isFeatureEnabled(
      state.filterConfig,
      Features.FILE_FOLDER_FILTER
    );
    let videoDataWithSelect = videoData;
    if (fileFolderEnabled) {
      videoDataWithSelect = videoData.map((item: FilterItemCounts) => {
        const filterFolder = state.filterFolder?.startsWith("/")
          ? state.filterFolder.slice(1)
          : state.filterFolder;
        return {
          ...item,
          selected:
            filterFolder &&
            filterFolder !== "" &&
            item.title.startsWith(filterFolder),
        };
      });
    }
    const vlImageLabelsWithSelect = vlImageLabels.map(
      (item: FilterItemCounts) => {
        return {
          ...item,
          selected:
            state.labels.find(
              (label: FilterOption) => label.text === item.title
            )?.selected || false,
        };
      }
    );
    const vlObjectLabelsWithSelect = vlObjectLabels.map(
      (item: FilterItemCounts) => {
        return {
          ...item,
          selected:
            state.labels.find(
              (label: FilterOption) => label.text === item.title
            )?.selected || false,
        };
      }
    );
    const imageLabelsWithSelect = imageLabels.map((item: FilterItemCounts) => {
      return {
        ...item,
        selected:
          state.labels.find((label: FilterOption) => label.text === item.title)
            ?.selected || false,
      };
    });
    const objectLabelsWithSelect = objectLabels.map(
      (item: FilterItemCounts) => {
        return {
          ...item,
          selected:
            state.labels.find(
              (label: FilterOption) => label.text === item.title
            )?.selected || false,
        };
      }
    );
    const userTagsWithSelect = userTags.map((item: FilterItemCounts) => {
      return {
        ...item,
        selected:
          state.userTags.find((tag: FilterOption) => tag.text === item.title)
            ?.selected || false,
      };
    });

    const issueTypesWithSelect = issueData.map((item: FilterItemCounts) => {
      return {
        ...item,
        selected:
          state.issueTypes.find(
            (issue: FilterOption) => issue.text === item.title
          )?.selected || false,
      };
    });

    return {
      showVLEnrichment,
      vlLabels: [...vlImageLabelsWithSelect, ...vlObjectLabelsWithSelect],
      videoData: videoDataWithSelect,
      labels: [...imageLabelsWithSelect, ...objectLabelsWithSelect],
      userTags: userTagsWithSelect,
      issueTypes: issueTypesWithSelect,
    };
  }
);

export const getDataMetadataSummaryLoading = createSelector(
  state,
  (state: any) => {
    return state.dataMetadataSummaryLoading;
  }
);

export const getUserTags = createSelector(state, (state: any) => {
  let sortedUserTags = [...state.userTags];
  sortedUserTags = sortedUserTags.sort((a: FilterOption, b: FilterOption) => {
    if (a.text === "untagged") {
      return -1;
    } else if (b.text === "untagged") {
      return 1;
    } else {
      return a.text.localeCompare(b.text);
    }
  });

  return sortedUserTags;
});

export const getDatasetTags = createSelector(state, (state: any) => {
  return state.datasetTags;
});

export const getIsUserTagLoading = createSelector(state, (state: any) => {
  return state.isUserTagLoading;
});

export const getNavigationContext = createSelector(state, (state: any) => {
  return state.navigationContext;
});

export const getIsImageUploading = createSelector(state, (state: any) => {
  return state.isImageUploading;
});

export const getHoveredFileState = createSelector(state, (state: any) => {
  return state.hoveredFileState;
});

export const getFetchError = createSelector(state, (state: any) => {
  return state.fetchError;
});

export const getAutocompleteSuggestions = createSelector(
  state,
  (state: any) => {
    return state.autocompleteSuggestions;
  }
);

export const getRecentSearches = createSelector(state, (state: any) => {
  return state.recentSearches;
});

export const getAutocompleteLoading = createSelector(state, (state: any) => {
  return state.autocompleteLoading;
});

export const getAutocompleteNoResultsFlag = createSelector(
  state,
  (state: any) => {
    return state.autocompleteNoResultsFlag;
  }
);

export const getVertexPreviewsCountContext = createSelector(
  state,
  (state: any) => {
    return state.vertexPreviewsCountContext;
  }
);

const getSelectedItems = (filterArr: any[]) => {
  let result = [];

  if (Array.isArray(filterArr) && filterArr.length > 0) {
    result = filterArr
      .filter((filterItem: any) => filterItem.selected)
      .map((filterItem: any) => filterItem.text);

    return result;
  }

  return [];
};

export const getAllSelectedFilters = createSelector(
  getDatasetLabels,
  getDatasetIssueTypes,
  getUserTags,
  getSearchText,
  getEntityTypeFilter,
  getSelectedFolder,
  getUserConfig,
  (labels, issues, tags, searchText, entityType, filePath, filterConfig) => {
    const selectedLabels = getSelectedItems(labels);
    const selectedIssues = issues
      .filter((filterItem: any) => filterItem.selected)
      .map((filterItem: any) => filterItem.id);
    const selectedUserTags = getSelectedItems(tags);
    const allSelectedFilters: { [key: string]: any } = {};
    if (selectedLabels.length > 0) {
      allSelectedFilters.labels = selectedLabels;
    }
    if (selectedIssues.length > 0) {
      allSelectedFilters.issues = selectedIssues;
    }
    if (selectedUserTags.length > 0) {
      allSelectedFilters.tags = selectedUserTags;
    }
    const entityTypeValues = [...entityType];
    if (entityTypeValues.length > 0) {
      let valuesArray: any[] = [];
      let entityTypeBehavior = null;
      let noSelection = entityTypeValues.every(
        (value: any) => value.selected === false
      );

      let entityTypeFilter = filterConfig?.find(
        (filter: any) => filter.feature_key === Features.ENTITY_TYPE_FILTER
      );
      entityTypeBehavior = entityTypeFilter?.feature_behavior["ANY"];

      if (noSelection) {
        if (entityTypeBehavior === "TOGGLE") {
          valuesArray = [entityTypeValues[0].text.toUpperCase()];
        } else {
          valuesArray = [];
        }
      } else {
        let selectedEntities = entityTypeValues.filter(
          (value: any) => value.selected === true
        );
        valuesArray = selectedEntities.map((value: any) =>
          value.text.toUpperCase()
        );
      }
      let valueText = valuesArray.join(",");

      allSelectedFilters.entity_type = [valueText];
    }
    if (searchText?.trim() !== "") {
      allSelectedFilters.caption = searchText;
    }
    if (filePath?.length > 0) {
      allSelectedFilters.path = filePath;
    }
    return allSelectedFilters;
  }
);

export const getIsAssetPreviewEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.ASSET_PREVIEW);
  }
);

export const getIsExportEntitiesEnabled = createSelector(
  [getUserConfig, getDataset, getUserInfo],
  (userConfig: any, dataset: any, user: UserInfo) => {
    const exportEnabled = isFeatureEnabled(
      userConfig,
      Features.EXPORT_ENTITIES
    );
    if (!exportEnabled) {
      return false;
    } else {
      return !dataset?.sample || user.isInternalUser;
    }
  }
);

export const getImagePageParamsContext = createSelector(state, (state: any) => {
  return state.imagePageParamsContext;
});

export const getCurrentFilterQueries = createSelector(
  [
    state,
    (state: any, sendPageParameter: boolean | undefined = true) =>
      sendPageParameter,
    (
      state: any,
      sendPageParameter: boolean | undefined = true,
      sendVertexParams: boolean | undefined = true
    ) => sendVertexParams,
  ],
  (
    state: any,
    sendPageParameter: boolean | undefined,
    sendVertexParams: boolean | undefined
  ) => {
    let queries = `?threshold=${state.similarityThreshold}`;

    const labels = state.labels
      .filter((label: any) => label.selected)
      .map((label: any) => label.text);

    const issueTypes = state.issueTypes
      .filter((issueType: any) => issueType.selected)
      .map((issueType: any) => issueType.id);

    const searchText = state.searchText;
    const fileNameSearchText = state.fileNameSearchText;
    const entityTypeValues = [...state.entityTypeFilter];
    const vertexType = state.vertexType;

    const isCluster = /dataset.*cluster/.test(window.location.pathname);
    const pageNumber = isCluster
      ? state.clusterPageNumber
      : state.dataPageNumber;
    const vertexId = state.vertexID;

    if (labels.length > 0) {
      const encodedLabels = encodeURIComponent(JSON.stringify(labels));
      queries = `${queries}&labels=${encodedLabels}`;
    }
    if (issueTypes.length > 0) {
      queries = `${queries}&issues=${JSON.stringify(issueTypes)}`;
    }
    if (fileNameSearchText) {
      queries = `${queries}&image_filename=${fileNameSearchText}`;
    }
    if (searchText) {
      queries = `${queries}&image_caption=${searchText}`;
    }
    if (state.timeRange?.start) {
      queries = `${queries}&created_min_ms=${state.timeRange.start}`;
    }
    if (state.timeRange?.end) {
      queries = `${queries}&created_max_ms=${state.timeRange.end}`;
    }
    if (state.filterFolder !== null) {
      queries = `${queries}&path=${encodeURIComponent(state.filterFolder)}`;
    }
    if (vertexType && vertexType === "UPLOAD" && sendVertexParams) {
      queries = `${queries}&anchor_type=UPLOAD`;
    }
    if (entityTypeValues) {
      let valuesArray: any[] = [];
      let entityTypeBehavior = null;
      let noSelection = entityTypeValues.every(
        (value: any) => value.selected === false
      );

      let entityTypeFilter = state.filterConfig?.find(
        (filter: any) => filter.feature_key === Features.ENTITY_TYPE_FILTER
      );
      entityTypeBehavior = entityTypeFilter?.feature_behavior["ANY"];

      if (noSelection) {
        if (entityTypeBehavior === "TOGGLE") {
          valuesArray = [entityTypeValues[0].text.toUpperCase()];
        } else {
          valuesArray = entityTypeValues.map((value: any) =>
            value.text.toUpperCase()
          );
        }
      } else {
        let selectedEntities = entityTypeValues.filter(
          (value: any) => value.selected === true
        );
        valuesArray = selectedEntities.map((value: any) =>
          value.text.toUpperCase()
        );
      }
      let valueText = valuesArray.join(",");

      queries = `${queries}&entity_type=${valueText}`;
    }

    //Only for the case where page is data page and vertex is selected
    if (pageNumber && sendPageParameter) {
      queries = `${queries}&page_number=${pageNumber - 1}`;
    }

    const selectedTags = state.userTags
      .filter((tag: any) => tag.selected)
      .map((tag: any) => tag.id);
    if (selectedTags.length > 0) {
      const encodedTags = encodeURIComponent(JSON.stringify(selectedTags));
      queries = `${queries}&tags=${encodedTags}`;
    }
    if (vertexId && sendVertexParams) {
      queries = `${queries}&anchor_media_id=${vertexId}`;
    }

    return queries;
  }
);

export const getVertexQuery = createSelector(state, (state: any) => {
  let queries;
  if (state.vertexType === "CLUSTER" && !!state.vertexContext) {
    queries = state.vertexContext;
  } else if (state.vertexType === "UPLOAD") {
    const anchorParam = "anchor_type=UPLOAD";
    if (!queries) {
      queries = `?${anchorParam}`;
    } else {
      queries = `${queries}&${anchorParam}`;
    }
  } else {
    const renderParam = "render=MEDIA";
    if (!queries) {
      queries = `?${renderParam}`;
    } else {
      queries = `${queries}&${renderParam}`;
    }
  }
  return queries;
});

export const getExportRequestData = createSelector(
  getCartData,
  getAllSelectedFilters,
  (cartData: any, allSelectedFilters: any) => {
    const exportDataArray = cartData?.exportDataArray || [];

    let requestExportData = [];
    let mediaIDs: any[] = [];

    exportDataArray.forEach((singleItem: any) => {
      if (singleItem.exportType === "cluster") {
        let singleClusterData: any = {
          type: "cluster",
          payload: {
            cluster_id: singleItem.cluster_id,
            cluster_filters: singleItem.cluster_filters || allSelectedFilters,
          },
        };
        if (
          singleItem.excludeMediaIDs &&
          singleItem.excludeMediaIDs.length > 0
        ) {
          singleClusterData.payload.exclude_media_ids =
            singleItem.excludeMediaIDs;
        }
        requestExportData.push(singleClusterData);
      } else if (singleItem.exportType === "media") {
        mediaIDs.push(singleItem.media_id);
      }
    });

    if (mediaIDs.length > 0) {
      requestExportData.push({
        type: "media",
        payload: { media_ids: mediaIDs, cluster_filters: allSelectedFilters },
      });
    }

    return requestExportData;
  }
);

export const getAssignedTags = createSelector(state, (state: any) => {
  return state.assignedTags;
});

export const getIsDataIngestionTabEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.DATA_INGESTION_TAB_AVAILABLE);
  }
);

export const getIsClusterMetadataSummaryEnabled = createSelector(
  getUserConfig,
  (userConfig: any) => {
    return isFeatureEnabled(userConfig, Features.CLUSTER_METADATA_SUMMARY);
  }
);

export const getNavigationClusterID = createSelector(state, (state: any) => {
  return state.navigationCluster?.cluster_id || "";
});

export const getShowExportModal = createSelector(state, (state: any) => {
  return state.showExportModal;
});

export const getIsShowDebugInfoEnabled = createSelector(
  getUserConfig,
  (userConfig: any): boolean => {
    return isFeatureEnabled(userConfig, Features.EXPLORATION_DEBUG_INFO);
  }
);

export const getExportProgress = createSelector(state, (state): number => {
  return state.exportProgress;
});

export const getShowSharingMenu = createSelector(state, (state: any) => {
  return state.showSharingMenu;
});

export const getUsersWithAccess = createSelector(state, (state: any) => {
  return state.usersWithAccess;
});
