import { ChangeEvent, RefObject, useEffect, useState } from "react";
import { validateBucket } from "helpers/validations";
import { connect, useDispatch } from "react-redux";
import InputBanner from "../InputBanner";
import classNames from "classnames";
import { State } from "redux/store";

import {
  getLastUsedData,
  getNewDatasetId,
} from "redux/CreateDataset/selectors";
import {
  checkS3Bucket,
  setAnnotationStatus,
  setPreviewDataSource,
} from "redux/CreateDataset/actions";

import ArrowIcon from "assets/icons/ArrowIcon";
import XIcon from "assets/icons/XIcon";
import styles from "./style.module.scss";
import { DataSources, StepTileProgressStatus } from "types";
import { removeS3Prefix } from "helpers/utility/utilities";

const ErrorMessage = ({ s3InputError }: { s3InputError?: string }) => {
  const dynamicErrorText = s3InputError || "Error: Please insert valid URL";

  return (
    <span className={styles.errorMessage}>
      <XIcon color="#151928" />
      {dynamicErrorText}
    </span>
  );
};

const DefaultMessage = () => {
  return <span>Click on save after adding an s3 link.</span>;
};

const S3BannerMessage = ({ s3InputError }: S3BannerMessageProps) => {
  const renderMessage = () => {
    if (s3InputError) {
      return <ErrorMessage s3InputError={s3InputError} />;
    }
    return <DefaultMessage />;
  };

  return <div className={styles.lowerRow}>{renderMessage()}</div>;
};

const S3Banner = ({
  bannerRef,
  closeBanner,
  newDatasetId,
  lastUsedS3Url,
}: S3BannerProps) => {
  const [s3InputText, setS3InputText] = useState("");
  const [s3InputError, setS3InputError] = useState("");

  const dispatch = useDispatch();

  const isSubmitBtnEnabled = !!s3InputText && !s3InputError;

  const dynamicBorders = {
    [styles.errorBorder]: !!s3InputError,
  };

  const s3BannerClassNames = classNames(styles.s3Banner, { ...dynamicBorders });

  const s3InputContainerClassNames = classNames(styles.s3InputContainer, {
    ...dynamicBorders,
  });

  const clearInputButtonClassNames = classNames(styles.clearInputButton, {
    [styles.hiddenDiv]: !s3InputText,
  });

  const clearInputText = () => {
    setS3InputText("");
    setS3InputError("");
  };

  const handleInputValidation = (value: string) => {
    let result = validateBucket(value);

    if (result) {
      setS3InputError("");
    } else {
      setS3InputError("Error: Please insert valid URL");
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let newValue = removeS3Prefix(value);
    setS3InputText(newValue);
    handleInputValidation(newValue);
  };

  const handleSaveS3Bucket = async () => {
    if (newDatasetId) {
      dispatch(setAnnotationStatus(StepTileProgressStatus.DISABLED));
      dispatch(checkS3Bucket(newDatasetId, "s3://" + s3InputText) as any);
      dispatch(setPreviewDataSource(DataSources.S3));
      closeBanner();
    }
  };

  const renderBody = () => {
    return (
      <div className={styles.s3Container}>
        <div className={styles.s3Header}>
          <ArrowIcon color="#fff" />
          <span>Public S3 Bucket</span>
          <div className={styles.cancelBtn} onClick={closeBanner}>
            <XIcon color="#fff" />
          </div>
        </div>
        <div className={styles.s3Body}>
          <div className={styles.upperRow}>
            <span className={styles.s3Text}>s3://</span>
            <div className={s3InputContainerClassNames}>
              <input
                type="text"
                placeholder="e.g,: bucket-name/folderA/folderB"
                className={styles.s3Input}
                onChange={handleInputChange}
                value={s3InputText}
              />
              <div
                className={clearInputButtonClassNames}
                onClick={clearInputText}
              >
                <XIcon color="#151928" />
              </div>
            </div>
          </div>
          <S3BannerMessage s3InputError={s3InputError} />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (lastUsedS3Url !== s3InputText) {
      setS3InputText(removeS3Prefix(lastUsedS3Url));
    }
    //eslint-disable-next-line
  }, [lastUsedS3Url]);

  return (
    <div className={s3BannerClassNames}>
      <InputBanner
        body={renderBody()}
        bannerRef={bannerRef}
        isSaveBtnEnabled={isSubmitBtnEnabled}
        handleSaveButtonClick={handleSaveS3Bucket}
        closeBanner={closeBanner}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    newDatasetId: getNewDatasetId(state),
    lastUsedS3Url: getLastUsedData(state).s3Url,
  };
};

export default connect(mapStateToProps)(S3Banner);

interface S3BannerProps {
  bannerRef: RefObject<HTMLDivElement>;
  closeBanner: () => void;
  newDatasetId: string | null;
  lastUsedS3Url: string;
}

interface S3BannerMessageProps {
  s3InputError: string;
}
