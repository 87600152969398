import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import { BiChevronDown, BiChevronUp } from "react-icons/bi";

import {
  fetchDatasets,
  fetchSortedDatasets,
  fetchSampleData,
  setPublicParameter,
} from "redux/Datasets/actions";
import {
  getDatasets,
  getContentLoading,
  getSampleData,
  getDatasetsSortingValue,
  getDeleteModalId,
  getPublicParameter,
  getIsNewDatasetImportEnabled,
  getIsInventoryFilteredEnabled,
} from "redux/Datasets/selectors";
import { getUserConfig, getUserInfo } from "redux/User/selectors";
import { State } from "redux/store";
import { selectUser, isAnonymousUser, userLogout } from "redux/authReducer";
import {
  getShowRequestDemoModal,
  getShowRequestDemoSuccessModal,
} from "redux/Modals/selectors";

import {
  useWindowResize,
  calculatePlaceHolderCount,
  getQueryParameter,
} from "helpers/utility/utilities";
import { amplitudeTrack } from "helpers/utility/amplitude";
import {
  PRIVATE_DATASETS_PATH,
  PUBLIC_DATASETS_PATH,
} from "helpers/constants/paths";
import {
  AMP_INVENTORY_SORTED,
  AMP_DATASET_OPENED,
  AMP_USER_SIGN_IN,
} from "helpers/constants/amplitudeEvents";
import {
  AMP_DATASET_ID,
  AMP_DATASET_NAME,
  AMP_INVENTORY_SORT_VALUE,
  AMP_DATASET_SOURCE,
  AMP_USER_ORIGIN,
} from "helpers/constants/amplitudeProperties";
import {
  ContactUsComponentType,
  DatasetStatusEnum,
  DatasetTypeEnum,
  InventoryFilterData,
} from "types";
import { NEW_DATASET_CREATION__QUERY_PARAMETER_KEY } from "helpers/constants/miscellaneous";

import Header from "views/layout/Header";
import ContentLoad from "views/uikit/ContentLoad";

import RequestDemoSuccessModal from "views/modals/RequestDemoSuccessModal";
import InternalServerErrorModal from "views/modals/InternalServerErrorModal";
import { NoMatchFoundCard } from "views/components/NoMatchFoundCard";
import CreateDatasetModal from "views/modals/CreateDatasetModal";
import SignupSuccessModal from "views/modals/SignupSuccessModal";
import DatasetErrorModal from "views/modals/DatasetErrorModal";
import LimitExceedModal from "views/modals/LimitExceedModal";
import UpgradePlanModal from "views/modals/UpgradePlanModal";
import RequestDemoModal from "views/modals/RequestDemoModal";
import FreePlanModal from "views/modals/FreePlanModal";
import ProPlanModal from "views/modals/ProPlanModal";
import SignupModal from "views/modals/SignupModal";
import DatasetsFilters from "./DatasetsFilters";

import AddDatasetCard from "./AddDatasetCard";
import DatasetCard from "./DatasetCard";

import styles from "./style.module.scss";

const INITIAL_DATASET_STATUS = [
  { id: DatasetStatusEnum.READY, selected: false },
  { id: DatasetStatusEnum.INDEXING, selected: false },
  { id: DatasetStatusEnum.UPLOADING, selected: false },
  { id: DatasetStatusEnum.FATAL_ERROR, selected: false },
  { id: DatasetStatusEnum.INITIALIZING, selected: false },
];

const DatasetInventory = ({
  datasets,
  userConfig,
  deleteModalId,
  datasetsSortingValue,
  publicParameter,
  isContentLoading,
  showRequestDemoModal,
  showRequestDemoSuccessModal,
  isNewDatasetImportEnabled,
  isInventoryFilteredEnabled,
  userId,
}: DatasetInventoryTypes) => {
  const [filteredDatasets, setFilteredDatasets] = useState<any[]>(datasets);
  const [filterData, setFilterData] = useState<InventoryFilterData>({
    searchValue: null,
    datasetType: DatasetTypeEnum.All,
    datasetStatus: INITIAL_DATASET_STATUS,
    sortValue: "date",
  });
  const [selectedSortingValue, setSelectedSortingValue] =
    useState<any>(datasetsSortingValue);
  const [displaySortValue, setDisplaySortValue] = useState(
    datasetsSortingValue === "Name" ? "Name" : "Creation Date"
  );
  const [dropdownBoxDisplay, setdropdownBoxDisplay] = useState(false);
  const [count, setCount] = useState(0);
  const [showDatasetTypeDropdown, setShowDatasetTypeDropdown] = useState(false);
  const [cardOffsetWidth, setCardOffsetWidth] = useState(0);

  const cardContainer = useRef<HTMLDivElement>(null);
  const cardElement = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const filterOptionsRef = useRef<HTMLDivElement>(null);
  const optionsDropdownButtonRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathName = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const { height, width } = useWindowResize();

  const user = selectUser();

  //keeping the parameter "dc=1" to override gating
  const isNewDataIngestionEnabled =
    !!getQueryParameter(NEW_DATASET_CREATION__QUERY_PARAMETER_KEY) ||
    isNewDatasetImportEnabled;

  const isPrivatePath = pathName === PRIVATE_DATASETS_PATH || pathName === "/";
  const isPublicPath = pathName === PUBLIC_DATASETS_PATH;

  const dynamicTitle = isPrivatePath
    ? "Dataset Inventory"
    : "Public VL Datasets";

  const isAnyFilterSelected =
    filterData.searchValue ||
    filterData.sortValue !== "date" ||
    filterData.datasetType !== DatasetTypeEnum.All ||
    filterData.datasetStatus.filter((status) => status.selected).length > 0;

  const dynamicDatasets = isAnyFilterSelected ? filteredDatasets : datasets;
  const length = dynamicDatasets.length;

  const dynamicDatasetCount = `${length} Dataset${length > 1 ? "s" : ""}`;

  const datasetsCountText = `Displaying ${
    datasets.length === filteredDatasets.length ? "All" : "Filtered"
  } Datasets (${filteredDatasets.length}/${datasets.length})`;

  const handleSampleDatasets = () => {
    dispatch(fetchSampleData() as any);
  };

  const handleSortSelection = (value: "Name" | "Creation Date") => {
    if (value === "Name") {
      setSelectedSortingValue("Name");
      setDisplaySortValue("Name");
    } else if (value === "Creation Date") {
      setSelectedSortingValue("Creation Date");
      setDisplaySortValue("Creation Date");
    }
    amplitudeTrack(AMP_INVENTORY_SORTED, {
      [AMP_INVENTORY_SORT_VALUE]: value,
    });
  };

  const handleDropdownDisplay = (event: any) => {
    event.stopPropagation();
    setdropdownBoxDisplay(!dropdownBoxDisplay);
  };

  const handleOutsideClick = (event: any) => {
    const optionDropdownButton = optionsDropdownButtonRef.current;
    const options = filterOptionsRef.current;

    const clickedInOptions = options && !options.contains(event.target);
    const clickedOnOptionDropdown =
      optionDropdownButton && !optionDropdownButton.contains(event.target);
    if (
      showDatasetTypeDropdown &&
      clickedInOptions &&
      clickedOnOptionDropdown
    ) {
      setShowDatasetTypeDropdown(false);
    }

    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setdropdownBoxDisplay(false);
    }
  };

  const renderInventoryInfo = () => {
    if (isInventoryFilteredEnabled) {
      return (
        <DatasetsFilters
          filterData={filterData}
          isPrivatePath={isPrivatePath}
          setFilterData={setFilterData}
          datasetsCountText={datasetsCountText}
        />
      );
    }

    return (
      <div className={styles.oldFilterContainer}>
        <div className={styles.datasetCountText}>{dynamicDatasetCount}</div>
        <div className={styles.datasetContainerRight}>
          <div className={styles.sortedByContainer}>
            <span className={styles.sortedByText}>Sorted by</span>
            <div
              ref={dropdownRef}
              className={styles.dropdownWrapper}
              onClick={handleDropdownDisplay}
            >
              <strong className={styles.sortedByValue}>
                {displaySortValue}
              </strong>
              <div className={styles.dropdownContainer}>
                <div className={styles.dropdownButton}>
                  {dropdownBoxDisplay ? (
                    <BiChevronUp size="1.5em" />
                  ) : (
                    <BiChevronDown size="1.5em" />
                  )}
                </div>
                <ul
                  className={styles.dropdownBox}
                  style={dropdownBoxDisplay ? undefined : { display: "none" }}
                >
                  <li
                    className={styles.dropdownBoxElement1}
                    onClick={() => handleSortSelection("Creation Date")}
                  >
                    Creation Date
                  </li>
                  <li
                    className={styles.dropdownBoxElement2}
                    onClick={() => handleSortSelection("Name")}
                  >
                    Name
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleClearFilters = () => {
    const unselectedDatasetStatus = filterData.datasetStatus.map((status) => {
      return { ...status, selected: false };
    });

    setFilterData({
      datasetStatus: unselectedDatasetStatus,
      datasetType: DatasetTypeEnum.All,
      sortValue: "date",
      searchValue: "",
    });
    setFilteredDatasets([]);
  };

  const setCardWitdh = (value: number) => {
    setCardOffsetWidth(value);
  };

  useEffect(() => {
    if (!isInventoryFilteredEnabled) {
      return;
    }
    let result: any = datasets;
    const { datasetStatus, datasetType, searchValue, sortValue } = filterData;

    const selectedStatusValues = datasetStatus
      .filter((status) => status.selected)
      .map((status) => status.id);

    if (selectedStatusValues.length > 0) {
      result = result.filter((dataset: any) =>
        selectedStatusValues.includes(dataset.status)
      );
    }

    if (searchValue) {
      result = result.filter((dataset: any) => {
        const datasetName: string = dataset.display_name.toLowerCase();
        const searchText = searchValue.toLowerCase();

        return datasetName.includes(searchText) || false;
      });
    }

    if (datasetType === DatasetTypeEnum.Private) {
      result = result.filter((dataset: any) => !dataset.sample);
    } else if (datasetType === DatasetTypeEnum.Public) {
      result = result.filter((dataset: any) => dataset.sample);
    }

    if (sortValue === "size") {
      result = result.toSorted(
        (datasetA: any, datasetB: any) =>
          datasetB.size_bytes - datasetA.size_bytes
      );
    } else if (sortValue === "name") {
      result = result.toSorted((datasetA: any, datasetB: any) => {
        return datasetA.display_name.localeCompare(datasetB.display_name);
      });
    } else if (sortValue === "date") {
      result = result.toSorted(
        (datasetA: any, datasetB: any) =>
          new Date(datasetB.created_at).getTime() -
          new Date(datasetA.created_at).getTime()
      );
    }

    setFilteredDatasets(result);
  }, [filterData, datasets, isInventoryFilteredEnabled]);

  useEffect(() => {
    const extraWidth = 60;
    const recordsCount = isPrivatePath ? length + 1 : length;
    const card = cardElement.current
      ? cardElement.current
      : length > 0
      ? { offsetWidth: cardOffsetWidth }
      : null;

    let tempCount = calculatePlaceHolderCount(
      cardContainer.current,
      card, //in case the ref is null, try using cardOffsetWidth
      recordsCount,
      extraWidth
    );
    if (count !== tempCount) {
      setCount(tempCount);
    }
    //eslint-disable-next-line
  }, [height, width, count, length, datasets, filterData, cardOffsetWidth]);

  useEffect(() => {
    let fetchDatasetsTask: any;
    const isUserConfigReady = () => userConfig && userConfig.length > 0;

    if (
      isPrivatePath &&
      process.env.REACT_APP_IS_AUTHENTICATION_DISABELED === "false" &&
      ((user && isAnonymousUser()) || !user)
    ) {
      userLogout();
    }

    // Check if userConfig is initially filled, if not, check again
    if (!isUserConfigReady()) {
      const checkUserConfigInterval = setInterval(() => {
        if (isUserConfigReady()) {
          // Once userConfig is filled, clear the interval and start fetching datasets
          clearInterval(checkUserConfigInterval);
          startFetchingDatasets();
        }
      }, 300);
    } else {
      // If userConfig is already filled, start fetching datasets immediately
      startFetchingDatasets();
    }

    function startFetchingDatasets() {
      fetchDatasetsTask = setInterval(() => {
        dispatch(
          fetchSortedDatasets(
            selectedSortingValue?.toLowerCase(),
            false,
            isPrivatePath
          ) as any
        );
      }, 30000);
      dispatch(
        fetchSortedDatasets(
          selectedSortingValue?.toLowerCase(),
          true,
          isPrivatePath
        ) as any
      );

      if (isPublicPath) {
        dispatch(setPublicParameter("?p=1"));
      } else {
        dispatch(setPublicParameter(""));
      }

      if (searchParams.get("lin") === "1") {
        let user = selectUser();
        amplitudeTrack(AMP_USER_SIGN_IN, {
          [AMP_USER_ORIGIN]: user?.origin,
        });
      }
    }

    return () => clearInterval(fetchDatasetsTask);
    //eslint-disable-next-line
  }, [dispatch, selectedSortingValue, isPrivatePath, userConfig]);

  const LoadDataset = (
    datasetId: string,
    datasetName: string,
    datasetSource: string,
    similarity_data: boolean
  ) => {
    amplitudeTrack(AMP_DATASET_OPENED, {
      [AMP_DATASET_NAME]: datasetName,
      [AMP_DATASET_ID]: datasetId,
      [AMP_DATASET_SOURCE]: datasetSource,
    });

    navigate(`/dataset/${datasetId}/data${publicParameter}`);
  };

  const createFirstDataset = (isReload: boolean = false) => {
    if (isReload) {
      dispatch(fetchDatasets() as any);
    }
  };

  useEffect(() => {
    if (dropdownBoxDisplay || showDatasetTypeDropdown) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [dropdownBoxDisplay, showDatasetTypeDropdown]);

  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
      </Helmet>
      <Header
        handleSampleDatasets={handleSampleDatasets}
        isPrivatePath={isPrivatePath}
      />
      <div className={styles.datasetContainer}>
        <div className={styles.datasetCountDark}>{renderInventoryInfo()}</div>
        <div
          ref={cardContainer}
          className={
            isContentLoading || length < 1
              ? styles.cardSectionWithoutScroll
              : styles.CardSection
          }
        >
          {isPrivatePath ? (
            <AddDatasetCard
              handleSampleDatasets={handleSampleDatasets}
              isContentLoading={isContentLoading}
              user={user}
              isNewDatasetImportEnabled={isNewDataIngestionEnabled}
            />
          ) : null}

          {length < 1 && isAnyFilterSelected && !isContentLoading && (
            <NoMatchFoundCard
              handleClearFilters={handleClearFilters}
              isInventory={true}
            />
          )}
          {dynamicDatasets.map((dataset: any, i: number) => {
            return (
              <DatasetCard
                key={i}
                {...{
                  dataset,
                  cardElement,
                  LoadDataset,
                  deleteModalId,
                  isPrivatePath,
                  isNewDataIngestionEnabled,
                  userId,
                  setCardWitdh,
                }}
              />
            );
          })}

          {count > 0 &&
            [...Array(count)].map((_, index) => (
              <div key={index} className={styles.cardPlaceHolder}>
                <div className={styles.cardPlaceHolderData}>
                  {isContentLoading && <ContentLoad radius="33px" />}
                </div>
              </div>
            ))}
        </div>

        <CreateDatasetModal createFirstDataset={createFirstDataset} />
        <LimitExceedModal />
        <UpgradePlanModal />
        <ProPlanModal />
        <FreePlanModal />
        <SignupModal />
        <SignupSuccessModal />
        {showRequestDemoModal?.flag && (
          <RequestDemoModal calledFrom={showRequestDemoModal.calledFrom} />
        )}
        {showRequestDemoSuccessModal && <RequestDemoSuccessModal />}
        <DatasetErrorModal />
        <InternalServerErrorModal />
      </div>
    </>
  );
};

function mapStatesToProps(state: State) {
  return {
    datasets: getDatasets(state),
    userConfig: getUserConfig(state),
    sampleData: getSampleData(state),
    deleteModalId: getDeleteModalId(state),
    isContentLoading: getContentLoading(state),
    datasetsSortingValue: getDatasetsSortingValue(state),
    publicParameter: getPublicParameter(state),
    showRequestDemoModal: getShowRequestDemoModal(state),
    showRequestDemoSuccessModal: getShowRequestDemoSuccessModal(state),
    isNewDatasetImportEnabled: getIsNewDatasetImportEnabled(state),
    isInventoryFilteredEnabled: getIsInventoryFilteredEnabled(state),
    userId: getUserInfo(state).userId,
  };
}

export default connect(mapStatesToProps)(DatasetInventory);

type DatasetInventoryTypes = {
  datasets: [{}];
  deleteModalId: string;
  publicParameter: string;
  isContentLoading: boolean;
  datasetsSortingValue: string;
  showRequestDemoModal: { flag: boolean; calledFrom: ContactUsComponentType };
  showRequestDemoSuccessModal: boolean;
  isNewDatasetImportEnabled: boolean;
  isInventoryFilteredEnabled: boolean;
  userConfig: any[];
  userId: string;
};
