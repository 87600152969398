//always refer settings on amplitude.com
export const AMP_USER_ORIGIN = "User Origin";
export const AMP_USER_ID = "User ID";

export const AMP_DATASET_SOURCE = "Dataset Source";
export const AMP_IMAGE_SOURCE = "Image Source";

export const AMP_DATASET_NAME = "Dataset Name";
export const AMP_CLUSTER_NAME = "Cluster Name";
export const AMP_IMAGE_NAME = "Image Name";

export const AMP_DATASET_ID = "Dataset ID";
export const AMP_CLUSTER_ID = "Cluster ID";
export const AMP_IMAGE_ID = "Image ID";

export const AMP_INVENTORY_SORT_VALUE = "Inventory Sort Value";
export const AMP_CLUSTERS_SORT_VALUE = "Clusters Sort Value";
export const AMP_DATASET_SORT_VALUE = "Dataset Sort Value";

export const AMP_SEVERITY_TYPE = "Severity Type";
export const AMP_FILTER_TYPE = "Filter Type";
export const AMP_ISSUE_TYPE = "Issue Type";

export const SIGNUP_ORIGIN = "Signup Origin";
export const CREATE_NEW_DATASET = "Create New Dataset";

export const AMP_EXPORT_INCLUDES_RAW_DATA = "Export Raw Data Included";
export const AMP_EXPORT_FILE_FORMAT = "Export File Format";
export const AMP_EXPORT_FILE_CONTEXT = "Export Context";

export const AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE = "Cluster Size";
export const AMP_DATASET_EXPLORATION_PROPERTY__ENTITY_TYPE = "Entity Type";
export const AMP_DATASET_EXPLORATION_PROPERTY__PAGE_NUMBER = "Page Number";
export const AMP_DATASET_EXPLORATION_PROPERTY__FILTER_TYPE = "Filter Type";
export const AMP_IMAGE_COUNT = "Image Count";
export const AMP_DATASET_EXPLORATION_PROPERTY__TAB_NAME = "Tab Name";
export const AMP_DATASET_EXPLORATION_PROPERTY__GRANULARITY_LEVEL =
  "Granularity Level";
export const AMP_DATASET_EXPLORATION_PROPERTY__CONTEXT = "Context";
export const AMP_DATASET_EXPLORATION_PROPERTY__TARGET = "Target"; //Can be Cluster Image OR Object

export const AMP_DATASET_CREATION_PROPERTY__CREATED_AT = "Created At";
export const AMP_DATASET_CREATION_PROPERTY__CREATED_BY = "Created By";
export const AMP_DATASET_CREATION_PROPERTY__DATASET_STATUS = "Dataset Status";
export const AMP_DATASET_CREATION_PROPERTY__ERROR_MESSAGE = "Error Message";
export const AMP_DATASET_CREATION_PROPERTY__ATTEMPTED_UPLOAD_COUNT =
  "Attempted Upload Count";
export const AMP_DATASET_CREATION_PROPERTY__ALLOWED_UPLOAD_COUNT =
  "Allowed Upload Count";
export const AMP_DATASET_CREATION_PROPERTY__UPLOAD_SOURCE = "Upload Source"; //defined in DataSources type
