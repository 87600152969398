import { useState, useEffect, useRef, useMemo, RefObject } from "react";
import { useDispatch } from "react-redux";
import { FilterOption } from "types";

import {
  addNewTagFromCart,
  setAssignedTags,
  updateExportTags,
} from "redux/SingleDataset/actions";

import crossImg from "assets/img/blue-cross-icon.svg";
import { BiSearch } from "react-icons/bi";
import { FaPlus, FaCheckCircle, FaRegCircle } from "react-icons/fa";
import styles from "./style.module.scss";
import XIcon from "assets/icons/XIcon";

const SingleTagTag = ({ tag, handleRemoval }: SingleTagTagProps) => {
  const onRemoveButtonClick = (
    e: React.MouseEvent<HTMLDivElement>,
    tag: FilterOption
  ) => {
    e.stopPropagation();
    handleRemoval(tag);
  };
  return (
    <div className={styles.tagTagContainer}>
      <span className={styles.tagTagText}>{tag.text}</span>
      <div
        className={styles.tagRemoveButton}
        onClick={(e: React.MouseEvent<HTMLDivElement>) =>
          onRemoveButtonClick(e, tag)
        }
      >
        <XIcon />
      </div>
    </div>
  );
};

const SingleTagRow = ({ tag, onTagAddition, onTagRemoval }: SingleTagProps) => {
  const handleSelection = (
    e: React.MouseEvent<HTMLDivElement>,
    tag: FilterOption
  ) => {
    e.stopPropagation();
    if (tag.selected) {
      onTagRemoval(tag);
    } else {
      onTagAddition(tag);
    }
  };
  return (
    <div
      className={styles.tagContainer}
      onClick={(e: any) => handleSelection(e, tag)}
    >
      {tag.selected ? (
        <FaCheckCircle color="#0197D8" />
      ) : (
        <FaRegCircle color="#eeeeee" />
      )}
      <label className={styles.text} htmlFor={tag.text}>
        {tag.text}
      </label>
    </div>
  );
};

const TagsDropDown = ({
  assignedTags,
  allTags,
  toggleShowTags,
  btnRef,
  onTagSubmission,
  onTagRemoval,
  datasetID,
}: TagsDropDownProps) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchedResults, setSearchedResults] = useState<any[]>([]);
  const [newTag, setNewTag] = useState("");

  const tagsDropdownRef = useRef<HTMLDivElement>(null);
  const trimmedSearchText = searchText.replace(/^\s+|\s+$/g, "");
  const nonAssignedTags = useMemo(() => {
    return allTags.filter(
      (tag) => !assignedTags.some((assignedTag) => assignedTag.id === tag.id)
    );
  }, [allTags, assignedTags]);

  const showNewTag =
    trimmedSearchText.length > 0 &&
    !allTags.find(
      (tag: any) => tag.text.toLowerCase() === trimmedSearchText.toLowerCase()
    );

  const handleOutsideClick = (event: any) => {
    event.stopPropagation();
    const hasDropdownRef =
      tagsDropdownRef.current &&
      !tagsDropdownRef.current.contains(event.target);
    const hasBtnRef = btnRef.current && btnRef.current.contains(event.target);
    if (hasDropdownRef && !hasBtnRef) toggleShowTags(event);
  };

  const handleTagAddition = async (selectedTag: FilterOption) => {
    const updatedTags = [...assignedTags, { ...selectedTag, selected: true }];
    dispatch(setAssignedTags(updatedTags));
    await dispatch(updateExportTags(datasetID, selectedTag) as any);
    onTagSubmission(selectedTag.id);
  };

  const handleCloseSearch = () => {
    setSearchText("");
    setSearchedResults(nonAssignedTags);
  };

  const handleNewTagClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleCloseSearch();
    const tagID = await dispatch(addNewTagFromCart(datasetID, newTag) as any);
    if (tagID) {
      await handleTagAddition({ id: tagID, text: newTag });
    }
  };

  const handleTagRemoval = async (selectedTag: FilterOption) => {
    const updatedTags = assignedTags.map((tag) =>
      tag.id === selectedTag.id ? { ...tag, selected: false } : tag
    );
    dispatch(setAssignedTags(updatedTags));
    onTagRemoval(selectedTag.id);
  };

  const renderInputSection = () => {
    return (
      <div
        className={styles.searchBarContainer}
        onClick={(e: any) => e.stopPropagation()}
      >
        <div className={styles.searchBarButton}>
          <BiSearch />
        </div>
        <input
          type="search"
          onChange={(e: any) => setSearchText(e.target.value)}
          value={searchText}
          className={styles.searchBarInput}
          placeholder="Search or create a new tag."
        />
        {searchText && (
          <img
            className={styles.crossImg}
            src={crossImg}
            alt="cross-icon"
            onClick={handleCloseSearch}
          />
        )}
      </div>
    );
  };

  const renderAssignedTags = () => {
    if (assignedTags.length > 0) {
      return [
        <div className={styles.assignedTagsContainer}>
          {assignedTags.map((tag: FilterOption, index: number) => (
            <SingleTagTag
              key={index}
              tag={tag}
              handleRemoval={handleTagRemoval}
            />
          ))}
        </div>,
        <div className={styles.divider}></div>,
      ];
    }
  };

  const renderTagsSelection = () => {
    if (searchedResults.length > 0) {
      return (
        <div className={styles.availableTags}>
          {searchedResults.map((tag: FilterOption, index: number) => (
            <SingleTagRow
              key={index}
              tag={tag}
              onTagRemoval={handleTagRemoval}
              onTagAddition={handleTagAddition}
            />
          ))}
        </div>
      );
    }
  };

  const showNewTagOption = () => {
    if (showNewTag) {
      return (
        <div className={styles.newOptionText} onClick={handleNewTagClick}>
          <FaPlus />
          Create a new tag <strong>{`"${newTag}"`}</strong>
        </div>
      );
    } else return null;
  };

  useEffect(() => {
    if (trimmedSearchText.length > 0) {
      setSearchedResults([
        ...nonAssignedTags.filter((tag: any) =>
          tag.text.toLowerCase().includes(trimmedSearchText.toLowerCase())
        ),
        ...assignedTags.filter((tag: any) =>
          tag.text.toLowerCase().includes(trimmedSearchText.toLowerCase())
        ),
      ]);
    } else {
      setSearchedResults(nonAssignedTags);
    }

    if (showNewTag) setNewTag(trimmedSearchText);

    //eslint-disable-next-line
  }, [searchText, nonAssignedTags]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.tagsDropDownWrapper} ref={tagsDropdownRef}>
      <div className={styles.dropdown}>
        {renderAssignedTags()}
        {renderInputSection()}
        <div className={styles.tagsContainer}>
          {renderTagsSelection()}
          {showNewTagOption()}
        </div>
      </div>
    </div>
  );
};
export default TagsDropDown;

interface SingleTagProps {
  tag: FilterOption;
  onTagAddition: (selectedTag: FilterOption) => void;
  onTagRemoval: (selectedTag: FilterOption) => void;
}

interface SingleTagTagProps {
  tag: FilterOption;
  handleRemoval: (tag: FilterOption) => void;
}

interface TagsDropDownProps {
  assignedTags: FilterOption[];
  allTags: FilterOption[];
  datasetID: string;
  onTagRemoval: (tagID: number) => void;
  onTagSubmission: (tagID: number) => void;
  toggleShowTags: (e: React.MouseEvent<HTMLDivElement>) => void;
  btnRef: RefObject<HTMLDivElement>;
}
