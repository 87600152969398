import { useState } from "react";
import { useDispatch } from "react-redux";
import { setShowDemoRequestModal } from "redux/Modals/actions";
import { ContactUsComponentType, SetStateFunction } from "types";

import { LIGHT_GREEN } from "helpers/constants/colors";
import { GoCheck as GreenTick } from "react-icons/go";
import { validateDatasetName } from "helpers/validations";

import SourceItem from "views/components/SourceItem";
import ArrowCircleIcon from "assets/icons/ArrowCircleIcon";

import styles from "./style.module.scss";

export function DatasetName({ datasetName, handleChange }: any) {
  const [errorFlag, setErrorFlag] = useState("");
  const handleInputValidation = (value: any) => {
    let result = validateDatasetName(value);
    result ? setErrorFlag(result) : setErrorFlag("");
  };
  return (
    <div>
      <div className={styles.basicDetailsTop}>
        <span>Dataset title</span>
      </div>
      <div className={styles.addInputDataset}>
        <input
          type="text"
          placeholder="Add dataset title e.g my first dataset"
          className={errorFlag ? styles.errorFlagInput : styles.addInput}
          onChange={(e) => {
            handleChange(e.target.value);
            handleInputValidation(e.target.value);
          }}
          value={datasetName}
        />
        <div
          className={
            datasetName === "" || errorFlag
              ? styles.disabledGreenTick
              : undefined
          }
        >
          <GreenTick
            size="1em"
            color={LIGHT_GREEN}
            className={styles.greenTick}
          />
        </div>
        <span className={styles.errorFlag}>{errorFlag ? errorFlag : ""}</span>
      </div>
    </div>
  );
}

function SelectSourceType({
  show,
  handleChange,
  datasetName,
  selectedSource,
  onSelectSource,
  setSelectedSourceName,
  closeModal,
}: SelectSourceTypeProps) {
  const dispatch = useDispatch();

  const handleAdditionalOptionClick = (
    selectedOption: ContactUsComponentType
  ): void => {
    closeModal();
    dispatch(
      setShowDemoRequestModal({ flag: true, calledFrom: selectedOption })
    );
  };

  return (
    <div className={show ? styles.selectSourceType : styles.hideSourceType}>
      <DatasetName datasetName={datasetName} handleChange={handleChange} />
      <div
        className={
          datasetName === "" ? styles.disabledSourceContainer : undefined
        }
      >
        <div className={styles.basicDetailsBelow}>
          <span>Select Source</span>
        </div>
        <div className={styles.sourceInnerContainer}>
          <SourceItem
            name="AWS S3 Bucket"
            description="Use S3 bucket"
            selected={selectedSource === 2}
            onClick={() => {
              onSelectSource(2);
              setSelectedSourceName("S3 Upload");
            }}
          />
          <SourceItem
            name="Upload a file"
            description="Upload a local CSV or Zip file"
            selected={selectedSource === 1}
            onClick={() => {
              onSelectSource(1);
              setSelectedSourceName("Local Upload");
            }}
          />
          <SourceItem
            name="Sample Data"
            description="Use images from your S3 data storage"
            selected={selectedSource === 3}
            onClick={() => {
              onSelectSource(3);
              setSelectedSourceName("Sample Data");
            }}
          />
        </div>
        <div className={styles.additionalSourcesContainer}>
          <div className={styles.title}>Additional Sources</div>
          <div className={styles.additionalOptionsContainer}>
            <div
              className={styles.additionalOption}
              onClick={() =>
                handleAdditionalOptionClick(
                  "create_newdataset_private_s3_bucket"
                )
              }
            >
              <div className={styles.optionIcon}>
                <ArrowCircleIcon color="#FEAA01" />
              </div>
              <div className={styles.optionTitle}>Private S3 Bucket</div>
              <div className={styles.comingSoonText}>Contact Us</div>
            </div>
            <div
              className={styles.additionalOption}
              onClick={() =>
                handleAdditionalOptionClick("create_newdataset_google_drive")
              }
            >
              <div className={styles.optionIcon}>
                <ArrowCircleIcon color="#FEAA01" />
              </div>
              <div className={styles.optionTitle}>Google Drive, Dropbox</div>
              <div className={styles.comingSoonText}>Contact Us</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SelectSourceType;

interface SelectSourceTypeProps {
  show: boolean;
  datasetName: string;
  selectedSource: number;
  handleChange: SetStateFunction<string>;
  onSelectSource: SetStateFunction<number>;
  setSelectedSourceName: SetStateFunction<string>;
  closeModal: () => void;
}
