import { DataSources, QuotaLimitModalData, UserQuota } from "types";
import { connect, useDispatch } from "react-redux";
import { State } from "redux/store";

import { formatNumberWithCommas } from "helpers/utility/formatters";
import {
  getNewDatasetId,
  getPreviewDataSource,
} from "redux/CreateDataset/selectors";
import { getUserQuota } from "redux/User/selectors";
import XIcon from "assets/icons/XIcon";
import classNames from "classnames";

import {
  setQuotaLimitModalData,
  setSelectPlanModalData,
} from "redux/Modals/actions";
import { getQuotaLimitModalData } from "redux/Modals/selectors";

import styles from "./style.module.scss";
import { deleteDataset } from "redux/Datasets/actions";
import { useNavigate } from "react-router";
import { PRIVATE_DATASETS_PATH } from "helpers/constants/paths";
import { useEffect } from "react";

interface QuotaLimitModalProps {
  quotaLimitModalData: QuotaLimitModalData;
  previewDataSource: DataSources | null;
  userQuota: UserQuota | null;
  newDatasetId: string;
}

const QuotaLimitModal = ({
  quotaLimitModalData,
  previewDataSource,
  userQuota,
  newDatasetId,
}: QuotaLimitModalProps) => {
  const { show, attemptedImageCount } = quotaLimitModalData;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(setQuotaLimitModalData({ show: false, attemptedImageCount }));
    };
    //eslint-disable-next-line
  }, []);

  if (!show || !userQuota) {
    return <noscript />;
  }

  const { availableQuota, totalQuota, accountType, usedQuota } = userQuota;

  const continueButtonClassnames = classNames(styles.continueButton, {
    [styles.disableButton]: availableQuota < 1,
  });

  function handleCrossClick() {
    closeModal();
    dispatch(deleteDataset(newDatasetId) as any);
    navigate(PRIVATE_DATASETS_PATH);
  }

  function closeModal() {
    dispatch(setQuotaLimitModalData({ ...quotaLimitModalData, show: false }));
  }

  function handleContactusClick() {
    dispatch(setSelectPlanModalData({ show: true, calledFrom: "limit_modal" }));
    closeModal();
  }

  function handleContinueClick() {
    if (availableQuota) {
      dispatch(
        setQuotaLimitModalData({
          show: false,
          attemptedImageCount,
          allowUpload: true,
        })
      );
    }
  }

  const formattedAvailableQuota = formatNumberWithCommas(availableQuota);
  const formattedTotalQuota = formatNumberWithCommas(totalQuota);
  const formattedImageCount = formatNumberWithCommas(attemptedImageCount!);

  const formattedUsedQuota = formatNumberWithCommas(usedQuota);

  const progressPercentage = `calc(${(usedQuota / totalQuota) * 100}%)`;

  const dynamicUploadText =
    attemptedImageCount && previewDataSource !== DataSources.S3
      ? `Upload up to ${formattedAvailableQuota} images out of ${formattedImageCount}`
      : `Upload up to ${formattedAvailableQuota} images`;

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <div className={styles.headerTitle}>Upgrade Plan</div>
          <div className={styles.crossButton} onClick={handleCrossClick}>
            <XIcon color="#fff" />
          </div>
        </div>

        <div className={styles.description}>
          Oops! It looks like you've exceeded the image limit of your current{" "}
          {accountType.toLowerCase()} plan. Upgrade now to unlock more images
          and continue uploading hassle-free.
        </div>

        <div className={styles.divider}></div>

        <div className={styles.planContainer}>
          <span>Image Usage</span>
          <div className={styles.planText}>{`${accountType} PLAN`}</div>
        </div>

        <div className={styles.usageContainer}>
          <span className={styles.usedCount}>{`${formattedUsedQuota}/`}</span>
          <span className={styles.totalCount}>{formattedTotalQuota}</span>
        </div>

        <div className={styles.progressBar}>
          <div
            className={styles.bar}
            style={{ width: progressPercentage }}
          ></div>
        </div>

        <div className={styles.divider}></div>

        <div className={styles.contactButton} onClick={handleContactusClick}>
          Contact Sales
        </div>

        <div className={continueButtonClassnames} onClick={handleContinueClick}>
          <span>Continue Uploading</span>
          <span>{dynamicUploadText}</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    quotaLimitModalData: getQuotaLimitModalData(state),
    previewDataSource: getPreviewDataSource(state),
    userQuota: getUserQuota(state),
    newDatasetId: getNewDatasetId(state),
  };
};

export default connect(mapStateToProps)(QuotaLimitModal);
