function FindSimilarIcon({ color = "#fff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M17.2598 6.245V4.732C17.2598 2.671 15.5888 1 13.5268 1H12.3218"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.999989 11.6495L0.999988 13.1625C0.999988 15.2235 2.67099 16.8945 4.73299 16.8945L5.93799 16.8945"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.245V4.732C1 2.671 2.671 1 4.733 1H5.969"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2598 11.6495L17.2598 13.1625C17.2598 15.2235 15.5888 16.8945 13.5268 16.8945L12.2908 16.8945"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="9"
        cy="9"
        r="3"
        stroke={color}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L12.1464 12.8536ZM11.8536 11.1464L11.5 10.7929L10.7929 11.5L11.1464 11.8536L11.8536 11.1464ZM12.8536 12.1464L11.8536 11.1464L11.1464 11.8536L12.1464 12.8536L12.8536 12.1464Z"
        fill={color}
      />
    </svg>
  );
}

export default FindSimilarIcon;
