import classNames from "classnames";
import { BiCheck } from "react-icons/bi";
import styles from "./style.module.scss";
import { BRIGHT_BLUE } from "helpers/constants/colors";

const OptionContainer = ({
  value,
  selectedOptions,
  handleFilterSelection,
  text,
  type,
}: any) => {
  const isSelected = selectedOptions.includes(value);
  const optionContainerClassnames = classNames(styles.optionContainer, {
    [styles.selectedFilter]: isSelected,
  });

  return (
    <div
      className={optionContainerClassnames}
      onClick={(e: any) => {
        e.stopPropagation();
        handleFilterSelection(value, type);
      }}
    >
      <div
        className={styles.checkbox}
        style={isSelected ? { backgroundColor: BRIGHT_BLUE } : undefined}
      >
        {isSelected && <BiCheck color="#fff" size="12px" />}
      </div>
      <div
        className={styles.text}
        style={isSelected ? { fontWeight: 500 } : undefined}
      >
        {text}
      </div>
    </div>
  );
};

interface DropDownProps {
  options: { id: number; text: string }[];
  type: any;
  handleFilterSelection: (value: number, type: any) => void;
  selectedFilters: { [key: string]: number[] };
  dynamicDropDownStyle?: any;
}

const DropDown: React.FC<DropDownProps> = ({
  options,
  type,
  handleFilterSelection,
  selectedFilters,
  dynamicDropDownStyle,
}) => {
  const selectedOptions = selectedFilters[type] || [];

  return (
    <div
      className={styles.dropdown}
      style={dynamicDropDownStyle ? dynamicDropDownStyle : undefined}
    >
      {options.map((option) => (
        <OptionContainer
          key={option.id}
          value={option.id}
          selectedOptions={selectedOptions}
          handleFilterSelection={handleFilterSelection}
          text={option.text}
          type={type}
        />
      ))}
    </div>
  );
};

export default DropDown;
