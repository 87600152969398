import { ImInfo } from "react-icons/im";

import DropDown from "views/components/DropDown";
import BigImg from "assets/pngImages/BigImg.png";

import styles from "./style.module.scss";

function SelectSampleData(props: any) {
  return (
    <div className={props.show ? "d-block" : "d-none"}>
      <div className={styles.list}>
        <div className={styles.leftSection}>
          <div className={styles.sourceName}>
            {props.selectedSourceName}&nbsp;
          </div>
          <div className={styles.sampleInfoBox}>
            <div className={styles.singleRow}>
              <span className={styles.infoIcon}>
                <ImInfo size="10px" />
              </span>
              <span className={styles.infoText}>
                Each example Dataset can be used only once.
              </span>
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.iconImage}>
            <img src={BigImg} alt="" />
          </div>
        </div>
      </div>
      <DropDown {...props} />
    </div>
  );
}
export default SelectSampleData;
