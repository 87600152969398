import { StepTileProgressStatus } from "types";

export default function AnnotationsIcon({
  status,
}: {
  status: StepTileProgressStatus;
}) {
  switch (status) {
    case StepTileProgressStatus.DISABLED:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="32"
          viewBox="0 0 29 32"
          fill="none"
        >
          <path
            d="M20.7865 1H8.215C3.655 1 1 3.67 1 8.245V23.74C1 28.39 3.655 31 8.215 31H20.7865C25.42 31 28 28.39 28 23.74V8.245C28 3.67 25.42 1 20.7865 1Z"
            fill="url(#paint0_linear_5827_25552)"
            fillOpacity="0.24"
          />
          <path
            d="M20.7865 1.5C21.0626 1.5 21.2865 1.27614 21.2865 1C21.2865 0.723858 21.0626 0.5 20.7865 0.5V1.5ZM20.7865 0.5C20.5104 0.5 20.2865 0.723858 20.2865 1C20.2865 1.27614 20.5104 1.5 20.7865 1.5V0.5ZM20.7865 0.5H8.215V1.5H20.7865V0.5ZM8.215 0.5C5.83779 0.5 3.89328 1.19784 2.54314 2.55413C1.19332 3.9101 0.5 5.86155 0.5 8.245H1.5C1.5 6.05345 2.13418 4.3824 3.25186 3.25962C4.36922 2.13716 6.03221 1.5 8.215 1.5V0.5ZM0.5 8.245V23.74H1.5V8.245H0.5ZM0.5 23.74C0.5 26.159 1.19227 28.1156 2.54395 29.4673C3.89554 30.8189 5.84083 31.5 8.215 31.5V30.5C6.02917 30.5 4.36696 29.8761 3.25105 28.7602C2.13523 27.6444 1.5 25.971 1.5 23.74H0.5ZM8.215 31.5H20.7865V30.5H8.215V31.5ZM20.7865 31.5C23.1955 31.5 25.1423 30.8199 26.4857 29.4659C27.8286 28.1126 28.5 26.1552 28.5 23.74H27.5C27.5 25.9748 26.8814 27.6474 25.7759 28.7616C24.671 29.8751 23.011 30.5 20.7865 30.5V31.5ZM28.5 23.74V8.245H27.5V23.74H28.5ZM28.5 8.245C28.5 5.86535 27.8276 3.91319 26.4865 2.55551C25.1445 1.19684 23.1986 0.5 20.7865 0.5V1.5C23.0079 1.5 24.6687 2.13816 25.7751 3.25824C26.8824 4.37931 27.5 6.04965 27.5 8.245H28.5Z"
            fill="#60646E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.30477 8V8.015C7.65827 8.015 7.13477 8.54 7.13477 9.185C7.13477 9.83 7.65827 10.355 8.30477 10.355H12.7883C13.4348 10.355 13.9598 9.83 13.9598 9.1685C13.9598 8.525 13.4348 8 12.7883 8H8.30477ZM20.0648 17.1384H8.30477C7.65827 17.1384 7.13477 16.6134 7.13477 15.9684C7.13477 15.3234 7.65827 14.7969 8.30477 14.7969H20.0648C20.7098 14.7969 21.2348 15.3234 21.2348 15.9684C21.2348 16.6134 20.7098 17.1384 20.0648 17.1384ZM20.065 23.9899H8.305C7.855 24.0499 7.42 23.8249 7.18 23.4499C6.94 23.0599 6.94 22.5649 7.18 22.1899C7.42 21.7999 7.855 21.5899 8.305 21.6349H20.065C20.6635 21.6949 21.115 22.2049 21.115 22.8199C21.115 23.4184 20.6635 23.9299 20.065 23.9899Z"
            fill="#313745"
          />
          <defs>
            <linearGradient
              id="paint0_linear_5827_25552"
              x1="14.5"
              y1="1"
              x2="14.5"
              y2="31"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
          </defs>
        </svg>
      );
    case StepTileProgressStatus.ACTIVE:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="32"
          viewBox="0 0 29 32"
          fill="none"
        >
          <path
            d="M20.7865 1H8.215C3.655 1 1 3.67 1 8.245V23.74C1 28.39 3.655 31 8.215 31H20.7865C25.42 31 28 28.39 28 23.74V8.245C28 3.67 25.42 1 20.7865 1Z"
            fill="white"
          />
          <path
            d="M20.7865 1.5C21.0626 1.5 21.2865 1.27614 21.2865 1C21.2865 0.723858 21.0626 0.5 20.7865 0.5V1.5ZM20.7865 0.5C20.5104 0.5 20.2865 0.723858 20.2865 1C20.2865 1.27614 20.5104 1.5 20.7865 1.5V0.5ZM20.7865 0.5H8.215V1.5H20.7865V0.5ZM8.215 0.5C5.83779 0.5 3.89328 1.19784 2.54314 2.55413C1.19332 3.9101 0.5 5.86155 0.5 8.245H1.5C1.5 6.05345 2.13418 4.3824 3.25186 3.25962C4.36922 2.13716 6.03221 1.5 8.215 1.5V0.5ZM0.5 8.245V23.74H1.5V8.245H0.5ZM0.5 23.74C0.5 26.159 1.19227 28.1156 2.54395 29.4673C3.89554 30.8189 5.84083 31.5 8.215 31.5V30.5C6.02917 30.5 4.36696 29.8761 3.25105 28.7602C2.13523 27.6444 1.5 25.971 1.5 23.74H0.5ZM8.215 31.5H20.7865V30.5H8.215V31.5ZM20.7865 31.5C23.1955 31.5 25.1423 30.8199 26.4857 29.4659C27.8286 28.1126 28.5 26.1552 28.5 23.74H27.5C27.5 25.9748 26.8814 27.6474 25.7759 28.7616C24.671 29.8751 23.011 30.5 20.7865 30.5V31.5ZM28.5 23.74V8.245H27.5V23.74H28.5ZM28.5 8.245C28.5 5.86535 27.8276 3.91319 26.4865 2.55551C25.1445 1.19684 23.1986 0.5 20.7865 0.5V1.5C23.0079 1.5 24.6687 2.13816 25.7751 3.25824C26.8824 4.37931 27.5 6.04965 27.5 8.245H28.5Z"
            fill="#60646E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.30477 8V8.015C7.65827 8.015 7.13477 8.54 7.13477 9.185C7.13477 9.83 7.65827 10.355 8.30477 10.355H12.7883C13.4348 10.355 13.9598 9.83 13.9598 9.1685C13.9598 8.525 13.4348 8 12.7883 8H8.30477ZM20.0648 17.1384H8.30477C7.65827 17.1384 7.13477 16.6134 7.13477 15.9684C7.13477 15.3234 7.65827 14.7969 8.30477 14.7969H20.0648C20.7098 14.7969 21.2348 15.3234 21.2348 15.9684C21.2348 16.6134 20.7098 17.1384 20.0648 17.1384ZM20.065 23.9899H8.305C7.855 24.0499 7.42 23.8249 7.18 23.4499C6.94 23.0599 6.94 22.5649 7.18 22.1899C7.42 21.7999 7.855 21.5899 8.305 21.6349H20.065C20.6635 21.6949 21.115 22.2049 21.115 22.8199C21.115 23.4184 20.6635 23.9299 20.065 23.9899Z"
            fill="#151928"
          />
        </svg>
      );
    case StepTileProgressStatus.COMPLETE:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="30"
          viewBox="0 0 27 30"
          fill="none"
        >
          <path
            d="M19.7865 0H7.215C2.655 0 0 2.67 0 7.245V22.74C0 27.39 2.655 30 7.215 30H19.7865C24.42 30 27 27.39 27 22.74V7.245C27 2.67 24.42 0 19.7865 0Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.30477 7V7.015C6.65827 7.015 6.13477 7.54 6.13477 8.185C6.13477 8.83 6.65827 9.355 7.30477 9.355H11.7883C12.4348 9.355 12.9598 8.83 12.9598 8.1685C12.9598 7.525 12.4348 7 11.7883 7H7.30477ZM19.0648 16.1384H7.30477C6.65827 16.1384 6.13477 15.6134 6.13477 14.9684C6.13477 14.3234 6.65827 13.7969 7.30477 13.7969H19.0648C19.7098 13.7969 20.2348 14.3234 20.2348 14.9684C20.2348 15.6134 19.7098 16.1384 19.0648 16.1384ZM19.065 22.9899H7.305C6.855 23.0499 6.42 22.8249 6.18 22.4499C5.94 22.0599 5.94 21.5649 6.18 21.1899C6.42 20.7999 6.855 20.5899 7.305 20.6349H19.065C19.6635 20.6949 20.115 21.2049 20.115 21.8199C20.115 22.4184 19.6635 22.9299 19.065 22.9899Z"
            fill="#34A853"
          />
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="32"
          viewBox="0 0 29 32"
          fill="none"
        >
          <path
            d="M20.7865 1H8.215C3.655 1 1 3.67 1 8.245V23.74C1 28.39 3.655 31 8.215 31H20.7865C25.42 31 28 28.39 28 23.74V8.245C28 3.67 25.42 1 20.7865 1Z"
            fill="white"
          />
          <path
            d="M20.7865 1.5C21.0626 1.5 21.2865 1.27614 21.2865 1C21.2865 0.723858 21.0626 0.5 20.7865 0.5V1.5ZM20.7865 0.5C20.5104 0.5 20.2865 0.723858 20.2865 1C20.2865 1.27614 20.5104 1.5 20.7865 1.5V0.5ZM20.7865 0.5H8.215V1.5H20.7865V0.5ZM8.215 0.5C5.83779 0.5 3.89328 1.19784 2.54314 2.55413C1.19332 3.9101 0.5 5.86155 0.5 8.245H1.5C1.5 6.05345 2.13418 4.3824 3.25186 3.25962C4.36922 2.13716 6.03221 1.5 8.215 1.5V0.5ZM0.5 8.245V23.74H1.5V8.245H0.5ZM0.5 23.74C0.5 26.159 1.19227 28.1156 2.54395 29.4673C3.89554 30.8189 5.84083 31.5 8.215 31.5V30.5C6.02917 30.5 4.36696 29.8761 3.25105 28.7602C2.13523 27.6444 1.5 25.971 1.5 23.74H0.5ZM8.215 31.5H20.7865V30.5H8.215V31.5ZM20.7865 31.5C23.1955 31.5 25.1423 30.8199 26.4857 29.4659C27.8286 28.1126 28.5 26.1552 28.5 23.74H27.5C27.5 25.9748 26.8814 27.6474 25.7759 28.7616C24.671 29.8751 23.011 30.5 20.7865 30.5V31.5ZM28.5 23.74V8.245H27.5V23.74H28.5ZM28.5 8.245C28.5 5.86535 27.8276 3.91319 26.4865 2.55551C25.1445 1.19684 23.1986 0.5 20.7865 0.5V1.5C23.0079 1.5 24.6687 2.13816 25.7751 3.25824C26.8824 4.37931 27.5 6.04965 27.5 8.245H28.5Z"
            fill="#60646E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.30477 8V8.015C7.65827 8.015 7.13477 8.54 7.13477 9.185C7.13477 9.83 7.65827 10.355 8.30477 10.355H12.7883C13.4348 10.355 13.9598 9.83 13.9598 9.1685C13.9598 8.525 13.4348 8 12.7883 8H8.30477ZM20.0648 17.1384H8.30477C7.65827 17.1384 7.13477 16.6134 7.13477 15.9684C7.13477 15.3234 7.65827 14.7969 8.30477 14.7969H20.0648C20.7098 14.7969 21.2348 15.3234 21.2348 15.9684C21.2348 16.6134 20.7098 17.1384 20.0648 17.1384ZM20.065 23.9899H8.305C7.855 24.0499 7.42 23.8249 7.18 23.4499C6.94 23.0599 6.94 22.5649 7.18 22.1899C7.42 21.7999 7.855 21.5899 8.305 21.6349H20.065C20.6635 21.6949 21.115 22.2049 21.115 22.8199C21.115 23.4184 20.6635 23.9299 20.065 23.9899Z"
            fill="#151928"
          />
        </svg>
      );
  }
}
