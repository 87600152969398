import { useParams } from "react-router";
import { connect } from "react-redux";
import { State } from "redux/store";
import { useMemo } from "react";

import {
  getUserConfig,
  getTotalPagesForData,
  getEntityTypeFilter,
  getPaginationMetadataForData,
  getPaginationMetadataForCluster,
  getTotalPagesForCluster,
  getDataPageNumber,
  getClusterPageNumber,
  getIsCardslistLoading,
} from "redux/SingleDataset/selectors";

import { formatNumberWithCommas } from "helpers/utility/formatters";

import GranularitySlider from "./GranularitySlider";
import Paginator from "./Paginator";
import InfoBar from "./InfoBar";

import styles from "./style.module.scss";

const StatsFooter = ({
  userConfig,
  dataPageNumber,
  clusterPageNumber,
  totalPagesForData,
  totalPagesForCluster,
  metadataForData,
  metadataForCluster,
  entityType,
  isCardListLoading,
}: any) => {
  const { datasetId, clusterId } = useParams();
  const isClusterPage = datasetId && clusterId;

  const metadata = isClusterPage ? metadataForCluster : metadataForData;
  const pageNumber = isClusterPage ? clusterPageNumber : dataPageNumber;

  const totalPages = isClusterPage ? totalPagesForCluster : totalPagesForData;
  //changes based on assumpmtion that explorations stats will only have "ANY" context
  const statsBehavior = userConfig?.find(
    (config: any) => config.feature_key === "EXPLORATION_STATS"
  )?.feature_behavior["ANY"];
  const showStats = statsBehavior === "SHOW" ? true : false;

  const selectedEntityType = entityType.find((emtity: any) => emtity.selected);

  const dynamicType = isClusterPage
    ? selectedEntityType
      ? selectedEntityType.text
      : "Images"
    : "CLUSTERS";

  const itemsPerPage = metadata ? metadata.size : 0;
  const totalItems = metadata ? metadata.total : 0;

  const currentBracket = useMemo(() => {
    const startingValue =
      pageNumber === 1 ? 1 : (pageNumber - 1) * itemsPerPage + 1;
    const endingValue = Math.min(startingValue + itemsPerPage - 1, totalItems);

    return `${
      endingValue === 0 ? 0 : formatNumberWithCommas(startingValue)
    } - ${formatNumberWithCommas(endingValue)}`;
  }, [pageNumber, itemsPerPage, totalItems]);

  return (
    <div
      className={styles.footerContainer}
      style={isCardListLoading ? { pointerEvents: "none" } : undefined}
    >
      <InfoBar showStats={showStats} />
      {metadata && (
        <Paginator
          totalCount={totalItems}
          currentBracket={currentBracket}
          type={dynamicType}
          totalPages={totalPages}
          currentPage={pageNumber}
          metadata={metadata}
        />
      )}
      <GranularitySlider />
    </div>
  );
};

function mapStatesToProps(state: State) {
  return {
    userConfig: getUserConfig(state),
    dataPageNumber: getDataPageNumber(state),
    clusterPageNumber: getClusterPageNumber(state),
    totalPagesForData: getTotalPagesForData(state),
    totalPagesForCluster: getTotalPagesForCluster(state),
    metadataForData: getPaginationMetadataForData(state),
    metadataForCluster: getPaginationMetadataForCluster(state),
    entityType: getEntityTypeFilter(state),
    isCardListLoading: getIsCardslistLoading(state),
  };
}

export default connect(mapStatesToProps)(StatsFooter);
