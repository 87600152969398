import { useRef } from "react";
import styles from "./style.module.scss";

const VideoPreview = ({ src }: VideoPreviewProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateVideoImageDimensions = () => {
    if (containerRef) {
      const height = containerRef.current?.clientHeight;
      const previewHeight = Math.ceil(height! / 45);
      return `${previewHeight}%`;
    }
  };

  return (
    <div className={styles.videoImageContainer} ref={containerRef}>
      <img
        src={src}
        style={{ height: calculateVideoImageDimensions() }}
        className={styles.videoImage}
        alt="preview"
        loading="lazy"
      />
    </div>
  );
};

export default VideoPreview;

interface VideoPreviewProps {
  src: string;
}
