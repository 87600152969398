import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { selectUser } from "redux/authReducer";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import {
  PRIVATE_DATASETS_PATH,
  PUBLIC_DATASETS_PATH,
} from "helpers/constants/paths";
import { initAmplitude } from "helpers/utility/amplitude";

import Layout from "views/layout";
import Login from "views/pages/Login";
import DatasetInventory from "views/pages/DatasetInventory";
import DataPage from "views/pages/DataPage";
import MobileScreenErrorModal from "views/modals/MobileScreenErrorModal";
import DatasetCreationPage from "views/pages/DatasetCreationPage";

import "./App.scss";
import { fetchUserInfo } from "redux/User/actions";
import { isFastdupUser } from "helpers/utility/utilities";
import ErrorComponent from "views/layout/ErrorComponent";

const LoginRouter = () => {
  const router = createBrowserRouter(
    createRoutesFromElements([<Route path="/login" element={<Login />} />])
  );
  return <RouterProvider router={router} />;
};

const DataPageRouter = () => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />} errorElement={<ErrorComponent />}>
        <Route
          path="/dataset/:datasetId/data"
          element={<DataPage />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path="/dataset/:datasetId/data/cluster/:clusterId"
          element={<DataPage />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path="/dataset/:datasetId/data/image/:imageId"
          element={<DataPage />}
          errorElement={<ErrorComponent />}
        />
      </Route>
    )
  );
};

const DataAndLegacyRouter = () => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />} errorElement={<ErrorComponent />}>
        <Route
          path="/"
          element={<DatasetInventory />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path={PRIVATE_DATASETS_PATH}
          element={<DatasetInventory />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path={PUBLIC_DATASETS_PATH}
          element={<DatasetInventory />}
          errorElement={<ErrorComponent />}
        />
        <Route path="/dataset/:datasetId/data" element={<DataPage />} />
        <Route
          path="/dataset/:datasetId/data/cluster/:clusterId"
          element={<DataPage />}
        />
        <Route
          path="/dataset/:datasetId/data/image/:imageId"
          element={<DataPage />}
        />
        <Route
          path="/dataset/:newDatasetId/create"
          element={<DatasetCreationPage />}
        />
      </Route>
    )
  );
};

const WrappedRoutes = () => {
  return (
    <RouterProvider
      router={isFastdupUser() ? DataPageRouter() : DataAndLegacyRouter()}
    />
  );
};

const RouterApp = () => {
  const dispatch = useDispatch();
  const pathname = window.location.pathname.split("/")[1];
  const user = selectUser();

  useEffect(() => {
    if (user) {
      dispatch(fetchUserInfo(user) as any);
    }
  }, [user, dispatch]);

  initAmplitude();
  return (
    <>
      <MobileScreenErrorModal />
      {pathname === "login" ? <LoginRouter /> : <WrappedRoutes />}
    </>
  );
};

export default RouterApp;
