import { useEffect, useMemo, useState } from "react";
import { State } from "redux/store";
import { connect } from "react-redux";
import {
  getEntityTypeFilter,
  getIsToggleConfig,
} from "redux/SingleDataset/selectors";
import { DATA_PAGE } from "helpers/constants/pages";
import { BRIGHT_BLUE } from "helpers/constants/colors";
import VLAccordian from "views/components/Accordian";
import DropDown from "views/pages/DataPage/CardsView/DataPageFilters/DropDown";

import styles from "./style.module.scss";

const EntityDropDown = (props: any) => {
  const {
    isEntityTypeSelected,
    entityTypesCountDisplayNumber,
    firstEntityType,
    entityTypeBehavior,
    selectedEntityTypes,
    handleFilterSelection,
    entityTypeFilter,
    type,
  } = props;

  return (
    <VLAccordian
      title="Entity Type"
      type={type}
      page={DATA_PAGE}
      customWidth={"max-content"}
      hideInfo={true}
      initiallyClosed={true}
      fontSize={"13px"}
      bgColor={isEntityTypeSelected ? BRIGHT_BLUE : "#151928"}
      borderRadius="5px"
      padding={"0 10px"}
      height="40px"
      border={isEntityTypeSelected ? "1px solid #fff" : undefined}
      selectionCount={entityTypesCountDisplayNumber}
      firstSelectionName={firstEntityType}
      behavior={entityTypeBehavior}
      tooltipContent={
        isEntityTypeSelected
          ? ""
          : `Apply this filter to display only the data entries from a specific type: Images or Objects within images`
      }
      tooltipID="data-page-entity-type-filter-box"
      tooltipWidth="265px"
    >
      <DropDown
        selectedFilters={{ entityType: selectedEntityTypes }}
        handleFilterSelection={handleFilterSelection}
        options={entityTypeFilter}
        type={type}
      />
    </VLAccordian>
  );
};

const EntityToggle = ({
  handleFilterSelection,
  hideToggle,
  selectedEntityTypes,
  type,
}: any) => {
  const initialIndex = useMemo(() => {
    return selectedEntityTypes.length === 1 ? selectedEntityTypes[0] : 0;
  }, [selectedEntityTypes]);

  const initialValue = useMemo(() => {
    return initialIndex === 0 ? "Images" : "Objects";
  }, [initialIndex]);

  const [selectedButton, setSelectedButton] = useState<"Images" | "Objects">(
    initialValue
  );
  const handleButtonClick = (selection: "Images" | "Objects") => {
    if (selection === selectedButton) return;
    let value = selection === "Images" ? 0 : 1;
    setSelectedButton(selection);
    handleFilterSelection(value, type);
  };

  useEffect(() => setSelectedButton(initialValue), [initialValue]);

  return (
    <div
      className={styles.entityToggleBox}
      style={hideToggle ? { pointerEvents: "none", opacity: "0.5" } : undefined}
    >
      <div
        className={`${styles.imageButton} ${
          selectedButton === "Images" ? styles.selectedButton : ""
        }`}
        onClick={() => handleButtonClick("Images")}
      >
        Images
      </div>
      <div
        className={`${styles.objectButton} ${
          selectedButton === "Objects" ? styles.selectedButton : ""
        }`}
        onClick={() => handleButtonClick("Objects")}
      >
        Objects
      </div>
    </div>
  );
};

const DataPageEntityFilter = (props: any) => {
  const { isToggle } = props;

  return isToggle ? <EntityToggle {...props} /> : <EntityDropDown {...props} />;
};

const mapStateToProps = (state: State) => {
  return {
    isToggle: getIsToggleConfig("entityType")(state),
    entityTypeFilter: getEntityTypeFilter(state),
  };
};

export default connect(mapStateToProps)(DataPageEntityFilter);
