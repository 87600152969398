export const kFormatter = (num: any, digits: any = 1) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const formatBytes = (bytes: any, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export default function formattedDateNow() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-based
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");
  const minute = String(now.getMinutes()).padStart(2, "0");
  const second = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}T${hour}-${minute}-${second}`;
}

export function formatNumberWithCommas(value: string | number) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function timeStringToSeconds(timeString: string): number {
  const cleanTimeString = timeString.replace("Z", "");

  const [timePart, fractionalPart] = cleanTimeString.split(".");

  const [hours, minutes, seconds] = timePart.split(":").map(Number);

  const fractionalSeconds = fractionalPart
    ? parseFloat(`0.${fractionalPart}`)
    : 0;

  const totalSeconds =
    hours * 3600 + minutes * 60 + seconds + fractionalSeconds;

  return totalSeconds;
}

export function secondsToTimeString(totalSeconds: number): string {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Pad single digit minutes and seconds with leading zeros
  const paddedHours = String(hours).padStart(2, "0");
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(seconds).padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

export function displayForamttedDate(inputDateStr: string) {
  const inputDate = new Date(inputDateStr);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const year = inputDate.getFullYear();
  const monthIndex = inputDate.getMonth();
  const day = inputDate.getDate();

  const formattedDate = `${months[monthIndex]} ${day} ${year}`;

  return formattedDate;
}

function padZero(num: number) {
  return (num < 10 ? "0" : "") + num;
}

export function formatTimeHHMM(timestamp: string | number | Date) {
  if (typeof timestamp === "string") {
    timestamp = new Date(timestamp);
  }

  if (timestamp instanceof Date && !isNaN(timestamp.getTime())) {
    const hours = timestamp.getHours();
    const minutes = timestamp.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;

    return `${hours12}:${padZero(minutes)} ${ampm}`;
  } else {
    return "";
  }
}
