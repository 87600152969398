import { useDispatch } from "react-redux";
import { User } from "types";
import { useNavigate } from "react-router";

import { setShowModal, setShowSignUpModal } from "redux/Modals/actions";

import {
  CREATE_NEW_DATASET,
  SIGNUP_ORIGIN,
} from "helpers/constants/amplitudeProperties";

import addDatasetIcon from "assets/img/custom-add-icon.svg";
import styles from "./style.module.scss";
import { isAnonymousUser } from "redux/authReducer";
import { createNewDataset, resetStore } from "redux/CreateDataset/actions";

type AddDatasetCardTypes = {
  isContentLoading: boolean;
  user: User | null;
  handleSampleDatasets: () => void;
  isNewDatasetImportEnabled: boolean;
};

const AddDatasetCard = ({
  isContentLoading,
  user,
  handleSampleDatasets,
  isNewDatasetImportEnabled,
}: AddDatasetCardTypes) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = async () => {
    //check if user has not signed-in
    if (
      !process.env.REACT_APP_IS_AUTHENTICATION_DISABELED &&
      (user === null || isAnonymousUser())
    ) {
      dispatch(setShowSignUpModal(true));
      localStorage.setItem(SIGNUP_ORIGIN, CREATE_NEW_DATASET);
      return;
    }
    //open the new dataset creation flow
    else if (isNewDatasetImportEnabled) {
      dispatch(resetStore());
      const newDatasetId = await dispatch(createNewDataset("") as any);

      if (newDatasetId) {
        navigate(`/dataset/${newDatasetId}/create`);
      }
      return;
    }
    //open the old dataset creation flow
    dispatch(setShowModal(true));
    handleSampleDatasets();
  };

  return (
    <div
      className={isContentLoading ? "d-none" : styles.addDatasetWrapper}
      onClick={handleClick}
    >
      <img
        className={styles.addDatasetIcon}
        src={addDatasetIcon}
        alt="ad-dataset-img"
      />
      <span className={styles.addDatasetText}>Add a new Dataset</span>
    </div>
  );
};

export default AddDatasetCard;
