import {
  IS_CONTENT_LOADING,
  SET_SINGLE_IMAGE_DATA,
  SET_SINGLE_IMAGE_LABELS,
  SET_SINGLE_IMAGE_ISSUES,
  SET_HOVERED_OBJECT_LABEL,
  SET_SELECTED_OBJECT_LABELS,
} from "./constants";

const INITIAL_STATE = {
  isContentLoading: false,
  isLoading: false,
  singleImageData: null,
  singleImageIssues: [],
  singleImageLabels: [],
  selectedObjectLabels: [],
  hoveredObjectLabel: "",
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case IS_CONTENT_LOADING:
      return {
        ...state,
        isContentLoading: action.data,
      };

    case SET_SINGLE_IMAGE_DATA:
      return {
        ...state,
        singleImageData: action.data,
      };

    case SET_SINGLE_IMAGE_ISSUES:
      return {
        ...state,
        singleImageIssues: action.data,
      };

    case SET_SINGLE_IMAGE_LABELS:
      return {
        ...state,
        singleImageLabels: action.data,
      };

    case SET_SELECTED_OBJECT_LABELS:
      return {
        ...state,
        selectedObjectLabels: action.data,
      };

    case SET_HOVERED_OBJECT_LABEL:
      return {
        ...state,
        hoveredObjectLabel: action.data,
      };

    default:
      return state;
  }
};

export default reducer;
