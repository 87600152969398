import {
  ENTITY_TYPE_PARAMETER,
  LOADING_OR_ERROR_CARD_DIMENSION_NUMBER,
  OBJECT_ID_QUERY_PARAMETER,
  PUBLIC_PAGE_QUERY_PARAMETER_KEY,
  PUBLIC_PAGE_QUERY_PARAMETER_VALUE,
} from "helpers/constants/miscellaneous";
import { useEffect, useState } from "react";
import classNames from "classnames";
import {
  addQueryParameter,
  getQueryParameter,
} from "helpers/utility/utilities";
import { setSelectedCarouselIndex } from "redux/Modals/actions";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  clearImagePageParamsContext,
  fetchNavigationCluster,
  setDataPageNumber,
} from "redux/SingleDataset/actions";

import Header from "./Header";
import Body from "./Body";
import Carousel from "./Carousel";

import { hoveredAssetDataType, SetStateFunction } from "types";
import styles from "./style.module.scss";

const AssetPreviewCard = ({
  isClusterPage,
  singleData,
  handleSingleCardLabels,
  handleFileType,
  isDataPage,
  isSingleEntityOnDataPage,
  clusterSize,
  isCardSelected,
  checkExportSelected,
  containerRef,
  index,
  hoveredAssetData,
  assetData,
  handleSimilarButtonClick,
  page,
  setIsHoveringLinkButton,
  isHoveringLinkButton,
  setIsHoveringFindSimilarButton,
  isHoveringFindSimilarButton,
  showCarousel,
  setAssetPreviewFlag,
  updateAssetData,
  clearAssetData,
  searchParams,
  indexOfPointerOut,
}: AssetPreviewProps) => {
  const [assetImage, setAssetImage] = useState("");
  const [transistionEnded, setTransistionEnded] = useState(false);
  const [linkClicked, setLinkClicked] = useState(false);

  const { datasetId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const singleCardRef = document.getElementById(`clusterCardWrapper-${index}`);
  const showAssetPreview = Object.keys(assetData).length > 0;
  const timestamp = assetData.frame_timestamp
    ? assetData.frame_timestamp.substring(0, 8)
    : undefined;
  const previewCount = singleData.previews ? singleData.previews.length : 0;

  function getNewPositions() {
    const container = containerRef.current;
    const card = document.getElementById(`clusterCardWrapper-${index}`);
    let x: "left" | "right" | "center" | "" = "";
    let y: "top" | "bottom" = "top";

    if (!container || !card) return { x, y };

    // Calculate dimensions
    const computedStyle = window.getComputedStyle(container);
    const paddingLeft = parseFloat(computedStyle.paddingLeft);
    const paddingRight = parseFloat(computedStyle.paddingRight);

    const totalContainerWidth =
      container.offsetWidth - (paddingLeft + paddingRight);
    const totalContainerHeight = container.scrollHeight;

    const cardWidth = card.offsetWidth;
    const cardHeight = card.offsetHeight;
    const columns = Math.floor(totalContainerWidth / cardWidth);
    const rows = Math.floor(totalContainerHeight / cardHeight);
    //removing the total space taken by gaps in the cards
    const containerWidth = totalContainerWidth - (columns - 1) * 18;

    // Calculate max dimensions
    const maxCardWidth = cardWidth * 2;

    // Calculate card position
    const { left: cardX } = card.getBoundingClientRect();
    const midPoint = containerWidth / 2;

    if (maxCardWidth < containerWidth && columns > 2) {
      const midRangeLowerPoint = midPoint - cardWidth;
      const midRangeHigherPoint = midPoint + cardWidth;

      if (cardX < midRangeLowerPoint) x = "left";
      else if (cardX + cardWidth > midRangeHigherPoint) x = "right";
      else x = "center";
    } else x = cardX <= midPoint ? "left" : "right";

    const totalCount = rows * columns;
    if (rows > 1 && totalCount - columns < index + 1) y = "bottom";

    return { x, y };
  }
  const { x: assetPositionX, y: assetPositionY } = getNewPositions();

  const assetPreviewClassNames = classNames(styles.assetPreviewWrapper, {
    [styles.assetPreview]: showAssetPreview,
    [styles.hiddenDiv]: !showAssetPreview,
    [styles.alignLeft]: assetPositionX === "left",
    [styles.alignRight]: assetPositionX === "right",
    [styles.alignCenter]: assetPositionX === "center",
    [styles.alignTop]: assetPositionY === "top",
    [styles.alignBottom]: assetPositionY === "bottom",
  });

  const handleLinkClick = (e: any) => {
    e.stopPropagation();
    if (linkClicked) return;
    else setLinkClicked(true);

    const entityParam = searchParams.has(ENTITY_TYPE_PARAMETER)
      ? `?${ENTITY_TYPE_PARAMETER}=${searchParams.get(ENTITY_TYPE_PARAMETER)}`
      : "";

    const dynamicID =
      assetData.type === "IMAGE" ? assetData.media_id : assetData.image_id;

    let imagePath =
      location.pathname.split("/cluster")[0] +
      `/image/${dynamicID}${entityParam}`;

    if (!!getQueryParameter(PUBLIC_PAGE_QUERY_PARAMETER_KEY)) {
      imagePath = addQueryParameter(
        imagePath,
        PUBLIC_PAGE_QUERY_PARAMETER_KEY,
        PUBLIC_PAGE_QUERY_PARAMETER_VALUE
      );
    }

    if (assetData.type === "OBJECT") {
      imagePath = addQueryParameter(
        imagePath,
        OBJECT_ID_QUERY_PARAMETER,
        assetData.media_id
      );
    }

    const url = window.location.host + imagePath;

    navigator.clipboard.writeText(url.toString());
  };

  const handleMouseLeave = () => {
    if (indexOfPointerOut > -1) return;
    setTransistionEnded(false);
    clearAssetData();
    setAssetPreviewFlag(false);
  };

  const assetPreviewStyle = () => {
    if (hoveredAssetData.id === assetData.media_id) {
      if (singleCardRef && hoveredAssetData && hoveredAssetData.dimensions) {
        //deducting the extra header height
        let height = singleCardRef.offsetHeight - 71;
        let width = singleCardRef.offsetWidth;
        const maxHeight = height * 2;
        const maxWidth = width * 2;
        const contentHeight = hoveredAssetData.dimensions.naturalHeight;
        const contentWidth = hoveredAssetData.dimensions.naturalWidth;

        // if height and width are -999(give card height and width)
        if (
          contentHeight === LOADING_OR_ERROR_CARD_DIMENSION_NUMBER &&
          contentWidth === LOADING_OR_ERROR_CARD_DIMENSION_NUMBER
        ) {
          return {
            height: `${singleCardRef.offsetHeight * 1.5}px`,
            width: `${singleCardRef.offsetWidth * 1.5}px`,
          };
        } else {
          const aspectRatio = contentWidth / contentHeight;

          if (aspectRatio > 1) {
            // Landscape orientation
            width = maxWidth;
            height = width / aspectRatio;
          } else {
            // Portrait or square orientation
            height = maxHeight;
            width = height * aspectRatio;
          }
        }
        return { height: `${height + 71}px`, width: `${width}px` };
      }
    }
    return undefined;
  };

  const renderCarousel = () => {
    if (showCarousel) {
      const imageIndex = singleData.previews.findIndex(
        (p: any) => p.media_id === assetData.media_id
      );

      return (
        <Carousel
          previews={singleData.previews}
          imageIndex={imageIndex}
          updateAssetData={updateAssetData}
        />
      );
    }

    return null;
  };

  const handleAssetPreviewDetailClick = (e: any) => {
    clearAssetData();
    dispatch(clearImagePageParamsContext());

    const dynamicID =
      assetData.type === "IMAGE" ? assetData.media_id : assetData.image_id;

    const entityParam = searchParams.has(ENTITY_TYPE_PARAMETER)
      ? `?${ENTITY_TYPE_PARAMETER}=${searchParams.get(ENTITY_TYPE_PARAMETER)}`
      : "";

    let imagePath =
      location.pathname.split("/cluster")[0] +
      `/image/${dynamicID}${entityParam}`;

    if (assetData.type === "OBJECT") {
      imagePath = addQueryParameter(
        imagePath,
        OBJECT_ID_QUERY_PARAMETER,
        assetData.media_id
      );
    }

    if (isDataPage && singleData.previews.length > 1) {
      dispatch(setDataPageNumber(1));
      dispatch(
        fetchNavigationCluster(datasetId!, singleData.cluster_id) as any
      );
    }

    dispatch(setSelectedCarouselIndex(index));
    navigate(imagePath);
  };

  const handleAssetPreviewFindSimilarClick = (e: any) => {
    clearAssetData();
    handleSimilarButtonClick(e);
  };

  useEffect(() => {
    if (showAssetPreview) {
      switch (assetData.type) {
        case "IMAGE":
          setAssetImage(assetData.media_uri);
          break;
        case "OBJECT":
          setAssetImage(assetData.media_uri);
          break;
        default:
          return;
      }
    }

    return () => {
      setLinkClicked(false);
    };
  }, [showAssetPreview, assetData]);

  return (
    <div
      className={assetPreviewClassNames}
      style={assetPreviewStyle()}
      onMouseLeave={handleMouseLeave}
      onTransitionEnd={() => showAssetPreview && setTransistionEnded(true)}
      onClick={(e) => e.stopPropagation()}
    >
      <Header
        index={index}
        assetData={assetData}
        clearAssetData={clearAssetData}
        isCardSelected={isCardSelected}
        isExportSelected={!!checkExportSelected()}
        singleCardLabels={handleSingleCardLabels()}
        type={singleData?.type}
        fileType={handleFileType()}
        isDataPage={isDataPage}
        isClusterPage={isClusterPage}
        isSingleEntityOnDataPage={isSingleEntityOnDataPage}
        clusterSize={clusterSize}
      />
      <Body
        handleDetailsClick={handleAssetPreviewDetailClick}
        assetType={assetData.type}
        videoURI={assetData.video_uri}
        imageSrc={assetImage}
        handleLinkClick={handleLinkClick}
        setIsHoveringLinkButton={setIsHoveringLinkButton}
        linkClicked={linkClicked}
        isHoveringLinkButton={isHoveringLinkButton}
        handleFindSimilarClick={handleAssetPreviewFindSimilarClick}
        setIsHoveringFindSimilarButton={setIsHoveringFindSimilarButton}
        isHoveringFindSimilarButton={isHoveringFindSimilarButton}
        isCardSelected={isCardSelected}
        page={page}
        boundingBox={assetData.bounding_box || undefined}
        transistionEnded={transistionEnded}
        timestamp={timestamp}
        hoveredAssetData={hoveredAssetData}
        previewCount={previewCount}
      />
      {renderCarousel()}
      {isCardSelected && <div className={styles.selectedDataCard}></div>}
      {checkExportSelected() && (
        <div className={styles.selectedExportCard}></div>
      )}
    </div>
  );
};

export default AssetPreviewCard;

interface AssetPreviewProps {
  page: string;
  index: number;
  assetData: any;
  singleData: any;
  containerRef: any;
  handleFileType: any;
  clusterSize: number;
  isDataPage: boolean;
  updateAssetData: (id: string, source: string) => void;
  showCarousel: boolean;
  isClusterPage: boolean;
  isCardSelected: boolean;
  checkExportSelected: any;
  clearAssetData: any;
  setAssetPreviewFlag: (value: boolean) => void;
  handleSingleCardLabels: any;
  setIsHoveringLinkButton: any;
  isHoveringLinkButton: boolean;
  handleSimilarButtonClick: any;
  isSingleEntityOnDataPage: boolean;
  setIsHoveringFindSimilarButton: any;
  isHoveringFindSimilarButton: boolean;
  hoveredAssetData: hoveredAssetDataType;
  searchParams: any;
  isObjectCluster: boolean;
  indexOfPointerOut: number;
  setIndexOfPointerOut: SetStateFunction<number>;
}
