import { setIsImageUploading } from "redux/SingleDataset/actions";
import Cropper, { ReactCropperElement } from "react-cropper";
import { createRef, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./style.module.scss";
import "cropperjs/dist/cropper.css";

const ImageUploadModal = ({
  file,
  cropData,
  setCropData,
}: ImageUploadModalProps) => {
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const image = useMemo(() => {
    return URL.createObjectURL(file);
  }, [file]);
  const cropperRef = createRef<ReactCropperElement>();

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(setIsImageUploading(false));
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
      croppedCanvas.toBlob((blob: any) => {
        const newFile = new File([blob], file.name, {
          type: "image/jpeg",
        });
        // Update state with the cropped file
        const { x, y, width, height } = cropperRef.current?.cropper.getData()!;
        const absoluteHeightDifference = Math.abs(
          height - imageDimensions.height
        );
        const absoluteWidthDifference = Math.abs(width - imageDimensions.width);

        const sameHeight =
          absoluteHeightDifference > 0 && absoluteHeightDifference <= 1;
        const sameWidth =
          absoluteWidthDifference > 0 && absoluteWidthDifference <= 1;

        let interacted = false;
        if (!sameHeight || !sameWidth) interacted = true;

        setCropData({
          x: Math.round(x),
          y: Math.round(y),
          x1: Math.round(width) + Math.round(x),
          y1: Math.round(height) + Math.round(y),
          file: newFile,
          interacted,
        });
      }, "image/jpeg");
    }
  };

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageDimensions({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
    img.src = URL.createObjectURL(file);
  }, [file]);

  return (
    <div className={styles.imageUploadModalWrapper}>
      <div className={styles.imageUploadModal}>
        <div className={styles.imageUploadModalHeader}>
          <div className={styles.headerTitleContainer}>
            <div className={styles.headerTitle}>Crop Image</div>
          </div>
          <div className={styles.headerCloseButton} onClick={handleCloseModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M1 10L0 9L4 5L0 1L1 0L5 4L9 0L10 1L6 5L10 9L9 10L5 6L1 10Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
        <div className={styles.imageUploadModalBody}>
          <div className={styles.cropperContainer}>
            <Cropper
              ref={cropperRef}
              style={{ height: "100%", width: "100%" }}
              zoomTo={0}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={70}
              minCropBoxWidth={70}
              background={false}
              responsive={true}
              autoCropArea={100}
              checkOrientation={true}
              guides={true}
            />
          </div>

          <div className={styles.actionButtons}>
            <div className={styles.button} onClick={getCropData}>
              Submit
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadModal;

interface ImageUploadModalProps {
  file: File;
  cropData: any;
  setCropData: any;
}
