import { connect, useDispatch } from "react-redux";
import Cropper, { ReactCropperElement } from "react-cropper";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  Fragment,
  createRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import IssuesBoundingBox from "./IssuesBoundingBox";
import ContentLoad from "views/uikit/ContentLoad";
import ImageCarousel from "./ImageCarousel";
import BoundingBox from "./BoundingBox";

import {
  getImageData,
  getImageDataLoader,
  getSelectedIssues,
  getSelectedLabels,
} from "redux/Modals/selectors";
import { State } from "redux/store";
import { getSelectedObjectLabels } from "redux/SingleImage/selectors";
import {
  getImagePageParamsContext,
  getNavigationCluster,
  getNavigationContext,
  isRegionSearchEnabled,
  getNavigationClusterLoader,
} from "redux/SingleDataset/selectors";
import {
  searchImageFileUploaded,
  setImagePageParamsContext,
  clearImagePageParamsContext,
  setContentLoader,
} from "redux/SingleDataset/actions";

import { calculateAspectRatio } from "helpers/utility/utilities";
import { timeStringToSeconds } from "helpers/utility/formatters";
import {
  setImageDataLoader,
  setSelectedCarouselIndex,
  setSelectedIssues,
  setSelectedLabels,
} from "redux/Modals/actions";
import { IMAGE_DETAILS_PAGE } from "helpers/constants/pages";

import { BiArrowBack } from "react-icons/bi";
import FindSimilarIcon from "assets/icons/FindSimilarIcon";
import {
  OBJECT_ID_QUERY_PARAMETER,
  PAGE_PARAMETER,
  VERTEX_ID,
  VERTEX_TYPE,
} from "helpers/constants/miscellaneous";
import classNames from "classnames";
import { singleImageObjectType } from "types";

import styles from "./style.module.scss";
import "./vl-cropper.css";
import ZoomControl from "./ZoomControl";
import { useImageDrag } from "helpers/hooks";

const ImageSection = ({
  imageData,
  navigationCluster,
  children,
  isExpanded,
  setIsExpanded,
  selectedLabels,
  selectedIssues,
  isContentLoading,
  navigationContext,
  regionSearchEnabled,
  imagePageParamsContext,
  navigationClusterLoading,
}: any) => {
  const { searchRegion } = imagePageParamsContext;

  const [imageLoader, setimageLoader] = useState(true);
  const [tooltipDimensions, setTooltipDimensions] = useState({
    height: 0,
    width: 0,
  });
  const [imgDimensions, setImgDimensions] = useState<{
    height: any;
    width: any;
  }>({ height: 0, width: 0 });
  const [imageContainerStyle, setImageContainerStyle] = useState<any>({});
  const [isFindSimilarButtonHovered, setIsFindSimilarButtonHovered] =
    useState(false);
  const [selectionButtonPosition, setSelectionButtonPosition] =
    useState<any>(null);
  const [cropData, setCropData] = useState<any>(null);
  const [file, setFile] = useState<File | null>(null);
  const [hoveredLabelKey, setHoveredLabelKey] = useState("");
  const [imageZoomInLevel, setImageZoomInLevel] = useState(100);

  const cropperRef = createRef<ReactCropperElement>();
  const heroImageRef = useRef<HTMLImageElement>(null);
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const draggableContainerRef = useRef<HTMLDivElement>(null);

  const { centerImageHandler } = useImageDrag(
    draggableContainerRef,
    imageZoomInLevel
  );

  const { datasetId, imageId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [isFindSimilarButtonSelected, setIsFindSimilarButtonSelected] =
    useState(!!searchRegion);

  const timestamp = imageData?.frame_timestamp
    ? timeStringToSeconds(imageData.frame_timestamp)
    : 0;

  const handleImageDetailsBackButtonClick = (e: any) => {
    dispatch(setSelectedLabels([]));
    dispatch(setSelectedIssues([]));
    setIsExpanded(e, true);
    dispatch(setSelectedCarouselIndex(0));

    if (
      navigationContext.length === 1 &&
      navigationContext.includes(IMAGE_DETAILS_PAGE)
    ) {
      let path = location.pathname.split("/image")[0];
      navigate(path);
    } else navigate(-1);
  };

  const dynamicColor =
    isFindSimilarButtonHovered && !isFindSimilarButtonSelected
      ? "#151928"
      : "#fff";

  const findSimilarButtonTextClassNames = classNames(
    styles.findSimilarButtonText,
    isFindSimilarButtonHovered && !isFindSimilarButtonSelected
      ? styles.blackColorText
      : styles.whiteColorText
  );

  const findSimilarButtonClassNames = classNames(styles.findSimilarButton, {
    [styles.whiteBackground]:
      isFindSimilarButtonHovered && !isFindSimilarButtonSelected,
    [styles.blueBackground]: isFindSimilarButtonSelected,
  });

  const resetZoomLevel = () => {
    centerImageHandler();
    setImageZoomInLevel(100);
  };

  const calculateNewImageDimensions = () => {
    if (heroImageRef.current) {
      let zoomValue = imageZoomInLevel / 100;
      let newValues = calculateAspectRatio(heroImageRef.current);
      setTooltipDimensions({
        height: newValues[0] * zoomValue,
        width: newValues[1] * zoomValue,
      });
    }
  };

  const calculateOuterImageContainerHeight = () => {
    let mainContainer = mainContainerRef.current;
    if (mainContainer) {
      let carouselHeight = 0;
      if (navigationCluster && navigationCluster.previews.length > 1) {
        carouselHeight = isExpanded ? 144 : 50;
      }
      setImageContainerStyle({
        height: mainContainer.clientHeight - 50 - carouselHeight, // -50 for image header
      });
    }
  };

  const handleImageLoad = () => {
    calculateOuterImageContainerHeight();
    let zoomValue = imageZoomInLevel / 100;
    setImgDimensions({
      height: (heroImageRef.current?.naturalHeight || 0) * zoomValue,
      width: (heroImageRef.current?.naturalWidth || 0) * zoomValue,
    });
    setimageLoader(false);
  };

  const handleFindSimilarClickOnImagePage = () => {
    setIsFindSimilarButtonSelected(!isFindSimilarButtonSelected);
    dispatch(clearImagePageParamsContext());
  };

  const handleSelectionSubmit = async () => {
    if (typeof cropperRef.current?.cropper !== "undefined" && imageData) {
      const cropper = cropperRef.current.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();
      const { naturalHeight: imageHeight, naturalWidth: imageWidth } =
        cropper.getImageData();

      const createFile = async (imgUrl: any) => {
        const response = await fetch(imgUrl);
        const blob = await response.blob();
        const file = new File([blob], imageData.file_name, {
          type: blob.type,
        });

        return file;
      };

      const imageFile = await createFile(imageData.image_uri);
      if (imageFile) setFile(imageFile);

      croppedCanvas.toBlob((blob: any) => {
        const cropFile = new File([blob], imageData.file_name, {
          type: "image/jpeg",
        });
        // Update state with the cropped file
        const { x, y, width, height } = cropper.getData();
        const absoluteHeightDifference = Math.abs(imageHeight - height);
        const absoluteWidthDifference = Math.abs(imageWidth - width);
        const sameHeight =
          absoluteHeightDifference >= 0 && absoluteHeightDifference <= 1;
        const sameWidth =
          absoluteWidthDifference >= 0 && absoluteWidthDifference <= 1;
        let interacted = false;
        if (!sameHeight || !sameWidth) interacted = true;
        setCropData({
          x: Math.round(x),
          y: Math.round(y),
          x1: Math.round(width) + Math.round(x),
          y1: Math.round(height) + Math.round(y),
          file: cropFile,
          interacted,
        });
      }, "image/jpeg");
    }
  };

  const renderBoundingBoxes = () => {
    const showBoundingBoxes = !isFindSimilarButtonSelected;
    if (!showBoundingBoxes) return null;

    return selectedLabels.map(
      (singleLabel: singleImageObjectType, index: number) => {
        return (
          singleLabel.selected && (
            <Fragment key={index}>
              <BoundingBox
                indexKey={index + ""}
                objectLabel={singleLabel}
                imageDimensions={imgDimensions}
                tooltipDimensions={tooltipDimensions}
                isContentLoading={isContentLoading}
                hoveredLabelKey={hoveredLabelKey}
                setHoveredLabelKey={setHoveredLabelKey}
              />
            </Fragment>
          )
        );
      }
    );
  };

  const renderIssueBoxes = () => {
    const showIssueBoxes = !isFindSimilarButtonSelected;
    if (showIssueBoxes) {
      return selectedIssues.map((selectedIssue: any, index: number) => (
        <Fragment key={`common-${index}`}>
          <IssuesBoundingBox
            selectedIssue={selectedIssue}
            imageDimensions={imgDimensions}
            tooltipDimensions={tooltipDimensions}
          />
        </Fragment>
      ));
    } else return null;
  };

  const renderRegionOfInterest = () => {
    if (isFindSimilarButtonSelected && imageData) {
      const handleButtonStyle = () => {
        if (selectionButtonPosition) {
          return selectionButtonPosition;
        } else return { display: "none" };
      };

      const handleCropEvent = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
          const data = cropperRef.current?.cropper.getCropBoxData();
          setSelectionButtonPosition({
            top: data.top + data.height / 2 - 15,
            left: data.left + data.width / 2 - 15,
          });
        }
      };
      const handleCropperReady = () => {
        const ref = cropperRef.current;
        if (searchRegion && isFindSimilarButtonSelected && ref) {
          const boundingBoxValues = searchRegion.split(",");
          if (typeof ref.cropper !== "undefined") {
            const cropper = ref.cropper;

            cropper.setCropBoxData({
              left: parseInt(boundingBoxValues[0]),
              top: parseInt(boundingBoxValues[1]),
              width: parseInt(boundingBoxValues[2]),
              height: parseInt(boundingBoxValues[3]),
            });
          }
        }
      };

      centerImageHandler();
      if (imageZoomInLevel > 100) {
        resetZoomLevel();
      }

      return (
        <div className={styles.cropperContainer}>
          <div
            className={styles.submitSelectionButton}
            onClick={handleSelectionSubmit}
            style={handleButtonStyle()}
          >
            <div className={styles.submitSelectionButtonIcon}>
              <FindSimilarIcon />
            </div>
          </div>
          <Cropper
            className={styles.cropper}
            ref={cropperRef}
            preview=".img-preview"
            src={imageData.image_uri}
            viewMode={1}
            minCropBoxHeight={50}
            minCropBoxWidth={50}
            background={false}
            responsive={true}
            autoCropArea={searchRegion ? 0 : 100}
            checkOrientation={true}
            zoomable={false}
            guides={false}
            ready={handleCropperReady}
            crop={handleCropEvent}
          />
        </div>
      );
    } else return null;
  };

  const handleRegionSearch = async () => {
    if (cropData && file) {
      const boundingBoxValues = `${cropData.x},${cropData.y},${cropData.x1},${cropData.y1}`;
      let path = location.pathname.split("/image")[0];
      searchParams.delete(OBJECT_ID_QUERY_PARAMETER);
      dispatch(clearImagePageParamsContext());

      const cropper = cropperRef.current?.cropper;
      if (cropper !== undefined) {
        const { left, top, width, height } = cropper.getCropBoxData();
        const searchRegionValues = `${Math.round(left)},${Math.round(
          top
        )},${Math.round(width)},${Math.round(height)}`;

        dispatch(
          setImagePageParamsContext({
            imageId: imageId!,
            searchRegion: searchRegionValues,
          })
        );

        dispatch(
          searchImageFileUploaded(
            datasetId,
            file,
            cropData.file,
            cropData.interacted ? boundingBoxValues : undefined
          ) as any
        )
          .then((anchorMediaId: string) => {
            dispatch(setContentLoader(false));
            searchParams.set(VERTEX_ID, anchorMediaId);
            searchParams.set(VERTEX_TYPE, "UPLOAD");
            searchParams.set(PAGE_PARAMETER, "1");
            navigate(path + `?${searchParams.toString()}`);
          })
          .catch(() => {
            searchParams.delete(VERTEX_ID);
            searchParams.delete(VERTEX_TYPE);
            navigate(path + `?${searchParams.toString()}`);
          });
        dispatch(setImageDataLoader(true));
      }
    }
  };

  useEffect(() => {
    if (heroImageRef.current) {
      heroImageRef.current.style.transform = `scale(${imageZoomInLevel / 100})`;
      calculateNewImageDimensions();
    }
    //eslint-disable-next-line
  }, [imageZoomInLevel, heroImageRef]);

  useEffect(() => {
    window.addEventListener("resize", calculateOuterImageContainerHeight);
    calculateOuterImageContainerHeight();

    return () => {
      window.removeEventListener("resize", calculateOuterImageContainerHeight);
    };

    // eslint-disable-next-line
  }, [isExpanded, isFindSimilarButtonSelected, navigationClusterLoading]);

  useEffect(() => {
    if (imageContainerStyle.height) {
      calculateNewImageDimensions();
    }
    //eslint-disable-next-line
  }, [imageContainerStyle]);

  useEffect(() => {
    handleRegionSearch();
    //eslint-disable-next-line
  }, [cropData]);

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.mainContainer} ref={mainContainerRef}>
        <div className={styles.imageHeader}>
          <div
            className={styles.backButton}
            onClick={handleImageDetailsBackButtonClick}
          >
            <div className={styles.backButtonIcon}>
              <BiArrowBack size="1.25em" />
            </div>
            <span className={styles.backButtonText}>GO BACK</span>
          </div>
          <div className={styles.imageTitle} title={imageData?.file_name}>
            {imageData?.file_name}
          </div>
          {regionSearchEnabled ? (
            <div
              className={findSimilarButtonClassNames}
              onMouseEnter={() => setIsFindSimilarButtonHovered(true)}
              onMouseLeave={() => setIsFindSimilarButtonHovered(false)}
              onClick={handleFindSimilarClickOnImagePage}
            >
              <div className={styles.findSimilarButtonIconContainer}>
                <FindSimilarIcon color={dynamicColor} />
              </div>
              <div className={findSimilarButtonTextClassNames}>
                Find Similar
              </div>
            </div>
          ) : (
            <div className={styles.showNoRegionSearch}></div>
          )}
        </div>
        <div className={styles.imageContainer} style={imageContainerStyle}>
          <div
            className={styles.draggableContainer}
            ref={draggableContainerRef}
          >
            {(isContentLoading || imageLoader) && !imageData ? (
              <div className={styles.imageLoader}>
                <ContentLoad />
              </div>
            ) : (
              <>
                <img
                  ref={heroImageRef}
                  className={styles.heroImage}
                  src={imageData?.image_uri}
                  alt="hero_img"
                  loading="lazy"
                  onLoad={handleImageLoad}
                  draggable={false}
                />
              </>
            )}
            {renderBoundingBoxes()}
            {renderIssueBoxes()}
            {renderRegionOfInterest()}
          </div>
          {!isFindSimilarButtonSelected && (
            <ZoomControl
              zoomLevel={imageZoomInLevel}
              onZoomLevelChange={setImageZoomInLevel}
              onCenterImageClick={centerImageHandler}
              className={styles.zoomController}
            />
          )}
        </div>
        {navigationCluster && navigationCluster.previews.length > 1 && (
          <ImageCarousel
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            navigationCluster={navigationCluster}
            isContentLoading={isContentLoading}
            timestamp={timestamp}
            resetZoomLevel={resetZoomLevel}
          />
        )}
      </div>

      {children}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    imageData: getImageData(state),
    selectedLabels: getSelectedLabels(state),
    selectedIssues: getSelectedIssues(state),
    isContentLoading: getImageDataLoader(state),
    navigationContext: getNavigationContext(state),
    navigationCluster: getNavigationCluster(state),
    selectedObjectLabel: getSelectedObjectLabels(state),
    regionSearchEnabled: isRegionSearchEnabled(state),
    imagePageParamsContext: getImagePageParamsContext(state),
    navigationClusterLoading: getNavigationClusterLoader(state),
  };
};

export default connect(mapStateToProps)(ImageSection);
