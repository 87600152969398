import { SetStateFunction, VertexType } from "types";
import { ImageFileCard } from "../ImageFileCard";
import { State } from "redux/store";
import { connect, useDispatch } from "react-redux";
import {
  getSimilarityVertexCluster,
  getVertexType,
} from "redux/SingleDataset/selectors";
import SingleCard from "../SingleCard";
import { useLocation, useNavigate, useParams } from "react-router";
import { DATA_PAGE } from "helpers/constants/pages";
import { useMemo } from "react";
import { setVertexPreviewsCountContext } from "redux/SingleDataset/actions";
import {
  PAGE_PARAMETER,
  VERTEX_CONTEXT,
  VERTEX_ID,
  VERTEX_TYPE,
} from "helpers/constants/miscellaneous";

const VertexCard = ({
  vertexCluster,
  vertexType,
  cardElementRef,
  cardContainerRef,
  indexOfPointerOut,
  setIndexOfPointerOut,
}: VertexCardProps) => {
  const { datasetId } = useParams();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRemoveVertex = () => {
    dispatch(setVertexPreviewsCountContext(0));
    searchParams.delete(VERTEX_ID);
    searchParams.delete(VERTEX_TYPE);
    searchParams.delete(VERTEX_CONTEXT);
    searchParams.delete(PAGE_PARAMETER);
    let newPath = location.pathname.concat(`?${searchParams.toString()}`);
    navigate(newPath);
  };

  if (!vertexCluster) {
    return <noscript />;
  }
  if (
    vertexType === "UPLOAD" &&
    vertexCluster.previews &&
    vertexCluster.previews.length > 0
  ) {
    return (
      <ImageFileCard
        filename={vertexCluster.previews[0].file_name}
        imageSrc={vertexCluster.previews[0].media_uri}
        handleRemove={handleRemoveVertex}
        cardElementRef={cardElementRef}
      />
    );
  } else {
    return (
      <SingleCard
        key={0}
        index={0}
        singleData={vertexCluster}
        datasetId={datasetId!}
        cardElementRef={cardElementRef}
        containerRef={cardContainerRef}
        page={DATA_PAGE}
        isDataPage={true}
        isClusterPage={false}
        isCardSelected={true}
        handleRemoveVertex={handleRemoveVertex}
        clusterID={vertexCluster.cluster_id}
        showExportOption={true}
        searchParams={searchParams}
        indexOfPointerOut={indexOfPointerOut}
        setIndexOfPointerOut={setIndexOfPointerOut}
      />
    );
  }
};

const mapStateToProps = (state: State) => {
  return {
    vertexCluster: getSimilarityVertexCluster(state),
    vertexType: getVertexType(state),
  };
};
export default connect(mapStateToProps)(VertexCard);

type VertexCardProps = {
  vertexCluster: any;
  vertexType: VertexType;
  cardElementRef: any;
  cardContainerRef: any;
  indexOfPointerOut: number;
  setIndexOfPointerOut: SetStateFunction<number>;
};
