import { Helmet } from "react-helmet";
import { connect, useDispatch, useSelector } from "react-redux";
import { State } from "redux/store";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  getIsCardslistLoading,
  getFilteredSimilarityClusters,
  getContentLoading,
  getNavigationClusterLoader,
  isClusterMetadataSummaryEnabled,
  getVertexID,
  getIsToggleConfig,
  getTotalPagesForCluster,
  getIsImageUploading,
  isDataMetadataSummaryEnabled,
  getFilteredNavigationCluster,
} from "redux/SingleDataset/selectors";
import {
  getImageData,
  getImageLabels,
  getShowLearnMoreModal,
  getShowRequestDemoModal,
  getShowRequestDemoSuccessModal,
} from "redux/Modals/selectors";
import {
  fetchDataset,
  fetchSimilarityData,
  setDatasetLabels,
  fetchDatasetLabels,
  setDatasetIssueTypes,
  fetchFilterConfig,
  fetchNavigationCluster,
  fetchClusterPagePagination,
  fetchDataPagePagination,
  fetchSimilarityThresholdsInfo,
  resetStore,
  setCardslistLoading,
  setNavigationContext,
  setVertexID,
  setClusterPageNumber,
  setDataPageNumber,
  setUserTags,
  setSearchText,
  setEntityTypeFilter,
  setSimilarityThreshold,
  setSimilarityVertex,
  setFilterFolder,
  updateFileSelection,
  fetchClusterPageIssueTypes,
  setVertexContext,
  setVertexType,
  fetchDatasetIssues,
} from "redux/SingleDataset/actions";
import { fetchImageInfo } from "redux/Modals/actions";
import { fetchUserTags } from "redux/SingleDataset/actions";
import {
  CLUSTER_DETAILS_PAGE,
  DATA_PAGE,
  IMAGE_DETAILS_PAGE,
} from "helpers/constants/pages";
import { getQueryParameter } from "helpers/utility/utilities";
import {
  OBJECT_ID_QUERY_PARAMETER,
  DATE_PARAMETER,
  LABELS_PARAMETER,
  FOLDER_PARAMETER,
  PAGE_PARAMETER,
  USER_TAGS_PARAMETER,
  ISSUE_TYPE_PARAMETER,
  SEARCH_TEXT_PARAMETER,
  ENTITY_TYPE_PARAMETER,
  GRANULARITY_PARAMETER,
  VERTEX_CONTEXT,
  VERTEX_TYPE,
  VERTEX_ID,
} from "helpers/constants/miscellaneous";

import RequestDemoSuccessModal from "views/modals/RequestDemoSuccessModal";
import RequestDemoModal from "views/modals/RequestDemoModal";

import ContainerHeader from "views/components/ContainerHeader";
import HealthStatus from "views/components/HealthStatus";
import StatsFooter from "./StatsFooter";
import ImageView from "./ImageView";
import CardsView from "./CardsView";

import styles from "./style.module.scss";
import {
  DATE_RANGE_FILTER,
  ENTITY_TYPE_FILTER,
  FILE_FOLDER_FILTER,
  ISSUE_TYPE_FILTER,
  LABEL_FILTER,
  TAGS_FILTER,
  TEXTUAL_SEARCH,
} from "helpers/constants/filters";
import { ContactUsComponentType, FilterOption } from "types";
import SignupTeaserModal from "views/modals/SignupTeaserModal";
import { LearnMoreModal } from "views/modals/LearnMoreModal";

const DataPage = ({
  clusters,
  isContentLoading,
  isCardListLoading,
  navigationCluster,
  navigationClusterLoader,
  imageData,
  labels,
  clusterMetadataSummaryEnabled,
  dataMetadataSummaryEnabled,
  isImageUploading,
  showRequestDemoModal,
  showRequestDemoSuccessModal,
  showLearnMoreModal,
}: DataPageProps) => {
  const [commonDataFetched, setCommonDataFetched] = useState(false);
  const { datasetId, clusterId, imageId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const selectedLabelID = getQueryParameter(OBJECT_ID_QUERY_PARAMETER);
  const isDataPage = !!datasetId && !imageId && !clusterId;
  const isClusterPage = !!datasetId && !!clusterId;
  const isImagePage = !!imageId;

  const dynamicPage = isImagePage
    ? IMAGE_DETAILS_PAGE
    : isClusterPage
    ? CLUSTER_DETAILS_PAGE
    : DATA_PAGE;

  const navigate = useNavigate();
  const singleDatasetState = useSelector((state: State) => state.singleDataset);

  const convertURItoArray = (str: string) => {
    const result = JSON.parse(str);

    if (Array.isArray(result)) {
      return result;
    } else {
      return false;
    }
  };
  const convertURItoNumber = (str: string) => {
    const result = decodeURIComponent(JSON.parse(str));
    if (Number(result) || Number(result) === 0) {
      return Number(result);
    } else {
      return false;
    }
  };
  const filterConfigAllows = (key: string, filterConfig: any) => {
    const singleFilter = filterConfig.find(
      (filter: any) => filter.feature_key === key
    );
    if (singleFilter) {
      if (singleFilter.feature_behavior.ANY !== "HIDE") {
        return true;
      }
    }
    return false;
  };
  const createParams = (searchParams: URLSearchParams, filterConfig: any) => {
    const params: { [key: string]: any } = {
      [DATE_PARAMETER]: null,
      [FOLDER_PARAMETER]: null,
      [VERTEX_ID]: null,
      [VERTEX_TYPE]: null,
      [VERTEX_CONTEXT]: null,
      [LABELS_PARAMETER]: null,
      [PAGE_PARAMETER]: null,
      [USER_TAGS_PARAMETER]: null,
      [ISSUE_TYPE_PARAMETER]: null,
      [SEARCH_TEXT_PARAMETER]: null,
      [ENTITY_TYPE_PARAMETER]: null,
      [GRANULARITY_PARAMETER]: null,
    };

    searchParams.forEach((value: string, key: string) => {
      switch (key) {
        case DATE_PARAMETER:
          if (filterConfigAllows(DATE_RANGE_FILTER, filterConfig)) {
            params[DATE_PARAMETER] = decodeURIComponent(value);
          }
          break;
        case FOLDER_PARAMETER:
          if (filterConfigAllows(FILE_FOLDER_FILTER, filterConfig))
            params[FOLDER_PARAMETER] = decodeURIComponent(value);
          break;
        case LABELS_PARAMETER:
          if (
            filterConfigAllows(LABEL_FILTER, filterConfig) &&
            convertURItoArray(value)
          )
            params[LABELS_PARAMETER] = convertURItoArray(value);
          break;
        case PAGE_PARAMETER:
          if (convertURItoNumber(value)) {
            params[PAGE_PARAMETER] = convertURItoNumber(value);
          }
          break;

        case VERTEX_ID:
          params[VERTEX_ID] = decodeURIComponent(value);
          break;

        case VERTEX_TYPE:
          params[VERTEX_TYPE] = decodeURIComponent(value);
          break;

        case VERTEX_CONTEXT:
          params[VERTEX_CONTEXT] = decodeURIComponent(value);
          break;

        case USER_TAGS_PARAMETER:
          if (
            filterConfigAllows(TAGS_FILTER, filterConfig) &&
            convertURItoArray(value)
          )
            params[USER_TAGS_PARAMETER] = convertURItoArray(value);
          break;
        case ISSUE_TYPE_PARAMETER:
          if (
            filterConfigAllows(ISSUE_TYPE_FILTER, filterConfig) &&
            convertURItoArray(value)
          )
            params[ISSUE_TYPE_PARAMETER] = convertURItoArray(value);
          break;
        case SEARCH_TEXT_PARAMETER:
          if (filterConfigAllows(TEXTUAL_SEARCH, filterConfig))
            params[SEARCH_TEXT_PARAMETER] = decodeURIComponent(value);
          break;
        case ENTITY_TYPE_PARAMETER:
          if (
            filterConfigAllows(ENTITY_TYPE_FILTER, filterConfig) &&
            convertURItoArray(value)
          )
            params[ENTITY_TYPE_PARAMETER] = convertURItoArray(value);
          break;
        case GRANULARITY_PARAMETER:
          if (convertURItoNumber(value) || convertURItoNumber(value) === 0)
            params[GRANULARITY_PARAMETER] = convertURItoNumber(value);
          break;
        default:
          break;
      }
    });

    return params;
  };
  const createStateFromParams = (params: any, state: any) => {
    const updateArrayWithParamValues = (
      paramValues: string[],
      stateArr: FilterOption[]
    ) => {
      return stateArr.map((option) => {
        return {
          ...option,
          selected: paramValues.includes(option.text),
        };
      });
    };

    const getUnselectedArray = (arr: FilterOption[]) => {
      return arr.map((option: FilterOption) => {
        return { ...option, selected: false };
      });
    };

    Object.keys(params).forEach((key: string) => {
      switch (key) {
        case DATE_PARAMETER:
          break;
        case FOLDER_PARAMETER:
          //Ugly Hack:
          //refer - reducer/singleDataset/UPDATE_FILE_SELECTION
          if (params[key]) {
            const path =
              params[key].charAt(0) === "/" //if the first char is a slash remove it
                ? params[key].slice(1)
                : params[key];

            const pathValues = path.split("/");
            const pathHasChildren = pathValues.length > 1;
            if (pathHasChildren) {
              dispatch(updateFileSelection({ title: path, isSelected: true }));
            } else {
              dispatch(setFilterFolder(path));
            }
          } else {
            dispatch(setFilterFolder(params[key]));
          }
          break;

        case VERTEX_ID:
          dispatch(setVertexID(params[key]));
          dispatch(setSimilarityVertex(null));
          break;

        case VERTEX_CONTEXT:
          dispatch(setVertexContext(params[VERTEX_CONTEXT]));
          break;

        case VERTEX_TYPE:
          dispatch(setVertexType(params[VERTEX_TYPE]));
          break;

        case PAGE_PARAMETER:
          if (params[key] === null) {
            if (isClusterPage) {
              dispatch(setClusterPageNumber(1));
            } else if (isDataPage) {
              dispatch(setDataPageNumber(1));
            }
            if (!isImagePage) {
              searchParams.set(PAGE_PARAMETER, "1");
              let newPath = location.pathname.concat(
                `?${searchParams.toString()}`
              );
              navigate(newPath, { replace: true });
            }
          } else {
            if (isClusterPage) {
              dispatch(setClusterPageNumber(params[key]));
            } else if (isDataPage) {
              dispatch(setDataPageNumber(params[key]));
            }
          }
          break;

        case LABELS_PARAMETER:
          if (params[key] === null) {
            const unselected = getUnselectedArray(state.labels);
            dispatch(setDatasetLabels(unselected));
          } else {
            const selectedLabels = updateArrayWithParamValues(
              params[key],
              state.labels
            );
            dispatch(setDatasetLabels(selectedLabels));
          }
          break;
        case USER_TAGS_PARAMETER:
          if (params[key] === null) {
            const unselected = getUnselectedArray(state.userTags);
            dispatch(setUserTags(unselected));
          } else {
            const selectedTags = updateArrayWithParamValues(
              params[key],
              state.userTags
            );
            dispatch(setUserTags(selectedTags));
          }
          break;
        case ISSUE_TYPE_PARAMETER:
          if (params[key] === null) {
            const unselected = getUnselectedArray(state.issueTypes);
            dispatch(setDatasetIssueTypes(unselected));
          } else {
            const selectedIssueTypes = updateArrayWithParamValues(
              params[key],
              state.issueTypes
            );
            dispatch(setDatasetIssueTypes(selectedIssueTypes));
          }
          break;
        case ENTITY_TYPE_PARAMETER:
          if (params[key] === null) {
            const unselected = getUnselectedArray(state.entityTypeFilter);
            dispatch(setEntityTypeFilter(unselected));
          } else {
            const selectedEntities = updateArrayWithParamValues(
              params[key],
              state.entityTypeFilter
            );
            dispatch(setEntityTypeFilter(selectedEntities));
          }
          break;

        case SEARCH_TEXT_PARAMETER:
          dispatch(setSearchText(params[key] || ""));
          break;
        case GRANULARITY_PARAMETER:
          if (params[key] === null) {
            const middleValue =
              Math.floor(state.similarityThresholds?.length / 2) || 2;
            setSimilarityThreshold({ threshold: middleValue.toString() });
          } else {
            dispatch(
              setSimilarityThreshold({ threshold: params[key].toString() })
            );
          }
          break;
      }
    });
  };
  const fetchCommonData = async () => {
    try {
      dispatch(setCardslistLoading(true));
      dispatch(setDatasetLabels([]));

      if (isClusterPage) {
        dispatch(fetchDataset(datasetId, clusterId) as any);
        dispatch(fetchUserTags(datasetId, clusterId) as any);
        dispatch(fetchClusterPageIssueTypes(datasetId, clusterId) as any);
        dispatch(fetchDatasetLabels(datasetId, clusterId) as any);
      } else if (isDataPage) {
        dispatch(fetchDataset(datasetId) as any);
        dispatch(fetchUserTags(datasetId) as any);
        dispatch(fetchDatasetLabels(datasetId) as any);
      } else if (isImagePage) {
        dispatch(fetchDataset(datasetId) as any);
      }

      await Promise.all([
        // items in this list are blocking the page loading so the list should be the bare minimum that is required to render the page and not nothing else (additional settings can be loaded in the background)
        dispatch(fetchSimilarityThresholdsInfo(datasetId!) as any),
        dispatch(fetchFilterConfig(datasetId) as any),
      ]);
      setCommonDataFetched(true);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchForDataPage = async (datasetID: string) => {
    dispatch(setCardslistLoading(true));
    dispatch(fetchDataPagePagination(datasetID) as any);
    dispatch(fetchSimilarityData(datasetId) as any);
    dispatch(fetchDatasetIssues(datasetId) as any);
  };
  const fetchForClusterPage = async (datasetID: string, clusterID: string) => {
    dispatch(fetchClusterPagePagination(datasetID, clusterID) as any);
    await dispatch(fetchNavigationCluster(datasetID, clusterID) as any);
  };
  const fetchForImagePage = (imageID: string) => {
    dispatch(fetchImageInfo(imageID, selectedLabelID) as any);
  };

  useEffect(() => {
    fetchCommonData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (commonDataFetched) {
      const params = createParams(
        searchParams,
        singleDatasetState.filterConfig
      );
      createStateFromParams(params, singleDatasetState);

      if (isImagePage) {
        fetchForImagePage(imageId);
        dispatch(setNavigationContext(IMAGE_DETAILS_PAGE) as any);
      } else if (params[PAGE_PARAMETER] || params[VERTEX_ID]) {
        if (isClusterPage) {
          fetchForClusterPage(datasetId, clusterId);
          dispatch(setNavigationContext(CLUSTER_DETAILS_PAGE) as any);
        } else if (isDataPage) {
          fetchForDataPage(datasetId);
          dispatch(setNavigationContext(DATA_PAGE) as any);
        }
      }
    }
    //eslint-disable-next-line
  }, [commonDataFetched, datasetId, clusterId, imageId, searchParams]);

  useEffect(() => {
    return () => {
      dispatch(resetStore());
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>Dataset Exploration</title>
      </Helmet>
      <div className={styles.headerContainer}>
        <ContainerHeader
          isContentLoading={isContentLoading}
          isCardListLoading={isCardListLoading}
          page={DATA_PAGE}
          dynamicPage={dynamicPage}
          showPageSwitch={true}
        />
        <HealthStatus />
      </div>

      {showRequestDemoModal.flag && (
        <RequestDemoModal calledFrom={showRequestDemoModal.calledFrom} />
      )}
      {showRequestDemoSuccessModal && <RequestDemoSuccessModal />}
      {showLearnMoreModal && <LearnMoreModal />}
      {dynamicPage === IMAGE_DETAILS_PAGE ? (
        <ImageView labels={labels} imageData={imageData} />
      ) : (
        <>
          <CardsView
            clusters={clusters}
            dynamicPage={dynamicPage}
            navigationCluster={navigationCluster}
            isCardListLoading={isCardListLoading}
            navigationClusterLoader={navigationClusterLoader}
            metadataSummaryEnabled={
              isDataPage
                ? dataMetadataSummaryEnabled
                : clusterMetadataSummaryEnabled
            }
            isImageUploading={isImageUploading}
          />
          <StatsFooter />
        </>
      )}
      <SignupTeaserModal />
    </>
  );
};

const mapStatesToProps = (state: State) => {
  return {
    isContentLoading: getContentLoading(state),
    isCardListLoading: getIsCardslistLoading(state),
    clusters: getFilteredSimilarityClusters(state),
    navigationCluster: getFilteredNavigationCluster(state),
    navigationClusterLoader:
      getNavigationClusterLoader(state) || !getTotalPagesForCluster(state),
    imageData: getImageData(state),
    labels: getImageLabels(state),
    clusterMetadataSummaryEnabled: isClusterMetadataSummaryEnabled(state),
    dataMetadataSummaryEnabled: isDataMetadataSummaryEnabled(state),
    stateVertexID: getVertexID(state),
    isEntityToggle: getIsToggleConfig("entityType")(state),
    isImageUploading: getIsImageUploading(state),
    showRequestDemoModal: getShowRequestDemoModal(state),
    showRequestDemoSuccessModal: getShowRequestDemoSuccessModal(state),
    showLearnMoreModal: getShowLearnMoreModal(state),
  };
};

export default connect(mapStatesToProps)(DataPage);

interface DataPageProps {
  labels: any;
  clusters: any;
  imageData: any;
  navigationCluster: any;
  isEntityToggle: boolean | undefined;
  isContentLoading: boolean;
  isImageUploading: boolean;
  isCardListLoading: boolean;
  navigationClusterLoader: boolean;
  clusterMetadataSummaryEnabled: boolean;
  dataMetadataSummaryEnabled: boolean;
  showRequestDemoModal: { flag: boolean; calledFrom: ContactUsComponentType };
  showRequestDemoSuccessModal: boolean;
  showLearnMoreModal: boolean;
}
