import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  searchImageFileUploaded,
  setCardslistLoading,
  setIsImageUploading,
  setNavigationCluster,
  setNavigationClusterLoader,
  setSimilarityData,
  setSimilarityVertex,
} from "redux/SingleDataset/actions";
import { validateImageUpload } from "helpers/validations";

import ImageUploadModal from "./ImageUploadModal";
import styles from "./style.module.scss";
import {
  PAGE_PARAMETER,
  VERTEX_ID,
  VERTEX_TYPE,
} from "helpers/constants/miscellaneous";

const StyledDownArrow = ({ color = "#0197D8" }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="41"
    viewBox="0 0 27 41"
    fill="none"
  >
    <path
      d="M10.5098 34.9314C12.1653 34.2 13.6867 33.5712 14.9846 33.0905C17.5691 32.1177 19.2818 31.7147 19.4625 32.088C19.6432 32.4612 18.3476 33.5765 16.1123 35.0464C14.9946 35.7758 13.5636 36.6082 11.9759 37.4866L9.48239 38.8329L8.14046 39.5178C7.80518 39.6949 7.45279 39.8367 7.08885 39.9409C6.58673 40.0881 6.05796 40.1172 5.54299 40.026C5.03583 39.9258 4.5591 39.7063 4.1513 39.3854C3.9594 39.2336 3.78242 39.0636 3.62281 38.8775C3.50615 38.7406 3.39713 38.5973 3.29628 38.4481C3.00271 37.993 2.73535 37.5211 2.49553 37.0348C2.06841 36.1869 1.69104 35.3144 1.36537 34.4216C0.811436 32.9315 0.429688 31.3817 0.227722 29.803C-0.0960075 27.1188 0.267547 25.4632 0.715621 25.4389C1.16369 25.4145 1.67362 26.9536 2.54516 29.3185C2.98634 30.4499 3.54071 31.8868 4.25214 33.3798L4.60212 34.0923C5.07878 32.7195 5.77803 30.9493 6.82266 28.6566C8.28017 25.486 9.89915 22.3939 11.6731 19.3928C13.625 16.0724 15.7524 12.8608 18.0459 9.77197C22.4159 3.86375 26.6131 -0.298898 26.9728 0.0170149C27.3325 0.332928 24.1122 5.06979 20.4714 11.2132C16.8182 17.332 13.5161 23.6584 10.5816 30.1609C9.35931 32.8735 8.50376 34.7916 7.8478 36.1537L7.91502 36.1534L10.4989 35.0107"
      fill={color}
    />
  </svg>
);

const Header = ({ fileError }: { fileError: boolean }) => {
  return fileError ? (
    <div className={styles.headingContainer}>
      <StyledDownArrow color="#ff5454" />
      <div className={styles.headingTextWithError}>
        Please drop an image file
      </div>
    </div>
  ) : (
    <div className={styles.headingContainer}>
      <StyledDownArrow color="#0197D8" />
      <div className={styles.headingText}>Drop Image Here</div>
    </div>
  );
};

const ImageDropBox = () => {
  //eslint-disable-next-line
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileError, setFileError] = useState(false);
  //eslint-disable-next-line
  const [replace, setReplace] = useState(false); //In case we want to introduce a replace image option.
  //eslint-disable-next-line
  const [file, setFile] = useState<File | null>(null);
  const [cropData, setCropData] = useState<any>(null);

  const inputRef = useRef<any>(null);
  const { datasetId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const setSelectedFile = (file: File) => {
    if (validateImageUpload(file)) {
      setFileError(false);
      setFile(file);
      // const path = location.pathname.concat(`?${searchParams.toString()}`);
      // navigate(path);
      // dispatch(setIsImageUploading(false));
      // dispatch(searchImageFileUploaded(datasetId, file) as any);
    } else {
      setFileError(true);
    }
  };

  const uploadFile = async (e: any) => {
    setReplace(false);
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  async function handleVisualSearch() {
    if (cropData && file) {
      const boundingBoxValues = `${cropData.x},${cropData.y},${cropData.x1},${cropData.y1}`;
      dispatch(setIsImageUploading(false));
      dispatch(setCardslistLoading(true));
      dispatch(setSimilarityData(null));
      dispatch(setNavigationClusterLoader(true));
      dispatch(setNavigationCluster(null));
      dispatch(setSimilarityVertex(null));

      dispatch(
        searchImageFileUploaded(
          datasetId,
          file,
          cropData.file,
          cropData.interacted ? boundingBoxValues : undefined
        ) as any
      )
        .then((anchorMediaId: string) => {
          searchParams.set(VERTEX_ID, anchorMediaId);
          searchParams.set(VERTEX_TYPE, "UPLOAD");
          searchParams.set(PAGE_PARAMETER, "1");
          navigate({ search: `?${searchParams.toString()}` });
        })
        .catch(() => {
          searchParams.delete(VERTEX_ID);
          searchParams.delete(VERTEX_TYPE);
          navigate({ search: `?${searchParams.toString()}` });
          window.location.reload();
        });
    }
  }

  useEffect(() => {
    handleVisualSearch();
    //eslint-disable-next-line
  }, [cropData]);

  return (
    <div className={styles.imageDropBoxWrapper}>
      <div
        className={styles.imageDropBoxContainer}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={fileError ? { borderColor: "#ff5454" } : undefined}
      >
        <Header fileError={fileError} />
        <div className={styles.orText}>or</div>
        <div
          className={styles.browseButton}
          onClick={() => inputRef?.current?.click()}
        >
          Browse your computer
        </div>
        <input
          type="file"
          ref={inputRef}
          hidden
          onChange={(e: any) => uploadFile(e)}
        />
      </div>
      {!!file && (
        <ImageUploadModal
          file={file}
          cropData={cropData}
          setCropData={setCropData}
        />
      )}
    </div>
  );
};
export default ImageDropBox;
