import { useEffect, useRef, useState } from "react";

import TooltipWrapper from "views/uikit/TooltipWrapper";
import FindSimilarIcon from "assets/icons/FindSimilarIcon";
import LinkIcon from "assets/icons/LinkIcon";

import {
  timeStringToSeconds,
  secondsToTimeString,
} from "helpers/utility/formatters";

import ErrorIcon from "./Icons/ErrorIcon";
import Loader from "views/uikit/Loader";
import VideoBox from "./VideoBox";

import classNames from "classnames";
import styles from "./style.module.scss";

const ErrorPreview = () => {
  return (
    <div className={styles.errorPreview}>
      <ErrorIcon />
      preview is not available
    </div>
  );
};

const ObjectBody = ({
  imageSrc,
  boundingBox,
  transistionEnded,
  videoURI,
  timestamp,
  videoLoaded,
  videoDuration,
  handleVideoLoad,
  handleVideoError,
  handleTimeUpdate,
  showLoadingOverLay,
}: ObjectBodyProps) => {
  const [imageLoader, setimageLoader] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const objectImageRef = useRef<HTMLImageElement>(null);

  const boundingBoxStyle = () => {
    let heightRatio = 100;
    let widthRatio = 100;
    const container = containerRef.current;
    const image = objectImageRef.current;

    if (container && image) {
      const height = container.offsetHeight || 1;
      const width = container.offsetWidth || 1;

      const imageHeight = image.naturalHeight || 1;
      const imageWidth = image.naturalWidth || 1;

      heightRatio = (height / imageHeight) * 100;
      widthRatio = (width / imageWidth) * 100;

      if (boundingBox && !imageLoader && transistionEnded) {
        return {
          left: (boundingBox[0] * widthRatio) / 100,
          top: (boundingBox[1] * heightRatio) / 100,
          width: (boundingBox[2] * widthRatio) / 100,
          height: (boundingBox[3] * heightRatio) / 100,
        };
      }
    }

    return undefined;
  };

  return (
    <div ref={containerRef} className={styles.previewContainer}>
      {!videoLoaded && (
        <>
          <img
            ref={objectImageRef}
            className={styles.previewImage}
            src={imageSrc}
            alt="preview img"
            loading="lazy"
            onLoad={() => setimageLoader(false)}
          />
          {!videoURI && (
            <div className={styles.tooltipWrapper}>
              <div
                className={styles.boundingBox}
                style={boundingBoxStyle()}
              ></div>
            </div>
          )}
        </>
      )}

      {showLoadingOverLay && (
        <div className={styles.loadingOverlay}>
          <Loader />
        </div>
      )}

      <VideoBox
        src={videoURI}
        offset={timestamp ? timeStringToSeconds(timestamp) : 0}
        duration={videoDuration}
        showVideoBox={videoLoaded}
        handleVideoLoad={handleVideoLoad}
        handleVideoError={handleVideoError}
        handleTimeUpdate={handleTimeUpdate}
      />
    </div>
  );
};

const Body = ({
  handleDetailsClick,
  assetType,
  videoURI,
  imageSrc,
  handleLinkClick,
  linkClicked,
  handleFindSimilarClick,
  isCardSelected,
  page,
  setIsHoveringLinkButton,
  isHoveringLinkButton,
  setIsHoveringFindSimilarButton,
  isHoveringFindSimilarButton,
  boundingBox,
  transistionEnded,
  timestamp,
  hoveredAssetData,
  previewCount,
}: BodyProps) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [currTime, setCurrTime] = useState(0);
  const { error, id: hoveredId } = hoveredAssetData;

  const showVideo = !error && videoLoaded;
  const showTimestamp = timestamp && showVideo;
  const showActionButtons = !(error || videoError) && hoveredId;
  const videoDuration = 4;
  const showLoadingOverLay = !!videoURI && !videoLoaded && !videoError;
  const timestampText = `Timestamp ${secondsToTimeString(currTime)}`;

  const timestampClassNames = classNames(styles.assetPreviewTimestamp, {
    [styles.higherMarginBottom]: previewCount > 2,
  });

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  const handleVideoError = () => {
    setVideoError(true);
  };

  const handleTimeUpdate = (newTime: number) => {
    if (newTime) {
      let temp = Math.floor(newTime);
      if (temp !== currTime) {
        setCurrTime(temp);
      }
    } else setCurrTime(0);
  };

  //handle the video reloads when selection is made from carousel
  useEffect(() => {
    if (videoURI) {
      setVideoError(false);
      setVideoLoaded(false);
    }
  }, [hoveredId, videoURI]);

  const renderPreview = () => {
    if (videoError) {
      return <ErrorPreview />;
    }

    switch (assetType) {
      case "IMAGE":
        return (
          <div className={styles.previewContainer}>
            {!videoLoaded && (
              <img
                className={styles.previewImage}
                src={imageSrc}
                alt="preview img"
                loading="lazy"
              />
            )}
            {showLoadingOverLay && (
              <div className={styles.loadingOverlay}>
                <Loader />
              </div>
            )}

            <VideoBox
              src={videoURI}
              offset={timestamp ? timeStringToSeconds(timestamp) : 0}
              duration={videoDuration}
              showVideoBox={videoLoaded}
              handleVideoLoad={handleVideoLoad}
              handleVideoError={handleVideoError}
              handleTimeUpdate={handleTimeUpdate}
            />
          </div>
        );

      case "OBJECT":
        return (
          <ObjectBody
            imageSrc={imageSrc}
            boundingBox={boundingBox}
            transistionEnded={transistionEnded}
            videoURI={videoURI}
            timestamp={timestamp}
            videoDuration={videoDuration}
            videoLoaded={videoLoaded}
            handleVideoLoad={handleVideoLoad}
            handleVideoError={handleVideoError}
            handleTimeUpdate={handleTimeUpdate}
            showLoadingOverLay={showLoadingOverLay}
          />
        );

      default:
        return null;
    }
  };

  const renderActionButtons = () => {
    return (
      showActionButtons && (
        <div className={styles.actionButtons}>
          <div
            className={styles.actionButton}
            onClick={handleLinkClick}
            onMouseEnter={() => setIsHoveringLinkButton(true)}
            onMouseLeave={() => setIsHoveringLinkButton(false)}
            data-tooltip-id={"assetpreview-link-button-tooltip"}
            data-tooltip-content={linkClicked ? "Copied!" : "Copy URL"}
          >
            <LinkIcon
              color={isHoveringLinkButton ? "#0197d8" : "#fff"}
              clicked={linkClicked}
            />

            <TooltipWrapper id="assetpreview-link-button-tooltip" />
          </div>
          {!isCardSelected && (
            <div
              className={styles.actionButton}
              onClick={handleFindSimilarClick}
              onMouseEnter={() => setIsHoveringFindSimilarButton(true)}
              onMouseLeave={() => setIsHoveringFindSimilarButton(false)}
              data-tooltip-id="assetpreview-find-similar-button-tooltip"
              data-tooltip-content="Find Similar"
            >
              <FindSimilarIcon
                color={isHoveringFindSimilarButton ? "#0197d8" : "#fff"}
              />
              <TooltipWrapper id="assetpreview-find-similar-button-tooltip" />
            </div>
          )}
        </div>
      )
    );
  };

  return (
    <div className={styles.cardBody} onClick={handleDetailsClick}>
      {renderPreview()}
      {renderActionButtons()}
      {showTimestamp && (
        <div className={timestampClassNames}>{timestampText}</div>
      )}
    </div>
  );
};

export default Body;

interface BodyProps {
  handleDetailsClick: any;
  assetType: "IMAGE" | "OBJECT";
  videoURI: string | undefined;
  imageSrc: string;
  page: string;
  setIsHoveringLinkButton: any;
  isHoveringLinkButton: boolean;
  setIsHoveringFindSimilarButton: any;
  isHoveringFindSimilarButton: boolean;
  handleLinkClick: any;
  linkClicked: any;
  handleFindSimilarClick: any;
  isCardSelected: any;
  boundingBox: number[] | undefined;
  transistionEnded: boolean;
  timestamp: string | undefined;
  hoveredAssetData: any;
  previewCount: number;
}

interface ObjectBodyProps {
  imageSrc: string;
  boundingBox: number[] | undefined;
  transistionEnded: boolean;
  videoURI: string | undefined;
  timestamp: string | undefined;
  videoDuration: number;
  videoLoaded: boolean;
  handleVideoLoad: () => void;
  handleVideoError: () => void;
  showLoadingOverLay: boolean;
  handleTimeUpdate: (currTime: number) => void;
}
