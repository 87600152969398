import { useMemo } from "react";
import {
  FilterItemCountsWithSelect,
  FilterOption,
  MetadataBarTypes,
} from "types";
import { useLocation, useNavigate } from "react-router";
import { trackFilterSelection } from "helpers/utility/amplitude";
import FileDisplayContent from "./FileDisplayContent";
import BarDisplayContent from "./BarDisplayContent";
import { PAGE_PARAMETER } from "helpers/constants/miscellaneous";

import styles from "./style.module.scss";

function trackMetadataFilterChange(
  filterType: "Labels" | "Files" | "Issue Types" | "User Tags",
  entityState: FilterOption[],
  changeType: "Added" | "Removed"
) {
  trackFilterSelection(filterType, "Metadata Summary", entityState, changeType);
}

const ContentBody = ({
  title,
  type,
  data,
  showNoMatchingData,
  isContentSelected,
  entityTypeFilter,
  resetSection,
  datasetLabels,
  userTags,
  issueTypes,
}: ContentBodyProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const isDataOverflowing = data.length > 5;
  const sortedData = data.slice().sort((a: any, b: any) => b.count - a.count);

  const updateQueryParameter = (options: FilterOption[], parameter: string) => {
    const result = options
      .filter((option) => option.selected === true)
      .map((option) => option.text);

    if (result.length > 0) {
      searchParams.set(parameter, JSON.stringify(result));
    } else {
      searchParams.delete(parameter);
    }
    searchParams.set(PAGE_PARAMETER, "1");
    navigate({ search: `?${searchParams.toString()}` });
  };

  const showEmptyContent = () => {
    return (
      <div className={styles.noMatchcontainer}>
        Currently no matching {title.toLowerCase()}.
      </div>
    );
  };

  const showContentForType = (type: MetadataBarTypes) => {
    if (data.length === 0) {
      return showEmptyContent();
    }
    switch (type) {
      case "file-display":
        return (
          <FileDisplayContent
            data={sortedData}
            isDataOverflowing={isDataOverflowing}
            showNoMatchingData={showNoMatchingData}
            isContentSelected={isContentSelected}
            entityTypeFilter={entityTypeFilter}
            resetSection={resetSection}
            trackMetadataFilterChange={trackMetadataFilterChange}
          />
        );
      case "labels":
        return (
          <BarDisplayContent
            data={sortedData}
            isDataOverflowing={isDataOverflowing}
            showNoMatchingData={showNoMatchingData}
            isContentSelected={isContentSelected}
            entityTypeFilter={entityTypeFilter}
            resetSection={resetSection}
            datasetLabels={datasetLabels}
            updateQueryParameter={updateQueryParameter}
            type={type}
            trackMetadataFilterChange={trackMetadataFilterChange}
          />
        );
      case "user-tags":
        return (
          <BarDisplayContent
            data={sortedData}
            isDataOverflowing={isDataOverflowing}
            showNoMatchingData={showNoMatchingData}
            isContentSelected={isContentSelected}
            entityTypeFilter={entityTypeFilter}
            resetSection={resetSection}
            userTags={userTags}
            updateQueryParameter={updateQueryParameter}
            type={type}
            trackMetadataFilterChange={trackMetadataFilterChange}
          />
        );
      case "issue-types":
        return (
          <BarDisplayContent
            data={sortedData}
            isDataOverflowing={isDataOverflowing}
            showNoMatchingData={showNoMatchingData}
            isContentSelected={isContentSelected}
            entityTypeFilter={entityTypeFilter}
            resetSection={resetSection}
            issueTypes={issueTypes}
            updateQueryParameter={updateQueryParameter}
            type={type}
            trackMetadataFilterChange={trackMetadataFilterChange}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div
      className={styles.contentBody}
      style={isContentSelected ? { height: "calc(100% - 40px)" } : undefined}
    >
      {showContentForType(type)}
    </div>
  );
};

export default ContentBody;

interface ContentBodyProps {
  title: string;
  type: MetadataBarTypes;
  data: Array<FilterItemCountsWithSelect>;
  showNoMatchingData: boolean;
  isContentSelected: boolean;
  entityTypeFilter: FilterOption[];
  resetSection: any;
  datasetLabels?: FilterOption[];
  userTags?: FilterOption[];
  issueTypes?: FilterOption[];
}
