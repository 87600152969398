import { useEffect } from "react";

import crossImg from "assets/img/modalBlackCross.svg";

import styles from "./style.module.scss";

function VLModal({
  resetUpgradeModalStates,
  resetModalStates,
  closeLimitModal,
  expandModalHeight,
  setShowModal,
  showModal,
  children,
}: any) {
  // eslint-disable-next-line
  const closeModal = () => {
    resetModalStates && resetModalStates();
    resetUpgradeModalStates && resetUpgradeModalStates();
    setShowModal && setShowModal(false);
    closeLimitModal && closeLimitModal();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={showModal ? styles.modalMainContainer : styles.hideModal}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={
            expandModalHeight
              ? styles.modalInnerContainerExpanded
              : styles.modalInnerContainer
          }
        >
          <div className={styles.closeBtn}>
            <button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                closeModal();
              }}
            >
              <img src={crossImg} alt="" />
            </button>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}
export default VLModal;
