import { ReactNode, useEffect, useRef } from "react";
import ChevronIcon from "assets/icons/ChevronIcon";
import { SetStateFunction } from "types";

import styles from "./style.module.scss";

interface FilterDropdownWrapperProps {
  title: string;
  children: ReactNode;
  filterSelectionText: string;
  additionalCount?: number;
  showDropdown: boolean;
  setShowDropdown: SetStateFunction<boolean>;
}

const FilterDropdownWrapper = ({
  title,
  children,
  additionalCount,
  filterSelectionText,
  showDropdown,
  setShowDropdown,
}: FilterDropdownWrapperProps) => {
  const filterContainerRef = useRef<HTMLDivElement>(null);

  const toggleDropDown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOutsideClick = (event: any) => {
    if (
      filterContainerRef.current &&
      !filterContainerRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    //eslint-disable-next-line
  }, [showDropdown]);

  return (
    <div
      className={styles.filterWrapper}
      onClick={toggleDropDown}
      ref={filterContainerRef}
    >
      <div className={styles.titleContainer}>
        <div className={styles.titleTextContainer}>
          <div className={styles.filtertitle}>
            {title}
            {filterSelectionText && ":"}
          </div>
          {filterSelectionText && (
            <div className={styles.firstSelection}>{filterSelectionText}</div>
          )}
          {!!additionalCount && (
            <div className={styles.additionalCount}>+{additionalCount}</div>
          )}
        </div>
        <div className={styles.filterIcon}>
          {<ChevronIcon color="#fff" directon={showDropdown ? "up" : "down"} />}
        </div>
      </div>
      {showDropdown && <div className={styles.filterBody}>{children}</div>}
    </div>
  );
};
export default FilterDropdownWrapper;
