import { useMemo, useRef, useState } from "react";
import {
  BiChevronDown,
  BiChevronUp,
  BiChevronRight,
  BiChevronLeft,
} from "react-icons/bi";
import { State } from "redux/store";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  setSelectedCarouselIndex,
  setSelectedLabels,
  setSelectedIssues,
  fetchImageInfo,
} from "redux/Modals/actions";
import { getSelectedCarouselIndex } from "redux/Modals/selectors";
import { OBJECT_ID_QUERY_PARAMETER } from "helpers/constants/miscellaneous";
import { formatNumberWithCommas } from "helpers/utility/formatters";
import { getQueryParameter } from "helpers/utility/utilities";

import styles from "./style.module.scss";

const TimestampTooltip = ({
  timestampText,
  containerDimensions,
}: TimestampTooltipProps) => {
  const getTooltipWidth = (text: string) => {
    const tempDiv = document.createElement("div");
    const styles = {
      width: "max-content",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 500,
      padding: "8px 16px",
      visibility: "hidden",
    };
    Object.assign(tempDiv.style, styles);
    tempDiv.textContent = text;

    document.body.appendChild(tempDiv);
    const width = tempDiv.offsetWidth;
    const height = tempDiv.offsetHeight;
    document.body.removeChild(tempDiv);

    return { height, width };
  };

  const tooltipStyles = (): any => {
    if (!!timestampText && !!containerDimensions) {
      const { height, width } = getTooltipWidth("Playing   " + timestampText);

      return {
        left: containerDimensions.x + containerDimensions.width / 2,
        top: containerDimensions.y - height - 20,
        transform: `translateX(-${width / 2}px)`,
        minWidth: `${timestampText.length}ch`,
      };
    } else {
      return { display: "none" };
    }
  };

  return (
    <div style={tooltipStyles()} className={styles.timestampTooltip}>
      <span className={styles.playingText}>Playing&nbsp;&nbsp;</span>
      <span className={styles.valuesText}>{timestampText}</span>
    </div>
  );
};

const ImageCarousel = ({
  isExpanded,
  setIsExpanded,
  navigationCluster,
  imageIndex,
  isContentLoading,
  timestamp,
  resetZoomLevel,
}: any) => {
  const [hoverId, setHoverId] = useState<null | number>(null);
  const [hoveredOnce, setHoveredOnce] = useState(false);
  const [containerDimensions, setContainerDimensions] = useState<any>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const selectedLabelID = getQueryParameter(OBJECT_ID_QUERY_PARAMETER);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const previews = navigationCluster.previews;
  const isLastIndex = imageIndex === previews.length - 1;
  const isFirstIndex = imageIndex === 0;
  const additionalClusterType = navigationCluster.type
    ? navigationCluster.type.charAt(0).toUpperCase() +
      navigationCluster.type.slice(1).toLowerCase()
    : "Images";

  const timestampText = timestamp
    ? new Date(timestamp * 1000).toISOString().substr(11, 8).replace(/^00:/, "")
    : "";

  const showTooltip =
    !!timestampText && containerDimensions && isExpanded && !hoveredOnce;

  const handleImageChange = (index: number) => {
    setHoveredOnce(true);
    resetZoomLevel();
    dispatch(setSelectedLabels([]));
    dispatch(setSelectedIssues([]));

    const newImg = previews[index];
    const imageID = newImg.type === "IMAGE" ? newImg.media_id : newImg.image_id;

    dispatch(fetchImageInfo(imageID, selectedLabelID) as any);

    if (newImg.type === "OBJECT") {
      searchParams.set(OBJECT_ID_QUERY_PARAMETER, newImg.media_id);
    } else {
      searchParams.delete(OBJECT_ID_QUERY_PARAMETER);
    }

    const path = `${location.pathname.split("image/")[0]}image/${imageID}`;
    const url = `${path}?${searchParams.toString()}`;
    navigate(url, { replace: true });
  };

  const selectNextImage = () => {
    handleImageChange(imageIndex + 1);
    dispatch(setSelectedCarouselIndex(imageIndex + 1));
  };

  const selectPreviousImage = () => {
    handleImageChange(imageIndex - 1);
    dispatch(setSelectedCarouselIndex(imageIndex - 1));
  };

  const handleImageSelect = (index: number) => {
    if (index === imageIndex) return;
    dispatch(setSelectedCarouselIndex(index));
    handleImageChange(index);
  };

  const handleBackButtonClick = () => {
    dispatch(setSelectedCarouselIndex(0));
    dispatch(setSelectedLabels([]));
    dispatch(setSelectedIssues([]));
  };

  const handleCarouselImageLoad = (index: number) => {
    const container = containerRef.current;
    if (imageIndex === index && container) {
      setContainerDimensions(container.getBoundingClientRect());
    }
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carouselHeader}>
        <div
          className={styles.carouselTitleBox}
          onClick={handleBackButtonClick}
        >
          <span className={styles.carouselText}>
            Additional Cluster {additionalClusterType}{" "}
            {`(${formatNumberWithCommas(previews.length)})`}
          </span>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.scrollButtons}>
            <div
              onClick={selectPreviousImage}
              className={styles.leftScroll}
              style={
                isFirstIndex || isContentLoading
                  ? { color: "#575B67", pointerEvents: "none" }
                  : undefined
              }
            >
              <BiChevronLeft size="1.55em" />
            </div>
            <div
              onClick={selectNextImage}
              className={styles.rightScroll}
              style={
                isLastIndex || isContentLoading
                  ? { color: "#575B67", pointerEvents: "none" }
                  : undefined
              }
            >
              <BiChevronRight size="1.55em" />
            </div>
          </div>
          <div className={styles.dropDownIcon} onClick={setIsExpanded}>
            {isExpanded ? (
              <BiChevronUp size="1.55em" />
            ) : (
              <BiChevronDown size="1.55em" />
            )}
          </div>
        </div>
      </div>
      {showTooltip && (
        <TimestampTooltip
          timestampText={timestampText}
          containerDimensions={containerDimensions}
        />
      )}
      <div
        className={styles.carouselBody}
        style={isExpanded ? undefined : { height: 0 }}
      >
        <div className={styles.carouselImages}>
          {previews.map((image: any, index: number) => {
            const carouselImageSource =
              image.type === "IMAGE" ? image.media_uri : image.media_thumb_uri;

            return (
              <div
                className={styles.carouselImageContainer}
                ref={index === imageIndex ? containerRef : undefined}
                key={index}
                onMouseEnter={() => {
                  setHoverId(index);
                  setHoveredOnce(true);
                }}
                onMouseLeave={() => setHoverId(null)}
                onClick={() => handleImageSelect(index)}
              >
                <img
                  className={styles.carouselImage}
                  src={carouselImageSource}
                  alt={image.file_name}
                  loading="lazy"
                  onLoad={() => handleCarouselImageLoad(index)}
                />
                {(index === hoverId || index === imageIndex) && (
                  <div className={styles.hoveredImage}></div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    imageIndex: getSelectedCarouselIndex(state),
  };
};
export default connect(mapStateToProps)(ImageCarousel);

interface TimestampTooltipProps {
  timestampText: string;
  containerDimensions: any;
}
