import { useDispatch } from "react-redux";
import { BsArrowRightCircleFill } from "react-icons/bs";

import { YELLOW } from "helpers/constants/colors";

import { setShowFreeModal, setShowUpgradeModal } from "redux/Modals/actions";

import VLModal from "views/uikit/VLModal";
import FreePlanDataset from "./FreePlanDataset";

import styles from "./style.module.scss";

function FreePlanModal({ showModal }: any) {
  const dispatch = useDispatch();
  return (
    <VLModal
      showModal={showModal}
      setShowModal={(value: boolean) => dispatch(setShowFreeModal(value))}
    >
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          {/* Free plan User first modal heading */}
          <BsArrowRightCircleFill
            className={styles.arrowRight}
            color={YELLOW}
          />
          <span className={styles.freePlan}>You are on the Free Plan</span>
        </div>

        <div className={styles.modalBody}>
          {/* Free User First Modal */}
          <FreePlanDataset
            showModal={showModal}
            setShowModal={(value: boolean) => dispatch(setShowFreeModal(value))}
            setShowUpgradeModal={(value: boolean) =>
              dispatch(setShowUpgradeModal(value))
            }
          />
        </div>
      </div>
    </VLModal>
  );
}
export default FreePlanModal;
