import classNames from "classnames";
import { State } from "redux/store";
import { useLocation, useNavigate } from "react-router";
import { connect, useDispatch } from "react-redux";
import { useMemo } from "react";
import { setDatasetLabels } from "redux/SingleDataset/actions";
import {
  getDatasetLabels,
  getFilteredSimilarityClusters,
  getNavigationCluster,
} from "redux/SingleDataset/selectors";
import { amplitudeTrack } from "helpers/utility/amplitude";
import { AMP_DATASET_EXPLORATION_EVENT__ENTITY__TOP_LABEL__CLICKED } from "helpers/constants/amplitudeEvents";
import {
  AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE,
  AMP_DATASET_EXPLORATION_PROPERTY__TARGET,
} from "helpers/constants/amplitudeProperties";
import { LABELS_PARAMETER } from "helpers/constants/miscellaneous";

import { IoMdInformationCircleOutline } from "react-icons/io";
import TooltipWrapper from "views/uikit/TooltipWrapper";
import ObjectIcon from "assets/icons/ObjectIcon";
import FrameIcon from "assets/icons/frameIcon";
import ImageIcon from "assets/icons/imageIcon";
import TagIcon from "assets/icons/tagIcon";
import { FilterOption } from "types";

import styles from "./style.module.scss";

const SingleLabel = ({
  label,
  isCardSelected,
  isExportSelected,
  datasetLabels,
  handleSingleLabelClick,
}: any) => {
  const existingText = datasetLabels.find(
    (l: FilterOption) => l.text === label
  );
  const isLabelSelected = !!existingText?.selected;

  const labelClassName = classNames({
    [styles.selectedSingleLabelForSimilarity]:
      isCardSelected && isLabelSelected,
    [styles.singleLabelForSimilarity]: isCardSelected && !isLabelSelected,
    [styles.selectedSingleLabelForExport]:
      isExportSelected && !isCardSelected && isLabelSelected,
    [styles.singleLabelForExport]:
      isExportSelected && !isCardSelected && !isLabelSelected,
    [styles.selectedSingleLabel]:
      !isCardSelected && !isExportSelected && isLabelSelected,
    [styles.singleLabel]:
      !isCardSelected && !isExportSelected && !isLabelSelected,
  });

  return (
    <div
      className={labelClassName}
      onClick={() => handleSingleLabelClick(label)}
      title={label}
    >
      <span>{label}</span>
    </div>
  );
};

const DescriptionBox = ({
  visibleLabels,
  getDynamicColor,
  isCardSelected,
  isExportSelected,
  datasetLabels,
  handleSingleLabelClick,
}: any) => {
  return (
    <div className={styles.descriptionBox}>
      <TagIcon color={getDynamicColor()} />

      <div className={styles.labelsContainer}>
        {visibleLabels.map((label: any, index: number) => {
          return (
            <SingleLabel
              key={index}
              label={label}
              isCardSelected={isCardSelected}
              isExportSelected={isExportSelected}
              datasetLabels={datasetLabels}
              handleSingleLabelClick={handleSingleLabelClick}
            />
          );
        })}
        {visibleLabels.length < 1 && (
          <span
            className={styles.noLabelsText}
            style={{ color: getDynamicColor() }}
          >
            {"No labels available"}
          </span>
        )}
      </div>
    </div>
  );
};

const Header = ({
  index,
  isCardSelected,
  singleCardLabels,
  isExportSelected,
  type,
  datasetLabels,
  clusterSize,
  assetData,
  clearAssetData,
}: HeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const fileName = assetData.video_uri
    ? assetData.video_uri.split("/")[assetData.video_uri.split("/").length - 1]
    : assetData.file_name;

  const visibleLabels = singleCardLabels.slice(0, 3);

  const showObjectInfo = assetData.type === "OBJECT" && !assetData.video_uri;

  const handleSingleLabelClick = (value: string) => {
    const selectedLabel = datasetLabels.find(
      (option: FilterOption) => option.text === value
    );

    if (!!selectedLabel) {
      const newDatasetLabels = [
        ...datasetLabels.filter(
          (option: FilterOption) => option.text !== value
        ),
        { ...selectedLabel, selected: !selectedLabel?.selected },
      ];

      const result = newDatasetLabels
        .filter((option) => option.selected === true)
        .map((option) => option.text);

      if (result.length > 0) {
        searchParams.set(LABELS_PARAMETER, JSON.stringify(result));
      } else {
        searchParams.delete(LABELS_PARAMETER);
      }
      navigate({ search: `?${searchParams.toString()}` });

      dispatch(setDatasetLabels(newDatasetLabels) as any);
    }

    //tarck top label clicked
    let properties: any;
    let target = "";

    switch (type) {
      case "IMAGES":
        if (clusterSize > 1) target = "Cluster";
        else target = "Image";
        break;
      case "OBJECTS":
        if (clusterSize > 1) target = "Cluster";
        else target = "Object";
        break;
      case "IMAGE":
        target = "IMAGE";
        break;
      case "OBJECT":
        target = "OBJECT";
        break;
    }

    if (!!target) {
      properties = {
        [AMP_DATASET_EXPLORATION_PROPERTY__TARGET]: target,
      };
    }
    if (clusterSize > 1) {
      properties = {
        ...properties,
        [AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE]: clusterSize,
      };
    }
    amplitudeTrack(
      AMP_DATASET_EXPLORATION_EVENT__ENTITY__TOP_LABEL__CLICKED,
      properties
    );

    clearAssetData();
  };

  const getDynamicColor = () => {
    if (isExportSelected && isCardSelected) return "#fff";
    else if (isExportSelected) return "#151928";
  };

  const getDynamicTitleClass = () => {
    if (isExportSelected && isCardSelected) return styles.title;
    else if (isExportSelected) return styles.selectedTitle;
    else return styles.title;
  };

  const renderTitle = () => {
    const titleHeaderClassNames = classNames(styles.titleHeader, {
      [styles.higherZIndex]: isCardSelected || isExportSelected,
    });

    const renderIcon = () => {
      if (assetData.video_uri) {
        return <FrameIcon color={getDynamicColor()} />;
      } else if (assetData.type === "IMAGE") {
        return <ImageIcon color={getDynamicColor()} />;
      } else if (assetData.type === "OBJECT") {
        return <ObjectIcon color={getDynamicColor()} />;
      }
    };

    const renderText = () => {
      if (assetData.video_uri) {
        const text = `${assetData.file_name}, part of ${fileName}`;
        return (
          <div className={styles.videoTitle}>
            <span className={getDynamicTitleClass()} title={text}>
              {text}
            </span>
            <a
              href={assetData.video_uri}
              className={styles.linkToVideo}
              target="_blank"
              rel="noreferrer"
            >
              Link to video
            </a>
          </div>
        );
      } else {
        return (
          <span className={getDynamicTitleClass()} title={fileName}>
            {fileName}
          </span>
        );
      }
    };

    const renderObjectInfo = () => {
      if (!showObjectInfo) {
        return <noscript />;
      }

      let objectSizeLine = null;

      if (assetData.bounding_box) {
        const [, , width, height] = assetData.bounding_box;
        const objectSizeText = `${height}x${width}`;

        objectSizeLine = <span>{`object_size: ${objectSizeText}`}</span>;
      }

      const content = (
        <div className={styles.objectToolTipTextContainer}>
          <span>{`object_id: ${assetData.media_id}`}</span>
          {objectSizeLine}
        </div>
      );

      return (
        <div
          className={styles.infoIcon}
          data-tooltip-id={"asset-object-info-tooltip" + index}
        >
          <IoMdInformationCircleOutline size={"1em"} />
          <TooltipWrapper
            maxWidth="max-content"
            id={"asset-object-info-tooltip" + index}
            content={content}
          />
        </div>
      );
    };

    return (
      <div className={titleHeaderClassNames}>
        <div className={styles.titleBox}>
          {renderIcon()}
          {renderText()}
          {renderObjectInfo()}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.cardHead}>
      <div className={styles.cardHeadTitleContainer}>{renderTitle()}</div>

      <DescriptionBox
        visibleLabels={visibleLabels}
        isCardSelected={isCardSelected}
        isExportSelected={isExportSelected}
        getDynamicColor={getDynamicColor}
        datasetLabels={datasetLabels}
        handleSingleLabelClick={handleSingleLabelClick}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    datasetLabels: getDatasetLabels(state),
    clusters: getFilteredSimilarityClusters(state),
    navigationCluster: getNavigationCluster(state),
  };
};

export default connect(mapStateToProps)(Header);

type HeaderProps = {
  isCardSelected: boolean;
  singleCardLabels: any;
  clusters: any;
  isExportSelected: boolean;
  type: any;
  isDataPage: boolean;
  isClusterPage: boolean;
  navigationCluster: any;
  isSingleEntityOnDataPage?: boolean;
  datasetLabels: FilterOption[];
  clusterSize: number;
  fileType: "image" | "video" | null;
  assetData: any;
  clearAssetData: any;
  index: number;
};
