import { RotatingLines } from "react-loader-spinner";
import { DARK_BLUE } from "helpers/constants/colors";

const Loader = () => {
  return (
    <div>
      <RotatingLines
        strokeColor={DARK_BLUE}
        strokeWidth="5"
        animationDuration="0.75"
        width="90"
        visible={true}
      />
    </div>
  );
};

export default Loader;
