import { Tooltip } from "react-tooltip";
import classNames from "classnames";

import styles from "./style.module.scss";

type TooltipProps = {
  id: string;
  maxWidth?: string;
  isOpen?: boolean;
  content?: any;
  customClass?: "" | "cardActionButton" | "totalQuotaTooltip";
  children?: React.ReactNode;
  isClickable?: boolean;
  setIsOpen?: (value: boolean) => void;
};

const TooltipWrapper = ({
  id,
  maxWidth,
  isOpen,
  setIsOpen,
  content,
  customClass = "",
  children = null,
  isClickable = false,
}: TooltipProps) => {
  const dynamicClassName = classNames(styles.defaultClass, {
    [styles[customClass]]: !!customClass,
  });

  return (
    <Tooltip
      className={dynamicClassName}
      id={id}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      content={content}
      style={maxWidth ? { maxWidth } : undefined}
      clickable={isClickable}
    >
      {children}
    </Tooltip>
  );
};
export default TooltipWrapper;
