import {
  SHOW_SIGN_UP_SUCCESS_MODAL,
  SHOW_IMAGE_OPTIONS_MODAL,
  SHOW_MOBILE_ERROR_MODAL,
  SHOW_INVITATION_MODAL,
  SHOW_PROGRESS_MODAL,
  SHOW_PROFILE_MODAL,
  SHOW_UPGRADE_MODAL,
  SHOW_SIGN_UP_MODAL,
  SHOW_FREE_MODAL,
  SHOW_PRO_MODAL,
  SHOW_MODAL,
  SET_IMAGE_DATA,
  SET_IMAGE_DATA_LOADER,
  SET_IMAGE_LABELS,
  SET_IMAGE_ISSUES,
  SET_SELECTED_LABELS,
  SET_SELECTED_ISSUES,
  SET_SELECTED_CAROUSEL_INDEX,
  SET_IMAGE_TAGS,
  IS_IMAGE_TAG_LOADING,
  SHOW_DEMO_REQUEST_MODAL,
  SHOW_DEMO_REQUEST_SUCCESS_MODAL,
  SHOW_LEARN_MORE_MODAL,
  SHOW_DATASET_ERROR_MODAL,
  SHOW_INTERNAL_ERROR_MODAL,
  SET_QUOTA_LIMIT_MODAL_DATA,
  SHOW_SELECT_PLAN_MODAL,
} from "./constants";

const INITIAL_STATE = {
  showSignUpSuccessModal: false,
  showImageOptionsModal: false,
  showMobileErrorModal: false,
  showInvitationModal: false,
  showProgressModal: false,
  showUpgradeModal: false,
  showProfileModal: false,
  showSignUpModal: false,
  showFreeModal: false,
  showProModal: false,
  showModal: false,

  showDatasetErrorModal: null,

  imageData: null,
  imageDataLoader: false,
  imageLabels: [],
  imageIssues: [],
  selectedLabels: [],
  selectedIssues: [],
  selectedCarouselIndex: 0,

  imageTags: [],
  isImageTagLoading: false,

  showRequestDemoModal: { flag: false, calledFrom: "" },
  showRequestDemoSuccessModal: false,
  showLearnMoreModal: false,

  showInternalErrorModal: false,
  quotaLimitModalData: { show: false, attemptedImageCount: 0 },
  selectPlanModalData: { show: false, calledFrom: "" },
};
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SHOW_MOBILE_ERROR_MODAL:
      return { ...state, showMobileErrorModal: action.data };

    case SHOW_SIGN_UP_SUCCESS_MODAL:
      return { ...state, showSignUpSuccessModal: action.data };

    case SHOW_INVITATION_MODAL:
      return { ...state, showInvitationModal: action.data };

    case SHOW_PROFILE_MODAL:
      return { ...state, showProfileModal: action.data };

    case SHOW_UPGRADE_MODAL:
      return { ...state, showUpgradeModal: action.data };

    case SHOW_SIGN_UP_MODAL:
      return { ...state, showSignUpModal: action.data };

    case SHOW_FREE_MODAL:
      return { ...state, showFreeModal: action.data };

    case SHOW_PRO_MODAL:
      return { ...state, showProModal: action.data };

    case SHOW_IMAGE_OPTIONS_MODAL:
      return { ...state, showImageOptionsModal: action.data };

    case SHOW_PROGRESS_MODAL:
      return { ...state, showProgressModal: action.data };

    case SHOW_MODAL:
      return { ...state, showModal: action.data };

    case SET_IMAGE_DATA:
      return { ...state, imageData: action.data };

    case SET_IMAGE_DATA_LOADER:
      return { ...state, imageDataLoader: action.data };

    case SET_IMAGE_ISSUES:
      return { ...state, imageIssues: action.data };

    case SET_SELECTED_ISSUES:
      return { ...state, selectedIssues: action.data };

    case SET_IMAGE_LABELS:
      return { ...state, imageLabels: action.data };

    case SET_SELECTED_LABELS:
      return { ...state, selectedLabels: action.data };

    case SET_SELECTED_CAROUSEL_INDEX:
      return { ...state, selectedCarouselIndex: action.data };

    case SET_IMAGE_TAGS:
      return { ...state, imageTags: action.data };
    case IS_IMAGE_TAG_LOADING:
      return { ...state, isImageTagLoading: action.data };

    case SHOW_DEMO_REQUEST_MODAL:
      return { ...state, showRequestDemoModal: action.data };

    case SHOW_DEMO_REQUEST_SUCCESS_MODAL:
      return { ...state, showRequestDemoSuccessModal: action.data };

    case SHOW_LEARN_MORE_MODAL:
      return { ...state, showLearnMoreModal: action.data };

    case SHOW_DATASET_ERROR_MODAL:
      return { ...state, showDatasetErrorModal: action.data };

    case SHOW_INTERNAL_ERROR_MODAL:
      return { ...state, showInternalErrorModal: action.data };

    case SET_QUOTA_LIMIT_MODAL_DATA:
      return { ...state, quotaLimitModalData: action.data };

    case SHOW_SELECT_PLAN_MODAL:
      return { ...state, selectPlanModalData: action.data };

    default:
      return state;
  }
};

export default reducer;
