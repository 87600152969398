import classNames from "classnames";
import { useState } from "react";

import NoResultsIcon from "assets/img/no-results-icon.svg";
import styles from "./style.module.scss";

export const NoMatchFoundCard = ({
  handleClearFilters,
  isInventory,
}: NoMatchFoundCardProps) => {
  const [loaded, setLoaded] = useState(false);

  const noResultsBoxClassnames = classNames(styles.noResultsBox, {
    [styles.noResultsInInventory]: isInventory,
  });

  return (
    <div className={noResultsBoxClassnames}>
      <div className={styles.iconContainer}>
        <img
          src={NoResultsIcon}
          style={!loaded ? { display: "none" } : undefined}
          alt="no results icon"
          onLoad={() => setLoaded(true)}
        />
      </div>

      <div className={styles.textContainer}>
        <div className={styles.title}>No results found</div>
        <span className={styles.description}>
          Please check your search query for spelling errors or expand the
          search/filtering criteria and try again.
        </span>
        <div className={styles.clearFiltersButton} onClick={handleClearFilters}>
          Clear Filters
        </div>
      </div>
    </div>
  );
};

interface NoMatchFoundCardProps {
  handleClearFilters: () => void;
  isInventory?: boolean;
}

export const NoMatchFoundPlaceholder = ({
  handleClearFilters,
}: NoMatchFoundCardProps) => {
  return (
    <div className={styles.noMatchPlaceholderContainer}>
      <div className={styles.title}>No results found</div>
      <span className={styles.description}>
        Please select a different image
      </span>
      <div className={styles.clearFiltersButton} onClick={handleClearFilters}>
        Clear Filters
      </div>
    </div>
  );
};
