import {
  getRootDatasetsPath,
  getRootDatasetTitle,
} from "helpers/constants/paths";
import {
  CLEAR_EXPORT_DATA,
  ERROR_TEXT,
  IS_CONTENT_LOADING,
  IS_DISCLAIMER_SHOWN,
  IS_ERROR,
  IS_LOADING,
  LIMIT_ERROR,
  SET_BREADCRUMBS,
  SET_DATASETS,
  SET_DATASETS_SORTING_VALUE,
  SET_DATASET_ID,
  SET_DATASET_PROGRESS,
  SET_DELETE_MODAL_ID,
  SET_HEALTH_STATUS,
  SET_PUBLIC_PARAMETER,
  SET_SAMPLE_DATA,
  SET_SAMPLE_DATASET,
  SET_SELECTED_SAMPLE_DATASET,
  SET_SELECTED_IMAGE_COUNT,
  SET_SELECTED_EXPORT_IDS,
  SET_DUPLICATE_DATASET,
} from "./constants";

const INITIAL_STATE = {
  breadcrumbs: [{ name: getRootDatasetTitle(), path: getRootDatasetsPath() }],

  datasetId: null,
  datasets: [],
  datasetsSortingValue: "Creation Date",
  deleteModalId: null,
  errorText: null,
  healthStatus: null,
  isContentLoading: false,
  isDisclaimerShown: false,
  isError: false,
  isLoading: false,
  limitError: null,
  publicParameter: "",
  sampleData: null,
  selectedSampleDataset: null,
  selectedExportIDs: {},
  selectedImageCount: 0,
  datasetIdToDuplicate: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_DATASETS:
      const uploadingDatasets = action.data.filter(
        (dataset: any) => dataset.status === "UPLOADING"
      );
      uploadingDatasets.forEach((dataset: any) => {
        const stateDs = state.datasets.find(
          (stateDataset: any) => stateDataset.id === dataset.id
        );
        dataset.progress = stateDs
          ? (stateDs as any).progress
          : dataset.progress;
      });
      return { ...state, datasets: action.data };

    case LIMIT_ERROR:
      return { ...state, limitError: action.data };

    case IS_LOADING:
      return { ...state, isLoading: action.data };

    case IS_CONTENT_LOADING:
      return { ...state, isContentLoading: action.data };

    case IS_DISCLAIMER_SHOWN:
      return { ...state, isDisclaimerShown: action.data };

    case SET_SAMPLE_DATA:
      return { ...state, sampleData: action.data };

    case SET_DATASET_ID:
      return { ...state, datasetId: action.data };

    case SET_DATASET_PROGRESS:
      const dataset: any = state.datasets?.find(
        (dataset: any) => dataset.id === action.datasetId
      );
      if (dataset === undefined) {
        return state;
      }

      let status = dataset.status;
      if (action.progress === 100) {
        status = "SAVING";
      }

      return {
        ...state,
        datasets: [
          { ...(dataset as any), progress: action.progress, status },
          ...state.datasets.filter(
            (dataset: any) => dataset.id !== action.datasetId
          ),
        ],
      };

    case SET_SELECTED_SAMPLE_DATASET:
      return { ...state, selectedSampleDataset: action.data };

    case IS_ERROR:
      return { ...state, isError: action.data };

    case SET_SAMPLE_DATASET:
      return { ...state, dataset: action.data };

    case ERROR_TEXT:
      return { ...state, errorText: action.data };

    case SET_BREADCRUMBS:
      return { ...state, breadcrumbs: action.data };

    case SET_DATASETS_SORTING_VALUE:
      return { ...state, datasetsSortingValue: action.data };

    case SET_DELETE_MODAL_ID:
      return { ...state, deleteModalId: action.data };

    case SET_HEALTH_STATUS:
      return { ...state, healthStatus: action.data };

    case SET_PUBLIC_PARAMETER:
      return { ...state, publicParameter: action.data };

    case SET_SELECTED_EXPORT_IDS:
      return { ...state, selectedExportIDs: action.data };

    case SET_SELECTED_IMAGE_COUNT:
      return { ...state, selectedImageCount: action.data };

    case CLEAR_EXPORT_DATA:
      return { ...state, selectedExportIDs: {}, selectedImageCount: 0 };

    case SET_DUPLICATE_DATASET:
      return { ...state, datasetIdToDuplicate: action.data };

    default:
      return state;
  }
};

export default reducer;
