import freePlanImg from "assets/img/freePlan.svg";
import styles from "./style.module.scss";

function FreePlanDataset({
  showModal,
  setShowModal,
  setShowUpgradeModal,
}: any) {
  const handleClick = () => {
    setShowUpgradeModal(true);
    setShowModal(false);
  };
  return (
    <div
      className={showModal ? styles.selectSourceType : styles.hideSourceType}
    >
      <div className={styles.basicDetailsTop}>
        <span className={styles.upgrade}>Upgrade is easy</span>
      </div>
      <div className={styles.desc}>
        <span className={styles.description}>
          In this plan, you can create up to 10 datasets, each containing up to
          100k images. to remove these limitations and unlock more features,
          submit an upgrade inquiry
        </span>
      </div>
      <div>
        <div className={styles.basicDetailsBelow}>
          <div className={styles.freePlanImg}>
            <img src={freePlanImg} alt="" />
          </div>
          <div className={styles.nextBtnContainer}>
            <button className={styles.nextBtn} onClick={handleClick}>
              Upgrade
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FreePlanDataset;
