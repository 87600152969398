import { State } from "redux/store";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { PRIVATE_DATASETS_PATH } from "helpers/constants/paths";
import { getIsFatalError } from "redux/CreateDataset/selectors";

import DangerCircleIcon from "assets/icons/DangerCircleIcon";
import styles from "./style.module.scss";

const FatalError = ({ isFatalError }: FatalErrorProps) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(PRIVATE_DATASETS_PATH);
  };

  return isFatalError ? (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.containrHeader}>
          <DangerCircleIcon variant="filled" color="#D6493E" />
          <span className={styles.containrHeaderTitle}>
            Incorrect Dataset ID
          </span>
        </div>

        <div className={styles.containrBody}>
          The dataset for given id does not exist. Please try a different
          dataset.
        </div>
        <div className={styles.goBackBtn} onClick={handleGoBack}>
          Go Back
        </div>
      </div>
    </div>
  ) : (
    <noscript />
  );
};

const mapStateToProps = (state: State) => {
  return { isFatalError: getIsFatalError(state) };
};

export default connect(mapStateToProps)(FatalError);

interface FatalErrorProps {
  isFatalError: boolean;
}
