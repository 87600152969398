import React from "react";
import styles from "./style.module.scss";
import classNames from "classnames";

import plusButton from "assets/img/plus.svg";
import minusButton from "assets/img/minus.svg";
import zoomControl from "assets/img/zoom-control.svg";

const MIN_RANGE = 100;
const MAX_RANGE = 500;
const ZoomControl: React.FC<ZoomControlProps> = ({
  zoomLevel,
  onZoomLevelChange,
  onCenterImageClick,
  className,
}) => {
  const handleMinusClick = () => {
    onZoomLevelChange(Math.max(MIN_RANGE, zoomLevel - 10));
  };

  const handlePlusClick = () => {
    onZoomLevelChange(Math.min(MAX_RANGE, zoomLevel + 10));
  };

  const handleZoomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onZoomLevelChange(Number(e.target.value));
  };

  const handleIconClick = () => {
    onZoomLevelChange(100);
    onCenterImageClick();
  };

  return (
    <div className={classNames(styles.zoomControl, className)}>
      <img
        className={styles.zoomIcon}
        src={zoomControl}
        alt="zoom-control"
        onClick={handleIconClick}
      />
      <img
        src={minusButton}
        alt="minus-button"
        onClick={handleMinusClick}
        className={styles.zoomButton}
      />
      <input
        type="range"
        min={MIN_RANGE}
        max={MAX_RANGE}
        value={zoomLevel}
        onChange={handleZoomChange}
        className={styles.zoomRange}
      />
      <img
        src={plusButton}
        alt="plus-button"
        onClick={handlePlusClick}
        className={styles.zoomButton}
      />
    </div>
  );
};

export default ZoomControl;

interface ZoomControlProps {
  zoomLevel: number;
  onZoomLevelChange: (zoomLevel: number) => void;
  className?: string;
  onCenterImageClick: () => void;
}
