import { BRIGHT_BLUE } from "helpers/constants/colors";
import { LABELS_PARAMETER } from "helpers/constants/miscellaneous";
import { DATA_PAGE } from "helpers/constants/pages";
import { trackFilterSelection } from "helpers/utility/amplitude";
import { useDispatch } from "react-redux";
import { setDatasetLabels } from "redux/SingleDataset/actions";
import { FilterBehavior, FilterOption } from "types";
import VLAccordian from "views/components/Accordian";
import MultiSelectDropDown from "views/pages/DataPage/CardsView/DataPageFilters/MultiSelectDropDown";

const DataPageLabelFilters = ({
  datasetId,
  datasetLabels,
  isLabelLoading,
  behavior,
  resetImageSearch,
  updateQueryParameter,
  entityTypeFilter,
}: {
  datasetId: string;
  datasetLabels: Array<FilterOption>;
  isLabelLoading: boolean;
  behavior: FilterBehavior;
  resetImageSearch: () => void;
  entityTypeFilter: any;
  updateQueryParameter: (options: FilterOption[], parameter: string) => void;
}) => {
  const dispatch = useDispatch();
  const clearLabels = () => {
    const unselectedLabels = datasetLabels.map((option: FilterOption) => ({
      ...option,
      selected: false,
    }));
    updateQueryParameter(unselectedLabels, LABELS_PARAMETER);
    dispatch(setDatasetLabels(unselectedLabels));
    resetImageSearch();
  };
  const selectedLabels = datasetLabels.filter(
    (option: FilterOption) => option.selected
  );
  const isLabelSelected = selectedLabels.length > 0;
  const selectedLabelsId = selectedLabels.map(
    (option: FilterOption) => option.id
  );
  const selectedLabelsText = selectedLabels.map(
    (option: FilterOption) => option.text
  );
  const firstLabelText =
    selectedLabelsText.length > 0 ? selectedLabelsText[0] : "";
  const labelsCountDisplayNumber =
    selectedLabels?.length > 1 ? selectedLabels?.length - 1 : 0;

  const handleFilterSelection = (value: number, type: any) => {
    const selectedLabel = datasetLabels.find(
      (option: FilterOption) => option.id === value
    );
    if (selectedLabel?.selected) {
      trackFilterSelection("Labels", "Dataset", entityTypeFilter, "Removed");
    } else {
      trackFilterSelection("Labels", "Dataset", entityTypeFilter, "Added");
    }

    const newDatasetLabels = [
      ...datasetLabels.filter((option: FilterOption) => option.id !== value),
      { ...selectedLabel, selected: !selectedLabel?.selected },
    ];

    updateQueryParameter(newDatasetLabels as any, LABELS_PARAMETER);
    dispatch(setDatasetLabels(newDatasetLabels) as any);
    resetImageSearch();
  };

  return (
    <VLAccordian
      title="Labels"
      type="label"
      page={DATA_PAGE}
      customWidth={"max-content"}
      hideInfo={true}
      initiallyClosed={true}
      fontSize={"13px"}
      bgColor={isLabelSelected ? BRIGHT_BLUE : "#151928"}
      borderRadius="5px"
      padding={"0 10px"}
      height="40px"
      border={isLabelSelected ? "1px solid #fff" : undefined}
      selectionCount={labelsCountDisplayNumber}
      firstSelectionName={firstLabelText}
      behavior={behavior}
      tooltipContent={
        isLabelSelected
          ? ""
          : `Apply this filter to display only the data entries tagged with a specific Image or Object Label you're interested in.`
      }
      tooltipID="data-page-label-filter-box"
      tooltipWidth="203px"
    >
      <MultiSelectDropDown
        type={"label"}
        clear={clearLabels}
        options={datasetLabels}
        isLoading={isLabelLoading}
        selectedFilters={{ label: selectedLabelsId }}
        handleFilterSelection={handleFilterSelection}
      />
    </VLAccordian>
  );
};
export default DataPageLabelFilters;
