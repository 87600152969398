import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { State } from "redux/store";

import { useLocation, useNavigate } from "react-router";
import {
  ENTITY_TYPE_PARAMETER,
  OBJECT_ID_QUERY_PARAMETER,
  VERTEX_ID,
  VERTEX_TYPE,
  PAGE_PARAMETER,
} from "helpers/constants/miscellaneous";
import { getUserConfig } from "redux/SingleDataset/selectors";
import { FIND_SIMILAR_OBJECT_FILTER } from "helpers/constants/filters";
import FindSimilarIcon from "assets/icons/FindSimilarIcon";
import classNames from "classnames";
import { singleImageObjectType } from "types";
import { addOpacity } from "helpers/utility/utilities";
import styles from "./style.module.scss";

const FindSimilarObjectButton = ({
  handleFindSimilarObject,
  hovering,
}: FindSimilarObjectButtonProps) => {
  const findSimilarButtonClassNames = classNames(
    hovering ? styles.findSimilarButton : styles.hiddenFindSimilarButton
  );

  return (
    <div className={styles.findSimilarButtonContainer}>
      <div
        className={findSimilarButtonClassNames}
        onClick={handleFindSimilarObject}
      >
        <div className={styles.findSimilarButtonIconContainer}>
          <FindSimilarIcon />
        </div>
      </div>
    </div>
  );
};

const BoundingBox = ({
  indexKey,
  filterConfig,
  imageDimensions,
  objectLabel,
  tooltipDimensions,
  isContentLoading,
  hoveredLabelKey,
  setHoveredLabelKey,
}: BoundingBoxProps) => {
  const [labelContainerStyle, setLabelContainerStyle] = useState<any>();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const boxRef = useRef<HTMLDivElement>(null);
  const labelContainerRef = useRef<HTMLDivElement>(null);

  const isHovering = hoveredLabelKey === indexKey;

  const findSimilarObjectConfig = filterConfig.find(
    (filter: any) => filter.feature_key === FIND_SIMILAR_OBJECT_FILTER
  );
  const showFindSimilarObject =
    findSimilarObjectConfig &&
    findSimilarObjectConfig.feature_behavior["ANY"] !== "HIDE";

  const showBox =
    !isContentLoading &&
    !!tooltipDimensions.height &&
    !!tooltipDimensions.width;

  const handleFindSimilarObject = () => {
    searchParams.delete(OBJECT_ID_QUERY_PARAMETER);
    searchParams.set(PAGE_PARAMETER, "1");

    searchParams.set(ENTITY_TYPE_PARAMETER, JSON.stringify(["Objects"]));
    searchParams.set(VERTEX_ID, objectLabel.id);
    searchParams.set(VERTEX_TYPE, "MEDIA");

    let path = location.pathname.split("/image")[0];
    path = path.concat(`?${searchParams.toString()}`);
    navigate(path);
  };

  const getTooltipWrapperStyle = () => {
    if (tooltipDimensions.height > 0 && tooltipDimensions.width > 0) {
      return {
        height: `${tooltipDimensions.height}px`,
        width: `${tooltipDimensions.width}px`,
        zIndex: isHovering ? 10 : undefined,
      };
    } else {
      return undefined;
    }
  };

  const boundingBoxStyle = (label: any, forTextTooltip: boolean) => {
    let heightRatio = 100;
    let widthRatio = 100;

    if (imageDimensions.height > 0 && imageDimensions.width > 0) {
      heightRatio = (tooltipDimensions.height / imageDimensions.height) * 100;
      widthRatio = (tooltipDimensions.width / imageDimensions.width) * 100;
    }

    if (label.boundingBox) {
      return {
        left: (label.boundingBox[0] * widthRatio) / 100,
        top: (label.boundingBox[1] * heightRatio) / 100,
        width: (label.boundingBox[2] * widthRatio) / 100,
        height: (label.boundingBox[3] * heightRatio) / 100,
        borderColor: forTextTooltip
          ? "transparent"
          : isHovering
          ? "#fff"
          : label.color,
        backgroundColor: forTextTooltip
          ? "transparent"
          : addOpacity(label.color, 0.3),
        display: showBox ? undefined : "none",
      };
    }

    return undefined;
  };

  const labelStyle = (label: any) => {
    let heightRatio = 100;
    let widthRatio = 100;

    if (imageDimensions.height > 0 && imageDimensions.width > 0) {
      heightRatio = (tooltipDimensions.height / imageDimensions.height) * 100;
      widthRatio = (tooltipDimensions.width / imageDimensions.width) * 100;
    }

    if (label.boundingBox) {
      return {
        left: (label.boundingBox[0] * widthRatio) / 100,
        top: (label.boundingBox[1] * heightRatio) / 100,
        width: (label.boundingBox[2] * widthRatio) / 100,
        height: (label.boundingBox[3] * heightRatio) / 100,
        display: showBox ? undefined : "none",
      };
    }

    return undefined;
  };

  const getLabelWidth = (text: string, styles: any) => {
    const tempDiv = document.createElement("div");
    Object.assign(tempDiv.style, styles);
    tempDiv.textContent = text;

    document.body.appendChild(tempDiv);
    const width = tempDiv.offsetWidth;
    document.body.removeChild(tempDiv);

    return width;
  };

  const calculateLabelPosition = (): any => {
    const boundingBoxDimensions = boundingBoxStyle(objectLabel, true); // Use bounding box dimensions
    const tooltipContainerDimensions = tooltipDimensions;

    if (boundingBoxDimensions && tooltipContainerDimensions) {
      const {
        height: bboxHeight,
        width: bboxWidth,
        top: bboxTop,
        left: bboxLeft,
      } = boundingBoxDimensions;
      const { width: tooltipWidth } = tooltipContainerDimensions;

      // Mock styles for label to get width before render
      const mockStyles = {
        visibility: "hidden",
        fontSize: "13px",
        fontFamily: "Inter",
        fontWeight: 700,
        padding: "5px",
        width: "max-content",
        height: "max-content",
      };
      if (objectLabel) {
        const labelWidth = getLabelWidth(objectLabel.name, mockStyles);
        const FIND_SIMILAR_ICON_WIDTH = 30;
        const LABEL_HEIGHT = 30;

        // Determine position values
        let position = {};
        let similarButtonPosition: "left" | "right" = "right";

        const spaceInRightOutsideBBox = tooltipWidth - bboxLeft >= labelWidth;
        const spaceInRightOutsideBBoxWithSimilarButtton =
          tooltipWidth - bboxLeft >= labelWidth + FIND_SIMILAR_ICON_WIDTH;
        const spaceInTopOutsideBBox = bboxTop > LABEL_HEIGHT + 2;

        const biggerWidthThanBBox = labelWidth > bboxWidth;
        const biggerHeightThanBBox = LABEL_HEIGHT > bboxHeight;

        if (biggerWidthThanBBox) {
          position = {
            ...(spaceInRightOutsideBBox ? { left: "-2px" } : { right: "-2px" }),
          };
          if (!spaceInRightOutsideBBoxWithSimilarButtton) {
            similarButtonPosition = "left";
          }
        } else {
          position = { ...position, left: "-2px" };
        }

        if (spaceInTopOutsideBBox) {
          position = { ...position, top: `-${LABEL_HEIGHT + 2}px` };
        } else {
          if (biggerHeightThanBBox) {
            position = { ...position, bottom: `-${LABEL_HEIGHT + 2}px` };
          } else {
            position = { ...position, top: `0px` };
          }
        }

        //position the similar button based on the
        labelContainerRef.current?.children[1]?.classList.add(
          similarButtonPosition === "left"
            ? styles.alignLeft
            : styles.alignRight
        );

        setLabelContainerStyle(position);
        return position;
      }
    }
    setLabelContainerStyle({ display: "none" });
    return { display: "none" };
  };

  useEffect(() => {
    calculateLabelPosition();
    //eslint-disable-next-line
  }, [tooltipDimensions]);

  return (
    <Fragment>
      <div className={styles.tooltipWrapper} style={getTooltipWrapperStyle()}>
        <div className={styles.tooltipConatiner}>
          <div
            className={styles.tooltipBox}
            ref={boxRef}
            style={boundingBoxStyle(objectLabel, false)}
          ></div>
        </div>
      </div>

      <div
        className={styles.textTooltipWrapper}
        style={getTooltipWrapperStyle()}
      >
        <div
          className={styles.textTooltipConatiner}
          style={labelStyle(objectLabel) as any}
          onMouseEnter={() => setHoveredLabelKey(indexKey)}
          onMouseLeave={() => setHoveredLabelKey("")}
        >
          <div
            className={styles.labelContainer}
            ref={labelContainerRef}
            style={labelContainerStyle}
          >
            <span className={styles.textLabel}>{objectLabel.name}</span>

            {showFindSimilarObject && (
              <FindSimilarObjectButton
                handleFindSimilarObject={handleFindSimilarObject}
                hovering={isHovering}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: State) => {
  return {
    filterConfig: getUserConfig(state),
  };
};

export default connect(mapStateToProps)(BoundingBox);

interface FindSimilarObjectButtonProps {
  handleFindSimilarObject: () => void;
  hovering: boolean;
}

interface BoundingBoxProps {
  indexKey: string;
  filterConfig: any;
  imageDimensions: any;
  objectLabel: singleImageObjectType;
  tooltipDimensions: any;
  isContentLoading: boolean;
  setHoveredLabelKey: any;
  hoveredLabelKey: string;
}
