import { DATA_PAGE } from "helpers/constants/pages";
import { BRIGHT_BLUE } from "helpers/constants/colors";
import VLAccordian from "views/components/Accordian";
import DropDown from "views/pages/DataPage/CardsView/DataPageFilters/DropDown";

const IssueTypeFilter = ({
  firstIssueType,
  issueTypeBehavior,
  datasetIssueTypes,
  selectedIssueTypes,
  isIssueTypeSelected,
  handleFilterSelection,
  issueTypesCountDisplayNumber,
}: any) => {
  return (
    <VLAccordian
      title="Issue Type"
      type="issueType"
      page={DATA_PAGE}
      customWidth={"max-content"}
      hideInfo={true}
      initiallyClosed={true}
      fontSize={"13px"}
      bgColor={isIssueTypeSelected ? BRIGHT_BLUE : "#151928"}
      borderRadius="5px"
      padding={"0 10px"}
      height="40px"
      border={isIssueTypeSelected ? "1px solid #fff" : undefined}
      selectionCount={issueTypesCountDisplayNumber}
      firstSelectionName={firstIssueType}
      behavior={issueTypeBehavior}
      tooltipContent={
        isIssueTypeSelected
          ? ""
          : `Apply this filter to display only the data entries which have certain quality problems such as Duplicates, Mislabels and Outliers.`
      }
      tooltipID="data-page-issue-type-filter-box"
      tooltipWidth="278px"
    >
      <DropDown
        selectedFilters={{ issueType: selectedIssueTypes }}
        handleFilterSelection={handleFilterSelection}
        options={datasetIssueTypes}
        type={"issueType"}
      />
    </VLAccordian>
  );
};
export default IssueTypeFilter;
