import axios from "axios";
import { userLogout } from "redux/authReducer";
const LOGOUT_CODES = [401, 403];

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.code === "ERR_CANCELED") {
      return Promise.resolve();
    }
    //REMOVE CONDITION WHEN TWEAKING WITHOUT TOKENS IS NOT NEEDED ANYMORE
    if (process.env.REACT_APP_IS_AUTHENTICATION_DISABELED === "false") {
      if (error.response && LOGOUT_CODES.includes(error.response.status)) {
        userLogout();
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
