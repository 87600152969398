import { createSelector } from "@reduxjs/toolkit";
import { QuotaLimitModalData, SelectPlanModalData } from "types";

const state = (state: any) => state.modals;

export const getShowSignUpSuccessModal = createSelector(state, (state: any) => {
  return state.showSignUpSuccessModal;
});

export const getShowMobileErrorModal = createSelector(state, (state: any) => {
  return state.showMobileErrorModal;
});

export const getShowUpgradeModal = createSelector(state, (state: any) => {
  return state.showUpgradeModal;
});

export const getShowSignUpModal = createSelector(state, (state: any) => {
  return state.showSignUpModal;
});

export const getShowFreeModal = createSelector(state, (state: any) => {
  return state.showFreeModal;
});

export const getShowProfileModal = createSelector(state, (state: any) => {
  return state.showProfileModal;
});

export const getShowInvitationModal = createSelector(state, (state: any) => {
  return state.showInvitationModal;
});

export const getShowProModal = createSelector(state, (state: any) => {
  return state.showProModal;
});

export const getShowImageOptionsModal = createSelector(state, (state: any) => {
  return state.showImageOptionsModal;
});

export const getShowProgressModal = createSelector(state, (state: any) => {
  return state.showProgressModal;
});

export const getImageDataLoader = createSelector(state, (state: any) => {
  return state.imageDataLoader;
});

export const getImageData = createSelector(state, (state: any) => {
  return state.imageData;
});

export const getSelectedLabels = createSelector(state, (state: any) => {
  return state.selectedLabels;
});

export const getImageLabels = createSelector(state, (state: any) => {
  return state.imageLabels;
});

export const getImageIssues = createSelector(state, (state: any) => {
  return state.imageIssues;
});

export const getShowModal = createSelector(state, (state: any) => {
  return state.showModal;
});

export const getSelectedIssues = createSelector(state, (state: any) => {
  return state.selectedIssues;
});

export const getSelectedCarouselIndex = createSelector(state, (state: any) => {
  return state.selectedCarouselIndex;
});

export const getImageTags = createSelector(state, (state: any) => {
  return state.imageTags;
});

export const getImageTagLoading = createSelector(state, (state: any) => {
  return state.isImageTagLoading;
});

export const getShowRequestDemoModal = createSelector(state, (state: any) => {
  return state.showRequestDemoModal;
});

export const getShowRequestDemoSuccessModal = createSelector(
  state,
  (state: any) => {
    return state.showRequestDemoSuccessModal;
  }
);

export const getShowLearnMoreModal = createSelector(state, (state: any) => {
  return state.showLearnMoreModal;
});

export const getShowDatasetErrorModal = createSelector(state, (state: any) => {
  return state.showDatasetErrorModal;
});

export const getShowInternalErrorModal = createSelector(state, (state: any) => {
  return state.showInternalErrorModal;
});

export const getQuotaLimitModalData = createSelector(
  state,
  (state: any): QuotaLimitModalData => {
    return state.quotaLimitModalData;
  }
);

export const getSelectPlanModalData = createSelector(
  state,
  (state): SelectPlanModalData => {
    return state.selectPlanModalData;
  }
);
