import { BiCheck } from "react-icons/bi";
import { MouseEvent } from "react";
import classNames from "classnames";

import styles from "./style.module.scss";

interface SingleOptionProps {
  isSelected: boolean;
  showRadioButton: boolean;
  option: { id: string; text: string };
  handleSelection: (option: string) => void;
}

const SingleOption = ({
  showRadioButton,
  handleSelection,
  isSelected,
  option,
}: SingleOptionProps) => {
  const filterClassnames = classNames(styles.optionContainer, {
    [styles.selectedFilter]: isSelected,
  });

  const checkboxClassnames = classNames(
    showRadioButton ? styles.radioButton : styles.checkbox,
    {
      [styles.blueBackground]: isSelected,
    }
  );

  const icon = showRadioButton ? (
    <div className={styles.radioButtonIcon} />
  ) : (
    <BiCheck color="#fff" size="12px" />
  );

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    handleSelection(option.id);
  };
  return (
    <div onClick={handleClick} className={filterClassnames}>
      <div className={checkboxClassnames}>{isSelected && icon}</div>
      <div className={styles.text}>{option.text}</div>
    </div>
  );
};

interface FilterDropdownProps {
  selectedOptions: string[];
  allowMultiSelect?: boolean;
  selectedOption: string | null;
  defaultOptionText?: "All";
  options: { id: any; text: string }[];
  handleSelection: (id: string | null) => void;
}

const FilterDropdown = ({
  options,
  selectedOption,
  selectedOptions,
  handleSelection,
  allowMultiSelect,
  defaultOptionText,
}: FilterDropdownProps) => {
  const checkOptionSelected = (id: string) => {
    if (allowMultiSelect) {
      return selectedOptions?.includes(id) || false;
    } else {
      return selectedOption === id;
    }
  };

  const handleDefaultSelection = (e: MouseEvent) => {
    e.stopPropagation();
    handleSelection(defaultOptionText!);
  };

  const renderDefaultOption = () => {
    if (!defaultOptionText) {
      return <noscript />;
    }

    const isSelected = selectedOptions.length === options.length;
    return (
      <div
        className={styles.defaultOptionContainer}
        onClick={handleDefaultSelection}
      >
        <div
          className={classNames(styles.checkbox, {
            [styles.blueBackground]: isSelected,
          })}
        >
          {isSelected && <BiCheck color="#fff" size="12px" />}
        </div>
        <div className={styles.defaultOptionText}>{defaultOptionText}</div>
      </div>
    );
  };

  return (
    <div className={styles.dropdown}>
      {renderDefaultOption()}

      {options.map((option, index) => (
        <SingleOption
          key={option.text + index}
          option={option}
          handleSelection={handleSelection}
          isSelected={checkOptionSelected(option.id)}
          showRadioButton={!allowMultiSelect}
        />
      ))}
    </div>
  );
};

export default FilterDropdown;
