import { BsArrowRightCircleFill } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";

import { YELLOW } from "helpers/constants/colors";

import { getShowProModal } from "redux/Modals/selectors";
import { setShowProModal } from "redux/Modals/actions";
import { State } from "redux/store";

import VLModal from "views/uikit/VLModal";
import ProPlanUser from "./ProPlanUser";

import styles from "./style.module.scss";

function ProPlanModal({ showModal }: any) {
  const dispatch = useDispatch();
  return (
    <VLModal
      showModal={showModal}
      setShowModal={(value: boolean) => dispatch(setShowProModal(value))}
    >
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          {/* Pro plan User first modal heading */}
          <BsArrowRightCircleFill
            className={styles.arrowRight}
            color={YELLOW}
          />
          <span className={styles.freePlan}>You Are On The Pro Plan</span>
        </div>

        <div className={styles.modalBody}>
          <ProPlanUser
            showModal={showModal}
            setShowModal={(value: boolean) => dispatch(setShowProModal(value))}
          />
        </div>
      </div>
    </VLModal>
  );
}

const mapStatesToProps = (state: State) => {
  return {
    showModal: getShowProModal(state),
  };
};

export default connect(mapStatesToProps)(ProPlanModal);
