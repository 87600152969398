import ArrowIcon from "assets/icons/ArrowIcon";
import styles from "./style.module.scss";

const StartHereBanner = () => {
  return (
    <div className={styles.startHereCard}>
      <div className={styles.startHereCardHeader}>
        <div className={styles.startHereCardIcon}>
          <ArrowIcon color="#fff" />
        </div>
        <div className={styles.startHereCardTitle}>Start Here</div>
      </div>

      <div className={styles.startHereCardDescription}>
        Get a sneak peek of your data using various methods
      </div>
    </div>
  );
};

export default StartHereBanner;
