import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { UserInfo, UserQuota } from "types";

import {
  SET_USER_INFO,
  SET_VL_VERSION,
  SET_USER_CONFIG,
  SET_USER_QUOTA,
} from "./constants";
import { State } from "redux/store";

import { axiosGet, composeApiURL } from "helpers/utility/axiosHelpers";
import { initPendo } from "helpers/utility/pendo";

import { User } from "types";
import axios from "axios";
import { trackUserEmail } from "helpers/utility/amplitude";
import {
  isFeatureEnabled,
  transformFilterData,
} from "helpers/utility/utilities";
import { USAGE_REPORT } from "helpers/constants/filters";

const HOST_URL = process.env.REACT_APP_API_ENDPOINT!;

export const setUserInfo = (data: UserInfo) => {
  return { type: SET_USER_INFO, data };
};

export const setVlVersion = (data: string) => {
  return { type: SET_VL_VERSION, data };
};

export const setUserQuota = (data: UserQuota | null) => {
  return { type: SET_USER_QUOTA, data };
};

export const fetchUserInfo = (
  user: User
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(process.env.REACT_APP_API_ENDPOINT!, `/user`);
      let response = await axiosGet(url, user);
      let userInfo = {
        userAvatar: response.data.avatar_uri,
        isInternalUser: response.data.is_internal_user,
        email: response.data.email,
        userId: response.data.user_id,
      };
      if (response.data.email) {
        trackUserEmail(response.data.email);
      }
      dispatch(setUserInfo(userInfo));
      if (userInfo.isInternalUser) {
        dispatch(fetchVlVersion() as any);
      }

      initPendo(user, userInfo.email);
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchVlVersion = (): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/extended_version`
      );
      let response = await axios.get(url);
      let data = response.data;

      const lines = data.split("\n");
      let productVersion = "";

      for (const line of lines) {
        const [key, value] = line.split(":").map((text: string) => text.trim());

        // Check if the key is 'productVersion'
        if (key === "productVersion") {
          productVersion = value;
          break;
        }
      }

      dispatch(setVlVersion(productVersion));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setUserConfig = (data: any) => {
  return { type: SET_USER_CONFIG, data };
};

export const fetchUserConfig = (): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/user_config`
      );
      const response = await axios.get(url);

      const transformedData = transformFilterData(response.data?.features);
      dispatch(setUserConfig(transformedData));

      //get UsageInfo if Feature-Gating allows
      const isUsageReportEnabled = isFeatureEnabled(
        transformedData,
        USAGE_REPORT
      );

      if (isUsageReportEnabled) {
        dispatch(checkUserQuota() as any);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const checkUserQuota = (): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(HOST_URL, `/usage_report`);
      const { data: responseData } = await axios.get(url);

      if (responseData) {
        const quota: UserQuota = {
          accountType: responseData.account,
          totalQuota: responseData.image_quota,
          usedQuota: responseData.image_usage,
          availableQuota: responseData.remaining_quota,
          email: responseData.email,
        };

        dispatch(setUserQuota(quota));
      } else {
        dispatch(setUserQuota(null));
      }
    } catch (error: any) {
      console.log(error);
    }
  };
};
