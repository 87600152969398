import { useLocation, useNavigate } from "react-router";
import { connect, useDispatch } from "react-redux";
import { RefObject, useEffect, useRef, useState } from "react";

import { State } from "redux/store";
import { setSelectedCarouselIndex } from "redux/Modals/actions";
import {
  clearImagePageParamsContext,
  fetchClusterPageIssueTypes,
  fetchDatasetLabels,
  fetchUserTags,
  setClusterPageNumber,
  setNavigationCluster,
  setSimilarityData,
} from "redux/SingleDataset/actions";
import {
  getDatasetLabels,
  getExportData,
  getIsAssetPreviewEnabled,
  getVertexPreviewsCountContext,
} from "redux/SingleDataset/selectors";

import { amplitudeTrack } from "helpers/utility/amplitude";
import {
  AMP_DATASET_EXPLORATION_EVENT__ENTITY__URL_COPIED,
  AMP_DATASET_EXPLORATION_EVENT__ENTITY__OPENED,
  AMP_DATASET_EXPLORATION_EVENT__VISUAL_SEARCH__APPLIED,
} from "helpers/constants/amplitudeEvents";
import {
  AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE,
  AMP_DATASET_EXPLORATION_PROPERTY__CONTEXT,
  AMP_DATASET_EXPLORATION_PROPERTY__TARGET,
} from "helpers/constants/amplitudeProperties";

import {
  addQueryParameter,
  getMediaDimensions,
  getQueryParameter,
} from "helpers/utility/utilities";
import { DATA_PAGE } from "helpers/constants/pages";
import {
  OBJECT_ID_QUERY_PARAMETER,
  PAGE_PARAMETER,
  DATA_PAGE_PARAMETER,
  ENTITY_TYPE_PARAMETER,
  LOADING_OR_ERROR_CARD_DIMENSION_NUMBER,
  PUBLIC_PAGE_QUERY_PARAMETER_KEY,
  PUBLIC_PAGE_QUERY_PARAMETER_VALUE,
} from "helpers/constants/miscellaneous";
import TooltipWrapper from "views/uikit/TooltipWrapper";
import { EventTarget, FilterOption, SetStateFunction } from "types";

import FindSimilarIcon from "assets/icons/FindSimilarIcon";
import AssetPreviewCard from "../AssetPreviewCard";
import LinkIcon from "assets/icons/LinkIcon";
import CardHeader from "./CardHeader";
import CardBody from "./CardBody";

import styles from "./style.module.scss";
import classNames from "classnames";

interface SingleCardProps {
  index: number;
  singleData: any;
  datasetId: string;
  cardElementRef: any;
  containerRef: RefObject<HTMLDivElement>;
  page: string;
  isCardSelected: boolean;
  handleFindSimilarClick?: any;
  handleRemoveVertex?: () => void;
  exportData: any;
  clusterID: string;
  showExportOption: boolean;
  searchParams: any;
  isDataPage: boolean;
  isClusterPage: boolean;
  datasetLabels: FilterOption[];
  vertexPreviewCountContext: number;
  isAssetPreviewEnabled: boolean;
  indexOfPointerOut: number;
  setIndexOfPointerOut: SetStateFunction<number>;
}

const SingleCard = ({
  index,
  singleData,
  datasetId,
  cardElementRef,
  containerRef,
  page,
  isCardSelected,
  handleFindSimilarClick,
  handleRemoveVertex,
  exportData,
  clusterID,
  showExportOption,
  searchParams,
  isDataPage,
  isClusterPage,
  datasetLabels,
  vertexPreviewCountContext,
  isAssetPreviewEnabled,
  indexOfPointerOut,
  setIndexOfPointerOut,
}: SingleCardProps) => {
  const [isHovering, setIsHovering] = useState<any>(false);
  //eslint-disable-next-line
  const [images, setImages] = useState<any>([]);
  const [isHoveringButton1, setIsHoveringButton1] = useState(false);
  const [isHoveringButton2, setIsHoveringButton2] = useState(false);
  const [linkClicked, setLinkClicked] = useState(false);
  const [hoveredAssetData, setHoveredAssetData] = useState({
    id: "",
    dimensions: { naturalHeight: 0, naturalWidth: 0 },
    error: false,
  });
  const assetPreviewFlagRef = useRef(true);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const publicParameterExists = !!getQueryParameter(
    PUBLIC_PAGE_QUERY_PARAMETER_KEY
  );

  const prefix =
    page === DATA_PAGE && singleData.previews?.length > 1 ? "Cluster" : "";
  let title =
    page === DATA_PAGE ? clusterID?.substring(0, 8) : singleData?.file_name;
  if (singleData?.distance && typeof singleData.distance === "number") {
    title += `\t distance: ${singleData.distance.toFixed(2)}`;
  }
  const dynamicTitle = isCardSelected
    ? `Vertex ${title}`
    : `${prefix} ${title}`;

  const isObjectCluster = page !== DATA_PAGE && singleData.type === "OBJECT";
  const boundingBox = isObjectCluster ? singleData.bounding_box : false;

  const videoURIForCluster = isClusterPage && singleData.video_uri;
  const videoURIsForData =
    isDataPage &&
    singleData.previews.map((preview: any) => preview.video_uri || "");

  const isSingleEntityOnDataPage =
    isDataPage && singleData.previews.length === 1;

  const fileName = isSingleEntityOnDataPage
    ? singleData.previews[0].video_uri
      ? singleData.previews[0].video_uri.split("/")[
          singleData.previews[0].video_uri.split("/").length - 1
        ]
      : singleData.previews[0].file_name
    : isClusterPage
    ? singleData.video_uri
      ? singleData.video_uri.split("/")[
          singleData.video_uri.split("/").length - 1
        ]
      : singleData.file_name
    : "";

  const clusterSize = isDataPage
    ? singleData.type === "IMAGES"
      ? singleData.n_images
      : singleData.type === "OBJECTS"
      ? singleData.n_objects
      : 0 //handle video count when ready
    : 0;

  const entityParam = searchParams.has(ENTITY_TYPE_PARAMETER)
    ? `?${ENTITY_TYPE_PARAMETER}=${searchParams.get(ENTITY_TYPE_PARAMETER)}`
    : "";

  const videoCount = isDataPage ? singleData.n_videos || 0 : 0;
  const frameCount = isDataPage ? singleData.n_frames || 0 : 0;

  const dynamicAssetPreviewEnabled =
    (indexOfPointerOut === -1 || indexOfPointerOut === index) &&
    isAssetPreviewEnabled;

  const hoverDelay = dynamicAssetPreviewEnabled ? 700 : 0;

  const cardHoverClassName = classNames(styles.dataCard, {
    [styles.dataCardWithOutline]: isHovering,
  });

  const getDynamicImages = (type: "plain" | "thumb", previews: any) => {
    let result: { src: string; id: string }[] = [];
    if (type === "thumb") {
      result = previews?.map((preview: any) => {
        return { src: preview.media_thumb_uri, id: preview.media_id };
      });
    } else if (type === "plain") {
      result = previews?.map((preview: any) => {
        return { src: preview.media_uri, id: preview.media_id };
      });
    }

    if (isCardSelected && !!vertexPreviewCountContext) {
      result = result.slice(0, vertexPreviewCountContext);
    }

    return result;
  };

  const plainImages = getDynamicImages("plain", singleData.previews);
  const thumbImages = getDynamicImages("thumb", singleData.previews);
  const plainImage = { id: singleData.media_id, src: singleData.media_uri };
  const thumbImage = {
    id: singleData.media_id,
    src: singleData.media_thumb_uri,
  };

  const getAssetData = () => {
    let assetData;

    if (hoveredAssetData.id) {
      if (isDataPage) {
        assetData = singleData.previews.find(
          (preview: any) => preview.media_id === hoveredAssetData.id
        );
      } else if (isClusterPage) {
        assetData =
          singleData.media_id === hoveredAssetData.id ? singleData : undefined;
      }
    }

    return assetData ?? {};
  };

  const setAssetPreviewFlag = (value: boolean) => {
    assetPreviewFlagRef.current = value;
  };

  const clearAssetData = () => {
    setHoveredAssetData({
      id: "",
      dimensions: { naturalHeight: 0, naturalWidth: 0 },
      error: false,
    });
  };

  const updateAssetData = async (id: string, source: string) => {
    const dimensions = {
      naturalWidth: LOADING_OR_ERROR_CARD_DIMENSION_NUMBER,
      naturalHeight: LOADING_OR_ERROR_CARD_DIMENSION_NUMBER,
    };

    try {
      const res = await getMediaDimensions(source);
      const result = { id, dimensions: res, error: false };

      if (assetPreviewFlagRef.current) {
        setHoveredAssetData((prev) => {
          const oldHeight = prev.dimensions.naturalHeight;
          const oldWidth = prev.dimensions.naturalWidth;

          const newHeight = res.naturalHeight;
          const newWidth = res.naturalWidth;

          // Normalize dimensions by the larger dimension to ensure consistent scale
          const normalizedOldHeight = oldHeight / Math.max(oldHeight, oldWidth);
          const normalizedOldWidth = oldWidth / Math.max(oldHeight, oldWidth);
          const normalizedNewHeight = newHeight / Math.max(newHeight, newWidth);
          const normalizedNewWidth = newWidth / Math.max(newHeight, newWidth);

          // Compare normalized dimensions to determine if new size is smaller
          if (
            normalizedNewHeight < normalizedOldHeight ||
            normalizedNewWidth < normalizedOldWidth
          ) {
            setIndexOfPointerOut(index);
          } else if (indexOfPointerOut > -1) {
            setIndexOfPointerOut(-1);
          }

          return result;
        });
      } else {
        clearAssetData();
      }
    } catch (error) {
      const result = { id, dimensions, error: true };
      if (assetPreviewFlagRef.current) {
        setHoveredAssetData(result);
      } else {
        clearAssetData();
      }
    }
  };

  const handlePreviewType = (preview: any): "image" | "video" | null => {
    if (preview) return preview.video_uri ? "video" : "image";
    return null;
  };

  const handleFileType = (): "image" | "video" | null => {
    if (isDataPage && isSingleEntityOnDataPage) {
      return handlePreviewType(singleData?.previews[0]);
    } else if (isClusterPage) {
      return handlePreviewType(singleData);
    } else {
      return null;
    }
  };

  const isExportSelected = () => {
    if (isDataPage) {
      return !!exportData.find(
        (singleExp: any) => singleExp.cluster_id === singleData.cluster_id
      );
    } else if (isClusterPage) {
      const selectedParentCluster = exportData.find(
        (data: any) =>
          data.cluster_id === clusterID && data.exportType === "cluster"
      );
      if (selectedParentCluster) {
        //check if the media is selected to be removed from cluster selection
        const selectedForRemoval = selectedParentCluster.excludeMediaIDs
          ? selectedParentCluster.excludeMediaIDs.includes(singleData.media_id)
          : false;
        if (selectedForRemoval) {
          return false;
        }
        return true;
      } else {
        return !!exportData.find(
          (data: any) => data.media_id === singleData.media_id
        );
      }
    }
  };

  const trackEntityOpenAction = (
    target: EventTarget,
    type: "Dataset" | "Cluster Details",
    clusterSize?: number
  ) => {
    let properties: any = {
      [AMP_DATASET_EXPLORATION_PROPERTY__TARGET]: target,
      [AMP_DATASET_EXPLORATION_PROPERTY__CONTEXT]: type,
    };
    if (clusterSize) {
      properties = {
        ...properties,
        [AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE]: clusterSize,
      };
    }

    amplitudeTrack(AMP_DATASET_EXPLORATION_EVENT__ENTITY__OPENED, properties);
  };

  const handleLinkClick = (e: any) => {
    e.stopPropagation();

    if (linkClicked) return;
    else setLinkClicked(true);

    let newPath = location.pathname;
    let clusterSize = 0;
    let target: EventTarget = "Image";
    if (isDataPage) {
      const imageCount = singleData?.previews?.length;
      if (imageCount === 1) {
        const firstPreview = singleData.previews[0];
        const isTypeImage = firstPreview.type === "IMAGE";
        const dynamicID = isTypeImage
          ? firstPreview.media_id
          : firstPreview.image_id;

        newPath += `/image/${dynamicID}${entityParam}`;

        if (publicParameterExists) {
          newPath = addQueryParameter(
            newPath,
            PUBLIC_PAGE_QUERY_PARAMETER_KEY,
            PUBLIC_PAGE_QUERY_PARAMETER_VALUE
          );
        }

        if (!isTypeImage) {
          newPath = addQueryParameter(
            newPath,
            OBJECT_ID_QUERY_PARAMETER,
            firstPreview.media_id
          );
        }

        //track url copy action on single entity
        const isVideo = !!firstPreview.video_uri;
        if (isVideo) target = "Video";
        else if (isTypeImage) target = "Image";
        else if (firstPreview.type === "OBJECT") target = "Object";
      } else {
        clusterSize = imageCount;

        const tempParams = new URLSearchParams(searchParams.toString());
        tempParams.set(DATA_PAGE_PARAMETER, tempParams.get(PAGE_PARAMETER)!);
        tempParams.delete(PAGE_PARAMETER);
        const params = `?${tempParams.toString()}`;

        newPath += `/cluster/${clusterID}${params}`;
        target = "Cluster";
      }
    } else if (isClusterPage) {
      const dynamicID =
        singleData.type === "IMAGE" ? singleData.media_id : singleData.image_id;

      let imagePath =
        location.pathname.split("/cluster")[0] +
        `/image/${dynamicID}${entityParam}`;

      if (publicParameterExists) {
        imagePath = addQueryParameter(
          imagePath,
          PUBLIC_PAGE_QUERY_PARAMETER_KEY,
          PUBLIC_PAGE_QUERY_PARAMETER_VALUE
        );
      }

      if (isObjectCluster) {
        imagePath = addQueryParameter(
          imagePath,
          OBJECT_ID_QUERY_PARAMETER,
          singleData.media_id
        );
      }

      newPath = imagePath;

      //track url copy action on single entity
      const isVideo = !!singleData.video_uri;
      if (isVideo) target = "Video";
      else if (singleData.type === "IMAGE") target = "Image";
      else if (singleData.type === "OBJECT") target = "Object";
    }
    // track link copy action
    let properties: any = {
      [AMP_DATASET_EXPLORATION_PROPERTY__TARGET]: target,
    };
    if (!!clusterSize) {
      properties = {
        ...properties,
        [AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE]: clusterSize,
      };
    }
    amplitudeTrack(
      AMP_DATASET_EXPLORATION_EVENT__ENTITY__URL_COPIED,
      properties
    );

    const url = window.location.host + newPath;
    navigator.clipboard.writeText(url.toString());
  };

  const handleDetailsButtonClick = (e: any, data: any) => {
    if (isCardSelected) {
      return;
    }
    e.stopPropagation();
    clearAssetData();
    dispatch(clearImagePageParamsContext());

    if (isDataPage) {
      dispatch(setSimilarityData(null));
      dispatch(setNavigationCluster(null));

      const imageCount = data?.previews?.length;
      if (imageCount === 1) {
        const firstPreview = data.previews[0];
        const isTypeImage = firstPreview.type === "IMAGE";
        const dynamicID = isTypeImage
          ? firstPreview.media_id
          : firstPreview.image_id;

        dispatch(setNavigationCluster(data));
        let basePath = `${location.pathname}/image/${dynamicID}${entityParam}`;

        if (publicParameterExists) {
          basePath = addQueryParameter(
            basePath,
            PUBLIC_PAGE_QUERY_PARAMETER_KEY,
            PUBLIC_PAGE_QUERY_PARAMETER_VALUE
          );
        }

        if (isTypeImage) {
          navigate(basePath);
        } else {
          const newPath = addQueryParameter(
            basePath,
            OBJECT_ID_QUERY_PARAMETER,
            firstPreview.media_id
          );
          navigate(newPath);
        }

        //track action when single entity on dataset exploration page
        const isVideo = !!firstPreview.video_uri;
        let target: EventTarget = "Image";
        if (isVideo) {
          target = "Video";
        } else if (isTypeImage) {
          target = "Image";
        } else if (firstPreview.type === "OBJECT") {
          target = "Object";
        }
        trackEntityOpenAction(target, "Dataset");
      } else {
        searchParams.set(DATA_PAGE_PARAMETER, searchParams.get(PAGE_PARAMETER));
        dispatch(setClusterPageNumber(1));
        dispatch(fetchDatasetLabels(datasetId, clusterID) as any);
        dispatch(fetchClusterPageIssueTypes(datasetId, clusterID) as any);
        dispatch(fetchUserTags(datasetId, clusterID) as any);

        searchParams.delete(PAGE_PARAMETER);
        const newPath = `${location.pathname}/cluster/${clusterID}`;
        const params = `?${searchParams.toString()}`;
        navigate(newPath.concat(params));

        const clusterSize =
          singleData.type === "IMAGES"
            ? singleData.n_images
            : singleData.n_objects;
        trackEntityOpenAction("Cluster", "Dataset", clusterSize);
      }
    }

    if (isClusterPage) {
      const dynamicID =
        singleData.type === "IMAGE" ? singleData.media_id : singleData.image_id;

      let imagePath =
        location.pathname.split("/cluster")[0] +
        `/image/${dynamicID}${entityParam}`;

      if (publicParameterExists) {
        imagePath = addQueryParameter(
          imagePath,
          PUBLIC_PAGE_QUERY_PARAMETER_KEY,
          PUBLIC_PAGE_QUERY_PARAMETER_VALUE
        );
      }

      if (isObjectCluster) {
        imagePath = addQueryParameter(
          imagePath,
          OBJECT_ID_QUERY_PARAMETER,
          singleData.media_id
        );
      }

      dispatch(setSelectedCarouselIndex(index));
      navigate(imagePath);

      //track action when single entity on cluster details page
      const isVideo = !!singleData.video_uri;
      let target: EventTarget = "Image";

      if (isVideo) {
        target = "Video";
      } else if (singleData.type === "Image") {
        target = "Image";
      } else if (singleData.type === "OBJECT") {
        target = "Object";
      }
      trackEntityOpenAction(target, "Cluster Details");
    }
  };

  const handleSimilarButtonClick = (e: any) => {
    e.stopPropagation();
    clearAssetData();
    if (handleFindSimilarClick) {
      if (isDataPage) {
        handleFindSimilarClick(clusterID, "CLUSTER");
      } else {
        handleFindSimilarClick(singleData.media_id, "MEDIA");
      }
    }
    dispatch(clearImagePageParamsContext());
    trackVisualSearch();
  };

  const handleAssetPreviewSimilarButtonClick = (e: any) => {
    e.stopPropagation();
    clearAssetData();
    if (handleFindSimilarClick) {
      handleFindSimilarClick(hoveredAssetData.id, "MEDIA");
    }
    dispatch(clearImagePageParamsContext());
    trackVisualSearch();
  };

  const trackVisualSearch = () => {
    let target: EventTarget = "Image";
    let clusterSize: number = 0;
    const isSingleEntity =
      singleData.previews && singleData.previews.length === 1;

    if (isSingleEntity) {
      const singlePreview = singleData.previews[0];
      const isVideo = !!singlePreview.video_uri;
      if (isVideo) target = "Video";
      else if (singlePreview.type === "IMAGE") target = "Image";
      else if (singlePreview.type === "OBJECT") target = "Object";
    } else if (singleData.type === "IMAGES") {
      target = "Cluster";
      clusterSize = singleData.n_images;
    } else if (singleData.type === "OBJECTS") {
      target = "Cluster";
      clusterSize = singleData.n_objects;
    }
    let properties: any = {
      [AMP_DATASET_EXPLORATION_PROPERTY__TARGET]: target,
    };
    if (!!clusterSize) {
      properties = {
        ...properties,
        [AMP_DATASET_EXPLORATION_PROPERTY__CLUSTER_SIZE]: clusterSize,
      };
    }
    //track visual search in amplitude(applied here)
    amplitudeTrack(
      AMP_DATASET_EXPLORATION_EVENT__VISUAL_SEARCH__APPLIED,
      properties
    );
  };

  const handleMouseEnter = (e: any) => {
    e.stopPropagation();
    setIsHovering(true);
    setAssetPreviewFlag(true);
    if (indexOfPointerOut === index) {
      setIndexOfPointerOut(-1);
    }
  };
  const handleMouseLeave = (e: any) => {
    e.stopPropagation();
    setIsHovering(false);
    setLinkClicked(false);
    setAssetPreviewFlag(false);
  };

  useEffect(() => {
    if (singleData.preview_boxes) {
      createPreviewBoxImagesArray(singleData.preview_boxes);
    }
    //eslint-disable-next-line
  }, []);

  const orderSingleCardLabels = (singleCardLabels: string[]) => {
    if (singleCardLabels.length > 0) {
      let orderedLabels: any[] = [];
      singleCardLabels.forEach((label: string) => {
        const existingText = datasetLabels.find(
          (l: FilterOption) => l.text === label
        );
        const isLabelSelected = !!existingText?.selected;
        if (isLabelSelected) orderedLabels.unshift(label);
        else orderedLabels.push(label);
      });
      return orderedLabels;
    } else return [];
  };

  const handleSingleCardLabels = () => {
    let singleCardLabels: string[] = [];
    if (singleData && singleData.labels) {
      if (isClusterPage) {
        singleCardLabels = singleData.labels;
      } else {
        singleCardLabels = Object.keys(singleData.labels);
      }
    }
    // This is for vertex we wrap and display as a cluster.
    if (
      singleCardLabels.length === 0 &&
      isCardSelected &&
      singleData.previews &&
      singleData.previews.length > 0
    ) {
      singleCardLabels = singleData.previews[0].labels || [];
    }
    return orderSingleCardLabels(singleCardLabels);
  };

  const createPreviewBoxImagesArray = async (previewBoxArray: any) => {
    let tileDimension = 0;
    let colCount = 0;

    if (previewBoxArray && cardElementRef?.offsetWidth) {
      if (previewBoxArray.length >= 25) {
        colCount = 5;
      } else if (previewBoxArray.length >= 16 && previewBoxArray.length < 25) {
        colCount = 4;
      } else if (previewBoxArray.length >= 9 && previewBoxArray.length < 16) {
        colCount = 3;
      } else {
        colCount = 2;
      }
      tileDimension =
        Math.floor(cardElementRef?.offsetWidth / colCount) + (colCount - 1) * 5;
      //eslint-disable-next-line
      await previewBoxArray.map((singleBox: any, index: number) => {
        let canvas = document.createElement("canvas") as HTMLCanvasElement;

        let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        canvas.width =
          singleBox?.box[2] < tileDimension ? singleBox?.box[2] : tileDimension;
        canvas.height =
          singleBox?.box[3] < tileDimension ? singleBox?.box[3] : tileDimension;

        let img = new Image();
        img.src = singleBox.image_uri;
        img.setAttribute("crossorigin", "anonymous");

        img.onload = () => {
          ctx.drawImage(
            img,
            singleBox.box[0],
            singleBox.box[1],
            singleBox.box[2],
            singleBox.box[3],
            0,
            0,
            singleBox.width < tileDimension ? singleBox.width : tileDimension,
            singleBox.height < tileDimension ? singleBox.height : tileDimension
          );
          if (index < 25) {
            setImages((images: any) => [
              ...images,
              { o: index, src: canvas.toDataURL() },
            ]);
          }
        };
      });
    }
  };

  const renderAssetPreview = () => {
    const assetData = getAssetData();
    const showCarousel =
      singleData.previews &&
      singleData.previews.length > 1 &&
      hoveredAssetData.id &&
      !hoveredAssetData.error;

    return (
      <AssetPreviewCard
        setIsHoveringFindSimilarButton={setIsHoveringButton2}
        handleSimilarButtonClick={handleAssetPreviewSimilarButtonClick}
        isSingleEntityOnDataPage={isSingleEntityOnDataPage}
        isHoveringFindSimilarButton={isHoveringButton2}
        handleSingleCardLabels={handleSingleCardLabels}
        setIsHoveringLinkButton={setIsHoveringButton1}
        clearAssetData={clearAssetData}
        setAssetPreviewFlag={setAssetPreviewFlag}
        isHoveringLinkButton={isHoveringButton1}
        checkExportSelected={isExportSelected}
        hoveredAssetData={hoveredAssetData}
        handleFileType={handleFileType}
        isCardSelected={isCardSelected}
        isClusterPage={isClusterPage}
        showCarousel={showCarousel}
        containerRef={containerRef}
        clusterSize={clusterSize}
        singleData={singleData}
        isDataPage={isDataPage}
        assetData={assetData}
        updateAssetData={updateAssetData}
        index={index}
        page={page}
        searchParams={searchParams}
        isObjectCluster={isObjectCluster}
        indexOfPointerOut={indexOfPointerOut}
        setIndexOfPointerOut={setIndexOfPointerOut}
      />
    );
  };

  return (
    <div
      key={index}
      className={styles.singleClusterWrapper}
      id={`clusterCardWrapper-${index}`}
      ref={cardElementRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e: any) => handleDetailsButtonClick(e, singleData)}
    >
      <div
        className={styles.extendedArea}
        onMouseEnter={() => setIsHovering(true)}
      ></div>
      <div className={cardHoverClassName}>
        <CardHeader
          title={dynamicTitle}
          isCardSelected={isCardSelected}
          handleRemoveVertex={handleRemoveVertex}
          isHovering={isHovering}
          clusterID={clusterID}
          isExportSelected={!!isExportSelected()}
          singleCardLabels={handleSingleCardLabels()}
          exportData={exportData}
          showExportOption={showExportOption}
          type={singleData?.type}
          imageCount={singleData?.n_images}
          objectCount={singleData?.n_objects}
          fileType={handleFileType()}
          videoCount={videoCount}
          frameCount={frameCount}
          isDataPage={isDataPage}
          isClusterPage={isClusterPage}
          clusterPreview={isClusterPage ? singleData : false}
          isSingleEntityOnDataPage={isSingleEntityOnDataPage}
          clusterSize={clusterSize}
          fileName={fileName}
          hoveredAssetData={hoveredAssetData}
          index={index}
        />
        <CardBody
          page={page}
          isCardSelected={isCardSelected}
          plainImages={plainImages}
          thumbImages={thumbImages}
          cardType={singleData.type}
          isHovering={isHovering}
          plainImage={plainImage}
          thumbImage={thumbImage}
          isObjectCluster={isObjectCluster}
          boundingBox={boundingBox}
          videoURIForCluster={videoURIForCluster}
          videoURIsForData={videoURIsForData}
          roundBottom={true}
          isAssetPreviewEnabled={dynamicAssetPreviewEnabled}
          updateAssetData={updateAssetData}
          hoverDelay={hoverDelay}
        />

        {isCardSelected && <div className={styles.selectedDataCard}></div>}
        {showExportOption && isExportSelected() && (
          <div className={styles.selectedExportCard}></div>
        )}
        {isHovering && (
          <div
            className={styles.actionButtons}
            onClick={(e: any) => e.stopPropagation()}
          >
            <div
              className={styles.actionButton}
              onClick={handleLinkClick}
              onMouseEnter={() => setIsHoveringButton1(true)}
              onMouseLeave={() => setIsHoveringButton1(false)}
              data-tooltip-id={"data-page-link-button-tooltip"}
              data-tooltip-content={linkClicked ? "Copied!" : "Copy URL"}
            >
              <LinkIcon
                color={isHoveringButton1 ? "#0197d8" : "#fff"}
                clicked={linkClicked}
              />

              <TooltipWrapper
                id="data-page-link-button-tooltip"
                customClass="cardActionButton"
              />
            </div>
            {!isCardSelected && (
              <div
                className={styles.actionButton}
                onClick={handleSimilarButtonClick}
                onMouseEnter={() => setIsHoveringButton2(true)}
                onMouseLeave={() => setIsHoveringButton2(false)}
                data-tooltip-id="data-page-find-similar-button-tooltip"
                data-tooltip-content="Find Similar"
              >
                <FindSimilarIcon
                  color={isHoveringButton2 ? "#0197d8" : "#fff"}
                />
                <TooltipWrapper
                  id="data-page-find-similar-button-tooltip"
                  customClass="cardActionButton"
                />
              </div>
            )}
          </div>
        )}
      </div>
      {dynamicAssetPreviewEnabled && renderAssetPreview()}
    </div>
  );
};

const mapStateToProp = (state: State) => {
  return {
    datasetLabels: getDatasetLabels(state),
    exportData: getExportData(state),
    vertexPreviewCountContext: getVertexPreviewsCountContext(state),
    isAssetPreviewEnabled: getIsAssetPreviewEnabled(state),
  };
};

export default connect(mapStateToProp)(SingleCard);
