import { State } from "redux/store";
import { connect, useDispatch } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { useLocation, useNavigate } from "react-router";
import { VariableSizeList as List } from "react-window";
import {
  // useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  // setHoveredFileState,
  updateFileSelection,
} from "redux/SingleDataset/actions";
import {
  FOLDER_PARAMETER,
  PAGE_PARAMETER,
} from "helpers/constants/miscellaneous";
import { formatNumberWithCommas } from "helpers/utility/formatters";
import { getHoveredFileState } from "redux/SingleDataset/selectors";

import styles from "./style.module.scss";

const SmartDisplayIcon = ({ color }: { color: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
  >
    <path
      d="M7.5 12.5L14.5 8L7.5 3.5V12.5ZM2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2ZM2 14H18V2H2V14Z"
      fill={color}
    />
  </svg>
);

const NoMatchingData = () => (
  <div className={styles.noMatchcontainer}>No Matching Data.</div>
);

const SingleFileDisplay = ({
  index,
  path,
  totalCount,
  handleChange,
  isDataOverflowing,
  isChecked,
  maxFileCount,
  hoveredFileState,
}: any) => {
  const [isHovering, setIsHovering] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const fileCountBoxRef = useRef<HTMLDivElement>(null);
  // const dispatch = useDispatch();

  const frameCountText = formatNumberWithCommas(totalCount) + " Frames";
  const maxFrameCountText = formatNumberWithCommas(maxFileCount) + " Frames";

  const dynamicFileName = (path: string) => {
    return path.split("/").pop();
  };

  const onCheckboxChange = () => {
    handleChange(index, !isChecked);
  };

  const dynamicFileIconColor = () => {
    if (isChecked) return "#151928";
    else if (isHovering) return "#ffffff";
    else return "#464C5E";
  };

  const handleFileTitleBoxStyle = () => {
    let maxWidth = "";
    const countBoxRef = fileCountBoxRef.current;
    if (countBoxRef) maxWidth = `calc(100% - ${countBoxRef.offsetWidth})`;
    return {
      color: isChecked ? "#151928" : "#fff",
      maxWidth: maxWidth ? maxWidth : undefined,
    };
  };

  const fileCountBoxStyle = {
    minWidth: `calc(${maxFrameCountText.length}ch)`,
  };

  const dynamicfileDisplayBarStyle = {
    backgroundColor: isChecked ? "#fff" : "#3C424F",
    outlineColor: isHovering || isChecked ? "#fff" : "#3C424F",
  };

  // useEffect(() => {
  //   if (isHovering) {
  //     const rf = containerRef.current;
  //     if (rf) {
  //       const position = rf.getBoundingClientRect();
  //       const alreadyExists =
  //         hoveredFileState &&
  //         hoveredFileState.path === path &&
  //         hoveredFileState.right === rf.offsetWidth + 40 &&
  //         hoveredFileState.top === position.top + rf.offsetHeight / 2;

  //       if (!alreadyExists) {
  //         dispatch(
  //           setHoveredFileState({
  //             path,
  //             right: rf.offsetWidth + 40,
  //             top: position.top + rf.offsetHeight / 2,
  //           })
  //         );
  //       }
  //     }
  //   }
  //   //eslint-disable-next-line
  // }, [isHovering]);

  return (
    <div
      className={styles.singleFileDisplay}
      ref={containerRef}
      key={index}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        className={styles.fileDisplayBar}
        style={dynamicfileDisplayBarStyle}
        onClick={onCheckboxChange}
      >
        <div className={styles.fileTitleBox} style={handleFileTitleBoxStyle()}>
          <div className={styles.fileIcon}>
            <SmartDisplayIcon color={dynamicFileIconColor()} />
          </div>
          <div
            className={styles.filePath}
            title={dynamicFileName(path)}
            style={{ fontWeight: isChecked ? 500 : 300 }}
          >
            {dynamicFileName(path)}
          </div>
        </div>
        <div
          className={styles.fileCountBox}
          ref={fileCountBoxRef}
          style={fileCountBoxStyle}
        >
          {frameCountText}
        </div>
      </div>
    </div>
  );
};

const Row = ({
  index,
  style,
  data,
  dynamicPaddingRight,
  handleSingleFileSelection,
  isDataOverflowing,
  hoveredFileState,
}: any) => {
  const singleData = data[index];
  const dynamicPaddingTop =
    index === 0 || index === data.length - 1 ? "1px" : "0";
  return (
    <div
      key={index}
      className={styles.singleFileRow}
      style={{
        ...style,
        padding: `${dynamicPaddingTop} ${
          dynamicPaddingRight().paddingRight
        } 10px 10px`,
      }}
    >
      <SingleFileDisplay
        index={index}
        path={singleData.title}
        totalCount={singleData.count}
        handleChange={handleSingleFileSelection}
        isDataOverflowing={isDataOverflowing}
        isChecked={singleData.selected}
        maxFileCount={Math.max(...data.map((label: any) => label.count))}
        hoveredFileState={hoveredFileState}
      />
    </div>
  );
};

const FileDisplayContent = ({
  data,
  isDataOverflowing,
  showNoMatchingData,
  isContentSelected,
  entityTypeFilter,
  resetSection,
  trackMetadataFilterChange,
  hoveredFileState,
}: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const handleSingleFileSelection = (index: number, isChecked: boolean) => {
    //track file filter selection for amplitude
    trackMetadataFilterChange(
      "Files",
      entityTypeFilter,
      isChecked ? "Added" : "Removed"
    );

    dispatch(
      updateFileSelection({
        title: data[index].title,
        isSelected: isChecked,
      }) as any
    );
    if (isChecked) {
      searchParams.set(FOLDER_PARAMETER, data[index].title);
    } else {
      searchParams.delete(FOLDER_PARAMETER);
    }
    searchParams.set(PAGE_PARAMETER, "1");
    navigate({ search: `?${searchParams.toString()}` });
    resetSection();
  };

  const dynamicPaddingRight = () => {
    return {
      paddingRight: isContentSelected
        ? "4px"
        : isDataOverflowing
        ? "4px"
        : "7px",
    };
  };

  const itemSize = (index: number) => {
    return 40; // Set a default item height
  };

  const dynamicWrapperHeight = () => {
    if (isContentSelected) {
      return { height: "100%", maxHeight: "100%" };
    } else {
      switch (data.length) {
        case 1 || 2 || 0:
          return { height: "90px" };
        case 3:
          return { height: "130px" };
        case 4:
          return { height: "170px" };
        default:
          return { height: "210px" };
      }
    }
  };

  return showNoMatchingData ? (
    <NoMatchingData />
  ) : (
    <div className={styles.wrapper} style={dynamicWrapperHeight()}>
      <AutoSizer>
        {({ height, width }: any) => (
          <List
            height={height}
            width={width}
            itemCount={data.length}
            itemSize={itemSize}
            itemData={data}
            className={`List ${styles.fileListContainer}`}
            style={dynamicPaddingRight()}
          >
            {({ index, style }) => {
              return (
                <Row
                  data={data}
                  index={index}
                  style={style}
                  isDataOverflowing={isDataOverflowing}
                  dynamicPaddingRight={dynamicPaddingRight}
                  handleSingleFileSelection={handleSingleFileSelection}
                  hoveredFileState={hoveredFileState}
                />
              );
            }}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    hoveredFileState: getHoveredFileState(state),
  };
};
export default connect(mapStateToProps)(FileDisplayContent);
