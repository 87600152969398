import styles from "./style.module.scss";

const VideoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g filter="url(#filter0_d_1607_10222)">
        <path
          d="M11 0H1V10H11V0ZM6.56 1.10667H7.66667V2.21333H6.56V1.10667ZM4.33333 1.10667H5.44V2.21333H4.33333V1.10667ZM2.10667 1.10667H3.21333V2.21333H2.10667V1.10667ZM3.22667 8.89333H2.12V7.78667H3.22667V8.89333ZM5.44 8.89333H4.33333V7.78667H5.44V8.89333ZM7.66667 8.89333H6.56V7.78667H7.66667V8.89333ZM9.89333 8.89333H8.78667V7.78667H9.89333V8.89333ZM9.89333 6.66667H2.10667V3.33333H9.88V6.66667H9.89333ZM9.89333 2.22667H8.78667V1.12H9.89333V2.22667Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1607_10222"
          x="0"
          y="0"
          width="12"
          height="12"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1607_10222"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1607_10222"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

const VideoIndicationIcon = () => {
  return (
    <div className={styles.wrapper}>
      <VideoIcon />
    </div>
  );
};

export default VideoIndicationIcon;
