const ShieldIcon = ({
  color = "#575B67",
}: {
  color?: string;
  size?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
    >
      <path
        d="M8 19C5.68333 18.4458 3.77083 17.1831 2.2625 15.2119C0.754167 13.2406 0 11.0517 0 8.645V2.85L8 0L16 2.85V8.645C16 11.0517 15.2458 13.2406 13.7375 15.2119C12.2292 17.1831 10.3167 18.4458 8 19ZM8 17.005C9.73333 16.4825 11.1667 15.4375 12.3 13.87C13.4333 12.3025 14 10.5608 14 8.645V4.15625L8 2.01875L2 4.15625V8.645C2 10.5608 2.56667 12.3025 3.7 13.87C4.83333 15.4375 6.26667 16.4825 8 17.005Z"
        fill={color}
      />
      <path
        d="M9.125 12H6.875V10.125H5V7.875H6.875V6H9.125V7.875H11V10.125H9.125V12Z"
        fill={color}
      />
    </svg>
  );
};

export default ShieldIcon;
