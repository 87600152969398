import { User } from "types";

export async function initPendo(user: User | undefined, email: string = "") {
  (window as any).pendo.initialize({
    visitor: {
      id: user?.session,
      email: email,
      origin: user?.origin,
    },
    account: {
      id: email && email.includes("@") ? email.split("@")[1] : "",
    },
  });
}
