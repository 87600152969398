import Button from "react-bootstrap/Button";
import TooltipWrapper from "../TooltipWrapper";

import styles from "./style.module.scss";
type vlButtonPropTypes = {
  onClick: Function;
  text: string;
  tooltipText: string;
};
function VLButton({ onClick, text, tooltipText }: vlButtonPropTypes) {
  return (
    <Button
      className={styles.vlButton}
      onClick={() => onClick()}
      data-tooltip-content={tooltipText}
      data-tooltip-id="vl-button-tooltip"
    >
      {text}
      <TooltipWrapper id="vl-button-tooltip" />
    </Button>
  );
}

export default VLButton;
