//always refer settings on amplitude.com
export const AMP_USER_SIGN_IN = "User Sign In";

export const AMP_INVENTORY_SORTED = "Inventory Sorted";

export const AMP_DATASET_FILTERED = "Dataset Filtered";
export const AMP_DATASET_IMPORTED = "Dataset Imported";
export const AMP_DATASET_DELETED = "Dataset Deleted";
export const AMP_DATASET_OPENED = "Dataset Opened";
export const AMP_DATASET_SORTED = "Dataset Sorted";

export const AMP_CLUSTERS_SORTED = "Clusters Sorted";
export const AMP_CLUSTER_OPENED = "Cluster Opened";

export const AMP_IMAGE_OPENED = "Image  Opened";

export const AMP_IMAGE_SOURCE_VISITED = "Image Source Visited";
export const AMP_DOCUMENTATION_VISITED = "Documentation Visited";

export const AMP_DATA_EXPORTED = "Data Exported";

export const AMP_DATASET_EXPLORATION_EVENT__GRANULARITY_CHANGED =
  "Cluster Granularity Changed";
export const AMP_DATASET_EXPLORATION_EVENT__TAB_CHANGED = "Tab Changed";
export const AMP_DATASET_EXPLORATION_EVENT__PAGE_CHANGED = "Page Changed";
export const AMP_DATASET_EXPLORATION_EVENT__ENTITY_TYPE_CHANGED =
  "Entity Type Changed";

export const AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__APPLIED =
  "Textual Search Applied";
export const AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__REMOVED =
  "Textual Search Removed";

export const AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__RETURNED_RESULTS =
  "Textual Search Returned Results";

export const AMP_DATASET_EXPLORATION_EVENT__TEXTUAL_SEARCH__RETURNED_NO_RESULT =
  "Textual Search Returned No Result";

export const AMP_DATASET_EXPLORATION_EVENT__VISUAL_SEARCH__APPLIED =
  "Visual Search Applied";
export const AMP_DATASET_EXPLORATION_EVENT__VISUAL_SEARCH__REMOVED =
  "Visual Search Removed";

export const AMP_DATASET_EXPLORATION_EVENT__FILTER__APPLIED = "Filter Applied";
export const AMP_DATASET_EXPLORATION_EVENT__FILTER__REMOVED = "Filter Removed";
export const AMP_DATASET_EXPLORATION_EVENT__FILTER__ALL_CLEARED =
  "All Filters Removed";

export const AMP_DATASET_EXPLORATION_EVENT__METADATA__SECTION_OPENED =
  "Metadata Section Opened";

export const AMP_DATASET_EXPLORATION_EVENT__ENTITY__OPENED = "Entity Opened";
export const AMP_DATASET_EXPLORATION_EVENT__ENTITY__SELECTED =
  "Entity Selected";
export const AMP_DATASET_EXPLORATION_EVENT__USER_TAG__ASSIGNED =
  "User Tag Assigned";
export const AMP_DATASET_EXPLORATION_EVENT__ENTITY__URL_COPIED =
  "Entity URL Copied";
export const AMP_DATASET_EXPLORATION_EVENT__ENTITY__TOP_LABEL__CLICKED =
  "Entity Top Label Clicked";

export const AMP_DATASET_EXPLORATION_EVENT__SIGNUP_TEASER_MODAL__DISPLAYED =
  "Signup Teaser Modal Displayed";
export const AMP_DATASET_EXPLORATION_EVENT__SIGNUP_TEASER_MODAL__SIGNUP_CLICKED =
  "Signup Teaser Modal Signup Clicked";
export const AMP_DATASET_EXPLORATION_EVENT__SIGNUP_TEASER_MODAL__LEARN_MORE_CLICKED =
  "Signup Teaser Modal Learn More Clicked";

export const AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__GET_STARTED_CLICKED =
  "Cloud Teaser Modal Clicked";

export const AMP_DATASET_EXPLORATION_EVENT__LEARN_MORE_MODAL__OPENED =
  "Cloud Teaser Modal Displayed";

//Dataset Ingestion Events
export const AMP_DATASET_INGESTION_EVENT__DATASET_CREATION_INITIATED =
  "Dataset Creation Initiated";
export const AMP_DATASET_INGESTION_EVENT__DATASET_CREATION_SUCCESS =
  "Dataset Creation Success";
export const AMP_DATASET_INGESTION_EVENT__DATASET_CREATION_FAILED =
  "Dataset Creation Failed";
export const AMP_DATASET_INGESTION_EVENT__SOURCE_ADDED = "Dataset Source Added";
export const AMP_DATASET_INGESTION_EVENT__ANNOTATIONS_ADDED =
  "Annotations Added";
export const AMP_DATASET_INGESTION_EVENT__SOURCE_UPDATED =
  "Dataset Source Updated";
export const AMP_DATASET_INGESTION_EVENT__ANNOTATIONS_UPDATED =
  "Annotations Updated";
export const AMP_DATASET_INGESTION_EVENT__INDEXING_STARTED =
  "Dataset Indexing Started";
export const AMP_DATASET_INGESTION_EVENT__USER_QUIT_WHILE_UPLOADING =
  "User Quit While Uploading";
export const AMP_DATASET_INGESTION_EVENT__QUOTA_LIMIT_EXCEEDED =
  "User Quota Limit Exceeded";
