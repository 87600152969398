import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { State } from "redux/store";
import { useState } from "react";
import {
  addAllForExport,
  setExportDataTimeout,
  setShowCart,
  setTempExportData,
  clearExportData,
  updateExportData,
  clearSelectedMediaIDsFromExportData,
  addAllMediaIDsFromClusterPage,
} from "redux/SingleDataset/actions";

import styles from "./style.module.scss";

const ToolTipBox = ({
  tempExportData,
  exportData,
  timeoutID,
  clusters,
  removeAll,
  setRemoveAll,
  allSelectedFilters,
}: any) => {
  const [isAllSelected, setIsAllselected] = useState(false);
  const { datasetId, clusterId } = useParams();
  const dispatch = useDispatch();

  const isClusterPage = !!datasetId && !!clusterId;
  const clearSelection = tempExportData?.clearSelection;
  const isClusterArray = tempExportData?.type === "clusters";
  const isCluster = tempExportData?.exportType === "cluster";
  const selectedClusterOnDataPage =
    isClusterPage &&
    exportData.find(
      (singleData: any) =>
        singleData.cluster_id === clusterId &&
        singleData.exportType === "cluster"
    );

  //used for cart actions since it is not accessible from useParams there
  const datasetID = useSelector(
    (state: State) => state.singleDataset?.stats?.dataset?.id
  );

  const isTypeImage =
    tempExportData?.type === "IMAGES" || tempExportData?.type === "IMAGE";

  const count = calculateCount();

  const clusterID = tempExportData?.cluster_id?.substring(0, 8);
  const dynamicCount = isClusterArray
    ? `${count} Clusters`
    : isTypeImage
    ? `${count} ${count === 1 ? "Image" : "Images"}`
    : `${count} ${count === 1 ? "Object" : "Objects"}`;
  const dynamicWasWere = dynamicCount.startsWith("1 ") ? "was" : "were";

  const itemType = isClusterArray ? "Items" : "Item";
  const dynamicTitle = removeAll
    ? "Clear All"
    : tempExportData?.isAdded
    ? `${itemType} Added`
    : `${itemType} Removed`;

  const actionDescription = isClusterArray ? "" : `from Cluster #${clusterID}`;

  const dynamicText = removeAll
    ? "This will clear all of the items selected in this Dataset"
    : clearSelection
    ? "All items were removed from your Selected Items"
    : tempExportData?.isAdded
    ? `${dynamicCount} ${actionDescription} ${dynamicWasWere} added to your Selected Items`
    : `${dynamicCount} ${actionDescription} ${dynamicWasWere} removed from your Selected Items`;

  function calculateCount() {
    if (isClusterArray) {
      return tempExportData.clusterArray.length;
    } else if (isCluster) {
      if (isTypeImage) {
        return isAllSelected
          ? tempExportData.previews.length
          : tempExportData.n_images;
      } else {
        return isAllSelected
          ? tempExportData.previews.length
          : tempExportData.n_objects;
      }
    } else {
      return 1;
    }
  }

  const handleClearSelection = () => {
    setIsAllselected(false);
    if (isClusterPage) {
      if (!!selectedClusterOnDataPage) {
        dispatch(
          updateExportData(
            datasetId!,
            selectedClusterOnDataPage,
            "card",
            "cluster",
            {}
          ) as any
        );
      } else {
        dispatch(clearSelectedMediaIDsFromExportData(datasetId, true) as any);
      }
    } else if (!!datasetId) {
      dispatch(clearExportData(datasetId, true) as any);
    }
  };
  const handleSelectAll = () => {
    setIsAllselected(true);
    if (isClusterPage) {
      if (!!selectedClusterOnDataPage) {
        //will remove the selected cluster from cart and add medias
        dispatch(
          updateExportData(
            datasetID,
            selectedClusterOnDataPage,
            "card",
            "cluster",
            allSelectedFilters
          ) as any
        );
      }
      dispatch(clearSelectedMediaIDsFromExportData(datasetId) as any);
      dispatch(
        addAllMediaIDsFromClusterPage(datasetID, allSelectedFilters) as any
      );
    } else if (!!datasetId) {
      dispatch(addAllForExport(datasetId, clusters, allSelectedFilters) as any);
    }
  };
  const handleMouseLeave = () => {
    const newTimeout = setTimeout(
      () => dispatch(setTempExportData(null)),
      3000
    );
    dispatch(setExportDataTimeout(newTimeout));
  };
  const cancelRemoveAll = () => {
    setRemoveAll(false);
  };
  const confirmRemoveAll = () => {
    dispatch(clearExportData(datasetID) as any);
    setRemoveAll(false);
    dispatch(setShowCart(false));
  };

  return (
    <div
      className={styles.tooltipBox}
      onClick={(e: any) => e.stopPropagation()}
      onMouseEnter={() => clearTimeout(timeoutID)}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.tooltipTitle}>{dynamicTitle}</div>
      <div className={styles.tooltipMessage}>{dynamicText}</div>
      {removeAll ? (
        <div className={styles.tooltipButtons}>
          <div
            className={styles.tooltipButton}
            style={{ color: "#151928" }}
            onClick={cancelRemoveAll}
          >
            Cancel
          </div>
          <div
            className={styles.tooltipButton}
            style={{ color: "#FF4500" }}
            onClick={confirmRemoveAll}
          >
            Clear All
          </div>
        </div>
      ) : (
        tempExportData?.isAdded && (
          <div className={styles.tooltipButtons}>
            <div
              className={styles.tooltipButton}
              onClick={handleClearSelection}
            >
              Clear Selection
            </div>
            <div className={styles.tooltipButton} onClick={handleSelectAll}>
              Select All
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default ToolTipBox;
