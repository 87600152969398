import Image from "react-bootstrap/Image";
import dummyImage from "assets/img/anonymous_user.svg";

import styles from "./style.module.scss";

const VLAvatar = (props: VLAvatarProps) => {
  const { size, userAvatar } = props;

  let imageSize = styles.small;

  switch (size) {
    case "xsmall":
      imageSize = styles.xsmall;
      break;

    case "medium":
      imageSize = styles.medium;
      break;

    case "large":
      imageSize = styles.large;
      break;
  }

  return (
    <Image
      className={imageSize}
      src={userAvatar || dummyImage}
      roundedCircle
      alt="avatar-img"
      referrerPolicy="no-referrer"
    />
  );
};

interface VLAvatarProps {
  userAvatar: string | undefined;
  size: "xsmall" | "small" | "medium" | "large";
}

export default VLAvatar;
