import { State } from "redux/store";
import { connect, useDispatch } from "react-redux";

import {
  getQuotaLimitModalData,
  getSelectPlanModalData,
} from "redux/Modals/selectors";
import { SelectPlanModalData } from "types";
import {
  setQuotaLimitModalData,
  setShowDemoRequestModal,
  setSelectPlanModalData,
} from "redux/Modals/actions";

import styles from "./style.module.scss";
import XIcon from "assets/icons/XIcon";

const EllipseIcon = () => {
  return (
    <div className={styles.ellipseIcon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <circle
          cx="9.00005"
          cy="8.99992"
          r="7.18571"
          fill="#151928"
          stroke="white"
          stroke-width="3"
        />
      </svg>
    </div>
  );
};

const EnterprisePlanIcon = () => {
  return (
    <div className={styles.ellipseIcon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.626 10.0313C16.5929 10.0509 16.5596 10.0707 16.5261 10.0907C15.2591 10.8479 13.6356 11.9234 12.5333 13.0732C11.5374 14.112 10.5962 15.5984 9.93133 16.7531C9.9197 16.7733 9.90814 16.7934 9.89664 16.8134C9.86852 16.7715 9.84005 16.7292 9.81127 16.6867C9.03669 15.5406 7.97786 14.0839 6.94697 13.053C5.92071 12.0268 4.47249 10.9728 3.32879 10.1992C3.35395 10.1865 3.37921 10.1737 3.40457 10.1609C4.67843 9.51443 6.33281 8.56115 7.44697 7.44699C8.54283 6.35113 9.48305 4.73266 10.1287 3.46767C10.1372 3.48168 10.1458 3.49573 10.1543 3.5098C10.9252 4.77618 11.9951 6.38908 13.053 7.44699C14.0756 8.46953 15.4913 9.38082 16.626 10.0313Z"
          stroke="white"
          stroke-width="2.67844"
        />
      </svg>
    </div>
  );
};

const BlueCheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.26723 3.4551C6.91048 3.40375 7.52114 3.15073 8.01223 2.7321C8.56689 2.2597 9.27165 2.00024 10.0002 2.00024C10.7288 2.00024 11.4336 2.2597 11.9882 2.7321C12.4793 3.15073 13.09 3.40375 13.7332 3.4551C14.4597 3.51316 15.1417 3.82807 15.657 4.34337C16.1723 4.85867 16.4872 5.54067 16.5452 6.2671C16.5962 6.9101 16.8492 7.5211 17.2682 8.0121C17.7406 8.56677 18.0001 9.27153 18.0001 10.0001C18.0001 10.7287 17.7406 11.4334 17.2682 11.9881C16.8496 12.4792 16.5966 13.0898 16.5452 13.7331C16.4872 14.4595 16.1723 15.1415 15.657 15.6568C15.1417 16.1721 14.4597 16.487 13.7332 16.5451C13.09 16.5965 12.4793 16.8495 11.9882 17.2681C11.4336 17.7405 10.7288 18 10.0002 18C9.27165 18 8.56689 17.7405 8.01223 17.2681C7.52114 16.8495 6.91048 16.5965 6.26723 16.5451C5.54079 16.487 4.85879 16.1721 4.34349 15.6568C3.82819 15.1415 3.51328 14.4595 3.45523 13.7331C3.40387 13.0898 3.15086 12.4792 2.73223 11.9881C2.25982 11.4334 2.00037 10.7287 2.00037 10.0001C2.00037 9.27153 2.25982 8.56677 2.73223 8.0121C3.15086 7.52102 3.40387 6.91036 3.45523 6.2671C3.51328 5.54067 3.82819 4.85867 4.34349 4.34337C4.85879 3.82807 5.54079 3.51316 6.26723 3.4551ZM13.7072 8.7071C13.8894 8.5185 13.9902 8.2659 13.9879 8.0037C13.9856 7.74151 13.8805 7.49069 13.695 7.30529C13.5096 7.11988 13.2588 7.01471 12.9966 7.01243C12.7344 7.01015 12.4818 7.11095 12.2932 7.2931L9.00023 10.5861L7.70723 9.2931C7.51862 9.11095 7.26602 9.01015 7.00382 9.01243C6.74163 9.01471 6.49082 9.11988 6.30541 9.30529C6.12 9.49069 6.01483 9.74151 6.01255 10.0037C6.01027 10.2659 6.11107 10.5185 6.29323 10.7071L8.29323 12.7071C8.48075 12.8946 8.73506 12.9999 9.00023 12.9999C9.26539 12.9999 9.5197 12.8946 9.70723 12.7071L13.7072 8.7071Z"
        fill="#5DB5F0"
      />
    </svg>
  );
};

const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
    >
      <path
        d="M5.20828 9.36761L3.49317 7.65251L2.75671 8.38896L5.20828 10.8405L10.264 5.7848L9.52755 5.04834L5.20828 9.36761Z"
        fill="white"
      />
    </svg>
  );
};
interface SelectPlanModalProps {
  selectPlanData: SelectPlanModalData;
  attemptedImageCount: number;
}

const SelectPlanModal = ({
  selectPlanData,
  attemptedImageCount,
}: SelectPlanModalProps) => {
  const { show, calledFrom } = selectPlanData;

  const dispatch = useDispatch();

  const handleCloseClick = () => {
    if (calledFrom === "limit_modal") {
      dispatch(setQuotaLimitModalData({ show: true, attemptedImageCount }));
    }
    dispatch(
      setSelectPlanModalData({ show: false, calledFrom: "pricing_modal" })
    );
  };

  const handleLinkClick = (type: "discord" | "email") => {
    if (type === "discord") {
      window.open("https://discord.com/invite/tkYHJCA7mb", "_blank");
    }
    if (type === "email") {
      window.location.href = "mailto:support@visual-layer.com";
    }
  };

  const handleContactUsClick = () => {
    dispatch(setQuotaLimitModalData({ show: false, attemptedImageCount }));
    dispatch(
      setSelectPlanModalData({ show: false, calledFrom: "pricing_modal" })
    );
    dispatch(
      setShowDemoRequestModal({ flag: true, calledFrom: "upgrade_plan" })
    );
  };

  return !show ? (
    <noscript />
  ) : (
    <div className={styles.overlay} onClick={handleCloseClick}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalTitleContainer}>
          <div className={styles.modaltitle}>Explore Pricing Plans</div>
          <div className={styles.closeButton} onClick={handleCloseClick}>
            <XIcon color="#fff" />
          </div>
        </div>
        <span className={styles.modalDivder}></span>

        <div className={styles.content}>
          <div className={styles.plan}>
            <div className={styles.titleContainer}>
              <EllipseIcon />
              <div className={styles.title}>Starter Plan</div>
            </div>
            <span className={styles.subTitle}>
              Start unlocking your visual data value. Explore public datasets.
              No credit card required.
            </span>
            <div className={styles.priceContainer}>
              <span className={styles.price}>$ 0 </span>{" "}
              <span className={styles.priceSubtitle}>/Forever </span>
            </div>
            <span className={styles.divder}></span>

            <div className={styles.featuresList}>
              <div className={styles.featuresListItem}>
                <BlueCheckIcon />
                <span className={styles.featuresListItemTitle}>
                  Private Datasets
                </span>
              </div>
              <div className={styles.featuresListItem}>
                <BlueCheckIcon />
                <span className={styles.featuresListItemTitle}>
                  10 Datasets
                </span>
              </div>
              <div className={styles.featuresListItem}>
                <BlueCheckIcon />
                <span className={styles.featuresListItemTitle}>
                  10,000 Source images/videos frames
                </span>
              </div>
              <div className={styles.featuresListItem}>
                <BlueCheckIcon />
                <span className={styles.featuresListItemTitle}>
                  10,000 Enrichment Credits
                </span>
              </div>
            </div>

            <div className={styles.currentPlanButton}>
              <span className={styles.currentPlanButtonText}>Current Plan</span>
            </div>

            <div className={styles.keyFeaturesContainer}>
              <div className={styles.keyFeaturesTitle}>KEY FEATURES</div>
              <div className={styles.keyFeatureslist}>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    Interactive Cluster Exploration
                  </div>
                </div>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    Automatic Data Quality Analysis
                  </div>
                </div>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    Advanced Visual Search
                  </div>
                </div>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    Advanced Semantic Search
                  </div>
                </div>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    Basic Enrichment Models
                  </div>
                </div>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    Basic Data Export
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.supportContainer}>
              <div className={styles.keyFeaturesTitle}>SUPPORT</div>
              <div className={styles.keyFeatureslist}>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    <span
                      className={`${styles.normalText} ${styles.link}`}
                      onClick={() => handleLinkClick("discord")}
                    >
                      Discord,
                    </span>{" "}
                    <span
                      className={`${styles.normalText} ${styles.link}`}
                      onClick={() => handleLinkClick("email")}
                    >
                      Email
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* custom plan */}
          <div className={styles.plan}>
            <div className={styles.titleContainer}>
              <EnterprisePlanIcon />

              <div className={styles.title}>Enterprise Plan</div>
              <div className={styles.recommendedButton}>
                <span className={styles.buttonText}>RECOMMENDED</span>
              </div>
            </div>
            <span className={styles.subTitle}>
              Custom deployment options, including air-gapped environments.
              Dedicated support and SLAs.
            </span>
            <div className={styles.priceContainer}>
              <span className={styles.price}>Custom Pricing </span>
            </div>
            <span className={styles.divder}></span>

            <div className={styles.featuresList}>
              <div className={styles.featuresListItem}>
                <BlueCheckIcon />
                <span
                  className={`${styles.featureslistItemText} ${styles.boldText}`}
                >
                  Everything in Starten plan +
                </span>
              </div>
              <div className={styles.featuresListItem}>
                <BlueCheckIcon />
                <span className={styles.featuresListItemTitle}>
                  <span className={styles.boldText}>Unlimited</span> Datasets
                </span>
              </div>
              <div className={styles.featuresListItem}>
                <BlueCheckIcon />
                <span className={styles.featuresListItemTitle}>
                  <span className={styles.boldText}>Unlimited</span> Source
                  images/videos frames
                </span>
              </div>
              <div className={styles.featuresListItem}>
                <BlueCheckIcon />
                <span className={styles.featuresListItemTitle}>
                  <span className={styles.boldText}>Unlimited</span> Enrichment
                  Credits
                </span>
              </div>
            </div>

            <div
              className={styles.upgradePlanButton}
              onClick={handleContactUsClick}
            >
              <span className={styles.upgradePlanButtonText}>Upgrade Plan</span>
            </div>

            <div className={styles.keyFeaturesContainer}>
              <div className={styles.keyFeaturesTitle}>KEY FEATURES</div>
              <div className={styles.keyFeatureslist}>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div
                    className={`${styles.featureslistItemText} ${styles.boldText}`}
                  >
                    Everything in Starter Plan +
                  </div>
                </div>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    Advanced Data Ingestion Options
                  </div>
                </div>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    Advanced Export Options
                  </div>
                </div>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    Private VPC / On Prem Deployment
                  </div>
                </div>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    User Access Control
                  </div>
                </div>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    Custom Enrichment Models
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.supportContainer}>
              <div className={styles.keyFeaturesTitle}>SUPPORT</div>
              <div className={styles.keyFeatureslist}>
                <div className={styles.keyFeatureslistItem}>
                  <CheckIcon />
                  <div className={styles.featureslistItemText}>
                    <span
                      className={`${styles.normalText} ${styles.link}`}
                      onClick={() => handleLinkClick("discord")}
                    >
                      Discord,
                    </span>{" "}
                    <span
                      className={`${styles.normalText} ${styles.link}`}
                      onClick={() => handleLinkClick("email")}
                    >
                      Email
                    </span>
                    <span>, On Call, On Site</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectPlanData: getSelectPlanModalData(state),
    attemptedImageCount: getQuotaLimitModalData(state).attemptedImageCount,
  };
};
export default connect(mapStateToProps)(SelectPlanModal);
