import { useRef, useEffect, useState, useCallback } from "react";
import ContentLoad from "views/uikit/ContentLoad";
import styles from "./style.module.scss";

const VideoBox = ({ src, offset, duration = 2 }: VideoBoxProps) => {
  const [timestampPosition, setTimestampPosition] = useState("");
  const [showTimestamp, setShowTimestamp] = useState(false);
  const [hoveredOnce, setHoveredOnce] = useState(false);
  const [timestamp, setTimestamp] = useState("");
  const [isPlaying, setIsPlaying] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showContentLoader, setShowContentLoader] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const timestampRef = useRef<HTMLDivElement>(null);

  const handleShowTimestamp = (flag: boolean) => {
    if (offset) setShowTimestamp(flag);
    else setShowTimestamp(false);
  };

  const playVid = useCallback(async () => {
    const video = videoRef.current;

    if (video) {
      const SPECIAL_CHECK__IS_PLAYING =
        video.currentTime > 0 &&
        !video.paused &&
        !video.ended &&
        video.readyState > video.HAVE_CURRENT_DATA;
      if (!SPECIAL_CHECK__IS_PLAYING) {
        try {
          await video.play();
          setIsPlaying(true);
        } catch (error) {
          console.error("Error playing video:", error);
          setShowContentLoader(false);
        }
      }
    }
  }, []);

  const pauseVid = useCallback(() => {
    const video = videoRef.current;
    if (video && isPlaying) {
      video.pause();
      setIsPlaying(false);
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isLoaded) {
      const video = videoRef.current;
      if (!video) return;

      video.onplaying = () => setIsPlaying(true);
      video.onpause = () => setIsPlaying(false);
      if (offset && !hoveredOnce) {
        const startTime = Math.max(0, offset - Math.round(duration));
        video.currentTime = startTime;
        playVid();

        const pauseTimeout = setTimeout(() => {
          pauseVid();
          if (showContentLoader) {
            setShowContentLoader(false);
          }
        }, (duration * 2 + 1) * 1000);

        return () => clearTimeout(pauseTimeout);
      }
    }
    //eslint-disable-next-line
  }, [isLoaded, isPlaying]);

  useEffect(() => {
    const video = videoRef.current;
    if (isLoaded && video && video.duration) {
      const videoDuration = Math.floor(video.duration);
      const timestampSeconds = Math.floor(offset % videoDuration);
      let timestamp = new Date(timestampSeconds * 1000)
        .toISOString()
        .substr(11, 8);
      if (timestamp.startsWith("00:")) {
        timestamp = timestamp.substr(3);
      }
      setTimestamp(timestamp);

      const progressPercentage = Math.round(
        (timestampSeconds / videoDuration) * 100
      );
      setTimestampPosition(`${progressPercentage}%`);
    }
  }, [offset, isLoaded]);

  useEffect(() => {
    const tRef = timestampRef.current;
    const progressPercentage = timestampPosition.slice(0, -1);
    if (timestamp && tRef) {
      const tRefHelfWidth = Math.ceil(tRef.offsetWidth / 2);
      setTimestampPosition(`calc(${progressPercentage}% - ${tRefHelfWidth}px)`);
    }
    //eslint-disable-next-line
  }, [timestamp]);

  return (
    <div className={styles.videoContainer}>
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        controls
        autoPlay={!!offset}
        muted
        disablePictureInPicture
        playsInline
        onPlay={() => setIsLoaded(true)}
        onMouseEnter={() => {
          handleShowTimestamp(true);
          setHoveredOnce(true);
        }}
        onMouseLeave={() => handleShowTimestamp(false)}
        onError={() => setShowContentLoader(false)}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {timestamp && (
        <div className={styles.timestampWrapper}>
          <div className={styles.timestampContainer}>
            <div
              ref={timestampRef}
              className={styles.timestamp}
              style={{
                left: timestampPosition,
                visibility: showTimestamp ? "visible" : "hidden",
              }}
            >
              {timestamp}
            </div>
          </div>
        </div>
      )}
      {showContentLoader && (
        <div className={styles.contentLoader}>
          <ContentLoad />
        </div>
      )}
    </div>
  );
};

export default VideoBox;

interface VideoBoxProps {
  src: string;
  offset: number;
  duration: number;
}
