import { useState } from "react";
import Content from "../Content";
import VLAccordian from "views/components/Accordian";

import styles from "../style.module.scss";
import { MdOutlineTopic } from "react-icons/md";

function NoMetadata() {
  return (
    <div className={styles.noDataWrapper}>
      <div className={styles.noData}>
        Currently no Metadata available in this cluster.
      </div>
    </div>
  );
}

const MetadataSectionContentVideo = ({
  videoData,
  selectedID,
  setSelectedID,
}: any) => {
  if (videoData.length > 0) {
    return (
      <Content
        title={"Videos"}
        totalCount={videoData.length}
        type={"file-display"}
        data={videoData}
        sectionType={"metadata"}
        id={"metadata-1"}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
      />
    );
  } else {
    return <></>;
  }
};

const MetadataSectionContentLabels = ({
  labels,
  selectedID,
  setSelectedID,
}: any) => {
  if (labels.length > 0) {
    return (
      <>
        <Content
          title={"Labels"}
          totalCount={labels.length}
          type={"labels"}
          data={labels}
          sectionType={"metadata"}
          id={"metadata-2"}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
        />
      </>
    );
  } else {
    return <></>;
  }
};

const MetadataSectionContent = ({ videoData, labels, sectionHeight }: any) => {
  const [selectedID, setSelectedID] = useState(null);

  return (
    <div
      className={styles.section}
      style={{ height: !!sectionHeight ? sectionHeight : "max-content" }}
    >
      <MetadataSectionContentLabels
        labels={labels}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
      />
      <MetadataSectionContentVideo
        videoData={videoData}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
      />
    </div>
  );
};

const MetadataSection = ({
  videoData,
  labels,
  metadataRef,
  metadataCategory,
  sectionHeight,
  isLoading,
}: any) => {
  const handleDisplay = () => {
    if (isLoading) {
      return <div className={styles.loadingBox}>Loading...</div>;
    } else if (videoData.length > 0 || labels.length > 0) {
      return (
        <MetadataSectionContent
          videoData={videoData}
          labels={labels}
          sectionHeight={sectionHeight}
        />
      );
    } else return <NoMetadata />;
  };

  return (
    <div className={styles.metadataWrapper} ref={metadataRef}>
      <VLAccordian
        title="Metadata"
        icon={<MdOutlineTopic color="#373C4B" size={"1.55em"} />}
        childrenBgColor={"#151928"}
        roundCorners="10px"
        borderRadius="10px 10px 0 0"
        padding="0 18px 0 17px"
        height="47px"
        category={metadataCategory}
        initiallyClosed={true}
      >
        {handleDisplay()}
      </VLAccordian>
    </div>
  );
};
export default MetadataSection;
