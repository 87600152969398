import { BiChevronDown, BiChevronUp, BiX } from "react-icons/bi";
import { ImInfo } from "react-icons/im";

import {
  ISSUES_TOOLTIP_TEXT,
  SEVERITY_TOOLTIP_TEXT,
  ISSUE_TYPE_TOOLTIP_TEXT,
  IMAGE_LABELS_TOOLTIP_TEXT,
  OBJECT_LABELS_TOOLTIP_TEXT,
  IMAGE_CLASS_DISTRIBUTION_TOOLTIP_TEXT,
  OBJECT_CLASS_DISTRIBUTION_TOOLTIP_TEXT,
  VL_ENRICHMENT_TOOLTIP_TEXT,
  QUALITY_TOOLTIP_TEXT,
  USER_TAGS_TOOLTIP_TEXT,
  META_DATA_TOOLTIP_TEXT,
} from "helpers/constants/tooltips";

import TooltipWrapper from "views/uikit/TooltipWrapper";

import styles from "./style.module.scss";
import ContentLoad from "views/uikit/ContentLoad";

const AccordianHeader = ({
  icon,
  customIconMarginRight,
  title,
  isExpanded,
  toggleAccordion,
  hideInfo,
  fontSize,
  bgColor,
  padding,
  height,
  borderRadius,
  border,
  selectionCount,
  headerRef,
  type,
  showCrossNotChevron,
  handleCrossBtnClick,
  navigationClusterLoader,
  category,
  showTitleOnHover,
}: any) => {
  const handleTooltipContent = (title: string) => {
    switch (title?.toLowerCase()) {
      case "severity":
        return SEVERITY_TOOLTIP_TEXT;
      case "issue type":
        return ISSUE_TYPE_TOOLTIP_TEXT;
      case "issue":
        return ISSUES_TOOLTIP_TEXT;
      case "issues":
        return ISSUES_TOOLTIP_TEXT;
      case "image class distribution":
        return IMAGE_CLASS_DISTRIBUTION_TOOLTIP_TEXT;
      case "object class distribution":
        return OBJECT_CLASS_DISTRIBUTION_TOOLTIP_TEXT;
      case "image labels":
        return IMAGE_LABELS_TOOLTIP_TEXT;
      case "object labels":
        return OBJECT_LABELS_TOOLTIP_TEXT;
      case "vl enrichment":
        return VL_ENRICHMENT_TOOLTIP_TEXT;
      case "quality":
        return QUALITY_TOOLTIP_TEXT;
      case "user tags":
        return USER_TAGS_TOOLTIP_TEXT;
      case "metadata":
        return META_DATA_TOOLTIP_TEXT;
    }
  };

  const textOverflowStyle = !!type
    ? {
        maxWidth: "18ch",
        minWidth: "unset",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }
    : {};

  return (
    <div
      ref={headerRef}
      className={
        isExpanded
          ? styles.accordianHeaderWrapperExpand
          : styles.accordianHeaderWrapper
      }
      style={{
        backgroundColor: bgColor,
        padding,
        height,
        borderRadius,
        border,
      }}
      onClick={toggleAccordion}
    >
      <div className={styles.leftContainer}>
        {icon && (
          <div
            className={styles.icon}
            style={
              customIconMarginRight
                ? { marginRight: customIconMarginRight }
                : undefined
            }
          >
            {icon}
          </div>
        )}

        {navigationClusterLoader ? (
          <div className={styles.navigationClusterTitleLoader}>
            <ContentLoad />
          </div>
        ) : (
          <span
            className={styles.title}
            style={{ ...(textOverflowStyle as any), fontSize }}
            title={showTitleOnHover ? showTitleOnHover : !!type ? title : ""}
          >
            {title}
          </span>
        )}
        {selectionCount > 0 && (
          <div className={styles.selectionCountBox}>+{selectionCount}</div>
        )}
        {category && <div className={styles.categoryBox}>{category}</div>}
      </div>
      <div className={styles.rightContainer}>
        {hideInfo ? null : (
          <div
            data-tooltip-id={`${title?.toLowerCase()}-tooltip`}
            data-tooltip-content={handleTooltipContent(title)}
            className={styles.infoIcon}
          >
            <ImInfo size="0.75em" />
            <TooltipWrapper id={`${title?.toLowerCase()}-tooltip`} />
          </div>
        )}
        {showCrossNotChevron ? (
          <div className={styles.removeIcon} onClick={handleCrossBtnClick}>
            <BiX size="1.55em" color="#fff" />
          </div>
        ) : (
          <div className={styles.triggerIcon}>
            {isExpanded ? (
              <BiChevronUp size="1.55em" color="#fff" />
            ) : (
              <BiChevronDown size="1.55em" color="#fff" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default AccordianHeader;
