import maxAmount from "assets/img/maxAmountDataset.svg";
import styles from "./style.module.scss";

interface MaxAmountDatasetProps {
  limitErrorMessage: string;
}

function MaxAmountDataset({ limitErrorMessage }: MaxAmountDatasetProps) {
  return (
    <div className={styles.selectSourceType}>
      <div className={styles.basicDetailsTop}>
        <span className={styles.upgrade}>{limitErrorMessage}</span>
      </div>
      <div className={styles.desc}>
        <span className={styles.description}>
          To add new Dataset, please delete one of existing Datasets and try
          again. To remove these limitations and unlock more features, submit
          and upgrade inquiry.
        </span>
      </div>
      <div>
        <div className={styles.basicDetailsBelow}>
          <div className={styles.maxAmountImg}>
            <img src={maxAmount} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default MaxAmountDataset;
