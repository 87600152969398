import { useState, RefObject, useRef, useEffect, useMemo } from "react";
import { setDeleteModalId } from "redux/Datasets/actions";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { CopyURLTypes, DatasetStatusEnum } from "types";

import { displayForamttedDate, kFormatter } from "helpers/utility/formatters";
import DatasetStatusButton from "views/pages/DatasetInventory/DatasetCard/DatasetStatusButton";
import { useDatasetVersion, useWindowResize } from "helpers/utility/utilities";
import EditableDatasetTitle from "views/components/EditableDatasetTitle";

import ImageIcon from "assets/icons/imageIcon";

import DatasetHamburger from "views/components/DatasetHamburger";
import DatasetDeletionModal from "views/modals/DatasetDeletionModal";

import ContentLoad from "views/uikit/ContentLoad";

import styles from "./style.module.scss";

interface DatasetCardTypes {
  dataset: any;
  cardElement: RefObject<HTMLDivElement>;
  LoadDataset: (
    datasetId: string,
    datasetName: string,
    datasetSource: string,
    similarity_data: boolean
  ) => void;
  deleteModalId: string | null;
  isPrivatePath: boolean;
  isNewDataIngestionEnabled: boolean;
  userId: string;
  setCardWitdh: (value: number) => void;
}

type FontStyle = {
  fontSize: string;
  lineHeight: string;
};

type DynamicFontStyles = {
  title: FontStyle | undefined;
  createdAt: FontStyle | undefined;
  count: FontStyle | undefined;
  datasetStatus: FontStyle | undefined;
  label: FontStyle | undefined;
};

type StatusContainerStyle = { display: string } | { width: string };

const OptionsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="23"
    viewBox="0 0 5 23"
    fill="none"
  >
    <circle
      cx="2.40425"
      cy="20.0352"
      r="2.40434"
      transform="rotate(-180 2.40425 20.0352)"
      fill="white"
    />
    <circle
      cx="2.40425"
      cy="11.2188"
      r="2.40434"
      transform="rotate(-180 2.40425 11.2188)"
      fill="white"
    />
    <circle
      cx="2.40425"
      cy="2.40431"
      r="2.40434"
      transform="rotate(-180 2.40425 2.40431)"
      fill="white"
    />
  </svg>
);

const DatasetCard = ({
  dataset,
  cardElement,
  LoadDataset,
  deleteModalId,
  isPrivatePath,
  isNewDataIngestionEnabled,
  userId,
  setCardWitdh,
}: DatasetCardTypes) => {
  const [showDeleteDatasetModal, setShowDeleteDatasetModal] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [statusContainerStyle, setStatusContainerStyle] =
    useState<StatusContainerStyle>({ display: "none" });
  const [dynamicFontStyles, setDynamicFontStyles] = useState<DynamicFontStyles>(
    {
      title: undefined,
      createdAt: undefined,
      count: undefined,
      datasetStatus: undefined,
      label: undefined,
    }
  );

  const createdAtTextRef = useRef<HTMLDivElement>(null);
  const imageCountRef = useRef<HTMLDivElement>(null);
  const dataCardRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowResize();
  const dsVersion = useDatasetVersion(dataset?.pipeline_commit_id);

  const editableTitleFontStyle = {
    ...dynamicFontStyles.title,
    height: dynamicFontStyles.title?.lineHeight,
    width: "100%",
  };

  const handleFormattedText = (value: number) => {
    let formattedText = kFormatter(value);
    //check if fomatted float has more than 2 integer length
    if (formattedText.includes(".")) {
      if (formattedText.split(".")[0].length > 2) return kFormatter(value, 0);
    }
    return formattedText;
  };

  const inProgressDataset = dataset.status !== DatasetStatusEnum.READY;

  const imageCountText = inProgressDataset
    ? ".."
    : handleFormattedText(dataset.n_images);
  const objectCountText = inProgressDataset
    ? ".."
    : handleFormattedText(dataset.n_objects);
  const videoCountText = inProgressDataset
    ? ".."
    : handleFormattedText(dataset.n_videos);
  const frameCountText = inProgressDataset
    ? ".."
    : handleFormattedText(dataset.n_video_frames);

  const copyButtonURL: CopyURLTypes = useMemo((): CopyURLTypes => {
    if (dataset.status === DatasetStatusEnum.READY) {
      return "/data";
    } else if (
      isNewDataIngestionEnabled &&
      dataset.status !== DatasetStatusEnum.FATAL_ERROR
    ) {
      return "/create";
    } else {
      return null;
    }
  }, [dataset.status, isNewDataIngestionEnabled]);

  const createAtContainerStyles = dynamicFontStyles.createdAt
    ? {
        height: `calc(${dynamicFontStyles.createdAt.lineHeight} + 8px)`,
      }
    : undefined;

  const optionIconStyle = dynamicFontStyles.title
    ? {
        height: `calc(${dynamicFontStyles.title?.fontSize} - 6px)`,
      }
    : undefined;

  const dynamicTitleStyle = !!dynamicFontStyles.title
    ? { ...dynamicFontStyles.title, width: `calc(100% - 46px)` }
    : dynamicFontStyles.title;

  const handleOptionsButtonClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (dataset.id === deleteModalId) {
      dispatch(setDeleteModalId(null) as any);
    } else {
      dispatch(setDeleteModalId(dataset.id) as any);
    }
  };

  const handleTitle = (title: string) => {
    if (title === "0") return undefined;
    else return title;
  };

  const handleCountContainerStyle = () => {
    const imageCount = imageCountRef.current;
    let maxWidth = "";
    if (imageCount) {
      maxWidth = `calc((100% - ${imageCount.offsetWidth + 26}px) / 3)`;
    }
    if (maxWidth) return { maxWidth };
    return undefined;
  };

  const handleMouseLeave = () => {
    dispatch(setDeleteModalId(null) as any);
  };

  const handleDataCardClick = () => {
    switch (dataset.status as DatasetStatusEnum) {
      case DatasetStatusEnum.READY:
        LoadDataset(
          dataset.id,
          dataset.display_name,
          dataset.source_type,
          dataset.similarity_data
        );
        return;
      case DatasetStatusEnum.INITIALIZING:
      case DatasetStatusEnum.INDEXING:
        if (isNewDataIngestionEnabled) {
          navigate(`/dataset/${dataset.id}/create`);
        }
        return;

      default:
        return;
    }
  };

  const handleImageLoad = () => {
    if (cardElement.current) {
      setCardWitdh(cardElement.current.offsetWidth);
    }
    setIsImageLoading(false);
  };

  const handleImageError = () => {
    setImageLoadError(true);
  };

  const handleStatusButtonStyle = () => {
    const ref = createdAtTextRef.current;
    if (ref) {
      let width = `calc(100% - (${ref.offsetWidth}px + 5px))`;
      setStatusContainerStyle({ width: width });
    } else {
      setStatusContainerStyle({ display: "none" });
    }
  };

  const handleLabelStyle = () => {
    if (dynamicFontStyles && dynamicFontStyles.title?.lineHeight) {
      const lineHeight: any =
        dynamicFontStyles.title?.lineHeight.split("px")[0];

      let paddingV = lineHeight * 0.1238;
      let paddingH = lineHeight * 0.2296;
      return {
        padding: `${paddingV}px ${paddingH}px`,
        borderRadius: `${paddingV}px `,
      };
    }
  };

  const renderEditableTitle = () => {
    if (!dataset) {
      return <noscript />;
    }

    //If not creator, show title only
    if (dataset.created_by !== userId) {
      const normalTitleStyle = () => {
        const dataCardWidth = dataCardRef.current?.offsetWidth;
        if (dataCardWidth) {
          return {
            ...dynamicFontStyles.title,
            maxWidth: `calc(100% - ${Math.round(
              dataCardWidth * 0.1682
            )}px + 8px)`,
          };
        } else {
          return dynamicFontStyles.title;
        }
      };

      return (
        <>
          <div className={styles.normalTitleContainer}>
            <span
              title={dataset.display_name}
              style={normalTitleStyle()}
              className={styles.titleText}
            >
              {dataset.display_name}
            </span>
            <div className={styles.datasetTypeLable} style={handleLabelStyle()}>
              {dataset.sample ? (
                <span
                  style={dynamicFontStyles.label}
                  className={styles.lableText}
                >
                  PUBLIC
                </span>
              ) : (
                <span
                  className={styles.lableText}
                  style={dynamicFontStyles.label}
                >
                  PRIVATE
                </span>
              )}
            </div>
          </div>
        </>
      );
    } else
      return (
        <EditableDatasetTitle
          datasetID={dataset.id}
          title={dataset.display_name}
          fontStyle={editableTitleFontStyle}
          editOnlyOnHover={true}
          datasetSample={dataset.sample}
          labelStyle={handleLabelStyle()}
        />
      );
  };

  useEffect(() => {
    setImageLoadError(false);
  }, [dataset.preview_uri]);

  useEffect(() => {
    const dataCardWidth = dataCardRef.current?.offsetWidth;
    if (dataCardWidth) {
      setDynamicFontStyles({
        title: {
          fontSize: `${Math.round(dataCardWidth * 0.0696)}px`,
          lineHeight: `${Math.round(dataCardWidth * 0.0725)}px`,
        },
        createdAt: {
          fontSize: `${Math.round(dataCardWidth * 0.0464)}px`,
          lineHeight: `${Math.round(dataCardWidth * 0.0696)}px`,
        },
        count: {
          fontSize: `${Math.round(dataCardWidth * 0.058)}px`,
          lineHeight: `${Math.round(dataCardWidth * 0.0696)}px`,
        },
        datasetStatus: {
          fontSize: `${Math.round(dataCardWidth * 0.0306)}px`,
          lineHeight: `${Math.round(dataCardWidth * 0.0373)}px`,
        },
        label: {
          fontSize: `${Math.round(dataCardWidth * 0.0348)}px`,
          lineHeight: `${Math.round(dataCardWidth * 0.0345)}px`,
        },
      });
      handleStatusButtonStyle();
    }
  }, [width, dataset, dataCardRef, createdAtTextRef]);

  return (
    <div
      ref={cardElement}
      className={styles.datacardWrapper}
      onClick={handleDataCardClick}
    >
      <div
        className={styles.dataCard}
        ref={dataCardRef}
        onMouseLeave={handleMouseLeave}
      >
        <div className={styles.cardHead}>
          <div className={styles.titleContainer}>
            <div style={dynamicTitleStyle} className={styles.title}>
              {renderEditableTitle()}
            </div>
            <div
              className={styles.optionsButton}
              onClick={handleOptionsButtonClick}
              style={optionIconStyle}
            >
              <OptionsIcon />

              <DatasetHamburger
                url={dataset.id}
                setShowDeleteDatasetModal={setShowDeleteDatasetModal}
                datasetId={dataset.id}
                deleteModalId={deleteModalId}
                isPrivatePath={isPrivatePath}
                hasSimilarityData={dataset.similarity_data}
                copyButtonURL={copyButtonURL}
              />
            </div>
          </div>
          <div
            className={styles.createAtContainer}
            style={createAtContainerStyles}
          >
            <div
              ref={createdAtTextRef}
              className={styles.createdAtText}
              style={dynamicFontStyles.createdAt}
            >
              Created {displayForamttedDate(dataset.created_at)}{" "}
              {!!dsVersion ? (
                <span>
                  <br />
                  {`(v${dsVersion})`}
                </span>
              ) : (
                <noscript />
              )}
            </div>
            <div
              className={styles.stausButtonContainer}
              style={statusContainerStyle}
            >
              <DatasetStatusButton
                status={dataset.status}
                datasetId={dataset.id}
                errorMessage={dataset.fatal_error_msg || ""}
                buttonStyle={dynamicFontStyles.datasetStatus}
              />
            </div>
          </div>

          <div className={styles.infoContainer}>
            <div
              className={styles.countContainer}
              key="imageCount"
              ref={imageCountRef}
            >
              <div className={styles.countTitle}>Images</div>
              <div
                style={dynamicFontStyles.count}
                className={styles.count}
                title={handleTitle(imageCountText)}
              >
                {imageCountText}
              </div>
            </div>

            <div
              className={styles.countContainer}
              style={handleCountContainerStyle()}
              key="objectCount"
            >
              <div className={styles.countTitle}>Objects</div>
              <div
                className={styles.count}
                style={dynamicFontStyles.count}
                title={handleTitle(objectCountText)}
              >
                {objectCountText}
              </div>
            </div>

            <div
              className={styles.countContainer}
              style={handleCountContainerStyle()}
              key="videoCount"
            >
              <div className={styles.countTitle}>Videos</div>
              <div
                className={styles.count}
                style={dynamicFontStyles.count}
                title={handleTitle(videoCountText)}
              >
                {videoCountText}
              </div>
            </div>

            <div
              className={styles.countContainer}
              style={handleCountContainerStyle()}
              key="frameCount"
            >
              <div className={styles.countTitle}>Video Frames</div>
              <div
                className={styles.count}
                style={dynamicFontStyles.count}
                title={handleTitle(frameCountText)}
              >
                {frameCountText}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.cardBody}>
          {isImageLoading && <ContentLoad radius="33px" />}
          <div
            className={
              isImageLoading ? styles.cardImageLoading : styles.cardImage
            }
          >
            {imageLoadError ? (
              <div className={styles.imagePlaceholderIcon}>
                <ImageIcon color="#373C4B" />
              </div>
            ) : (
              <img
                src={dataset.preview_uri}
                alt="dataset card img"
                loading="lazy"
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            )}
          </div>
        </div>
      </div>
      <DatasetDeletionModal
        datasetId={dataset.id}
        datasetName={dataset.display_name}
        datasetSource={dataset.source_type}
        showModal={showDeleteDatasetModal}
        setShowModal={setShowDeleteDatasetModal}
      />
    </div>
  );
};

export default DatasetCard;
