import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import axios from "helpers/axiosInstance";
import { composeApiURL } from "helpers/utility/axiosHelpers";

import { State } from "redux/store";
import {
  RESET_STORE,
  SET_ACTIVITY_LOGS,
  SET_ACTIVITY_OFFSET,
  SET_ANNOTATION_STATUS,
  SET_CONNECT_STATUS,
  SET_IS_FATAL_ERROR,
  SET_IMAGE_ERROR_FILE_NAME,
  SET_NEW_DATASET,
  SET_PREVIEW_DATA,
  SET_PREVIEW_DATA_SOURCE,
  SET_S3_FETCH_PROGRESS,
  SET_UPLOAD_STATUS,
  SET_FILES_UPLOAD_TRANS_ID,
  SET_INDEXING_STATUS,
  SET_LIMIT_EXCEEDED_DETAILS,
  SET_ARE_ANNOTATIONS_FETCHED,
  SET_STATUS_CALL_IN_PROGRESS,
  SET_IS_LOADING,
  SET_SHOW_DELETE_DATASET_MODAL,
  SET_DATASET_STATUS,
  SET_LAST_USED_DATA,
  SET_UPLOAD_FINISHED,
  SET_USER_INITIATED_CREATION,
  SET_FILES_UPLOAD_SIZE,
  SET_FILES_UPLOAD_PROGRESS,
  SET_SHOW_ACTIVITY_LOG_MODAL,
} from "./constants";
import {
  ActivityLogShowModalType,
  DataPreview,
  DatasetStatusEnum,
  DataSources,
  FilesUploadSize,
  LastUsedData,
  StepTileProgressStatus,
} from "types";
import { getRequest } from "helpers/utility/axiosServiceLayer";
import {
  DatasetInitialized,
  DataSourceEvent,
  Event,
  FileMediaPreview,
  LimitExceeded,
  S3MediaPreview,
} from "extractedBackendTypes";
import {
  getActivityLogs,
  getConnectStatus,
  getActivityOffset,
  getAnnotationStatus,
  getAreAnnotationsFetched,
  getFilesUploadTransId,
  getIsNewDatasetInitializing,
  getLastUsedData,
  getPreviewData,
  getPreviewDataSource,
  getStatusCallInProgress,
  getUserInitiatedCreation,
  getFilesUploadSize,
} from "./selectors";
import { setLimitError } from "redux/Datasets/actions";
import { AxiosProgressEvent } from "axios";
import { setQuotaLimitModalData } from "redux/Modals/actions";
import { getIsUsageReportEnabled } from "redux/Datasets/selectors";
import { checkUserQuota } from "redux/User/actions";
import abortControllerManager from "helpers/abortControllerManager";
import {
  trackAnnotation,
  trackDataSourceAdded,
  trackFatalError,
  trackNewDatasetIngestionEvent,
} from "helpers/utility/amplitude";
import {
  AMP_DATASET_INGESTION_EVENT__DATASET_CREATION_INITIATED,
  AMP_DATASET_INGESTION_EVENT__DATASET_CREATION_SUCCESS,
  AMP_DATASET_INGESTION_EVENT__INDEXING_STARTED,
  AMP_DATASET_INGESTION_EVENT__QUOTA_LIMIT_EXCEEDED,
} from "helpers/constants/amplitudeEvents";
import {
  AMP_DATASET_CREATION_PROPERTY__ALLOWED_UPLOAD_COUNT,
  AMP_DATASET_CREATION_PROPERTY__ATTEMPTED_UPLOAD_COUNT,
  AMP_DATASET_CREATION_PROPERTY__UPLOAD_SOURCE,
} from "helpers/constants/amplitudeProperties";
import { getUserQuota } from "redux/User/selectors";

const HOST_URL = process.env.REACT_APP_API_ENDPOINT!;

export const resetStore = () => {
  return { type: RESET_STORE };
};

export const setConnectStatus = (data: StepTileProgressStatus) => {
  return { type: SET_CONNECT_STATUS, data };
};

export const setAnnotationStatus = (data: StepTileProgressStatus) => {
  return { type: SET_ANNOTATION_STATUS, data };
};

export const setUploadStatus = (data: StepTileProgressStatus) => {
  return { type: SET_UPLOAD_STATUS, data };
};

export const setIndexingStatus = (data: StepTileProgressStatus) => {
  return { type: SET_INDEXING_STATUS, data };
};

export const setPreviewData = (data: DataPreview[]) => {
  return { type: SET_PREVIEW_DATA, data };
};

export const setPreviewDataSource = (data: DataSources | null) => {
  return { type: SET_PREVIEW_DATA_SOURCE, data };
};

export const setNewDataset = (data: DatasetInitialized | null) => {
  return { type: SET_NEW_DATASET, data };
};

export const setS3FetchProgress = (data: number) => {
  return { type: SET_S3_FETCH_PROGRESS, data };
};

export const setActivityLogs = (data: any[]) => {
  return { type: SET_ACTIVITY_LOGS, data };
};

export const setImageErrorFileName = (data: string) => {
  return { type: SET_IMAGE_ERROR_FILE_NAME, data };
};

export const setActivityOffset = (data: number) => {
  return { type: SET_ACTIVITY_OFFSET, data };
};

export const setIsFatalError = (data: boolean) => {
  return { type: SET_IS_FATAL_ERROR, data };
};

export const setFilesUploadTransId = (data: string | null) => {
  return { type: SET_FILES_UPLOAD_TRANS_ID, data };
};

export const setLimitExceededDetails = (data: LimitExceeded | null) => {
  return { type: SET_LIMIT_EXCEEDED_DETAILS, data };
};

export const setAreAnnotationsFetched = (data: boolean) => {
  return { type: SET_ARE_ANNOTATIONS_FETCHED, data };
};

export const setStatusCallInProgress = (data: boolean) => {
  return { type: SET_STATUS_CALL_IN_PROGRESS, data };
};

export const setIsLoading = (data: boolean) => {
  return { type: SET_IS_LOADING, data };
};

export const setShowDeleteDatasetModal = (data: boolean) => {
  return { type: SET_SHOW_DELETE_DATASET_MODAL, data };
};

export const setDatasetStatus = (data: DatasetStatusEnum | null) => {
  return { type: SET_DATASET_STATUS, data };
};

export const setLastUsedData = (data: LastUsedData) => {
  return { type: SET_LAST_USED_DATA, data };
};

export const setUploadFinished = (data: boolean) => {
  return { type: SET_UPLOAD_FINISHED, data };
};

export const setUserInitiatedCreation = (data: boolean) => {
  return { type: SET_USER_INITIATED_CREATION, data };
};

export const setFilesUploadSize = (data: FilesUploadSize | null) => {
  return { type: SET_FILES_UPLOAD_SIZE, data };
};

export const setFilesUploadProgress = (data: number | null) => {
  return { type: SET_FILES_UPLOAD_PROGRESS, data };
};

export const checkS3Bucket = (
  newDatasetId: string,
  s3URI: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const isCalledAgain =
      getConnectStatus(getState()) === StepTileProgressStatus.COMPLETE;

    //track Data Source  event
    trackDataSourceAdded(isCalledAgain, DataSources.S3);

    try {
      dispatch(setConnectStatus(StepTileProgressStatus.IN_PROGRESS));
      const url = composeApiURL(
        HOST_URL,
        `/ingestion/${newDatasetId}/s3connect?s3_uri=${s3URI}`
      );
      const response = await axios.get(url);
      if (response.status === 202 || response.status === 200) {
        dispatch(setPreviewData([]));
        dispatch(setUploadFinished(true));
      } else {
        dispatch(setConnectStatus(StepTileProgressStatus.ACTIVE));
      }
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const checkZip = (
  newDatasetId: string,
  file: File
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState) => {
    const isCalledAgain =
      getConnectStatus(getState()) === StepTileProgressStatus.COMPLETE;

    //track Data Source  event
    trackDataSourceAdded(isCalledAgain, DataSources.ZIP);

    try {
      dispatch(setUploadFinished(false));
      dispatch(setConnectStatus(StepTileProgressStatus.IN_PROGRESS));
      const url = composeApiURL(HOST_URL, `/ingestion/${newDatasetId}/archive`);
      const requestBody = new FormData();
      requestBody.append("archive_upload_file", file);
      dispatch(setFilesUploadProgress(0));
      dispatch(setFilesUploadSize({ length: 1, bytes: file.size }));
      const res = await axios.post(url, requestBody, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const totalLength = progressEvent.total;
          if (!!totalLength) {
            const progress = Math.min(
              Math.round((progressEvent.loaded * 100) / totalLength),
              100
            );
            dispatch(setFilesUploadProgress(progress));
          }
        },
      });
      if (res.status === 413) {
        dispatch(setFilesUploadProgress(null));
      }
      dispatch(setUploadFinished(true));
    } catch (error: any) {
      console.log(error);
      dispatch(setConnectStatus(StepTileProgressStatus.ACTIVE));
    }
  };
};

export const checkFolder = (
  newDatasetId: string,
  files: FileList | null
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const isCalledAgain =
      getConnectStatus(getState()) === StepTileProgressStatus.COMPLETE;

    //track Data Source event
    trackDataSourceAdded(isCalledAgain, DataSources.FOLDER);

    try {
      if (!files) return;

      dispatch(setUploadFinished(false));
      const filesArray = Array.from(files);
      dispatch(setFilesUploadProgress(0));
      dispatch(
        setFilesUploadSize({
          length: filesArray.length,
          bytes: filesArray.reduce((sum, file) => sum + file.size, 0),
        })
      );
      dispatch(setConnectStatus(StepTileProgressStatus.IN_PROGRESS));
      dispatch(setAnnotationStatus(StepTileProgressStatus.DISABLED));
      dispatch(setIndexingStatus(StepTileProgressStatus.DISABLED));
      dispatch(setUploadStatus(StepTileProgressStatus.ACTIVE));
      const abortController = abortControllerManager.getController();
      let url = composeApiURL(
        HOST_URL,
        `/ingestion/${newDatasetId}/data_files`
      );
      const response = await axios.get(url, {
        signal: abortController.signal,
      });
      let transId: string | null = null;
      if (response.status === 200) {
        transId = response.data.trans_id;
        dispatch(setFilesUploadTransId(transId));
        dispatch(setPreviewData([]));
      }
      if (!transId) {
        throw new Error("Couldn't preform bulk upload, failed to start.");
      }

      const chunkSize = 250;
      url = composeApiURL(
        HOST_URL,
        `/ingestion/${newDatasetId}/data_files/${transId}`
      );
      for (let i = 0; i < filesArray.length; i += chunkSize) {
        const chunk = filesArray.slice(i, i + chunkSize);
        const requestBody = new FormData();
        chunk.forEach((file) => {
          requestBody.append("files", file);
        });
        const isLastChunk = i + chunkSize >= filesArray.length;
        const urlWithParams = `${url}?last_chunk=${isLastChunk}`;

        const res = await axios.post(urlWithParams, requestBody, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          signal: abortController.signal,
        });
        //in case limit exceeded:
        if (res.status === 413) {
          dispatch(setUploadFinished(true));
          dispatch(setFilesUploadProgress(null));
          break;
        } else {
          const percentageCompleted = Math.min(
            ((i + chunk.length) / filesArray.length) * 100,
            100
          );
          dispatch(setFilesUploadProgress(percentageCompleted));
        }
      }
      dispatch(setUploadFinished(true));
    } catch (error: any) {
      if (error.response?.status === 413) {
        dispatch(setUploadFinished(true));
        dispatch(setFilesUploadProgress(null));
      } else {
        dispatch(setConnectStatus(StepTileProgressStatus.ACTIVE));
      }
    }
  };
};

export const createNewDataset = (
  name: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(
        HOST_URL,
        `/ingestion/new_dataset?name=${name}`
      );
      const responseData = await getRequest<DatasetInitialized>(url);

      dispatch(setNewDataset(responseData));
      //BE response contains id but type specifies dataset_id -TBD
      //@ts-ignore-next-line
      const newDatasetId = responseData.id;

      //Track New Dataset Initialization event
      trackNewDatasetIngestionEvent(
        AMP_DATASET_INGESTION_EVENT__DATASET_CREATION_INITIATED
      );

      return newDatasetId;
    } catch (error: any) {
      const errorResponse: { status: number; data: { detail: string } } =
        error.response;
      if (errorResponse.status === 400) {
        dispatch(setLimitError(errorResponse.data.detail));
      }
      return "";
    }
  };
};

export const fetchNewDataset = (
  uuid: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${uuid}/stats`
      );
      const response = await axios.get(url);
      const newDataset = response.data.dataset;
      dispatch(setNewDataset(newDataset));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsFatalError(true));
      dispatch(setNewDataset(null));
      dispatch(setIsLoading(false));
      console.log(error);
    }
  };
};

export const updateDatasetName = (
  name: string,
  uuid: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(
        process.env.REACT_APP_API_ENDPOINT!,
        `/dataset/${uuid}`
      );
      const requestBody = new FormData();
      requestBody.append("name", name);

      const response = await axios.post(url, requestBody, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        dispatch(fetchNewDataset(uuid) as any);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchEventLogs = (
  newDatasetId: string,
  isFirstCall: boolean
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      if (getStatusCallInProgress(state)) return;

      const offset = getActivityOffset(state);
      const existingLogs = getActivityLogs(state);
      const existingLastUsedData = getLastUsedData(state);
      const existingPreviewData = getPreviewData(state);
      const isNewDatasetInitializing = getIsNewDatasetInitializing(state);

      const isConntectStateInprogress =
        getConnectStatus(state) === StepTileProgressStatus.IN_PROGRESS;

      const abortController = abortControllerManager.getController();
      const url = composeApiURL(
        HOST_URL,
        `/ingestion/${newDatasetId}/status?offset=${offset}`
      );

      dispatch(setStatusCallInProgress(true));
      const fetchResponse = await axios.get(url, {
        signal: abortController.signal,
      });
      dispatch(setStatusCallInProgress(false));
      if (!fetchResponse.data) {
        return;
      }
      const fetchResponseData: any[] = fetchResponse.data;

      // Make a copy of existing logs and previews to update
      let updatedLogs: Event[] = [...existingLogs];
      let updatedPreviewData = [...existingPreviewData];
      let updatedLastUsedData: LastUsedData = { ...existingLastUsedData };

      const isFirstLog = offset === 0 && fetchResponseData.length === 1;

      if (isFirstLog && updatedLogs.length === 1) {
        return;
      }

      fetchResponseData.forEach((event: any) => {
        // handle first log
        if (event.serial === 0 && isFirstCall) {
          updatedLogs.push(event);
        }
        // Check if event serial is greater than  offset
        else if (event.serial >= offset) {
          updatedLogs.push(event);

          switch (event.event_type) {
            case "AnnotationsValid":
              updatedPreviewData = [];
              updatedLastUsedData = {
                ...updatedLastUsedData,
                annotationFiles: event.file_names,
              };
              dispatch(setAreAnnotationsFetched(false));
              dispatch(setAnnotationStatus(StepTileProgressStatus.COMPLETE));

              break;
            case "AnnotationsCleared":
              updatedPreviewData = [];
              break;

            case "AnnotationsInvalid":
              dispatch(setConnectStatus(StepTileProgressStatus.COMPLETE));
              dispatch(setAnnotationStatus(StepTileProgressStatus.ACTIVE));
              break;

            case "DatasetInitializationFailed":
            case "S3InvalidURL":
            case "S3NoAccess":
            case "S3Error":
              updatedPreviewData = [];
              dispatch(setConnectStatus(StepTileProgressStatus.ACTIVE));
              dispatch(setAnnotationStatus(StepTileProgressStatus.DISABLED));
              break;

            case "InvalidInput":
              updatedPreviewData = [];
              dispatch(setConnectStatus(StepTileProgressStatus.ACTIVE));
              dispatch(setAnnotationStatus(StepTileProgressStatus.DISABLED));
              break;
            case "ServerFailure":
              if (isConntectStateInprogress) {
                dispatch(setConnectStatus(StepTileProgressStatus.ACTIVE));
                dispatch(setAnnotationStatus(StepTileProgressStatus.DISABLED));
              }
              break;
            case "FileMediaPreview":
            case "S3MediaPreview":
              const mediaPreviewEvent: S3MediaPreview | FileMediaPreview =
                event;
              updatedPreviewData.push(mediaPreviewEvent);
              if (
                (mediaPreviewEvent.image_annotations &&
                  mediaPreviewEvent.image_annotations.length > 0) ||
                (mediaPreviewEvent.object_annotations &&
                  mediaPreviewEvent.object_annotations?.length > 0)
              ) {
                dispatch(setAreAnnotationsFetched(true));
              }
              break;

            case "PreviewReady":
              dispatch(setS3FetchProgress(-1));
              dispatch(setConnectStatus(StepTileProgressStatus.COMPLETE));
              if (getAreAnnotationsFetched(getState())) {
                dispatch(setAnnotationStatus(StepTileProgressStatus.COMPLETE));
              } else if (
                getAnnotationStatus(getState()) ===
                StepTileProgressStatus.DISABLED
              ) {
                dispatch(setAnnotationStatus(StepTileProgressStatus.ACTIVE));
              }
              break;

            case "S3Connected":
              updatedPreviewData = [];
              updatedLastUsedData = {
                ...updatedLastUsedData,
                s3Url: event.s3_url,
              };

              dispatch(setAreAnnotationsFetched(false));
              break;

            case "DatasetStatus":
              dispatch(setDatasetStatus(event.status.split(".")[1]));

              dispatch(setAnnotationStatus(StepTileProgressStatus.COMPLETE));
              dispatch(setConnectStatus(StepTileProgressStatus.COMPLETE));
              dispatch(setUploadStatus(StepTileProgressStatus.COMPLETE));
              switch (event.status) {
                case "DatasetStatus.READY":
                  if (isNewDatasetInitializing) {
                    trackNewDatasetIngestionEvent(
                      AMP_DATASET_INGESTION_EVENT__DATASET_CREATION_SUCCESS
                    );
                  }

                  dispatch(setIndexingStatus(StepTileProgressStatus.COMPLETE));
                  break;

                case "DatasetStatus.FATAL_ERROR":
                  if (isNewDatasetInitializing) {
                    trackFatalError(
                      event.reason || "",
                      getPreviewDataSource(state)
                    );
                  }
                  dispatch(setUploadStatus(StepTileProgressStatus.ACTIVE));
                  dispatch(setIndexingStatus(StepTileProgressStatus.DISABLED));
                  break;

                case "DatasetStatus.INDEXING":
                  dispatch(
                    setIndexingStatus(StepTileProgressStatus.IN_PROGRESS)
                  );
                  break;
              }

              break;

            case "FatalDatasetStatus":
              if (isNewDatasetInitializing) {
                trackFatalError(
                  event.reason || "",
                  getPreviewDataSource(state)
                );
              }
              break;

            case "LimitExceeded":
              const eventType: LimitExceeded["limit_type"] = event.limit_type;

              if (eventType === "MAX_USER_QUOTA") {
                const dataSource = getPreviewDataSource(state);
                let attemptedImageCount;

                if (dataSource === DataSources.FOLDER) {
                  attemptedImageCount = getFilesUploadSize(state).length;
                } else {
                  attemptedImageCount = event.actual_value;
                }

                dispatch(
                  setQuotaLimitModalData({
                    show: false,
                    attemptedImageCount: attemptedImageCount,
                  })
                );

                //track Quota limi exceeded event
                trackNewDatasetIngestionEvent(
                  AMP_DATASET_INGESTION_EVENT__QUOTA_LIMIT_EXCEEDED,
                  {
                    [AMP_DATASET_CREATION_PROPERTY__UPLOAD_SOURCE]: dataSource,
                    [AMP_DATASET_CREATION_PROPERTY__ATTEMPTED_UPLOAD_COUNT]:
                      attemptedImageCount,
                    [AMP_DATASET_CREATION_PROPERTY__ALLOWED_UPLOAD_COUNT]:
                      getUserQuota(state)?.availableQuota,
                  }
                );
              }
              dispatch(setLimitExceededDetails(event));
              dispatch(setConnectStatus(StepTileProgressStatus.COMPLETE));
              if (getUserInitiatedCreation(state)) {
                dispatch(startProcess(newDatasetId));
              } else {
                dispatch(setUploadFinished(true));
                dispatch(setUploadStatus(StepTileProgressStatus.ACTIVE));
              }
              break;

            case "LimitNotExceeded":
              dispatch(setConnectStatus(StepTileProgressStatus.COMPLETE));
              if (getUserInitiatedCreation(state)) {
                dispatch(startProcess(newDatasetId));
              } else {
                dispatch(setUploadFinished(true));
                dispatch(setUploadStatus(StepTileProgressStatus.ACTIVE));
              }
              break;

            case "FilesUploadStart":
              updatedPreviewData = [];
              dispatch(setFilesUploadTransId(event.transaction_id));
              break;

            case "FilesChunkUploaded":
              updatedLastUsedData = {
                ...updatedLastUsedData,
                folderName: event.folder_name,
              };

              break;

            case "DataSourceEvent":
              updatedPreviewData = [];
              if (getIsUsageReportEnabled(state)) {
                dispatch(checkUserQuota());
              }
              let source: DataSourceEvent["source_type"] = event.source_type;
              switch (source) {
                case "FOLDER":
                  dispatch(setPreviewDataSource(DataSources.FOLDER));
                  break;
                case "BUCKET":
                  dispatch(setPreviewDataSource(DataSources.S3));
                  break;
                case "ZIP":
                  dispatch(setPreviewDataSource(DataSources.ZIP));
                  break;
              }
          }
        }
      });

      // For the case in which the previous upload was interrupted
      if (offset === 0) {
        const hasDataSourceEvent = fetchResponseData.some(
          (event) =>
            event.event_type === "DataSourceEvent" &&
            event.source_type &&
            event.source_type === "FOLDER"
        );
        const hasFilesUploadCompleted = fetchResponseData.some(
          (event) => event.event_type === "FilesUploadCompleted"
        );
        if (hasDataSourceEvent && !hasFilesUploadCompleted) {
          dispatch(setConnectStatus(StepTileProgressStatus.ACTIVE));
          dispatch(setAnnotationStatus(StepTileProgressStatus.DISABLED));
          dispatch(setUploadStatus(StepTileProgressStatus.FAILED));
        }
      }

      // Sort logs by serial in descending order
      updatedLogs.sort((a, b) => b.serial - a.serial);

      dispatch(setActivityLogs(updatedLogs));
      dispatch(setPreviewData(updatedPreviewData));

      if (!isNewDatasetInitializing) {
        dispatch(setLastUsedData(updatedLastUsedData));
      }

      const newOffset =
        fetchResponseData.length > 0
          ? fetchResponseData[fetchResponseData.length - 1].serial + 1
          : offset;
      dispatch(setActivityOffset(newOffset));
    } catch (error) {
      console.error("Error fetching event logs:", error);
      dispatch(setS3FetchProgress(-1));
    }
  };
};

export const uploadAnnotations = (
  newDatasetId: string,
  files: File[]
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const isCalledAgain =
      getAnnotationStatus(getState()) === StepTileProgressStatus.COMPLETE;

    //track Annotation selection
    trackAnnotation(isCalledAgain);

    try {
      dispatch(setAnnotationStatus(StepTileProgressStatus.IN_PROGRESS));
      const url = composeApiURL(
        HOST_URL,
        `/ingestion/${newDatasetId}/annotations`
      );

      const requestBody = new FormData();
      files.forEach((file) => requestBody.append("files", file));

      axios
        .post(url, requestBody, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          dispatch(setPreviewData([])); //clear old previews for new ones
          // Handle events or additional logic here
        })
        .catch((error) => {
          console.error("File upload failed", error);
        });
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const skipAnnotations = (
  newDatasetId: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(clearAnnotations(newDatasetId) as any);
    dispatch(setAnnotationStatus(StepTileProgressStatus.COMPLETE));
  };
};

export const clearAnnotations = (
  newDatasetId: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      const url = composeApiURL(
        HOST_URL,
        `/ingestion/${newDatasetId}/annotations`
      );
      axios.delete(url).catch((error) => {
        console.error("clear annotations failed", error);
      });
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const startProcess = (
  newDatasetId: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      const state = getState();
      const dataSource = getPreviewDataSource(state);
      if (dataSource === DataSources.S3) {
        dispatch(processS3(newDatasetId) as any);
      } else if (dataSource === DataSources.FOLDER) {
        dispatch(processFiles(newDatasetId) as any);
      } else if (dataSource === DataSources.ZIP) {
        dispatch(processZip(newDatasetId) as any);
      }

      trackNewDatasetIngestionEvent(
        AMP_DATASET_INGESTION_EVENT__INDEXING_STARTED,
        {
          [AMP_DATASET_CREATION_PROPERTY__UPLOAD_SOURCE]: dataSource,
        }
      );
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const processZip = (
  newDatasetId: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setUploadStatus(StepTileProgressStatus.COMPLETE));
      dispatch(setIndexingStatus(StepTileProgressStatus.IN_PROGRESS));
      const url = composeApiURL(
        HOST_URL,
        `/ingestion/${newDatasetId}/process_archive`
      );
      const response = await axios.get(url);
      if (response.status !== 202) {
        //handle errors -TBD
        dispatch(setUploadStatus(StepTileProgressStatus.ACTIVE));
        dispatch(setIndexingStatus(StepTileProgressStatus.ACTIVE));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(setUploadStatus(StepTileProgressStatus.ACTIVE));
    }
  };
};

export const processFiles = (
  newDatasetId: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      dispatch(setUploadStatus(StepTileProgressStatus.COMPLETE));
      dispatch(setIndexingStatus(StepTileProgressStatus.IN_PROGRESS));
      const state = getState();
      const transId = getFilesUploadTransId(state);
      const url = composeApiURL(
        HOST_URL,
        `/ingestion/${newDatasetId}/process_files/${transId}`
      );
      const response = await axios.get(url);
      if (response.status !== 202) {
        //handle errors -TBD
        dispatch(setUploadStatus(StepTileProgressStatus.ACTIVE));
        dispatch(setIndexingStatus(StepTileProgressStatus.ACTIVE));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(setUploadStatus(StepTileProgressStatus.ACTIVE));
    }
  };
};

export const processS3 = (
  newDatasetId: string
): ThunkAction<void, State, unknown, any> => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setUploadStatus(StepTileProgressStatus.COMPLETE));
      dispatch(setIndexingStatus(StepTileProgressStatus.IN_PROGRESS));
      const url = composeApiURL(
        HOST_URL,
        `/ingestion/${newDatasetId}/process_s3`
      );

      const response = await axios.get(url);

      if (response.status !== 202) {
        //handle errors -TBD
        dispatch(setUploadStatus(StepTileProgressStatus.ACTIVE));
        dispatch(setIndexingStatus(StepTileProgressStatus.ACTIVE));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(setUploadStatus(StepTileProgressStatus.ACTIVE));
    }
  };
};

export const setShowActivityLogModal = (data: ActivityLogShowModalType) => {
  return { type: SET_SHOW_ACTIVITY_LOG_MODAL, data };
};
