import { useRef, useEffect, useState, useCallback } from "react";
import styles from "./style.module.scss";
import classNames from "classnames";

const VideoBox = ({
  src,
  offset,
  duration,
  showVideoBox,
  handleVideoLoad,
  handleVideoError,
  handleTimeUpdate,
}: VideoBoxProps) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playedOnce, setPlayedOnce] = useState(false);

  const assetPreviewVideoContainerClassNames = classNames(
    styles.assetPreviewVideoContainer,
    { [styles.hiddenAssetPreviewVideoContainer]: !showVideoBox }
  );

  const playVid = useCallback(async () => {
    const video = videoRef.current;
    if (video) {
      const SPECIAL_CHECK__IS_PLAYING =
        video.currentTime > 0 &&
        !video.paused &&
        !video.ended &&
        video.readyState > video.HAVE_CURRENT_DATA;
      if (!SPECIAL_CHECK__IS_PLAYING) {
        try {
          await video.play();
          setIsPlaying(true);
        } catch (error) {
          handleVideoError();
          console.error("Error playing video:", error);
        }
      }
    }
    //eslint-disable-next-line
  }, []);

  const pauseVid = useCallback(() => {
    const video = videoRef.current;
    if (video && isPlaying) {
      video.pause();
      setIsPlaying(false);
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isLoaded) {
      const video = videoRef.current;
      if (!video) return;

      video.onplaying = () => setIsPlaying(true);
      video.onpause = () => setIsPlaying(false);

      if (offset) {
        const startTime = Math.max(0, offset);
        video.currentTime = startTime;
        playVid();
        const pauseTimeout = setTimeout(() => {
          pauseVid();
          if (!playedOnce) setPlayedOnce(true);
        }, duration * 1000);
        return () => clearTimeout(pauseTimeout);
      }
    }
    //eslint-disable-next-line
  }, [isLoaded, isPlaying]);

  useEffect(() => {
    if (playedOnce) {
      handleVideoLoad();
    }
    return () => {
      setPlayedOnce(false);
    };
    //eslint-disable-next-line
  }, [playedOnce]);

  return src ? (
    <div className={assetPreviewVideoContainerClassNames}>
      <video
        ref={videoRef}
        className={styles.assetPreviewVideo}
        autoPlay={!!offset}
        muted
        disablePictureInPicture
        playsInline
        onTimeUpdate={() =>
          handleTimeUpdate(videoRef.current?.currentTime || 0)
        }
        onPlay={() => setIsLoaded(true)}
        onError={() => handleVideoError()}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  ) : null;
};

export default VideoBox;

interface VideoBoxProps {
  src: string | undefined;
  offset: number;
  duration: number;
  showVideoBox: boolean;
  handleVideoLoad: () => void;
  handleVideoError: () => void;
  handleTimeUpdate: (currTime: number) => void;
}
