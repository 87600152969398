// PAGES
export const DATA_PAGE = "DATA_PAGE";
export const DATASETS_PAGE = "DATASETS_PAGE";
export const SINGLE_DATASET_PAGE = "SINGLE_DATASET_PAGE";
export const CLUSTERS_PAGE = "CLUSTERS_PAGE";
export const IMAGE_CLUSTERS_PAGE = "IMAGE_CLUSTERS_PAGE";
export const SINGLE_IMAGE_PAGE = "SINGLE_IMAGE_PAGE";

export const CLUSTER_DETAILS_PAGE = "CLUSTER_DETAILS_PAGE";
export const IMAGE_DETAILS_PAGE = "IMAGE_DETAILS_PAGE";

export const DATASET_CREATION_PAGE = "DATASET_CREATION_PAGE";
export const DATASET_INVENTORY_PAGE = "DATASET_INVENTORY_PAGE";
