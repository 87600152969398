import { truncateFilename } from "helpers/utility/utilities";

import styles from "./style.module.scss";

export const FolderItem = ({ folderName, onRemoveFolder }: FolderItemProps) => {
  const name = truncateFilename(folderName, 42);
  return (
    <div className={styles.correctFile}>
      <span title={folderName}>{name}</span>
      <div className={styles.removeButton} onClick={onRemoveFolder}>
        Remove
      </div>
    </div>
  );
};

export const FileItem = ({ file, onRemoveFile }: FileItemProps) => {
  const name = truncateFilename(file.name, 42);

  return (
    <div className={styles.correctFile}>
      <span title={file.name}>{name}</span>
      <div className={styles.removeButton} onClick={() => onRemoveFile(file)}>
        Remove
      </div>
    </div>
  );
};

const SelectedFilesList = ({
  correctFiles,
  onRemoveFile,
}: SelectedFilesListProps) => {
  return (
    <div className={styles.fileList}>
      <div className={styles.correctFilesList}>
        {correctFiles.map((file, index) => (
          <FileItem key={index} file={file} onRemoveFile={onRemoveFile} />
        ))}
      </div>
    </div>
  );
};

export default SelectedFilesList;

interface SelectedFilesListProps {
  correctFiles: File[];
  onRemoveFile: (selectedFile: File) => void;
}

interface FileItemProps {
  file: File;
  onRemoveFile: (selectedFile: File) => void;
}

interface FolderItemProps {
  folderName: string;
  onRemoveFolder: () => void;
}
