import ProPlanUserImg from "assets/img/pro-plan.svg";
import styles from "./style.module.scss";

function ProPlanUser({ showModal, setShowModal }: any) {
  return (
    <div
      className={showModal ? styles.selectSourceType : styles.hideSourceType}
    >
      <div className={styles.basicDetailsTop}>
        <span className={styles.upgrade}>The Pro Plan Allows You To:</span>
      </div>
      <div className={styles.desc}>
        <span className={styles.description}>
          In this plan, you can create up to 10 datasets, each containing up to
          100k images. to remove these limitations and unlock more features,
          submit an upgrade inquiry.
        </span>
      </div>
      <div>
        <div className={styles.basicDetailsBelow}>
          <div className={styles.maxAmountImg}>
            <img src={ProPlanUserImg} alt="" />
          </div>
          <div className={styles.nextBtnContainer}>
            <button
              className={styles.nextBtn}
              onClick={() => setShowModal(false)}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProPlanUser;
