import { getIsNewDatasetInitializing } from "redux/CreateDataset/selectors";
import { ReactNode, RefObject } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { State } from "redux/store";
import XIcon from "assets/icons/XIcon";

import styles from "./style.module.scss";

const InputBanner = ({
  body,
  bannerRef,
  isSaveBtnEnabled,
  handleSaveButtonClick = () => {}, //default action  till code is completed for all steps,
  viewOnly,
  closeBanner,
}: InputBannerProps) => {
  const saveBtnClassNames = classNames(styles.saveBtn, {
    [styles.disabledBtn]: !isSaveBtnEnabled,
  });

  return (
    <div className={styles.inputBannerContainer} ref={bannerRef}>
      {viewOnly && (
        <div className={styles.overlay}>
          <div className={styles.crossButton} onClick={closeBanner}>
            <XIcon color="#fff" />
          </div>
        </div>
      )}
      <div className={styles.inputBannerContent}>{body}</div>
      <div className={styles.actionButtons}>
        <div className={saveBtnClassNames} onClick={handleSaveButtonClick}>
          NEXT
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return { viewOnly: !getIsNewDatasetInitializing(state) };
};

export default connect(mapStateToProps)(InputBanner);

interface InputBannerProps {
  body: ReactNode;
  bannerRef: RefObject<HTMLDivElement>;
  handleSaveButtonClick?: () => void; //remove ? once all Parents have created the corresponding function
  isSaveBtnEnabled: boolean;
  viewOnly: boolean;
  closeBanner: () => void;
}
