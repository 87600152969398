import {
  SET_USER_INFO,
  SET_VL_VERSION,
  SET_USER_CONFIG,
  SET_USER_QUOTA,
} from "./constants";

const INITIAL_STATE = {
  userInfo: {
    isInternalUser: false,
    email: "",
  },
  vlVersion: undefined,
  isLoading: false,
  userConfig: [],
  userQuota: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, userInfo: action.data };

    case SET_VL_VERSION:
      return { ...state, vlVersion: action.data };

    case SET_USER_CONFIG:
      return { ...state, userConfig: action.data };

    case SET_USER_QUOTA:
      return { ...state, userQuota: action.data };

    default:
      return state;
  }
};

export default reducer;
