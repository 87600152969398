const TagIcon = ({
  color = "#fff",
  size = "15",
}: {
  color?: string;
  size?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M6.20023 1.64672C6.76077 1.08618 7.53497 0.795278 8.31965 0.850368L10.9748 1.03678C12.3006 1.12986 13.3475 2.1767 13.4405 3.50248L13.6269 6.15766C13.682 6.94234 13.3911 7.71654 12.8306 8.27708L8.43332 12.6743C7.37285 13.7348 5.66503 13.7464 4.61878 12.7001L1.7772 9.85852C0.730956 8.81228 0.74249 7.10445 1.80296 6.04398L6.20023 1.64672Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TagIcon;
