const PREFIX = "Modals";
export const SHOW_SIGN_UP_SUCCESS_MODAL = `${PREFIX}_SHOW_SIGN_UP_SUCCESS_MODAL`;
export const SHOW_IMAGE_OPTIONS_MODAL = `${PREFIX}_SHOW_IMAGE_OPTIONS_MODAL`;
export const SHOW_MOBILE_ERROR_MODAL = `${PREFIX}_SHOW_MOBILE_ERROR_MODAL`;
export const SHOW_INVITATION_MODAL = `${PREFIX}_SHOW_INVITATION_MODAL`;
export const SHOW_PROGRESS_MODAL = `${PREFIX}_SHOW_PROGRESS_MODAL`;
export const SHOW_UPGRADE_MODAL = `${PREFIX}_SHOW_UPGRADE_MODAL`;
export const SHOW_SIGN_UP_MODAL = `${PREFIX}_SHOW_SIGN_UP_MODAL`;
export const SHOW_PROFILE_MODAL = `${PREFIX}_SHOW_PROFILE_MODAL`;
export const SHOW_FREE_MODAL = `${PREFIX}_SHOW_FREE_MODAL`;
export const SHOW_PRO_MODAL = `${PREFIX}_SHOW_PRO_MODAL`;
export const SHOW_MODAL = `${PREFIX}_SHOW_MODAL`;

export const SET_IMAGE_DATA = `${PREFIX}_SET_IMAGE_DATA`;
export const SET_IMAGE_LABELS = `${PREFIX}_SET_IMAGE_LABELS`;
export const SET_SELECTED_LABELS = `${PREFIX}_SET_SELECTED_LABELS`;
export const SET_IMAGE_DATA_LOADER = `${PREFIX}_SET_IMAGE_DATA_LOADER`;

export const SET_IMAGE_ISSUES = `${PREFIX}_SET_IMAGE_ISSUES`;
export const SET_SELECTED_ISSUES = `${PREFIX}_SET_SELECTED_ISSUES`;

export const SET_SELECTED_CAROUSEL_INDEX = `${PREFIX}_SET_SELECTED_CAROUSEL_INDEX`;

export const SET_IMAGE_TAGS = `${PREFIX}_SET_IMAGE_TAGS`;
export const IS_IMAGE_TAG_LOADING = `${PREFIX}_IS_IMAGE_TAG_LOADING`;

export const SHOW_DEMO_REQUEST_MODAL = `${PREFIX}_SHOW_DEMO_REQUEST_MODAL`;
export const SHOW_DEMO_REQUEST_SUCCESS_MODAL = `${PREFIX}_SHOW_DEMO_REQUEST_SUCCESS_MODAL`;

export const SHOW_LEARN_MORE_MODAL = `${PREFIX}_SHOW_LEARN_MORE_MODAL`;
export const SHOW_DATASET_ERROR_MODAL = `${PREFIX}_SHOW_DATASET_ERROR_MODAL`;

export const SHOW_INTERNAL_ERROR_MODAL = `${PREFIX}_SHOW_INTERNAL_ERROR_MODAL`;
export const SET_QUOTA_LIMIT_MODAL_DATA = `${PREFIX}_SET_QUOTA_LIMIT_MODAL_DATA`;

export const SHOW_SELECT_PLAN_MODAL = `${PREFIX}_SHOW_SELECT_PLAN_MODAL`;
