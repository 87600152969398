import { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet";
import { connect, useDispatch } from "react-redux";

import Modal from "react-bootstrap/Modal";
import { MdOutlineClose } from "react-icons/md";

import { State } from "redux/store";
import { setShowSignUpModal } from "redux/Modals/actions";
import { getShowSignUpModal } from "redux/Modals/selectors";

import SingleRow from "./SingleRow";
import LoginButton from "./LoginButton";
import GoogleButton from "views/uikit/GoogleButton";

import { SIGNUP_ORIGIN } from "helpers/constants/amplitudeProperties";

import fix from "assets/img/rocket.svg";
import scan from "assets/img/eye.svg";
import upload from "assets/img/upload-purple.svg";
import group from "assets/img/group.svg";

import styles from "./style.module.scss";

const SignupModal = ({ showSignUpModal }: any) => {
  const googleButtonRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const generateGithubSSOUrl = () => {
    return (
      "https://github.com/login/oauth/authorize?scope=user&client_id=" +
      process.env.REACT_APP_GITHUB_CLIENT_ID +
      "&state=" +
      uuidv4()
    );
  };

  const handleCloseModal = () => {
    dispatch(setShowSignUpModal(false));
    localStorage.removeItem(SIGNUP_ORIGIN);
  };

  const handleGoogleButtonClick = () => {
    googleButtonRef.current?.click();
    dispatch(setShowSignUpModal(false));
  };

  return (
    <Modal
      animation={false}
      show={showSignUpModal}
      onHide={handleCloseModal}
      className={styles.modalWrapper}
      backdropClassName={styles.backgroundColor}
    >
      <Helmet>
        <script
          src="https://accounts.google.com/gsi/client"
          async
          defer
        ></script>
      </Helmet>
      <Modal.Body onClick={(e: any) => e.stopPropagation()}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <div className={styles.modalHeaderTitleBox}>
              <span className={styles.modalHeaderTitle}>
                Let’s complete your account setup
              </span>
            </div>
            <div className={styles.modalCloseBtn} onClick={handleCloseModal}>
              <MdOutlineClose size="1.1em" />
            </div>
          </div>
          <hr className={styles.horizontalRule1} />
          <div className={styles.modalBody}>
            <div className={styles.leftSection}>
              <div className={styles.signUpButtonBox}>
                <div
                  className={styles.googleButtonWrapper}
                  onClick={handleGoogleButtonClick}
                >
                  <LoginButton type="google" text="Continue with Google">
                    <div
                      ref={googleButtonRef}
                      className={styles["google-auth-login"]}
                    >
                      <GoogleButton />
                      <div
                        className="g_id_signin"
                        data-width={292}
                        data-height={48}
                      ></div>
                    </div>
                  </LoginButton>
                </div>

                <a
                  className={styles.githubButton}
                  href={generateGithubSSOUrl()}
                  onClick={() => dispatch(setShowSignUpModal(false))}
                >
                  <LoginButton type="github" text="Continue with Github" />
                </a>
              </div>
              <span className={styles.termsText}>
                I agree to the{" "}
                <a
                  href={"https://visual-layer2.readme.io/docs/terms-of-service"}
                  target="_blank"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>{" "}
                and the{" "}
                <a
                  href={"https://visual-layer2.readme.io/docs/privacy-policy"}
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy
                </a>
              </span>
            </div>

            <div className={styles.verticalRule}></div>

            <div className={styles.rightSection}>
              <SingleRow
                imageSrc={upload}
                title="Fast and accurate"
                description="Swiftly and precisely identify anomalies and quality problems with your visual data"
              />
              <SingleRow
                imageSrc={scan}
                title="Intelligent image clustering"
                description="Save valuable time by fixing multiple related anomalies grouped into logical groups"
              />
              <SingleRow
                imageSrc={fix}
                title="Unparralelled scale"
                description="Specifically designed to scale, Profiler meets your expanding data requirements"
              />
              <SingleRow
                imageSrc={group}
                title="Growing Community"
                description="Engage with a diverse group of 200K+ indivisuals who are passionate about Computer Vision and Data Ops"
              />
            </div>
          </div>
          <hr className={styles.horizontalRule2} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state: State) => {
  return {
    showSignUpModal: getShowSignUpModal(state),
  };
};
export default connect(mapStateToProps)(SignupModal);
