import ArrowIcon from "assets/icons/ArrowIcon";
import styles from "./style.module.scss";
import { ChangeEvent, RefObject, useEffect, useRef, useState } from "react";

import classNames from "classnames";
import XIcon from "assets/icons/XIcon";
import { useDispatch } from "react-redux";
import {
  uploadAnnotations,
  skipAnnotations,
} from "redux/CreateDataset/actions";
import SupportedFormatPopup from "./SupportedFormatsPopup";
import SelectedFilesList from "../../SelectedFilesList";
import FileDropZone from "../../FileDropZone";

const AnnotationsInputBanner = ({
  showBanner,
  newDatasetId,
  closeBanner,
  bannerRef,
  viewOnly,
  lastUsedAnnotationFiles,
}: AnnotationsInputBannerProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const [showSFPopup, setShowSFPopup] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const showFileList = files.length > 0;
  const isFileUploadSuccess = files.length > 0;

  const annotationsInputContainerClassNames = classNames(
    styles.annotationInputContainer,
    { [styles.errorBorder]: fileErrorMessage }
  );

  const saveButtonClassNames = classNames(styles.saveButton, {
    [styles.enabledSaveButton]: isFileUploadSuccess,
  });

  const validateFileExtension = (value: string): boolean => {
    const fileExtensionRegex = /\.(csv|json|parquet)$/i;
    return fileExtensionRegex.test(value);
  };

  const getFileExtension = (fileName: string): string => {
    return fileName.split(".").pop() || "";
  };

  const resetStates = () => {
    setFiles([]);
    setFileErrorMessage("");
  };

  const onRemoveFile = (selectedFile: File) => {
    const updatedCorrectFiles = files.filter(
      (file) => file.name !== selectedFile.name
    );

    setFiles(updatedCorrectFiles);
  };

  //eslint-disable-next-line
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (!selectedFiles || selectedFiles.length < 1) {
      return;
    }
    let tempCorrectFiles: File[] = [];

    Array.from(selectedFiles).forEach((file) => {
      if (validateFileExtension(file.name)) {
        tempCorrectFiles.push(file);
      }
    });

    const isSingleJsonFile =
      tempCorrectFiles.length === 1 &&
      getFileExtension(tempCorrectFiles[0].name) === "json";

    const isSingleOrTwoCsvFiles =
      tempCorrectFiles.length === 1 ||
      (tempCorrectFiles.length === 2 &&
        tempCorrectFiles.every(
          (file) => getFileExtension(file.name) === "csv"
        ));

    const isSingleOrTwoParquetFiles =
      tempCorrectFiles.length === 1 ||
      (tempCorrectFiles.length === 2 &&
        tempCorrectFiles.every(
          (file) => getFileExtension(file.name) === "parquet"
        ));

    if (
      isSingleJsonFile ||
      isSingleOrTwoCsvFiles ||
      isSingleOrTwoParquetFiles
    ) {
      setFiles(tempCorrectFiles);
      setFileErrorMessage("");
    } else {
      if (tempCorrectFiles.length > 0) {
        setFiles([]);
        setFileErrorMessage(
          "Only 1 .json file or 2 .csv/.parquet files are supported."
        );
      }
    }
  };

  const handleNextButtonClick = () => {
    closeBanner();

    dispatch(uploadAnnotations(newDatasetId, files) as any);
    resetStates();
  };

  const handleSkipClick = () => {
    closeBanner();
    resetStates();
    dispatch(skipAnnotations(newDatasetId) as any);
  };

  const handleCancelClick = () => {
    closeBanner();
    resetStates();
  };

  const toggleShowSFPopup = () => {
    setShowSFPopup(!showSFPopup);
  };

  useEffect(() => {
    if (!showBanner) {
      setShowSFPopup(false);
    }
  }, [showBanner]);

  useEffect(() => {
    const tempFiles = lastUsedAnnotationFiles.map((file) => new File([], file));

    setFiles(tempFiles);
  }, [lastUsedAnnotationFiles]);

  return showBanner ? (
    <div className={styles.annotationInputBanner} ref={bannerRef}>
      <div className={annotationsInputContainerClassNames}>
        {viewOnly && (
          <div className={styles.overlay}>
            <div className={styles.crossButton} onClick={closeBanner}>
              <XIcon color="#fff" />
            </div>
          </div>
        )}
        <div className={styles.inputTitleContainer}>
          <div className={styles.titleIcon}>
            <ArrowIcon color="#fff" />
          </div>
          <span className={styles.title}>Import Annotations</span>
          <div className={styles.rightSection}>
            <div className={styles.cancelBtn} onClick={handleCancelClick}>
              <XIcon color="#fff" />
            </div>
          </div>
        </div>

        <div className={styles.inputOptions}>
          <span className={styles.formatHelpText} onClick={toggleShowSFPopup}>
            Supported Formats
          </span>
          <FileDropZone
            label="Drop files here"
            onFileChange={handleFileChange}
            dropRef={dropRef}
            inputRef={fileInputRef}
            inputProps={{
              accept: ".json ,.csv ,.parquet",
              hidden: true,
              multiple: true,
            }}
          />
        </div>

        {showFileList && (
          <SelectedFilesList correctFiles={files} onRemoveFile={onRemoveFile} />
        )}

        {fileErrorMessage && (
          <div className={styles.fileErrorMessage}>{fileErrorMessage}</div>
        )}

        <div className={styles.actionButtons}>
          <div className={styles.skipButton} onClick={handleSkipClick}>
            Skip
          </div>
          <div className={saveButtonClassNames} onClick={handleNextButtonClick}>
            Next
          </div>
        </div>
        {showSFPopup && <SupportedFormatPopup closePopup={toggleShowSFPopup} />}
      </div>
    </div>
  ) : (
    <noscript />
  );
};

export default AnnotationsInputBanner;

interface AnnotationsInputBannerProps {
  showBanner: boolean;
  newDatasetId: string;
  closeBanner: () => void;
  bannerRef: RefObject<HTMLDivElement>;
  viewOnly: boolean;
  lastUsedAnnotationFiles: string[];
}
