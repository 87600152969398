import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { State } from "redux/store";
import { PipelineType } from "helpers/constants/miscellaneous";
import {
  getLoading,
  getSelectedSampleDataset,
  getErrorText,
} from "redux/Datasets/selectors";
import {
  createDataset,
  setIsError,
  setErrorText,
  setDatasetIdToDuplicate,
  duplicateDataset,
} from "redux/Datasets/actions";
import { setShowModal } from "redux/Modals/actions";
import { getShowModal } from "redux/Modals/selectors";

import Loader from "views/uikit/Loader";
import VLModal from "views/uikit/VLModal";
import SourceItem from "views/components/SourceItem";

import SelectSampleData from "views/components/SelectSampleData";
import SelectSourceType, {
  DatasetName,
} from "views/components/SelectSourceType";
import UploadDataset from "views/components/UploadDataset";

import {
  AMP_DATASET_NAME,
  AMP_DATASET_SOURCE,
} from "helpers/constants/amplitudeProperties";
import { amplitudeTrack } from "helpers/utility/amplitude";
import { AMP_DATASET_IMPORTED } from "helpers/constants/amplitudeEvents";

import datasetIcon from "assets/img/dataset.svg";
import styles from "./style.module.scss";
import { getDatasetToDuplicate } from "redux/Datasets/selectors";

function NewDatasetModal({
  selectedSampleDataset,
  createFirstDataset,
  isLoading,
  errorText,
  showModal,
  selectedSampleName,
}: any) {
  //internal states
  const [selectedSource, setSelectedSource] = useState(0);
  const [sampleSourceState, setSampleSourceState] = useState(0);
  const [localSourceState, setLocalSourceState] = useState(0);
  const [s3SourceState, setS3SourceState] = useState(0);
  const [datasetName, setDatasetName] = useState("");
  const [s3Bucket, sets3Bucket] = useState("");
  const [selectedSourceName, setSelectedSourceName] = useState("");
  const [file, setFile] = useState<any>(null);
  const [fileError, setFileError] = useState(false);
  const [bucketNameError, setBucketNameError] = useState("");
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const handleModal = () => {
    dispatch(setShowModal(false));
    createFirstDataset(true);
    resetModalStates();
  };

  const nextStep = async () => {
    if (selectedSource === 1) {
      if (localSourceState) {
        await dispatch(createDataset(datasetName, null, null, file) as any);
        amplitudeTrack(AMP_DATASET_IMPORTED, {
          [AMP_DATASET_NAME]: datasetName,
          [AMP_DATASET_SOURCE]: "Local Upload",
        });
        handleModal();
      } else {
        setPage(2);
        setLocalSourceState(localSourceState + 1);
      }
    } else if (selectedSource === 2) {
      if (s3SourceState) {
        await dispatch(createDataset(datasetName, s3Bucket, null, null) as any);
        amplitudeTrack(AMP_DATASET_IMPORTED, {
          [AMP_DATASET_NAME]: datasetName,
          [AMP_DATASET_SOURCE]: "Public S3",
        });
        handleModal();
      } else {
        setPage(2);
        setS3SourceState(s3SourceState + 1);
      }
    } else {
      if (sampleSourceState) {
        await dispatch(
          createDataset(
            datasetName,
            null,
            selectedSampleDataset?.id,
            null
          ) as any
        );
        amplitudeTrack(AMP_DATASET_IMPORTED, {
          [AMP_DATASET_NAME]: datasetName,
          [AMP_DATASET_SOURCE]: "Sample",
        });
        handleModal();
      } else {
        setPage(2);
        setSampleSourceState(sampleSourceState + 1);
      }
    }
  };

  const previousStep = () => {
    if (selectedSource === 1) {
      setPage(1);
      setFile(null);
      setFileError(false);
      setLocalSourceState(localSourceState - 1);
    } else if (selectedSource === 2) {
      dispatch(setErrorText(null));
      sets3Bucket("");
      setBucketNameError("");
      setPage(1);
      setS3SourceState(s3SourceState - 1);
    } else {
      setPage(1);
      setSampleSourceState(sampleSourceState - 1);
    }
  };

  const resetModalStates = () => {
    setSelectedSource(0);
    setSampleSourceState(0);
    setLocalSourceState(0);
    setS3SourceState(0);
    setDatasetName("");
    sets3Bucket("");
    setFile(null);
    dispatch(setErrorText(null));
    setFileError(false);
    setBucketNameError("");
    setPage(1);
    dispatch(setIsError(false));
  };

  return (
    // modal wrapper
    <VLModal
      showModal={showModal}
      setShowModal={(value: boolean) => dispatch(setShowModal(value))}
      resetModalStates={resetModalStates}
      expandModalHeight={page === 1}
    >
      {/* show loader */}
      {isLoading && (
        <div className={styles.pAbsolute}>
          <Loader />
        </div>
      )}
      {/* modal with overflow (wrapper) */}
      <div
        className={
          sampleSourceState
            ? styles.modalContentWithOverFlow
            : styles.modalContent
        }
      >
        {/* Header constant */}
        <div className={styles.modalHeader}>
          <img src={datasetIcon} alt="" />
          <span>Create a new dataset</span>
        </div>
        {/* modal body */}
        <div className={styles.modalBody}>
          <SelectSourceType
            selectedSource={selectedSource}
            onSelectSource={setSelectedSource}
            setSelectedSourceName={setSelectedSourceName}
            datasetName={datasetName}
            handleChange={setDatasetName}
            show={!sampleSourceState && !localSourceState && !s3SourceState}
            closeModal={() => {
              resetModalStates();
              dispatch(setShowModal(false));
            }}
          />

          <SelectSampleData
            selectedSourceName={selectedSourceName}
            datasetName={datasetName}
            show={sampleSourceState === 1}
          />

          <UploadDataset
            errorText={errorText}
            setErrorText={setErrorText}
            fileUrl={s3Bucket}
            setFileUrl={sets3Bucket}
            selectedSourceName={selectedSourceName}
            datasetName={datasetName}
            isLocalUpload={localSourceState === 1}
            show={s3SourceState === 1 || localSourceState === 1}
            setFile={setFile}
            file={file}
            fileError={fileError}
            setFileError={setFileError}
            bucketNameError={bucketNameError}
            setBucketNameError={setBucketNameError}
          />
        </div>
        {/* modal footer */}
        <div className={styles.modalFooter}>
          {/* back button */}
          {sampleSourceState > 0 ||
          s3SourceState > 0 ||
          localSourceState > 0 ? (
            <div className={styles.backBtn} onClick={() => previousStep()}>
              Back
            </div>
          ) : (
            <div className={styles.hiddenBackBtn}></div>
          )}
          {/* middle dots */}
          <div className={styles.pagination}>
            <div
              className={page === 1 ? styles.circleBlack : styles.circleWhite}
            ></div>
            <div
              className={page === 2 ? styles.circleBlack : styles.circleWhite}
            ></div>
          </div>
          {/* next button */}
          <div className={styles.nextBtnContainer}>
            <button
              className={
                !selectedSource ||
                (selectedSource && fileError) ||
                datasetName === "" ||
                (sampleSourceState === 1 &&
                  selectedSampleName?.display_name === "") ||
                (s3SourceState === 1 &&
                  (errorText === null || errorText !== "success")) ||
                (!file && localSourceState === 1)
                  ? styles.disabledNextBtn
                  : styles.nextBtn
              }
              onClick={() => nextStep()}
            >
              {page === 2 ? "Create" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </VLModal>
  );
}

function DuplicateDatasetModal({ showModal, dataset }: any) {
  const dispatch = useDispatch();
  const [datasetName, setDatasetName] = useState(dataset.display_name);
  const [pipelineType, setPipelineType] = useState(PipelineType.NORMAL);

  const handleModal = () => {
    dispatch(setShowModal(false));
    dispatch(setDatasetIdToDuplicate(null));
  };

  const duplicateDatasetHandler = async () => {
    await dispatch(
      duplicateDataset(dataset.id, datasetName, pipelineType.toString()) as any
    );
    handleModal();
  };

  function PipelineTypeButtons() {
    return (
      <div>
        <div className={styles.basicDetailsBelow}>
          <span>Select Pipeline Type</span>
        </div>
        <div className={styles.sourceInnerContainer}>
          <SourceItem
            name="Normal"
            description="Public pipeline"
            selected={pipelineType === PipelineType.NORMAL}
            onClick={() => {
              setPipelineType(PipelineType.NORMAL);
            }}
          />
          <SourceItem
            name="Enriched"
            description="internal only. normal with enrichment"
            selected={pipelineType === PipelineType.ENRICHED}
            onClick={() => {
              setPipelineType(PipelineType.ENRICHED);
            }}
          />
          <SourceItem
            name="Big Dataset"
            description="internal only. Big dataset w/o enrichment"
            selected={pipelineType === PipelineType.BIG}
            onClick={() => {
              setPipelineType(PipelineType.BIG);
            }}
          />
          <SourceItem
            name="Normal"
            description="internal only. Big dataset with enrichment"
            selected={pipelineType === PipelineType.BIG_ENRICHED}
            onClick={() => {
              setPipelineType(PipelineType.BIG_ENRICHED);
            }}
          />
        </div>
      </div>
    );
  }

  function DuplicateButton() {
    return (
      <div className={styles.nextBtnContainer}>
        <button
          className={styles.nextBtn}
          onClick={() => duplicateDatasetHandler()}
        >
          Submit
        </button>
      </div>
    );
  }

  return (
    <VLModal
      showModal={showModal}
      setShowModal={handleModal}
      expandModalHeight={false}
    >
      <div className={styles.modalHeader}>
        <img src={datasetIcon} alt="" />
        <span>Duplicate an existing dataset</span>
      </div>
      {/* modal body */}
      <div className={styles.modalBody}>
        <div className={styles.modalContentWithOverFlow}>
          <DatasetName
            datasetName={datasetName}
            handleChange={setDatasetName}
          />
          <PipelineTypeButtons />
          <DuplicateButton />
        </div>
      </div>
    </VLModal>
  );
}

function CreateDatasetModal({
  selectedSampleDataset,
  createFirstDataset,
  isLoading,
  errorText,
  showModal,
  selectedSampleName,
}: any) {
  const duplicateDataset = useSelector(getDatasetToDuplicate);
  if (duplicateDataset) {
    return (
      <DuplicateDatasetModal showModal={showModal} dataset={duplicateDataset} />
    );
  } else {
    return (
      <NewDatasetModal
        selectedSampleDataset={selectedSampleDataset}
        createFirstDataset={createFirstDataset}
        isLoading={isLoading}
        errorText={errorText}
        showModal={showModal}
        selectedSampleName={selectedSampleName}
      />
    );
  }
}

function mapStatesToProps(state: State) {
  return {
    isLoading: getLoading(state),
    selectedSampleDataset: getSelectedSampleDataset(state),
    errorText: getErrorText(state),
    showModal: getShowModal(state),
  };
}

export default connect(mapStatesToProps)(CreateDatasetModal);
