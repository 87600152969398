import { BiChevronDown, BiChevronUp, BiSearch, BiX } from "react-icons/bi";
import { formatNumberWithCommas } from "helpers/utility/formatters";
import styles from "./style.module.scss";

const HeaderWithoutSearch = ({
  title,
  totalCount,
  toggleSearchSelection,
  isDropdownOpen,
  setIsDropdownOpen,
}: any) => {
  const handleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <div className={styles.contentHeader}>
      <div className={styles.textContainer}>
        <span className={styles.titleText}>{title}</span>
        <span className={styles.countText}>
          {`(${formatNumberWithCommas(totalCount)})`}
        </span>
      </div>
      <div className={styles.actionIcons}>
        <div
          className={styles.searchTriggerIcon}
          onClick={toggleSearchSelection}
        >
          <BiSearch size={"20px"} />
        </div>
        <div className={styles.dropdownIcon} onClick={handleDropdown}>
          {isDropdownOpen ? (
            <BiChevronUp size={"1.55em"} />
          ) : (
            <BiChevronDown size={"1.55em"} />
          )}
        </div>
      </div>
    </div>
  );
};

const HeaderWithSearch = ({
  title,
  toggleSearchSelection,
  searchText,
  setSearchText,
}: any) => {
  return (
    <div className={styles.contentHeader}>
      <div className={styles.searchIcon}>
        <BiSearch size={"20px"} />
      </div>
      <div className={styles.searchInputBox}>
        <input
          type="search"
          placeholder={`Search for ${title}`}
          onChange={(e: any) => setSearchText(e.target.value)}
          value={searchText}
          className={styles.searchInput}
          autoFocus
        />
        <div className={styles.closeSearchBtn} onClick={toggleSearchSelection}>
          <BiX size="1.25em" />
        </div>
      </div>
    </div>
  );
};

const ContentHeader = ({
  title,
  totalCount,
  isSearchSelected,
  toggleSearchSelection,
  searchText,
  setSearchText,
  isUserTitle,
  isDropdownOpen,
  setIsDropdownOpen,
}: ContentHeaderProps) => {
  return isSearchSelected ? (
    <HeaderWithSearch
      title={title}
      toggleSearchSelection={toggleSearchSelection}
      searchText={searchText}
      setSearchText={setSearchText}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
    />
  ) : (
    <HeaderWithoutSearch
      title={title}
      totalCount={totalCount}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      toggleSearchSelection={toggleSearchSelection}
    />
  );
};

export default ContentHeader;

interface ContentHeaderProps {
  title: string;
  totalCount: number;
  isSearchSelected: boolean;
  toggleSearchSelection: () => void;
  searchText: string;
  setSearchText: any;
  isUserTitle: boolean;
  isDropdownOpen: boolean;
  setIsDropdownOpen: any;
}
