import { State } from "redux/store";
import { unstable_useBlocker, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchEventLogs,
  resetStore,
  fetchNewDataset,
  setShowDeleteDatasetModal,
  setFilesUploadProgress,
  setFilesUploadSize,
} from "redux/CreateDataset/actions";
import {
  getActivityLogs,
  getNewDataset,
  getShowDeleteDatasetModal,
  getFilesUploadProgress,
} from "redux/CreateDataset/selectors";

import { PRIVATE_DATASETS_PATH } from "helpers/constants/paths";

import DatasetDeletionModal from "views/modals/DatasetDeletionModal";
import ActivityLogErrorModal from "./Modals/ActivityLogErrorModal";
import DangerCircleIcon from "assets/icons/DangerCircleIcon";
import QuotaLimitModal from "views/modals/QuotaLimitModal";
import StartHereBanner from "./StartHereBanner";
import DataViewSection from "./DataViewSection";
import UploadProgress from "./UploadProgress";
import ImageError from "./Modals/ImageError";
import FatalError from "./Modals/FatalError";
import StepsSection from "./StepsSection";
import ProgressPane from "./ProgressPane";
import PageHeader from "./PageHeader";

import styles from "./style.module.scss";
import UploadFailure from "./UploadFailure";
import abortControllerManager from "helpers/abortControllerManager";
import { trackNewDatasetIngestionEvent } from "helpers/utility/amplitude";
import { AMP_DATASET_INGESTION_EVENT__USER_QUIT_WHILE_UPLOADING } from "helpers/constants/amplitudeEvents";

const DatasetCreationPage = ({
  newDataset,
  activityLogs,
  showDeleteDatasetModal,
  fileUploadProgress,
}: DatasetCreationPageProps) => {
  const [showStartBanner, setShowStartBanner] = useState(false);
  const POLLING_INTERVAL = 3000;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { newDatasetId } = useParams();
  const blocker = unstable_useBlocker(
    ({ currentLocation, nextLocation }) =>
      fileUploadProgress !== null &&
      currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (
      blocker.state === "blocked" &&
      window.confirm(
        "We're still uploading your files, sure you want to leave?"
      )
    ) {
      //track user quit event
      trackNewDatasetIngestionEvent(
        AMP_DATASET_INGESTION_EVENT__USER_QUIT_WHILE_UPLOADING
      );

      abortControllerManager.abortController();
      blocker.proceed();
      navigate(blocker.location.pathname + blocker.location.search);
    } else {
      if (blocker.reset) {
        blocker.reset();
      }
    }
  }, [navigate, dispatch, blocker, blocker.state]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (fileUploadProgress !== null) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    const handleUnload = () => {
      if (fileUploadProgress !== null) {
        dispatch(setFilesUploadProgress(null));
        dispatch(setFilesUploadSize(null));
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [dispatch, fileUploadProgress]);

  const handleSetShowDeleteDatasetModal = (value: boolean) => {
    dispatch(setShowDeleteDatasetModal(value));
  };

  const handleNavigateOnDelete = () => {
    navigate(PRIVATE_DATASETS_PATH);
  };

  useEffect(() => {
    if (newDatasetId && !newDataset) {
      dispatch(fetchNewDataset(newDatasetId) as any);
    }
    return () => {
      dispatch(resetStore());
    };
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (newDatasetId) {
      dispatch(fetchEventLogs(newDatasetId, true) as any);
    }

    const intervalId = setInterval(() => {
      if (newDatasetId) {
        dispatch(fetchEventLogs(newDatasetId, false) as any);
      }
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, newDatasetId]);

  useEffect(() => {
    if (
      activityLogs.length === 1 &&
      activityLogs[0]?.event_type === "DatasetInitialized"
    ) {
      setShowStartBanner(true);
    } else {
      setShowStartBanner(false);
    }
    //eslint-disable-next-line
  }, [activityLogs.length]);

  return (
    <>
      <PageHeader />
      <div className={styles.innerBody}>
        <div className={styles.createDataSection}>
          <StepsSection
            showStartBanner={showStartBanner}
            setShowStartBanner={setShowStartBanner}
          />
          <div className={styles.sectionDivider}>
            {showStartBanner && <StartHereBanner />}
          </div>
          <DataViewSection />

          <div className={styles.progressSection}>
            <UploadFailure />
            <UploadProgress />
            <ProgressPane />
          </div>
          <ImageError />
          <FatalError />
          {showDeleteDatasetModal && newDataset && (
            <DatasetDeletionModal
              datasetId={newDataset.id}
              datasetName={newDataset.display_name}
              datasetSource={newDataset.source_type}
              showModal={showDeleteDatasetModal}
              setShowModal={handleSetShowDeleteDatasetModal}
              handleNavigate={handleNavigateOnDelete}
              modalTitle={"Abort Operation"}
              modalSubtitle={"Are you sure you want to abort this operation?"}
              icon={<DangerCircleIcon color="#D6493E" variant="filled" />}
            />
          )}
          <ActivityLogErrorModal />
          <QuotaLimitModal />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    newDataset: getNewDataset(state),
    activityLogs: getActivityLogs(state),
    showDeleteDatasetModal: getShowDeleteDatasetModal(state),
    fileUploadProgress: getFilesUploadProgress(state),
  };
};

export default connect(mapStateToProps)(DatasetCreationPage);

interface DatasetCreationPageProps {
  newDataset: any;
  activityLogs: any[];
  showDeleteDatasetModal: boolean;
  fileUploadProgress: number | null;
}
