import { composeApiURL } from "helpers/utility/axiosHelpers";

const GoogleButton = () => {
  const loginURI = composeApiURL(
    process.env.REACT_APP_API_ENDPOINT!,
    `/google-sso`
  );
  return (
    <div
      id="g_id_onload"
      data-client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      data-context="signin"
      data-ux_mode="popup"
      data-login_uri={loginURI}
      data-nonce=""
      data-auto_prompt="false"
    ></div>
  );
};
export default GoogleButton;
