import XIcon from "assets/icons/XIcon";
import styles from "./style.module.scss";

const SupportedFormatPopup = ({ closePopup }: { closePopup: () => void }) => {
  const handleLinkClick = () => {
    window.open(
      "https://docs.visual-layer.com/docs/importing-annotations",
      "_blank"
    );
  };

  return (
    <div className={styles.formatInfoPopup}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Supported annotation file</div>
        <div className={styles.crossButton} onClick={closePopup}>
          <XIcon color="#151928" />
        </div>
      </div>
      <div className={styles.description}>
        <ul>
          <li>Json Annotations - in the COCO format</li>
          <li>Parquet / CSV annotations</li>
        </ul>
        <div>
          For more information please review our{" "}
          <span className={styles.linkText} onClick={handleLinkClick}>
            Upload guidelines
          </span>
        </div>
      </div>
      <div className={styles.actionButtonContainer}>
        <div className={styles.gotItButton} onClick={closePopup}>
          Got It
        </div>
      </div>
    </div>
  );
};
export default SupportedFormatPopup;
